import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import { createStructuredSelector } from 'reselect';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';
import Form from '@atlaskit/form';
import Select from '@atlaskit/select';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import RouterLink from 'components/RouterLink';

import {
  changeNameCreateFromSimple,
  changeDescCreateFromSimple,
  changeNegativeCreateFromSimple,
  changeNegativeInput,
  changePositiveCreateFromSimple,
  changePositiveInput,
  negativeStaffList,
  positiveStaffList,
  profileCreateFromSample
} from 'pages/ProfileList/actions';
import {
  makeSelectNameFromSample,
  makeSelectDescFromSample,
  makeSelectNegativeFromSample,
  makeSelectPositiveFromSample,
  makeSelectProfileLoading,
  makeSelectNegativeList,
  makeSelectPositiveList,
  makeSelectLoadingNegative,
  makeSelectLoadingPositive
} from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Label from './styled/Label';
import MainBlock from './styled/MainBlock';
import P from './styled/P';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateAskStepOne extends React.Component {
  state = {
    changeFlags: [],
    nameValid: true,
    positiveValid: true,
    negativeValid: true
  };

  componentDidMount() {
    const { getNegativeList, getPositiveList, negativeList, positiveList } = this.props;

    if (negativeList.length === 0) {
      getNegativeList();
    }

    if (positiveList.length === 0) {
      getPositiveList();
    }

    this.sendChangedNegativeInput = debounce(this.sendChangedNegativeInput, 300);
    this.sendChangedPositiveInput = debounce(this.sendChangedPositiveInput, 300);
  }

  addFlag = () => {
    this.setState({
      changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
    });
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button href="/profile/list" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeName = event => {
    this.setState({
      nameValid: !isEmpty(event.target.value)
    });
    this.props.changeName({
      name: event.target.value
    });
  };

  onChangeDesc = event => {
    this.props.changeDesc({
      desc: event.target.value
    });
  };

  onChangeNegative = options => {
    this.setState({
      negativeValid: options.length > 0
    });
    this.props.changeNegative({
      negativeSamples: fromJS(options)
    });
  };

  onChangePositive = options => {
    this.setState({
      positiveValid: options.length > 0
    });
    this.props.changePositive({
      positiveSamples: fromJS(options)
    });
  };

  handleForm = () => {
    const nameValid = !isEmpty(this.props.name);
    const positiveValid = this.props.positive.length > 0;

    if (nameValid && positiveValid) {
      this.props.createProfile();
    } else {
      this.setState({
        nameValid,
        positiveValid
      });
    }
  };

  sendChangedNegativeInput = input => {
    this.props.changeNegativeInput({
      input
    });
  };

  sendChangedPositiveInput = input => {
    this.props.changePositiveInput({
      input
    });
  };

  render() {
    const {
      name,
      desc,
      negative,
      negativeList,
      negativeLoading,
      positive,
      positiveList,
      positiveLoading,
      profileLoading
    } = this.props;

    const breadcrumbs = (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/profile/list"
          onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
          text="Профили"
          key="Profile"
        />
        <BreadcrumbsItem
          href="/profile/create/compare/1"
          onClick={event => this.onBreadcumbsClick(event, '/profile/create/compare/1')}
          text="Создать с помощью выборки"
          key="Create"
        />
      </BreadcrumbsStateless>
    );

    const bottomBar = (
      <PageHeaderBottom>
        <h4>Путем сравнения</h4>
      </PageHeaderBottom>
    );

    const negativeOptions = negativeList.map(item => ({
      label: item.full_name,
      value: item.id
    }));
    const positiveOptions = positiveList.map(item => ({
      label: item.full_name,
      value: item.id
    }));

    const content = (
      <Grid layout="fixed">
        <GridColumn medium={12}>
          <PageHeader
            actions={this.getPageHeaderButtonsDrawer()}
            bottomBar={bottomBar}
            breadcrumbs={breadcrumbs}
          >
            Добавить профиль
          </PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <div>
            <Form>
              <FieldGroup marginTop="8px">
                <Field width="100%">
                  <h4>Шаг 1 из 2</h4>
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.nameValid}
                    label="Название профиля"
                    onChange={this.onChangeName}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    label="Описание"
                    onChange={this.onChangeDesc}
                    placeholder=""
                    shouldFitContainer
                    minimumRows={4}
                    value={desc}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </div>
          <MainBlock>
            <P color="#6B778C">
              Система сформирует новый профиль на основе анализа положительных и отрицательных
              примеров. Пожалуйста, выберите не более 5 положительных и не более 5 отрицательных
              примеров ниже:
            </P>
            <Block>
              <Label>
                <span className="label-text">Положительные примеры</span>
                <Field className="field" width="100%" isInvalid={!this.state.positiveValid}>
                  <Select
                    className="multi-select"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isLoading={positiveLoading}
                    isMulti
                    loadingMessage={() => 'Загрузка'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.onChangePositive}
                    onInputChange={this.sendChangedPositiveInput}
                    options={positiveOptions}
                    placeholder="Начните печатать имя или фамилию"
                    value={positive}
                  />
                </Field>
              </Label>
            </Block>
            <Block>
              <Label>
                <span className="label-text">Отрицательные примеры</span>
                <Field width="100%">
                  <Select
                    className="multi-select"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isLoading={negativeLoading}
                    isMulti
                    loadingMessage={() => 'Загрузка'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.onChangeNegative}
                    onInputChange={this.sendChangedNegativeInput}
                    options={negativeOptions}
                    placeholder="Начните печатать имя или фамилию"
                    value={negative}
                  />
                </Field>
              </Label>
            </Block>
          </MainBlock>
          <ButtonWrapper>
            <Button appearance="primary" onClick={this.handleForm} isLoading={profileLoading}>
              Далее
            </Button>
          </ButtonWrapper>
        </GridColumn>
      </Grid>
    );

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        <Container>{content}</Container>
      </Fragment>
    );
  }
}

ProfileCreateAskStepOne.propTypes = {
  changeDesc: PropTypes.func,
  changeName: PropTypes.func,
  changeNegative: PropTypes.func,
  changeNegativeInput: PropTypes.func,
  changePositive: PropTypes.func,
  changePositiveInput: PropTypes.func,
  createProfile: PropTypes.func,
  desc: PropTypes.string,
  getNegativeList: PropTypes.func,
  getPositiveList: PropTypes.func,
  name: PropTypes.string,
  negative: PropTypes.array,
  negativeList: PropTypes.array,
  negativeLoading: PropTypes.bool,
  positive: PropTypes.array,
  positiveList: PropTypes.array,
  positiveLoading: PropTypes.bool,
  profileLoading: PropTypes.bool,
  samples: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  return {
    changeName: value => dispatch(changeNameCreateFromSimple(value)),
    changeDesc: value => dispatch(changeDescCreateFromSimple(value)),
    changeNegative: value => dispatch(changeNegativeCreateFromSimple(value)),
    changeNegativeInput: value => dispatch(changeNegativeInput(value)),
    changePositive: value => dispatch(changePositiveCreateFromSimple(value)),
    changePositiveInput: value => dispatch(changePositiveInput(value)),
    createProfile: () => dispatch(profileCreateFromSample()),
    getNegativeList: () => dispatch(negativeStaffList()),
    getPositiveList: () => dispatch(positiveStaffList())
  };
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectNameFromSample(),
  desc: makeSelectDescFromSample(),
  negative: makeSelectNegativeFromSample(),
  negativeList: makeSelectNegativeList(),
  negativeLoading: makeSelectLoadingNegative(),
  positive: makeSelectPositiveFromSample(),
  positiveList: makeSelectPositiveList(),
  positiveLoading: makeSelectLoadingPositive(),
  profileLoading: makeSelectProfileLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateAskStepOne);
