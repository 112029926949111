import React from 'react';
import Button from '@atlaskit/button';
import { HashLink as Link } from 'react-router-hash-link';

class RouterHashLink extends React.PureComponent {
  render() {
    const { children, className, disabled, href, onMouseEnter, onMouseLeave } = this.props;

    let result = (
      <Link
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        smooth
        to={href}
      >
        {children}
      </Link>
    );

    if (disabled === true) {
      result = <Button isDisabled={true}>{children}</Button>;
    }

    return result;
  }
}

export default RouterHashLink;
