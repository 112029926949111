import styled from 'styled-components';

const IconsText = styled.div`
  color: #172b4d;
  font-size: 16px;
  letter-spacing: 0;
  margin-left: 10px;
`;

export default IconsText;
