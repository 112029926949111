import React from 'react';
import styled from 'styled-components';

import IconTime from './icon_time.svg';
import IconResult from './icon_result.svg';
import IconCommunication from './icon_communication.svg';

const Wrapper = styled.section`
  background-color: #fafafc;
  display: flex;
  padding: 100px 0;
  width: 100%;

  @media (max-width: 1020px) {
    padding: 50px 0;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1200px) {
    width: calc(100% - 30px);
  }
`;

const Title = styled.p`
  color: #172b4d;
  font-size: 29px;
  letter-spacing: 0.32px;
  line-height: 32px;
  text-align: center;

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
    text-align: left;
  }
`;

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 93px;

  @media (max-width: 1020px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

const Column = styled.div`
  width: 360px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% / 3 - 10px);
  }

  @media (max-width: 1020px) {
    margin-top: 50px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const Icon = styled.i`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  display: flex;
  height: 48px;
  width: 48px;
`;

const Subtitle = styled.p`
  font-size: 24px;
  color: #172b4d;
  letter-spacing: 0.33px;
  line-height: 28px;
  margin-top: 30px;

  @media (max-width: 1020px) {
    font-size: 19px;
    line-height: 22px;
    margin-top: 20px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: #172b4d;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 23px;

  @media (max-width: 1020px) {
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
`;

const Advantage = () => (
  <Wrapper>
    <Inner>
      <Title>Что даст вам “HRHelper”:</Title>
      <Block>
        <Column>
          <Icon backgroundImage={IconTime} />
          <Subtitle>Вы экономите время на поиске и подборе, значит, экономите деньги</Subtitle>
          <Text>
            Вы проводите собеседования только с людьми, которые вам изначально подходят, с
            пониманием, как коммуницировать и мотивировать их присоединиться к команде.
          </Text>
        </Column>
        <Column>
          <Icon backgroundImage={IconResult} />
          <Subtitle>Вы получаете точный результат с минимальными усилиями</Subtitle>
          <Text>
            Наш сервис выявляет кандидатов, подходящих на должность более чем на 87%. Для этого вы
            просто отправляете тест кандидату. Ни одно собеседование на начальном этапе не помогает
            выявить соответствие настолько просто и точно.
          </Text>
        </Column>
        <Column>
          <Icon backgroundImage={IconCommunication} />
          <Subtitle>Вы повышаете продуктивность взаимодействия с сотрудниками</Subtitle>
          <Text>
            Результаты тестирования — процент выраженности 18 способов обработки информации, с ними
            вы доносите задачи, обратную связь, мотивируете доступным для него способом и избегаете
            ненужного сопротивления, а ведь это и есть залог продуктивной работы команды.
          </Text>
        </Column>
      </Block>
    </Inner>
  </Wrapper>
);

export default Advantage;
