import styled from 'styled-components';
import { gridSize as gridSizeFn } from '@atlaskit/theme';

const gridSize = gridSizeFn();

const Wrapper = styled.div`
  font-size: 20px;
  color: #deebff;
  letter-spacing: 0.34px;
  padding: ${`${gridSize * 2}px 0`};
`;

export default Wrapper;
