import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import debounce from 'lodash/debounce';
import Button, { ButtonGroup } from '@atlaskit/button';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import TextField from '@atlaskit/field-text';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Select from '@atlaskit/select';
import Pagination from '@atlaskit/pagination';

import Center from 'components/Center';
import Empty from 'components/Empty';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeAddsModal,
  changeCompareProfile,
  changeCompareProfileInput,
  changeStaffCompareFilter,
  changeStaffComparePage,
  changeStaffCompareSort,
  createCompare,
  deleteCompare,
  staffRead,
  staffCompare,
  profileList
} from 'pages/Home/actions';
import {
  makeSelectCompany,
  makeSelectStaffError,
  makeSelectErrorCode,
  makeSelectLoadingTable,
  makeSelectProfileList,
  makeSelectStaffList,
  makeSelectStaffRead,
  makeSelectStaffCompare,
  makeSelectStaffLoading,
  makeSelectCompareCount,
  makeSelectComparePage,
  makeSelectCompareSort,
  makeSelectCompareProfiles,
  makeSelectProfileLoading
} from 'pages/Home/selectors';
import reducer from 'pages/Home/reducer';
import saga from 'pages/Home/saga';

import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Table from './Table';

class Report extends Component {
  state = {
    person: null,
    addModals: [],
    flags: [],
    nameFilter: '',
    filterChanged: false,
    selectValid: true,
    selectChanged: this.props.profilesCompare.size > 0
  };

  componentDidMount() {
    this.sendChangedNameFilter = debounce(this.sendChangedNameFilter, 300);
    this.sendChangedProfileSelector = debounce(this.sendChangedProfileSelector, 300);

    const personId = parseInt(this.props.match.params.id, 10);
    const { profileList, staffRead } = this.props;

    if (staffRead.id !== personId) {
      this.props.getStaffRead({
        staffId: personId
      });
    }

    if (profileList.size === 0) {
      this.props.getProfileList();
    }

    this.props.getStaffCompare({
      staffId: personId
    });
  }

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button
        appearance="primary"
        onClick={() =>
          this.setState({
            addModals: [1]
          })
        }
      >
        Создать отчет-соответствие
      </Button>
    </ButtonGroup>
  );

  closeModal = id => {
    this.setState({ addModals: this.state.addModals.filter(i => i !== id) });
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      addModals: this.state.addModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeCompareProfiles = result => {
    this.setState({
      selectValid: result.length > 0
    });
    this.props.changeCompareProfile({
      compareProfiles: result.map(item => item.value)
    });
  };

  onCreateCompare = id => {
    if (this.props.profilesCompare.size > 0) {
      const staffId = parseInt(this.props.match.params.id, 10);

      this.props.createCompare({
        staffId
      });
      this.addFlag(id);
    } else {
      this.setState({
        selectValid: false
      });
    }
  };

  onDeleteCompare = id => {
    const staffId = parseInt(this.props.match.params.id, 10);

    this.props.deleteCompare({
      compareId: id,
      staffId
    });
  };

  onChangeNameFilter = event => {
    this.sendChangedNameFilter(event.target.value);
    this.setState({
      filterChanged: true,
      nameFilter: event.target.value
    });
  };

  onSort = (key, order) => {
    this.props.changeStaffCompareSort({
      key,
      order
    });
  };

  sendChangedNameFilter = nameFilter => {
    this.props.changeStaffCompareFilter({
      nameFilter
    });
  };

  sendChangedProfileSelector = input => {
    this.props.changeCompareProfileInput({
      input
    });
  };

  render() {
    let { filterChanged, nameFilter, person } = this.state;

    const {
      changeAddsModal,
      changeStaffComparePage,
      compareCount,
      comparePage,
      compareSort,
      error,
      errorCode,
      loadingProfiles,
      loadingTable,
      profileList,
      staffCompare,
      staffLoading
    } = this.props;

    if (isNull(person)) {
      person = this.props.staffRead;
    }

    let content = <Spinner />;

    if ((!staffLoading && (staffCompare.size > 0 || filterChanged)) || loadingTable) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/person/list"
            onClick={event => this.onBreadcumbsClick(event, '/person/list')}
            text="Сотрудники"
            key="Personal"
          />
          <BreadcrumbsItem
            href={`/person/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/${person.id}`)}
            text={person.full_name}
            key="Petrov"
          />
          <BreadcrumbsItem
            href={`/person/compare/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/compare/${person.id}`)}
            text="Отчеты-соответствия"
            key="Report"
          />
        </BreadcrumbsStateless>
      );

      const barContent = (
        <ButtonGroup>
          <TextField
            isLabelHidden
            label="hidden"
            onChange={this.onChangeNameFilter}
            placeholder="Поиск по профилю"
            value={nameFilter}
          />
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader
              breadcrumbs={breadcrumbs}
              actions={this.getPageHeaderButtonsDrawer()}
              bottomBar={barContent}
            >
              Отчет-соответствие
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Table
              data={staffCompare}
              isLoading={loadingTable}
              onDelete={this.onDeleteCompare}
              onSort={this.onSort}
              personId={person.id}
              sortKey={compareSort.get('key')}
              sortOrder={compareSort.get('order')}
            />
            {!loadingTable && (
              <Center>
                <Pagination
                  value={comparePage}
                  total={Math.ceil(compareCount / 25)}
                  onChange={staffComparePage => changeStaffComparePage({ staffComparePage })}
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!staffLoading && staffCompare.size === 0 && !error) {
      content = (
        <Empty
          header="Создайте отчет-соответствие"
          text="Вы можете сравнить профиль кадра с профилем должности, чтобы узнать насколько человек соответствует должности."
        >
          <Button
            appearance="primary"
            onClick={() =>
              this.setState({
                addModals: [1]
              })
            }
          >
            Создать отчет-соответствие
          </Button>
        </Empty>
      );
    } else if (error && errorCode === 403) {
      content = (
        <Empty
          header="Отчет недоступен"
          text="Чтобы открыть этот отчет, необходимо купить дополнительные отчеты или перейти на другой тариф."
        >
          <Button
            appearance="primary"
            onClick={() =>
              changeAddsModal({
                addsModal: [1]
              })
            }
          >
            Купить отчеты
          </Button>
        </Empty>
      );
    }

    const options = profileList.toJS();

    return (
      <Page>
        <Helmet>
          <title>Отчет-соответствие</title>
        </Helmet>
        {content}

        {this.state.addModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Создать отчет-соответствие"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Создать',
                onClick: this.onCreateCompare
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
          >
            <p>Выберите один или несколько профилей, с которым необходимо сравнить сотрудника</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup marginTop="8px">
                <Field width="100%" isInvalid={!this.state.selectValid}>
                  <Select
                    className="multi-select"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isLoading={loadingProfiles}
                    isMulti
                    loadingMessage={() => 'Загрузка'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.onChangeCompareProfiles}
                    onInputChange={this.sendChangedProfileSelector}
                    options={options}
                    placeholder="Начните печатать"
                    shouldFitContainer
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Отчет-соответствие создан"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

Report.propTypes = {
  changeAddsModal: PropTypes.func,
  changeCompareProfile: PropTypes.func,
  changeCompareProfileInput: PropTypes.func,
  changeStaffCompareFilter: PropTypes.func,
  changeStaffComparePage: PropTypes.func,
  changeStaffCompareSort: PropTypes.func,
  company: PropTypes.object,
  compareCount: PropTypes.number,
  comparePage: PropTypes.number,
  compareSort: PropTypes.object,
  createCompare: PropTypes.func,
  error: PropTypes.bool,
  errorCode: PropTypes.number,
  deleteCompare: PropTypes.func,
  getProfileList: PropTypes.func,
  getStaffCompare: PropTypes.func,
  getStaffRead: PropTypes.func,
  loadingProfiles: PropTypes.bool,
  loadingTable: PropTypes.bool,
  profileList: PropTypes.object,
  profilesCompare: PropTypes.object,
  staffCompare: PropTypes.object,
  staffList: PropTypes.array,
  staffLoading: PropTypes.bool,
  staffRead: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    changeAddsModal: value => dispatch(changeAddsModal(value)),
    changeCompareProfile: value => dispatch(changeCompareProfile(value)),
    changeCompareProfileInput: value => dispatch(changeCompareProfileInput(value)),
    changeStaffCompareFilter: value => dispatch(changeStaffCompareFilter(value)),
    changeStaffComparePage: value => dispatch(changeStaffComparePage(value)),
    changeStaffCompareSort: value => dispatch(changeStaffCompareSort(value)),
    createCompare: value => dispatch(createCompare(value)),
    deleteCompare: value => dispatch(deleteCompare(value)),
    getProfileList: () => dispatch(profileList()),
    getStaffCompare: value => dispatch(staffCompare(value)),
    getStaffRead: value => dispatch(staffRead(value))
  };
}

const mapStateToProps = createStructuredSelector({
  company: makeSelectCompany(),
  compareCount: makeSelectCompareCount(),
  comparePage: makeSelectComparePage(),
  compareSort: makeSelectCompareSort(),
  error: makeSelectStaffError(),
  errorCode: makeSelectErrorCode(),
  loadingProfiles: makeSelectProfileLoading(),
  loadingTable: makeSelectLoadingTable(),
  profileList: makeSelectProfileList(),
  profilesCompare: makeSelectCompareProfiles(),
  staffCompare: makeSelectStaffCompare(),
  staffList: makeSelectStaffList(),
  staffLoading: makeSelectStaffLoading(),
  staffRead: makeSelectStaffRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Report);
