import styled from 'styled-components';

const Item = styled.div`
  align-items: center;
  color: initial;
  display: flex;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    color: initial;
    text-decoration: underline;
  }

  &:first-child {
    margin-top: 10px;
  }
`;

export default Item;
