import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const A = styled.a`
  & rect {
    transition: fill 0.1s ease-out;
  }

  &:hover {
    & rect {
      fill: ${darken(0.1, '#4285F4')};
    }
  }
`;

const GoogleAuth = ({ link }) => (
  <A href={link}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-784.000000, -451.000000)">
          <g transform="translate(784.000000, 451.000000)">
            <rect fill="#4285F4" x="0" y="0" width="32" height="32" rx="2" />
            <g transform="translate(9.000000, 9.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M13.8632812,5.62871085 L13.3,5.62871085 L13.3,5.6 L7,5.6 L7,8.4 L10.9566408,8.4 C10.3783204,10.0296875 8.8279296,11.2 7,11.2 C4.6798829,11.2 2.8,9.3201171 2.8,7 C2.8,4.6798829 4.6798829,2.8 7,2.8 C8.0705079,2.8 9.0453125,3.2033204 9.7863283,3.86367205 L11.7660158,1.88398455 C10.5164063,0.7191408 8.84433585,0 7,0 C3.13359375,0 0,3.13359375 0,7 C0,10.8664062 3.13359375,14 7,14 C10.8664062,14 14,10.8664062 14,7 C14,6.5310546 13.9521483,6.07304705 13.8632812,5.62871085 Z" />
              <path d="M0.8066408,3.7419921 L3.1076171,5.42910165 C3.7296875,3.88828125 5.2363283,2.8 7,2.8 C8.0705079,2.8 9.0453125,3.2033204 9.7863283,3.86367205 L11.7660158,1.88398455 C10.5164063,0.7191408 8.84433585,0 7,0 C4.3107421,0 1.9796875,1.5175783 0.8066408,3.7419921 Z" />
              <path d="M7,14 C8.80742205,14 10.4507812,13.3082033 11.6935546,12.1830079 L9.5265625,10.3496095 C8.8238283,10.8814454 7.9501954,11.2 7,11.2 C5.17890625,11.2 3.63398455,10.0392579 3.0515625,8.4191408 L0.76835955,10.1773438 C1.9263671,12.4441408 4.27929705,14 7,14 Z" />
              <path d="M13.8632812,5.62871085 L13.3,5.62871085 L13.3,5.6 L7,5.6 L7,8.4 L10.9566408,8.4 C10.6791016,9.18339835 10.1746095,9.85742205 9.5251954,10.3496096 C9.5265625,10.3496096 9.5265625,10.3496096 9.5265625,10.3496096 L11.6935546,12.1830079 C11.5404296,12.3210938 14,10.5 14,7 C14,6.5310546 13.9521484,6.07304705 13.8632812,5.62871085 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </A>
);

export default GoogleAuth;
