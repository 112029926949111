import styled from 'styled-components';

const Block = styled.div`
  margin-top: 15px;

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

export default Block;
