import styled from 'styled-components';

const MetaReactionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  width: 112px;
`;

export default MetaReactionsWrapper;
