import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';

import Page from '@atlaskit/page';
import Button from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Form from '@atlaskit/form';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import FieldGroup from 'pages/Home/styled/FieldGroup';
import Field from 'pages/Home/styled/Field';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';

import Modal from 'components/Modal';
import Header from 'promo/Header';
import CompanyLogos from 'promo/CompanyLogos';
import HowWork from 'promo/HowWork';
import Advantage from 'promo/Advantage';
import Banner from 'promo/Banner';
import Calculator from 'promo/Calculator';
import Feedback from 'promo/Feedback';
import Footer from 'promo/Footer';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './saga';

import {
  changeApplyFlags,
  changeEmail,
  changeFlags,
  changeModals,
  changeName,
  changeText,
  sendFeedback,
  sendSubmit
} from './actions';

import {
  makeSelectApplyFlags,
  makeSelectApplyLoading,
  makeSelectFlags,
  makeSelectLoading,
  makeSelectModals
} from './selectors';

import TopImage from './images/top.jpg';
import TeamImage from './images/team.jpg';

import AllWidthBlock from './styled/AllWidthBlock';
import AllWidthColumn from './styled/AllWidthColumn';
import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import CenteredColumn from './styled/CenteredColumn';
import Column from './styled/Column';
import FlexCentered from './styled/FlexCentered';
import Image from './styled/Image';
import RightColumn from './styled/RightColumn';
import Subtext from './styled/Subtext';
import Subtitle from './styled/Subtitle';
import Text from './styled/Text';
import Title from './styled/Title';
import Top from './styled/Top';

class LastPromo extends React.Component {
  state = {
    name: '',
    nameValid: true,
    phone: '',
    phoneValid: true,
    email: ''
  };

  addFlag = () => {
    this.props.sendFeedback();
  };

  removeFlag = id => this.props.changeFlags({ flags: this.props.flags.filter(v => v !== id) });

  removeSubmitFlag = id =>
    this.props.changeApplyFlags({ flags: this.props.applyFlags.filter(v => v !== id) });

  openModal = () => {
    this.props.changeModals({
      modals: [1]
    });
  };

  closeModal = id => {
    this.props.changeModals({
      modals: this.props.modals.filter(i => i !== id)
    });
  };

  changeName = e => {
    this.setState({
      name: e.target.value,
      nameValid: true
    });
  };

  changePhone = e => {
    this.setState({
      phone: e.target.value,
      phoneValid: true
    });
  };

  changeEmail = e => {
    this.setState({
      email: e.target.value
    });
  };

  sendForm = () => {
    const { name, phone, email } = this.state;

    const nameValid = !isEmpty(name);
    const phoneValid = !isEmpty(phone);

    this.setState({
      nameValid,
      phoneValid
    });

    if (nameValid && phoneValid) {
      this.props.sendSubmit({
        name,
        phone,
        email
      });
    }
  };

  render() {
    const { applyFlags, applyLoading, modals } = this.props;

    const { name, nameValid, phone, phoneValid, email } = this.state;

    return (
      <Page>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
        <Header withLinks />
        <Top>
          <Column>
            <Title>Ускорьте подбор правильных людей в 17 раз за счет метапрограммного опроса</Title>
            <Text>
              “HRHelper” объединил опыт и&nbsp;экспертизу профессионалов в&nbsp;области HR. Сервис
              позволит подобрать кандидата совместимого с&nbsp;компанией, командой, должностью более
              чем на&nbsp;87%
            </Text>
            <ButtonWrapper>
              <Button appearance="primary" onClick={this.openModal}>
                Оставить заявку
              </Button>
            </ButtonWrapper>
          </Column>
          <Image backgroundImage={TopImage} width={560} />
        </Top>
        <AllWidthBlock backgroundColor="#FAFAFC" height={700}>
          <CenteredColumn>
            <Title width={460}>
              С “HRHelper” вы получите ответ на&nbsp;вопросы о том, как кандидат (сотрудник)
            </Title>
            <Text width={460}>
              — Коммуницирует
              <br />
              — Мыслит
              <br />— Мотивируется
            </Text>
          </CenteredColumn>
          <AllWidthColumn backgroundImage={TeamImage} />
        </AllWidthBlock>
        <HowWork />
        <Advantage />
        <Banner onClick={this.openModal} />
        <Calculator />
        <AllWidthBlock backgroundColor="#FAFAFC">
          <Block padding="125px 0">
            <FlexCentered>
              <CompanyLogos />
            </FlexCentered>
            <RightColumn>
              <Subtitle width={460}>
                Метапрограммы — способы обработки информации человеком, показывающие особенности его
                коммуникации, мышления и мотивации
              </Subtitle>
              <Subtext width={460}>
                Метапрограммы применяются в бизнесе по всему миру: в корпорациях Toyota, Microsoft,
                Heineken, Land Rover и так&nbsp;далее. Область применения: подбор персонала,
                управление персоналом, продажи.
              </Subtext>

              <Subtext width={460}>
                1982 г. — Southwest Airlines — впервые применила в подборе персонала (штат 53 000
                сотрудников). Компания&nbsp;является бессменным лидером по количеству перевезенных
                пассажиров в США.
              </Subtext>
              <Subtext width={460}>1986 г. — начало применения в IBM, HP, CIBC, TRW</Subtext>
              <Subtext width={460}>
                2004 г. — тест, основанный на метапрограммах, получил аккредитацию уровня «В» от
                Британского психологического общества (BPS).
              </Subtext>
            </RightColumn>
          </Block>
        </AllWidthBlock>
        <Banner onClick={this.openModal} />
        <Footer />

        {modals.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Оставить заявку"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.sendForm,
                isLoading: applyLoading,
                type: 'submit'
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>
              Здравствуйте! Оставьте свои контакты, мы свяжемся с вами для предоставления
              бесплатного доступа
            </p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!nameValid}
                    label="Ваше имя"
                    name="name"
                    onChange={this.changeName}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!phoneValid}
                    label="Ваш телефон"
                    name="phone"
                    onChange={this.changePhone}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={phone}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Ваш email"
                    name="email"
                    onChange={this.changeEmail}
                    placeholder=""
                    shouldFitContainer
                    value={email}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeSubmitFlag(name)}>
          {applyFlags.map(id => (
            <Flag
              description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
              isDismissAllowed
              id={id}
              icon={
                id > 0 ? (
                  <Tick label="Success" />
                ) : (
                  <Error label="Error" primaryColor={colors.R300} />
                )
              }
              key={`${id}`}
              title={id > 0 ? 'Заявка принята. Ожидайте звонок.' : 'Ошибка'}
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.props.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Сообщение отправлено"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

LastPromo.propTypes = {
  applyFlags: PropTypes.array.isRequired,
  applyLoading: PropTypes.bool.isRequired,
  changeApplyFlags: PropTypes.func.isRequired,
  changeEmail: PropTypes.func,
  changeFlags: PropTypes.func,
  changeModals: PropTypes.func.isRequired,
  changeName: PropTypes.func,
  changeText: PropTypes.func,
  flags: PropTypes.array,
  loading: PropTypes.bool,
  modals: PropTypes.array.isRequired,
  sendFeedback: PropTypes.func,
  sendSubmit: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeApplyFlags: value => dispatch(changeApplyFlags(value)),
    changeEmail: value => dispatch(changeEmail(value)),
    changeFlags: value => dispatch(changeFlags(value)),
    changeModals: value => dispatch(changeModals(value)),
    changeName: value => dispatch(changeName(value)),
    changeText: value => dispatch(changeText(value)),
    sendFeedback: () => dispatch(sendFeedback()),
    sendSubmit: value => dispatch(sendSubmit(value))
  };
}

const mapStateToProps = createStructuredSelector({
  applyFlags: makeSelectApplyFlags(),
  applyLoading: makeSelectApplyLoading(),
  flags: makeSelectFlags(),
  loading: makeSelectLoading(),
  modals: makeSelectModals()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'landing', reducer });
const withSaga = injectSaga({ key: 'landing', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(LastPromo);
