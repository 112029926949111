import styled from 'styled-components';

const Title = styled.div`
  color: #172b4d;
  font-size: 29px;
  letter-spacing: 0.32px;
  line-height: 32px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
    width: 100%;
  }
`;

export default Title;
