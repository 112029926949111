import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(23, 43, 77, 0.32), 0 0 0 1px rgba(9, 30, 66, 0.08);
  box-sizing: border-box;
  display: ${props => (props.show ? 'block' : 'none')};
  left: ${props => props.positionX + 10}px;
  top: ${props => props.positionY}px;
  padding: 0 20px 20px;
  position: absolute;
  width: 360px;
  z-index: 10;
`;

export default Wrapper;
