import { fromJS } from 'immutable';
import moment from "moment";

import {
  CHANGE_PHONE,
  CHANGE_CODE,
  SEND_CREATE_REQUEST,
  SEND_CREATE_REQUEST_SUCCESS,
  SEND_CREATE_REQUEST_FAIL,
  SEND_VERIFY_REQUEST,
  SEND_VERIFY_REQUEST_SUCCESS,
  SEND_VERIFY_REQUEST_FAIL,
  USER_READ_REQUEST,
  USER_READ_REQUEST_SUCCESS,
  USER_READ_REQUEST_FAIL,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_GENDER,
  CHANGE_DATE_OF_BIRTH,
  CHANGE_TERMS_ACCEPTED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_REQUEST_SUCCESS,
  USER_UPDATE_REQUEST_FAIL,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_INVITE_CODE_REQUEST_SUCCESS,
  CHECK_INVITE_CODE_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  phone: '',
  code: '',
  smsWasSend: false,
  verified: false,
  invitationCode: null,
  loading: false,
  termsAccepted: false,
  user: {
    pk: 0,
    phone_number: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    gender: 0,
    is_paid: false,
    date_of_birth: ''
  }
});

function testStartReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PHONE:
      return state.set('phone', action.phone);
    case CHANGE_CODE:
      return state.set('code', action.code);
    case SEND_CREATE_REQUEST:
      return state.set('loading', true).set('smsWasSend', false);
    case SEND_CREATE_REQUEST_SUCCESS:
      return state.set('loading', false).set('smsWasSend', true);
    case SEND_CREATE_REQUEST_FAIL:
      return state.set('loading', false).set('smsWasSend', false);
    case SEND_VERIFY_REQUEST:
      return state
        .set('loading', true)
        .set('verified', false)
        .set('invitationCode', action.invitationCode);
    case SEND_VERIFY_REQUEST_SUCCESS:
      return state.set('loading', false).set('verified', true);
    case SEND_VERIFY_REQUEST_FAIL:
      return state.set('loading', false).set('verified', false);
    case USER_READ_REQUEST:
      return state.set('loading', true);
    case USER_READ_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('user', action.user)
        .setIn(['user', 'date_of_birth'], action.user.toJS().date_of_birth ?
          moment(action.user.toJS().date_of_birth, "YYYY-MM-DD").format("DD.MM.YYYY") : "");
    case USER_READ_REQUEST_FAIL:
      return state.set('loading', false);
    case CHANGE_NAME:
      return state.setIn(['user', 'first_name'], action.name);
    case CHANGE_SURNAME:
      return state.setIn(['user', 'last_name'], action.surname);
    case CHANGE_EMAIL:
      return state.setIn(['user', 'email'], action.email);
    case CHANGE_PASSWORD:
      return state.setIn(['user', 'password'], action.password);
    case CHANGE_GENDER:
      return state.setIn(['user', 'gender'], action.gender);
    case CHANGE_DATE_OF_BIRTH:
      return state.setIn(['user', 'date_of_birth'], action.date_of_birth);
    case CHANGE_TERMS_ACCEPTED:
      return state.set('termsAccepted', action.termsAccepted);
    case USER_UPDATE_REQUEST:
      return state.set('loading', true);
    case USER_UPDATE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case USER_UPDATE_REQUEST_FAIL:
      return state.set('loading', false);
    case CHECK_INVITE_CODE_REQUEST:
      return state.set('loading', true).set('invitationCode', action.invitationCode);
    case CHECK_INVITE_CODE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case CHECK_INVITE_CODE_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default testStartReducer;
