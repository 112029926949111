import styled from 'styled-components';

const LineBody = styled.div`
  border-bottom: 1px solid #d9d9d9;
  position: relative;
  transition: all 0.3s ease-out;
  width: max-content;

  &:hover {
    background-color: #f5faff;
  }
`;

export default LineBody;
