import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Item } from '@atlaskit/navigation-next';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import QueuesIcon from '@atlaskit/icon/glyph/queues';

import { changeReportShape } from 'pages/IndividualProfile/Reports/actions';

import BetterDropdown from "../../../containers/ReportDropdown/BetterDropdown";
import injectReducer from "../../../../utils/injectReducer";
import reducer from "pages/IndividualProfile/Reports/reducer";

const ReportDropdown = ({ changeReportShape, reportShape = -1, ...props }) => (
  <BetterDropdown
    trigger={({ isOpen = false }) => (
      <Item
        before={() => <QueuesIcon />}
        isSelected={isOpen || props.to === props.match.url}
        text={props.reportShapeText.find(element => element.id === reportShape).label}
        after={() => (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
      />
    )}
  >
    <DropdownItemGroup>
      {props.reportShapeText.map(dropdown => (
        <DropdownItem
          key={dropdown.id}
          isSelected={dropdown.id === reportShape}
          onClick={() => {
            if (props.to !== props.match.url) {
              props.history.push(props.to);
            }

            changeReportShape({ reportShape: dropdown.id });
          }}
        >
          {dropdown.label}
        </DropdownItem>
      ))}
    </DropdownItemGroup>
  </BetterDropdown>
);

function mapDispatchToProps(dispatch) {
  return {
    changeReportShape: value => dispatch(changeReportShape(value))
  };
}

const mapStateToProps = store => ({
  reportShape: store.getIn(['individualReport', 'reportShape']),
  reportShapeText: (() => {
    const report = store.getIn(['individualReport', 'report']);

    let reportShapeText = [
      {
        id: -1,
        label: 'Полный отчет'
      }
    ];

    if (!report) {
      return reportShapeText;
    }

    const jsList = report.toJS().list;

    let reportArray = [];
    for (let key in jsList) {
      reportArray.push(jsList[key])
    }

    reportShapeText = reportShapeText.concat(reportArray.map((section, index) => (
      {
        id: index,
        label: section.title
      }
    )));

    return reportShapeText;
  })()
})

const withReducer = injectReducer({ key: 'individualReport', reducer });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  withConnect,
  withReducer
)(ReportDropdown);
