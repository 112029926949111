import styled from 'styled-components';

const QuestionNote = styled.div`
  margin: 30px 0;
  font-size: 12px;
  color: #6b778c;
  letter-spacing: 0;
  line-height: 16px;
`;

export default QuestionNote;
