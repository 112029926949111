import {
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_SUCCESS,
  DOCUMENTS_LIST_FAIL,
  DOCUMENT_READ_REQUEST,
  DOCUMENT_READ_SUCCESS,
  DOCUMENT_READ_FAIL
} from './constants';

export function documentsList() {
  return {
    type: DOCUMENTS_LIST_REQUEST
  };
}

export function documentsListSuccess(payload) {
  return {
    type: DOCUMENTS_LIST_SUCCESS,
    ...payload
  };
}

export function documentsListFail() {
  return {
    type: DOCUMENTS_LIST_FAIL
  };
}

export function documentRead(payload) {
  return {
    type: DOCUMENT_READ_REQUEST,
    ...payload
  };
}

export function documentReadSuccess(payload) {
  return {
    type: DOCUMENT_READ_SUCCESS,
    ...payload
  };
}

export function documentReadFail() {
  return {
    type: DOCUMENT_READ_FAIL
  };
}
