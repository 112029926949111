export const CHANGE_NAME = 'hh/pages/landing/CHANGE_NAME';
export const CHANGE_EMAIL = 'hh/pages/landing/CHANGE_PHONE';
export const CHANGE_TEXT = 'hh/pages/landing/CHANGE_TEXT';
export const CHANGE_FLAGS = 'hh/pages/landing/CHANGE_FLAGS';

export const SEND_FEEDBACK_REQUEST = 'hh/pages/landing/SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_REQUEST_SUCCESS = 'hh/pages/landing/SEND_FEEDBACK_REQUEST_SUCCESS';
export const SEND_FEEDBACK_REQUEST_FAIL = 'hh/pages/landing/SEND_FEEDBACK_REQUEST_FAIL';

export const CHANGE_MODALS = 'hh/pages/landing/CHANGE_MODALS';
export const CHANGE_APPLY_FLAGS = 'hh/pages/landing/CHANGE_APPLY_FLAGS';
export const SEND_SUBMIT_REQUEST = 'hh/pages/landing/SEND_SUBMIT_REQUEST';
export const SEND_SUBMIT_REQUEST_SUCCESS = 'hh/pages/landing/SEND_SUBMIT_REQUEST_SUCCESS';
export const SEND_SUBMIT_REQUEST_FAIL = 'hh/pages/landing/SEND_SUBMIT_REQUEST_FAIL';
