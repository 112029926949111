import styled from 'styled-components';

const AllWidthBlock = styled.section`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};
  display: flex;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  width: 100%;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export default AllWidthBlock;
