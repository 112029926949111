import styled from 'styled-components';

const ButtonField = styled.div`
  align-items: center;
  display: flex;
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};
  margin-top: 40px;

  & div {
    margin-left: 15px;
  }

  @media (max-width: 1020px) {
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
`;

export default ButtonField;
