import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import UserPage from 'pages/PartnerProfile/User';

const PartnerUserView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/index" />
    <UserPage />
  </Fragment>
);

export default PartnerUserView;
