import {
  CHANGE_EMAIL,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_PHONE_NUMBER,
  CHANGE_GENDER,
  CHANGE_PASSWORD_ONE,
  CHANGE_PASSWORD_TWO,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_READ_REQUEST,
  USER_READ_SUCCESS,
  USER_READ_FAIL
} from './constants';

export function changeEmail(payload) {
  return {
    type: CHANGE_EMAIL,
    ...payload
  };
}

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    ...payload
  };
}

export function changeSurname(payload) {
  return {
    type: CHANGE_SURNAME,
    ...payload
  };
}

export function changePhoneNumber(payload) {
  return {
    type: CHANGE_PHONE_NUMBER,
    ...payload
  };
}

export function changeGender(payload) {
  return {
    type: CHANGE_GENDER,
    ...payload
  };
}

export function changePasswordOne(payload) {
  return {
    type: CHANGE_PASSWORD_ONE,
    ...payload
  };
}

export function changePasswordTwo(payload) {
  return {
    type: CHANGE_PASSWORD_TWO,
    ...payload
  };
}

export function updateUser() {
  return {
    type: UPDATE_USER_REQUEST
  };
}

export function updateUserSuccess() {
  return {
    type: UPDATE_USER_SUCCESS
  };
}

export function updateUserFail() {
  return {
    type: UPDATE_USER_FAIL
  };
}

export function updatePassword() {
  return {
    type: UPDATE_PASSWORD_REQUEST
  };
}

export function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS
  };
}

export function updatePasswordFail() {
  return {
    type: UPDATE_PASSWORD_FAIL
  };
}

export function userRead() {
  return {
    type: USER_READ_REQUEST
  };
}

export function userReadSuccess(payload) {
  return {
    type: USER_READ_SUCCESS,
    ...payload
  };
}

export function userReadFail() {
  return {
    type: USER_READ_FAIL
  };
}
