import { fromJS } from 'immutable';

import {
  CHANGE_EMAIL,
  CHANGE_RECOVER_SENT,
  CHANGE_NEW_PASSWORD,
  RECOVER_REQUEST,
  RECOVER_REQUEST_SUCCESS,
  RECOVER_REQUEST_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST_SUCCESS,
  NEW_PASSWORD_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  error: '',
  email: '',
  flags: [],
  loading: false,
  recoverSent: false,
  newPassword: '',
  uid: '',
  token: ''
});

function recoverReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_EMAIL:
      return state.set('email', action.email);
    case CHANGE_RECOVER_SENT:
      return state.set('recoverSent', action.recoverSent);
    case CHANGE_NEW_PASSWORD:
      return state.set('newPassword', action.newPassword);
    case RECOVER_REQUEST:
      return state.set('recoverSent', false).set('loading', true);
    case RECOVER_REQUEST_SUCCESS:
      return state.set('recoverSent', true).set('loading', false);
    case RECOVER_REQUEST_FAIL:
      return state.set('loading', false);
    case NEW_PASSWORD_REQUEST:
      return state
        .set('loading', true)
        .set('uid', action.uid)
        .set('token', action.token);
    case NEW_PASSWORD_REQUEST_SUCCESS:
      return state.set('loading', false);
    case NEW_PASSWORD_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default recoverReducer;
