import { fromJS } from 'immutable';

import {
  REPORT_LIST_REQUEST,
  REPORT_LIST_REQUEST_SUCCESS,
  REPORT_LIST_REQUEST_FAIL,
  DISLIKE_REQUEST,
  DISLIKE_REQUEST_SUCCESS,
  DISLIKE_REQUEST_FAIL,
  LIKE_REQUEST,
  LIKE_REQUEST_SUCCESS,
  LIKE_REQUEST_FAIL,
  FIRE_REQUEST,
  FIRE_REQUEST_SUCCESS,
  FIRE_REQUEST_FAIL,
  CREATE_DISLIKE,
  CREATE_NEW_COMMENT_BLOCK,
  CHANGE_COMMENT_TEXT,
  HIDE_COMMENT_BLOCK,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAIL,
  CREATE_EDIT_COMMENT_BLOCK,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  CHANGE_EMAIL_MODALS,
  CHANGE_EMAIL_FLAGS,
  CHANGE_EMAIL_TEXT,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  CHANGE_REPORT_SHAPE,
  CHANGE_ORDER_REPORT_BY_RANK, REPORT_LIST_REQUEST_NO_CONTENT_ERROR
} from './constans';

const initialState = fromJS({
  comment: {
    forDislike: false,
    id: 0,
    loading: false,
    text: '',
    x: 0,
    y: 0,
    show: false,
    sectionId: 0,
    subsectionId: 0,
    selectedText: '',
    textRangeFrom: 0,
    textRangeTo: 0,
    forEdit: false
  },
  deleteComment: {
    id: 0,
    loading: false,
    sectionId: 0,
    subsectionId: 0
  },
  report: {
    id: 0,
    likesCount: 0,
    subsectionsCount: 0,
    createdAt: '',
    list: [],
    loading: true
  },
  dislike: {
    sectionId: 0,
    subsectionId: 0,
    prevState: 1
  },
  like: {
    sectionId: 0,
    subsectionId: 0,
    prevState: 1
  },
  fire: {
    sectionId: 0,
    subsectionId: 0,
    prevState: 1
  },
  reportModal: {
    email: '',
    flags: [],
    loading: false,
    modals: []
  },
  reportShape: -1,
  reportOrderByRank: false,
  noContentError: false
});

function individualReportReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_EMAIL_MODALS:
      return state.setIn(['reportModal', 'modals'], fromJS(action.modals));
    case CHANGE_EMAIL_FLAGS:
      return state.setIn(['reportModal', 'flags'], fromJS(action.flags));
    case CHANGE_EMAIL_TEXT:
      return state.setIn(['reportModal', 'email'], action.email);
    case SEND_EMAIL_REQUEST:
      return state.setIn(['reportModal', 'loading'], true);
    case SEND_EMAIL_SUCCESS:
      return state
        .setIn(['reportModal', 'email'], '')
        .setIn(['reportModal', 'flags'], fromJS([1]))
        .setIn(['reportModal', 'loading'], false)
        .setIn(['reportModal', 'modals'], fromJS([]));
    case SEND_EMAIL_FAIL:
      return state
        .setIn(['reportModal', 'email'], '')
        .setIn(['reportModal', 'flags'], fromJS([-1]))
        .setIn(['reportModal', 'loading'], false)
        .setIn(['reportModal', 'modals'], fromJS([]));
    case DELETE_COMMENT_REQUEST:
      return state
        .setIn(['deleteComment', 'id'], action.id)
        .setIn(['deleteComment', 'loading'], true)
        .setIn(['deleteComment', 'sectionId'], action.sectionId)
        .setIn(['deleteComment', 'subsectionId'], action.subsectionId);
    case DELETE_COMMENT_SUCCESS:
      return state
        .setIn(['comment', 'show'], false)
        .setIn(
          [
            'report',
            'list',
            state.getIn(['deleteComment', 'sectionId']).toString(),
            'subsections',
            state.getIn(['deleteComment', 'subsectionId']).toString(),
            'comments'
          ],
          state
            .getIn([
              'report',
              'list',
              state.getIn(['deleteComment', 'sectionId']).toString(),
              'subsections',
              state.getIn(['deleteComment', 'subsectionId']).toString(),
              'comments'
            ])
            .delete(state.getIn(['deleteComment', 'id']).toString())
        )
        .setIn(['deleteComment', 'id'], 0)
        .setIn(['deleteComment', 'loading'], false)
        .setIn(['deleteComment', 'sectionId'], 0)
        .setIn(['deleteComment', 'subsectionId'], 0);
    case DELETE_COMMENT_FAIL:
      return state
        .setIn(['comment', 'show'], false)
        .setIn(['deleteComment', 'id'], 0)
        .setIn(['deleteComment', 'loading'], false)
        .setIn(['deleteComment', 'sectionId'], 0)
        .setIn(['deleteComment', 'subsectionId'], 0);
    case CREATE_COMMENT_REQUEST:
      return state.setIn(['comment', 'loading'], true);
    case CREATE_COMMENT_SUCCESS:
      return state
        .setIn(
          [
            'report',
            'list',
            state.getIn(['comment', 'sectionId']).toString(),
            'subsections',
            state.getIn(['comment', 'subsectionId']).toString(),
            'comments'
          ],
          state
            .getIn([
              'report',
              'list',
              state.getIn(['comment', 'sectionId']).toString(),
              'subsections',
              state.getIn(['comment', 'subsectionId']).toString(),
              'comments'
            ])
            .set(
              action.commentId.toString(),
              fromJS({
                id: action.commentId,
                selected_text_range: {
                  lower: state.getIn(['comment', 'textRangeFrom']),
                  upper: state.getIn(['comment', 'textRangeTo'])
                },
                selected_text: state.getIn(['comment', 'selectedText']),
                comment: state.getIn(['comment', 'text']),
                subsection: state.getIn(['comment', 'subsectionId'])
              })
            )
        )
        .setIn(['comment', 'show'], false)
        .setIn(['comment', 'id'], 0)
        .setIn(['comment', 'text'], '')
        .setIn(['comment', 'sectionId'], 0)
        .setIn(['comment', 'subsectionId'], 0)
        .setIn(['comment', 'selectedText'], '')
        .setIn(['comment', 'textRangeFrom'], 0)
        .setIn(['comment', 'textRangeTo'], 0)
        .setIn(['comment', 'x'], 0)
        .setIn(['comment', 'y'], 0)
        .setIn(['comment', 'forDislike'], false)
        .setIn(['comment', 'loading'], false);
    case CREATE_COMMENT_FAIL:
      return state
        .setIn(['comment', 'show'], false)
        .setIn(['comment', 'id'], 0)
        .setIn(['comment', 'text'], '')
        .setIn(['comment', 'sectionId'], 0)
        .setIn(['comment', 'subsectionId'], 0)
        .setIn(['comment', 'selectedText'], '')
        .setIn(['comment', 'textRangeFrom'], 0)
        .setIn(['comment', 'textRangeTo'], 0)
        .setIn(['comment', 'x'], 0)
        .setIn(['comment', 'y'], 0)
        .setIn(['comment', 'forEdit'], false)
        .setIn(['comment', 'forDislike'], false)
        .setIn(['comment', 'loading'], false);
    case CREATE_EDIT_COMMENT_BLOCK:
      return state
        .setIn(['comment', 'id'], action.id)
        .setIn(['comment', 'text'], action.text)
        .setIn(['comment', 'x'], action.x)
        .setIn(['comment', 'y'], action.y)
        .setIn(['comment', 'sectionId'], action.sectionId)
        .setIn(['comment', 'subsectionId'], action.subsectionId)
        .setIn(['comment', 'selectedText'], action.selectedText)
        .setIn(['comment', 'textRangeFrom'], action.textRangeFrom)
        .setIn(['comment', 'textRangeTo'], action.textRangeTo)
        .setIn(['comment', 'loading'], false)
        .setIn(['comment', 'forEdit'], true)
        .setIn(['comment', 'forDislike'], false)
        .setIn(['comment', 'show'], true);
    case CREATE_DISLIKE:
      const createDislikeSubsection = state.getIn([
        'report',
        'list',
        action.sectionId.toString(),
        'subsections',
        action.subsectionId.toString()
      ]);

      return state
        .setIn(['comment', 'id'], 0)
        .setIn(['comment', 'text'], '')
        .setIn(['comment', 'x'], action.x)
        .setIn(['comment', 'y'], action.y)
        .setIn(['comment', 'sectionId'], action.sectionId)
        .setIn(['comment', 'subsectionId'], action.subsectionId)
        .setIn(['comment', 'selectedText'], action.selectedText)
        .setIn(['comment', 'textRangeFrom'], action.textRangeFrom)
        .setIn(['comment', 'textRangeTo'], action.textRangeTo)
        .setIn(['comment', 'loading'], false)
        .setIn(['comment', 'forEdit'], false)
        .setIn(['comment', 'forDislike'], true)
        .setIn(['dislike', 'sectionId'], action.sectionId)
        .setIn(['dislike', 'subsectionId'], action.subsectionId)
        .setIn(['dislike', 'prevState'], createDislikeSubsection.get('like_type'))
        .setIn(
          [
            'report',
            'list',
            action.sectionId.toString(),
            'subsections',
            action.subsectionId.toString(),
            'like_type'
          ],
          0
        )
        .setIn(['comment', 'show'], true);
    case CREATE_NEW_COMMENT_BLOCK:
      return state
        .setIn(['comment', 'id'], 0)
        .setIn(['comment', 'text'], '')
        .setIn(['comment', 'x'], action.x)
        .setIn(['comment', 'y'], action.y)
        .setIn(['comment', 'sectionId'], action.sectionId)
        .setIn(['comment', 'subsectionId'], action.subsectionId)
        .setIn(['comment', 'selectedText'], action.selectedText)
        .setIn(['comment', 'textRangeFrom'], action.textRangeFrom)
        .setIn(['comment', 'textRangeTo'], action.textRangeTo)
        .setIn(['comment', 'loading'], false)
        .setIn(['comment', 'forEdit'], false)
        .setIn(['comment', 'forDislike'], false)
        .setIn(['comment', 'show'], true);
    case CHANGE_COMMENT_TEXT:
      return state.setIn(['comment', 'text'], action.text);
    case HIDE_COMMENT_BLOCK:
      return state
        .setIn(['comment', 'show'], false)
        .setIn(['comment', 'id'], 0)
        .setIn(['comment', 'text'], '')
        .setIn(['comment', 'sectionId'], 0)
        .setIn(['comment', 'subsectionId'], 0)
        .setIn(['comment', 'selectedText'], '')
        .setIn(['comment', 'textRangeFrom'], 0)
        .setIn(['comment', 'textRangeTo'], 0)
        .setIn(['comment', 'x'], 0)
        .setIn(['comment', 'y'], 0)
        .setIn(['comment', 'forEdit'], false)
        .setIn(['comment', 'forDislike'], false)
        .setIn(['comment', 'loading'], false);
    case REPORT_LIST_REQUEST:
      return state.setIn(['report', 'loading'], true);
    case REPORT_LIST_REQUEST_SUCCESS:
      return state
        .setIn(['report', 'id'], action.id)
        .setIn(['report', 'coincidence'], action.coincidence)
        .setIn(['report', 'likesCount'], action.likesCount)
        .setIn(['report', 'subsectionsCount'], action.subsectionsCount)
        .setIn(['report', 'createdAt'], action.createdAt)
        .setIn(['report', 'list'], action.list)
        .setIn(['report', 'loading'], false);
    case REPORT_LIST_REQUEST_FAIL:
      return state.setIn(['report', 'loading'], false);
    case REPORT_LIST_REQUEST_NO_CONTENT_ERROR:
      return state.setIn(['report', 'noContentError'], true);
    case DISLIKE_REQUEST:
      const dislikeSubsection = state.getIn([
        'report',
        'list',
        action.sectionId.toString(),
        'subsections',
        action.subsectionId.toString()
      ]);

      return state
        .setIn(['dislike', 'sectionId'], action.sectionId)
        .setIn(['dislike', 'subsectionId'], action.subsectionId)
        .setIn(['dislike', 'prevState'], dislikeSubsection.get('like_type'))
        .setIn(
          [
            'report',
            'list',
            action.sectionId.toString(),
            'subsections',
            action.subsectionId.toString(),
            'like_type'
          ],
          0
        );
    case DISLIKE_REQUEST_SUCCESS:
      const newLikesCount =
        state.getIn(['dislike', 'prevState']) > 0
          ? state.getIn(['report', 'likesCount']) - 1
          : state.getIn(['report', 'likesCount']);
      return state
        .setIn(['dislike', 'sectionId'], 0)
        .setIn(['dislike', 'subsectionId'], 0)
        .setIn(['report', 'likesCount'], newLikesCount)
        .setIn(['dislike', 'prevState'], 1);
    case DISLIKE_REQUEST_FAIL:
      return state
        .setIn(
          [
            'report',
            'list',
            state.getIn(['dislike', 'sectionId']).toString(),
            'subsections',
            state.getIn(['dislike', 'subsectionId']).toString(),
            'like_type'
          ],
          state.getIn(['dislike', 'prevState'])
        )
        .setIn(['dislike', 'sectionId'], 0)
        .setIn(['dislike', 'subsectionId'], 0)
        .setIn(['dislike', 'prevState'], 1);
    case LIKE_REQUEST:
      const likeSubsection = state.getIn([
        'report',
        'list',
        action.sectionId.toString(),
        'subsections',
        action.subsectionId.toString()
      ]);

      return state
        .setIn(['like', 'sectionId'], action.sectionId)
        .setIn(['like', 'subsectionId'], action.subsectionId)
        .setIn(['like', 'prevState'], likeSubsection.get('like_type'))
        .setIn(
          [
            'report',
            'list',
            action.sectionId.toString(),
            'subsections',
            action.subsectionId.toString(),
            'like_type'
          ],
          1
        );
    case LIKE_REQUEST_SUCCESS:
      const likeRequestNewLikesCount =
        state.getIn(['like', 'prevState']) > 0
          ? state.getIn(['report', 'likesCount'])
          : state.getIn(['report', 'likesCount']) + 1;

      return state
        .setIn(['like', 'sectionId'], 0)
        .setIn(['like', 'subsectionId'], 0)
        .setIn(['report', 'likesCount'], likeRequestNewLikesCount)
        .setIn(['like', 'prevState'], 1);
    case LIKE_REQUEST_FAIL:
      return state
        .setIn(
          [
            'report',
            'list',
            state.getIn(['like', 'sectionId']).toString(),
            'subsections',
            state.getIn(['like', 'subsectionId']).toString(),
            'like_type'
          ],
          state.getIn(['like', 'prevState'])
        )
        .setIn(['like', 'sectionId'], 0)
        .setIn(['like', 'subsectionId'], 0)
        .setIn(['like', 'prevState'], 1);
    case FIRE_REQUEST:
      const fireSubsection = state.getIn([
        'report',
        'list',
        action.sectionId.toString(),
        'subsections',
        action.subsectionId.toString()
      ]);

      return state
        .setIn(['fire', 'sectionId'], action.sectionId)
        .setIn(['fire', 'subsectionId'], action.subsectionId)
        .setIn(['fire', 'prevState'], fireSubsection.get('like_type'))
        .setIn(
          [
            'report',
            'list',
            action.sectionId.toString(),
            'subsections',
            action.subsectionId.toString(),
            'like_type'
          ],
          2
        );
    case FIRE_REQUEST_SUCCESS:
      const fireRequestNewLikesCount =
        state.getIn(['fire', 'prevState']) > 0
          ? state.getIn(['report', 'likesCount'])
          : state.getIn(['report', 'likesCount']) + 1;

      return state
        .setIn(['fire', 'sectionId'], 0)
        .setIn(['fire', 'subsectionId'], 0)
        .setIn(['report', 'likesCount'], fireRequestNewLikesCount)
        .setIn(['fire', 'prevState'], 1);
    case FIRE_REQUEST_FAIL:
      return state
        .setIn(
          [
            'report',
            'list',
            state.getIn(['fire', 'sectionId']).toString(),
            'subsections',
            state.getIn(['fire', 'subsectionId']).toString(),
            'like_type'
          ],
          state.getIn(['fire', 'prevState'])
        )
        .setIn(['fire', 'sectionId'], 0)
        .setIn(['fire', 'subsectionId'], 0)
        .setIn(['fire', 'prevState'], 1);
    case CHANGE_REPORT_SHAPE:
      return state.setIn(['reportShape'], action.reportShape)
    case CHANGE_ORDER_REPORT_BY_RANK:
      return state.set('reportOrderByRank', action.order);
    default:
      return state;
  }
}

export default individualReportReducer;
