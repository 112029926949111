import { fromJS } from 'immutable';

import {
  GET_FINANCES_LIST,
  GET_FINANCES_LIST_SUCCESS,
  GET_FINANCES_LIST_FAIL,
  CHANGE_FINANCES_PAGE,
  CHANGE_DATE_FILTER,
  CHANGE_TYPE_FILTER
} from './constants';

const initialState = fromJS({
  finances: {
    count: 0,
    error: null,
    fromDateFilter: '',
    list: [],
    loading: true,
    page: 1,
    rewardAmountSum: 0,
    toDateFilter: '',
    typeFilter: ''
  }
});

function partnerFinancesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FINANCES_LIST:
      return state.setIn(['finances', 'loading'], true);
    case GET_FINANCES_LIST_SUCCESS:
      return state
        .setIn(['finances', 'count'], action.count)
        .setIn(['finances', 'list'], fromJS(action.list))
        .setIn(['finances', 'rewardAmountSum'], action.rewardAmountSum)
        .setIn(['finances', 'loading'], false);
    case GET_FINANCES_LIST_FAIL:
      return state
        .setIn(['finances', 'error'], action.error)
        .setIn(['finances', 'count'], 0)
        .setIn(['finances', 'list'], fromJS([]))
        .setIn(['finances', 'rewardAmountSum'], 0)
        .setIn(['finances', 'loading'], false);
    case CHANGE_FINANCES_PAGE:
      return state.setIn(['finances', 'page'], action.page);
    case CHANGE_DATE_FILTER:
      return state.setIn(['finances', action.field], action.text);
    case CHANGE_TYPE_FILTER:
      return state.setIn(['finances', 'typeFilter'], action.typeFilter);
    default:
      return state;
  }
}

export default partnerFinancesReducer;
