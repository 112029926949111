import styled from 'styled-components';

const P = styled.p`
  color: #6b778c;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

export default P;
