export const GET_COMPARE_LIST_REQUEST = 'hh/pages/compareList/GET_COMPARE_LIST_REQUEST';
export const GET_COMPARE_LIST_SUCCESS = 'hh/pages/compareList/GET_COMPARE_LIST_SUCCESS';
export const GET_COMPARE_LIST_FAIL = 'hh/pages/compareList/GET_COMPARE_LIST_FAIL';

export const GET_STAFF_LIST_REQUEST = 'hh/pages/compareList/GET_STAFF_LIST_REQUEST';
export const GET_STAFF_LIST_SUCCESS = 'hh/pages/compareList/GET_STAFF_LIST_SUCCESS';
export const GET_STAFF_LIST_FAIL = 'hh/pages/compareList/GET_STAFF_LIST_FAIL';

export const CHANGE_STAFF_SELECTOR = 'hh/pages/compareList/CHANGE_STAFF_SELECTOR';
export const CHANGE_STAFF_INPUT = 'hh/pages/compareList/CHANGE_STAFF_INPUT';
export const DELETE_STAFF_FROM_COMPARE = 'hh/pages/compareList/DELETE_STAFF_FROM_COMPARE';
export const ADD_STAFF_TO_COMPARE = 'hh/pages/compareList/ADD_STAFF_TO_COMPARE';

export const OPEN_ADD_MODAL = 'hh/pages/compareList/OPEN_ADD_MODAL';
export const CLOSE_ADD_MODAL = 'hh/pages/compareList/CLOSE_ADD_MODAL';

export const CHANGE_COLUMN_POSITION = 'hh/pages/compareList/CHANGE_COLUMN_POSITION';

export const DELETE_ID_FROM_COMPARE = 'hh/pages/compareList/DELETE_ID_FROM_COMPARE';
