import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';
import queryString from 'query-string';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { colors } from '@atlaskit/theme';
import { AutoDismissFlag as Flag, FlagGroup } from '@atlaskit/flag';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import TrayIcon from '@atlaskit/icon/glyph/tray';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import QueuesIcon from '@atlaskit/icon/glyph/queues';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { noun } from 'plural-ru';
import CompanyName from 'navigation/components/CompanyName';
import LinkItem from 'navigation/components/LinkItem';
import ViewRegistrar from 'navigation/components/ViewRegistrar';
import Modal from 'components/Modal';
import TextField from 'components/TextField';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/Home/reducer';
import saga from 'pages/Home/userSaga';

import videoReducer from 'pages/Video/reducer';
import videoSaga from 'pages/Video/saga';

import {
  addsReports,
  changeAddsFlag,
  changeAddsModal,
  changeAddsReportsCount,
  changeWarningFlag,
  checkOrder,
  getUserInfo,
  sendToPaymentPage
} from 'pages/Home/actions';

import { videosList } from 'pages/Video/actions';

import {
  makeSelectAddsFlag,
  makeSelectAddsModal,
  makeSelectAddsReportsCount,
  makeSelectAddsReportsResult,
  makeSelectCompany,
  makeSelectUnreadReportsCount,
  makeSelectLoadingAddsReports,
  makeSelectUser,
  makeSelectUserLoading,
  makeSelectWarningFlag
} from 'pages/Home/selectors';

import { makeSelectVideoList, makeSelectVideoLoading } from 'pages/Video/selectors';

import Form from './styled/Form';
import P from './styled/P';
import Rouble from './styled/Rouble';
import Text from './styled/Text';
import Total from './styled/Total';
import VideoIcon from './styled/VideoIcon';
import ConnectedLinkItem from "../../components/ConnectedLinkItem";

class RootViews extends Component {
  state = {
    doNotShowFlag: false,
    totalReports: this.props.company.billing_plan.price_per_report_1000 || null
  };

  componentDidMount() {
    const getParams = queryString.parse(this.props.location.search);
    const { getVideosList, videoList } = this.props;
    if (!isUndefined(getParams.orderId)) {
      this.setState({
        doNotShowFlag: true
      });
    }

    const reportsCount = this.props.match.params.reports;
    if (!isUndefined(reportsCount)) {
      const url = this.props.match.url.split('/');
      url.pop();
      this.props.checkOrder({
        addsReportsResult: reportsCount,
        orderId: getParams.orderId
      });
      this.props.history.push(url.join('/'));
    }

    if (this.props.user.get('pk') === 0 || this.props.company.id === 0) {
      this.props.getUserInfo();
    }

    if (videoList.length === 0) {
      getVideosList();
    }
  }

  removeFlag = () => {
    this.props.changeWarningFlag({
      warningFlag: false
    });
  };

  changeAddsReportsCount = event => {
    this.props.changeAddsReportsCount({
      count: event.target.value
    });
  };

  render() {
     const rootIndex = fromJS([
      {
        id: 'root/index:header',
        items: [
          {
            type: 'InlineComponent',
            component: CompanyName,
            id: 'company-name',
            text: this.props.user.get('company_name')
          }
        ],
        type: 'Section'
      },
      {
        id: 'root/index:menu',
        items: [
          {
            type: 'InlineComponent',
            component: LinkItem,
            before: PeopleIcon,
            id: 'invites',
            text: 'Приглашения',
            to: '/invites'
          },
          {
            type: 'InlineComponent',
            component: ConnectedLinkItem,
            id: 'personal',
            text: 'Отчеты',
            before: QueuesIcon,
            to: '/person/list',
            badge: this.props.unreadReports
          },
          {
            type: 'InlineComponent',
            component: LinkItem,
            id: 'profiles',
            text: 'Профили',
            before: TrayIcon,
            to: '/profile/list'
          },
          {
            goTo: 'root/videos',
            id: 'videos',
            text: 'Обучающие видео',
            before: VideoIcon,
            type: 'GoToItem'
          }
        ],
        nestedGroupKey: 'menu',
        parentId: null,
        type: 'Section'
      }
    ]);
    const settingsProject = [
      {
        id: 'root/settings:header',
        items: [
          {
            type: 'InlineComponent',
            component: CompanyName,
            id: 'company-name',
            text: this.props.user.get('company_name')
          },
          {
            id: 'back-button',
            items: [
              {
                goTo: 'root/index',
                id: 'back',
                text: 'Настройки',
                type: 'BackItem'
              }
            ],
            type: 'Group'
          }
        ],
        type: 'Section'
      },
      {
        id: 'root/settings:menu',
        nestedGroupKey: 'menu',
        parentId: 'root/index:menu',
        type: 'Section',
        items: [
          {
            before: OfficeBuildingIcon,
            id: 'organizations',
            text: 'Организации',
            to: '/settings/organizations/list',
            type: 'InlineComponent',
            component: LinkItem
          },
          {
            before: QueuesIcon,
            id: 'users',
            text: 'Пользователи',
            to: '/settings/users',
            type: 'InlineComponent',
            component: LinkItem
          },
          {
            before: CreditcardIcon,
            id: 'tariffs',
            text: 'Тарифы',
            to: '/settings/billing',
            type: 'InlineComponent',
            component: LinkItem
          }
        ]
      }
    ];
    const videosProject = [
      {
        id: 'root/videos:header',
        items: [
          {
            type: 'InlineComponent',
            component: CompanyName,
            id: 'company-name',
            text: this.props.user.get('company_name')
          },
          {
            id: 'back-button',
            items: [
              {
                goTo: 'root/index',
                id: 'back',
                text: 'Обучающие видео',
                type: 'BackItem'
              }
            ],
            type: 'Group'
          }
        ],
        type: 'Section'
      },
      {
        id: 'root/videos:menu',
        nestedGroupKey: 'menu',
        parentId: 'root/index:menu',
        type: 'Section',
        items: this.props.videoList.map(section => ({
          before: VideoIcon,
          id: section.id,
          text: section.name,
          to: `/videos/${section.id}`,
          type: 'InlineComponent',
          component: LinkItem
        }))
      }
    ];
    let rootViews = [
      { id: 'root/index', getItems: () => rootIndex.toJS(), type: 'product' },
      {
        id: 'root/videos',
        getItems: () => videosProject,
        type: 'product'
      }
    ];

    if (this.props.user.get('role') === '0') {
      rootViews = [
        {
          id: 'root/index',
          getItems: () =>
            rootIndex
              .setIn(
                [rootIndex.findIndex(item => item.get('id') === 'root/index:menu'), 'items'],
                rootIndex
                  .getIn([
                    rootIndex.findIndex(item => item.get('id') === 'root/index:menu'),
                    'items'
                  ])
                  .push({
                    goTo: 'root/settings',
                    id: 'settings',
                    text: 'Настройки',
                    before: SettingsIcon,
                    type: 'GoToItem'
                  })
              )
              .toJS(),
          type: 'product'
        },
        {
          id: 'root/videos',
          getItems: () => videosProject,
          type: 'product'
        },
        {
          id: 'root/settings',
          getItems: () => settingsProject,
          type: 'product'
        }
      ];
    }

    if (this.props.loading || this.props.videoLoading || this.props.user.get('pk') === 0) {
      return <div />;
    }

    let flags = [];
    let actions = [];
    if (this.props.warningFlag) {
      flags = [1];
    } else {
      flags = [];
    }

    if (this.props.location.pathname !== '/settings/billing') {
      actions = [
        {
          content: 'Перейти на страницу тарифов',
          onClick: () => {
            this.props.history.push('/settings/billing');
          }
        }
      ];
    }

    let totalReports = 0;
    if (this.props.company.id !== 0) {
      const { addsReportsCount } = this.props;

      this.props.company.billing_plan.ranges.forEach(range => {
        if (
          addsReportsCount >= range.quantity_range.lower &&
          addsReportsCount < range.quantity_range.upper
        ) {
          totalReports = addsReportsCount * range.price_per_report;
        } else if (
          isNull(range.quantity_range.upper) &&
          addsReportsCount >= range.quantity_range.lower
        ) {
          totalReports = addsReportsCount * range.price_per_report;
        }
      });
    }

    return (
      <Fragment>
        {rootViews.map(view => (
          <ViewRegistrar key={view.id} view={view} />
        ))}

        {this.props.addsModal.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Купить дополнительные отчеты"
            onClose={() =>
              this.props.changeAddsModal({
                addsModal: []
              })
            }
            actions={[
              {
                isLoading: this.props.loadingAdds,
                onClick: () =>
                  this.props.sendToPaymentPage({
                    totalReports,
                    url: this.props.match.url
                  }),
                text: 'Купить'
              },
              {
                text: 'Отменить',
                onClick: () =>
                  this.props.changeAddsModal({
                    addsModal: []
                  })
              }
            ]}
            width="small"
          >
            {this.props.match.url !== '/settings/billing' && (
              <p>
                Доступные вам отчеты закончились. Купите дополнительные отчеты или перейдите на{' '}
                <Link to="/settings/billing">другой тариф</Link>.
              </p>
            )}
            <Form>
              <TextField
                isInvalid={false}
                required
                label="Количество покупаемых отчетов"
                name="number"
                mask="9999999999"
                maskChar=""
                onBlur={this.changeAddsReportsCount}
                onChange={this.changeAddsReportsCount}
                placeholder=""
                value={this.props.addsReportsCount}
                shouldFitContainer
              />
              <Text>
                <P>Стоимость отчетов:</P>
                {this.props.company.billing_plan.ranges.map(range => {
                  if (isNull(range.quantity_range.upper)) {
                    return (
                      <P key={range.quantity_range.lower}>
                        от {range.quantity_range.lower.toLocaleString()} —{' '}
                        {range.price_per_report.toLocaleString()}{' '}
                        <Rouble fontWeight={300}>₽</Rouble> за отчет;
                      </P>
                    );
                  }

                  return (
                    <P key={range.quantity_range.lower}>
                      от {range.quantity_range.lower.toLocaleString()} до{' '}
                      {(parseInt(range.quantity_range.upper, 10) - 1).toLocaleString()} —{' '}
                      {range.price_per_report.toLocaleString()} <Rouble fontWeight={300}>₽</Rouble>{' '}
                      за отчет;
                    </P>
                  );
                })}
              </Text>
              <Total>
                Итого: {totalReports.toLocaleString()} <Rouble>₽</Rouble>
              </Total>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={() => this.props.changeAddsFlag({ flag: [] })}>
          {this.props.addsFlag.map(flag => (
            <Flag
              description=""
              icon={
                flag === 1 ? (
                  <CheckCircleIcon label="Успех" primaryColor={colors.G400} />
                ) : (
                  <CrossCircleIcon label="Ошибка" primaryColor={colors.R400} />
                )
              }
              id={flag}
              isDismissAllowed
              key={flag}
              title={
                flag === 1
                  ? `Вы купили ${noun(
                      this.props.addsReportsResult,
                      '%d отчет',
                      '%d отчета',
                      '%d отчетов'
                    )}`
                  : 'Ошибка при покупке отчетов'
              }
            />
          ))}
        </FlagGroup>

        {!this.state.doNotShowFlag && (
          <FlagGroup onDismissed={this.removeFlag}>
            {flags.map(flag => (
              <Flag
                actions={actions}
                description="Завтра истекает срок действия вашего тарифа. Неизрасходованные отчеты сгорят. Отчеты пользователей сохранятся. Продлите тариф или выберите другой чтобы получить новые отчеты."
                icon={<WarningIcon label="Внимание" primaryColor={colors.Y200} />}
                id={flag}
                isDismissAllowed
                key={flag}
                title="Внимание"
              />
            ))}
          </FlagGroup>
        )}
      </Fragment>
    );
  }
}

function mapDispatchToPropsRoot(dispatch) {
  return {
    addsReports: value => dispatch(addsReports(value)),
    changeAddsFlag: value => dispatch(changeAddsFlag(value)),
    changeAddsModal: value => dispatch(changeAddsModal(value)),
    changeAddsReportsCount: value => dispatch(changeAddsReportsCount(value)),
    changeWarningFlag: value => dispatch(changeWarningFlag(value)),
    checkOrder: value => dispatch(checkOrder(value)),
    getUserInfo: () => dispatch(getUserInfo()),
    getVideosList: () => dispatch(videosList()),
    sendToPaymentPage: value => dispatch(sendToPaymentPage(value))
  };
}

const mapStateToPropsRoot = createStructuredSelector({
  addsFlag: makeSelectAddsFlag(),
  addsModal: makeSelectAddsModal(),
  addsReportsCount: makeSelectAddsReportsCount(),
  addsReportsResult: makeSelectAddsReportsResult(),
  company: makeSelectCompany(),
  unreadReports: makeSelectUnreadReportsCount(),
  loading: makeSelectUserLoading(),
  loadingAdds: makeSelectLoadingAddsReports(),
  user: makeSelectUser(),
  videoList: makeSelectVideoList(),
  videoLoading: makeSelectVideoLoading(),
  warningFlag: makeSelectWarningFlag()
});

const withConnectRoot = connect(
  mapStateToPropsRoot,
  mapDispatchToPropsRoot
);

const withReducerRoot = injectReducer({ key: 'staff', reducer });
const withSagaRoot = injectSaga({ key: 'user', saga });

const withVideoReducer = injectReducer({ key: 'video', reducer: videoReducer });
const withVideoSaga = injectSaga({ key: 'video', saga: videoSaga });

export default compose(
  withRouter,
  withReducerRoot,
  withVideoReducer,
  withSagaRoot,
  withVideoSaga,
  withConnectRoot
)(RootViews);
