export const PROFILE_LIST_REQUEST = 'hh/pages/profile/PROFILE_LIST_REQUEST';
export const PROFILE_LIST_FILTER_REQUEST = 'hh/pages/profile/PROFILE_LIST_FILTER_REQUEST';
export const PROFILE_LIST_REQUEST_SUCCESS = 'hh/pages/profile/PROFILE_LIST_REQUEST_SUCCESS';
export const PROFILE_LIST_REQUEST_FAIL = 'hh/pages/profile/PROFILE_LIST_REQUEST_FAIL';

export const PROFILE_READ_REQUEST = 'hh/pages/profile/PROFILE_READ_REQUEST';
export const PROFILE_READ_REQUEST_SUCCESS = 'hh/pages/profile/PROFILE_READ_REQUEST_SUCCESS';
export const PROFILE_READ_REQUEST_FAIL = 'hh/pages/profile/PROFILE_READ_REQUEST_FAIL';

export const CHANGE_REPORTS_NAME = 'hh/pages/profile/CHANGE_REPORTS_NAME';
export const CHANGE_REPORTS_PAGE = 'hh/pages/profile/CHANGE_REPORTS_PAGE';
export const CHANGE_REPORTS_SORT = 'hh/pages/profile/CHANGE_REPORTS_SORT';

export const PROFILE_REPORTS_REQUEST = 'hh/pages/profile/PROFILE_REPORTS_REQUEST';
export const PROFILE_REPORTS_REQUEST_SUCCESS = 'hh/pages/profile/PROFILE_REPORTS_REQUEST_SUCCESS';
export const PROFILE_REPORTS_REQUEST_FAIL = 'hh/pages/profile/PROFILE_REPORTS_REQUEST_FAIL';

export const CREATE_COMPARE_REQUEST = 'hh/pages/profile/CREATE_COMPARE_REQUEST';
export const CREATE_COMPARE_REQUEST_SUCCESS = 'hh/pages/profile/CREATE_COMPARE_REQUEST_SUCCESS';
export const CREATE_COMPARE_REQUEST_FAIL = 'hh/pages/profile/CREATE_COMPARE_REQUEST_FAIL';

export const PROFILE_CREATE_FROM_SAMPLE_REQUEST =
  'hh/pages/profile/PROFILE_CREATE_FROM_SAMPLE_REQUEST';
export const PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS =
  'hh/pages/profile/PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS';
export const PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL =
  'hh/pages/profile/PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL';

export const CHANGE_STAFF_INPUT = 'hh/pages/profile/CHANGE_STAFF_INPUT';
export const STAFF_LIST_REQUEST = 'hh/pages/profile/STAFF_LIST_REQUEST';
export const STAFF_LIST_REQUEST_SUCCESS = 'hh/pages/profile/STAFF_LIST_REQUEST_SUCCESS';
export const STAFF_LIST_REQUEST_FAIL = 'hh/pages/profile/STAFF_LIST_REQUEST_FAIL';

export const CHANGE_STAFF_POSITIVE_INPUT = 'hh/pages/profile/CHANGE_STAFF_POSITIVE_INPUT';
export const POSITIVE_STAFF_LIST_REQUEST = 'hh/pages/profile/POSITIVE_STAFF_LIST_REQUEST';
export const POSITIVE_STAFF_LIST_REQUEST_SUCCESS =
  'hh/pages/profile/POSITIVE_STAFF_LIST_REQUEST_SUCCESS';
export const POSITIVE_STAFF_LIST_REQUEST_FAIL = 'hh/pages/profile/POSITIVE_STAFF_LIST_REQUEST_FAIL';

export const CHANGE_STAFF_NEGATIVE_INPUT = 'hh/pages/profile/CHANGE_STAFF_NEGATIVE_INPUT';
export const NEGATIVE_STAFF_LIST_REQUEST = 'hh/pages/profile/NEGATIVE_STAFF_LIST_REQUEST';
export const NEGATIVE_STAFF_LIST_REQUEST_SUCCESS =
  'hh/pages/profile/NEGATIVE_STAFF_LIST_REQUEST_SUCCESS';
export const NEGATIVE_STAFF_LIST_REQUEST_FAIL = 'hh/pages/profile/NEGATIVE_STAFF_LIST_REQUEST_FAIL';

export const CHANGE_NAME_CREATE_FROM_SAMPLE = 'hh/pages/profile/CHANGE_NAME_CREATE_FROM_SAMPLE';
export const CHANGE_DESC_CREATE_FROM_SAMPLE = 'hh/pages/profile/CHANGE_DESC_CREATE_FROM_SAMPLE';
export const CHANGE_NEGATIVE_CREATE_FROM_SAMPLE =
  'hh/pages/profile/CHANGE_NEGATIVE_CREATE_FROM_SAMPLE';
export const CHANGE_POSITIVE_CREATE_FROM_SAMPLE =
  'hh/pages/profile/CHANGE_POSITIVE_CREATE_FROM_SAMPLE';
export const CHANGE_NEGATIVE_CREATE_FORM_INPUT =
  'hh/pages/profile/CHANGE_NEGATIVE_CREATE_FORM_INPUT';
export const CHANGE_POSITIVE_CREATE_FORM_INPUT =
  'hh/pages/profile/CHANGE_POSITIVE_CREATE_FORM_INPUT';

export const CHANGE_NAME_CREATE_FROM_QUESTIONS =
  'hh/pages/profile/CHANGE_NAME_CREATE_FROM_QUESTIONS';
export const CHANGE_DESC_CREATE_FROM_QUESTIONS =
  'hh/pages/profile/CHANGE_DESC_CREATE_FROM_QUESTIONS';
export const CHANGE_ANSWER_CREATE_FROM_QUESTIONS =
  'hh/pages/profile/CHANGE_ANSWER_CREATE_FROM_QUESTIONS';

export const CHANGE_PATTERN_NAME = 'hh/pages/profile/CHANGE_PATTERN_NAME';
export const CHANGE_PATTERN_DESC = 'hh/pages/profile/CHANGE_PATTERN_DESC';
export const CHANGE_PATTERN_RANGE_LOWER = 'hh/pages/profile/CHANGE_PATTERN_RANGE_LOWER';
export const CHANGE_PATTERN_RANGE_UPPER = 'hh/pages/profile/CHANGE_PATTERN_RANGE_UPPER';
export const CHANGE_PATTERN_EDIT_CRITICAL = 'hh/pages/profile/CHANGE_PATTERN_EDIT_CRITICAL';

export const PROFILE_UPDATE_REQUEST = 'hh/pages/profile/PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_REQUEST_SUCCESS = 'hh/pages/profile/PROFILE_UPDATE_REQUEST_SUCCESS';
export const PROFILE_UPDATE_REQUEST_FAIL = 'hh/pages/profile/PROFILE_UPDATE_REQUEST_FAIL';

export const QUESTIONS_LIST_REQUEST = 'hh/pages/profile/QUESTIONS_LIST_REQUEST';
export const QUESTIONS_LIST_REQUEST_SUCCESS = 'hh/pages/profile/QUESTIONS_LIST_REQUEST_SUCCESS';
export const QUESTIONS_LIST_REQUEST_FAIL = 'hh/pages/profile/QUESTIONS_LIST_REQUEST_FAIL';

export const PROFILE_CREATE_FROM_QUESTIONS_REQUEST =
  'hh/pages/profile/PROFILE_CREATE_FROM_QUESTIONS_REQUEST';
export const PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS =
  'hh/pages/profile/PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS';
export const PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL =
  'hh/pages/profile/PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL';

export const CHANGE_PATTERN_CREATED_RANGE_LOWER =
  'hh/pages/profile/CHANGE_PATTERN_CREATED_RANGE_LOWER';
export const CHANGE_PATTERN_CREATED_RANGE_UPPER =
  'hh/pages/profile/CHANGE_PATTERN_CREATED_RANGE_UPPER';
export const CHANGE_PATTERN_CREATED_CRITICAL = 'hh/pages/profile/CHANGE_PATTERN_CREATED_CRITICAL';
export const CHANGE_PROFILE_FOR_UPDATE = 'hh/pages/profile/CHANGE_PROFILE_FOR_UPDATE';

export const DELETE_PROFILE_REQUEST = 'hh/pages/profile/DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_REQUEST_SUCCESS = 'hh/pages/profile/DELETE_PROFILE_REQUEST_SUCCESS';
export const DELETE_PROFILE_REQUEST_FAIL = 'hh/pages/profile/DELETE_PROFILE_REQUEST_FAIL';

export const CHANGE_PATTERN_CUSTOM_RANGE_LOWER =
  'hh/pages/profile/CHANGE_PATTERN_CUSTOM_RANGE_LOWER';
export const CHANGE_PATTERN_CUSTOM_RANGE_UPPER =
  'hh/pages/profile/CHANGE_PATTERN_CUSTOM_RANGE_UPPER';
export const CHANGE_PROFILE_NAME_CUSTOM = 'hh/pages/profile/CHANGE_PROFILE_NAME_CUSTOM';
export const CHANGE_PROFILE_DESC_CUSTOM = 'hh/pages/profile/CHANGE_PROFILE_DESC_CUSTOM';
export const CHANGE_PATTERN_CRITICAL = 'hh/pages/profile/CHANGE_PATTERN_CRITICAL';

export const META_PROGRAMS_LIST_REQUEST = 'hh/pages/profile/META_PROGRAMS_LIST_REQUEST';
export const META_PROGRAMS_LIST_REQUEST_SUCCESS =
  'hh/pages/profile/META_PROGRAMS_LIST_REQUEST_SUCCESS';
export const META_PROGRAMS_LIST_REQUEST_FAIL = 'hh/pages/profile/META_PROGRAMS_LIST_REQUEST_FAIL';

export const PROFILE_CREATE_CUSTOM_REQUEST = 'hh/pages/profile/PROFILE_CREATE_CUSTOM_REQUEST';
export const PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS =
  'hh/pages/profile/PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS';
export const PROFILE_CREATE_CUSTOM_REQUEST_FAIL =
  'hh/pages/profile/PROFILE_CREATE_CUSTOM_REQUEST_FAIL';

export const CHANGE_NAME_FILTER = 'hh/pages/profile/CHANGE_NAME_FILTER';
export const CHANGE_MANAGERS_FILTER = 'hh/pages/profile/CHANGE_MANAGERS_FILTER';
export const CHANGE_PROFILE_PAGE = 'hh/pages/profile/CHANGE_PROFILE_PAGE';
export const CHANGE_PROFILE_SORT = 'hh/pages/profile/CHANGE_PROFILE_SORT';

export const LOADING_TABLE_END = 'hh/pages/profile/LOADING_TABLE_END';

export const CHANGE_PROFILE_CHIEF_NAME = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_NAME';
export const CHANGE_PROFILE_CHIEF_DESC = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_DESC';
export const CHANGE_PROFILE_CHIEF_EMAIL = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_EMAIL';
export const CHANGE_PROFILE_CHIEF_FLAGS = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_FLAGS';
export const CHANGE_PROFILE_CHIEF_FULL_NAME = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_FULL_NAME';
export const CHANGE_PROFILE_CHIEF_MESSAGE = 'hh/pages/profile/CHANGE_PROFILE_CHIEF_MESSAGE';

export const SEND_PROFILE_CHIEF_REQUEST = 'hh/pages/profile/SEND_PROFILE_CHIEF_REQUEST';
export const SEND_PROFILE_CHIEF_REQUEST_SUCCESS =
  'hh/pages/profile/SEND_PROFILE_CHIEF_REQUEST_SUCCESS';
export const SEND_PROFILE_CHIEF_REQUEST_FAIL = 'hh/pages/profile/SEND_PROFILE_CHIEF_REQUEST_FAIL';

export const CHANGE_PROFILE_EMAIL_FLAGS = 'hh/pages/profile/CHANGE_PROFILE_EMAIL_FLAGS';
export const CHANGE_PROFILE_EMAIL_MODALS = 'hh/pages/profile/CHANGE_PROFILE_EMAIL_MODALS';
export const CHANGE_PROFILE_EMAIL_ADDRESS = 'hh/pages/profile/CHANGE_PROFILE_EMAIL_ADDRESS';

export const SEND_PROFILE_EMAIL_REQUEST = 'hh/pages/profile/SEND_PROFILE_EMAIL_REQUEST';
export const SEND_PROFILE_EMAIL_REQUEST_SUCCESS =
  'hh/pages/profile/SEND_PROFILE_EMAIL_REQUEST_SUCCESS';
export const SEND_PROFILE_EMAIL_REQUEST_FAIL = 'hh/pages/profile/SEND_PROFILE_EMAIL_REQUEST_FAIL';
