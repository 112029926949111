import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog, { ModalTransition } from 'components/ModalDialog';
import Footer from './Footer';

class CustomModal extends React.Component {
  onClose = () => {
    this.props.onClose(this.props.id);
  };

  render() {
    return (
      <ModalTransition>
        <ModalDialog
          heading={this.props.heading}
          footer={Footer}
          onClose={this.onClose}
          width={this.props.width ? this.props.width : 'medium'}
        >
          {this.props.children}
        </ModalDialog>
      </ModalTransition>
    );
  }
}

CustomModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  id: PropTypes.number,
  heading: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.number
};

export default CustomModal;
