import styled from 'styled-components';

const ButtonWrapper = styled.div`
  margin-top: 43px;

  @media (max-width: 1020px) {
    margin-top: 20px;
  }
`;

export default ButtonWrapper;
