import styled from 'styled-components';

const Total = styled.p`
  color: #172b4d;
  font-size: 20px;
  letter-spacing: -0.34px;
  line-height: 24px;
  margin-top: 25px;
`;

export default Total;
