import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@atlaskit/icon/glyph/info';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import BillingIcon from '@atlaskit/icon/glyph/billing';

import LinkItem from 'navigation/components/LinkItem/index';
import ViewRegistrar from 'navigation/components/ViewRegistrar/index';

const ContainerViews = props => {
  const containerProject = [
    {
      id: 'partner/client:header',
      items: [
        {
          id: 'back-button',
          items: [
            {
              type: 'BackItem',
              goTo: 'partner/index',
              text: props.clientName,
              id: 'back'
            }
          ],
          type: 'Group'
        }
      ],
      type: 'HeaderSection'
    },
    {
      id: 'partner/client:menu',
      nestedGroupKey: 'menu',
      items: [
        {
          before: InfoIcon,
          id: 'information',
          text: 'Информация',
          to: `/partner/clients/${props.clientId}`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: PeopleGroupIcon,
          id: 'managers',
          text: 'Менеджеры',
          to: `/partner/clients/${props.clientId}/managers`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: OfficeBuildingIcon,
          id: 'requisites',
          text: 'Реквизиты',
          to: `/partner/clients/${props.clientId}/requisites`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: BillingIcon,
          id: 'financials',
          text: 'Фин. операции',
          to: `/partner/clients/${props.clientId}/finances`,
          type: 'InlineComponent',
          component: LinkItem
        }
      ],
      type: 'Section'
    }
  ];
  const editedView = [
    {
      id: `partner/client/${props.clientId}`,
      getItems: () => containerProject,
      type: 'container'
    }
  ];

  return (
    <Fragment>
      {editedView.map(view => (
        <ViewRegistrar key={view.id} view={view} />
      ))}
    </Fragment>
  );
};

ContainerViews.propTypes = {
  clientId: PropTypes.number,
  clientName: PropTypes.string
};

export default ContainerViews;
