import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ContainerViews from 'navigation/routes/Partner/Partners/ContainerView';
import RootViews from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import injectReducer from 'utils/injectReducer';
import reducer from 'pages/PartnerProfile/Partners/reducer';
import InformationPage from 'pages/PartnerProfile/Partners/View/Information';

import {
  makeSelectPartnerLoading,
  makeSelectPartnerRead
} from 'pages/PartnerProfile/Partners/selectors';

class PartnerInformationBase extends Component {
  render() {
    const id = parseInt(this.props.match.params.id, 10);
    const { partner, loading } = this.props;

    let container = <div />;

    if (!loading && partner.id !== 0) {
      container = (
        <ContainerViews
          partnerId={partner.id}
          partnerEmail={partner.email}
          partnerName={partner.full_name}
        />
      );
    }

    return (
      <Fragment>
        <RootViews />
        {container}
        <SetActiveView id={`partner/partners/${id}`} />
        <InformationPage />
      </Fragment>
    );
  }
}

PartnerInformationBase.propTypes = {
  partner: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  partner: makeSelectPartnerRead(),
  loading: makeSelectPartnerLoading()
});

const withConnect = connect(mapStateToProps);
const withReducer = injectReducer({ key: 'partnerPartners', reducer });

export default compose(
  withRouter,
  withReducer,
  withConnect
)(PartnerInformationBase);
