import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import uniqid from 'uniqid';
import * as moment from 'moment';
import 'moment/locale/ru';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Form from '@atlaskit/form';
import TextField from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';

import Modal from 'components/Modal';
import Span from 'components/Span';

import Button from './styled/Button';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flag from './styled/Flag';
import Line from './styled/Line';
import NameWrapper from './styled/NameWrapper';
import Text from './styled/Text';
import TriggerButton from './styled/TriggerButton';
import ReadIndicator from './styled/ReadIndicator';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'is_read_report',
        content: ''
      },
      {
        key: 'full_name',
        content: 'Имя',
        isSortable: true,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'party',
        content: 'Email',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'testing_date',
        content: 'Дата тестирования',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'content',
        content: 'HR-менеджер',
        shouldTruncate: true,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'state',
        content: 'Состояние отчета',
        shouldTruncate: true,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class Table extends React.Component {
  state = {
    deleteModals: [],
    sendModals: [],
    flags: [],
    sendFlags: []
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      deleteModals: this.state.deleteModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  closeModal = id => {
    this.setState({ deleteModals: this.state.deleteModals.filter(i => i !== id) });
  };

  closeEditModal = id => {
    this.setState({ sendModals: this.state.sendModals.filter(i => i !== id) });
  };

  addSendFlag = id => {
    this.setState({
      sendFlags: [this.state.flags.length, ...this.state.flags],
      sendModals: this.state.sendModals.filter(i => i !== id)
    });
  };

  removeSendFlag = id => this.setState({ sendFlags: this.state.sendFlags.filter(v => v !== id) });

  onRemoveStaff = id => {
    this.props.onDelete({
      staffId: id
    });
    this.addFlag(id);
  };

  onCompareClick = id => {
    this.props.onCompare({
      id
    });
  };

  onCancelCompareClick = id => {
    this.props.onDeleteCompare({
      id
    });
  };

  renderCompareLine = (id, isAvailable) => {
    const { compareIds } = this.props;

    if (!isAvailable) {
      return null;
    }

    if (!isUndefined(compareIds.toJS().find((el) => el === id))) {
      return (
        <DropdownItem onClick={() => this.onCancelCompareClick(id)}>
          Убрать из сравнения
        </DropdownItem>
      );
    }

    return (
      <DropdownItem onClick={() => this.onCompareClick(id)}>Добавить к сравнению</DropdownItem>
    );
  };

  render() {
    const data = isNull(this.props.data) ? [] : this.props.data;
    const rowsTable = data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: (<>{!item.is_report_read ? <ReadIndicator/> : ''}</>),
          width: 20
        },
        {
          key: createKey(item.full_name),
          content: (
            <NameWrapper>
              <Link to={`/person/report/${item.id}`}>{item.full_name}</Link>
            </NameWrapper>
          )
        },
        {
          key: createKey(item.email),
          content: item.email
        },
        {
          key: createKey(item.testing_date),
          content: item.testing_date ? moment(item.testing_date).format('DD.MM.YYYY') : ''
        },
        {
          key: createKey(item.inviter.full_name),
          content: item.inviter.full_name
        },
        {
          key: uniqid(),
          content: item.is_available ? 'Доступен' : <Span color="#FF3E12">Недоступен</Span>
        },
        {
          content: (
            <DropdownMenu trigger={TriggerButton} position="bottom right">
              <DropdownItemGroup>
                {this.renderCompareLine(item.id, item.is_available)}
                <DropdownItem
                  onClick={() =>
                    this.setState({
                      deleteModals: [item.id]
                    })
                  }
                >
                  Удалить
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )
        }
      ]
    }));

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          paginationi18n={{ prev: 'Предыдущая', next: 'Следующая' }}
          rows={rowsTable}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {this.state.deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить сотрудника"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Удалить',
                onClick: this.onRemoveStaff
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
          >
            <p>Уверены, что хотите удалить сотрудника?</p>
          </Modal>
        ))}

        {this.state.sendModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Отправить тест"
            onClose={this.closeEditModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.addSendFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeEditModal
              }
            ]}
            width="small"
          >
            <p>Отправьте или скопируйте ссылку</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup layout="column" form="">
                <Field width="65%">
                  <TextField
                    label="Скопируйте ссылку"
                    shouldFitContainer
                    name="link"
                    placeholder=""
                    isReadOnly
                    value="http://profgrowth.com/test/12345t"
                  />
                </Field>
                <Field width="32%" alignSelf="flex-end">
                  <Button className="allWidth">Скопировать</Button>
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Line />
                <Text>или</Text>
                <Line />
              </FieldGroup>
              <FieldGroup layout="column" form="">
                <Field width="100%">
                  <TextField
                    label="Отправьте тестирование"
                    shouldFitContainer
                    name="email"
                    placeholder=""
                    value="petrov@mail.ru"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup marginTop="8px">
                <Field width="100%">
                  <TextArea
                    shouldFitContainer
                    name="message"
                    placeholder="Добавьте сообщение (необязательно)"
                    value=""
                    isLabelHidden
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Сотрудник удален"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSendFlag(name)}>
          {this.state.sendFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Ссылка отправлена"
            />
          ))}
        </FlagGroup>
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array
};

export default Table;
