import {
  GET_EDUCATIONS_LIST_REQUEST,
  GET_EDUCATIONS_LIST_SUCCESS,
  GET_EDUCATIONS_LIST_FAIL
} from './constants';

export function getEducationsList() {
  return {
    type: GET_EDUCATIONS_LIST_REQUEST
  };
}

export function getEducationsListSuccess(payload) {
  return {
    type: GET_EDUCATIONS_LIST_SUCCESS,
    ...payload
  };
}

export function getEducationsListFail(payload) {
  return {
    type: GET_EDUCATIONS_LIST_FAIL,
    ...payload
  };
}
