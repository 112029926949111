import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isGuest } from 'utils/partnerRequest';

import reducer from 'pages/PartnerProfile/Auth/reducer';
import saga from 'pages/PartnerProfile/Auth/saga';

import {
  changeFlags,
  changeResetField,
  createPasswordReset,
  confirmPasswordReset
} from 'pages/PartnerProfile/Auth/actions';

import {
  makeSelectResetError,
  makeSelectResetForm,
  makeSelectResetFlags,
  makeSelectResetLoading
} from 'pages/PartnerProfile/Auth/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import Page from '@atlaskit/page';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import ButtonField from 'pages/Login/styled/ButtonField';
import FieldGroup from 'pages/Login/styled/FieldGroup';
import Field from 'pages/Login/styled/Field';
import Flex from 'pages/Login/styled/Flex';
import FlexItem from 'pages/Login/styled/FlexItem';
import Image from 'pages/Login/images/pic.png';
import Image2x from 'pages/Login/images/pic-2x.png';
import Title from 'pages/Login/styled/Title';
import P from 'pages/Login/styled/P';

import TextField from 'components/TextField';
import Tick from '@atlaskit/icon/glyph/check-circle';

class Reset extends Component {
  state = {
    formValid: {
      email: true,
      new_password: true
    },
    showPasswordForm:
      !isUndefined(this.props.match.params.uid) && !isUndefined(this.props.match.params.token)
  };

  componentDidMount() {
    if (!isGuest()) {
      const {
        props: {
          history: { push }
        }
      } = this;

      push('/partner/clients');
    }
  }

  onChangeFormField = event => {
    const {
      props: { changeResetField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeResetField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { form, confirmPasswordReset, createPasswordReset },
      state: { showPasswordForm },
      validateForm
    } = this;
    let {
      state: { formValid }
    } = this;
    const formValidationResult = {};
    let isValid = true;

    if (showPasswordForm) {
      formValid = {
        new_password: formValid.new_password
      };
    } else {
      formValid = {
        email: formValid.email
      };
    }

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: form[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      if (showPasswordForm) {
        const uid = this.props.match.params.uid;
        const token = this.props.match.params.token;

        confirmPasswordReset({
          uid,
          token
        });
      } else {
        createPasswordReset();
      }
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      case 'new_password':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              new_password: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { form, loading },
      state: { formValid, showPasswordForm }
    } = this;

    let formContent = (
      <Form name="submitForm">
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.email}
              required
              label="Ваш Email"
              name="email"
              id="email"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.email}
              shouldFitContainer
            />
          </Field>
        </FieldGroup>

        <FieldGroup>
          <ButtonField>
            <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
              Восстановить пароль
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );

    if (showPasswordForm) {
      formContent = (
        <Form name="submitForm">
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!formValid.new_password}
                required
                label="Новый пароль"
                name="new_password"
                id="new_password"
                onBlur={onChangeFormField}
                onChange={onChangeFormField}
                placeholder=""
                value={form.new_password}
                shouldFitContainer
                type="password"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <ButtonField>
              <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
                Восстановить пароль
              </Button>
            </ButtonField>
          </FieldGroup>
        </Form>
      );
    }

    return formContent;
  };

  renderFlags = () => {
    const {
      props: { changeFlags, error, flags }
    } = this;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'reset',
        flags: flags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {flags.map(id => {
          let icon = '';
          let title = '';
          let description = '';
          if (id === 1) {
            icon = <Tick label="Success" />;
            description = 'Письмо с инструкциями по восстановлению пароля отправлено.';
            title = 'Отправлено';
          } else if (id === 2) {
            icon = <Tick label="Success" />;
            description = 'Сейчас вы будете перенаправлены на страницу авторизации.';
            title = 'Пароль изменен';
          } else {
            icon = <Error label="Error" primaryColor={colors.R300} />;
            title = 'Ошибка';
            description = error;
          }

          return (
            <Flag
              description={description}
              isDismissAllowed
              id={id}
              icon={icon}
              key={`${id}`}
              title={title}
            />
          );
        })}
      </FlagGroup>
    );
  };

  renderContent = () => {
    const {
      renderFlags,
      renderForm,
      state: { showPasswordForm }
    } = this;

    return (
      <Flex>
        <FlexItem align="center" hideOnMobile>
          <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
        </FlexItem>
        <FlexItem>
          <Title>{showPasswordForm ? 'Введите новый пароль' : 'Забыли пароль?'}</Title>
          <P marginTop="3px" color="#6B778C">
            <Link to="/partner/login">Вернуться на страницу входа</Link>
          </P>

          {renderForm()}
          {renderFlags()}
        </FlexItem>
      </Flex>
    );
  };

  render() {
    const { renderContent } = this;

    return (
      <Page>
        <Helmet>
          <title>Вход в кабинет партнера</title>
        </Helmet>

        {renderContent()}
      </Page>
    );
  }
}

Reset.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changeResetField: PropTypes.func.isRequired,
  createPasswordReset: PropTypes.func.isRequired,
  confirmPasswordReset: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  flags: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeResetField: value => dispatch(changeResetField(value)),
    createPasswordReset: () => dispatch(createPasswordReset()),
    confirmPasswordReset: value => dispatch(confirmPasswordReset(value))
  };
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectResetError(),
  form: makeSelectResetForm(),
  flags: makeSelectResetFlags(),
  loading: makeSelectResetLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerAuth', reducer });
const withSaga = injectSaga({ key: 'partnerAuth', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Reset);
