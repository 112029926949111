import { createSelector } from 'reselect';

const selectGlobal = state => state.get('recoverPassword');

const makeSelectEmail = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('email')
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectRecoverSent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('recoverSent')
  );

const makeSelectNewPassword = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('newPassword')
  );

const makeSelectUid = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('uid')
  );

const makeSelectToken = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('token')
  );

export {
  makeSelectEmail,
  makeSelectLoading,
  makeSelectRecoverSent,
  makeSelectNewPassword,
  makeSelectUid,
  makeSelectToken
};
