import styled from 'styled-components';

const QuestionBody = styled.div`
  margin-bottom: 23px;

  @media (max-width: 1020px) {
    font-size: 13px;
    letter-spacing: -0.14px;
    line-height: 18px;
  }
`;

export default QuestionBody;
