import isUndefined from 'lodash/isUndefined';

export const formatTime = value => {
  let result = '';
  if (!isUndefined(value)) {
    const splited = value.split(':');
    let hours = splited[0] === '00' ? '' : splited[0];
    let minutes = splited[1] === '00' ? '' : splited[1];
    let seconds = splited[2] === '00' ? '0 сек' : splited[2];

    if (hours !== '') {
      if (hours[0] === '0') {
        hours = `${hours[1]} ч `;
      } else {
        hours = `${hours} ч `;
      }
    }

    if (minutes !== '') {
      if (minutes[0] === '0') {
        minutes = `${minutes[1]} мин `;
      } else {
        minutes = `${minutes} мин `;
      }
    }

    if (seconds !== '0 сек') {
      if (seconds[0] === '0') {
        seconds = `${seconds[1]} сек`;
      } else {
        seconds = `${seconds} сек`;
      }
    }

    result = hours + minutes + seconds;
  }

  return result;
};
