import { fromJS } from 'immutable';

import {
  GET_COMPARE_LIST_REQUEST,
  GET_COMPARE_LIST_SUCCESS,
  GET_COMPARE_LIST_FAIL,
  CHANGE_REPORT_SELECTOR,
  DELETE_REPORT_FROM_COMPARE,
  OPEN_ADD_MODAL,
  CLOSE_ADD_MODAL,
  CHANGE_COLUMN_POSITION
} from './constants';

const initialState = fromJS({
  addModal: [],
  compareIds: [],
  compareResult: [],
  loading: true,
  sections: [],
  staffInput: '',
  staffList: [],
  staffListLoading: true,
  staffSelector: []
});

function compareListReducer(state = initialState, action) {
  let compareIds = state.get('compareIds').toJS();
  switch (action.type) {
    case GET_COMPARE_LIST_REQUEST:
      return state.set('loading', true)
    case GET_COMPARE_LIST_SUCCESS:
      const newCompareIds = [];
      for (let res in action.compareResult) {
        newCompareIds.push(action.compareResult[res])
      }
      return state
        .set('loading', false)
        .set('compareResult', fromJS(newCompareIds))
        .set('sections', fromJS(action.sections));
    case GET_COMPARE_LIST_FAIL:
      return state.set('loading', false);
    case CHANGE_REPORT_SELECTOR:
      return state.set('compareIds', fromJS([...compareIds.concat(action.staffSelector)]));
    case DELETE_REPORT_FROM_COMPARE:
      return state
        .set('compareIds',fromJS(state.get('compareIds').toJS().filter(r => r !== action.id)))
    case OPEN_ADD_MODAL:
      return state.set('addModal', fromJS([1]));
    case CLOSE_ADD_MODAL:
      return state.set('addModal', fromJS([]));
    case CHANGE_COLUMN_POSITION:
      const idx = compareIds.findIndex(c => c === action.id);
      if(idx >= 0) {
        const temp = compareIds[idx + action.diff];
        compareIds[idx + action.diff] = compareIds[idx];
        compareIds[idx] = temp;
        return state.set('compareIds', fromJS(compareIds))
      }
      return state
    default:
      return state;
  }
}

export default compareListReducer;
