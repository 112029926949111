import { Component } from 'react';
import { withNavigationViewController } from '@atlaskit/navigation-next';

class ViewRegistrar extends Component {
  componentDidMount() {
    const { navigationViewController, view } = this.props;
    if (!navigationViewController.views[view.id]) {
      navigationViewController.addView(view);
    }
  }

  render() {
    return null;
  }
}

export default withNavigationViewController(ViewRegistrar);
