import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import Button from '@atlaskit/button';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';

import ButtonWrapper from './styled/ButtonWrapper';
import Wrapper from './styled/Wrapper';

class Comment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabledButton: true
    };

    this.commentRef = React.createRef();
  }

  componentDidMount() {
    const { show } = this.props;

    if (show) {
      document.addEventListener('mousedown', this.onOutsideClick);
    } else {
      document.removeEventListener('mousedown', this.onOutsideClick);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabledButton: isEmpty(nextProps.text)
    });

    if (nextProps.show === true) {
      document.addEventListener('mousedown', this.onOutsideClick);
    } else {
      document.removeEventListener('mousedown', this.onOutsideClick);
    }
  }

  onCommentChange = event => {
    const { onChange } = this.props;
    const comment = event.target.value;

    this.setState({
      disabledButton: comment.length < 10
    });
    onChange({ text: event.target.value });
  };

  onOutsideClick = event => {
    if (!this.commentRef.current.contains(event.target)) {
      this.onCloseComment();
    }
  };

  onCloseComment = () => {
    const { onClose, textRangeFrom, textRangeTo } = this.props;

    const span = document.getElementById(`selected-text-${textRangeFrom}-${textRangeTo}`);
    if (!isNull(span)) {
      const newContent = span.parentNode.textContent;
      span.parentNode.innerHTML = newContent;
    }
    onClose();
  };

  onDeleteClick = () => {
    const { id, sectionId, subsectionId } = this.props;

    this.props.onDelete({
      id,
      sectionId,
      subsectionId
    });
  };

  onSubmitClick = () => {
    const { onSubmit, textRangeFrom, textRangeTo } = this.props;

    const span = document.getElementById(`selected-text-${textRangeFrom}-${textRangeTo}`);
    if (!isNull(span)) {
      const newContent = span.parentNode.textContent;
      span.parentNode.innerHTML = newContent;
    }

    onSubmit();
  };

  render() {
    const { disabledButton } = this.state;
    const { isEdit, isDeleteLoading, isLoading, positionX, positionY, show, text } = this.props;
    const submitButtonText = isEdit ? 'Сохранить' : 'Отправить';
    const cancelButtonText = isEdit ? 'Удалить' : 'Отмена';
    const cancelOnClick = isEdit ? this.onDeleteClick : this.onCloseComment;

    return (
      <Wrapper positionX={positionX} positionY={positionY} innerRef={this.commentRef} show={show}>
        <form>
          <TextArea
            enableResize={false}
            label="Комментарий"
            minimumRows={4}
            name="text"
            onChange={this.onCommentChange}
            shouldFitContainer
            value={text}
          />
          <ButtonWrapper>
            <Button
              appearance="primary"
              isDisabled={disabledButton}
              isLoading={isLoading}
              onClick={this.onSubmitClick}
            >
              {submitButtonText}
            </Button>
            <Button appearance="subtle" isLoading={isDeleteLoading} onClick={cancelOnClick}>
              {cancelButtonText}
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  }
}

Comment.propTypes = {
  id: PropTypes.number.isRequired,
  isDeleteLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  positionX: PropTypes.number.isRequired,
  positionY: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  subsectionId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  textRangeFrom: PropTypes.number.isRequired,
  textRangeTo: PropTypes.number.isRequired
};

export default Comment;
