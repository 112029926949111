import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isString from 'lodash/isString';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from 'pages/PartnerProfile/Video/reducer';
import saga from 'pages/PartnerProfile/Video/saga';

import userReducer from 'pages/PartnerProfile/User/reducer';
import userSaga from 'pages/PartnerProfile/User/saga';

import BillingIcon from '@atlaskit/icon/glyph/billing';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import VideoIcon from 'navigation/routes/RootViews/styled/VideoIcon';

import IndividualName from 'navigation/components/IndividualName';
import LinkItem from 'navigation/components/LinkItem';
import ViewRegistrar from 'navigation/components/ViewRegistrar';

import { getEducationsList } from 'pages/PartnerProfile/Video/actions';
import { getUser } from 'pages/PartnerProfile/User/actions';

class ProfileRootView extends Component {
  componentDidMount() {
    if (!isString(localStorage.getItem('partnerKey')) && false) {
      this.props.history.push('/individual/login');
    }

    const { getEducationsList, getUser, sections, user } = this.props;

    if (user.pk === 0) {
      getUser();
    }

    if (sections.length === 0) {
      getEducationsList();
    }
  }

  render() {
    const { loading, sections, user, userLoading } = this.props;

    if (loading || userLoading) {
      return <div />;
    }

    const rootIndex = [
      {
        id: 'partner/index:header',
        items: [
          {
            type: 'InlineComponent',
            component: IndividualName,
            id: 'company-name',
            text: `${user.first_name} ${user.last_name}`,
            desc: user.email
          }
        ],
        type: 'Section'
      },
      {
        id: 'partner/index:menu',
        items: [
          {
            type: 'InlineComponent',
            component: LinkItem,
            id: 'clients',
            text: 'Клиенты',
            before: OfficeBuildingIcon,
            to: '/partner/clients'
          },
          {
            type: 'InlineComponent',
            component: LinkItem,
            id: 'partners',
            text: 'Партнеры',
            before: PeopleIcon,
            to: '/partner/partners'
          },
          {
            type: 'InlineComponent',
            component: LinkItem,
            id: 'finances',
            text: 'Финансы',
            before: BillingIcon,
            to: '/partner/finances'
          },
          {
            goTo: 'partner/videos',
            id: 'videos',
            text: 'Обучающие видео',
            before: VideoIcon,
            type: 'GoToItem'
          },
          {
            goTo: 'partner/settings',
            id: 'settings',
            text: 'Настройки',
            before: SettingsIcon,
            type: 'GoToItem'
          }
        ],
        nestedGroupKey: 'menu',
        parentId: null,
        type: 'Section'
      }
    ];

    const videosIndex = [
      {
        id: 'partner/videos:header',
        items: [
          {
            type: 'InlineComponent',
            component: IndividualName,
            id: 'company-name',
            text: `${user.first_name} ${user.last_name}`,
            desc: user.email
          },
          {
            id: 'back-button',
            items: [
              {
                goTo: 'partner/index',
                id: 'back',
                text: 'Обучающие видео',
                type: 'BackItem'
              }
            ],
            type: 'Group'
          }
        ],
        type: 'Section'
      },
      {
        id: 'partner/videos:menu',
        nestedGroupKey: 'menu',
        parentId: 'partner/index:menu',
        type: 'Section',
        items: sections.map(section => ({
          before: VideoIcon,
          id: section.id,
          text: section.name,
          to: `/partner/videos/${section.id}`,
          type: 'InlineComponent',
          component: LinkItem
        }))
      }
    ];
    const settingsIndex = [
      {
        id: 'partner/settings:header',
        items: [
          {
            type: 'InlineComponent',
            component: IndividualName,
            id: 'company-name',
            text: `${user.first_name} ${user.last_name}`,
            desc: user.email
          },
          {
            id: 'back-button',
            items: [
              {
                goTo: 'partner/index',
                id: 'back',
                text: 'Настройки',
                type: 'BackItem'
              }
            ],
            type: 'Group'
          }
        ],
        type: 'Section'
      },
      {
        id: 'partner/settings:menu',
        nestedGroupKey: 'menu',
        parentId: 'partner/index:menu',
        type: 'Section',
        items: [
          {
            before: OfficeBuildingIcon,
            id: 'requisites',
            text: 'Реквизиты',
            to: `/partner/requisites`,
            type: 'InlineComponent',
            component: LinkItem
          }
        ]
      }
    ];
    let rootViews = [
      { id: 'partner/index', getItems: () => rootIndex, type: 'product' },
      {
        id: 'partner/videos',
        getItems: () => videosIndex,
        type: 'product'
      },
      {
        id: 'partner/settings',
        getItems: () => settingsIndex,
        type: 'product'
      }
    ];

    return rootViews.map(view => <ViewRegistrar key={view.id} view={view} />);
  }
}

ProfileRootView.propTypes = {
  getEducationsList: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sections: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    getEducationsList: () => dispatch(getEducationsList()),
    getUser: () => dispatch(getUser())
  };
}

const mapStateToProps = store => ({
  loading: store.getIn(['educations', 'loading']),
  sections: store.getIn(['educations', 'sections']).toJS(),
  user: store.getIn(['partnerUser', 'user', 'read']).toJS(),
  userLoading: store.getIn(['partnerUser', 'user', 'loading'])
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'educations', reducer });
const withSaga = injectSaga({ key: 'educations', saga });
const withUserReducer = injectReducer({ key: 'partnerUser', reducer: userReducer });
const withUserSaga = injectSaga({ key: 'partnerUser', saga: userSaga });

export default compose(
  withRouter,
  withReducer,
  withUserReducer,
  withSaga,
  withUserSaga,
  withConnect
)(ProfileRootView);
