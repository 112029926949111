export const STAFF_LIST_REQUEST = 'hh/pages/home/STAFF_LIST_REQUEST';
export const STAFF_LIST_FILTER_REQUEST = 'hh/pages/home/STAFF_LIST_FILTER_REQUEST';
export const STAFF_LIST_REQUEST_SUCCESS = 'hh/pages/home/STAFF_LIST_REQUEST_SUCCESS';
export const STAFF_LIST_REQUEST_FAIL = 'hh/pages/home/STAFF_LIST_REQUEST_FAIL';

export const CHANGE_STAFF_PAGE = 'hh/pages/home/CHANGE_STAFF_PAGE';
export const CHANGE_STAFF_SORT = 'hh/pages/home/CHANGE_STAFF_SORT';

export const CHANGE_STAFF_COMMENT = 'hh/pages/home/CHANGE_STAFF_COMMENT';
export const CHANGE_STAFF_FLAGS = 'hh/pages/home/CHANGE_STAFF_FLAGS';

export const STAFF_READ_REQUEST = 'hh/pages/home/STAFF_READ_REQUEST';
export const STAFF_READ_REQUEST_SUCCESS = 'hh/pages/home/STAFF_READ_REQUEST_SUCCESS';
export const STAFF_READ_REQUEST_FAIL = 'hh/pages/home/STAFF_READ_REQUEST_FAIL';

export const STAFF_UPDATE_REQUEST = 'hh/pages/home/STAFF_UPDATE_REQUEST';
export const STAFF_UPDATE_REQUEST_SUCCESS = 'hh/pages/home/STAFF_UPDATE_REQUEST_SUCCESS';
export const STAFF_UPDATE_REQUEST_FAIL = 'hh/pages/home/STAFF_UPDATE_REQUEST_FAIL';

export const STAFF_REPORT_REQUEST = 'hh/pages/home/STAFF_REPORT_REQUEST';
export const STAFF_REPORT_REQUEST_SUCCESS = 'hh/pages/home/STAFF_REPORT_REQUEST_SUCCESS';
export const STAFF_REPORT_REQUEST_FAIL = 'hh/pages/home/STAFF_REPORT_REQUEST_FAIL';

export const CHANGE_STAFF_COMPARE_FILTER = 'hh/pages/home/CHANGE_STAFF_COMPARE_FILTER';
export const CHANGE_STAFF_COMPARE_PAGE = 'hh/pages/home/CHANGE_STAFF_COMPARE_PAGE';
export const CHANGE_STAFF_COMPARE_SORT = 'hh/pages/home/CHANGE_STAFF_COMPARE_SORT';

export const STAFF_COMPARE_REQUEST = 'hh/pages/home/STAFF_COMPARE_REQUEST';
export const STAFF_COMPARE_REQUEST_SUCCESS = 'hh/pages/home/STAFF_COMPARE_REQUEST_SUCCESS';
export const STAFF_COMPARE_REQUEST_FAIL = 'hh/pages/home/STAFF_COMPARE_REQUEST_FAIL';

export const STAFF_DELETE_REQUEST = 'hh/pages/home/STAFF_DELETE_REQUEST';
export const STAFF_DELETE_REQUEST_SUCCESS = 'hh/pages/home/STAFF_DELETE_REQUEST_SUCCESS';
export const STAFF_DELETE_REQUEST_FAIL = 'hh/pages/home/STAFF_DELETE_REQUEST_FAIL';

export const CHANGE_COMPARE_PROFILE = 'hh/pages/home/CHANGE_COMPARE_PROFILE';
export const CHANGE_COMPARE_PROFILE_INPUT = 'hh/pages/home/CHANGE_COMPARE_PROFILE_INPUT';

export const PROFILE_LIST_REQUEST = 'hh/pages/home/PROFILE_LIST_REQUEST';
export const PROFILE_LIST_REQUEST_SUCCESS = 'hh/pages/home/PROFILE_LIST_REQUEST_SUCCESS';
export const PROFILE_LIST_REQUEST_FAIL = 'hh/pages/home/PROFILE_LIST_REQUEST_FAIL';

export const CREATE_COMPARE_REQUEST = 'hh/pages/home/CREATE_COMPARE_REQUEST';
export const CREATE_COMPARE_REQUEST_SUCCESS = 'hh/pages/home/CREATE_COMPARE_REQUEST_SUCCESS';
export const CREATE_COMPARE_REQUEST_FAIL = 'hh/pages/home/CREATE_COMPARE_REQUEST_FAIL';

export const DELETE_COMPARE_REQUEST = 'hh/pages/home/DELETE_COMPARE_REQUEST';
export const DELETE_COMPARE_REQUEST_SUCCESS = 'hh/pages/home/DELETE_COMPARE_REQUEST_SUCCESS';
export const DELETE_COMPARE_REQUEST_FAIL = 'hh/pages/home/DELETE_COMPARE_REQUEST_FAIL';

export const GET_COMPARE_REQUEST = 'hh/pages/home/GET_COMPARE_REQUEST';
export const GET_COMPARE_REQUEST_SUCCESS = 'hh/pages/home/GET_COMPARE_REQUEST_SUCCESS';
export const GET_COMPARE_REQUEST_FAIL = 'hh/pages/home/GET_COMPARE_REQUEST_FAIL';

export const CHANGE_INVITE_NAME = 'hh/pages/home/CHANGE_INVITE_NAME';
export const CHANGE_INVITE_FULL_NAME = 'hh/pages/home/CHANGE_INVITE_FULL_NAME';
export const CHANGE_INVITE_EMAIL = 'hh/pages/home/CHANGE_INVITE_EMAIL';
export const CHANGE_INVITE_TEXT = 'hh/pages/home/CHANGE_INVITE_TEXT';
export const CHANGE_INVITE_COMMENT = 'hh/pages/home/CHANGE_INVITE_COMMENT';

export const CHANGE_INVITE_FLAGS = 'hh/pages/home/CHANGE_INVITE_FLAGS';
export const CHANGE_INVITE_MODALS = 'hh/pages/home/CHANGE_INVITE_MODALS';

export const SEND_INVITE_USER_REQUEST = 'hh/pages/home/SEND_INVITE_USER_REQUEST';
export const SEND_INVITE_USER_REQUEST_SUCCESS = 'hh/pages/home/SEND_INVITE_USER_REQUEST_SUCCESS';
export const SEND_INVITE_USER_REQUEST_FAIL = 'hh/pages/home/SEND_INVITE_USER_REQUEST_FAIL';

export const GET_USER_READ_REQUEST = 'hh/pages/home/GET_USER_READ_REQUEST';
export const GET_USER_READ_REQUEST_SUCCESS = 'hh/pages/home/GET_USER_READ_REQUEST_SUCCESS';
export const GET_USER_READ_REQUEST_FAIL = 'hh/pages/home/GET_USER_READ_REQUEST_FAIL';

export const CHANGE_USER_NAME = 'hh/pages/home/CHANGE_USER_NAME';
export const CHANGE_USER_SURNAME = 'hh/pages/home/CHANGE_USER_SURNAME';
export const CHANGE_USER_PHONE_NUMBER = 'hh/pages/home/CHANGE_USER_PHONE_NUMBER';
export const CHANGE_USER_EMAIL = 'hh/pages/home/CHANGE_USER_EMAIL';
export const CHANGE_USER_PASSWORD1 = 'hh/pages/home/CHANGE_USER_PASSWORD1';
export const CHANGE_USER_PASSWORD2 = 'hh/pages/home/CHANGE_USER_PASSWORD2';
export const CHANGE_USER_COMPANY_NAME = 'hh/pages/home/CHANGE_USER_COMPANY_NAME';

export const UPDATE_USER_REQUEST = 'hh/pages/home/UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_SUCCESS = 'hh/pages/home/UPDATE_USER_REQUEST_SUCCESS';
export const UPDATE_USER_REQUEST_FAIL = 'hh/pages/home/UPDATE_USER_REQUEST_FAIL';

export const UPDATE_USER_PASSWORD_REQUEST = 'hh/pages/home/UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_REQUEST_SUCCESS =
  'hh/pages/home/UPDATE_USER_PASSWORD_REQUEST_SUCCESS';
export const UPDATE_USER_PASSWORD_REQUEST_FAIL = 'hh/pages/home/UPDATE_USER_PASSWORD_REQUEST_FAIL';

export const GET_MANAGERS_LIST_REQUEST = 'hh/pages/home/GET_MANAGERS_LIST_REQUEST';
export const GET_MANAGERS_LIST_REQUEST_SUCCESS = 'hh/pages/home/GET_MANAGERS_LIST_REQUEST_SUCCESS';
export const GET_MANAGERS_LIST_REQUEST_FAIL = 'hh/pages/home/GET_MANAGERS_LIST_REQUEST_FAIL';

export const CHANGE_NAME_FILTER = 'hh/pages/home/CHANGE_NAME_FILTER';
export const CHANGE_MANAGERS_FILTER = 'hh/pages/home/CHANGE_MANAGERS_FILTER';

export const CHANGE_WARNING_FLAG = 'hh/pages/home/CHANGE_WARNING_FLAG';

export const LOADING_END = 'hh/pages/home/LOADING_END';
export const LOADING_TABLE_END = 'hh/pages/home/LOADING_TABLE_END';

export const CHANGE_ADDS_REPORTS_COUNT = 'hh/pages/home/CHANGE_BUY_REPORTS_COUNT';
export const CHANGE_ADDS_MODAL = 'hh/pages/home/CHANGE_ADDS_MODAL';
export const CHANGE_ADDS_FLAG = 'hh/pages/home/CHANGE_ADDS_FLAG';
export const ADDS_REPORTS_REQUEST = 'hh/pages/home/ADDS_REPORTS_REQUEST';
export const ADDS_REPORTS_REQUEST_SUCCESS = 'hh/pages/home/ADDS_REPORTS_REQUEST_SUCCESS';
export const ADDS_REPORTS_REQUEST_FAIL = 'hh/pages/home/ADDS_REPORTS_REQUEST_FAIL';

export const CHECK_ORDER_REQUEST = 'hh/pages/home/CHECK_ORDER_REQUEST';
export const CHECK_ORDER_REQUEST_SUCCESS = 'hh/pages/home/CHECK_ORDER_REQUEST_SUCCESS';
export const CHECK_ORDER_REQUEST_FAIL = 'hh/pages/home/CHECK_ORDER_REQUEST_FAIL';

export const SEND_TO_PAYMENT_PAGE = 'hh/pages/home/SEND_TO_PAYMENT_PAGE';

export const CHANGE_REPORTS_FLAGS = 'hh/pages/home/CHANGE_REPORTS_FLAGS';
export const CHANGE_REPORTS_EMAIL = 'hh/pages/home/CHANGE_REPORTS_EMAIL';
export const CHANGE_REPORTS_LOADING = 'hh/pages/home/CHANGE_REPORTS_LOADING';
export const CHANGE_REPORTS_MODALS = 'hh/pages/home/CHANGE_REPORTS_MODALS';
export const SEND_REPORTS_EMAIL_REQUEST = 'hh/pages/home/SEND_REPORTS_EMAIL_REQUEST';
export const SEND_REPORTS_EMAIL_REQUEST_SUCCESS =
  'hh/pages/home/SEND_REPORTS_EMAIL_REQUEST_SUCCESS';
export const SEND_REPORTS_EMAIL_REQUEST_FAIL = 'hh/pages/home/SEND_REPORTS_EMAIL_REQUEST_FAIL';

export const CHANGE_ORDER_REPORT_BY_RANK = 'hh/pages/home/CHANGE_ORDER_REPORT_BY_RANK';
export const CHANGE_MISMATCHED_PATTERNS = 'hh/pages/home/CHANGE_MISMATCHED_PATTERNS';
export const CHANGE_REPORT_TYPE = 'hh/pages/home/CHANGE_REPORT_TYPE';

export const CHANGE_COMPARE_INFO_EMAIL = 'hh/pages/home/CHANGE_COMPARE_INFO_EMAIL';
export const CHANGE_COMPARE_INFO_LOADING = 'hh/pages/home/CHANGE_COMPARE_INFO_LOADING';
export const CHANGE_COMPARE_INFO_MODAL = 'hh/pages/home/CHANGE_COMPARE_INFO_MODAL';
export const CHANGE_COMPARE_INFO_FLAG = 'hh/pages/home/CHANGE_COMPARE_INFO_FLAG';

export const SEND_COMPARE_INFO_REQUEST = 'hh/pages/home/SEND_COMPARE_INFO_REQUEST';
export const SEND_COMPARE_INFO_REQUEST_SUCCESS = 'hh/pages/home/SEND_COMPARE_INFO_REQUEST_SUCCESS';
export const SEND_COMPARE_INFO_REQUEST_FAIL = 'hh/pages/home/SEND_COMPARE_INFO_REQUEST_FAIL';

export const ADD_REPORT_TO_COMPARE = 'hh/pages/home/ADD_REPORT_TO_COMPARE';
export const DELETE_REPORT_FROM_COMPARE = 'hh/pages/home/DELETE_REPORT_FROM_COMPARE';
export const UPDATE_REPORTS_TO_COMPARE = 'hh/pages/home/UPDATE_REPORTS_TO_COMPARE';

export const CHANGE_REPORT_SHAPE = 'hh/pages/home/CHANGE_REPORT_SHAPE';

export const SEND_INVITES_BY_FILE = 'hh/pages/home/SEND_INVITES_BY_FILE';
export const SEND_INVITES_BY_FILE_SUCCESS = 'hh/pages/home/SEND_INVITES_BY_FILE_SUCCESS';
export const SEND_INVITES_BY_FILE_FAIL = 'hh/pages/home/SEND_INVITES_BY_FILE_FAIL';

export const CHANGE_INVITE_BY_FILE_FLAGS = 'hh/pages/home/CHANGE_INVITE_BY_FILE_FLAGS';

export const SEND_REPORT_READ_REQUEST = 'hh/pages/home/SEND_REPORT_READ_REQUEST';
export const DECREASE_UNREAD_REPORTS_COUNT = 'hh/pages/home/DECREASE_UNREAD_REPORTS_COUNT';
