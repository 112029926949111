import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isEmail, isPhoneInProfile } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isGuest } from 'utils/partnerRequest';
import { legalName } from "utils/checkService";

import reducer from 'pages/PartnerProfile/Auth/reducer';
import saga from 'pages/PartnerProfile/Auth/saga';

import {
  changeFlags,
  changeRegistrationField,
  sendPasscode,
  sendRegistration
} from 'pages/PartnerProfile/Auth/actions';

import {
  makeSelectRegistrationError,
  makeSelectRegistrationFlags,
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading,
  makeSelectRegistrationPasscodeSent
} from 'pages/PartnerProfile/Auth/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import Page from '@atlaskit/page';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import ButtonField from 'pages/Login/styled/ButtonField';
import FieldGroup from 'pages/Login/styled/FieldGroup';
import Field from 'pages/Login/styled/Field';
import Flex from 'pages/Login/styled/Flex';
import FlexItem from 'pages/Login/styled/FlexItem';
import Image from 'pages/Login/images/pic.png';
import Image2x from 'pages/Login/images/pic-2x.png';
import Title from 'pages/Login/styled/Title';
import P from 'pages/Login/styled/P';

import TextField from 'components/TextField';

class Registration extends Component {
  state = {
    formValid: {
      email: true,
      password: true,
      phone_number: true,
      first_name: true,
      passcode: true
    }
  };

  componentDidMount() {
    if (!isGuest()) {
      const {
        props: {
          history: { push }
        }
      } = this;

      push('/partner/clients');
    }
  }

  onChangeFormField = event => {
    const {
      props: { changeRegistrationField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeRegistrationField(field);
    validateForm(field);
  };

  onSendPasscode = event => {
    event.preventDefault();
    const {
      props: { sendPasscode }
    } = this;

    sendPasscode();
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { form, sendRegistration },
      state: { formValid },
      validateForm
    } = this;
    const referral = this.props.match.params.referral;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: form[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      sendRegistration({
        referral
      });
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      case 'password':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              password: result
            }
          });
        }

        return result;
      case 'phone_number':
        result = isPhoneInProfile(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              phone_number: result
            }
          });
        }

        return result;
      case 'first_name':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              first_name: result
            }
          });
        }

        return result;
      case 'passcode':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              passcode: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSendPasscode,
      onSubmitForm,
      props: { form, loading, passcodeSent },
      state: { formValid }
    } = this;

    return (
      <Form name="submitForm">
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.first_name}
              required
              label="Имя"
              name="first_name"
              id="first_name"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.first_name}
              shouldFitContainer
            />
          </Field>
          <Field>
            <TextField
              label="Фамилия"
              name="last_name"
              id="last_name"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.last_name}
              shouldFitContainer
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.email}
              required
              label="Email"
              name="email"
              id="email"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.email}
              shouldFitContainer
            />
          </Field>
          <Field>
            <TextField
              isInvalid={!formValid.password}
              required
              label="Пароль"
              name="password"
              id="password"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.password}
              shouldFitContainer
              type="password"
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.phone_number}
              required
              label="Телефон"
              mask="+79999999999"
              name="phone_number"
              id="phone_number"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.phone_number}
              shouldFitContainer
            />
          </Field>
          <Field>
            <TextField
              isInvalid={!formValid.passcode}
              required
              label="Код подтверждения"
              name="passcode"
              id="passcode"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.passcode}
              shouldFitContainer
            />
            <P marginTop="5px">
              {passcodeSent ? (
                'Код - последние 4 цифры входящего звонка'
              ) : (
                <a href="/partner/passcode" onClick={onSendPasscode}>
                  Отправить код подтверждения
                </a>
              )}
            </P>
          </Field>
        </FieldGroup>
        <FieldGroup>
          <ButtonField>
            <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
              Завершить регистрацию
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );
  };

  renderFlags = () => {
    const {
      props: { changeFlags, error, flags }
    } = this;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'registration',
        flags: flags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {flags.map(id => (
          <Flag
            description={error}
            isDismissAllowed
            id={id}
            icon={<Error label="Error" primaryColor={colors.R300} />}
            key={`${id}`}
            title="Ошибка"
          />
        ))}
      </FlagGroup>
    );
  };

  render() {
    const { renderForm, renderFlags } = this;

    return (
      <Page>
        <Helmet>
          <title>Регистрация для партнеров</title>
        </Helmet>

        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Регистрация для партнеров</Title>
            <P marginTop="3px" color="#6B778C">
              Уже зарегистрированы? <Link to="/partner/login">Войти</Link>
            </P>

            {renderForm()}

            <P marginTop="21px" color="#6B778C">
              Нажимая «Завершить регистрацию» я принимаю условия{' '}
              <a href="/documents/4" target="_blank" rel="noopener noreferrer">
                Пользовательского соглашения
              </a>{' '}
              и даю своё согласие {legalName} на обработку моих персональных данных, в соответствии
              с Федеральным законом от 27.07.2006 года #152-ФЗ "О персональных данных", на условиях
              и для целей, определенных{' '}
              <a href="/documents/3" target="_blank" rel="noopener noreferrer">
                Политикой конфиденциальности
              </a>
              .
            </P>
            {renderFlags()}
          </FlexItem>
        </Flex>
      </Page>
    );
  }
}

Registration.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changeRegistrationField: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  flags: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  passcodeSent: PropTypes.bool.isRequired,
  sendPasscode: PropTypes.func.isRequired,
  sendRegistration: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeRegistrationField: value => dispatch(changeRegistrationField(value)),
    sendPasscode: () => dispatch(sendPasscode()),
    sendRegistration: value => dispatch(sendRegistration(value))
  };
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectRegistrationError(),
  flags: makeSelectRegistrationFlags(),
  form: makeSelectRegistrationForm(),
  loading: makeSelectRegistrationLoading(),
  passcodeSent: makeSelectRegistrationPasscodeSent()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerAuth', reducer });
const withSaga = injectSaga({ key: 'partnerAuth', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Registration);
