import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Page from '@atlaskit/page';
import Form from '@atlaskit/form';
import Button from '@atlaskit/button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';

import { isRequired, isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import TextField from 'components/TextField';

import { changeEmail, changeRecoverSent, recoverRequest } from './actions';

import { makeSelectEmail, makeSelectLoading, makeSelectRecoverSent } from './selectors';

import reducer from './reducer';
import saga from './saga';

import Image from './images/pic.png';
import Image2x from './images/pic-2x.png';

import ButtonField from './styled/ButtonField';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import P from './styled/P';
import Title from './styled/Title';

class RecoverPassword extends Component {
  state = {
    errorFlag: [1],
    email: {
      wasChanged: false,
      isInvalid: false,
      value: ''
    },
    isFormValid: false
  };

  onChangeEmail = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changeEmail({
      email: e.target.value
    });

    if (isRequired(e.target.value) && isEmail(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      email: result,
      isFormValid: !result.isInvalid && result.wasChanged
    });
  };

  onChangeRecoverSent = () => {
    this.props.changeEmail({
      email: ''
    });

    this.props.changeRecoverSent({
      recoverSent: false
    });
  };

  render() {
    const showError = !isUndefined(this.props.history.location.state)
      ? this.props.history.location.state.showError
      : false;
    const { email, loading, recoverSent } = this.props;

    let content = (
      <Form>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={this.state.email.isInvalid}
              required
              label="Ваш Email"
              name="email"
              onBlur={this.onChangeEmail}
              onChange={this.onChangeEmail}
              placeholder=""
              value={email}
              shouldFitContainer
            />
          </Field>
        </FieldGroup>

        <FieldGroup>
          <ButtonField>
            <Button
              appearance="primary"
              isDisabled={!this.state.isFormValid}
              isLoading={loading}
              onClick={this.props.recoverRequest}
            >
              Восстановить пароль
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );

    if (recoverSent) {
      content = (
        <div>
          <P marginTop="40px" marginBottom="20px">
            Мы отправили ссылку для восстановления пароля на адрес: <b>{email}</b>
          </P>

          <ButtonField>
            <Button appearance="primary" onClick={this.onChangeRecoverSent}>
              Отправить еще раз
            </Button>
          </ButtonField>
        </div>
      );
    }

    return (
      <Page>
        <Helmet>
          <title>Восстановление пароля</title>
        </Helmet>

        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Забыли пароль?</Title>
            {showError && !recoverSent && (
              <P marginTop="20px" marginBottom="20px">
                Ссылка на восстановление пароля недействительна. Введите ваш Email, чтобы получить
                новую ссылку.
              </P>
            )}

            {content}
          </FlexItem>
        </Flex>
      </Page>
    );
  }
}

RecoverPassword.propTypes = {
  changeEmail: PropTypes.func,
  changeRecoverSent: PropTypes.func,
  email: PropTypes.string,
  loading: PropTypes.bool,
  recoverRequest: PropTypes.func,
  recoverSent: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeEmail: value => dispatch(changeEmail(value)),
    changeRecoverSent: value => dispatch(changeRecoverSent(value)),
    recoverRequest: () => dispatch(recoverRequest())
  };
}

const mapStateToProps = createStructuredSelector({
  email: makeSelectEmail(),
  loading: makeSelectLoading(),
  recoverSent: makeSelectRecoverSent()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'recoverPassword', reducer });
const withSaga = injectSaga({ key: 'recoverPassword', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(RecoverPassword);
