import styled from 'styled-components';

const getBorderColor = (isDragActive, isInvalid) => {
  let borderColor = '#DEE1E6';

  if (isDragActive) {
    borderColor = '#4C9AFF';
  }

  if (isInvalid) {
    borderColor = '#DE350B';
  }

  return `border-color: ${borderColor}`;
};

const Container = styled.div`
  border: 1px dashed #dee1e6;
  ${props => getBorderColor(props.isDragActive, props.isInvalid)};
  border-radius: 3px;
  box-sizing: border-box;
  color: #6b778c;
  font-size: 14px;
  height: 140px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 10px 0;
  padding-top: 30px;
  text-align: center;
  transition: all 0.3s ease-out;
  width: 100%;
`;

export default Container;
