import styled from 'styled-components';

const Wrapper = styled.div`
  & {
    .page-wrapper {
      box-sizing: border-box;
      flex: 1 1 auto;
      margin-left: 64px;
      min-width: 960px;
      width: 0;

      @media print {
        margin-left: 0 !important;
        padding-left: 0 !important;
      }
    }

    .navigation,
    .media-print-hide {
      @media print {
        display: none;
      }
    }

    .content {
      @media print {
        margin-left: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
`;

export default Wrapper;
