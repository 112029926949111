import React from 'react';
import styled from 'styled-components';

import Ibm from './ibm.svg';
import Heineken from './heineken.svg';
import Lr from './lr.svg';
import Microsoft from './microsoft.svg';
import Southwest from './southwest.svg';
import Toyota from './toyota.svg';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 560px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: 100%;
  }

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const Logo = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-size: ${props => props.backgroundWidth}px;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 260px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: 50%;
  }

  @media (max-width: 1020px) {
    background-size: contain;
    margin: 10px;
    width: calc(50% - 20px);
  }
`;

const CompanyLogos = () => (
  <Wrapper>
    <Logo backgroundImage={Ibm} backgroundWidth={150} />
    <Logo backgroundImage={Lr} backgroundWidth={150} />
    <Logo backgroundImage={Heineken} backgroundWidth={198} />
    <Logo backgroundImage={Toyota} backgroundWidth={199} />
    <Logo backgroundImage={Microsoft} backgroundWidth={199} />
    <Logo backgroundImage={Southwest} backgroundWidth={200} />
  </Wrapper>
);

export default CompanyLogos;
