import { call, put, takeLeading } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';

import { VIDEOS_LIST_REQUEST } from './constants';

import { videosListSuccess, videosListFail } from './actions';

function* videoListSaga() {
  const url = 'api/staff/education_sections/';

  try {
    const request = yield call(apiRequest, url);

    yield put(
      videosListSuccess({
        sections: request.data
      })
    );
  } catch (e) {
    yield put(videosListFail());
  }
}

export default function* videoSagas() {
  yield takeLeading(VIDEOS_LIST_REQUEST, videoListSaga);
}
