import React from 'react';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import NormalFlag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import styled from 'styled-components';
import Form, { Field as FormField } from '@atlaskit/form';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/field-text';
import uuid from 'uuid/v1';

import Modal from 'components/Modal';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const Flag = styled(NormalFlag)`
  background-color: green !important;
`;

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
`;

const Field = styled.div`
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'inherit')};
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};
`;

const TableItem = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`;

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'full_name',
        content: 'Имя, фамилия',
        isSortable: true,
        width: withWidth ? 36 : undefined
      },
      {
        key: 'email',
        content: 'Email',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 23 : undefined
      },
      {
        key: 'access',
        content: 'Роль',
        shouldTruncate: true,
        width: withWidth ? 37 : undefined
      }
    ]
  };
};

const headTable = createHead(true);

const P = styled.p`
  color: ${props => (props.color ? props.color : 'inherit')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
`;

class UserTable extends React.Component {
  state = {
    deleteModals: [],
    sendModals: [],
    flags: [],
    sendFlags: []
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      deleteModals: this.state.deleteModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  closeModal = id => {
    this.setState({ deleteModals: this.state.deleteModals.filter(i => i !== id) });
  };

  closeEditModal = id => {
    this.setState({ sendModals: this.state.sendModals.filter(i => i !== id) });
  };

  addSendFlag = id => {
    this.setState({
      sendFlags: [this.state.flags.length, ...this.state.flags],
      sendModals: this.state.sendModals.filter(i => i !== id)
    });
  };

  removeSendFlag = id => this.setState({ sendFlags: this.state.sendFlags.filter(v => v !== id) });

  render() {
    const rowsTable = this.props.data
      .map((item, index) => ({
        key: `row-${index}-${item.get('id')}`,
        cells: [
          {
            key: createKey(item.get('full_name')),
            content: <TableItem>{item.get('full_name')}</TableItem>
          },
          {
            key: createKey(item.get('email')),
            content: <TableItem>{item.get('email')}</TableItem>
          },
          {
            key: uuid(),
            content: <TableItem>{item.get('role') === 0 ? 'Администратор' : 'Менеджер'}</TableItem>
          }
        ]
      }))
      .toJS();

    const options = [
      { label: 'Администратор', value: 'admin', extra: 'extra' },
      { label: 'Пользователь', value: 'user' }
    ];

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          rows={rowsTable}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {this.state.deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить пользователя"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Удалить',
                onClick: this.addFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>Уверены, что хотите удалить пользователя?</p>
          </Modal>
        ))}

        {this.state.sendModals.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Изменить роль"
            onClose={this.closeEditModal}
            actions={[
              {
                text: 'Сохранить',
                onClick: this.addSendFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeEditModal
              }
            ]}
            width="small"
          >
            <p>Укажите имя человека и e-mail, на который будет отправлено приглашение.</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup layout="column" form="">
                <Field width="100%">
                  <TextField
                    label="Пользователь"
                    shouldFitContainer
                    name="user"
                    placeholder=""
                    disabled
                    value="Ильвира Набиуллина"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <FormField label="Роль" validateOnBlur={false}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      className="multi-select"
                      classNamePrefix="react-select"
                      defaultValue={options[1]}
                      options={options}
                      placeholder="Роль"
                    />
                  </FormField>
                  <P marginTop="9px" color="#6B778C">
                    Администратор может приглашать других пользователей в компанию и имеет полный
                    доступ к данным всех менеджеров. Менеджер имеет доступ только к своим данным, а
                    данные других менеджеров он может только просматривать.
                  </P>
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Пользователь удален"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSendFlag(name)}>
          {this.state.sendFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Роль изменена"
            />
          ))}
        </FlagGroup>
      </div>
    );
  }
}

export default UserTable;
