import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import { apiRequest } from 'utils/partnerRequest';

import {
  CHANGE_CLIENTS_LIST_PAGE,
  GET_CLIENTS_LIST,
  REGISTER_NEW_CLIENT,
  GET_CLIENT,
  UPDATE_CLIENT,
  GET_MANAGERS_LIST,
  GET_REQUISITES_LIST,
  GET_FINANCES_LIST,
  SEND_INVITE,
  CHANGE_FILTER_FIELD,
  CHANGE_IS_MINE_FILTER,
  CHANGE_DATE_FILTER
} from './constants';

import {
  getClientsList,
  getClientsListSuccess,
  getClientsListFail,
  registerNewClientSuccess,
  registerNewClientFail,
  getClientSuccess,
  getClientFail,
  updateClientSuccess,
  updateClientFail,
  getManagersList,
  getManagersListSuccess,
  getManagersListFail,
  getRequisitesListSuccess,
  getRequisitesListFail,
  getFinancesList,
  getFinancesListSuccess,
  getFinancesListFail,
  sendInviteSuccess,
  sendInviteFail
} from './actions';

import {
  makeSelectClientsIsMineFilter,
  makeSelectClientsPage,
  makeSelectClientsSearch,
  makeSelectRegistrationForm,
  makeSelectClientComment,
  makeSelectManagersPage,
  makeSelectManagersSearch,
  makeSelectRequisitesPage,
  makeSelectFinancesPage,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter,
  makeSelectInviteForm
} from './selectors';
import { makeSelectUserRead } from '../User/selectors';

function* getClientsListSaga() {
  const page = yield select(makeSelectClientsPage());
  const search = yield select(makeSelectClientsSearch());
  const isMine = yield select(makeSelectClientsIsMineFilter());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (!isEmpty(search)) {
    params.search = search;
  }

  if (isMine) {
    params.only_mine = 'true';
  }

  const url = `api/partners/organizations/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getClientsListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (e) {
    yield put(
      getClientsListFail({
        error: e
      })
    );
  }
}

function* registerNewClientSaga() {
  const data = yield select(makeSelectRegistrationForm());
  const user = yield select(makeSelectUserRead());
  const url = 'api/partners/companies/register';
  const options = {
    method: 'post',
    data: {
      ...data,
      referral_code: user.referral_code
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(registerNewClientSuccess());
  } catch (error) {
    yield put(
      registerNewClientFail({
        error: error.response.data
      })
    );
  } finally {
    yield put(push('/partner/clients'));
  }
}

function* getClientSaga({ id }) {
  const url = `api/partners/companies/${id}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getClientSuccess({
        client: request.data
      })
    );
  } catch (error) {
    yield put(
      getClientFail({
        error: error.response.data
      })
    );
  }
}

function* updateClientSaga({ id }) {
  const url = `api/partners/companies/${id}/`;
  const comment = yield select(makeSelectClientComment());
  const options = {
    method: 'patch',
    data: {
      comment
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updateClientSuccess());
  } catch (error) {
    yield put(
      updateClientFail({
        error: error.response.data
      })
    );
  }
}

function* getManagersListSaga({ id }) {
  const page = yield select(makeSelectManagersPage());
  const search = yield select(makeSelectManagersSearch());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (!isEmpty(search)) {
    params.search = search;
  }
  const url = `api/partners/companies/${id}/managers/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getManagersListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getManagersListFail({
        error: error.response.data
      })
    );
  }
}

function* getRequisitesListSaga({ id }) {
  const page = yield select(makeSelectRequisitesPage());
  const offset = page * 25 - 25;
  let url = `api/partners/companies/${id}/organizations/`;

  if (page > 1) {
    url = `api/partners/companies/${id}/organizations/?offset=${offset}`;
  }

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getRequisitesListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getRequisitesListFail({
        error: error.response.data
      })
    );
  }
}

function* getFinancesListSaga({ id }) {
  const page = yield select(makeSelectFinancesPage());
  const fromDateFilter = yield select(makeSelectFinancesFromDateFilter());
  const toDateFilter = yield select(makeSelectFinancesToDateFilter());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (!isEmpty(fromDateFilter)) {
    params.from_date = fromDateFilter;
  }

  if (!isEmpty(toDateFilter)) {
    params.to_date = toDateFilter;
  }

  const url = `api/partners/companies/${id}/income_items/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getFinancesListSuccess({
        rewardAmountSum: request.data.reward_amount__sum,
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getFinancesListFail({
        error: error.response.data
      })
    );
  }
}

function* sendInviteSaga() {
  const url = 'api/partners/companies/send_email/';
  const form = yield select(makeSelectInviteForm());
  const options = {
    method: 'post',
    data: {
      to_email: form.email,
      welcome_text: form.message
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(sendInviteSuccess());
  } catch (e) {
    yield put(sendInviteFail());
  }
}

function* changeFilterFieldSaga({ field, id }) {
  if (field === 'clients') {
    yield put(getClientsList());
  } else if (field === 'managers') {
    yield put(getManagersList({ id }));
  }
}

function* changeIsMineFilterSaga() {
  yield put(getClientsList());
}

function* changeDateFilterSaga({ id }) {
  yield put(getFinancesList({ id }));
}

export default function* partnerClientsSaga() {
  yield takeLeading(GET_CLIENTS_LIST, getClientsListSaga);
  yield takeLeading(CHANGE_CLIENTS_LIST_PAGE, getClientsListSaga);
  yield takeLeading(REGISTER_NEW_CLIENT, registerNewClientSaga);
  yield takeLeading(GET_CLIENT, getClientSaga);
  yield takeLeading(UPDATE_CLIENT, updateClientSaga);
  yield takeLeading(GET_MANAGERS_LIST, getManagersListSaga);
  yield takeLeading(GET_REQUISITES_LIST, getRequisitesListSaga);
  yield takeLeading(GET_FINANCES_LIST, getFinancesListSaga);
  yield takeLeading(SEND_INVITE, sendInviteSaga);
  yield takeLeading(CHANGE_FILTER_FIELD, changeFilterFieldSaga);
  yield takeLeading(CHANGE_IS_MINE_FILTER, changeIsMineFilterSaga);
  yield takeLeading(CHANGE_DATE_FILTER, changeDateFilterSaga);
}
