import React from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';
import Button from '@atlaskit/button';
import AnimatedNumber from 'react-animated-number';

import './index.scss';

import Bg from './bg.jpg';

const Wrapper = styled.section`
  background-color: #fafafc;
  display: flex;
  padding: 100px 0 20px;
  width: 100%;

  @media (max-width: 1200px) and (min-width: 1021px) {
    min-height: auto;
    padding: 40px 0;
  }

  @media (max-width: 1020px) {
    margin-top: 50px;
    min-height: auto;
    padding: 40px 0;
    width: 100%;
  }
`;

const Inner = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1020px) {
    display: block;
    margin: 0 auto;
    width: calc(100% - 30px);
  }
`;

const Left = styled.div`
  margin-left: auto;
  width: 560px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(50% - 15px);
  }

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const Right = styled.div`
  background-image: url(${Bg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50%;

  @media (max-width: 1020px) {
    height: 200px;
    margin-top: 50px;
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 29px;
  color: #172b4d;
  letter-spacing: 0.32px;
  line-height: 32px;

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  color: #172b4d;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin-top: 20px;

  @media (max-width: 1020px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
  width: 100%;

  @media (max-width: 1020px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const Slider = styled.div`
  width: 260px;

  @media (max-width: 1020px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const Label = styled.label`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 43px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;
`;

const Result = styled.div`
  color: #172b4d;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.33px;
  text-align: center;
  line-height: 28px;
`;

const ResultText = styled.div`
  font-size: 16px;
  color: #172b4d;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 15px;
  text-align: center;
`;

const Note = styled.p`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: 93px;

  @media (max-width: 1020px) {
    margin-top: 30px;
  }
`;

class Calculator extends React.Component {
  state = {
    vacancy: 1,
    candidate: 15,
    time: 555,
    economy: 17.3
  };

  handleCalculate = () => {
    const { vacancy, candidate } = this.state;

    const time = vacancy * candidate * 37;
    const platformTime = vacancy * 32;
    const economy = time / platformTime;

    this.setState({
      time,
      economy
    });
  };

  render() {
    const { time, economy } = this.state;

    return (
      <Wrapper>
        <Inner>
          <Left>
            <Title>Сколько времени вы сэкономите на подборе сотрудников</Title>
            <Text>
              Среднее время на обработку одной вакансии c 15 кандидатами на нашем сервисе составляет
              32 минуты.  А&nbsp;у&nbsp;вас?
            </Text>

            <StyledWrapper>
              <Slider>
                <Label>Количество вакансий</Label>
                <InputRange
                  minValue={1}
                  maxValue={10}
                  value={this.state.vacancy}
                  onChange={vacancy => this.setState({ vacancy })}
                />
              </Slider>
              <Slider>
                <Label>Количество кандидатов на 1 вакансию</Label>
                <InputRange
                  minValue={1}
                  maxValue={30}
                  value={this.state.candidate}
                  onChange={candidate => this.setState({ candidate })}
                />
              </Slider>
            </StyledWrapper>

            <ButtonWrapper>
              <StyledButton appearance="primary" onClick={this.handleCalculate}>
                Рассчитать экономию времени
              </StyledButton>
            </ButtonWrapper>

            <StyledWrapper>
              <Slider>
                <Result>
                  <AnimatedNumber
                    duration={500}
                    formatValue={n => parseInt(n)}
                    stepPrecision={1}
                    value={time}
                  />{' '}
                  мин.
                </Result>
                <ResultText>
                  Ваше время обработки
                  <br />
                  вакансии*
                </ResultText>
              </Slider>
              <Slider>
                <Result>
                  <AnimatedNumber
                    duration={500}
                    formatValue={n =>
                      n
                        .toFixed(1)
                        .toString()
                        .replace('.', ',')
                    }
                    stepPrecision={1}
                    value={economy}
                  />{' '}
                  раза
                </Result>
                <ResultText>Ваша экономия времени с&nbsp;помощью “HRHelper”</ResultText>
              </Slider>
            </StyledWrapper>

            <Note>*Среднее время на одно собеседование — 37&nbsp;мин.</Note>
          </Left>
          <Right />
        </Inner>
      </Wrapper>
    );
  }
}

export default Calculator;
