import styled from 'styled-components';

const PageDesc = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #172b4d;
  letter-spacing: -0.32px;
  line-height: 20px;
  margin-top: 10px;
`;

export default PageDesc;
