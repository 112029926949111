import { fromJS } from 'immutable';

import {
  LOADING_END,
  LOADING_TABLE_END,
  STAFF_LIST_FILTER_REQUEST,
  STAFF_LIST_REQUEST,
  STAFF_LIST_REQUEST_SUCCESS,
  CHANGE_STAFF_COMMENT,
  CHANGE_STAFF_FLAGS,
  CHANGE_STAFF_PAGE,
  CHANGE_STAFF_SORT,
  STAFF_READ_REQUEST,
  STAFF_READ_REQUEST_SUCCESS,
  STAFF_READ_REQUEST_FAIL,
  STAFF_UPDATE_REQUEST,
  STAFF_UPDATE_REQUEST_SUCCESS,
  STAFF_UPDATE_REQUEST_FAIL,
  STAFF_REPORT_REQUEST,
  STAFF_REPORT_REQUEST_SUCCESS,
  STAFF_REPORT_REQUEST_FAIL,
  CHANGE_STAFF_COMPARE_FILTER,
  CHANGE_STAFF_COMPARE_PAGE,
  CHANGE_STAFF_COMPARE_SORT,
  STAFF_COMPARE_REQUEST,
  STAFF_COMPARE_REQUEST_SUCCESS,
  STAFF_COMPARE_REQUEST_FAIL,
  STAFF_DELETE_REQUEST,
  STAFF_DELETE_REQUEST_SUCCESS,
  STAFF_DELETE_REQUEST_FAIL,
  CHANGE_COMPARE_PROFILE,
  CHANGE_COMPARE_PROFILE_INPUT,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_REQUEST_SUCCESS,
  PROFILE_LIST_REQUEST_FAIL,
  CREATE_COMPARE_REQUEST,
  CREATE_COMPARE_REQUEST_SUCCESS,
  CREATE_COMPARE_REQUEST_FAIL,
  DELETE_COMPARE_REQUEST,
  DELETE_COMPARE_REQUEST_SUCCESS,
  DELETE_COMPARE_REQUEST_FAIL,
  GET_COMPARE_REQUEST,
  GET_COMPARE_REQUEST_SUCCESS,
  GET_COMPARE_REQUEST_FAIL,
  CHANGE_INVITE_COMMENT,
  CHANGE_INVITE_FULL_NAME,
  CHANGE_INVITE_EMAIL,
  CHANGE_INVITE_NAME,
  CHANGE_INVITE_TEXT,
  CHANGE_INVITE_FLAGS,
  CHANGE_INVITE_MODALS,
  SEND_INVITE_USER_REQUEST,
  SEND_INVITE_USER_REQUEST_SUCCESS,
  SEND_INVITE_USER_REQUEST_FAIL,
  GET_USER_READ_REQUEST,
  GET_USER_READ_REQUEST_SUCCESS,
  GET_USER_READ_REQUEST_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_SUCCESS,
  UPDATE_USER_REQUEST_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST_SUCCESS,
  UPDATE_USER_PASSWORD_REQUEST_FAIL,
  CHANGE_USER_NAME,
  CHANGE_USER_SURNAME,
  CHANGE_USER_COMPANY_NAME,
  CHANGE_USER_PHONE_NUMBER,
  CHANGE_USER_EMAIL,
  CHANGE_USER_PASSWORD1,
  CHANGE_USER_PASSWORD2,
  GET_MANAGERS_LIST_REQUEST,
  GET_MANAGERS_LIST_REQUEST_SUCCESS,
  GET_MANAGERS_LIST_REQUEST_FAIL,
  CHANGE_NAME_FILTER,
  CHANGE_MANAGERS_FILTER,
  CHANGE_WARNING_FLAG,
  CHANGE_ADDS_REPORTS_COUNT,
  CHANGE_ADDS_MODAL,
  CHANGE_ADDS_FLAG,
  ADDS_REPORTS_REQUEST,
  ADDS_REPORTS_REQUEST_SUCCESS,
  ADDS_REPORTS_REQUEST_FAIL,
  CHECK_ORDER_REQUEST,
  CHECK_ORDER_REQUEST_SUCCESS,
  CHECK_ORDER_REQUEST_FAIL,
  SEND_TO_PAYMENT_PAGE,
  CHANGE_REPORTS_FLAGS,
  CHANGE_REPORTS_EMAIL,
  CHANGE_REPORTS_LOADING,
  CHANGE_REPORTS_MODALS,
  SEND_REPORTS_EMAIL_REQUEST,
  SEND_REPORTS_EMAIL_REQUEST_SUCCESS,
  SEND_REPORTS_EMAIL_REQUEST_FAIL,
  CHANGE_ORDER_REPORT_BY_RANK,
  CHANGE_MISMATCHED_PATTERNS,
  CHANGE_REPORT_TYPE,
  CHANGE_COMPARE_INFO_EMAIL,
  CHANGE_COMPARE_INFO_LOADING,
  CHANGE_COMPARE_INFO_MODAL,
  CHANGE_COMPARE_INFO_FLAG,
  SEND_COMPARE_INFO_REQUEST,
  SEND_COMPARE_INFO_REQUEST_SUCCESS,
  SEND_COMPARE_INFO_REQUEST_FAIL,
  ADD_REPORT_TO_COMPARE,
  DELETE_REPORT_FROM_COMPARE,
  UPDATE_REPORTS_TO_COMPARE,
  CHANGE_REPORT_SHAPE,
  SEND_INVITES_BY_FILE,
  SEND_INVITES_BY_FILE_SUCCESS,
  SEND_INVITES_BY_FILE_FAIL,
  CHANGE_INVITE_BY_FILE_FLAGS,
  DECREASE_UNREAD_REPORTS_COUNT
} from './constants';

const initialState = fromJS({
  addsFlag: [],
  addsModal: [],
  addsReportsCount: 1,
  addsReportsResult: 1,
  addsReturnUrl: '',
  addsTotal: 0,
  company: {
    id: 0,
    billing_plan: {
      id: 0,
      position: 0,
      name: null,
      included_reports_count: 0,
      price_per_year: 0,
      ranges: []
    },
    created_at: null,
    extra_reports_count: 0,
    name: null,
    inn: null,
    billing_plan_valid_until: null,
    billing_plan_available_reports_count: 0,
    owner: 0
  },
  compareId: null,
  compareProfileInput: '',
  compareProfileLoading: false,
  compareProfiles: [],
  compareRead: {},
  compareReportsId: {},
  compareInfo: {
    compareId: 0,
    flag: [],
    modal: [],
    email: '',
    loading: false
  },
  error: false,
  errorCode: null,
  invite: {
    comment: '',
    email: '',
    full_name: '',
    name: '',
    type: 0,
    welcome_text: ''
  },
  inviteFlags: [],
  inviteModals: [],
  inviteWithFileCount: 0,
  inviteWithFlags: [],
  loading: false,
  loadingAddsReports: false,
  loadingInvite: false,
  loadingPassword: false,
  loadingProfile: false,
  loadingTable: false,
  loadingUpdate: false,
  loadingUser: false,
  managerFilter: 0,
  managersList: [],
  nameFilter: '',
  orderId: null,
  profileList: [],
  reportEmail: '',
  reportFlags: [],
  reportId: null,
  reportIds: [],
  reportLoading: false,
  reportModals: [],
  sort: {
    key: '',
    order: ''
  },
  staffCompare: [],
  staffCompareCount: 0,
  staffCompareFilter: '',
  staffComparePage: 1,
  staffCompareSort: {
    key: '',
    order: ''
  },
  staffCount: 0,
  staffId: null,
  staffPage: 1,
  staffList: [],
  staffRead: {
    id: 0,
    full_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    testing_date: '',
    testing_result: {},
    comment: '',
    is_report_read: false,
    date_of_birth: ''
  },
  staffReportSize: 0,
  staffReport: {
    createdAt: null,
    reports: []
  },
  staffUpdateFlags: [],
  user: {
    company_inn: '',
    company_name: '',
    email: '',
    first_name: '',
    last_name: '',
    new_email: '',
    password_one: '',
    password_two: '',
    phone_number: '',
    pk: 0,
    role: '1'
  },
  reportType: 0,
  reportShape: 0,
  reportOrderByRank: false,
  showMismatched: false,
  warningFlag: false,
  inviteFiles: []
});

function staffReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_REPORT_SHAPE:
      return state.set('reportShape', action.reportShape);
    case CHANGE_REPORT_TYPE:
      return state.set('reportType', action.reportType);
    case SEND_COMPARE_INFO_REQUEST:
      return state
        .setIn(['compareInfo', 'compareId'], action.compareId)
        .setIn(['compareInfo', 'flag'], fromJS([]))
        .setIn(['compareInfo', 'loading'], true);
    case SEND_COMPARE_INFO_REQUEST_SUCCESS:
      return state
        .setIn(['compareInfo', 'flag'], fromJS([1]))
        .setIn(['compareInfo', 'loading'], false)
        .setIn(['compareInfo', 'modal'], fromJS([]))
        .setIn(['compareInfo', 'email'], '');
    case SEND_COMPARE_INFO_REQUEST_FAIL:
      return state
        .setIn(['compareInfo', 'flag'], fromJS([-1]))
        .setIn(['compareInfo', 'loading'], false)
        .setIn(['compareInfo', 'modal'], fromJS([]));
    case CHANGE_COMPARE_INFO_EMAIL:
      return state.setIn(['compareInfo', 'email'], action.email);
    case CHANGE_COMPARE_INFO_LOADING:
      return state.setIn(['compareInfo', 'loading'], action.loading);
    case CHANGE_COMPARE_INFO_MODAL:
      return state.setIn(['compareInfo', 'modal'], fromJS(action.modal));
    case CHANGE_COMPARE_INFO_FLAG:
      return state.setIn(['compareInfo', 'flag'], fromJS(action.flag));
    case CHANGE_MISMATCHED_PATTERNS:
      return state.set('showMismatched', action.showMismatched);
    case CHANGE_ORDER_REPORT_BY_RANK:
      return state.set('reportOrderByRank', action.order);
    case CHANGE_REPORTS_FLAGS:
      return state.set('reportFlags', fromJS(action.reportFlags));
    case CHANGE_REPORTS_EMAIL:
      return state.set('reportEmail', action.reportEmail);
    case CHANGE_REPORTS_LOADING:
      return state.set('reportLoading', action.reportLoading);
    case CHANGE_REPORTS_MODALS:
      return state.set('reportModals', fromJS(action.reportModals));
    case SEND_REPORTS_EMAIL_REQUEST:
      return state.set('reportId', action.reportId).set('reportLoading', true);
    case SEND_REPORTS_EMAIL_REQUEST_SUCCESS:
      return state
        .set('reportModals', fromJS([]))
        .set('reportFlags', fromJS([1]))
        .set('reportEmail', '')
        .set('reportLoading', false);
    case SEND_REPORTS_EMAIL_REQUEST_FAIL:
      return state.set('reportModals', fromJS([])).set('reportLoading', false);
    case CHANGE_ADDS_FLAG:
      return state.set('addsFlag', fromJS(action.flag));
    case CHECK_ORDER_REQUEST:
      return state
        .set('addsReportsResult', action.addsReportsResult)
        .set('addsFlag', fromJS([]))
        .set('orderId', action.orderId);
    case CHECK_ORDER_REQUEST_SUCCESS:
      return state.set('addsFlag', fromJS([1]));
    case CHECK_ORDER_REQUEST_FAIL:
      return state.set('addsFlag', fromJS([-1]));
    case CHANGE_ADDS_REPORTS_COUNT:
      return state.set('addsReportsCount', action.count);
    case CHANGE_ADDS_MODAL:
      return state.set('addsModal', fromJS(action.addsModal));
    case SEND_TO_PAYMENT_PAGE:
      return state
        .set('addsModal', fromJS([]))
        .set('addsReturnUrl', action.url)
        .set('addsTotal', action.totalReports);
    case ADDS_REPORTS_REQUEST:
      return state.set('addsFlag', fromJS([])).set('loadingAddsReports', true);
    case ADDS_REPORTS_REQUEST_SUCCESS:
      return state
        .set('addsModal', fromJS([]))
        .set('addsReportsCount', 1)
        .set('addsReportsResult', action.result)
        .set('loadingAddsReports', false);
    case ADDS_REPORTS_REQUEST_FAIL:
      return state
        .set('addsModal', fromJS([]))
        .set('addsReportsCount', 1)
        .set('loadingAddsReports', false);
    case LOADING_END:
      return state.set('loading', false);
    case LOADING_TABLE_END:
      return state.set('loadingTable', false);

    case CHANGE_STAFF_COMMENT:
      return state.setIn(['staffRead', 'comment'], action.comment);
    case CHANGE_STAFF_FLAGS:
      return state.set('staffUpdateFlags', fromJS(action.flags));

    case CHANGE_STAFF_SORT:
      return state
        .setIn(['sort', 'key'], action.key)
        .setIn(['sort', 'order'], action.order)
        .set('staffPage', 1);
    case CHANGE_NAME_FILTER:
      return state.set('nameFilter', action.nameFilter).set('staffPage', 1);
    case CHANGE_MANAGERS_FILTER:
      return state.set('managerFilter', action.managerFilter).set('staffPage', 1);
    case GET_MANAGERS_LIST_REQUEST:
      return state.set('managersList', fromJS([]));
    case GET_MANAGERS_LIST_REQUEST_SUCCESS:
      return state.set('managersList', action.managersList);
    case GET_MANAGERS_LIST_REQUEST_FAIL:
      return state.set('managersList', fromJS([]));

    case STAFF_LIST_FILTER_REQUEST:
      return state.set('loadingTable', true);
    case STAFF_LIST_REQUEST:
      return state.set('loading', true);
    case STAFF_LIST_REQUEST_SUCCESS:
      return state.set('staffList', action.staffList).set('staffCount', action.staffCount);

    case CHANGE_STAFF_PAGE:
      return state.set('staffPage', action.staffPage);

    case STAFF_READ_REQUEST:
      return state.set('loading', true).set('staffId', action.staffId);
    case STAFF_READ_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['staffRead', 'id'], action.id)
        .setIn(['staffRead', 'full_name'], action.full_name)
        .setIn(['staffRead', 'first_name'], action.first_name)
        .setIn(['staffRead', 'last_name'], action.last_name)
        .setIn(['staffRead', 'email'], action.email)
        .setIn(['staffRead', 'phone_number'], action.phone_number)
        .setIn(['staffRead', 'testing_date'], action.testing_date)
        .setIn(['staffRead', 'testing_result'], action.testing_result)
        .setIn(['staffRead', 'comment'], action.comment)
        .setIn(['staffRead', 'is_report_read'], action.is_report_read)
        .setIn(['staffRead', 'date_of_birth'], action.date_of_birth);
    case STAFF_READ_REQUEST_FAIL:
      return state.set('loading', false);

    case STAFF_UPDATE_REQUEST:
      return state.set('loadingUpdate', true).set('staffUpdateFlags', fromJS([]));
    case STAFF_UPDATE_REQUEST_SUCCESS:
      return state.set('loadingUpdate', false).set('staffUpdateFlags', fromJS([1]));
    case STAFF_UPDATE_REQUEST_FAIL:
      return state.set('loadingUpdate', false);

    case STAFF_REPORT_REQUEST:
      return state
        .set('loading', true)
        .set('error', false)
        .set('staffReportSize', 0)
        .setIn(['staffReport', 'createdAt'], null)
        .setIn(['staffReport', 'reports'], fromJS([]))
        .set('staffId', action.staffId);
    case STAFF_REPORT_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('reportId', action.reportId)
        .set('reportIds', fromJS(action.reportIds))
        .set('staffReportSize', action.staffReport.get('reports').size)
        .set('staffReport', action.staffReport);
    case STAFF_REPORT_REQUEST_FAIL:
      return state
        .set('loading', false)
        .set('errorCode', action.errorCode)
        .set('error', true);

    case CHANGE_STAFF_COMPARE_FILTER:
      return state
        .set('staffCompareFilter', action.nameFilter)
        .set('staffComparePage', 1)
        .set('loadingTable', true);
    case CHANGE_STAFF_COMPARE_PAGE:
      return state.set('staffComparePage', action.staffComparePage).set('loadingTable', true);
    case CHANGE_STAFF_COMPARE_SORT:
      return state
        .setIn(['staffCompareSort', 'key'], action.key)
        .setIn(['staffCompareSort', 'order'], action.order)
        .set('staffComparePage', 1)
        .set('loadingTable', true);
    case STAFF_COMPARE_REQUEST:
      return state.set('loading', true).set('staffId', action.staffId);
    case STAFF_COMPARE_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('staffCompare', action.staffCompare)
        .set('staffCompareCount', action.staffCompareCount);
    case STAFF_COMPARE_REQUEST_FAIL:
      return state
        .set('errorCode', action.errorCode)
        .set('error', true)
        .set('loading', false);

    case STAFF_DELETE_REQUEST:
      return state.set('loading', true).set('staffId', action.staffId);
    case STAFF_DELETE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case STAFF_DELETE_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_COMPARE_PROFILE:
      return state.set('compareProfiles', fromJS(action.compareProfiles));
    case CHANGE_COMPARE_PROFILE_INPUT:
      return state.set('compareProfileInput', action.input).set('compareProfileLoading', true);

    case PROFILE_LIST_REQUEST:
      return state.set('compareProfileLoading', true);
    case PROFILE_LIST_REQUEST_SUCCESS:
      return state.set('compareProfileLoading', false).set('profileList', action.profileList);
    case PROFILE_LIST_REQUEST_FAIL:
      return state.set('compareProfileLoading', false);

    case CREATE_COMPARE_REQUEST:
      return state.set('loading', true).set('staffId', action.staffId);
    case CREATE_COMPARE_REQUEST_SUCCESS:
      return state.set('loading', false).set('compareProfiles', fromJS([]));
    case CREATE_COMPARE_REQUEST_FAIL:
      return state.set('loading', false).set('compareProfiles', fromJS([]));

    case DELETE_COMPARE_REQUEST:
      return state
        .set('loading', true)
        .set('staffId', action.staffId)
        .set('compareId', action.compareId);
    case DELETE_COMPARE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case DELETE_COMPARE_REQUEST_FAIL:
      return state.set('loading', false);

    case GET_COMPARE_REQUEST:
      return state.set('loading', true).set('compareId', action.compareId);
    case GET_COMPARE_REQUEST_SUCCESS:
      return state.set('loading', false).set('compareRead', action.compareRead);
    case GET_COMPARE_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_INVITE_COMMENT:
      return state.setIn(['invite', 'comment'], action.comment);
    case CHANGE_INVITE_EMAIL:
      return state.setIn(['invite', 'email'], action.email);
    case CHANGE_INVITE_NAME:
      return state.setIn(['invite', 'name'], action.name);
    case CHANGE_INVITE_FULL_NAME:
      return state.setIn(['invite', 'full_name'], action.fullName);
    case CHANGE_INVITE_TEXT:
      return state.setIn(['invite', 'welcome_text'], action.text);

    case CHANGE_INVITE_FLAGS:
      return state.set('inviteFlags', fromJS(action.flags));
    case CHANGE_INVITE_MODALS:
      return state.set('inviteModals', fromJS(action.modals));

    case SEND_INVITE_USER_REQUEST:
      return state.set('loadingInvite', true);
    case SEND_INVITE_USER_REQUEST_SUCCESS:
      return state
        .set('loadingInvite', false)
        .setIn(['invite', 'email'], '')
        .setIn(['invite', 'full_name'], '')
        .setIn(['invite', 'name'], '')
        .setIn(['invite', 'type'], 0)
        .setIn(['invite', 'comment'], '')
        .setIn(
          ['invite', 'welcome_text'],
          localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            ? localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            : `${state.getIn(['user', 'first_name'])} ${state.getIn([
                'user',
                'last_name'
              ])} из компании ${state.getIn([
                'user',
                'company_name'
              ])} пригласил Вас пройти опрос на сервисе HRHelper.`
        )
        .set('inviteFlags', fromJS([1]))
        .set('inviteModals', fromJS([]));
    case SEND_INVITE_USER_REQUEST_FAIL:
      return state
        .set('loadingInvite', false)
        .setIn(['invite', 'email'], '')
        .setIn(['invite', 'full_name'], '')
        .setIn(['invite', 'name'], '')
        .setIn(['invite', 'type'], 0)
        .setIn(['invite', 'comment'], '')
        .set('inviteFlags', fromJS([]))
        .set('inviteModals', fromJS([]));

    case GET_USER_READ_REQUEST:
      return state.set('loadingUser', true);
    case GET_USER_READ_REQUEST_SUCCESS:
      return state
        .set('loadingUser', false)
        .setIn(['user', 'company_inn'], action.company_inn)
        .setIn(['user', 'company_name'], action.company_name)
        .setIn(['user', 'email'], action.email)
        .setIn(['user', 'first_name'], action.first_name)
        .setIn(['user', 'last_name'], action.last_name)
        .setIn(['user', 'phone_number'], action.phone_number)
        .setIn(['user', 'pk'], action.pk)
        .setIn(['user', 'role'], action.role)
        .setIn(['user', 'password_one'], '')
        .setIn(['user', 'password_two'], '')
        .setIn(
          ['invite', 'welcome_text'],
          localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            ? localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            : `${action.first_name} ${action.last_name} из компании ${
                action.company_name
              } пригласил Вас пройти опрос на сервисе HRHelper.`
        )
        .set('company', fromJS(action.company))
        .set('warningFlag', action.warningFlag);
    case GET_USER_READ_REQUEST_FAIL:
      return state.set('loadingUser', false);

    case CHANGE_WARNING_FLAG:
      return state.set('warningFlag', action.warningFlag);

    case UPDATE_USER_REQUEST:
      return state.set('loadingProfile', true);
    case UPDATE_USER_REQUEST_SUCCESS:
      return state.set('loadingProfile', false);
    case UPDATE_USER_REQUEST_FAIL:
      return state.set('loadingProfile', false);

    case CHANGE_USER_NAME:
      return state.setIn(['user', 'first_name'], action.name);
    case CHANGE_USER_SURNAME:
      return state.setIn(['user', 'last_name'], action.surname);
    case CHANGE_USER_COMPANY_NAME:
      return state.setIn(['user', 'company_name'], action.companyName);
    case CHANGE_USER_PHONE_NUMBER:
      return state.setIn(['user', 'phone_number'], action.phone);
    case CHANGE_USER_EMAIL:
      return state.setIn(['user', 'new_email'], action.email);
    case CHANGE_USER_PASSWORD1:
      return state.setIn(['user', 'password_one'], action.passwordOne);
    case CHANGE_USER_PASSWORD2:
      return state.setIn(['user', 'password_two'], action.passwordTwo);

    case UPDATE_USER_PASSWORD_REQUEST:
      return state.set('loadingPassword', true);
    case UPDATE_USER_PASSWORD_REQUEST_SUCCESS:
      return state
        .set('loadingPassword', false)
        .setIn(['user', 'password_one'], '')
        .setIn(['user', 'password_two'], '');
    case UPDATE_USER_PASSWORD_REQUEST_FAIL:
      return state.set('loadingPassword', false);

    case ADD_REPORT_TO_COMPARE:
      return state.set('compareReportsId', state.get('compareReportsId').set(action.id, action.id));
    case DELETE_REPORT_FROM_COMPARE:
      return state.set('compareReportsId', state.get('compareReportsId').delete(action.id));
    case UPDATE_REPORTS_TO_COMPARE:
      return state.set('compareReportsId', action.compareReportsId);
    case SEND_INVITES_BY_FILE:
      return state
        .set('inviteWithFileCount', 0)
        .set('inviteWithFlags', fromJS([]))
        .set('loadingInvite', true);
    case SEND_INVITES_BY_FILE_SUCCESS:
      return state
        .set('inviteWithFileCount', action.count)
        .set('inviteWithFlags', fromJS([1]))
        .set('inviteModals', fromJS([]))
        .set('loadingInvite', false);
    case SEND_INVITES_BY_FILE_FAIL:
      return state
        .set('inviteWithFileCount', 0)
        .set('inviteWithFlags', fromJS([-1]))
        .set('inviteModals', fromJS([]))
        .set('loadingInvite', false);
    case CHANGE_INVITE_BY_FILE_FLAGS:
      return state.set('inviteWithFlags', fromJS(action.flags));
    case DECREASE_UNREAD_REPORTS_COUNT:
      const company = state.get('company').toJS();
      company.unread_reports_count -= 1;
      return state.set('company', fromJS(company))

    default:
      return state;
  }
}

export default staffReducer;
