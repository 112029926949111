import styled from 'styled-components';

const OnlyMobile = styled.div`
  display: none;

  @media (max-width: 1020px) {
    display: block;
  }

  @media print {
    display: none;
  }
`;

export default OnlyMobile;
