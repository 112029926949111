import styled from 'styled-components';

const Risk = styled.div`
  background: #f0f2f5;
  border-radius: 3px;
  padding: 24px 40px;
  width: calc(100% - 80px);
`;

export default Risk;
