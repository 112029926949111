import React, { Fragment } from 'react';
import StaffRootView from 'navigation/routes/StaffRootView';
import SetActiveView from 'navigation/routes/SetActiveView';
import VideoPage from 'pages/IndividualProfile/Video';

const IndividualVideoView = () => (
  <Fragment>
    <StaffRootView />
    <SetActiveView id="individual/videos" />
    <VideoPage />
  </Fragment>
);

export default IndividualVideoView;
