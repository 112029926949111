import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'axios';
import apiUrl, { pageHostUrl } from 'utils/serverConfig';

// import {
//   getUserInfo,
// } from 'pages/Home/actions';

import { LOGIN_REQUEST, LOGOUT } from './constants';

import { loginSuccess, loginFail } from './actions';

import { makeSelectLogin, makeSelectPassword } from './selectors';

function* loginSaga() {
  const url = `${apiUrl}/api/staff/auth/login/`;
  const login = yield select(makeSelectLogin());
  const password = yield select(makeSelectPassword());
  const options = {
    method: 'post',
    data: {
      email: login,
      password: password
    }
  };

  try {
    const request = yield call(axios, url, options);

    const key = request.data.key;
    localStorage.setItem('staffKey', key);

    yield put(loginSuccess());
    // yield put(getUserInfo());
    yield put(push('/individual/report'));
  } catch (e) {
    const errorMessage = e.response.data.non_field_errors[0];
    yield put(
      loginFail({
        errorMessage
      })
    );
  }
}

function logout() {
  localStorage.removeItem('staffKey');
  window.location.href = `${pageHostUrl}/individual/login`;
}

export default function* loginPageSaga() {
  yield takeLeading(LOGIN_REQUEST, loginSaga);
  yield takeLeading(LOGOUT, logout);
}
