import styled from 'styled-components';

const MenuItem = styled.div`
  font-size: 16px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  margin: 12px 0;
  width: 260px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1020px) {
    font-size: 14px;
  }
`;

export default MenuItem;
