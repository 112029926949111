import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import ClientListPage from 'pages/PartnerProfile/Clients/List';

const ClientListView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/index" />
    <ClientListPage />
  </Fragment>
);

export default ClientListView;
