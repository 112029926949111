import React, { Fragment } from 'react';
import Form from '@atlaskit/form';
import TextArea from '@atlaskit/field-text-area';

import FileInput from 'components/FileInput';
import FieldGroup from 'pages/Home/styled/FieldGroup';
import Field from 'pages/Home/styled/Field';

import Template from './template.xlsx';

const InviteByFileForm = ({ invite, isFileInvalid, onChangeText, onChangeComment, onDropFile }) => (
  <Fragment>
    <p>
      Скачайте{' '}
      <a href={Template} target="_blank" rel="noopener noreferrer">
        шаблон XLSX-файла
      </a>
      , заполните его контактными данными людей, и загрузите его в форме, чтобы массово отправить
      ссылки на опрос.
    </p>
    <FileInput onDrop={onDropFile} isInvalid={isFileInvalid} />
    <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
      <FieldGroup>
        <Field width="100%">
          <TextArea
            enableResize={false}
            label="Текст приглашения"
            minimumRows={4}
            name="text"
            onChange={onChangeText}
            placeholder=""
            shouldFitContainer
            value={invite.welcome_text}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field width="100%">
          <TextArea
            enableResize={false}
            label="Комментарий"
            minimumRows={4}
            name="comment"
            onChange={onChangeComment}
            placeholder=""
            shouldFitContainer
            value={invite.comment}
          />
        </Field>
      </FieldGroup>
    </Form>
  </Fragment>
);

export default InviteByFileForm;
