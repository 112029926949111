import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileCreateCustomPage from 'pages/ProfileCreateCustom';

const ProfileCreateCustomView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileCreateCustomPage />
  </Fragment>
);

export default ProfileCreateCustomView;
