import styled from 'styled-components';

const MetaWrapper = styled.div`
  border-bottom: 1px solid rgba(9, 30, 66, 0.13);
  margin-top: 39px;
  padding-bottom: 51px;

  &:first-child {
    margin-top: 61px;
  }
`;

export default MetaWrapper;
