import styled from 'styled-components';

const Table = styled.div`
  overflow-x: scroll;
  padding-top: 110px;
  position: relative;
  user-select: none;
`;

export default Table;
