import React from 'react';
import Button from '@atlaskit/button';

class RouterA extends React.PureComponent {
  render() {
    const {
      children,
      className,
      disabled,
      href,
      target,
      onClick,
      onMouseEnter,
      onMouseLeave
    } = this.props;

    let result = (
      <a
        className={className}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        href={href}
        target={target}
      >
        {children}
      </a>
    );

    if (disabled === true) {
      result = <Button isDisabled={true}>{children}</Button>;
    }

    return result;
  }
}

export default RouterA;
