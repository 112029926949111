import styled from 'styled-components';
import bg from './logo_sm.png';
import my_bg from './my_logo_sm.png';

import service from "../../../../utils/checkService";

const Logo = styled.div`
  background-image: url(${service === "hrh24" ? bg : my_bg});
  background-size: contain;
  height: 24px;
  width: 24px;
`;

export default Logo;
