import { fromJS } from 'immutable';

import {
  ADD_ANSWER_MOVES,
  CHANGE_ANSWERS,
  CHANGE_ANSWER_TIME,
  GET_QUESTIONNARIE_LIST_REQUEST,
  GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS,
  GET_QUESTIONNARIE_LIST_REQUEST_FAIL,
  SEND_QUESTIONNARIE_REQUEST,
  SEND_QUESTIONNARIE_REQUEST_SUCCESS,
  SEND_QUESTIONNARIE_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: true,
  questionsList: null,
  answersCount: null,
  answersList: null,
  answersTime: null,
  questionnaireId: null,
  maxQuestionNumber: 0,
  invitationCode: ''
});

function testReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ANSWER_MOVES:
      return state.setIn(
        ['answersCount', action.index],
        state.getIn(['answersCount', action.index]) + 1
      );
    case CHANGE_ANSWER_TIME:
      return state
        .set(
          'maxQuestionNumber',
          action.currentQuestionNumber > state.get('maxQuestionNumber')
            ? action.currentQuestionNumber
            : state.get('maxQuestionNumber')
        )
        .setIn(['answersTime', action.index], action.time);
    case CHANGE_ANSWERS:
      return state.set('answersList', action.answersList);
    case GET_QUESTIONNARIE_LIST_REQUEST:
      return state
        .set('invitationCode', action.invitationCode)
        .set('loading', true)
        .set('answersCount', null)
        .set('questionsList', null)
        .set('answersList', null)
        .set('answersTime', null);
    case GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('questionnaireId', action.questionnaireId)
        .set('questionsList', action.questionsList)
        .set('answersCount', action.answersCount)
        .set('answersList', action.answersList)
        .set('answersTime', action.answersTime);
    case GET_QUESTIONNARIE_LIST_REQUEST_FAIL:
      return state
        .set('loading', false)
        .set('questionsList', null)
        .set('answersCount', null)
        .set('answersList', null)
        .set('answersTime', null);
    case SEND_QUESTIONNARIE_REQUEST:
      return state.set('loading', true);
    case SEND_QUESTIONNARIE_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('questionsList', null)
        .set('answersCount', null)
        .set('answersList', null)
        .set('answersTime', null);
    case SEND_QUESTIONNARIE_REQUEST_FAIL:
      return state
        .set('loading', false)
        .set('questionsList', null)
        .set('answersCount', null)
        .set('answersList', null)
        .set('answersTime', null);
    default:
      return state;
  }
}

export default testReducer;
