import { createSelector } from 'reselect';

const selectGlobal = state => state.get('search');

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectSearch = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('search')
  );

const makeSelectStaffList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffList')
  );

const makeSelectProfilesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profilesList')
  );

export { makeSelectLoading, makeSelectSearch, makeSelectStaffList, makeSelectProfilesList };
