import React, { Fragment, PureComponent } from 'react';
import Button from '@atlaskit/button';
import Dropzone from 'react-dropzone';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';

class FileInput extends PureComponent {
  onDrop = acceptedFiles => {
    this.props.onDrop(acceptedFiles);
  };

  render() {
    const { isInvalid } = this.props;

    return (
      <Dropzone accept=".xlsx" onDrop={this.onDrop} multiple={false}>
        {({
          acceptedFiles,
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        }) => {
          return (
            <Container
              isInvalid={isInvalid}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {acceptedFiles.length === 0 && (
                <Fragment>
                  Перетяните сюда готовый XLSX-файл или
                  <ButtonWrapper>
                    <Button appearance="primary" onClick={() => {}}>
                      Выберите файл
                    </Button>
                  </ButtonWrapper>
                </Fragment>
              )}
              {acceptedFiles.map(file => (
                <Fragment key={file.lastModified}>
                  <p>{file.name}</p>
                  <ButtonWrapper>
                    <Button appearance="primary" onClick={() => {}}>
                      Заменить файл
                    </Button>
                  </ButtonWrapper>
                </Fragment>
              ))}
            </Container>
          );
        }}
      </Dropzone>
    );
  }
}

export default FileInput;
