import { fromJS } from 'immutable';

import {
  GET_COMPARE_LIST_REQUEST,
  GET_COMPARE_LIST_SUCCESS,
  GET_COMPARE_LIST_FAIL,
  GET_STAFF_LIST_REQUEST,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST_FAIL,
  CHANGE_STAFF_INPUT,
  CHANGE_STAFF_SELECTOR,
  DELETE_STAFF_FROM_COMPARE,
  OPEN_ADD_MODAL,
  CLOSE_ADD_MODAL,
  CHANGE_COLUMN_POSITION,
  ADD_STAFF_TO_COMPARE, DELETE_ID_FROM_COMPARE
} from './constants';

const initialState = fromJS({
  addModal: [],
  compareIds: [],
  compareResult: [],
  loading: true,
  sections: [],
  staffInput: '',
  staffList: [],
  staffListLoading: true,
  staffSelector: []
});

function compareListReducer(state = initialState, action) {
  const storagedCompareIds = JSON.parse(localStorage.getItem("compareIds"));

  switch (action.type) {
    case GET_COMPARE_LIST_REQUEST:
      return state.set('loading', true).set('compareIds', fromJS(action.compareIds));
    case GET_COMPARE_LIST_SUCCESS:
      const newCompareIds = JSON.parse(localStorage.getItem("compareIds")).map(id => action.compareResult[id]);
      return state
        .set('loading', false)
        .set('compareResult', fromJS(newCompareIds))
        .set('sections', fromJS(action.sections));
    case GET_COMPARE_LIST_FAIL:
      return state.set('loading', false);
    case GET_STAFF_LIST_REQUEST:
      return state.set('staffListLoading', true);
    case GET_STAFF_LIST_SUCCESS:
      return state.set('staffListLoading', false).set('staffList', fromJS(action.staffList));
    case GET_STAFF_LIST_FAIL:
      return state.set('staffListLoading', false);
    case CHANGE_STAFF_INPUT:
      return state.set('staffInput', action.staffInput);
    case CHANGE_STAFF_SELECTOR:
      return state.set('staffSelector', fromJS(action.staffSelector));
    case DELETE_ID_FROM_COMPARE:
      const filteredCompIds = storagedCompareIds.filter(id => id !== action.id);
      localStorage.setItem("compareIds", JSON.stringify(filteredCompIds));
      return state.set('compareIds', fromJS(filteredCompIds));
    case DELETE_STAFF_FROM_COMPARE:
      const newCompareResult = state.get('compareResult').toJS().filter(r => r.staffId !== action.staffId);
      const filteredCompareIds = storagedCompareIds.filter(id => id !== action.staffId);
      localStorage.setItem("compareIds", JSON.stringify(filteredCompareIds));
      return state
        .set('compareResult',fromJS(newCompareResult))
        .set('compareIds', fromJS(filteredCompareIds));
    case ADD_STAFF_TO_COMPARE:
      const compIds = new Set(localStorage.getItem("compareIds") ? JSON.parse(localStorage.getItem("compareIds")) : []);
      compIds.add(action.id);
      localStorage.setItem('compareIds', JSON.stringify(Array.from(compIds)));
      return state.set('compareIds', fromJS(Array.from(compIds)));
    case OPEN_ADD_MODAL:
      return state.set('addModal', fromJS([1]));
    case CLOSE_ADD_MODAL:
      return state.set('addModal', fromJS([]));
    case CHANGE_COLUMN_POSITION:
      let storagedArray = JSON.parse(localStorage.getItem("compareIds"));
      const idx = storagedArray.findIndex(c => c === action.id);
      if(idx >= 0) {
        const temp = storagedArray[idx + action.diff];
        storagedArray[idx + action.diff] = storagedArray[idx];
        storagedArray[idx] = temp;
        const compRes = state.get('compareResult').toJS();
        const newCompRes = storagedArray.map(i => compRes.find(c => c.staffId === i));
        localStorage.setItem('compareIds', JSON.stringify(storagedArray));
        return state.set('compareIds', fromJS(storagedArray)).set('compareResult', fromJS(newCompRes));
      }
      return state
    default:
      return state;
  }
}

export default compareListReducer;
