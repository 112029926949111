import {
  CHANGE_SEARCH_INPUT,
  STAFF_SEARCH_REQUEST,
  STAFF_SEARCH_REQUEST_SUCCESS,
  STAFF_SEARCH_REQUEST_FAIL,
  PROFILE_SEARCH_REQUEST,
  PROFILE_SEARCH_REQUEST_SUCCESS,
  PROFILE_SEARCH_REQUEST_FAIL
} from './constants';

export function changeSearchInput(payload) {
  return {
    type: CHANGE_SEARCH_INPUT,
    ...payload
  };
}

export function staffSearch() {
  return {
    type: STAFF_SEARCH_REQUEST
  };
}

export function staffSearchSuccess(payload) {
  return {
    type: STAFF_SEARCH_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffSearchFail() {
  return {
    type: STAFF_SEARCH_REQUEST_FAIL
  };
}

export function profileSearch() {
  return {
    type: PROFILE_SEARCH_REQUEST
  };
}

export function profileSearchSuccess(payload) {
  return {
    type: PROFILE_SEARCH_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileSearchFail() {
  return {
    type: PROFILE_SEARCH_REQUEST_FAIL
  };
}
