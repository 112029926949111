import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import FinancesListPage from 'pages/PartnerProfile/Finances/List';

const FinancesListView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/index" />
    <FinancesListPage />
  </Fragment>
);

export default FinancesListView;
