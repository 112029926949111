import { fromJS } from 'immutable';

import {
  CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_INVITE_CODE_REQUEST_SUCCESS,
  CHECK_INVITE_CODE_REQUEST_FAIL,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_REQUEST_SUCCESS,
  QUESTIONS_LIST_REQUEST_FAIL,
  SEND_QUIZ_RESULT_REQUEST,
  SEND_QUIZ_RESULT_REQUEST_SUCCESS,
  SEND_QUIZ_RESULT_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  inviteCode: '',
  loading: true,
  profileQuestions: {
    answers: [],
    description: '',
    loading: true,
    name: ''
  },
  quizResultLoading: false
});

function chiefQuizReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ANSWER_CREATE_FROM_QUESTIONS:
      return state.setIn(
        [
          'profileQuestions',
          'answers',
          state
            .getIn(['profileQuestions', 'answers'])
            .findIndex(question => question.get('id') === action.questionId),
          'answers',
          state
            .getIn(['profileQuestions', 'answers'])
            .find(question => question.get('id') === action.questionId)
            .get('answers')
            .findIndex(answer => answer.get('id') === action.answerId),
          'value'
        ],
        action.value
      );
    case CHECK_INVITE_CODE_REQUEST:
      return state.set('loading', true).set('inviteCode', action.inviteCode);
    case CHECK_INVITE_CODE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case CHECK_INVITE_CODE_REQUEST_FAIL:
      return state.set('loading', false);
    case QUESTIONS_LIST_REQUEST:
      return state.setIn(['profileQuestions', 'loading'], true);
    case QUESTIONS_LIST_REQUEST_SUCCESS:
      return state
        .setIn(['profileQuestions', 'answers'], action.answers)
        .setIn(['profileQuestions', 'loading'], false);
    case QUESTIONS_LIST_REQUEST_FAIL:
      return state.setIn(['profileQuestions', 'loading'], false);
    case SEND_QUIZ_RESULT_REQUEST:
      return state.set('quizResultLoading', true);
    case SEND_QUIZ_RESULT_REQUEST_SUCCESS:
      return state.set('quizResultLoading', false);
    case SEND_QUIZ_RESULT_REQUEST_FAIL:
      return state.set('quizResultLoading', false);
    default:
      return state;
  }
}

export default chiefQuizReducer;
