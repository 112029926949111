import styled from 'styled-components';

const AuthWrapper = styled.div`
  margin-top: 15px;
  & a {
    display: inline-block;
    margin-right: 10px;
  }
`;

export default AuthWrapper;
