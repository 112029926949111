import styled from 'styled-components';

const Image = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: ${props => props.width}px;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export default Image;
