import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AppSwitcher from '@atlaskit/app-switcher';
import { GlobalItem } from '@atlaskit/navigation-next';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import GlobalNavigation from '@atlaskit/global-navigation';

import BasicQuickSearch from 'navigation/containers/Search';
import Create from 'navigation/components/Create';
import GlobalDropdown from 'navigation/components/GlobalDropdown';
import HelpDropdown from 'navigation/components/HelpDropdown';

import Logo from './styled/Logo';
import NavigationWrapper from './styled/NavigationWrapper';



const appSwitcherData = {
  isHomeLinkEnabled: false,
  isMarketplaceLinkEnabled: false,
  isSiteAdminLinkEnabled: false,
  isPeopleProfileLinkEnabled: false,
  recentContainers: [],
  linkedApplications: {
    configureLink: '',
    apps: [
      {
        name: 'Кабинет партнера',
        url: '/partner/login',
        product: ''
      }
    ],
    error: false
  },
  isAnonymousUser: false,
  i18n: {
    home: '',
    configure: '',
    recent: '',
    'container.confluence-space': '',
    'container.jira-project': '',
    'applinks.error': '',
    'try.lozenge': '',
    apps: 'ПЕРЕЙТИ В'
  }
};
const appSwitcherDropdownOptions = {
  position: 'right bottom'
};

const AppSwitcherComponent = itemProps => (
  <AppSwitcher
    {...appSwitcherData}
    isDropdownOpenInitially={false}
    dropdownOptions={appSwitcherDropdownOptions}
    trigger={isDropdownOpen => (
      <GlobalItem {...itemProps} icon={AppSwitcherIcon} isSelected={isDropdownOpen} />
    )}
  />
);

class DefaultGlobalNavigationBase extends Component {
  state = {
    isCreateDrawerOpen: false,
    isSearchDrawerOpen: false
  };

  componentDidMount() {
    let divs = document.querySelectorAll('div');
    let target = [...divs].filter(el => el.offsetWidth === 64 && el.childNodes.length === 2 && el.className);
    if(target.length) {
      target.forEach(el => el.style.height = 'var(--app-height)')
    }
  }

  openCreateDrawer = () => {
    this.setState({
      isCreateDrawerOpen: true
    });
  };

  openSearchDrawer = () => {
    this.setState({
      isSearchDrawerOpen: true
    });
  };

  closeOpenDrawer = () => {
    this.setState({
      isCreateDrawerOpen: false
    });
  };

  closeSearchDrawer = () => {
    this.setState({
      isSearchDrawerOpen: false
    });
  };

  clickOpenDrawer = path => {
    this.props.history.push(path);
    this.setState({
      isCreateDrawerOpen: false
    });
  };

  render() {
    return (
      <NavigationWrapper>
        <GlobalNavigation
          productIcon={Logo}
          productHref="/"
          productTooltip="HRHelper"
          onCreateClick={this.openCreateDrawer}
          createTooltip="Добавить"
          isCreateDrawerOpen={this.state.isCreateDrawerOpen}
          createDrawerContents={() => <Create onItemClick={this.clickOpenDrawer} />}
          onCreateDrawerClose={this.closeOpenDrawer}
          onProductClick={() => console.log('product clicked')}
          onSearchClick={this.openSearchDrawer}
          searchTooltip="Найти"
          isSearchDrawerOpen={this.state.isSearchDrawerOpen}
          searchDrawerContents={() => <BasicQuickSearch />}
          onSearchDrawerClose={this.closeSearchDrawer}
          helpTooltip="Поддержка"
          helpItems={HelpDropdown}
          profileTooltip="Профиль"
          profileItems={GlobalDropdown}
          appSwitcherComponent={AppSwitcherComponent}
          appSwitcherTooltip="Перейти"
        />
      </NavigationWrapper>
    );
  }
}

export default withRouter(DefaultGlobalNavigationBase);
