import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Clients/reducer';
import saga from 'pages/PartnerProfile/Clients/saga';

import {
  changeDateFilter,
  changeFinancesPage,
  getFinancesList,
  getClient
} from 'pages/PartnerProfile/Clients/actions';

import {
  makeSelectClientLoading,
  makeSelectClientRead,
  makeSelectFinancesCount,
  makeSelectFinancesList,
  makeSelectFinancesLoading,
  makeSelectFinancesPage,
  makeSelectFinancesRewardAmountSum,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter
} from 'pages/PartnerProfile/Clients/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import { DatePicker } from '@atlaskit/datetime-picker';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';

import FinancesTable from './Table';
import isEmpty from 'lodash/isEmpty';

class Finances extends Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { client, getFinancesList, getClient }
    } = this;

    if (client.id !== id) {
      getClient({ id });
    }

    getFinancesList({ id });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeFromDateFilterField = value => {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { changeDateFilter }
    } = this;

    changeDateFilter({
      id,
      field: 'fromDateFilter',
      text: value
    });
  };

  onChangeToDateFilterField = value => {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { changeDateFilter }
    } = this;

    changeDateFilter({
      id,
      field: 'toDateFilter',
      text: value
    });
  };

  renderBottomBar = () => {
    const {
      onChangeFromDateFilterField,
      onChangeToDateFilterField,
      props: { fromDateFilter, toDateFilter }
    } = this;

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 200px' }}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            id="fromDateFilter"
            onChange={onChangeFromDateFilterField}
            placeholder="От даты"
            value={fromDateFilter}
          />
        </div>
        <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            id="toDateFilter"
            onChange={onChangeToDateFilterField}
            placeholder="До даты"
            value={toDateFilter}
          />
        </div>
      </div>
    );
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { client }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/clients"
          onClick={event => onBreadcrumbsClick(event, '/partner/clients')}
          text="Клиенты"
          key="clients"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}`)}
          text={client.name}
          key="clientName"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}/finances`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}/finances`)}
          text="Фин. операции"
          key="clientManagers"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      props: {
        changeFinancesPage,
        count,
        fromDateFilter,
        list,
        loading,
        page,
        rewardAmountSum,
        client,
        tableLoading,
        toDateFilter
      },
      renderBottomBar,
      renderBreadcrumbs
    } = this;

    let content = (
      <GridColumn medium={12}>
        <Empty header="У партнера еще нет фин. операций" />
      </GridColumn>
    );

    if (count >= 1 || !isEmpty(fromDateFilter) || !isEmpty(toDateFilter)) {
      content = (
        <Fragment>
          <GridColumn medium={12}>
            <PageHeader breadcrumbs={renderBreadcrumbs()} bottomBar={renderBottomBar()}>
              Фин. операции
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <FinancesTable
              key="clientsFinancesTable"
              data={list}
              isLoading={tableLoading}
              rewardAmountSum={rewardAmountSum}
            />
            <Pagination
              key="pagination"
              value={page}
              total={Math.ceil(count / 25)}
              onChange={page => changeFinancesPage({ page, id: client.id })}
            />
          </GridColumn>
        </Fragment>
      );
    } else if (loading) {
      content = (
        <GridColumn medium={12}>
          <Spinner height="80vh" />
        </GridColumn>
      );
    }

    return <Grid layout="fluid">{content}</Grid>;
  };

  render() {
    const {
      props: { client },
      renderContent
    } = this;

    return (
      <Page>
        <Helmet>
          <title>{client.name}</title>
        </Helmet>
        {renderContent()}
      </Page>
    );
  }
}

Finances.propTypes = {
  changeDateFilter: PropTypes.func.isRequired,
  changeFinancesPage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  fromDateFilter: PropTypes.string.isRequired,
  getClient: PropTypes.func.isRequired,
  getFinancesList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rewardAmountSum: PropTypes.number.isRequired,
  tableLoading: PropTypes.bool.isRequired,
  toDateFilter: PropTypes.string.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeDateFilter: value => dispatch(changeDateFilter(value)),
    changeFinancesPage: value => dispatch(changeFinancesPage(value)),
    getClient: value => dispatch(getClient(value)),
    getFinancesList: value => dispatch(getFinancesList(value))
  };
}

const mapStateToProps = createStructuredSelector({
  client: makeSelectClientRead(),
  count: makeSelectFinancesCount(),
  fromDateFilter: makeSelectFinancesFromDateFilter(),
  list: makeSelectFinancesList(),
  loading: makeSelectClientLoading(),
  page: makeSelectFinancesPage(),
  rewardAmountSum: makeSelectFinancesRewardAmountSum(),
  tableLoading: makeSelectFinancesLoading(),
  toDateFilter: makeSelectFinancesToDateFilter()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerClients', reducer });
const withSaga = injectSaga({ key: 'partnerClients', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Finances);
