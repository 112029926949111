import { call, select, put, takeLeading } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { apiRequest } from 'utils/partnerRequest';

import {
  GET_FINANCES_LIST,
  CHANGE_FINANCES_PAGE,
  CHANGE_DATE_FILTER,
  CHANGE_TYPE_FILTER
} from './constants';

import { getFinancesList, getFinancesListSuccess, getFinancesListFail } from './actions';

import {
  makeSelectFinancesPage,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter,
  makeSelectFinancesTypeFilter
} from './selectors';

function* getFinancesListSaga() {
  const fromDateFilter = yield select(makeSelectFinancesFromDateFilter());
  const toDateFilter = yield select(makeSelectFinancesToDateFilter());
  const page = yield select(makeSelectFinancesPage());
  const sourceType = yield select(makeSelectFinancesTypeFilter());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (!isEmpty(fromDateFilter)) {
    params.from_date = fromDateFilter;
  }

  if (!isEmpty(toDateFilter)) {
    params.to_date = toDateFilter;
  }

  if (!isEmpty(sourceType)) {
    params.source_type = sourceType;
  }

  const url = `api/partners/income_items/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getFinancesListSuccess({
        rewardAmountSum: request.data.reward_amount__sum,
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getFinancesListFail({
        error: error.response.data
      })
    );
  }
}

function* changeDateFilterSaga() {
  yield put(getFinancesList());
}

function* changeTypeFilterSaga() {
  yield put(getFinancesList());
}

export default function* partnerClientsSaga() {
  yield takeLeading(GET_FINANCES_LIST, getFinancesListSaga);
  yield takeLeading(CHANGE_FINANCES_PAGE, getFinancesListSaga);
  yield takeLeading(CHANGE_DATE_FILTER, changeDateFilterSaga);
  yield takeLeading(CHANGE_TYPE_FILTER, changeTypeFilterSaga);
}
