import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';
import * as moment from 'moment';
import 'moment/locale/ru';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isEmail } from 'utils/validators';

import Button, { ButtonGroup } from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import Form from '@atlaskit/form';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Select from '@atlaskit/select';
import CollapsedText from 'components/CollapsedText';
import Empty from 'components/Empty';
import Info from 'components/Info';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Field from 'pages/Home/styled/Field';
import FieldGroup from 'pages/Home/styled/FieldGroup';

import reducer from 'pages/Home/reducer';
import saga from 'pages/Home/saga';
import {
  addReportToCompare,
  changeAddsModal,
  changeOrderReportByRank,
  changeReportsFlags,
  changeReportsEmail,
  changeReportsModals,
  changeReportType,
  deleteReportFromCompare,
  sendReportsEmail,
  staffRead,
  staffReport,
  updateReportsToCompare,
  changeReportRead
} from 'pages/Home/actions';
import {
  makeSelectReportType,
  makeSelectCompany,
  makeSelectErrorCode,
  makeSelectStaffError,
  makeSelectStaffLoading,
  makeSelectStaffList,
  makeSelectStaffRead,
  makeSelectStaffReport,
  makeSelectReportGlags,
  makeSelectReportEmail,
  makeSelectReportLoading,
  makeSelectReportModals,
  makeSelectOrderByRank,
  makeSelectStaffReportSize,
  makeSelectCompareReportsId,
  makeSelectCompareIdsString,
  makeSelectReportShape,
  makeSelectReportShapeText
} from 'pages/Home/selectors';

import 'pages/Home/css/index.css';

import Label from './styled/Label';
import Link from './styled/Link';
import P from './styled/P';
import Block from './styled/Block';
import ProgressLine from './styled/ProgressLine';
import MainBlock from './styled/MainBlock';
import Progress from './styled/Progress';
import Risk from './styled/Risk';
import Container from './styled/Container';
import RightWrapper from './styled/RightWrapper';
import SelectWrapper from './styled/SelectWrapper';
import Title from './styled/Title';
import TriggerButton from './styled/TriggerButton';
import isUndefined from 'lodash/isUndefined';

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compareFlags: [],
      emailValid: true,
      person: null,
      showInterpretation: true
    };

    const compareReportsId = localStorage.getItem('compareIds')
      ? JSON.parse(localStorage.getItem('compareIds'))
      : [];
    if (compareReportsId.length > 0) {
      let compareReportsObject = fromJS({});

      compareReportsId.forEach(report => {
        compareReportsObject = compareReportsObject.set(report, report);
      });

      props.updateReportsToCompare({
        compareReportsId: compareReportsObject
      });
    }
  }

  componentDidMount() {
    const personId = parseInt(this.props.match.params.id, 10);
    const staffRead = this.props.staffRead;

    if (staffRead.id !== personId) {
      this.props.getStaffRead({
        staffId: personId
      });
    }

    this.props.getStaffReport({
      staffId: personId
    });

    this.props.changeReportRead({personId})
  }

  onAddToCompareList = id => () => {
    const compareFlags = this.state.compareFlags;
    compareFlags.push(1);

    this.setState({
      compareFlags: compareFlags
    });
    this.props.addReportToCompare({ id });
  };

  onDeleteFromCompareList = id => () => this.props.deleteReportFromCompare({ id });

  getPageHeaderButtonsDrawer = () => {
    const personId = parseInt(this.props.match.params.id, 10);

    const { compareReportsIds } = this.props;

    let compareButton = (
      <Button onClick={this.onAddToCompareList(personId)}>Добавить к сравнению</Button>
    );
    if (!isUndefined(compareReportsIds.get(personId))) {
      compareButton = (
        <Button onClick={this.onDeleteFromCompareList(personId)}>Убрать из сравнения</Button>
      );
    }

    return (
      <div className="media-print-hide">
        <ButtonGroup>
          {compareButton}
          <DropdownMenu
            className="media-print-hide"
            trigger={TriggerButton}
            position="bottom right"
          >
            <DropdownItemGroup>
              <DropdownItem
                onClick={() => {
                  window.print();
                }}
              >
                Печать
              </DropdownItem>
              <DropdownItem onClick={this.onOpenModal}>Отправить на email</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        </ButtonGroup>
      </div>
    );
  };

  getProgressColor = progress => {
    if (progress >= 40 && progress <= 70) {
      return '#36B37E';
    }

    return '#FFAB00';
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onOpenModal = () => {
    this.props.changeReportsModals({
      reportModals: [1]
    });
  };

  onCloseModal = id => {
    this.props.changeReportsModals({
      reportModals: this.props.reportModals.filter(i => i !== id)
    });
  };

  onEmailChange = event => {
    if (!this.state.emailValid) {
      this.setState({
        emailValid: true
      });
    }

    this.props.changeReportsEmail({
      reportEmail: event.target.value
    });
  };

  onOrderByChange = event => {
    this.props.changeOrderReportByRank({
      order: event.value === 1
    });
  };

  onReportTypeChange = event => {
    this.props.changeReportType({
      reportType: event.value
    });
  };

  onRemoveFlag = id => {
    this.props.changeReportsFlags({ reportFlags: this.props.reportFlags.filter(v => v !== id) });
  };

  onRemoveCompareFlag = id => {
    this.setState({
      compareFlags: this.state.compareFlags.filter(v => v !== id)
    });
  };

  onSendModal = event => {
    if (isObject(event)) {
      event.preventDefault();
    }
    const { reportEmail } = this.props;
    const personId = parseInt(this.props.match.params.id, 10);

    if (isEmail(reportEmail)) {
      this.props.sendReportsEmail({
        reportId: personId
      });
    } else {
      this.setState({
        emailValid: false
      });
    }
  };

  toggleSortByInterpretation = () => {
    const { showInterpretation } = this.state;
    this.setState({
      showInterpretation: !showInterpretation
    });
  };

  renderSection = section => {
    const { showInterpretation } = this.state;

    if (section.type === 0) {
      return (
        <Block>
          <Title>
            <h4>{section.title}</h4>
            {!isEmpty(section.info) && <Info isBig text={section.info} />}
          </Title>
          <ProgressLine
            backgroundColor={this.getProgressColor(section.value)}
            color="#172B4D"
            progress={`${section.value}%`}
          >
            <Progress>{section.value}%</Progress>
          </ProgressLine>
          <CollapsedText isOpened={showInterpretation}>{section.text}</CollapsedText>
        </Block>
      );
    } else if (section.type === 3) {
      return (
        <Block>
          <Risk>
            <Title>
              <h4>{section.title}</h4>
              {!isEmpty(section.info) && <Info isBig text={section.info} />}
            </Title>
            <CollapsedText isOpened={showInterpretation}>{section.text}</CollapsedText>
          </Risk>
        </Block>
      );
    }

    return (
      <Block>
        <Title>
          <h4>{section.title}</h4>
          {!isEmpty(section.info) && <Info isBig text={section.info} />}
        </Title>
        <ProgressLine
            backgroundColor={this.getProgressColor(section.value)}
            color="#172B4D"
            progress={`${section.value}%`}
          >
            <Progress>{section.value}%</Progress>
          </ProgressLine>
        <CollapsedText isOpened={showInterpretation}>{section.text}</CollapsedText>
      </Block>
    );
  };

  renderReport = staffReport =>
    staffReport.reports.map(report => (
      <MainBlock key={report.id}>
        <Title>
          <h3>{report.title}</h3>
          {!isEmpty(report.info) && <Info isBig text={report.info} />}
        </Title>
        {report.subsections.map(section => (
          <div key={section.id}>{this.renderSection(section)}</div>
        ))}
      </MainBlock>
    ));

  render() {
    const {
      changeAddsModal,
      compareIdsString,
      errorCode,
      loading,
      order,
      reportFlags,
      reportEmail,
      reportLoading,
      reportModals,
      reportShape,
      reportShapeText,
      reportType,
      staffError,
      staffReport,
      staffReportSize
    } = this.props;

    let { showInterpretation, emailValid, person } = this.state;

    if (isNull(person)) {
      person = this.props.staffRead;
    }

    let content = <Spinner />;

    if (!loading && staffReport.reports.length > 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/person/list"
            onClick={event => this.onBreadcumbsClick(event, '/person/list')}
            text="Отчеты"
            key="Personal"
          />
          <BreadcrumbsItem
            href={`/person/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/${person.id}`)}
            text={person.full_name}
            key="Petrov"
          />
          <BreadcrumbsItem
            href={`/person/report/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/report/${person.id}`)}
            text={reportShapeText[reportShape].label}
            key="Report"
          />
        </BreadcrumbsStateless>
      );

      const typeOptions = [
        {
          label: 'Для HR',
          value: 0
        },
        {
          label: 'Для руководителя',
          value: 1
        },
        {
          label: 'Персональный',
          value: 2
        }
      ].slice(0, staffReportSize);
      const orderOptions = [
        {
          label: 'Стандартно',
          value: 0
        },
        {
          label: 'По значимости',
          value: 1
        }
      ];
      const showInterpretationText = showInterpretation
        ? 'Скрыть интерпретации'
        : 'Показать интерпретации';

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader breadcrumbs={breadcrumbs} actions={this.getPageHeaderButtonsDrawer()}>
                {person.full_name}
              </PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <div>
                <Label>Дата тестирования</Label>
                <P marginTop="0">{moment(staffReport.createdAt).format('DD.MM.YYYY HH:mm')}</P>
              </div>
            </GridColumn>
          </Grid>
          <div className="media-print-hide">
            <Grid layout="fixed">
              <GridColumn medium={6}>
                <SelectWrapper>
                  <Label>Тип отчета</Label>
                  <SelectWrapper marginTop="5px">
                    <Select
                      className="single-select"
                      defaultOptions={typeOptions[0]}
                      options={typeOptions}
                      isSearchable={false}
                      onChange={this.onReportTypeChange}
                      placeholder="Тип отчета"
                      value={typeOptions[reportType]}
                    />
                  </SelectWrapper>
                </SelectWrapper>
              </GridColumn>
            </Grid>
          </div>
          <div className="media-print-hide">
            <Grid layout="fixed">
              <GridColumn medium={6}>
                <SelectWrapper>
                  <Label>Упорядочить</Label>
                  <SelectWrapper marginTop="5px">
                    <Select
                      className="single-select"
                      defaultOptions={orderOptions[0]}
                      options={orderOptions}
                      isSearchable={false}
                      onChange={this.onOrderByChange}
                      placeholder="Упорядочить"
                      value={orderOptions[order]}
                    />
                  </SelectWrapper>
                </SelectWrapper>
              </GridColumn>
              <RightWrapper>
                <div>
                  <Link type="button" onClick={this.toggleSortByInterpretation}>
                    {showInterpretationText}
                  </Link>
                </div>
              </RightWrapper>
            </Grid>
          </div>
          <Grid layout="fixed">
            <GridColumn medium={12}>{this.renderReport(staffReport)}</GridColumn>
          </Grid>
        </Container>
      );
    } else if (staffError && errorCode === 404) {
      content = (
        <Empty
          header=""
          text="Кандидат еще не прошел тестирование, мы не можем сформировать отчет"
        />
      );
    } else if (staffError && errorCode === 403) {
      content = (
        <Empty
          header="Отчет недоступен"
          text="Чтобы открыть этот отчет, необходимо купить дополнительные отчеты или перейти на другой тариф."
        >
          <Button
            appearance="primary"
            onClick={() =>
              changeAddsModal({
                addsModal: [1]
              })
            }
          >
            Купить отчеты
          </Button>
        </Empty>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Отчёт по оценке потенциала</title>
        </Helmet>

        {content}

        {reportModals.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Отправить отчет"
            onClose={this.onCloseModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.onSendModal,
                isLoading: reportLoading,
                type: 'submit'
              },
              {
                text: 'Отменить',
                onClick: this.onCloseModal
              }
            ]}
            width="small"
          >
            <p>Укажите e-mail, на который необходимо отправить отчет</p>
            <Form name="layout-example" onSubmit={this.onSendModal} onReset={() => {}} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!emailValid}
                    label="Email"
                    name="email"
                    onChange={this.onEmailChange}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={reportEmail}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.onRemoveFlag(name)}>
          {reportFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Отчет отправлен"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.onRemoveCompareFlag(name)}>
          {this.state.compareFlags.map(id => (
            <Flag
              actions={[
                {
                  content: 'Перейти к сравнению',
                  onClick: () => {
                    this.props.history.push(`/compare/`);
                  }
                }
              ]}
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Отчет добавлен к сравнению"
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

Report.propTypes = {
  addReportToCompare: PropTypes.func.isRequired,
  changeAddsModal: PropTypes.func,
  changeOrderReportByRank: PropTypes.func,
  changeReportsFlags: PropTypes.func,
  changeReportsEmail: PropTypes.func,
  changeReportsModals: PropTypes.func,
  changeReportType: PropTypes.func,
  company: PropTypes.object,
  compareIdsString: PropTypes.string.isRequired,
  compareReportsIds: PropTypes.object,
  deleteReportFromCompare: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  getStaffRead: PropTypes.func,
  getStaffReport: PropTypes.func,
  loading: PropTypes.bool,
  order: PropTypes.number,
  reportFlags: PropTypes.array,
  reportEmail: PropTypes.string,
  reportLoading: PropTypes.bool,
  reportModals: PropTypes.array,
  reportShape: PropTypes.number.isRequired,
  reportShapeText: PropTypes.array,
  reportType: PropTypes.number,
  sendReportsEmail: PropTypes.func,
  staffError: PropTypes.bool,
  staffList: PropTypes.array,
  staffRead: PropTypes.object,
  staffReport: PropTypes.object,
  staffReportSize: PropTypes.number,
  updateReportsToCompare: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    addReportToCompare: value => dispatch(addReportToCompare(value)),
    changeAddsModal: value => dispatch(changeAddsModal(value)),
    changeOrderReportByRank: value => dispatch(changeOrderReportByRank(value)),
    changeReportsFlags: value => dispatch(changeReportsFlags(value)),
    changeReportsEmail: value => dispatch(changeReportsEmail(value)),
    changeReportsModals: value => dispatch(changeReportsModals(value)),
    changeReportType: value => dispatch(changeReportType(value)),
    deleteReportFromCompare: value => dispatch(deleteReportFromCompare(value)),
    getStaffRead: value => dispatch(staffRead(value)),
    getStaffReport: value => dispatch(staffReport(value)),
    sendReportsEmail: value => dispatch(sendReportsEmail(value)),
    updateReportsToCompare: value => dispatch(updateReportsToCompare(value)),
    changeReportRead: value => dispatch(changeReportRead(value))
  };
}

const mapStateToProps = createStructuredSelector({
  company: makeSelectCompany(),
  compareIdsString: makeSelectCompareIdsString(),
  compareReportsIds: makeSelectCompareReportsId(),
  errorCode: makeSelectErrorCode(),
  order: makeSelectOrderByRank(),
  reportFlags: makeSelectReportGlags(),
  reportEmail: makeSelectReportEmail(),
  reportLoading: makeSelectReportLoading(),
  reportModals: makeSelectReportModals(),
  reportShape: makeSelectReportShape(),
  reportShapeText: makeSelectReportShapeText(),
  reportType: makeSelectReportType(),
  loading: makeSelectStaffLoading(),
  staffError: makeSelectStaffError(),
  staffRead: makeSelectStaffRead(),
  staffReport: makeSelectStaffReport(),
  staffReportSize: makeSelectStaffReportSize(),
  staffList: makeSelectStaffList()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Report);
