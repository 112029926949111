import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import { apiRequest } from 'utils/partnerRequest';

import {
  GET_PARTNERS_LIST,
  CHANGE_PARTNERS_PAGE,
  REGISTER_NEW_PARTNER,
  GET_PARTNER,
  UPDATE_PARTNER,
  GET_CLIENTS_LIST,
  CHANGE_CLIENTS_PAGE,
  GET_REQUISITES_LIST,
  CHANGE_REQUISITES_PAGE,
  SEND_INVITE,
  CHANGE_FILTER_FIELD
} from './constants';

import {
  getPartnersList,
  getPartnersListSuccess,
  getPartnersListFail,
  registerNewPartnerSuccess,
  registerNewPartnerFail,
  getPartnerSuccess,
  getPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
  getClientsListSuccess,
  getClientsListFail,
  getRequisitesListSuccess,
  getRequisitesListFail,
  sendInviteSuccess,
  sendInviteFail
} from './actions';

import {
  makeSelectClientsPage,
  makeSelectPartnerComment,
  makeSelectPartnersPage,
  makeSelectPartnersSearch,
  makeSelectRegistrationForm,
  makeSelectRequisitesPage,
  makeSelectInviteForm
} from './selectors';
import { makeSelectUserRead } from '../User/selectors';

function* getPartnerListSaga() {
  const page = yield select(makeSelectPartnersPage());
  const search = yield select(makeSelectPartnersSearch());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (!isEmpty(search)) {
    params.search = search;
  }

  const url = `api/partners/partners/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getPartnersListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getPartnersListFail({
        error: error.response.data
      })
    );
  }
}

function* registerNewPartnerSaga() {
  const data = yield select(makeSelectRegistrationForm());
  const user = yield select(makeSelectUserRead());
  const url = 'api/partners/partners/register';
  const options = {
    method: 'post',
    data: {
      ...data,
      referral_code: user.referral_code
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(registerNewPartnerSuccess());
  } catch (error) {
    yield put(
      registerNewPartnerFail({
        error: error.response.data
      })
    );
  } finally {
    yield put(push('/partner/partners'));
  }
}

function* getPartnerSaga({ id }) {
  const url = `api/partners/partners/${id}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getPartnerSuccess({
        partner: request.data
      })
    );
  } catch (error) {
    yield put(
      getPartnerFail({
        error: error.response.data
      })
    );
  }
}

function* updatePartnerSaga({ id }) {
  const url = `api/partners/partners/${id}/`;
  const comment = yield select(makeSelectPartnerComment());
  const options = {
    method: 'patch',
    data: {
      comment
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updatePartnerSuccess());
  } catch (error) {
    yield put(
      updatePartnerFail({
        error: error.response.data
      })
    );
  }
}

function* getClientsListSaga({ id }) {
  const page = yield select(makeSelectClientsPage());
  const offset = page * 25 - 25;
  let url = `api/partners/partners/${id}/companies/`;

  if (page > 1) {
    url = `api/partners/partners/${id}/companies/?offset=${offset}`;
  }

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getClientsListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getClientsListFail({
        error: error.response.data
      })
    );
  }
}

function* getRequisitesListSaga({ id }) {
  const page = yield select(makeSelectRequisitesPage());
  const offset = page * 25 - 25;
  let url = `api/partners/partners/${id}/organizations/`;

  if (page > 1) {
    url = `api/partners/partners/${id}/organizations/?offset=${offset}`;
  }

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getRequisitesListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getRequisitesListFail({
        error: error.response.data
      })
    );
  }
}

function* sendInviteSaga() {
  const url = 'api/partners/partners/send_email/';
  const form = yield select(makeSelectInviteForm());
  const options = {
    method: 'post',
    data: {
      to_email: form.email,
      welcome_text: form.message
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(sendInviteSuccess());
  } catch (e) {
    yield put(sendInviteFail());
  }
}

function* changeFilterFieldSaga({ field }) {
  if (field === 'partners') {
    yield put(getPartnersList());
  }
}

export default function* partnerClientsSaga() {
  yield takeLeading(GET_PARTNERS_LIST, getPartnerListSaga);
  yield takeLeading(CHANGE_PARTNERS_PAGE, getPartnerListSaga);
  yield takeLeading(REGISTER_NEW_PARTNER, registerNewPartnerSaga);
  yield takeLeading(GET_PARTNER, getPartnerSaga);
  yield takeLeading(UPDATE_PARTNER, updatePartnerSaga);
  yield takeLeading(GET_CLIENTS_LIST, getClientsListSaga);
  yield takeLeading(CHANGE_CLIENTS_PAGE, getClientsListSaga);
  yield takeLeading(GET_REQUISITES_LIST, getRequisitesListSaga);
  yield takeLeading(CHANGE_REQUISITES_PAGE, getRequisitesListSaga);
  yield takeLeading(SEND_INVITE, sendInviteSaga);
  yield takeLeading(CHANGE_FILTER_FIELD, changeFilterFieldSaga);
}
