import styled from 'styled-components';

const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 70px auto;
  min-height: calc(100vh - 140px);
  max-width: 1060px;
  width: 500px;

  @media (max-width: 1020px) {
    width: calc(100% - 30px);
  }
`;

export default Flex;
