import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-right: 10px;
  padding: 10px 7px 10px 10px;
  width: 120px;
  flex-direction: row;

  &:last-child {
    margin-right: 0;
  }
`;

export default Wrapper;
