import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isRequired, isPhoneInProfile, isEmail } from 'utils/validators';
import injectSaga from 'utils/injectSaga';
import Button from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Form from '@atlaskit/form';
import Tabs from '@atlaskit/tabs';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';

import {
  changeCompanyName,
  changeEmail,
  changeName,
  changePasswordOne,
  changePasswordTwo,
  changePhoneNumber,
  changeSurname,
  updatePassword,
  updateUser
} from 'pages/Home/actions';

import {
  makeSelectLoadingPassword,
  makeSelectLoadingProfile,
  makeSelectStaffLoading,
  makeSelectUser
} from 'pages/Home/selectors';

import saga from 'pages/Home/saga';

import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class MyProfile extends React.Component {
  state = {
    companyName: true,
    emailFlags: [],
    emailDisabled: true,
    emailValid: true,
    flags: [],
    informationDisabled: true,
    nameValid: true,
    passwordDisabled: true,
    passFlags: [],
    passwordOneValid: true,
    passwordTwoValid: true,
    phoneValid: true,
    selected: null,
    surnameValid: true,
    title: ''
  };

  addFlag = () => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags]
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  changeSelected = selected => {
    this.setState({ selected });
  };

  changeEmail = event => {
    const emailValid = isEmail(event.target.value);
    this.setState({
      emailValid,
      emailDisabled: !emailValid
    });

    this.props.changeEmail({
      email: event.target.value
    });
  };

  changeName = event => {
    this.props.changeName({
      name: event.target.value
    });

    this.checkInformationForm('name', event.target.value);
  };

  changePasswordOne = event => {
    this.props.changePasswordOne({
      passwordOne: event.target.value
    });

    this.checkPasswordForm('passwordOne', event.target.value);
  };

  changePasswordTwo = event => {
    this.props.changePasswordTwo({
      passwordTwo: event.target.value
    });

    this.checkPasswordForm('passwordTwo', event.target.value);
  };

  changePhoneNumber = event => {
    this.props.changePhoneNumber({
      phone: event.target.value
    });

    this.checkInformationForm('phone', event.target.value);
  };

  changeSurname = event => {
    this.props.changeSurname({
      surname: event.target.value
    });

    this.checkInformationForm('surname', event.target.value);
  };

  changeCompanyName = event => {
    this.props.changeCompanyName({
      companyName: event.target.value
    });

    this.checkInformationForm('companyName', event.target.value);
  };

  checkInformationForm = (input, value) => {
    const user = this.props.user;
    let informationDisabled = true;

    const nameValid = isRequired(input === 'name' ? value : user.get('first_name'));
    const surnameValid = isRequired(input === 'surname' ? value : user.get('last_name'));
    const phoneValid = isPhoneInProfile(input === 'phone' ? value : user.get('phone_number'));
    const companyNameValid = isRequired(input === 'companyName' ? value : user.get('company_name'));

    switch (input) {
      case 'name':
        this.setState({
          nameValid
        });
        break;
      case 'surname':
        this.setState({
          surnameValid
        });
        break;
      case 'phone':
        this.setState({
          phoneValid
        });
        break;
      case 'companyName':
        this.setState({
          companyNameValid
        });
        break;
      default:
        break;
    }

    if (nameValid && surnameValid && phoneValid && companyNameValid) {
      informationDisabled = false;
    }

    this.setState({
      informationDisabled
    });
  };

  checkPasswordForm = (input, value) => {
    const user = this.props.user;
    let passwordDisabled = true;

    const passwordOneValid = isRequired(input === 'passwordOne' ? value : user.get('password_one'));
    let passwordTwoValid = isRequired(input === 'passwordTwo' ? value : user.get('password_two'));

    switch (input) {
      case 'passwordOne':
        this.setState({
          passwordOneValid
        });
        break;
      case 'passwordTwo':
        if (!passwordTwoValid || value !== user.get('password_one')) {
          passwordTwoValid = false;
        }
        this.setState({
          passwordTwoValid
        });
        break;
      default:
        break;
    }

    if (passwordOneValid && passwordTwoValid) {
      passwordDisabled = false;
    }

    this.setState({
      passwordDisabled
    });
  };

  updatePassword = () => {
    this.props.updatePassword();
    this.addFlag();
  };

  updateUser = () => {
    this.props.updateUser();
    this.addFlag();
  };

  render() {
    const { loading, loadingPassword, loadingProfile, user } = this.props;

    const {
      companyName,
      emailDisabled,
      emailValid,
      informationDisabled,
      nameValid,
      passwordDisabled,
      passwordOneValid,
      passwordTwoValid,
      phoneValid,
      surnameValid,
      title
    } = this.state;

    if (isEmpty(title) && user.get('pk') !== 0) {
      this.setState({
        title: `${user.get('first_name')} ${user.get('last_name')}`
      });
    }

    let content = <Spinner />;

    if (!loading && user.get('pk') !== 0) {
      const information = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!nameValid}
                required
                label="Имя"
                name="name"
                onChange={this.changeName}
                placeholder=""
                shouldFitContainer
                value={user.get('first_name')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!surnameValid}
                required
                label="Фамилия"
                name="surname"
                onChange={this.changeSurname}
                placeholder=""
                shouldFitContainer
                value={user.get('last_name')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!phoneValid}
                label="Телефон"
                mask="+7(999) 999-99-99"
                name="phone"
                onChange={this.changePhoneNumber}
                placeholder=""
                required
                shouldFitContainer
                value={user.get('phone_number')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalud={!companyName}
                label="Компания"
                name="company"
                onChange={this.changeCompanyName}
                placeholder=""
                shouldFitContainer
                value={user.get('company_name')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={informationDisabled}
              isLoading={loadingProfile}
              onClick={this.updateUser}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const email = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup marginTop="23px">
            <h4>Изменение email</h4>
          </FieldGroup>
          <FieldGroup marginTop="8px">
            <p>
              Ваш текущий email <b>{user.get('email')}</b>
            </p>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!emailValid}
                required
                label="Новый email"
                name="email"
                onChange={this.changeEmail}
                placeholder="Укажите новый адрес email"
                shouldFitContainer
                value={user.get('new_email')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={emailDisabled}
              isLoading={loadingProfile}
              onClick={this.updateUser}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const password = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup marginTop="23px">
            <h4>Изменение пароля</h4>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!passwordOneValid}
                required
                label="Новый пароль"
                name="current_password"
                onChange={this.changePasswordOne}
                placeholder=""
                shouldFitContainer
                type="password"
                value={user.get('password_one')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!passwordTwoValid}
                required
                label="Повторите новый пароль"
                name="new_password"
                onChange={this.changePasswordTwo}
                placeholder=""
                shouldFitContainer
                type="password"
                value={user.get('password_two')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={passwordDisabled}
              isLoading={loadingPassword}
              onClick={this.updatePassword}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const tabs = [
        { label: 'Общая информация', content: information },
        { label: 'Email', content: email },
        { label: 'Пароль', content: password }
      ];
      let selected = this.state.selected !== null ? tabs[this.state.selected] : tabs[0];

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader>{title}</PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <Tabs
                onSelect={(selected, index) => this.changeSelected(index)}
                selected={selected}
                tabs={tabs}
              />
              <FlagGroup onDismissed={name => this.removeFlag(name)}>
                {this.state.flags.map(id => (
                  <Flag
                    icon={<Tick label="Success" />}
                    id={id}
                    isDismissAllowed
                    key={`${id}`}
                    title="Изменения сохранены"
                  />
                ))}
              </FlagGroup>
            </GridColumn>
          </Grid>
        </Container>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {content}
      </Fragment>
    );
  }
}

MyProfile.propTypes = {
  changeCompanyName: PropTypes.func,
  changeEmail: PropTypes.func,
  changeName: PropTypes.func,
  changePasswordOne: PropTypes.func,
  changePasswordTwo: PropTypes.func,
  changePhoneNumber: PropTypes.func,
  changeSurname: PropTypes.func,
  loading: PropTypes.bool,
  loadingPassword: PropTypes.bool,
  loadingProfile: PropTypes.bool,
  updatePassword: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    changeCompanyName: value => dispatch(changeCompanyName(value)),
    changeEmail: value => dispatch(changeEmail(value)),
    changeName: value => dispatch(changeName(value)),
    changePasswordOne: value => dispatch(changePasswordOne(value)),
    changePasswordTwo: value => dispatch(changePasswordTwo(value)),
    changePhoneNumber: value => dispatch(changePhoneNumber(value)),
    changeSurname: value => dispatch(changeSurname(value)),
    updatePassword: () => dispatch(updatePassword()),
    updateUser: () => dispatch(updateUser())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectStaffLoading(),
  loadingPassword: makeSelectLoadingPassword(),
  loadingProfile: makeSelectLoadingProfile(),
  user: makeSelectUser()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withSaga,
  withConnect
)(MyProfile);
