import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Clients/reducer';
import saga from 'pages/PartnerProfile/Clients/saga';

import {
  changeRequisitesPage,
  getRequisitesList,
  getClient
} from 'pages/PartnerProfile/Clients/actions';

import {
  makeSelectClientRead,
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage
} from 'pages/PartnerProfile/Clients/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';

import RequisitesTable from './Table';

class Clients extends Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { client, getRequisitesList, getClient }
    } = this;

    if (client.id !== id) {
      getClient({ id });
    }

    getRequisitesList({ id });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { client }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/clients"
          onClick={event => onBreadcrumbsClick(event, '/partner/clients')}
          text="Клиенты"
          key="clients"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}`)}
          text={client.name}
          key="clientName"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}/requisites`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}/requisites`)}
          text="Реквизиты"
          key="clientManagers"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      props: { changeRequisitesPage, count, list, page, client, tableLoading },
      renderBreadcrumbs
    } = this;

    let content = (
      <GridColumn medium={12}>
        <Empty header="У партнера еще нет реквизитов" />
      </GridColumn>
    );

    if (count >= 1) {
      content = (
        <GridColumn medium={12}>
          <RequisitesTable key="clientsRequisitesTable" data={list} isLoading={tableLoading} />
          <Pagination
            key="pagination"
            value={page}
            total={Math.ceil(count / 25)}
            onChange={page => changeRequisitesPage({ page, id: client.id })}
          />
        </GridColumn>
      );
    } else if (tableLoading) {
      content = (
        <GridColumn medium={12}>
          <Spinner height="80vh" />
        </GridColumn>
      );
    }

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader breadcrumbs={renderBreadcrumbs()}>Реквизиты</PageHeader>
        </GridColumn>
        {content}
      </Grid>
    );
  };

  render() {
    const {
      props: { client },
      renderContent
    } = this;

    return (
      <Page>
        <Helmet>
          <title>{client.name}</title>
        </Helmet>
        {renderContent()}
      </Page>
    );
  }
}

Clients.propTypes = {
  changeRequisitesPage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  getClient: PropTypes.func.isRequired,
  getRequisitesList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  tableLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeRequisitesPage: value => dispatch(changeRequisitesPage(value)),
    getClient: value => dispatch(getClient(value)),
    getRequisitesList: value => dispatch(getRequisitesList(value))
  };
}

const mapStateToProps = createStructuredSelector({
  client: makeSelectClientRead(),
  count: makeSelectRequisitesCount(),
  list: makeSelectRequisitesList(),
  page: makeSelectRequisitesPage(),
  tableLoading: makeSelectRequisitesLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerClients', reducer });
const withSaga = injectSaga({ key: 'partnerClients', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Clients);
