import styled from 'styled-components';

const ProgressBefore = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: ${props => (props.isCritical ? '#00875A' : '#6B778C')};
  letter-spacing: 0;
  line-height: 16px;
  bottom: -48px;
  left: ${props => `${props.width}%`};
  transform: translateX(-50%);

  &:after {
    background-color: ${props => (props.isCritical ? '#00875A' : '#7A869A')};
    content: '';
    display: block;
    height: 56px;
    left: 50%;
    top: -77px;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s ease-out;
    width: 1px;
  }
`;

export default ProgressBefore;
