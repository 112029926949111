import {
  GET_PARTNERS_LIST,
  GET_PARTNERS_LIST_SUCCESS,
  GET_PARTNERS_LIST_FAIL,
  CHANGE_PARTNERS_PAGE,
  CHANGE_FLAGS,
  CHANGE_PARTNER_REGISTRATION_FIELD,
  RESET_PARTNER_REGISTRATION_FORM,
  REGISTER_NEW_PARTNER,
  REGISTER_NEW_PARTNER_SUCCESS,
  REGISTER_NEW_PARTNER_FAIL,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAIL,
  CHANGE_PARTNER_COMMENT_FIELD,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  CHANGE_CLIENTS_PAGE,
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  CHANGE_INVITE_MODAL,
  CHANGE_INVITE_FIELD,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  CHANGE_FILTER_FIELD
} from './constants';

export function getPartnersList() {
  return {
    type: GET_PARTNERS_LIST
  };
}

export function getPartnersListSuccess(payload) {
  return {
    type: GET_PARTNERS_LIST_SUCCESS,
    ...payload
  };
}

export function getPartnersListFail(payload) {
  return {
    type: GET_PARTNERS_LIST_FAIL,
    ...payload
  };
}

export function changePartnersPage(payload) {
  return {
    type: CHANGE_PARTNERS_PAGE,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function changePartnerRegistrationField(payload) {
  return {
    type: CHANGE_PARTNER_REGISTRATION_FIELD,
    ...payload
  };
}

export function resetPartnerRegistrationForm() {
  return {
    type: RESET_PARTNER_REGISTRATION_FORM
  };
}

export function registerNewPartner() {
  return {
    type: REGISTER_NEW_PARTNER
  };
}

export function registerNewPartnerSuccess() {
  return {
    type: REGISTER_NEW_PARTNER_SUCCESS
  };
}

export function registerNewPartnerFail(payload) {
  return {
    type: REGISTER_NEW_PARTNER_FAIL,
    ...payload
  };
}

export function getPartner(payload) {
  return {
    type: GET_PARTNER,
    ...payload
  };
}

export function getPartnerSuccess(payload) {
  return {
    type: GET_PARTNER_SUCCESS,
    ...payload
  };
}

export function getPartnerFail(payload) {
  return {
    type: GET_PARTNER_FAIL,
    ...payload
  };
}

export function changePartnerComment(payload) {
  return {
    type: CHANGE_PARTNER_COMMENT_FIELD,
    ...payload
  };
}

export function updatePartner(payload) {
  return {
    type: UPDATE_PARTNER,
    ...payload
  };
}

export function updatePartnerSuccess(payload) {
  return {
    type: UPDATE_PARTNER_SUCCESS,
    ...payload
  };
}

export function updatePartnerFail(payload) {
  return {
    type: UPDATE_PARTNER_FAIL,
    ...payload
  };
}

export function getClientsList(payload) {
  return {
    type: GET_CLIENTS_LIST,
    ...payload
  };
}

export function getClientsListSuccess(payload) {
  return {
    type: GET_CLIENTS_LIST_SUCCESS,
    ...payload
  };
}

export function getClientsListFail(payload) {
  return {
    type: GET_CLIENTS_LIST_FAIL,
    ...payload
  };
}

export function changeClientsPage(payload) {
  return {
    type: CHANGE_CLIENTS_PAGE,
    ...payload
  };
}

export function getRequisitesList(payload) {
  return {
    type: GET_REQUISITES_LIST,
    ...payload
  };
}

export function getRequisitesListSuccess(payload) {
  return {
    type: GET_REQUISITES_LIST_SUCCESS,
    ...payload
  };
}

export function getRequisitesListFail(payload) {
  return {
    type: GET_REQUISITES_LIST_FAIL,
    ...payload
  };
}

export function changeRequisitesPage(payload) {
  return {
    type: CHANGE_REQUISITES_PAGE,
    ...payload
  };
}

export function changeInviteModal(payload) {
  return {
    type: CHANGE_INVITE_MODAL,
    ...payload
  };
}

export function changeInviteField(payload) {
  return {
    type: CHANGE_INVITE_FIELD,
    ...payload
  };
}

export function sendInvite() {
  return {
    type: SEND_INVITE
  };
}

export function sendInviteSuccess() {
  return {
    type: SEND_INVITE_SUCCESS
  };
}

export function sendInviteFail() {
  return {
    type: SEND_INVITE_FAIL
  };
}

export function changeFilterField(payload) {
  return {
    type: CHANGE_FILTER_FIELD,
    ...payload
  };
}
