import { fromJS } from 'immutable';

import {
  CHANGE_EMAIL,
  CHANGE_NAME,
  CHANGE_MODALS,
  CHANGE_TEXT,
  CHANGE_FLAGS,
  CHANGE_APPLY_FLAGS,
  SEND_FEEDBACK_REQUEST,
  SEND_FEEDBACK_REQUEST_SUCCESS,
  SEND_FEEDBACK_REQUEST_FAIL,
  SEND_SUBMIT_REQUEST,
  SEND_SUBMIT_REQUEST_SUCCESS,
  SEND_SUBMIT_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  applyFlags: [],
  applyLoading: false,
  email: '',
  flags: [],
  loading: false,
  modals: [],
  name: '',
  text: ''
});

function landingReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_APPLY_FLAGS:
      return state.set('applyFlags', fromJS(action.flags));
    case CHANGE_MODALS:
      return state.set('modals', fromJS(action.modals));
    case SEND_SUBMIT_REQUEST:
      return state.set('applyLoading', true);
    case SEND_SUBMIT_REQUEST_SUCCESS:
      return state
        .set('applyLoading', false)
        .set('modals', fromJS([]))
        .set('applyFlags', fromJS([1]));
    case SEND_SUBMIT_REQUEST_FAIL:
      return state
        .set('applyLoading', false)
        .set('modals', fromJS([]))
        .set('applyFlags', fromJS([-1]));
    case CHANGE_NAME:
      return state.set('name', action.name);
    case CHANGE_EMAIL:
      return state.set('email', action.email);
    case CHANGE_TEXT:
      return state.set('text', action.text);
    case CHANGE_FLAGS:
      return state.set('flags', fromJS(action.flags));
    case SEND_FEEDBACK_REQUEST:
      return state.set('loading', true).set('flags', fromJS([]));
    case SEND_FEEDBACK_REQUEST_SUCCESS:
      return state.set('loading', false).set('flags', fromJS([1]));
    case SEND_FEEDBACK_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default landingReducer;
