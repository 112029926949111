import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Page from '@atlaskit/page';
import Button from '@atlaskit/button';
import { AutoDismissFlag as Flag } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import RouterHashLink from 'components/RouterHashLink';
import Header from 'promo/Header';
import Feedback from 'promo/LeadForm';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { changeName, changePhone, sendLead } from './actions';

import reducer from './reducer';
import saga from './saga';

import TopImage from './images/top.jpg';
import ButtonWrapper from './styled/ButtonWrapper';
import Column from './styled/Column';
import FlagGroup from './styled/FlagGroup';
import Image from './styled/Image';
import Text from './styled/Text';
import Title from './styled/Title';
import Top from './styled/Top';

class Promo extends React.Component {
  state = {
    flags: []
  };

  addFlag = () => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags]
    });
    this.props.sendLead();
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  render() {
    return (
      <Page>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
        <Header />
        <Top>
          <Column>
            <Title>
              HRHelper ускоряет в 17 раз подбор кандидатов, совместимых с компанией более, чем на
              87%
            </Title>
            <Text>
              Платформа “HRHelper” позволит подобрать кандидата совместимого с компанией, командой,
              должностью более чем на 87%
            </Text>
            <ButtonWrapper>
              <Button appearance="primary" href="/#feedback" component={RouterHashLink}>
                Узнать о запуске
              </Button>
            </ButtonWrapper>
          </Column>
          <Image backgroundImage={TopImage} width={560} />
        </Top>
        <Feedback
          onChangeName={this.props.changeName}
          onChangePhone={this.props.changePhone}
          onFormSubmit={this.addFlag}
        />

        <FlagGroup id="flagGroup" onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Спасибо за проявленный интерес. Мы сообщим вам о запуске."
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

Promo.propTypes = {
  changeName: PropTypes.func,
  changePhone: PropTypes.func,
  sendLead: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeName: value => dispatch(changeName(value)),
    changePhone: value => dispatch(changePhone(value)),
    sendLead: () => dispatch(sendLead())
  };
}

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'promo', reducer });
const withSaga = injectSaga({ key: 'promo', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Promo);
