import React from 'react';
import { Link } from 'react-router-dom';
import 'moment/locale/ru';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import NormalButton from '@atlaskit/button';
import styled from 'styled-components';

import Modal from 'components/Modal';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const Button = styled(NormalButton)`
  &.allWidth {
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;

const TableItem = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`;

const TriggerButton = <Button>...</Button>;

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'name',
        content: 'Название организации',
        isSortable: false,
        width: withWidth ? 23 : undefined
      },
      {
        key: 'inn',
        content: 'ИНН',
        isSortable: false,
        width: withWidth ? 23 : undefined
      },
      {
        key: 'kpp',
        content: 'КПП',
        isSortable: false,
        width: withWidth ? 23 : undefined
      },
      {
        key: 'ogrn',
        content: 'ОГРН',
        shouldTruncate: true,
        width: withWidth ? 23 : undefined
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class OrganizationsTable extends React.Component {
  closeModal = id => {
    this.props.onChangeDeleteModals({
      modals: this.props.deleteModals.filter(i => i !== id)
    });
  };

  onDelete = id => {
    this.props.onDelete(id);
  };

  render() {
    const { deleteModals, isLoadingDelete } = this.props;
    const rowsTable = this.props.data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: createKey(item.name),
          content: (
            <TableItem>
              <Link to={`/settings/organizations/${item.id}`}>{item.name}</Link>
            </TableItem>
          )
        },
        {
          key: createKey(item.inn),
          content: <TableItem>{item.inn}</TableItem>
        },
        {
          key: createKey(item.kpp),
          content: <TableItem>{item.kpp}</TableItem>
        },
        {
          key: createKey(item.ogrn),
          content: <TableItem>{item.ogrn}</TableItem>
        },
        {
          content: (
            <DropdownMenu trigger={TriggerButton} position="bottom right">
              <DropdownItemGroup>
                <DropdownItem
                  onClick={() =>
                    this.props.onChangeDeleteModals({
                      modals: [item.id]
                    })
                  }
                >
                  Удалить
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )
        }
      ]
    }));

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          rows={rowsTable}
        />

        {deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить организацию"
            onClose={this.closeModal}
            actions={[
              {
                isLoading: isLoadingDelete,
                onClick: this.onDelete,
                text: 'Удалить'
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>Уверены, что хотите удалить организацию?</p>
          </Modal>
        ))}
      </div>
    );
  }
}

export default OrganizationsTable;
