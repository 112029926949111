import styled from 'styled-components';

const Desc = styled.p`
  opacity: 0.5;
  font-size: 12px;
  color: #deebff;
  letter-spacing: 0;
  line-height: 16px;
`;

export default Desc;
