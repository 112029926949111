export const GET_COMPARE_LIST_REQUEST = 'hh/pages/compareReports/GET_COMPARE_LIST_REQUEST';
export const GET_COMPARE_LIST_SUCCESS = 'hh/pages/compareReports/GET_COMPARE_LIST_SUCCESS';
export const GET_COMPARE_LIST_FAIL = 'hh/pages/compareReports/GET_COMPARE_LIST_FAIL';

export const CHANGE_REPORT_SELECTOR = 'hh/pages/compareReports/CHANGE_REPORT_SELECTOR';
export const DELETE_REPORT_FROM_COMPARE = 'hh/pages/compareReports/DELETE_REPORT_FROM_COMPARE';

export const OPEN_ADD_MODAL = 'hh/pages/compareReports/OPEN_ADD_MODAL';
export const CLOSE_ADD_MODAL = 'hh/pages/compareReports/CLOSE_ADD_MODAL';

export const CHANGE_COLUMN_POSITION = 'hh/pages/compareReports/CHANGE_COLUMN_POSITION';
