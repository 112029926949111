export const CHANGE_LOGIN = 'hh/pages/login/CHANGE_LOGIN';
export const CHANGE_PASSWORD = 'hh/pages/login/CHANGE_PASSWORD';
export const CHANGE_FLAGS = 'hh/pages/login/CHANGE_FLAGS';

export const LOGIN_REQUEST = 'hh/pages/login/LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'hh/pages/login/LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAIL = 'hh/pages/login/LOGIN_REQUEST_FAIL';

export const LOGOUT = 'hh/pages/login/LOGOUT';

export const SEND_OTP = 'hh/pages/login/SEND_OTP';
export const SEND_OTP_SUCCESS = 'hh/pages/login/SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'hh/pages/login/SEND_OTP_FAIL';
