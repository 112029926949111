import styled from 'styled-components';

const CenteredColumn = styled.div`
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
  width: 580px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(50% - 30px);
  }

  @media (max-width: 1020px) {
    margin-bottom: 20px;
    margin-right: auto;
    margin-top: 50px;
    width: calc(100% - 30px);
  }
`;

export default CenteredColumn;
