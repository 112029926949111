import {
  CHANGE_NAME,
  CHANGE_EMAIL,
  CHANGE_MODALS,
  CHANGE_TEXT,
  CHANGE_FLAGS,
  CHANGE_APPLY_FLAGS,
  SEND_FEEDBACK_REQUEST,
  SEND_FEEDBACK_REQUEST_SUCCESS,
  SEND_FEEDBACK_REQUEST_FAIL,
  SEND_SUBMIT_REQUEST,
  SEND_SUBMIT_REQUEST_SUCCESS,
  SEND_SUBMIT_REQUEST_FAIL
} from './constants';

export function changeApplyFlags(payload) {
  return {
    type: CHANGE_APPLY_FLAGS,
    ...payload
  };
}

export function changeModals(payload) {
  return {
    type: CHANGE_MODALS,
    ...payload
  };
}

export function sendSubmit(payload) {
  return {
    type: SEND_SUBMIT_REQUEST,
    ...payload
  };
}

export function sendSubmitSuccess(payload) {
  return {
    type: SEND_SUBMIT_REQUEST_SUCCESS,
    ...payload
  };
}

export function sendSubmitFail(payload) {
  return {
    type: SEND_SUBMIT_REQUEST_FAIL,
    ...payload
  };
}

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    ...payload
  };
}

export function changeEmail(payload) {
  return {
    type: CHANGE_EMAIL,
    ...payload
  };
}

export function changeText(payload) {
  return {
    type: CHANGE_TEXT,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function sendFeedback() {
  return {
    type: SEND_FEEDBACK_REQUEST
  };
}

export function sendFeedbackSuccess() {
  return {
    type: SEND_FEEDBACK_REQUEST_SUCCESS
  };
}

export function sendFeedbackFail() {
  return {
    type: SEND_FEEDBACK_REQUEST_FAIL
  };
}
