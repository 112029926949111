import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isEmail, isPhoneInProfile } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import RouterLink from 'components/RouterLink';
import TextField from 'components/TextField';

import reducer from 'pages/PartnerProfile/Partners/reducer';
import saga from 'pages/PartnerProfile/Partners/saga';

import {
  changePartnerRegistrationField,
  registerNewPartner,
  resetPartnerRegistrationForm
} from 'pages/PartnerProfile/Partners/actions';
import {
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading
} from 'pages/PartnerProfile/Partners/selectors';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class Registration extends Component {
  state = {
    formValid: {
      email: true,
      phone_number: true,
      first_name: true
    }
  };

  componentDidMount() {
    const { resetPartnerRegistrationForm } = this.props;

    resetPartnerRegistrationForm();
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeFormField = event => {
    const {
      props: { changePartnerRegistrationField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changePartnerRegistrationField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { registerNewPartner, registrationForm },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: registrationForm[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      registerNewPartner();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'first_name':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              first_name: result
            }
          });
        }

        return result;
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      case 'phone_number':
        result = isPhoneInProfile(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              phone_number: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderActions = () => (
    <ButtonGroup>
      <Button href="/partner/partners" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  renderBreadcrumbs = () => (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        href="/partner/partners"
        onClick={event => this.onBreadcrumbsClick(event, '/partner/partners')}
        text="Партнеры"
        key="partnerPartners"
      />
      <BreadcrumbsItem
        href="/partner/partners/create"
        onClick={event => this.onBreadcrumbsClick(event, '/partner/partners/create')}
        text="Регистрация"
        key="partnerPartnersCreate"
      />
    </BreadcrumbsStateless>
  );

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { loading, registrationForm },
      state: { formValid }
    } = this;

    return (
      <Form>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.first_name}
              required
              label="Имя"
              name="name"
              id="first_name"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={registrationForm.first_name}
            />
          </Field>
          <Field>
            <TextField
              label="Фамилия"
              name="last_name"
              id="last_name"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={registrationForm.last_name}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.email}
              required
              label="Email"
              name="name"
              id="email"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={registrationForm.email}
            />
          </Field>
          <Field>
            <TextField
              isInvalid={!formValid.phone_number}
              required
              mask="+79999999999"
              maskChar=""
              label="Телефон"
              name="phone"
              id="phone_number"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={registrationForm.phone_number}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <ButtonWrapper>
            <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
              Зарегистрировать
            </Button>
          </ButtonWrapper>
        </FieldGroup>
      </Form>
    );
  };

  render() {
    return (
      <Container>
        <Helmet>
          <title>Регистрация партнера</title>
        </Helmet>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.renderActions()} breadcrumbs={this.renderBreadcrumbs()}>
              Регистрация партнера
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{this.renderForm()}</GridColumn>
        </Grid>
      </Container>
    );
  }
}

Registration.propTypes = {
  changePartnerRegistrationField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  registerNewPartner: PropTypes.func.isRequired,
  registrationForm: PropTypes.object.isRequired,
  resetPartnerRegistrationForm: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changePartnerRegistrationField: value => dispatch(changePartnerRegistrationField(value)),
    registerNewPartner: () => dispatch(registerNewPartner()),
    resetPartnerRegistrationForm: () => dispatch(resetPartnerRegistrationForm())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectRegistrationLoading(),
  registrationForm: makeSelectRegistrationForm()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerPartners', reducer });
const withSaga = injectSaga({ key: 'partnerPartners', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Registration);
