import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'axios';
import apiUrl from 'utils/serverConfig';

import { RECOVER_REQUEST, NEW_PASSWORD_REQUEST } from './constants';

import {
  recoverRequestSuccess,
  recoverRequestFail,
  newPasswordRequestSuccess,
  newPasswordRequestFail
} from './actions';

import {
  makeSelectEmail,
  makeSelectNewPassword,
  makeSelectUid,
  makeSelectToken
} from './selectors';

function* recoverSaga() {
  const url = `${apiUrl}/api/managers/auth/password/reset/`;
  const email = yield select(makeSelectEmail());

  const options = {
    method: 'post',
    data: {
      email
    }
  };

  try {
    yield call(axios, url, options);

    yield put(recoverRequestSuccess());
  } catch (e) {
    yield put(recoverRequestFail());
  }
}

function* newPasswordSaga() {
  const url = `${apiUrl}/api/managers/auth/password/reset/confirm/`;
  const newPassword = yield select(makeSelectNewPassword());
  const uid = yield select(makeSelectUid());
  const token = yield select(makeSelectToken());

  const options = {
    method: 'post',
    data: {
      new_password1: newPassword,
      new_password2: newPassword,
      uid,
      token
    }
  };

  try {
    yield call(axios, url, options);

    yield put(newPasswordRequestSuccess());
    yield put(
      push({
        pathname: '/individual/login',
        state: {
          passwordChanged: true
        }
      })
    );
  } catch (e) {
    yield put(newPasswordRequestFail());

    yield put(
      push({
        pathname: '/recover',
        state: { showError: true }
      })
    );
  }
}

export default function* loginPageSaga() {
  yield takeLeading(RECOVER_REQUEST, recoverSaga);
  yield takeLeading(NEW_PASSWORD_REQUEST, newPasswordSaga);
}
