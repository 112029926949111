import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PartnerClientsBase from './components/PartnerClientsBase';

const PartnerClientsView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <PartnerClientsBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default PartnerClientsView;
