import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import CriticalToggle from 'components/CriticalToggle';
import Progress from 'components/Progress';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';

import {
  changeProfileNameCustom,
  changeProfileDescCustom,
  changePatternCustomLower,
  changePatternCustomUpper,
  changePatternCritical,
  metaProgramsList,
  profileCreateCustom
} from 'pages/ProfileList/actions';

import {
  makeSelectMetaProgramsList,
  makeSelectProfileCustom,
  makeSelectProfileLoading
} from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import CenteredBlock from './styled/CenteredBlock';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import MainBlock from './styled/MainBlock';
import P from './styled/P';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateCustom extends React.Component {
  state = {
    changeFlags: [],
    nameValid: true
  };

  componentDidMount() {
    this.props.getMetaPrograms();
  }

  addFlag = () => {
    this.setState({
      changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
    });
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button href="/profile/list" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  changeName = event => {
    this.setState({
      nameValid: !isEmpty(event.target.value)
    });
    this.props.changeName({
      name: event.target.value
    });
  };

  changeDesc = event => {
    this.props.changeDesc({
      desc: event.target.value
    });
  };

  changeLowerRange = (programId, patternId, value) => {
    this.props.changeRangeLower({
      patternId,
      value
    });
  };

  changeUpperRange = (programId, patternId, value) => {
    this.props.changeRangeUpper({
      patternId,
      value
    });
  };

  changeCriticalPattern = (patternId, value) => () =>
    this.props.changePatternCritical({ patternId, value });

  createProfile = () => {
    const { profile } = this.props;
    const nameValid = !isEmpty(profile.get('name'));

    if (nameValid) {
      this.props.profileCreateCustom();
      this.addFlag();
    } else {
      window.scrollTo(0, 0);
      this.setState({
        nameValid
      });
    }
  };

  renderPattern = pattern => (
    <Block key={pattern.get('id')}>
      <CenteredBlock>
        <h4>{pattern.get('name')}</h4>
        <CriticalToggle
          onChange={this.changeCriticalPattern(pattern.get('id'), !pattern.get('is_important'))}
          isChecked={pattern.get('is_important')}
        />
      </CenteredBlock>
      <P marginTop="9px">{pattern.get('description')}</P>
      <P color="#6B778C" marginTop="9px">
        Удерживайте и тяните бегунки
      </P>
      <Progress
        after={100 - pattern.getIn(['range', 'upper'])}
        before={pattern.getIn(['range', 'lower'])}
        onAfterChange={this.changeUpperRange}
        onBeforeChange={this.changeLowerRange}
        patternId={pattern.get('id')}
        programId={pattern.get('metaProgramId')}
        isCritical={pattern.get('is_important')}
      />
    </Block>
  );

  renderMetaPrograms = () => {
    const { metaProgramsList, profile } = this.props;

    return metaProgramsList.map(metaProgram => (
      <MainBlock key={metaProgram.get('id')}>
        <h3>{metaProgram.get('name')}</h3>
        <p>{metaProgram.get('description')}</p>
        {profile
          .get('pattern_ranges')
          .filter(pattern => pattern.get('metaProgramId') === metaProgram.get('id'))
          .map(this.renderPattern)}
      </MainBlock>
    ));
  };

  render() {
    const { loading, metaProgramsList, profile } = this.props;

    let content = <Spinner />;

    if (!loading && metaProgramsList.size > 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/profile/list"
            onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
            text="Профили"
            key="Profile"
          />
          <BreadcrumbsItem
            href="/profile/create/custom"
            onClick={event => this.onBreadcumbsClick(event, '/profile/create/custom')}
            text="Создать вручную"
            key="Custom"
          />
        </BreadcrumbsStateless>
      );

      const bottomBar = (
        <PageHeaderBottom>
          <h4>В ручную</h4>
        </PageHeaderBottom>
      );

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader
                breadcrumbs={breadcrumbs}
                actions={this.getPageHeaderButtonsDrawer()}
                bottomBar={bottomBar}
              >
                Добавить профиль
              </PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <div>
                <Form>
                  <FieldGroup>
                    <Field width="100%">
                      <TextField
                        isInvalid={!this.state.nameValid}
                        required
                        label="Название профиля"
                        onChange={this.changeName}
                        placeholder=""
                        shouldFitContainer
                        value={profile.get('name')}
                      />
                    </Field>
                  </FieldGroup>
                  <FieldGroup>
                    <Field width="100%">
                      <TextArea
                        label="Описание"
                        minimumRows={4}
                        onChange={this.changeDesc}
                        placeholder=""
                        shouldFitContainer
                        value={profile.get('description')}
                      />
                    </Field>
                  </FieldGroup>
                </Form>
              </div>
              {this.renderMetaPrograms()}
              <ButtonWrapper>
                <Button appearance="primary" onClick={this.createProfile}>
                  Создать профиль
                </Button>
              </ButtonWrapper>
            </GridColumn>
          </Grid>
        </Container>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        {content}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.changeFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Профиль создан"
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

ProfileCreateCustom.propTypes = {
  changeDesc: PropTypes.func,
  changeName: PropTypes.func,
  changeRangeLower: PropTypes.func,
  changeRangeUpper: PropTypes.func,
  changePatternCritical: PropTypes.func.isRequired,
  getMetaPrograms: PropTypes.func,
  loading: PropTypes.bool,
  metaProgramsList: PropTypes.object,
  profile: PropTypes.object,
  profileCreateCustom: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeName: value => dispatch(changeProfileNameCustom(value)),
    changeDesc: value => dispatch(changeProfileDescCustom(value)),
    changeRangeLower: value => dispatch(changePatternCustomLower(value)),
    changeRangeUpper: value => dispatch(changePatternCustomUpper(value)),
    changePatternCritical: value => dispatch(changePatternCritical(value)),
    getMetaPrograms: () => dispatch(metaProgramsList()),
    profileCreateCustom: () => dispatch(profileCreateCustom())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectProfileLoading(),
  metaProgramsList: makeSelectMetaProgramsList(),
  profile: makeSelectProfileCustom()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateCustom);
