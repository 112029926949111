import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getFill = (active, withoutHover) => {
  if (active || withoutHover) {
    return `
      & circle {
        fill: #0747A6;
        transition: all .3s ease-out;
      }
    `;
  }

  return `
    & circle {
      fill: #CACBCF;
      transition: all .3s ease-out;
    }

    &:hover circle {
      fill: #0747A6;
    }
  `;
};

const Svg = styled.svg`
  cursor: ${props => (props.withoutHover ? 'default' : 'pointer')};
  ${props => getFill(props.active, props.withoutHover)};
`;

const Like = ({ active, onClick, withoutHover }) => (
  <Svg
    active={active}
    onClick={onClick}
    withoutHover={withoutHover}
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="comment-01" transform="translate(-575.000000, -396.000000)">
        <g id="like" transform="translate(575.000000, 396.000000)">
          <circle id="Oval" fill="#0747A6" cx="16" cy="16" r="16" />
          <g id="Group" transform="translate(7.000000, 6.000000)" fill="#FFFFFF">
            <polygon id="Path" points="2.35135135 6 0 6 0 18 3 18 3 6" />
            <path d="M17.25,6.06897394 L10.6388889,6.06897394 L12.0972222,2.05854501 C12.5833333,0.654894887 10.8333333,-0.648494514 9.66666667,0.354112718 C9.375,0.654894887 8.88888889,1.45698067 8.69444444,1.75776284 C7.43055556,3.66271658 5,6.06897394 5,6.06897394 L5,18 L14.625,18 C15.6944444,18 16.6666667,17.1979142 16.9583333,16.0950463 L19,8.0741884 C19,6.97132045 18.2222222,6.06897394 17.25,6.06897394 Z" />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

Like.defaultProps = {
  active: false,
  onClick: () => {},
  withoutHover: false
};

Like.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  withoutHover: PropTypes.bool
};

export default Like;
