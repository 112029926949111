export const pageHostUrl =
  `https://${process.env.REACT_APP_DIST_ENV === 'staging' ? 'dev.' : ''}${process.env.REACT_APP_TARGET}`

const apiUrl =
  process.env.REACT_APP_TARGET === "lk.my-yadro.ru" ?
    `https://${process.env.REACT_APP_DIST_ENV === 'staging' ? 'dev.' : ''}api.my-yadro.ru`
    :
  `https://${process.env.REACT_APP_DIST_ENV === 'staging' ? 'dev.' : ''}api.${process.env.REACT_APP_TARGET}`

export default apiUrl;
