export const GET_PARTNERS_LIST = 'hh/pages/partner/partners/GET_PARTNERS_LIST';
export const GET_PARTNERS_LIST_SUCCESS = 'hh/pages/partner/partners/GET_PARTNERS_LIST_SUCCESS';
export const GET_PARTNERS_LIST_FAIL = 'hh/pages/partner/partners/GET_PARTNERS_LIST_FAIL';
export const CHANGE_PARTNERS_PAGE = 'hh/pages/partner/partners/CHANGE_PARTNERS_PAGE';
export const CHANGE_FLAGS = 'hh/pages/partner/partners/CHANGE_FLAGS';
export const CHANGE_PARTNER_REGISTRATION_FIELD =
  'hh/pages/partner/partners/CHANGE_PARTNER_REGISTRATION_FIELD';
export const RESET_PARTNER_REGISTRATION_FORM =
  'hh/pages/partner/partners/RESET_PARTNER_REGISTRATION_FORM';
export const REGISTER_NEW_PARTNER = 'hh/pages/partner/partners/REGISTER_NEW_PARTNER';
export const REGISTER_NEW_PARTNER_SUCCESS =
  'hh/pages/partner/partners/REGISTER_NEW_PARTNER_SUCCESS';
export const REGISTER_NEW_PARTNER_FAIL = 'hh/pages/partner/partners/REGISTER_NEW_PARTNER_FAIL';
export const GET_PARTNER = 'hh/pages/partner/partners/GET_PARTNER';
export const GET_PARTNER_SUCCESS = 'hh/pages/partner/partners/GET_PARTNER_SUCCESS';
export const GET_PARTNER_FAIL = 'hh/pages/partner/partners/GET_PARTNER_FAIL';
export const CHANGE_PARTNER_COMMENT_FIELD =
  'hh/pages/partner/partners/CHANGE_PARTNER_COMMENT_FIELD';
export const UPDATE_PARTNER = 'hh/pages/partner/partners/UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCESS = 'hh/pages/partner/partners/UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAIL = 'hh/pages/partner/partners/UPDATE_PARTNER_FAIL';
export const GET_CLIENTS_LIST = 'hh/pages/partner/partners/GET_CLIENTS_LIST';
export const GET_CLIENTS_LIST_SUCCESS = 'hh/pages/partner/partners/GET_CLIENTS_LIST_SUCCESS';
export const GET_CLIENTS_LIST_FAIL = 'hh/pages/partner/partners/GET_CLIENTS_LIST_FAIL';
export const CHANGE_CLIENTS_PAGE = 'hh/pages/partner/partners/CHANGE_CLIENTS_PAGE';
export const GET_REQUISITES_LIST = 'hh/pages/partner/partners/GET_REQUISITES_LIST';
export const GET_REQUISITES_LIST_SUCCESS = 'hh/pages/partner/partners/GET_REQUISITES_LIST_SUCCESS';
export const GET_REQUISITES_LIST_FAIL = 'hh/pages/partner/partners/GET_REQUISITES_LIST_FAIL';
export const CHANGE_REQUISITES_PAGE = 'hh/pages/partner/partners/CHANGE_REQUISITES_PAGE';
export const CHANGE_INVITE_MODAL = 'hh/pages/partner/partners/CHANGE_INVITE_MODAL';
export const CHANGE_INVITE_FIELD = 'hh/pages/partner/partners/CHANGE_INVITE_FIELD';
export const SEND_INVITE = 'hh/pages/partner/partners/SEND_INVITE';
export const SEND_INVITE_SUCCESS = 'hh/pages/partner/partners/SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAIL = 'hh/pages/partner/partners/SEND_INVITE_FAIL';
export const CHANGE_FILTER_FIELD = 'hh/pages/partner/partners/CHANGE_FILTER_FIELD';
