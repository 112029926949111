import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import HomePage from 'pages/Home';

const HomeView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <HomePage />
  </Fragment>
);

export default HomeView;
