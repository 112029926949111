import { call, select, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import apiUrl from 'utils/serverConfig';

import { SEND_LEAD_REQUEST } from './constants';

import { sendLeadSuccess, sendLeadFail } from './actions';

import { makeSelectName, makeSelectPhone } from './selectors';

function* sendLeadSaga() {
  const url = `${apiUrl}/api/managers/leads/`;
  const name = yield select(makeSelectName());
  const phone_number = yield select(makeSelectPhone());
  const options = {
    method: 'post',
    data: {
      name,
      phone_number
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendLeadSuccess());
  } catch (e) {
    yield put(sendLeadFail());
  }
}

export default function* promoSagas() {
  yield takeLeading(SEND_LEAD_REQUEST, sendLeadSaga);
}
