import { call, put, select, takeLeading } from 'redux-saga/effects';
import { apiRequest } from 'utils/partnerRequest';

import { GET_USER, UPDATE_USER_COMMON, UPDATE_USER_EMAIL, UPDATE_USER_PASSWORD } from './constants';

import {
  getUserSuccess,
  getUserFail,
  updateUserCommonSuccess,
  updateUserCommonFail,
  updateUserEmailSuccess,
  updateUserEmailFail,
  updateUserPasswordSuccess,
  updateUserPasswordFail
} from './actions';

import { makeSelectUserForm, makeSelectUserPassword } from './selectors';

function* getUserSaga() {
  const url = 'api/partners/auth/user';

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getUserSuccess({
        user: request.data
      })
    );
  } catch (error) {
    yield put(
      getUserFail({
        error: error.response.data
      })
    );
  }
}

function* updateCommonSaga() {
  const url = 'api/partners/auth/user';
  const form = yield select(makeSelectUserForm());
  const options = {
    method: 'patch',
    data: {
      phone_number: form.phone_number,
      first_name: form.first_name,
      last_name: form.last_name
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updateUserCommonSuccess());
  } catch (e) {
    yield put(updateUserCommonFail());
  }
}

function* updateEmailSaga() {
  const url = 'api/partners/auth/user';
  const form = yield select(makeSelectUserForm());
  const options = {
    method: 'patch',
    data: {
      email: form.email
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updateUserEmailSuccess());
  } catch (e) {
    yield put(updateUserEmailFail());
  }
}

function* updatePasswordSaga() {
  const url = 'api/partners/auth/password/change/';
  const form = yield select(makeSelectUserPassword());
  const options = {
    method: 'post',
    data: {
      new_password1: form.new_password,
      new_password2: form.new_password
    }
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updateUserPasswordSuccess());
  } catch (e) {
    yield put(updateUserPasswordFail());
  }
}

export default function* partnerClientsSaga() {
  yield takeLeading(GET_USER, getUserSaga);
  yield takeLeading(UPDATE_USER_COMMON, updateCommonSaga);
  yield takeLeading(UPDATE_USER_EMAIL, updateEmailSaga);
  yield takeLeading(UPDATE_USER_PASSWORD, updatePasswordSaga);
}
