import { fromJS } from 'immutable';
import isUndefined from 'lodash/isUndefined';
import uuid from 'uuid/v1';

import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_REQUEST_SUCCESS,
  GET_USER_INFO_REQUEST_FAIL,
  GET_MANAGERS_LIST_REQUEST,
  GET_MANAGERS_FILTERED_LIST_REQUEST,
  GET_MANAGERS_LIST_REQUEST_SUCCESS,
  GET_MANAGERS_LIST_REQUEST_FAIL,
  CHANGE_COMMENT_INVITE,
  CHANGE_FLAGS_INVITE,
  CHANGE_FULL_NAME_INVITE,
  CHANGE_EMAIL_INVITE,
  CHANGE_MODALS_INVITE,
  CHANGE_NAME_INVITE,
  CHANGE_ROLE_INVITE,
  CHANGE_TEXT_INVITE,
  SEND_INVITE_REQUEST,
  SEND_INVITE_REQUEST_SUCCESS,
  SEND_INVITE_REQUEST_FAIL,
  GET_INVITES_LIST_REQUEST,
  GET_INVITES_LIST_REQUEST_SUCCESS,
  GET_INVITES_LIST_REQUEST_FAIL,
  CHANGE_DELETE_INVITE_MODALS,
  DELETE_INVITE_REQUEST,
  DELETE_INVITE_REQUEST_SUCCESS,
  DELETE_INVITE_REQUEST_FAIL,
  LOADING_FILTER_END,
  CHANGE_NAME_FILTER,
  CHANGE_INVITES_NAME_FILTER,
  CHANGE_MANAGERS_PAGE,
  CHANGE_INVITES_PAGE,
  CHANGE_MANAGERS_SORT,
  CHANGE_INVITES_SORT,
  GET_BILLING_PLANS_LIST_REQUEST,
  GET_BILLING_PLANS_LIST_REQUEST_SUCCESS,
  GET_BILLING_PLANS_LIST_REQUEST_FAIL,
  OPEN_PROLONG_PLAN_MODAL,
  CLOSE_PROLONG_PLAN_MODAL,
  PROLONG_PLAN_REQUEST,
  PROLONG_PLAN_REQUEST_SUCCESS,
  PROLONG_PLAN_REQUEST_FAIL,
  CHANGE_PROLONG_FLAG,
  CHECK_PROLONG_ORDER_REQUEST,
  CHECK_PROLONG_ORDER_REQUEST_SUCCESS,
  CHECK_PROLONG_ORDER_REQUEST_FAIL,
  OPEN_ACTIVATE_PLAN_MODAL,
  CLOSE_ACTIVATE_PLAN_MODAL,
  ACTIVATE_PLAN_REQUEST,
  ACTIVATE_PLAN_REQUEST_SUCCESS,
  ACTIVATE_PLAN_REQUEST_FAIL,
  CHANGE_PAYMENT_ORGANIZATION,
  CHANGE_PAYMENT_EMAIL,
  CHANGE_PAYMENT_METHOD,
  CHANGE_VISA_POPUP,
  CHANGE_PAYMENT_POPUP,
  CHANGE_PAYMENT_LOADING,
  SEND_PAYMENT_REQUEST,
  SEND_PAYMENT_REQUEST_SUCCESS,
  SEND_PAYMENT_REQUEST_FAIL,
  SEND_TO_PAYMENT_PAGE,
  PAYMENT_WAS_SEND_CHANGE,
  GET_ORGANIZATIONS_LIST_REQUEST,
  GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS,
  GET_ORGANIZATIONS_LIST_REQUEST_FAIL,
  CHANGE_DELETE_ORGANIZATION_MODALS,
  CHANGE_ORGANIZATION_ID,
  CHANGE_ORGANIZATIONS_FLAGS,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_REQUEST_SUCCESS,
  DELETE_ORGANIZATION_REQUEST_FAIL,
  GET_ORGANIZATION_READ_REQUEST,
  GET_ORGANIZATION_READ_REQUEST_SUCCESS,
  GET_ORGANIZATION_READ_REQUEST_FAIL,
  CHANGE_ORGANIZATION_NAME,
  CHANGE_ORGANIZATION_INN,
  CHANGE_ORGANIZATION_KPP,
  CHANGE_ORGANIZATION_OGRN,
  CHANGE_ORGANIZATION_INDEX,
  CHANGE_ORGANIZATION_ADDRESS,
  CHANGE_ORGANIZATION_ACCOUNT_NUMBER,
  CHANGE_ORGANIZATION_BANK_NAME,
  CHANGE_ORGANIZATION_BANK_BIK,
  CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_REQUEST_SUCCESS,
  CREATE_ORGANIZATION_REQUEST_FAIL,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_REQUEST_SUCCESS,
  EDIT_ORGANIZATION_REQUEST_FAIL,
  CHANGE_INVITE_STAFF_NAME,
  CHANGE_INVITE_STAFF_FULL_NAME,
  CHANGE_INVITE_STAFF_EMAIL,
  CHANGE_INVITE_STAFF_TEXT,
  CHANGE_INVITE_STAFF_COMMENT,
  CHANGE_INVITE_STAFF_FLAGS,
  CHANGE_INVITE_STAFF_MODALS,
  SEND_INVITE_STAFF_REQUEST,
  SEND_INVITE_STAFF_REQUEST_SUCCESS,
  SEND_INVITE_STAFF_REQUEST_FAIL,
  SEND_INVITE_STAFF_BY_FILE_REQUEST,
  SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS,
  SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL,
  CHANGE_INVITE_STAFF_BY_FILE_FLAGS
} from './constants';

import { GET_USER_READ_REQUEST_SUCCESS } from 'pages/Home/constants';

const initialState = fromJS({
  deleteModals: [],
  organizationDeleteModals: [],
  loading: false,
  loadingActivate: false,
  loadingBilling: false,
  loadingCompany: false,
  loadingDelete: false,
  loadingInvite: false,
  loadingOrganizations: false,
  loadingOrganizationCreate: false,
  loadingProlong: false,
  loadingTable: false,
  plansList: [],
  user: {
    company_inn: null,
    company_name: null,
    email: null,
    first_name: null,
    last_name: null,
    pk: 0,
    phone_number: null
  },
  managersCount: 0,
  managersPage: 1,
  managersList: [],
  invite: {
    comment: '',
    full_name: '',
    email: '',
    name: '',
    role: 1,
    welcome_text: ''
  },
  invitesCount: 0,
  invitesFilter: '',
  inviteFlags: [],
  inviteModals: [],
  invitesPage: 1,
  invitesList: [],
  inviteId: null,
  nameFilter: '',
  managersSort: {
    key: '',
    order: ''
  },
  invitesSort: {
    key: '',
    order: ''
  },
  prolongModal: [],
  checkProlongOrderId: null,
  checkProlongOrderResult: [],
  activateModal: [],
  activatePlanId: 0,
  payment: {
    cost: 0,
    email: '',
    loading: false,
    method: 2,
    service: '',
    showPaymentPopup: false,
    showVisaPopup: false,
    type: '',
    organization: null,
    wasSend: []
  },
  organizationsCount: 0,
  organizationsFlags: [],
  organizationsList: [],
  organizationsMounted: false,
  organizationsPage: 1,
  organizationId: null,
  organization: {
    name: '',
    inn: '',
    kpp: '',
    ogrn: '',
    index: '',
    address: '',
    accountNumber: '',
    bankName: '',
    bankBik: '',
    correspondentAccountNumber: ''
  },
  inviteStaff: {
    comment: '',
    email: '',
    full_name: '',
    name: '',
    type: 0,
    welcome_text: ''
  },
  inviteStaffFlags: [],
  inviteStaffModals: [],
  invitesByFileCount: 0,
  invitesByFileFlags: []
});

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ORGANIZATIONS_FLAGS:
      return state.set('organizationsFlags', fromJS(action.flags));
    case EDIT_ORGANIZATION_REQUEST:
      return state.set('loadingOrganizationCreate', true);
    case EDIT_ORGANIZATION_REQUEST_SUCCESS:
      return state
        .set('organizationsFlags', state.get('organizationsFlags').insert(0, uuid()))
        .set('loadingOrganizationCreate', false);
    case EDIT_ORGANIZATION_REQUEST_FAIL:
      return state.set('loadingOrganizationCreate', false);
    case CREATE_ORGANIZATION_REQUEST:
      return state.set('loadingOrganizationCreate', true);
    case CREATE_ORGANIZATION_REQUEST_SUCCESS:
      return state.set('loadingOrganizationCreate', false);
    case CREATE_ORGANIZATION_REQUEST_FAIL:
      return state.set('loadingOrganizationCreate', false);
    case CHANGE_ORGANIZATION_NAME:
      return state.setIn(['organization', 'newName'], action.name);
    case CHANGE_ORGANIZATION_INN:
      return state.setIn(['organization', 'inn'], action.inn);
    case CHANGE_ORGANIZATION_KPP:
      return state.setIn(['organization', 'kpp'], action.kpp);
    case CHANGE_ORGANIZATION_OGRN:
      return state.setIn(['organization', 'ogrn'], action.ogrn);
    case CHANGE_ORGANIZATION_INDEX:
      return state.setIn(['organization', 'index'], action.index);
    case CHANGE_ORGANIZATION_ADDRESS:
      return state.setIn(['organization', 'address'], action.address);
    case CHANGE_ORGANIZATION_ACCOUNT_NUMBER:
      return state.setIn(['organization', 'accountNumber'], action.accountNumber);
    case CHANGE_ORGANIZATION_BANK_NAME:
      return state.setIn(['organization', 'bankName'], action.bankName);
    case CHANGE_ORGANIZATION_BANK_BIK:
      return state.setIn(['organization', 'bankBik'], action.bankBik);
    case CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER:
      return state.setIn(
        ['organization', 'correspondentAccountNumber'],
        action.correspondentAccountNumber
      );
    case CHANGE_ORGANIZATION_ID:
      return state
        .setIn(['organization', 'name'], '')
        .setIn(['organization', 'newName'], '')
        .setIn(['organization', 'inn'], '')
        .setIn(['organization', 'kpp'], '')
        .setIn(['organization', 'ogrn'], '')
        .setIn(['organization', 'index'], '')
        .setIn(['organization', 'address'], '')
        .setIn(['organization', 'accountNumber'], '')
        .setIn(['organization', 'bankName'], '')
        .setIn(['organization', 'bankBik'], '')
        .setIn(['organization', 'correspondentAccountNumber'], '')
        .set('organizationId', action.organizationId)
        .set('organizationsFlags', fromJS([]));
    case GET_ORGANIZATION_READ_REQUEST:
      return state
        .set(
          'organizationsFlags',
          action.flags ? fromJS(action.flags) : state.get('organizationsFlags')
        )
        .set('loadingOrganizations', action.withoutLoading ? false : true)
        .set('organizationId', action.organizationId);
    case GET_ORGANIZATION_READ_REQUEST_SUCCESS:
      return state
        .setIn(['organization', 'name'], action.name)
        .setIn(['organization', 'newName'], action.name)
        .setIn(['organization', 'inn'], action.inn)
        .setIn(['organization', 'kpp'], action.kpp)
        .setIn(['organization', 'ogrn'], action.ogrn)
        .setIn(['organization', 'index'], action.index)
        .setIn(['organization', 'address'], action.address)
        .setIn(['organization', 'accountNumber'], action.account_number)
        .setIn(['organization', 'bankName'], action.bank_name)
        .setIn(['organization', 'bankBik'], action.bank_bik)
        .setIn(['organization', 'correspondentAccountNumber'], action.correspondent_account_number)
        .set('loadingOrganizations', false);
    case GET_ORGANIZATION_READ_REQUEST_FAIL:
      return state.set('loadingOrganizations', false);
    case GET_ORGANIZATIONS_LIST_REQUEST:
      return state
        .set('organizationsPage', action.organizationsPage)
        .set(
          'organizationsMounted',
          isUndefined(action.organizationsMounted) ? false : action.organizationsMounted
        )
        .set('loadingOrganizations', true);
    case GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS:
      return state
        .set('loadingOrganizations', false)
        .set('organizationsMounted', false)
        .set('organizationsCount', action.organizationsCount)
        .set('organizationsList', fromJS(action.organizationsList));
    case GET_ORGANIZATIONS_LIST_REQUEST_FAIL:
      return state.set('organizationsMounted', false).set('loadingOrganizations', true);
    case DELETE_ORGANIZATION_REQUEST:
      return state.set('loadingDelete', true).set('organizationId', action.organizationId);
    case DELETE_ORGANIZATION_REQUEST_SUCCESS:
      return state.set('loadingDelete', false).set('organizationId', null);
    case DELETE_ORGANIZATION_REQUEST_FAIL:
      return state.set('loadingDelete', false).set('organizationId', null);
    case PAYMENT_WAS_SEND_CHANGE:
      return state.setIn(['payment', 'wasSend'], action.wasSend ? fromJS([1]) : fromJS([]));
    case SEND_TO_PAYMENT_PAGE:
      return state
        .set('activateModal', fromJS([]))
        .set('prolongModal', fromJS([]))
        .setIn(['payment', 'cost'], action.cost)
        .setIn(['payment', 'service'], action.service)
        .setIn(['payment', 'type'], action.paymentType);
    case CHANGE_PAYMENT_LOADING:
      return state.setIn(['payment', 'loading'], action.loading);
    case CHANGE_PAYMENT_METHOD:
      return state.setIn(['payment', 'method'], action.method);
    case CHANGE_PAYMENT_EMAIL:
      return state.setIn(['payment', 'email'], action.email);
    case CHANGE_PAYMENT_ORGANIZATION:
      return state.setIn(['payment', 'organization'], action.organization);
    case CHANGE_VISA_POPUP:
      return state.setIn(['payment', 'showVisaPopup'], action.popup);
    case CHANGE_PAYMENT_POPUP:
      return state.setIn(['payment', 'showPaymentPopup'], action.popup);
    case SEND_PAYMENT_REQUEST:
      return state.setIn(['payment', 'loading'], true);
    case SEND_PAYMENT_REQUEST_SUCCESS:
      return state.setIn(['payment', 'loading'], false);
    case SEND_PAYMENT_REQUEST_FAIL:
      return state.setIn(['payment', 'loading'], false);
    case OPEN_ACTIVATE_PLAN_MODAL:
      return state.set('activatePlanId', action.activatePlanId).set('activateModal', fromJS([1]));
    case CLOSE_ACTIVATE_PLAN_MODAL:
      return state.set('activateModal', fromJS([]));
    case ACTIVATE_PLAN_REQUEST:
      return state.setIn(['payment', 'loading'], true).set('loadingActivate', true);
    case ACTIVATE_PLAN_REQUEST_SUCCESS:
      return state
        .setIn(['payment', 'email'], '')
        .setIn(['payment', 'loading'], false)
        .setIn(['payment', 'showPaymentPopup'], false)
        .setIn(['payment', 'showVisaPopup'], false)
        .set('activateModal', fromJS([]))
        .set('checkProlongOrderResult', fromJS(action.isPaidFlag))
        .set('loadingActivate', false);
    case ACTIVATE_PLAN_REQUEST_FAIL:
      return state
        .setIn(['payment', 'email'], '')
        .setIn(['payment', 'loading'], false)
        .setIn(['payment', 'showPaymentPopup'], false)
        .setIn(['payment', 'showVisaPopup'], false)
        .set('checkProlongOrderResult', fromJS([-1]))
        .set('activateModal', fromJS([]))
        .set('loadingActivate', false);
    case CHECK_PROLONG_ORDER_REQUEST:
      return state
        .set('loadingBilling', true)
        .set('checkProlongOrderId', action.orderId)
        .set('checkProlongOrderResult', fromJS([]));
    case CHECK_PROLONG_ORDER_REQUEST_SUCCESS:
      return state.set('loadingBilling', false).set('checkProlongOrderResult', fromJS([1]));
    case CHECK_PROLONG_ORDER_REQUEST_FAIL:
      return state.set('loadingBilling', false).set('checkProlongOrderResult', fromJS([-1]));
    case CHANGE_PROLONG_FLAG:
      return state.set('checkProlongOrderResult', fromJS(action.prolongFlag));
    case PROLONG_PLAN_REQUEST:
      return state.setIn(['payment', 'loading'], true).set('loadingProlong', true);
    case PROLONG_PLAN_REQUEST_SUCCESS:
      return state
        .setIn(['payment', 'email'], '')
        .setIn(['payment', 'loading'], false)
        .setIn(['payment', 'showPaymentPopup'], false)
        .setIn(['payment', 'showVisaPopup'], false)
        .set('loadingProlong', false);
    case PROLONG_PLAN_REQUEST_FAIL:
      return state
        .setIn(['payment', 'email'], '')
        .setIn(['payment', 'loading'], false)
        .setIn(['payment', 'showPaymentPopup'], false)
        .setIn(['payment', 'showVisaPopup'], false)
        .set('loadingProlong', false);

    case OPEN_PROLONG_PLAN_MODAL:
      return state.set('prolongModal', fromJS([1]));
    case CLOSE_PROLONG_PLAN_MODAL:
      return state.set('prolongModal', fromJS([]));
    case GET_BILLING_PLANS_LIST_REQUEST:
      return state.set('loadingBilling', true);
    case GET_BILLING_PLANS_LIST_REQUEST_SUCCESS:
      return state.set('plansList', fromJS(action.plansList)).set('loadingBilling', false);
    case GET_BILLING_PLANS_LIST_REQUEST_FAIL:
      return state.set('loadingBilling', false);

    case CHANGE_MANAGERS_SORT:
      return state
        .setIn(['managersSort', 'key'], action.key)
        .setIn(['managersSort', 'order'], action.order)
        .set('managersPage', 1);
    case CHANGE_INVITES_SORT:
      return state
        .setIn(['invitesSort', 'key'], action.key)
        .setIn(['invitesSort', 'order'], action.order)
        .set('invitesPage', 1);
    case CHANGE_NAME_FILTER:
      return state.set('nameFilter', action.nameFilter).set('managersPage', 1);
    case CHANGE_INVITES_NAME_FILTER:
      return state.set('invitesFilter', action.invitesFilter).set('invitesPage', 1);
    case LOADING_FILTER_END:
      return state.set('loadingTable', false);
    case GET_USER_INFO_REQUEST:
      return state.set('loading', true);
    case GET_USER_INFO_REQUEST_SUCCESS:
      return state.set('loading', false).set('user', action.user);
    case GET_USER_INFO_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_MANAGERS_PAGE:
      return state.set('managersPage', action.managersPage);
    case CHANGE_INVITES_PAGE:
      return state.set('invitesPage', action.invitesPage);

    case GET_MANAGERS_LIST_REQUEST:
      return state.set('loading', true);
    case GET_MANAGERS_FILTERED_LIST_REQUEST:
      return state.set('loadingTable', true);
    case GET_MANAGERS_LIST_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('managersList', action.managersList)
        .set('managersCount', action.managersCount);
    case GET_MANAGERS_LIST_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_COMMENT_INVITE:
      return state.setIn(['invite', 'comment'], action.comment);
    case CHANGE_FLAGS_INVITE:
      return state.set('inviteFlags', fromJS(action.flags));
    case CHANGE_FULL_NAME_INVITE:
      return state.setIn(['invite', 'full_name'], action.fullName);
    case CHANGE_EMAIL_INVITE:
      return state.setIn(['invite', 'email'], action.email);
    case CHANGE_MODALS_INVITE:
      return state.set('inviteModals', fromJS(action.modals));
    case CHANGE_NAME_INVITE:
      return state.setIn(['invite', 'name'], action.name);
    case CHANGE_ROLE_INVITE:
      return state.setIn(['invite', 'role'], action.role);
    case CHANGE_TEXT_INVITE:
      return state.setIn(['invite', 'welcome_text'], action.text);

    case SEND_INVITE_REQUEST:
      return state.set('loadingInvite', true);
    case SEND_INVITE_REQUEST_SUCCESS:
      return state
        .set('loadingInvite', false)
        .setIn(['invite', 'comment'], '')
        .setIn(['invite', 'full_name'], '')
        .setIn(['invite', 'name'], '')
        .setIn(['invite', 'email'], '')
        .setIn(['invite', 'role'], 1)
        .setIn(['invite', 'welcome_text'], '')
        .set('inviteFlags', fromJS([1]))
        .set('inviteModals', fromJS([]));
    case SEND_INVITE_REQUEST_FAIL:
      return state
        .set('loadingInvite', false)
        .setIn(['invite', 'comment'], '')
        .setIn(['invite', 'full_name'], '')
        .setIn(['invite', 'name'], '')
        .setIn(['invite', 'email'], '')
        .setIn(['invite', 'role'], 1)
        .setIn(['invite', 'welcome_text'], '')
        .set('inviteFlags', fromJS([]))
        .set('inviteModals', fromJS([]));

    case GET_INVITES_LIST_REQUEST:
      return state.set('loadingTable', true);
    case GET_INVITES_LIST_REQUEST_SUCCESS:
      return state
        .set('loadingTable', false)
        .set('invitesList', action.invitesList)
        .set('invitesCount', action.invitesCount);
    case GET_INVITES_LIST_REQUEST_FAIL:
      return state.set('loadingTable', false);

    case CHANGE_DELETE_ORGANIZATION_MODALS:
      return state.set('organizationDeleteModals', fromJS(action.modals));
    case CHANGE_DELETE_INVITE_MODALS:
      return state.set('deleteModals', fromJS(action.modals));
    case DELETE_INVITE_REQUEST:
      return state.set('loadingDelete', true).set('inviteId', action.inviteId);
    case DELETE_INVITE_REQUEST_SUCCESS:
      return state.set('loadingDelete', false).set('deleteModals', fromJS([]));
    case DELETE_INVITE_REQUEST_FAIL:
      return state.set('loadingDelete', false).set('deleteModals', fromJS([]));

    case GET_USER_READ_REQUEST_SUCCESS:
      return state
        .setIn(
          ['invite', 'welcome_text'],
          `${action.first_name} ${action.last_name} пригласил Вас присоединиться к компании ${
            action.company_name
          } на сервисе HRHelper.`
        )
        .setIn(
          ['inviteStaff', 'welcome_text'],
          localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            ? localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            : `${action.first_name} ${action.last_name} из компании ${
                action.company_name
              } пригласил Вас пройти опрос на сервисе HRHelper.`
        );

    case CHANGE_INVITE_STAFF_COMMENT:
      return state.setIn(['inviteStaff', 'comment'], action.comment);
    case CHANGE_INVITE_STAFF_EMAIL:
      return state.setIn(['inviteStaff', 'email'], action.email);
    case CHANGE_INVITE_STAFF_NAME:
      return state.setIn(['inviteStaff', 'name'], action.name);
    case CHANGE_INVITE_STAFF_FULL_NAME:
      return state.setIn(['inviteStaff', 'full_name'], action.fullName);
    case CHANGE_INVITE_STAFF_TEXT:
      return state.setIn(['inviteStaff', 'welcome_text'], action.text);

    case CHANGE_INVITE_STAFF_FLAGS:
      return state.set('inviteStaffFlags', fromJS(action.flags));
    case CHANGE_INVITE_STAFF_MODALS:
      return state.set('inviteStaffModals', fromJS(action.modals));

    case SEND_INVITE_STAFF_REQUEST:
      return state.set('loadingInvite', true);
    case SEND_INVITE_STAFF_REQUEST_SUCCESS:
      return state
        .set('loadingInvite', false)
        .setIn(['inviteStaff', 'email'], '')
        .setIn(['inviteStaff', 'full_name'], '')
        .setIn(['inviteStaff', 'name'], '')
        .setIn(['inviteStaff', 'type'], 0)
        .setIn(['inviteStaff', 'comment'], '')
        .setIn(
          ['inviteStaff', 'welcome_text'],
          localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            ? localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            : `${state.getIn(['user', 'first_name'])} ${state.getIn([
                'user',
                'last_name'
              ])} из компании ${state.getIn([
                'user',
                'company_name'
              ])} пригласил Вас пройти опрос на сервисе HRHelper.`
        )
        .set('inviteStaffFlags', fromJS([1]))
        .set('inviteStaffModals', fromJS([]));
    case SEND_INVITE_STAFF_REQUEST_FAIL:
      return state
        .set('loadingInvite', false)
        .setIn(['inviteStaff', 'email'], '')
        .setIn(['inviteStaff', 'full_name'], '')
        .setIn(['inviteStaff', 'name'], '')
        .setIn(['inviteStaff', 'type'], 0)
        .setIn(['inviteStaff', 'comment'], '')
        .set('inviteStaffFlags', fromJS([]))
        .set('inviteStaffModals', fromJS([]));
    case SEND_INVITE_STAFF_BY_FILE_REQUEST:
      return state
        .set('invitesByFileCount', 0)
        .set('invitesByFileFlags', fromJS([]))
        .set('loadingInvite', true);
    case SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS:
      return state
        .set('loadingInvite', false)
        .setIn(['inviteStaff', 'email'], '')
        .setIn(['inviteStaff', 'full_name'], '')
        .setIn(['inviteStaff', 'name'], '')
        .setIn(['inviteStaff', 'type'], 0)
        .setIn(['inviteStaff', 'comment'], '')
        .setIn(
          ['inviteStaff', 'welcome_text'],
          localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            ? localStorage.getItem('754a618cb45ba8931da2b13d83de2ccf')
            : `${state.getIn(['user', 'first_name'])} ${state.getIn([
                'user',
                'last_name'
              ])} из компании ${state.getIn([
                'user',
                'company_name'
              ])} пригласил Вас пройти опрос на сервисе HRHelper.`
        )
        .set('invitesByFileCount', action.count)
        .set('invitesByFileFlags', fromJS([1]))
        .set('inviteStaffModals', fromJS([]));
    case SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL:
      return state
        .set('loadingInvite', false)
        .setIn(['inviteStaff', 'email'], '')
        .setIn(['inviteStaff', 'full_name'], '')
        .setIn(['inviteStaff', 'name'], '')
        .setIn(['inviteStaff', 'type'], 0)
        .setIn(['inviteStaff', 'comment'], '')
        .set('invitesByFileCount', 0)
        .set('invitesByFileFlags', fromJS([-1]))
        .set('inviteStaffModals', fromJS([]));
    case CHANGE_INVITE_STAFF_BY_FILE_FLAGS:
      return state.set('invitesByFileFlags', fromJS(action.flags));
    default:
      return state;
  }
}

export default settingsReducer;
