import { fromJS } from 'immutable';

import {
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_SUCCESS,
  DOCUMENTS_LIST_FAIL,
  DOCUMENT_READ_REQUEST,
  DOCUMENT_READ_SUCCESS,
  DOCUMENT_READ_FAIL
} from './constants';

const initialState = fromJS({
  activeDocument: null,
  activeDocumentId: null,
  documents: [],
  loading: false
});

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case DOCUMENTS_LIST_REQUEST:
      return state.set('loading', true);
    case DOCUMENTS_LIST_SUCCESS:
      return state.set('documents', fromJS(action.documents)).set('loading', false);
    case DOCUMENTS_LIST_FAIL:
      return state.set('loading', false);
    case DOCUMENT_READ_REQUEST:
      return state.set('loading', true).set('activeDocumentId', action.id);
    case DOCUMENT_READ_SUCCESS:
      return state.set('activeDocument', action.activeDocument).set('loading', false);
    case DOCUMENT_READ_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default documentsReducer;
