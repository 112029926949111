import {
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  CHANGE_CLIENTS_LIST_PAGE,
  CHANGE_CLIENT_REGISTRATION_FIELD,
  RESET_CLIENT_REGISTRATION_FORM,
  REGISTER_NEW_CLIENT,
  REGISTER_NEW_CLIENT_SUCCESS,
  REGISTER_NEW_CLIENT_FAIL,
  CHANGE_FLAGS,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  CHANGE_CLIENT_COMMENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  GET_MANAGERS_LIST,
  GET_MANAGERS_LIST_SUCCESS,
  GET_MANAGERS_LIST_FAIL,
  CHANGE_MANAGERS_PAGE,
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  GET_FINANCES_LIST,
  GET_FINANCES_LIST_SUCCESS,
  GET_FINANCES_LIST_FAIL,
  CHANGE_FINANCES_PAGE,
  CHANGE_INVITE_FIELD,
  CHANGE_INVITE_MODAL,
  SEND_INVITE,
  SEND_INVITE_FAIL,
  SEND_INVITE_SUCCESS,
  CHANGE_FILTER_FIELD,
  CHANGE_IS_MINE_FILTER,
  CHANGE_DATE_FILTER
} from './constants';

export function getClientsList() {
  return {
    type: GET_CLIENTS_LIST
  };
}

export function getClientsListSuccess(payload) {
  return {
    type: GET_CLIENTS_LIST_SUCCESS,
    ...payload
  };
}

export function getClientsListFail(payload) {
  return {
    type: GET_CLIENTS_LIST_FAIL,
    ...payload
  };
}

export function changeClientsPage(payload) {
  return {
    type: CHANGE_CLIENTS_LIST_PAGE,
    ...payload
  };
}

export function changeClientRegistrationField(payload) {
  return {
    type: CHANGE_CLIENT_REGISTRATION_FIELD,
    ...payload
  };
}

export function resetClientRegistrationForm() {
  return {
    type: RESET_CLIENT_REGISTRATION_FORM
  };
}

export function registerNewClient() {
  return {
    type: REGISTER_NEW_CLIENT
  };
}

export function registerNewClientSuccess() {
  return {
    type: REGISTER_NEW_CLIENT_SUCCESS
  };
}

export function registerNewClientFail(payload) {
  return {
    type: REGISTER_NEW_CLIENT_FAIL,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function getClient(payload) {
  return {
    type: GET_CLIENT,
    ...payload
  };
}

export function getClientSuccess(payload) {
  return {
    type: GET_CLIENT_SUCCESS,
    ...payload
  };
}

export function getClientFail(payload) {
  return {
    type: GET_CLIENT_FAIL,
    ...payload
  };
}

export function changeClientComment(payload) {
  return {
    type: CHANGE_CLIENT_COMMENT,
    ...payload
  };
}

export function updateClient(payload) {
  return {
    type: UPDATE_CLIENT,
    ...payload
  };
}

export function updateClientSuccess(payload) {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    ...payload
  };
}

export function updateClientFail(payload) {
  return {
    type: UPDATE_CLIENT_FAIL,
    ...payload
  };
}

export function getManagersList(payload) {
  return {
    type: GET_MANAGERS_LIST,
    ...payload
  };
}

export function getManagersListSuccess(payload) {
  return {
    type: GET_MANAGERS_LIST_SUCCESS,
    ...payload
  };
}

export function getManagersListFail(payload) {
  return {
    type: GET_MANAGERS_LIST_FAIL,
    ...payload
  };
}

export function changeManagersPage(payload) {
  return {
    type: CHANGE_MANAGERS_PAGE,
    ...payload
  };
}

export function getRequisitesList(payload) {
  return {
    type: GET_REQUISITES_LIST,
    ...payload
  };
}

export function getRequisitesListSuccess(payload) {
  return {
    type: GET_REQUISITES_LIST_SUCCESS,
    ...payload
  };
}

export function getRequisitesListFail(payload) {
  return {
    type: GET_REQUISITES_LIST_FAIL,
    ...payload
  };
}

export function changeRequisitesPage(payload) {
  return {
    type: CHANGE_REQUISITES_PAGE,
    ...payload
  };
}

export function getFinancesList(payload) {
  return {
    type: GET_FINANCES_LIST,
    ...payload
  };
}

export function getFinancesListSuccess(payload) {
  return {
    type: GET_FINANCES_LIST_SUCCESS,
    ...payload
  };
}

export function getFinancesListFail(payload) {
  return {
    type: GET_FINANCES_LIST_FAIL,
    ...payload
  };
}

export function changeFinancesPage(payload) {
  return {
    type: CHANGE_FINANCES_PAGE,
    ...payload
  };
}

export function changeInviteModal(payload) {
  return {
    type: CHANGE_INVITE_MODAL,
    ...payload
  };
}

export function changeInviteField(payload) {
  return {
    type: CHANGE_INVITE_FIELD,
    ...payload
  };
}

export function sendInvite() {
  return {
    type: SEND_INVITE
  };
}

export function sendInviteSuccess() {
  return {
    type: SEND_INVITE_SUCCESS
  };
}

export function sendInviteFail() {
  return {
    type: SEND_INVITE_FAIL
  };
}

export function changeFilterField(payload) {
  return {
    type: CHANGE_FILTER_FIELD,
    ...payload
  };
}

export function changeIsMineFilter(payload) {
  return {
    type: CHANGE_IS_MINE_FILTER,
    ...payload
  };
}

export function changeDateFilter(payload) {
  return {
    type: CHANGE_DATE_FILTER,
    ...payload
  };
}
