import {
  GET_COMPARE_LIST_REQUEST,
  GET_COMPARE_LIST_SUCCESS,
  GET_COMPARE_LIST_FAIL,
  CHANGE_REPORT_SELECTOR,
  DELETE_REPORT_FROM_COMPARE,
  OPEN_ADD_MODAL,
  CLOSE_ADD_MODAL,
  CHANGE_COLUMN_POSITION
} from './constants';

export function getCompareList(payload) {
  return {
    type: GET_COMPARE_LIST_REQUEST,
    ...payload
  };
}

export function getCompareListSuccess(payload) {
  return {
    type: GET_COMPARE_LIST_SUCCESS,
    ...payload
  };
}

export function getCompareListFail(payload) {
  return {
    type: GET_COMPARE_LIST_FAIL,
    ...payload
  };
}

export function deleteReportFromCompare(payload) {
  return {
    type: DELETE_REPORT_FROM_COMPARE,
    ...payload
  };
}

export function openAddModal() {
  return {
    type: OPEN_ADD_MODAL
  };
}

export function closeAddModal() {
  return {
    type: CLOSE_ADD_MODAL
  };
}

export function changeReportSelector(payload) {
  return {
    type: CHANGE_REPORT_SELECTOR,
    ...payload
  };
}

export function changeColumnPosition(payload) {
  return {
    type: CHANGE_COLUMN_POSITION,
    ...payload
  }
}
