import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  & {
    cursor: pointer;

    .telegram-inner {
      fill: #11203a;
      transition: all 0.3s ease-out;
    }
  }

  &:hover {
    & .telegram-inner {
      fill: #0052cc;
    }
  }
`;

const Telegram = () => (
  <Svg
    width="51px"
    height="51px"
    viewBox="0 0 51 51"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1332.000000, -8689.000000)">
        <g transform="translate(0.000000, 8587.000000)">
          <g transform="translate(1134.000000, 102.000000)">
            <g transform="translate(198.000000, 0.000000)">
              <circle fill="#FFFFFF" cx="25.5" cy="25.5" r="25.5" />
              <path
                className="telegram-inner"
                d="M31.975109,18.0547508 L17.3418163,23.9773118 C16.8676242,24.1689337 16.8965112,24.875277 17.3844802,25.0249379 L21.1029107,26.1653444 L22.4908226,30.7390926 C22.6352578,31.2165155 23.2183319,31.361514 23.5556438,31.0039131 L25.4781884,28.9650751 L29.2503932,31.8408018 C29.7121417,32.1928079 30.3694332,31.931251 30.4867591,31.3489257 L32.9852671,18.9429252 C33.1074816,18.3354232 32.5319626,17.8290939 31.975109,18.0547508 Z M30.1401145,20.8073919 L23.3432129,27.0507214 C23.2756616,27.1127304 23.2325532,27.1989835 23.2223317,27.2922302 L22.9605705,29.7082509 C22.9521266,29.7870443 22.8459111,29.7977677 22.8232459,29.7217717 L21.7464254,26.1182549 C21.6975396,25.9536745 21.7615356,25.774641 21.9028599,25.6841917 L29.9294612,20.5131988 C30.1138939,20.3943093 30.3032152,20.6581973 30.1401145,20.8073919 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default Telegram;
