import styled from 'styled-components';

const MainBlock = styled.div`
  margin-top: 32px;
  padding-bottom: 47px;
  border-bottom: 1px solid rgba(9, 30, 66, 0.13);

  &:last-child {
    border-bottom: none;
  }
`;

export default MainBlock;
