import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import SettingsCompanyPage from 'pages/Settings/subpages/Organizations';

const SettingsCompanyView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/settings" />
    <SettingsCompanyPage />
  </Fragment>
);

export default SettingsCompanyView;
