import styled from 'styled-components';

const FlexItem = styled.div`
  align-self: center;
  text-align: ${props => (props.align ? props.align : 'initial')};
  width: calc(50% - 20px);

  @media (max-width: 1020px) {
    display: ${props => (props.hideOnMobile ? 'none' : 'block')};
    margin: 0 auto;
    width: calc(100% - 60px);
  }
`;

export default FlexItem;
