import {
  CHANGE_PHONE,
  CHANGE_CODE,
  SEND_CREATE_REQUEST,
  SEND_CREATE_REQUEST_SUCCESS,
  SEND_CREATE_REQUEST_FAIL,
  SEND_VERIFY_REQUEST,
  SEND_VERIFY_REQUEST_SUCCESS,
  SEND_VERIFY_REQUEST_FAIL,
  USER_READ_REQUEST,
  USER_READ_REQUEST_SUCCESS,
  USER_READ_REQUEST_FAIL,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_REQUEST_SUCCESS,
  USER_UPDATE_REQUEST_FAIL,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_INVITE_CODE_REQUEST_SUCCESS,
  CHECK_INVITE_CODE_REQUEST_FAIL,
  CHECK_ORDER_REQUEST,
  CHECK_ORDER_REQUEST_SUCCESS,
  CHECK_ORDER_REQUEST_FAIL,
  CHANGE_TERMS_ACCEPTED,
} from './constants';

export function changePhone(payload) {
  return {
    type: CHANGE_PHONE,
    ...payload
  };
}

export function changeCode(payload) {
  return {
    type: CHANGE_CODE,
    ...payload
  };
}

export function sendCreate(payload) {
  return {
    type: SEND_CREATE_REQUEST,
    ...payload
  };
}

export function sendCreateSuccess(payload) {
  return {
    type: SEND_CREATE_REQUEST_SUCCESS,
    ...payload
  };
}

export function sendCreateFail(payload) {
  return {
    type: SEND_CREATE_REQUEST_FAIL,
    ...payload
  };
}

export function sendVerify(payload) {
  return {
    type: SEND_VERIFY_REQUEST,
    ...payload
  };
}

export function sendVerifySuccess(payload) {
  return {
    type: SEND_VERIFY_REQUEST_SUCCESS,
    ...payload
  };
}

export function sendVerifyFail(payload) {
  return {
    type: SEND_VERIFY_REQUEST_FAIL,
    ...payload
  };
}

export function userRead() {
  return {
    type: USER_READ_REQUEST
  };
}

export function userReadSuccess(payload) {
  return {
    type: USER_READ_REQUEST_SUCCESS,
    ...payload
  };
}

export function userReadFail() {
  return {
    type: USER_READ_REQUEST_FAIL
  };
}

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    ...payload
  };
}

export function changeSurname(payload) {
  return {
    type: CHANGE_SURNAME,
    ...payload
  };
}

export function changeEmail(payload) {
  return {
    type: CHANGE_EMAIL,
    ...payload
  };
}

export function userUpdate() {
  return {
    type: USER_UPDATE_REQUEST
  };
}

export function userUpdateSuccess() {
  return {
    type: USER_UPDATE_REQUEST_SUCCESS
  };
}

export function userUpdateFail() {
  return {
    type: USER_UPDATE_REQUEST_FAIL
  };
}

export function checkInviteCode(payload) {
  return {
    type: CHECK_INVITE_CODE_REQUEST,
    ...payload
  };
}

export function checkInviteCodeSuccess() {
  return {
    type: CHECK_INVITE_CODE_REQUEST_SUCCESS
  };
}

export function checkInviteCodeFail() {
  return {
    type: CHECK_INVITE_CODE_REQUEST_FAIL
  };
}

export function checkOrder(payload) {
  return {
    type: CHECK_ORDER_REQUEST,
    ...payload
  };
}

export function checkOrderSuccess(payload) {
  return {
    type: CHECK_ORDER_REQUEST_SUCCESS,
    ...payload
  };
}

export function checkOrderFail(payload) {
  return {
    type: CHECK_ORDER_REQUEST_FAIL,
    ...payload
  };
}

export function changeTermsAccepted(payload) {
  return {
    type: CHANGE_TERMS_ACCEPTED,
    ...payload
  };
}
