import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import ClientRequisitesBase from './components/ClientRequisitesBase';

const ClientRequisitesView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <ClientRequisitesBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default ClientRequisitesView;
