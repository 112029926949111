import {
  CHANGE_EMAIL,
  CHANGE_RECOVER_SENT,
  CHANGE_NEW_PASSWORD,
  RECOVER_REQUEST,
  RECOVER_REQUEST_SUCCESS,
  RECOVER_REQUEST_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST_SUCCESS,
  NEW_PASSWORD_REQUEST_FAIL
} from './constants';

export function changeEmail(payload) {
  return {
    type: CHANGE_EMAIL,
    ...payload
  };
}

export function changeRecoverSent(payload) {
  return {
    type: CHANGE_RECOVER_SENT,
    ...payload
  };
}

export function changeNewPassword(payload) {
  return {
    type: CHANGE_NEW_PASSWORD,
    ...payload
  };
}

export function recoverRequest() {
  return {
    type: RECOVER_REQUEST
  };
}

export function recoverRequestSuccess() {
  return {
    type: RECOVER_REQUEST_SUCCESS
  };
}

export function recoverRequestFail() {
  return {
    type: RECOVER_REQUEST_FAIL
  };
}

export function newPasswordRequest(payload) {
  return {
    type: NEW_PASSWORD_REQUEST,
    ...payload
  };
}

export function newPasswordRequestSuccess() {
  return {
    type: NEW_PASSWORD_REQUEST_SUCCESS
  };
}

export function newPasswordRequestFail() {
  return {
    type: NEW_PASSWORD_REQUEST_FAIL
  };
}
