import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ContainerViews from 'navigation/routes/ContainerViews';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';

import injectReducer from 'utils/injectReducer';
import reducer from 'pages/Home/reducer';
import ReportPage from 'pages/Report';

import { staffRead } from 'pages/Home/actions';
import { makeSelectStaffRead, makeSelectStaffLoading } from 'pages/Home/selectors';

class PersonReportBase extends Component {
  render() {
    const personId = parseInt(this.props.match.params.id, 10);
    const { staffRead, staffLoading } = this.props;

    let container = <div />;

    if (!staffLoading && staffRead.id === personId) {
      container = (
        <ContainerViews
          personEmail={staffRead.email}
          personName={staffRead.full_name}
          personId={personId}
        />
      );
    }

    return (
      <Fragment>
        <RootViews />
        {container}
        <SetActiveView id={`root/person/${personId}`} />
        <ReportPage />
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStaffRead: value => dispatch(staffRead(value))
  };
}

const mapStateToProps = createStructuredSelector({
  staffRead: makeSelectStaffRead(),
  staffLoading: makeSelectStaffLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });

export default compose(
  withRouter,
  withReducer,
  withConnect
)(PersonReportBase);
