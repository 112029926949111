import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Form from '@atlaskit/form';
import isEmpty from 'lodash/isEmpty';
import RouterLink from 'components/RouterLink';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeNameCreateFromQuestions,
  changeDescCreateFromQuestions
} from 'pages/ProfileList/actions';
import {
  makeSelectProfileQuestionsName,
  makeSelectProfileQuestionsDesc
} from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateCompareStepOne extends React.Component {
  state = {
    changeFlags: [],
    nameValid: true
  };

  addFlag = () => {
    this.setState({
      changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
    });
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button href="/profile/list" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeName = event => {
    this.setState({
      nameValid: !isEmpty(event.target.value)
    });
    this.props.changeName({
      name: event.target.value
    });
  };

  onChangeDesc = event => {
    this.props.changeDesc({
      description: event.target.value
    });
  };

  handleForm = () => {
    const nameValid = !isEmpty(this.props.name);

    if (nameValid) {
      this.props.history.push('/profile/create/ask/2/1');
    } else {
      window.scrollTo(0, 0);
      this.setState({
        nameValid
      });
    }
  };

  render() {
    const { desc, name } = this.props;

    const breadcrumbs = (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/profile/list"
          onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
          text="Профили"
          key="Profile"
        />
        <BreadcrumbsItem
          href="/profile/create/ask/1"
          onClick={event => this.onBreadcumbsClick(event, '/profile/create/ask/1')}
          text="Создать с помощью опросника"
          key="Create"
        />
      </BreadcrumbsStateless>
    );

    const bottomBar = (
      <PageHeaderBottom>
        <h4>С помощью опросника</h4>
      </PageHeaderBottom>
    );

    const content = (
      <Grid layout="fixed">
        <GridColumn medium={12}>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={this.getPageHeaderButtonsDrawer()}
            bottomBar={bottomBar}
          >
            Добавить профиль
          </PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <div>
            <Form>
              <FieldGroup marginTop="8px">
                <Field width="100%">
                  <h4>Шаг 1 из 3</h4>
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.nameValid}
                    required
                    label="Название профиля"
                    onChange={this.onChangeName}
                    placeholder=""
                    shouldFitContainer
                    value={name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    label="Описание"
                    minimumRows={4}
                    onChange={this.onChangeDesc}
                    placeholder=""
                    shouldFitContainer
                    value={desc}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </div>
          <ButtonWrapper>
            <Button appearance="primary" onClick={this.handleForm}>
              Далее
            </Button>
          </ButtonWrapper>
        </GridColumn>
      </Grid>
    );

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        <Container>{content}</Container>
      </Fragment>
    );
  }
}

ProfileCreateCompareStepOne.propTypes = {
  changeName: PropTypes.func,
  changeDesc: PropTypes.func,
  desc: PropTypes.string,
  name: PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return {
    changeName: value => dispatch(changeNameCreateFromQuestions(value)),
    changeDesc: value => dispatch(changeDescCreateFromQuestions(value))
  };
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectProfileQuestionsName(),
  desc: makeSelectProfileQuestionsDesc()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateCompareStepOne);
