import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Spinner from 'components/Spinner';

import { changeAnswer, checkInviteCodeRequest, sendQuizResultRequest } from './actions';

import {
  makeSelectAnswers,
  makeSelectLoading,
  makeSelectQuestionsLoading,
  makeSelectQuizResultLoading
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import H4 from './styled/H4';
import MainBlock from './styled/MainBlock';
import P from './styled/P';
import Title from './styled/Title';

class ChiefQuiz extends React.Component {
  constructor(props) {
    super(props);

    const {
      checkInviteCode,
      match: {
        params: { invitationCode }
      }
    } = this.props;

    checkInviteCode({
      inviteCode: invitationCode
    });
  }

  onAnswerChange = event => {
    const { name, checked, value } = event.target;
    const questionId = parseInt(name.replace('question-', ''), 10);
    const answerId = parseInt(value, 10);

    this.props.changeAnswer({
      questionId,
      answerId,
      value: checked
    });
  };

  onNextQuestion = () => {
    const {
      match: {
        params: { invitationCode, questionNumber }
      }
    } = this.props;
    const number = isUndefined(questionNumber) ? 1 : parseInt(questionNumber, 10);
    this.props.history.push(`/invite/p/${invitationCode}/${number + 1}`);
  };

  onPrevQuestion = () => {
    const {
      match: {
        params: { invitationCode, questionNumber }
      }
    } = this.props;
    const number = isUndefined(questionNumber) ? 1 : parseInt(questionNumber, 10);
    this.props.history.push(`/invite/p/${invitationCode}/${number - 1}`);
  };

  handleForm = () => {
    this.props.sendQuizResult();
  };

  renderButtons() {
    const {
      answers,
      match: {
        params: { questionNumber }
      },
      quizResultLoading
    } = this.props;
    const number = isUndefined(questionNumber) ? 1 : parseInt(questionNumber, 10);

    if (number > 1 && number < answers.size) {
      return (
        <ButtonWrapper>
          <div>
            <Button appearance="default" onClick={this.onPrevQuestion}>
              Предыдущий вопрос
            </Button>
          </div>
          <div>
            <Button appearance="primary" onClick={this.onNextQuestion}>
              Следующий вопрос
            </Button>
          </div>
        </ButtonWrapper>
      );
    } else if (number === 1) {
      return (
        <ButtonWrapper>
          <div>
            <Button appearance="primary" onClick={this.onNextQuestion}>
              Следующий вопрос
            </Button>
          </div>
        </ButtonWrapper>
      );
    }

    return (
      <ButtonWrapper>
        <div>
          <Button appearance="primary" isLoading={quizResultLoading} onClick={this.handleForm}>
            Отправить
          </Button>
        </div>
      </ButtonWrapper>
    );
  }

  renderQuestions() {
    const {
      answers,
      match: {
        params: { questionNumber }
      }
    } = this.props;

    const number = isUndefined(questionNumber) ? 1 : parseInt(questionNumber, 10);
    const question = answers.get(number - 1);

    return (
      <MainBlock key={question.get('id')}>
        <H4>
          Вопрос {number} из {answers.size}
        </H4>
        <p>{question.get('text')}</p>
        <Block>
          {question.get('answers').map(answer => (
            <Checkbox
              defaultChecked={answer.get('value')}
              key={answer.get('id')}
              label={answer.get('text')}
              onChange={this.onAnswerChange}
              value={answer.get('id')}
              id={question.get('id')}
              name={`question-${question.get('id')}`}
            />
          ))}
        </Block>
      </MainBlock>
    );
  }

  render() {
    const {
      match: {
        params: { questionNumber }
      },
      questionsLoading,
      loading
    } = this.props;

    let content = <Spinner />;

    if (!questionsLoading && !loading && questionNumber !== 'success') {
      content = (
        <div>
          {this.renderQuestions()}
          {this.renderButtons()}
        </div>
      );
    } else if (questionNumber === 'success') {
      content = (
        <Fragment>
          <Title>Спасибо!</Title>

          <P>
            Опрос успешно завершен.
            <br />
          </P>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Создать профиль с помощью опроса</title>
        </Helmet>

        <Flex>
          <FlexItem>{content}</FlexItem>
        </Flex>
      </Fragment>
    );
  }
}

ChiefQuiz.propTypes = {
  answers: PropTypes.object,
  changeAnswer: PropTypes.func,
  checkInviteCode: PropTypes.func,
  loading: PropTypes.bool,
  questionsLoading: PropTypes.bool,
  quizResultLoading: PropTypes.bool,
  sendQuizResult: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeAnswer: value => dispatch(changeAnswer(value)),
    checkInviteCode: value => dispatch(checkInviteCodeRequest(value)),
    sendQuizResult: () => dispatch(sendQuizResultRequest())
  };
}

const mapStateToProps = createStructuredSelector({
  answers: makeSelectAnswers(),
  questionsLoading: makeSelectQuestionsLoading(),
  quizResultLoading: makeSelectQuizResultLoading(),
  loading: makeSelectLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'chiefQuiz', reducer });
const withSaga = injectSaga({ key: 'chiefQuiz', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ChiefQuiz);
