import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import SettingsUsersPage from 'pages/Settings/subpages/Users';

const SettingsUsersView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/settings" />
    <SettingsUsersPage />
  </Fragment>
);

export default SettingsUsersView;
