import { call, select, put, takeLeading } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { push } from 'react-router-redux';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { apiRequest } from 'utils/request';
import { pageHostUrl } from 'utils/serverConfig';

import { getUserInfo } from 'pages/Home/actions';

import { makeSelectCompany } from 'pages/Home/selectors';

import {
  ACTIVATE_PLAN_REQUEST,
  PROLONG_PLAN_REQUEST,
  GET_BILLING_PLANS_LIST_REQUEST,
  CHECK_PROLONG_ORDER_REQUEST,
  GET_USER_INFO_REQUEST,
  GET_MANAGERS_LIST_REQUEST,
  GET_MANAGERS_FILTERED_LIST_REQUEST,
  SEND_INVITE_REQUEST,
  GET_INVITES_LIST_REQUEST,
  DELETE_INVITE_REQUEST,
  CHANGE_NAME_FILTER,
  CHANGE_INVITES_NAME_FILTER,
  CHANGE_MANAGERS_PAGE,
  CHANGE_INVITES_PAGE,
  CHANGE_MANAGERS_SORT,
  CHANGE_INVITES_SORT,
  SEND_TO_PAYMENT_PAGE,
  GET_ORGANIZATIONS_LIST_REQUEST,
  DELETE_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_READ_REQUEST,
  CREATE_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_REQUEST,
  SEND_INVITE_STAFF_REQUEST,
  SEND_INVITE_STAFF_BY_FILE_REQUEST,
  CHANGE_INVITE_STAFF_TEXT
} from './constants';

import {
  activatePlanSuccess,
  activatePlanFail,
  billingPlansList,
  billingPlansListSuccess,
  billingPlansListFail,
  getUserInfoSuccess,
  getUserInfoFail,
  getManagersFilteredList,
  getManagersListSuccess,
  getManagersListFail,
  sendInviteSuccess,
  sendInviteFail,
  getInvitesList,
  getInvitesListSuccess,
  getInvitesListFail,
  deleteInviteSuccess,
  deleteInviteFail,
  loadingFilterFinish,
  prolongPlanSuccess,
  prolongPlanFail,
  checkOrderSuccess,
  checkOrderFail,
  changePaymentWasSend,
  getOrganizationsList,
  getOrganizationsListSuccess,
  getOrganizationsListFail,
  deleteOrganizationSuccess,
  deleteOrganizationFail,
  getOrganizationRead,
  getOrganizationReadSuccess,
  getOrganizationReadFail,
  createOrganizationSuccess,
  createOrganizationFail,
  editOrganizationSuccess,
  editOrganizationFail,
  changeDeleteOrganizationModals,
  sendInviteStaffSuccess,
  sendInviteStaffFail,
  sendInviteStaffByFileSuccess,
  sendInviteStaffByFileFail
} from './actions';

import {
  makeSelectCheckProlongOrderId,
  makeSelectInvite,
  makeSelectInviteId,
  makeSelectInvitesPage,
  makeSelectNameFilter,
  makeSelectManagersPage,
  makeSelectManagersSort,
  makeSelectInvitesFilter,
  makeSelectInvitesSort,
  makeSelectActivatePlanId,
  makeSelectPlansList,
  makeSelectPayment,
  makeSelectOrganizationsPage,
  makeSelectOrganizationId,
  makeSelectOrganization,
  makeSelectInviteStaff
} from './selectors';

function* userInfoSaga() {
  const url = 'api/managers/auth/user/';

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getUserInfoSuccess({
        user: fromJS(request.data)
      })
    );
  } catch (e) {
    yield put(getUserInfoFail());
  }
}

function* managersFilteredSaga() {
  yield put(getManagersFilteredList());
}

function* managersListSaga() {
  const url = 'api/managers/managers/';

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getManagersListSuccess({
        managersCount: request.data.count,
        managersList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(getManagersListFail());
  }
}

function* managersFilteredListSaga() {
  const nameFilter = yield select(makeSelectNameFilter());
  const page = yield select(makeSelectManagersPage());
  const sort = yield select(makeSelectManagersSort());
  const offset = page * 25 - 25;
  const params = {};

  if (nameFilter !== '') {
    params.full_name = nameFilter;
  }

  if (page > 1) {
    params.offset = offset;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/managers/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getManagersListSuccess({
        managersCount: request.data.count,
        managersList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(getManagersListFail());
  } finally {
    yield put(loadingFilterFinish());
  }
}

function* sendInviteSaga() {
  const url = 'api/managers/invitations/';
  const inviteMap = yield select(makeSelectInvite());
  const invite = inviteMap.toJS();
  const options = {
    method: 'post',
    data: {
      ...invite,
      full_name: `${invite.name} ${invite.full_name}`,
      type: invite.role
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendInviteSuccess());
  } catch (e) {
    yield put(sendInviteFail());
  } finally {
    yield put(getInvitesList());
  }
}

function* invitesFilteredSaga() {
  yield put(getInvitesList());
}

function* getInvitesListSaga() {
  const page = yield select(makeSelectInvitesPage());
  const nameFilter = yield select(makeSelectInvitesFilter());
  const sort = yield select(makeSelectInvitesSort());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  if (nameFilter !== '') {
    params.search = nameFilter;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/invitations/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getInvitesListSuccess({
        invitesCount: request.data.count,
        invitesList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(getInvitesListFail());
  }
}

function* deleteInviteSaga() {
  const inviteId = yield select(makeSelectInviteId());
  const url = `api/managers/invitations/${inviteId}/`;
  const options = {
    method: 'delete'
  };

  try {
    yield call(apiRequest, url, options);

    yield put(deleteInviteSuccess());
    yield put(getInvitesList());
  } catch (e) {
    yield put(deleteInviteFail());
  }
}

function* billingPlansListSaga() {
  const url = 'api/managers/billing_plans/';

  try {
    const request = yield call(apiRequest, url);
    const plansList = request.data;

    yield put(
      billingPlansListSuccess({
        plansList
      })
    );
  } catch (e) {
    yield put(billingPlansListFail());
  }
}

function* checkProlongOrderSaga() {
  const orderId = yield select(makeSelectCheckProlongOrderId());
  const url = `api/managers/orders/${orderId}/status/`;

  try {
    const request = yield call(apiRequest, url);

    if (request.data.is_paid) {
      yield put(checkOrderSuccess());
    } else {
      yield put(checkOrderFail());
    }
  } catch (e) {
    yield put(checkOrderFail());
  } finally {
    yield put(getUserInfo());
    yield put(billingPlansList());
  }
}

function* activatePlanSaga() {
  const url = 'api/managers/orders/create_for_billing_plan/';
  const plansList = yield select(makeSelectPlansList());
  const planId = yield select(makeSelectActivatePlanId());
  const payment = yield select(makeSelectPayment());

  const options = {
    method: 'post',
    data: {
      return_url: `${pageHostUrl}/settings/billing`,
      amount: plansList.find(plan => plan.id === planId).price_per_year,
      billing_plan: planId
    }
  };

  if (!isNull(payment.organization)) {
    options.data.organization = payment.organization;
  }

  if (payment.email !== '') {
    options.data.invoice_email = payment.email;
  }

  try {
    const request = yield call(apiRequest, url, options);
    let isPaidFlag = [];
    if (options.data.amount === 0) {
      if (request.data.is_paid) {
        isPaidFlag = [1];
      } else {
        isPaidFlag = [-1];
      }
    } else {
      if (!isUndefined(options.data.invoice_email) && options.data.invoice_email !== '') {
        yield put(
          changePaymentWasSend({
            wasSend: true
          })
        );
        yield put(push('/settings/billing'));
      } else {
        window.location.href = request.data.formUrl;
      }
    }

    yield put(
      activatePlanSuccess({
        isPaidFlag
      })
    );
  } catch (e) {
    yield put(activatePlanFail());
  } finally {
    yield put(getUserInfo());
    yield put(billingPlansList());
  }
}

function* prolongPlanSaga() {
  const url = 'api/managers/orders/create_for_billing_plan/';
  const company = yield select(makeSelectCompany());
  const payment = yield select(makeSelectPayment());

  const options = {
    method: 'post',
    data: {
      return_url: `${pageHostUrl}/settings/billing`,
      amount: company.billing_plan.price_per_year,
      billing_plan: company.billing_plan.id
    }
  };

  if (!isNull(payment.organization)) {
    options.data.organization = payment.organization;
  }

  if (payment.email !== '') {
    options.data.invoice_email = payment.email;
  }

  try {
    const request = yield call(apiRequest, url, options);
    if (options.data.invoice_email !== '') {
      yield put(
        changePaymentWasSend({
          wasSend: true
        })
      );
      yield put(push('/settings/billing'));
    } else {
      window.location.href = request.data.formUrl;
    }

    yield put(prolongPlanSuccess());
  } catch (e) {
    yield put(prolongPlanFail());
  }
}

function* sendToPaymentPageSaga() {
  const payment = yield select(makeSelectPayment());

  yield put(
    push({
      pathname: '/settings/payment',
      state: {
        cost: payment.cost,
        service: payment.service,
        type: payment.type
      }
    })
  );
}

function* getOrganizationsListSaga() {
  const page = yield select(makeSelectOrganizationsPage());
  const offset = page * 25 - 25;
  const params = {};

  if (page > 1) {
    params.offset = offset;
  }

  const url = `api/managers/organizations/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getOrganizationsListSuccess({
        organizationsCount: request.data.count,
        organizationsList: request.data.results
      })
    );
  } catch (e) {
    yield put(getOrganizationsListFail());
  }
}

function* deleteOrganizationSaga() {
  const organizationId = yield select(makeSelectOrganizationId());
  const organizationsPage = yield select(makeSelectOrganizationsPage());
  const url = `api/managers/organizations/${organizationId}/`;
  const options = {
    method: 'delete'
  };

  try {
    yield call(apiRequest, url, options);

    yield put(deleteOrganizationSuccess());
    yield put(
      getOrganizationsList({
        organizationsPage
      })
    );
  } catch (e) {
    yield put(deleteOrganizationFail());
  } finally {
    yield put(
      changeDeleteOrganizationModals({
        modals: []
      })
    );
  }
}

function* getOrganizationReadSaga() {
  const organizationId = yield select(makeSelectOrganizationId());
  const url = `api/managers/organizations/${organizationId}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(getOrganizationReadSuccess(request.data));
  } catch (e) {
    yield put(getOrganizationReadFail());
  }
}

function* createOrganizationSaga() {
  const organization = yield select(makeSelectOrganization());
  const url = 'api/managers/organizations/';

  const options = {
    method: 'post',
    data: {
      name: organization.newName,
      inn: organization.inn,
      kpp: organization.kpp,
      ogrn: organization.ogrn,
      index: organization.index,
      address: organization.address,
      account_number: organization.accountNumber,
      bank_name: organization.bankName,
      bank_bik: organization.bankBik,
      correspondent_account_number: organization.correspondentAccountNumber
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(createOrganizationSuccess());
  } catch (e) {
    yield put(createOrganizationFail());
  } finally {
    const organizationsPage = yield select(makeSelectOrganizationsPage());

    yield put(push('/settings/organizations/list'));
    yield put(
      getOrganizationsList({
        organizationsPage
      })
    );
  }
}

function* editOrganizationSaga() {
  const organizationId = yield select(makeSelectOrganizationId());
  const organization = yield select(makeSelectOrganization());
  const url = `api/managers/organizations/${organizationId}/`;

  const options = {
    method: 'put',
    data: {
      name: organization.newName,
      inn: organization.inn,
      kpp: organization.kpp,
      ogrn: organization.ogrn,
      index: organization.index,
      address: organization.address,
      account_number: organization.accountNumber,
      bank_name: organization.bankName,
      bank_bik: organization.bankBik,
      correspondent_account_number: organization.correspondentAccountNumber
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(editOrganizationSuccess());
    yield put(
      getOrganizationRead({
        organizationId,
        withoutLoading: true
      })
    );
  } catch (e) {
    yield put(editOrganizationFail());
  }
}

function* sendInviteStaffSaga() {
  const invite = yield select(makeSelectInviteStaff());
  const url = 'api/managers/invitations/';
  const options = {
    method: 'post',
    data: {
      ...invite,
      full_name: `${invite.name} ${invite.full_name}`
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendInviteStaffSuccess());
  } catch (e) {
    yield put(sendInviteStaffFail());
  }
}

function* sendInviteStaffByFileSaga(payload) {
  const invite = yield select(makeSelectInviteStaff());
  const url = 'api/managers/invitations/bulk_create_staff/';

  const formData = new FormData();
  const file = payload.files[0];
  formData.append('xlsx_file', file);
  formData.append('comment', invite.comment);
  formData.append('welcome_text', invite.welcome_text);

  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  };

  try {
    const request = yield call(apiRequest, url, options);

    yield put(
      sendInviteStaffByFileSuccess({
        count: request.data.count
      })
    );
  } catch (e) {
    yield put(sendInviteStaffByFileFail());
  }
}

// eslint-disable-next-line
function* changeInviteStaffTextSaga(invite) {
  localStorage.setItem('754a618cb45ba8931da2b13d83de2ccf', invite.text);
}

export default function* settingsSagas() {
  yield takeLeading(GET_USER_INFO_REQUEST, userInfoSaga);
  yield takeLeading(GET_MANAGERS_LIST_REQUEST, managersListSaga);
  yield takeLeading(SEND_INVITE_REQUEST, sendInviteSaga);
  yield takeLeading(GET_INVITES_LIST_REQUEST, getInvitesListSaga);
  yield takeLeading(DELETE_INVITE_REQUEST, deleteInviteSaga);
  yield takeLeading(CHANGE_INVITES_PAGE, invitesFilteredSaga);
  yield takeLeading(CHANGE_INVITES_SORT, invitesFilteredSaga);
  yield takeLeading(CHANGE_INVITES_NAME_FILTER, invitesFilteredSaga);
  yield takeLeading(CHANGE_NAME_FILTER, managersFilteredSaga);
  yield takeLeading(CHANGE_MANAGERS_PAGE, managersFilteredSaga);
  yield takeLeading(CHANGE_MANAGERS_SORT, managersFilteredSaga);
  yield takeLeading(GET_MANAGERS_FILTERED_LIST_REQUEST, managersFilteredListSaga);
  yield takeLeading(GET_BILLING_PLANS_LIST_REQUEST, billingPlansListSaga);
  yield takeLeading(PROLONG_PLAN_REQUEST, prolongPlanSaga);
  yield takeLeading(CHECK_PROLONG_ORDER_REQUEST, checkProlongOrderSaga);
  yield takeLeading(ACTIVATE_PLAN_REQUEST, activatePlanSaga);
  yield takeLeading(SEND_TO_PAYMENT_PAGE, sendToPaymentPageSaga);
  yield takeLeading(GET_ORGANIZATIONS_LIST_REQUEST, getOrganizationsListSaga);
  yield takeLeading(DELETE_ORGANIZATION_REQUEST, deleteOrganizationSaga);
  yield takeLeading(GET_ORGANIZATION_READ_REQUEST, getOrganizationReadSaga);
  yield takeLeading(CREATE_ORGANIZATION_REQUEST, createOrganizationSaga);
  yield takeLeading(EDIT_ORGANIZATION_REQUEST, editOrganizationSaga);
  yield takeLeading(SEND_INVITE_STAFF_REQUEST, sendInviteStaffSaga);
  yield takeLeading(SEND_INVITE_STAFF_BY_FILE_REQUEST, sendInviteStaffByFileSaga);
  yield takeLeading(CHANGE_INVITE_STAFF_TEXT, changeInviteStaffTextSaga);
}
