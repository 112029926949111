import { createSelector } from 'reselect';

const selectGlobal = state => state.get('test');

const makeSelectQuestionsList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('questionsList')
  );

const makeSelectAnswersCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('answersCount')
  );

const makeSelectAnswersList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('answersList')
  );

const makeSelectAnswersTime = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('answersTime')
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectQuestionnaireId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('questionnaireId')
  );

const makeSelectMaxQuestionNumber = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('maxQuestionNumber')
  );

const makeSelectInvitationCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitationCode')
  );

export {
  makeSelectLoading,
  makeSelectQuestionsList,
  makeSelectAnswersCount,
  makeSelectAnswersList,
  makeSelectAnswersTime,
  makeSelectQuestionnaireId,
  makeSelectMaxQuestionNumber,
  makeSelectInvitationCode
};
