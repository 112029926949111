import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import 'moment/locale/ru';
import Button from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { RadioGroup } from '@atlaskit/radio';
import Form from '@atlaskit/form';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import Select from '@atlaskit/select';
import Modal from 'components/Modal';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isEmail } from 'utils/validators';

import { addsReports } from 'pages/Home/actions';
import {
  makeSelectAddsTotal,
  makeSelectAddsReportsCount,
  makeSelectAddsReturnUrl
} from 'pages/Home/selectors';

import {
  activatePlan,
  billingPlansList,
  changePaymentEmail,
  changePaymentLoading,
  changePaymentMethod,
  changePaymentOrganization,
  changePaymentPopup,
  changeVisaPopup,
  getOrganizationsList,
  prolongPlan,
  sendPayment
} from '../actions';
import { makeSelectOrganizationsList, makeSelectPayment } from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import Container from '../styled/Container';
import Field from '../styled/Field';
import FieldGroup from '../styled/FieldGroup';

const H3 = styled.h3`
  font-weight: 400;
  letter-spacing: 0.34px;
  line-height: 24px;
  text-align: ${props => (props.align ? props.align : 'inherit')};
`;

const TextRight = styled.div`
  font-weight: ${props => (props.bold ? '600' : '400')};
  position: relative;
  text-align: right;
  width: 100%;
`;

const HR = styled.div`
  background-color: #d8d8d8;
  height: 1px;
  margin: 10px auto;
  width: ${props => (props.small ? '100%' : 'calc(100% - 32px)')};
`;

const Main = styled.div`
  margin-top: 30px;
`;

const PaymentMethod = styled.div`
  margin-top: 30px;
`;

const Label = styled.label`
  color: #6b778c;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 9px;
`;

const SafetyWrapper = styled.div`
  margin-top: 30px;
`;

const SafetyTitle = styled.div`
  color: #172b4d;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 20px;
`;

const SafetyText = styled.div`
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  margin-top: 37px;
`;

const Line = styled.div`
  margin: 10px 0;
`;

const P = styled.p`
  font-weight: ${props => (props.bold ? 700 : 400)};
`;

class Payment extends React.Component {
  state = {
    emailChanged: false
  };

  componentDidMount() {
    const { cost, service, type } = this.props.location.state;

    const { addsReportsCount, addsReturnUrl, addsTotal } = this.props;

    if (type !== 'addsReports') {
      if (
        this.props.payment.service === '' ||
        this.props.payment.cost === 0 ||
        this.props.payment.type === '' ||
        isUndefined(cost) ||
        isUndefined(service) ||
        isUndefined(type)
      ) {
        this.props.history.push('/settings/billing');
      }
    } else {
      if (
        addsReportsCount === 0 ||
        addsReturnUrl === '' ||
        addsTotal === 0 ||
        isUndefined(cost) ||
        isUndefined(service) ||
        isUndefined(type)
      ) {
        this.props.history.push('/settings/billing');
      }
    }

    this.props.getOrganizationsList();
    this.props.billingPlansList();
  }

  onChangePaymentMethod = event => {
    const method = event.currentTarget.value === 'visa' ? 1 : 2;

    this.props.changePaymentMethod({
      method
    });
  };

  onContinueClick = () => {
    const { method } = this.props.payment;
    const popup = true;

    if (method === 1) {
      this.props.changeVisaPopup({
        popup
      });
    } else {
      this.props.changePaymentPopup({
        popup
      });
    }
  };

  onCloseVisaPopup = () => {
    this.props.changeVisaPopup({
      popup: false
    });
  };

  onClosePaymentPopup = () => {
    this.props.changePaymentPopup({
      popup: false
    });
  };

  onAcceptVisaPopup = () => {
    const paymentType = this.props.location.state.type;

    this.props.changePaymentEmail({
      email: ''
    });

    if (paymentType === 'activatePlan') {
      this.props.activatePlan();
    } else if (paymentType === 'prolongPlan') {
      this.props.prolongPlan();
    } else if (paymentType === 'addsReports') {
      this.props.changePaymentLoading({
        loading: true
      });
      this.props.addsReports();
    }
  };

  onAcceptPaymentPopup = () => {
    const paymentType = this.props.location.state.type;

    if (paymentType === 'activatePlan') {
      this.props.activatePlan();
    } else if (paymentType === 'prolongPlan') {
      this.props.prolongPlan();
    } else if (paymentType === 'addsReports') {
      this.props.changePaymentLoading({
        loading: true
      });
      this.props.addsReports();
    }
  };

  onChangeEmail = event => {
    if (!this.state.emailChanged) {
      this.setState({
        emailChanged: true
      });
    }

    this.props.changePaymentEmail({
      email: event.target.value
    });
  };

  onChangeOrganization = result => {
    this.props.changePaymentOrganization({
      organization: result.value
    });
  };

  render() {
    const { cost, service } = this.props.location.state;

    const { organizationsList } = this.props;

    const {
      email,
      loading,
      method,
      organization,
      showPaymentPopup,
      showVisaPopup
    } = this.props.payment;

    const visaPopup = showVisaPopup ? [1] : [];
    const paymentPopup = showPaymentPopup ? [1] : [];

    const isInvalidEmail = !isEmail(email) && this.state.emailChanged;

    const options = [
      // {
      //   isChecked: method === 1,
      //   label: 'Банковская карта (Visa, MasterCard или Мир)',
      //   name: 'paymentMethod',
      //   value: 'visa'
      // },
      {
        isChecked: method === 2,
        label: 'Перевод на расчётный счёт (Безналичный платёж через банк)',
        name: 'paymentMethod',
        value: 'payment'
      }
    ];

    const organizationsSelectOptions = organizationsList.map(organization => {
      return {
        label: organization.name,
        value: organization.id
      };
    });

    let defaultOrganization = organizationsSelectOptions[0];
    if (!isNull(organization)) {
      const currentOrganization = organizationsList.filter(item => item.id === organization);

      if (!isUndefined(currentOrganization[0])) {
        defaultOrganization = {
          label: currentOrganization[0].name,
          value: currentOrganization[0].id
        };
      }
    }

    if (!isUndefined(defaultOrganization) && defaultOrganization.value !== organization) {
      this.onChangeOrganization({
        value: defaultOrganization.value
      });
    }

    const content = (
      <Fragment>
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader>Оплата услуги</PageHeader>
          </GridColumn>
        </Grid>
        <Main>
          <Grid>
            <GridColumn medium={6}>
              <H3>Услуга</H3>
            </GridColumn>
            <GridColumn medium={6}>
              <H3 align="right">{service}</H3>
            </GridColumn>
          </Grid>
          <HR />
          <Grid>
            <GridColumn medium={6}>
              <H3>Стоимость</H3>
            </GridColumn>
            <GridColumn medium={6}>
              <H3 align="right">{cost.toLocaleString()} руб.</H3>
            </GridColumn>
          </Grid>
          <HR />
          <PaymentMethod>
            <Grid>
              <GridColumn medium={12}>
                <RadioGroup options={options} onChange={this.onChangePaymentMethod} />
              </GridColumn>
            </Grid>
          </PaymentMethod>
          <PaymentMethod>
            <Grid>
              <GridColumn medium={12}>
                <Label>
                  Выберете организацию (или{' '}
                  <Link to="/settings/organizations/create">добавьте новую</Link>):
                </Label>
                <Select
                  className="single-select"
                  classNamePrefix="react-select"
                  defaultValue={defaultOrganization}
                  onChange={this.onChangeOrganization}
                  options={organizationsSelectOptions}
                />
              </GridColumn>
            </Grid>
          </PaymentMethod>
          <SafetyWrapper>
            <Grid>
              <GridColumn medium={5}>
                
              </GridColumn>
              <GridColumn medium={1} />
              <GridColumn medium={6}>
                <div>
                  <SafetyTitle>Ваш заказ</SafetyTitle>
                  <SafetyText>
                    <Line>
                      <Grid>
                        <GridColumn medium={3}>{service}</GridColumn>
                        <GridColumn medium={3}>
                          <TextRight bold>{cost.toLocaleString()} руб.</TextRight>
                        </GridColumn>
                      </Grid>
                    </Line>
                    <Line />
                    <HR small />
                    <Line>
                      <Grid>
                        <GridColumn medium={3}>Итого</GridColumn>
                        <GridColumn medium={3}>
                          <TextRight bold>{cost.toLocaleString()} руб.</TextRight>
                        </GridColumn>
                      </Grid>
                    </Line>
                  </SafetyText>
                  <ButtonWrapper>
                    <Button appearance="primary" onClick={this.onContinueClick} shouldFitContainer>
                      Продолжить оплату
                    </Button>
                  </ButtonWrapper>
                </div>
              </GridColumn>
            </Grid>
          </SafetyWrapper>
        </Main>
      </Fragment>
    );

    return (
      <Fragment>
        <Helmet>
          <title>Тарифы</title>
        </Helmet>
        <Container>
          {content}

          {visaPopup.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Оплата банковской картой"
              onClose={this.onCloseVisaPopup}
              actions={[
                {
                  isLoading: loading,
                  onClick: this.onAcceptVisaPopup,
                  text: 'Продолжить'
                },
                {
                  text: 'Отмена',
                  onClick: this.onCloseVisaPopup
                }
              ]}
              width="small"
            >
              <p>
                После нажатия на кнопку «Продолжить» вы будете перенаправлены на страницу оплаты для
                ввода данных вашей банковской карты.
              </p>
            </Modal>
          ))}

          {paymentPopup.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Перевод на расчётный счёт"
              onClose={this.onClosePaymentPopup}
              actions={[
                {
                  isDisabled: isInvalidEmail || !this.state.emailChanged,
                  isLoading: loading,
                  onClick: this.onAcceptPaymentPopup,
                  text: 'Продолжить'
                },
                {
                  text: 'Отмена',
                  onClick: this.onClosePaymentPopup
                }
              ]}
              width="small"
            >
              <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
                <FieldGroup marginTop="0">
                  <Field width="100%">
                    <TextField
                      isInvalid={isInvalidEmail}
                      label="Введите email, на который будет отправлен счёт"
                      name="email"
                      onChange={this.onChangeEmail}
                      placeholder=""
                      shouldFitContainer
                      value={email}
                    />
                  </Field>
                </FieldGroup>
              </Form>
              <P bold>
                Проследите, чтобы оператор банка абсолютно идентично заполнил назначение платежа.
              </P>
              <p>Мы активируем ваш аккаунт в течение суток после поступления денег.</p>
            </Modal>
          ))}
        </Container>
      </Fragment>
    );
  }
}

Payment.propTypes = {
  activatePlan: PropTypes.func,
  addsReports: PropTypes.func,
  addsReportsCount: PropTypes.any,
  addsReturnUrl: PropTypes.string,
  addsTotal: PropTypes.number,
  billingPlansList: PropTypes.func,
  changePaymentEmail: PropTypes.func,
  changePaymentLoading: PropTypes.func,
  changePaymentMethod: PropTypes.func,
  changePaymentOrganization: PropTypes.func,
  changePaymentPopup: PropTypes.func,
  changeVisaPopup: PropTypes.func,
  getOrganizationsList: PropTypes.func,
  payment: PropTypes.shape({
    cost: PropTypes.number,
    email: PropTypes.string,
    loading: PropTypes.bool,
    method: PropTypes.number,
    service: PropTypes.string,
    showPaymentPopup: PropTypes.bool,
    showVisaPopup: PropTypes.bool,
    type: PropTypes.string
  }),
  organizationsList: PropTypes.array,
  prolongPlan: PropTypes.func,
  sendPayment: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    activatePlan: () => dispatch(activatePlan()),
    addsReports: () => dispatch(addsReports()),
    billingPlansList: () => dispatch(billingPlansList()),
    changePaymentEmail: value => dispatch(changePaymentEmail(value)),
    changePaymentLoading: value => dispatch(changePaymentLoading(value)),
    changePaymentMethod: value => dispatch(changePaymentMethod(value)),
    changePaymentOrganization: value => dispatch(changePaymentOrganization(value)),
    changePaymentPopup: value => dispatch(changePaymentPopup(value)),
    changeVisaPopup: value => dispatch(changeVisaPopup(value)),
    getOrganizationsList: () => dispatch(getOrganizationsList()),
    prolongPlan: () => dispatch(prolongPlan()),
    sendPayment: () => dispatch(sendPayment())
  };
}

const mapStateToProps = createStructuredSelector({
  payment: makeSelectPayment(),
  addsTotal: makeSelectAddsTotal(),
  addsReportsCount: makeSelectAddsReportsCount(),
  addsReturnUrl: makeSelectAddsReturnUrl(),
  organizationsList: makeSelectOrganizationsList()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Payment);
