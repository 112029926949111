import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isString from 'lodash/isString';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/IndividualProfile/Video/reducer';
import saga from 'pages/IndividualProfile/Video/saga';

import profileReducer from 'pages/IndividualProfile/Profile/reducer';
import profileSaga from 'pages/IndividualProfile/Profile/saga';

import QueuesIcon from '@atlaskit/icon/glyph/queues';
import IndividualName from 'navigation/components/IndividualName';
import LinkItem from 'navigation/components/LinkItem';
import ViewRegistrar from 'navigation/components/ViewRegistrar';
import VideoIcon from 'navigation/routes/RootViews/styled/VideoIcon';

import { getEducationsList } from 'pages/IndividualProfile/Video/actions';

import { userRead } from 'pages/IndividualProfile/Profile/actions';
import ReportDropdown from "./components/ReportDropdown";

class StaffRootViews extends Component {
  componentDidMount() {
    if (!isString(localStorage.getItem('staffKey'))) {
      this.props.history.push('/individual/login');
    }

    const { getEducationsList, sections, userRead } = this.props;

    userRead();

    if (sections.length === 0) {
      getEducationsList();
    }
  }

  render() {
    const { loading, sections, user, userReadLoading } = this.props;

    if (loading || userReadLoading) {
      return <div />;
    }

    const rootIndex = [
      {
        id: 'individual/index:header',
        items: [
          {
            type: 'InlineComponent',
            component: IndividualName,
            id: 'company-name',
            text: `${user.name} ${user.surname}`,
            desc: `${user.email}`
          }
        ],
        type: 'Section'
      },
      {
        id: 'individual/index:menu',
        items: [
          {
            type: 'InlineComponent',
            component: ReportDropdown,
            id: 'personal',
            text: 'Отчет',
            before: QueuesIcon,
            to: '/individual/report'
          },
          {
            before: QueuesIcon,
            id: 'compare',
            text: 'Сравнение',
            to: `/individual/compare/`,
            type: 'InlineComponent',
            component: LinkItem
          },
          {
            goTo: 'individual/videos',
            id: 'videos',
            text: 'Обучающие видео',
            before: VideoIcon,
            type: 'GoToItem'
          }
        ],
        nestedGroupKey: 'menu',
        parentId: null,
        type: 'Section'
      }
    ];

    const settingsProject = [
      {
        id: 'individual/videos:header',
        items: [
          {
            type: 'InlineComponent',
            component: IndividualName,
            id: 'company-name',
            text: `${user.name} ${user.surname}`,
            desc: `${user.email}`
          },
          {
            id: 'back-button',
            items: [
              {
                goTo: 'individual/index',
                id: 'back',
                text: 'Обучающие видео',
                type: 'BackItem'
              }
            ],
            type: 'Group'
          }
        ],
        type: 'Section'
      },
      {
        id: 'individual/videos:menu',
        nestedGroupKey: 'menu',
        parentId: 'individual/index:menu',
        type: 'Section',
        items: sections.map(section => ({
          before: VideoIcon,
          id: section.id,
          text: section.name,
          to: `/individual/videos/${section.id}`,
          type: 'InlineComponent',
          component: LinkItem
        }))
      }
    ];
    let rootViews = [
      { id: 'individual/index', getItems: () => rootIndex, type: 'product' },
      {
        id: 'individual/videos',
        getItems: () => settingsProject,
        type: 'product'
      },
    ];

    return rootViews.map(view => <ViewRegistrar key={view.id} view={view} />);
  }
}

StaffRootViews.propTypes = {
  getEducationsList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sections: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userRead: PropTypes.func.isRequired,
  userReadLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    getEducationsList: () => dispatch(getEducationsList()),
    userRead: () => dispatch(userRead())
  };
}

const mapStateToProps = store => ({
  loading: store.getIn(['educations', 'loading']),
  sections: store.getIn(['educations', 'sections']).toJS(),
  user: store.getIn(['individualProfile', 'user']).toJS(),
  userReadLoading: store.getIn(['individualProfile', 'userReadLoading'])
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'educations', reducer });
const withSaga = injectSaga({ key: 'educations', saga });

const withProfileReducer = injectReducer({ key: 'individualProfile', reducer: profileReducer });
const withProfileSaga = injectSaga({ key: 'individualProfile', saga: profileSaga });

export default compose(
  withRouter,
  withReducer,
  withProfileReducer,
  withSaga,
  withProfileSaga,
  withConnect
)(StaffRootViews);
