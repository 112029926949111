import React from 'react';
import SpinnerIcon from '@atlaskit/spinner';
import Container from './styled/Container';

const Spinner = props => (
  <Container height={props.height}>
    <SpinnerIcon size="xlarge" />
  </Container>
);

export default Spinner;
