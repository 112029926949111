import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import * as moment from 'moment';
import 'moment/locale/ru';
import { colors } from '@atlaskit/theme';
import Button, { ButtonGroup } from '@atlaskit/button';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Form from '@atlaskit/form';
import { Grid, GridColumn } from '@atlaskit/page';
import Select from '@atlaskit/select';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isEmail } from 'utils/validators';

import Center from 'components/Center';
import Spinner from 'components/Spinner';
import RouterLink from 'components/RouterLink';

import {
  changeReportsPage,
  changeReportsSort,
  changeStaffInput,
  changeProfileEmailFlags,
  changeProfileEmailModals,
  changeProfileEmailAddress,
  sendProfileEmail,
  createCompare,
  profileRead,
  staffList
} from 'pages/ProfileList/actions';
import {
  makeSelectLoadingReportsTable,
  makeSelectLoadingStaff,
  makeSelectProfileEmail,
  makeSelectProfileLoading,
  makeSelectProfileRead,
  makeSelectProfileReports,
  makeSelectProfileReportsCount,
  makeSelectProfileReportsPage,
  makeSelectProfileReportsSort,
  makeSelectStaffSample
} from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import Container from './styled/Container';
import Label from './styled/Label';
import MainBlock from './styled/MainBlock';
import P from './styled/P';
import Title from './styled/Title';

import Table from './table';

import Modal from 'components/Modal';

import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class Profile extends React.Component {
  state = {
    addModals: [],
    emailValid: true,
    flags: [],
    filter: '',
    filterWasChanged: false,
    profile: null,
    staff: []
  };

  componentDidMount() {
    this.sendChangedStaffSelector = debounce(this.sendChangedStaffSelector, 300);
    const profileId = parseInt(this.props.match.params.id, 10);

    this.props.getProfileRead({
      profileId
    });

    this.props.getStaffList();
  }

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      addModals: this.state.addModals.filter(i => i !== id)
    });
  };

  closeModal = id => {
    this.setState({ addModals: this.state.addModals.filter(i => i !== id) });
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeCompareProfiles = result => {
    this.setState({
      staff: result.map(item => item.value)
    });
  };

  onCreateCompare = id => {
    const profileCompare = parseInt(this.props.match.params.id, 10);
    const staffCompare = this.state.staff;

    this.props.createCompare({
      profileCompare,
      staffCompare
    });
    this.addFlag(id);
  };

  onSort = (key, order) => {
    this.props.changeReportsSort({
      key,
      order
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  onOpenModal = () => {
    this.props.changeProfileEmailModals({
      modals: [1]
    });
  };

  onCloseModal = id => {
    this.props.changeProfileEmailModals({
      modals: this.props.profileEmail.modals.filter(i => i !== id)
    });
  };

  onRemoveFlag = id => {
    this.props.changeProfileEmailFlags({
      flags: this.props.profileEmail.flags.filter(v => v !== id)
    });
  };

  onEmailChange = event => {
    if (!this.state.emailValid) {
      this.setState({
        emailValid: true
      });
    }

    this.props.changeProfileEmailAddress({
      email: event.target.value
    });
  };

  onSendModal = event => {
    if (isObject(event)) {
      event.preventDefault();
    }
    const { email } = this.props.profileEmail;

    if (isEmail(email)) {
      const profileId = parseInt(this.props.match.params.id, 10);
      this.props.sendProfileEmail({
        id: profileId
      });
    } else {
      this.setState({
        emailValid: false
      });
    }
  };

  sendChangedStaffSelector = input => {
    this.props.changeStaffInput({
      input
    });
  };

  render() {
    let { emailValid, profile } = this.state;
    const {
      changeReportsPage,
      loadingReportsTable,
      loadingStaff,
      profileEmail,
      profileLoading,
      profileReports,
      profileReportsCount,
      profileReportsPage,
      profileReportsSort,
      staffList
    } = this.props;

    if (isNull(profile)) {
      profile = this.props.profileRead;
    }

    let content = <Spinner />;

    let options = [];
    if (staffList.length > 0) {
      options = staffList.map(staff => ({
        label: staff.full_name,
        value: staff.id
      }));
    }

    if (!profileLoading) {
      const button = (
        <ButtonGroup>
          <Button className="media-print-hide" onClick={this.onOpenModal}>
            Отправить на email
          </Button>
          <Button
            appearance="primary"
            href={`/profile/edit/${profile.get('id')}`}
            component={RouterLink}
          >
            Редактировать
          </Button>
        </ButtonGroup>
      );

      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/profile/list"
            onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
            text="Профили"
            key="Profile"
          />
          <BreadcrumbsItem
            href={`/profile/${profile.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/profile/${profile.get('id')}`)}
            text={profile.get('name')}
            key={profile.get('name')}
          />
        </BreadcrumbsStateless>
      );

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader breadcrumbs={breadcrumbs} actions={button}>
              {profile.get('name')}
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <div>
              <Label>Описание</Label>
              <P>{profile.get('description')}</P>
            </div>
            <Block>
              <Grid layout="fixed">
                <GridColumn medium={4}>
                  <Label>HR-менеджер</Label>
                  <P>{profile.getIn(['owner', 'full_name'])}</P>
                </GridColumn>
                <GridColumn medium={4}>
                  <Label>Создан</Label>
                  <P>{moment(profile.get('created_at')).format('DD.MM.YYYY HH:mm')}</P>
                </GridColumn>
                <GridColumn medium={4}>
                  <Label>Изменен</Label>
                  <P>{moment(profile.get('updated_at')).format('DD.MM.YYYY HH:mm')}</P>
                </GridColumn>
              </Grid>
            </Block>
            <MainBlock>
              <Title>
                <h3>Соответствие этому профилю</h3>
                <Button
                  appearance="primary"
                  onClick={() =>
                    this.setState({
                      addModals: [1]
                    })
                  }
                >
                  Добавить соответствие
                </Button>
              </Title>
              <Table
                data={profileReports}
                isLoading={loadingReportsTable}
                onSort={this.onSort}
                sortKey={profileReportsSort.get('key')}
                sortOrder={profileReportsSort.get('order')}
              />
              {!loadingReportsTable && (
                <Center>
                  <Pagination
                    value={profileReportsPage}
                    total={Math.ceil(profileReportsCount / 25)}
                    onChange={profileReportsPage => changeReportsPage({ profileReportsPage })}
                  />
                </Center>
              )}
            </MainBlock>
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{profile.get('name')}</title>
        </Helmet>

        <Container>
          {content}

          {this.state.addModals.map(id => (
            <Modal
              key={id}
              id={id}
              heading="Создать отчет-соответствие"
              onClose={this.closeModal}
              actions={[
                {
                  text: 'Создать',
                  onClick: this.onCreateCompare
                },
                {
                  text: 'Отменить',
                  onClick: this.closeModal
                }
              ]}
            >
              <p>Выберите один или несколько профилей, с которым необходимо сравнить сотрудника</p>
              <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
                <FieldGroup marginTop="8px">
                  <Field width="100%">
                    <Select
                      className="multi-select"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      isLoading={loadingStaff}
                      isMulti
                      filterOption={() => true}
                      loadingMessage={() => 'Загрузка'}
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => 'Не найдено'}
                      onChange={this.onChangeCompareProfiles}
                      onInputChange={this.sendChangedStaffSelector}
                      options={options}
                      placeholder=""
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Field>
                </FieldGroup>
              </Form>
            </Modal>
          ))}

          {profileEmail.modals.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Отправить профиль"
              onClose={this.onCloseModal}
              actions={[
                {
                  text: 'Отправить',
                  onClick: this.onSendModal,
                  isLoading: profileEmail.loading,
                  type: 'submit'
                },
                {
                  text: 'Отменить',
                  onClick: this.onCloseModal
                }
              ]}
              width="small"
            >
              <p>Укажите e-mail, на который необходимо отправить профиль</p>
              <Form
                name="layout-example"
                onSubmit={this.onSendModal}
                onReset={() => {}}
                method="GET"
              >
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!emailValid}
                      label="Email"
                      name="email"
                      onChange={this.onEmailChange}
                      placeholder=""
                      required
                      shouldFitContainer
                      value={profileEmail.email}
                    />
                  </Field>
                </FieldGroup>
              </Form>
            </Modal>
          ))}

          <FlagGroup onDismissed={name => this.onRemoveFlag(name)}>
            {profileEmail.flags.map(id => (
              <Flag
                description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
                isDismissAllowed
                id={id}
                icon={
                  id > 0 ? (
                    <Tick label="Success" />
                  ) : (
                    <Error label="Error" primaryColor={colors.R300} />
                  )
                }
                key={`${id}`}
                title={id > 0 ? 'Профиль отправлен' : 'Ошибка'}
              />
            ))}
          </FlagGroup>

          <FlagGroup onDismissed={name => this.removeFlag(name)}>
            {this.state.flags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={`${id}`}
                title="Отчет-соответствие создан"
              />
            ))}
          </FlagGroup>
        </Container>
      </Fragment>
    );
  }
}

Profile.propTypes = {
  changeProfileEmailAddress: PropTypes.func,
  changeProfileEmailFlags: PropTypes.func,
  changeProfileEmailModals: PropTypes.func,
  changeReportsPage: PropTypes.func,
  changeReportsSort: PropTypes.func,
  changeStaffInput: PropTypes.func,
  createCompare: PropTypes.func,
  getProfileRead: PropTypes.func,
  getStaffList: PropTypes.func,
  loadingReportsTable: PropTypes.bool,
  loadingStaff: PropTypes.bool,
  profileEmail: PropTypes.object,
  profileLoading: PropTypes.bool,
  profileRead: PropTypes.object,
  profileReports: PropTypes.array,
  profileReportsCount: PropTypes.number,
  profileReportsPage: PropTypes.number,
  profileReportsSort: PropTypes.object,
  staffList: PropTypes.array,
  sendProfileEmail: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeProfileEmailAddress: value => dispatch(changeProfileEmailAddress(value)),
    changeProfileEmailFlags: value => dispatch(changeProfileEmailFlags(value)),
    changeProfileEmailModals: value => dispatch(changeProfileEmailModals(value)),
    changeReportsPage: value => dispatch(changeReportsPage(value)),
    changeReportsSort: value => dispatch(changeReportsSort(value)),
    changeStaffInput: value => dispatch(changeStaffInput(value)),
    createCompare: value => dispatch(createCompare(value)),
    getProfileRead: value => dispatch(profileRead(value)),
    getStaffList: () => dispatch(staffList()),
    sendProfileEmail: value => dispatch(sendProfileEmail(value))
  };
}

const mapStateToProps = createStructuredSelector({
  loadingReportsTable: makeSelectLoadingReportsTable(),
  loadingStaff: makeSelectLoadingStaff(),
  profileEmail: makeSelectProfileEmail(),
  profileLoading: makeSelectProfileLoading(),
  profileRead: makeSelectProfileRead(),
  profileReports: makeSelectProfileReports(),
  profileReportsCount: makeSelectProfileReportsCount(),
  profileReportsPage: makeSelectProfileReportsPage(),
  profileReportsSort: makeSelectProfileReportsSort(),
  staffList: makeSelectStaffSample()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Profile);
