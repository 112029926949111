import styled from 'styled-components';

const ProgressLine = styled.div`
  background-color: #f0f2f5;
  border-radius: 3px;
  color: ${props => props.color};
  font-size: 16px;
  height: 32px;
  text-align: center;
  width: 100%;
  letter-spacing: -0.32px;
  line-height: 32px;
  position: relative;
  margin: 24px 0 12px;

  &:before {
    content: '';
    background-color: #7a869a;
    top: -12px;
    left: ${props => props.progress};
    position: absolute;
    width: 2px;
    height: 56px;
  }

  @media print {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

export default ProgressLine;
