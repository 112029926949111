import { createSelector } from 'reselect';

const selectGlobal = state => state.get('promo');

const makeSelectName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('name')
  );

const makeSelectPhone = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('phone')
  );

export { makeSelectName, makeSelectPhone };
