export const GET_REQUISITES_LIST = 'hh/pages/partner/requisites/GET_REQUISITES_LIST';
export const GET_REQUISITES_LIST_SUCCESS =
  'hh/pages/partner/requisites/GET_REQUISITES_LIST_SUCCESS';
export const GET_REQUISITES_LIST_FAIL = 'hh/pages/partner/requisites/GET_REQUISITES_LIST_FAIL';
export const CHANGE_REQUISITES_PAGE = 'hh/pages/partner/requisites/CHANGE_REQUISITES_PAGE';
export const CHANGE_REQUISITES_REGISTRATION_FIELD =
  'hh/pages/partner/requisites/CHANGE_REQUISITES_REGISTRATION_FIELD';
export const RESET_REQUISITES_REGISTRATION_FORM =
  'hh/pages/partner/requisites/RESET_REQUISITES_REGISTRATION_FORM';
export const REGISTER_NEW_REQUISITES = 'hh/pages/partner/requisites/REGISTER_NEW_REQUISITES';
export const REGISTER_NEW_REQUISITES_SUCCESS =
  'hh/pages/partner/requisites/REGISTER_NEW_REQUISITES_SUCCESS';
export const REGISTER_NEW_REQUISITES_FAIL =
  'hh/pages/partner/requisites/REGISTER_NEW_REQUISITES_FAIL';
export const CHANGE_FLAGS = 'hh/pages/partner/requisites/CHANGE_FLAGS';
export const CHANGE_MODALS = 'hh/pages/partner/requisites/CHANGE_MODALS';
export const GET_ORGANIZATION = 'hh/pages/partner/requisites/GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'hh/pages/partner/requisites/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAIL = 'hh/pages/partner/requisites/GET_ORGANIZATION_FAIL';
export const CHANGE_ORGANIZATION_FIELD = 'hh/pages/partner/requisites/CHANGE_ORGANIZATION_FIELD';
export const UPDATE_ORGANIZATION = 'hh/pages/partner/requisites/UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS =
  'hh/pages/partner/requisites/UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAIL = 'hh/pages/partner/requisites/UPDATE_ORGANIZATION_FAIL';
export const DELETE_ORGANIZATION = 'hh/pages/partner/requisites/DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS =
  'hh/pages/partner/requisites/DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAIL = 'hh/pages/partner/requisites/DELETE_ORGANIZATION_FAIL';
