import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from 'pages/PartnerProfile/User/reducer';
import saga from 'pages/PartnerProfile/User/saga';

import { changeUserPasswordField, updateUserPassword } from 'pages/PartnerProfile/User/actions';

import {
  makeSelectUserUpdateLoading,
  makeSelectUserPassword
} from 'pages/PartnerProfile/User/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import TextField from 'components/TextField';
import FieldGroup from 'pages/MyProfile/styled/FieldGroup';
import Field from 'pages/MyProfile/styled/Field';

class PasswordUser extends Component {
  state = {
    formValid: {
      current_password: true,
      new_password: true
    }
  };

  onChangeFormField = event => {
    const {
      props: { changeUserPasswordField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeUserPasswordField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { updateUserPassword, user },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: user[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      updateUserPassword();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'current_password':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              current_password: result
            }
          });
        }

        return result;
      case 'new_password':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              new_password: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { loading, user },
      state: { formValid }
    } = this;

    return (
      <Form name="userPassword">
        <FieldGroup marginTop="23px">
          <h4>Изменение пароля</h4>
        </FieldGroup>
        <FieldGroup>
          <Field width="280px">
            <TextField
              isInvalid={!formValid.current_password}
              required
              label="Текущий пароль"
              id="current_password"
              name="current_password"
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={user.current_password}
              type="password"
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field width="280px">
            <TextField
              isInvalid={!formValid.new_password}
              required
              label="Новый пароль"
              id="new_password"
              name="new_password"
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={user.new_password}
              type="password"
            />
          </Field>
        </FieldGroup>
        <FieldGroup marginTop="40px">
          <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
            Сохранить изменения
          </Button>
        </FieldGroup>
      </Form>
    );
  };

  render() {
    const { renderForm } = this;

    return renderForm();
  }
}

PasswordUser.propTypes = {
  changeUserPasswordField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeUserPasswordField: value => dispatch(changeUserPasswordField(value)),
    updateUserPassword: () => dispatch(updateUserPassword())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectUserUpdateLoading(),
  user: makeSelectUserPassword()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerUser', reducer });
const withSaga = injectSaga({ key: 'partnerUser', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(PasswordUser);
