import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Tabs from '@atlaskit/tabs';

import Spinner from 'components/Spinner';

import { videosList } from 'pages/Video/actions';

import { makeSelectVideoList, makeSelectVideoLoading } from './selectors';

import Container from './styled/Container';
import Content from './styled/Content';
import PageDesc from './styled/PageDesc';
import TabContent from './styled/TabContent';
import TabTitle from './styled/TabTitle';
import Text from './styled/Text';
import EmbedVideo from './styled/EmbedVideo';

class Video extends React.Component {
  constructor(props) {
    super(props);

    const { getEducationsList, sections } = this.props;

    if (sections.length === 0) {
      getEducationsList();
    }
  }

  renderTabContent = category =>
    category.videos.map(video => (
      <TabContent key={video.id}>
        <TabTitle>{video.name}</TabTitle>
        <Text>{video.description}</Text>
        <EmbedVideo dangerouslySetInnerHTML={{ __html: video.url }} />
      </TabContent>
    ));

  render() {
    const sectionId = parseInt(this.props.match.params.sectionId, 10);
    const { loading, sections } = this.props;

    let content = <Spinner />;

    if (!loading && sections.length > 0) {
      const currentSection = sections.find(section => section.id === sectionId);

      const tabs = currentSection.categories.map(category => ({
        label: category.name,
        content: this.renderTabContent(category)
      }));

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader>
              Обучающие видео
              <PageDesc>{currentSection.description}</PageDesc>
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Tabs
              components={{ Content }}
              tabs={tabs}
              onSelect={(tab, index) => console.log('Selected Tab', index + 1)}
            />
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Обучающие видео</title>
        </Helmet>
        <Container>{content}</Container>
      </Fragment>
    );
  }
}

Video.propTypes = {
  getVideoList: PropTypes.func,
  loading: PropTypes.bool,
  sections: PropTypes.array
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectVideoLoading(),
  sections: makeSelectVideoList()
});

function mapDispatchToProps(dispatch) {
  return {
    getEducationsList: () => dispatch(videosList())
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  withConnect
)(Video);
