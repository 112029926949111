import React from 'react';
import PropTypes from 'prop-types';
import AnswerList from 'components/AnswerList';
import QuestionBody from './styled/QuestionBody';
import QuestionCount from './styled/QuestionCount';
import QuestionNote from './styled/QuestionNote';

class Question extends React.Component {
  render() {
    const { currentQuestionNumber, onAnswersChange, question, totalQuestionNumber } = this.props;

    return (
      <div>
        <QuestionCount>
          {currentQuestionNumber} / {totalQuestionNumber}
        </QuestionCount>

        <QuestionBody>{question.body}</QuestionBody>

        <QuestionNote>
          Расставьте варианты ответов в порядке убывания: первый — наиболее похоже на вас, последний
          — наименее похоже на вас.
        </QuestionNote>

        <AnswerList
          answers={question.answers}
          number={currentQuestionNumber}
          onReorder={onAnswersChange}
        />
      </div>
    );
  }
}

Question.propTypes = {
  currentQuestionNumber: PropTypes.number.isRequired,
  onAnswersChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  totalQuestionNumber: PropTypes.number.isRequired
};

Question.defaultProps = {
  currentQuestionNumber: null,
  onAnswersChange: null,
  question: {
    body: null,
    answers: []
  },
  totalQuestionNumber: null
};

export default Question;
