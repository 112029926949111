import React, { Fragment } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import RouterLink from 'components/RouterLink';

import CloseImage from './close.svg';
import HRHLogoImage from './hrh24-logo.png';
import MYLogoImage from './my-yadro-logo.png';
import HamburgerMenu from './menu.svg';

import service from "../../utils/checkService";

const isIndividualHost = process.env.REACT_APP_TARGET === "lk.my-yadro.ru"

const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  height: 72px;
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
  }
`;

const Logo = styled.div`
  background-image: url(${service === "hrh24" ? HRHLogoImage : MYLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 24px;
  width: 141px;
`;

const Menu = styled.div`
  display: flex;
  margin-bottom: 1px;
  margin-left: auto;
  font-size: 16px;

  & div {
    cursor: pointer;
  }

  @media (max-width: 1020px) {
    display: none;
  }
`;

const ItemHash = styled(HashLink)`
  color: #172b4d;
  cursor: pointer;
  font-size: 16px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
`;

const Hamburger = styled.div`
  display: none;

  @media (max-width: 1020px) {
    &::after {
      background-image: url(${HamburgerMenu});
      background-size: cover;
      background-position: center;
      content: '';
      display: block;
      height: 14px;
      margin: auto;
      width: 20px;
    }

    cursor: pointer;
    display: flex;
    height: 26px;
    width: 26px;
  }
`;

const MobileMenu = styled.div`
  background-color: #ffffff;
  display: flex;
  height: 100vh;
  left: 0;
  position: absolute;
  opacity: ${props => (props.isOpened ? 1 : 0)};
  top: 0;
  transition: all 0.3s ease-out;
  width: 100vw;
  z-index: ${props => (props.isOpened ? 10 : -1)};
`;

const Close = styled.div`
  display: none;

  @media (max-width: 1020px) {
    &::after {
      background-image: url(${CloseImage});
      background-size: cover;
      background-position: center;
      content: '';
      display: block;
      height: 16px;
      margin: auto;
      width: 16px;
    }

    cursor: pointer;
    display: flex;
    height: 26px;
    position: absolute;
    right: 15px;
    top: 23px;
    width: 26px;
  }
`;

const MenuWrapper = styled.div`
  display: none;

  @media (max-width: 1020px) {
    display: block;
    margin: auto;
    transform: translateY(-20px);
  }
`;

const MenuItem = styled(Link)`
  display: none;

  @media (max-width: 1020px) {
    color: #172b4d;
    display: block;
    font-size: 19px;
    letter-spacing: 0.26px;
    text-align: center;
    line-height: 22px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const MenuHashItem = styled(HashLink)`
  display: none;

  @media (max-width: 1020px) {
    color: #172b4d;
    display: block;
    font-size: 19px;
    letter-spacing: 0.26px;
    text-align: center;
    line-height: 22px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

class Header extends React.Component {
  state = {
    mobileMenu: false
  };

  onLogoClick = () => {
    this.props.history.push('/');
  };

  toggleMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu
    });
  };

  render() {
    return (
      <Wrapper>
        <Logo onClick={this.onLogoClick} />

        {this.props.withLinks && (
          <Fragment>
            <Menu>
              <ItemHash smooth to="#contacts">
                Контакты
              </ItemHash>
              {
                isIndividualHost ? (
                  <ItemHash smooth to="/individual/login">
                    Вход
                  </ItemHash>
                ) : (
                  <DropdownMenu
                    trigger="Войти"
                    triggerType="default"
                    shouldFlip={false}
                    position="bottom right"
                  >
                    <DropdownItem href="/signin" linkComponent={RouterLink}>
                      Вход в кабинет компании
                    </DropdownItem>
                    <DropdownItem href="/partner/login" linkComponent={RouterLink}>
                      Вход в кабинет партнера
                    </DropdownItem>
                  </DropdownMenu>
                )
              }

            </Menu>
            <Hamburger onClick={this.toggleMenu} />

            <MobileMenu isOpened={this.state.mobileMenu}>
              <Close onClick={this.toggleMenu} />
              <MenuWrapper>
                <MenuHashItem smooth to="#contacts">
                  Контакты
                </MenuHashItem>
                {
                  isIndividualHost ? (
                    <MenuItem to="/individual/login">Вход в личный кабинет</MenuItem>
                  ) : (
                    <>
                      <MenuItem to="/signin">Вход в кабинет компании</MenuItem>
                      <MenuItem to="/partner/login">Вход в кабинет партнера</MenuItem>
                    </>
                  )
                }
              </MenuWrapper>
            </MobileMenu>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

export default compose(withRouter)(Header);
