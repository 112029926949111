import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import SettingsBillingPlansPage from 'pages/Settings/subpages/BillingPlans';

const SettingsBillingPlansView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/settings" />
    <SettingsBillingPlansPage />
  </Fragment>
);

export default SettingsBillingPlansView;
