import styled from 'styled-components';

const LegendWrapper = styled.div`
  background-color: #fafafc;
  box-sizing: border-box;
  margin-top: 35px;
  padding: 30px 35px;
  width: 100%;
`;

export default LegendWrapper;
