import { createSelector } from 'reselect';

const selectGlobal = state => state.get('documents');

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectDocuments = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('documents').toJS()
  );

const makeSelectActiveDocument = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('activeDocument')
  );

const makeSelectActiveDocumentId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('activeDocumentId')
  );

export {
  makeSelectActiveDocument,
  makeSelectActiveDocumentId,
  makeSelectDocuments,
  makeSelectLoading
};
