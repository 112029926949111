import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfilesListPage from 'pages/ProfileList';

const ProfilesListView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfilesListPage />
  </Fragment>
);

export default ProfilesListView;
