import styled from 'styled-components';

const Text = styled.div`
  font-size: 14px;
  color: #172b4d;
  letter-spacing: -0.15px;
  text-align: left;
  line-height: 20px;
  margin: 15px 0;
`;

export default Text;
