import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 75px;

  @media (max-width: 1020px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

export default Flex;
