import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@atlaskit/icon/glyph/info';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';

import LinkItem from 'navigation/components/LinkItem/index';
import ViewRegistrar from 'navigation/components/ViewRegistrar/index';

const ContainerViews = props => {
  const containerProject = [
    {
      id: 'partner/partners:header',
      items: [
        {
          id: 'back-button',
          items: [
            {
              type: 'BackItem',
              goTo: 'partner/index',
              text: props.partnerName,
              subText: props.partnerEmail,
              id: 'back'
            }
          ],
          type: 'Group'
        }
      ],
      type: 'HeaderSection'
    },
    {
      id: 'partner/partners:menu',
      nestedGroupKey: 'menu',
      items: [
        {
          before: InfoIcon,
          id: 'information',
          text: 'Информация',
          to: `/partner/partners/${props.partnerId}`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: PeopleGroupIcon,
          id: 'clients',
          text: 'Клиенты',
          to: `/partner/partners/${props.partnerId}/clients`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: OfficeBuildingIcon,
          id: 'requisites',
          text: 'Реквизиты',
          to: `/partner/partners/${props.partnerId}/requisites`,
          type: 'InlineComponent',
          component: LinkItem
        }
      ],
      type: 'Section'
    }
  ];
  const editedView = [
    {
      id: `partner/partners/${props.partnerId}`,
      getItems: () => containerProject,
      type: 'container'
    }
  ];

  return (
    <Fragment>
      {editedView.map(view => (
        <ViewRegistrar key={view.id} view={view} />
      ))}
    </Fragment>
  );
};

ContainerViews.propTypes = {
  partnerId: PropTypes.number,
  partnerEmail: PropTypes.string,
  partnerName: PropTypes.string
};

export default ContainerViews;
