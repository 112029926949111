import styled from "styled-components";

const NameContainer = styled.div`
  align-items: center;
  background-color: #edeff2;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: 80px;
  justify-content: space-between;
  margin-right: 10px;
  padding: 10px 7px 10px 10px;
  width: 120px;
  flex-direction: column;
`

export default NameContainer;
