import React from 'react';
import NothingImage from '../images/nothing.png';
import Flex from './Flex';

const Nothing = () => (
  <Flex>
    <img
      src={NothingImage}
      srcSet={`${NothingImage} 2x`}
      alt="По вашему запросу ничего не найдено"
      width="181"
    />
    <p>По вашему запросу ничего не найдено</p>
  </Flex>
);

export default Nothing;
