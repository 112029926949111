import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button, { ButtonGroup } from '@atlaskit/button';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Form, { Field as FormField } from '@atlaskit/form';
import Select from '@atlaskit/select';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Pagination from '@atlaskit/pagination';

import isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';
import Center from 'components/Center';
import Empty from 'components/Empty';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { isEmail, isRequired } from 'utils/validators';

import { getUserInfo } from 'pages/Home/actions';

import {
  changeCommentInvite,
  changeFlagsInvite,
  changeFullNameInvite,
  changeEmailInvite,
  changeManagersPage,
  changeManagersSort,
  changeModalsInvite,
  changeNameFilter,
  changeNameInvite,
  changeRoleInvite,
  changeTextInvite,
  getManagersList,
  sendInvite
} from '../actions';
import {
  makeSelectInvite,
  makeSelectInviteFlags,
  makeSelectInviteModals,
  makeSelectLoadingTable,
  makeSelectLoadingInvite,
  makeSelectManagersCount,
  makeSelectManagersList,
  makeSelectManagersPage,
  makeSelectManagersSort,
  makeSelectSettingsLoading
} from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import Field from '../styled/Field';
import FieldGroup from '../styled/FieldGroup';
import P from '../styled/P';
import UserTable from '../table/UserTable';
import TextArea from '@atlaskit/field-text-area';

class Users extends React.Component {
  state = {
    nameFilter: '',
    filterWasChanged: false,
    nameValid: true,
    emailValid: true
  };

  componentDidMount = () => {
    this.sendChangedNameFilter = debounce(this.sendChangedNameFilter, 300);

    if (!isUndefined(this.props.location.state)) {
      this.props.changeModalsInvite({
        modals: this.props.location.state.addModals
      });
    }

    this.props.getUserInfo();
    this.props.getManagersList();
  };

  closeModal = id => {
    this.props.changeModalsInvite({
      modals: this.props.inviteModals.filter(i => i !== id)
    });
  };

  addFlag = () => {
    this.props.changeFlagsInvite({
      flags: [this.props.inviteFlags.length, ...this.props.inviteFlags.flags]
    });
  };

  removeFlag = id => {
    this.props.changeFlagsInvite({
      flags: this.props.inviteFlags.filter(v => v !== id)
    });
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeComment = event => {
    this.props.changeCommentInvite({
      comment: event.target.value
    });
  };

  onChangeFullName = event => {
    this.props.changeFullNameInvite({
      fullName: event.target.value
    });
  };

  onChangeEmail = event => {
    this.setState({
      emailValid: isEmail(event.target.value)
    });
    this.props.changeEmailInvite({
      email: event.target.value
    });
  };

  onChangeName = event => {
    this.setState({
      nameValid: isRequired(event.target.value)
    });
    this.props.changeNameInvite({
      name: event.target.value
    });
  };

  onChangeRole = event => {
    this.props.changeRoleInvite({
      role: event.value
    });
  };

  onChangeText = event => {
    this.props.changeTextInvite({
      text: event.target.value
    });
  };

  onSendInvite = () => {
    const { invite } = this.props;
    const nameValid = isRequired(invite.get('name'));
    const emailValid = isEmail(invite.get('email'));

    if (nameValid && emailValid) {
      this.props.sendInvite();
    } else {
      this.setState({
        nameValid,
        emailValid
      });
    }
  };

  onChangeNameFilter = event => {
    this.sendChangedNameFilter(event.target.value);
    this.setState({
      filterWasChanged: true,
      nameFilter: event.target.value
    });
  };

  sendChangedNameFilter = nameFilter => {
    this.props.changeNameFilter({
      nameFilter
    });
  };

  onSort = (key, order) => {
    this.props.changeManagersSort({
      key,
      order
    });
  };

  render() {
    const {
      changeManagersPage,
      invite,
      inviteFlags,
      inviteModals,
      loadingInvite,
      loadingTable,
      managersCount,
      managersList,
      managersPage,
      managersSort,
      settingsLoading
    } = this.props;
    const { filterWasChanged, nameFilter, nameValid, emailValid } = this.state;

    let content = <Spinner />;

    if (!settingsLoading && (managersList.size > 0 || filterWasChanged)) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/settings/users"
            onClick={event => this.onBreadcumbsClick(event, '/settings/users')}
            text="Настройки"
            key="Settings"
          />
          <BreadcrumbsItem
            href="/settings/users"
            onClick={event => this.onBreadcumbsClick(event, '/settings/users')}
            text="Пользователи"
            key="Users"
          />
        </BreadcrumbsStateless>
      );

      const barContent = (
        <ButtonGroup>
          <TextField
            isLabelHidden
            label="hidden"
            onChange={this.onChangeNameFilter}
            placeholder="Имя или фамилия"
            value={nameFilter}
          />
        </ButtonGroup>
      );

      const actions = (
        <ButtonGroup>
          <Button
            appearance="primary"
            onClick={() =>
              this.props.changeModalsInvite({
                modals: [1]
              })
            }
          >
            Пригласить пользователя
          </Button>
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader breadcrumbs={breadcrumbs} actions={actions} bottomBar={barContent}>
              Пользователи
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <UserTable
              data={managersList}
              isLoading={loadingTable}
              onSort={this.onSort}
              sortKey={managersSort.get('key')}
              sortOrder={managersSort.get('order')}
            />
            {!loadingTable && (
              <Center>
                <Pagination
                  value={managersPage}
                  total={Math.ceil(managersCount / 25)}
                  onChange={managersPage => changeManagersPage({ managersPage })}
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!settingsLoading && managersList.size === 0) {
      content = (
        <Empty header="" text="Нажмите на кнопку чтобы пригласить пользователя">
          <Button
            appearance="primary"
            onClick={() =>
              this.props.changeModalsInvite({
                modals: [1]
              })
            }
          >
            Пригласить пользователя
          </Button>
        </Empty>
      );
    }

    const options = [];
    options[1] = { label: 'Администратор', value: 1, extra: 'extra' };
    options[2] = { label: 'Менеджер', value: 2 };

    return (
      <Fragment>
        <Helmet>
          <title>Пользователи</title>
        </Helmet>

        <Page>
          {content}

          {inviteModals.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Пригласить пользователя"
              onClose={this.closeModal}
              actions={[
                {
                  isLoading: loadingInvite,
                  onClick: this.onSendInvite,
                  text: 'Пригласить'
                },
                {
                  text: 'Отменить',
                  onClick: this.closeModal
                }
              ]}
              width="small"
            >
              <p>Укажите имя человека и e-mail, на который будет отправлено приглашение.</p>
              <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!nameValid}
                      label="Имя"
                      name="name"
                      onChange={this.onChangeName}
                      placeholder=""
                      required
                      shouldFitContainer
                      value={invite.get('name')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      label="Фамилия"
                      name="full_name"
                      onChange={this.onChangeFullName}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('full_name')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!emailValid}
                      label="Email"
                      name="email"
                      onChange={this.onChangeEmail}
                      placeholder=""
                      required
                      shouldFitContainer
                      value={invite.get('email')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <FormField label="Роль" validateOnBlur={false}>
                      <Select
                        className="multi-select"
                        classNamePrefix="react-select"
                        defaultValue={options[invite.get('role')]}
                        menuPortalTarget={document.body}
                        onChange={this.onChangeRole}
                        options={options}
                        placeholder="Роль"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </FormField>
                    <P marginTop="9px" color="#6B778C">
                      Администратор может приглашать других пользователей в компанию и имеет полный
                      доступ к данным всех менеджеров. Менеджер имеет доступ только к своим данным,
                      а данные других менеджеров он может только просматривать.
                    </P>
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextArea
                      enableResize={false}
                      label="Текст приглашения"
                      minimumRows={4}
                      name="text"
                      onChange={this.onChangeText}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('welcome_text')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextArea
                      enableResize={false}
                      label="Комментарий"
                      minimumRows={4}
                      name="comment"
                      onChange={this.onChangeComment}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('comment')}
                    />
                  </Field>
                </FieldGroup>
              </Form>
            </Modal>
          ))}

          <FlagGroup onDismissed={name => this.removeFlag(name)}>
            {inviteFlags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={`${id}`}
                title="Приглашение отправлено"
              />
            ))}
          </FlagGroup>
        </Page>
      </Fragment>
    );
  }
}

Users.propTypes = {
  changeCommentInvite: PropTypes.func,
  changeFlagsInvite: PropTypes.func,
  changeFullNameInvite: PropTypes.func,
  changeEmailInvite: PropTypes.func,
  changeManagersPage: PropTypes.func,
  changeManagersSort: PropTypes.func,
  changeModalsInvite: PropTypes.func,
  changeNameInvite: PropTypes.func,
  changeRoleInvite: PropTypes.func,
  changeTextInvite: PropTypes.func,
  getManagersList: PropTypes.func,
  getUserInfo: PropTypes.func,
  invite: PropTypes.object,
  inviteFlags: PropTypes.array,
  inviteModals: PropTypes.array,
  loadingInvite: PropTypes.bool,
  loadingTable: PropTypes.bool,
  managersCount: PropTypes.number,
  managersList: PropTypes.object,
  managersPage: PropTypes.number,
  managersSort: PropTypes.object,
  sendInvite: PropTypes.func,
  settingsLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeCommentInvite: value => dispatch(changeCommentInvite(value)),
    changeFlagsInvite: value => dispatch(changeFlagsInvite(value)),
    changeFullNameInvite: value => dispatch(changeFullNameInvite(value)),
    changeEmailInvite: value => dispatch(changeEmailInvite(value)),
    changeManagersPage: value => dispatch(changeManagersPage(value)),
    changeManagersSort: value => dispatch(changeManagersSort(value)),
    changeModalsInvite: value => dispatch(changeModalsInvite(value)),
    changeNameFilter: value => dispatch(changeNameFilter(value)),
    changeNameInvite: value => dispatch(changeNameInvite(value)),
    changeRoleInvite: value => dispatch(changeRoleInvite(value)),
    changeTextInvite: value => dispatch(changeTextInvite(value)),
    getManagersList: () => dispatch(getManagersList()),
    getUserInfo: () => dispatch(getUserInfo()),
    sendInvite: () => dispatch(sendInvite())
  };
}

const mapStateToProps = createStructuredSelector({
  invite: makeSelectInvite(),
  inviteFlags: makeSelectInviteFlags(),
  inviteModals: makeSelectInviteModals(),
  loadingInvite: makeSelectLoadingInvite(),
  loadingTable: makeSelectLoadingTable(),
  managersCount: makeSelectManagersCount(),
  managersList: makeSelectManagersList(),
  managersPage: makeSelectManagersPage(),
  managersSort: makeSelectManagersSort(),
  settingsLoading: makeSelectSettingsLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Users);
