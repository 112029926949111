import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 39px;
  margin-bottom: 30px;

  & div {
    min-width: 170px;

    &:first-child {
      margin-right: 15px;
    }
  }
`;

export default ButtonWrapper;
