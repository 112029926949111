import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileCreateAskChiefPage from 'pages/ProfileCreateCheifAsk';

const ProfileCreateAskChiefView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileCreateAskChiefPage />
  </Fragment>
);

export default ProfileCreateAskChiefView;
