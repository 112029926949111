export const CHANGE_EMAIL = 'hh/pages/registrationCompany/CHANGE_EMAIL';
export const CHANGE_PASSWORD_ONE = 'hh/pages/registrationCompany/CHANGE_PASSWORD_ONE';
export const CHANGE_SMS_CODE = 'hh/pages/registrationCompany/CHANGE_SMS_CODE';
export const CHANGE_PHONE_NUMBER = 'hh/pages/registrationCompany/CHANGE_PHONE_NUMBER';
export const CHANGE_NAME = 'hh/pages/registrationCompany/CHANGE_NAME';
export const CHANGE_SURNAME = 'hh/pages/registrationCompany/CHANGE_SURNAME';
export const CHANGE_COMPANY = 'hh/pages/registrationCompany/CHANGE_COMPANY';
export const CHANGE_INN = 'hh/pages/registrationCompany/CHANGE_INN';
export const CHANGE_FLAGS = 'hh/pages/registrationCompany/CHANGE_FLAGS';
export const CHANGE_SMS_FLAGS = 'hh/pages/registrationCompany/CHANGE_SMS_FLAGS';

export const SEND_SMS_CODE_REQUEST = 'hh/pages/registrationCompany/SEND_SMS_CODE_REQUEST';
export const SEND_SMS_CODE_REQUEST_SUCCESS =
  'hh/pages/registrationCompany/SEND_SMS_CODE_REQUEST_SUCCESS';
export const SEND_SMS_CODE_REQUEST_FAIL = 'hh/pages/registrationCompany/SEND_SMS_CODE_REQUEST_FAIL';

export const REGISTER_COMPANY_REQUEST = 'hh/pages/registrationCompany/REGISTER_COMPANY_REQUEST';
export const REGISTER_COMPANY_REQUEST_SUCCESS =
  'hh/pages/registrationCompany/REGISTER_COMPANY_REQUEST_SUCCESS';
export const REGISTER_COMPANY_REQUEST_FAIL =
  'hh/pages/registrationCompany/REGISTER_COMPANY_REQUEST_FAIL';
