import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: ${props => (props.height ? props.height : '100vh')};
  justify-content: center;
`;

export default Container;
