import { call, put, takeLeading, select } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'react-router-redux';
import apiUrl from 'utils/serverConfig';

import { UPDATE_USER_REQUEST, USER_READ_REQUEST, UPDATE_PASSWORD_REQUEST } from './constants';

import {
  updateUserSuccess,
  updateUserFail,
  userReadSuccess,
  userReadFail,
  updatePasswordSuccess,
  updatePasswordFail
} from './actions';

function* userReadSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/auth/user`;
  const questionnaireUrl = `${apiUrl}/api/staff/questionnaire/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    }
  };

  try {
    try {
      yield call(axios, questionnaireUrl, options);
    } catch (e) {}

    const request = yield call(axios, url, options);
    yield put(
      userReadSuccess({
        ...request.data
      })
    );
  } catch (e) {
    yield put(userReadFail());
  }
}

function* updateUserSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/auth/user`;
  const name = yield select(store => store.getIn(['individualProfile', 'name']));
  const surname = yield select(store => store.getIn(['individualProfile', 'surname']));
  const email = yield select(store => store.getIn(['individualProfile', 'email']));
  const gender = yield select(store => store.getIn(['individualProfile', 'gender']));
  const phoneNumber = yield select(store => store.getIn(['individualProfile', 'phoneNumber']));

  const options = {
    method: 'patch',
    headers: {
      Authorization: `Token ${staffKey}`
    },
    data: {
      phone_number: phoneNumber,
      email,
      first_name: name,
      last_name: surname,
      gender
    }
  };

  try {
    yield call(axios, url, options);
    yield put(updateUserSuccess());
  } catch (e) {
    yield put(updateUserFail());
  }
}

function* updatePasswordSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/auth/user`;
  const password = yield select(store => store.getIn(['individualProfile', 'passwordOne']));

  const options = {
    method: 'patch',
    headers: {
      Authorization: `Token ${staffKey}`
    },
    data: {
      password
    }
  };

  try {
    yield call(axios, url, options);
    yield put(updatePasswordSuccess());
  } catch (e) {
    yield put(updatePasswordFail());
  }
}

export default function* individualProfileSagas() {
  yield takeLeading(USER_READ_REQUEST, userReadSaga);
  yield takeLeading(UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLeading(UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
}
