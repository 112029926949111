import React, { PureComponent } from 'react';
import { PoseGroup } from 'react-pose';

import Tooltip from './styled/Tooltip';
import Wrapper from './styled/Wrapper';

class Circle extends PureComponent {
  state = {
    isOpened: false
  };

  getRankColor = rank => {
    if (rank > 6000) {
      return '#FFAB00';
    }

    if (rank > 300) {
      return '#36B37E';
    }

    return '#00B8D9';
  };

  getRankText = rank => {
    if (rank > 6000) {
      return 'Метапрограмма высокой значимости для вас';
    }

    if (rank > 300) {
      return 'Метапрограмма средней значимости для вас';
    }

    return 'Метапрограмма низкой значимости для вас';
  };

  onMouseOver = () => {
    this.setState({
      isOpened: true
    });
  };

  onMouseOut = () => {
    this.setState({
      isOpened: false
    });
  };

  render() {
    const { rank } = this.props;
    const { isOpened } = this.state;

    return (
      <Wrapper
        backgroundColor={this.getRankColor(rank)}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        <PoseGroup>
          {isOpened && <Tooltip key="tooltip">{this.getRankText(rank)}</Tooltip>}
        </PoseGroup>
      </Wrapper>
    );
  }
}

export default Circle;
