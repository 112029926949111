import styled from 'styled-components';

const TooltipText = styled.span`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  letter-spacing: -0.15px;
  line-height: 20px;
  position: relative;
  top: -1px;
`;

export default TooltipText;
