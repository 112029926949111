import styled from 'styled-components';

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 0 2rem;

  @media print {
    max-width: 100%;
  }
`;

export default Container;
