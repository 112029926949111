import { fromJS } from 'immutable';

import {
  CHANGE_SEARCH_INPUT,
  STAFF_SEARCH_REQUEST,
  STAFF_SEARCH_REQUEST_SUCCESS,
  STAFF_SEARCH_REQUEST_FAIL,
  PROFILE_SEARCH_REQUEST,
  PROFILE_SEARCH_REQUEST_SUCCESS,
  PROFILE_SEARCH_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: false,
  search: '',
  staffList: [],
  profilesList: []
});

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_INPUT:
      return state.set('search', action.search);
    case STAFF_SEARCH_REQUEST:
      return state.set('loading', true);
    case STAFF_SEARCH_REQUEST_SUCCESS:
      return state.set('loading', false).set('staffList', action.staffList);
    case STAFF_SEARCH_REQUEST_FAIL:
      return state.set('loading', false).set('staffList', fromJS([]));
    case PROFILE_SEARCH_REQUEST:
      return state.set('loading', true);
    case PROFILE_SEARCH_REQUEST_SUCCESS:
      return state.set('loading', false).set('profilesList', action.profilesList);
    case PROFILE_SEARCH_REQUEST_FAIL:
      return state.set('loading', false).set('profilesList', fromJS([]));
    default:
      return state;
  }
}

export default searchReducer;
