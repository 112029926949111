import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';

import CriticalToggle from 'components/CriticalToggle';
import Progress from 'components/Progress';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';

import {
  changePatternRangeCreatedLower,
  changePatternRangeCreatedUpper,
  changePatternCreatedCritical,
  changeProfileForUpdate
} from 'pages/ProfileList/actions';
import { makeSelectProfileCreated, makeSelectProfileLoading } from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import CenteredBlock from '../ProfileCreateAsk/styled/CenteredBlock';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import MainBlock from './styled/MainBlock';
import P from './styled/P';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateAskStepTwo extends React.Component {
  state = {
    changeFlags: []
  };

  componentDidMount() {
    const { profileCreated } = this.props;

    if (profileCreated.get('id') === 0) {
      this.props.history.push('/profile/create/compare/1');
    }
  }

  addFlag = () => {
    this.setState({
      changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
    });

    this.props.changeProfileForUpdate({
      profileForUpdate: 'created'
    });
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => {
    const { profileLoading } = this.props;

    return (
      <ButtonGroup>
        <Button appearance="primary" isLoading={profileLoading} onClick={this.addFlag}>
          Создать профиль
        </Button>
        <Button href="/profile/list" component={RouterLink}>
          Отмена
        </Button>
      </ButtonGroup>
    );
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeCritical = (programId, patternId, value) => () =>
    this.props.changePatternCreatedCritical({
      programId,
      patternId,
      value
    });

  onRangeLowerChange = (programId, patternId, value) => {
    this.props.changePatternRangeCreatedLower({
      programId,
      patternId,
      value
    });
  };

  onRangeUpperChange = (programId, patternId, value) => {
    this.props.changePatternRangeCreatedUpper({
      programId,
      patternId,
      value
    });
  };

  renderMetaProgram = program => (
    <MainBlock key={program.get('id')}>
      <h3>{program.get('name')}</h3>
      <p>{program.get('description')}</p>
      {program.get('patterns').map(pattern => (
        <Block key={pattern.get('id')}>
          <CenteredBlock>
            <h4>{pattern.get('name')}</h4>
            <CriticalToggle
              onChange={this.onChangeCritical(
                program.get('id'),
                pattern.get('id'),
                !pattern.get('is_important')
              )}
              isChecked={pattern.get('is_important')}
            />
          </CenteredBlock>
          <P color="#6B778C" marginTop="9px">
            Удерживайте и тяните бегунки
          </P>
          <Progress
            after={100 - pattern.getIn(['range', 'upper'])}
            before={pattern.getIn(['range', 'lower'])}
            programId={program.get('id')}
            patternId={pattern.get('id')}
            onAfterChange={this.onRangeUpperChange}
            onBeforeChange={this.onRangeLowerChange}
            isCritical={pattern.get('is_important')}
          />
          <P>{pattern.get('description')}</P>
        </Block>
      ))}
    </MainBlock>
  );

  render() {
    const { profileLoading, profileCreated } = this.props;

    let content = <Spinner />;

    if (profileCreated.get('id') !== 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/profile/list"
            onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
            text="Профили"
            key="Profile"
          />
          <BreadcrumbsItem
            href="/profile/create/compare/2"
            onClick={event => this.onBreadcumbsClick(event, '/profile/create/compare/2')}
            text="Создать с помощью выборки"
            key="Create"
          />
        </BreadcrumbsStateless>
      );

      const bottomBar = (
        <PageHeaderBottom>
          <h4>С помощью опросника</h4>
        </PageHeaderBottom>
      );

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader
              breadcrumbs={breadcrumbs}
              actions={this.getPageHeaderButtonsDrawer()}
              bottomBar={bottomBar}
            >
              Добавить профиль
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <div>
              <Form>
                <FieldGroup marginTop="8px">
                  <Field width="100%">
                    <h4>Шаг 2 из 2</h4>
                    <P color="#6B778C" marginTop="9px">
                      Профиль создан на основе анализа положительных и отрицательных примеров.
                      Проверьте значения ниже и отредактируйте их при необходимсоти.
                    </P>
                  </Field>
                </FieldGroup>
              </Form>
            </div>
            {profileCreated.get('meta_programs').map(program => this.renderMetaProgram(program))}
            <ButtonWrapper>
              <Button appearance="primary" isLoading={profileLoading} onClick={this.addFlag}>
                Создать профиль
              </Button>
            </ButtonWrapper>
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        <Container>{content}</Container>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.changeFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Профиль создан"
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

ProfileCreateAskStepTwo.propTypes = {
  changePatternCreatedCritical: PropTypes.func,
  changePatternRangeCreatedLower: PropTypes.func,
  changePatternRangeCreatedUpper: PropTypes.func,
  changeProfileForUpdate: PropTypes.func,
  profileCreated: PropTypes.object,
  profileLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changePatternRangeCreatedLower: value => dispatch(changePatternRangeCreatedLower(value)),
    changePatternRangeCreatedUpper: value => dispatch(changePatternRangeCreatedUpper(value)),
    changePatternCreatedCritical: value => dispatch(changePatternCreatedCritical(value)),
    changeProfileForUpdate: value => dispatch(changeProfileForUpdate(value))
  };
}

const mapStateToProps = createStructuredSelector({
  profileCreated: makeSelectProfileCreated(),
  profileLoading: makeSelectProfileLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateAskStepTwo);
