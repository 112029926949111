import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Page from '@atlaskit/page';

import Logo from './styled/Logo';

const TestStart = props => (
  <Page>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Helmet>
    <Logo />

    {props.page}
  </Page>
);

TestStart.propTypes = {
  page: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default TestStart;
