import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import apiUrl from 'utils/serverConfig';

class GoogleAuth extends PureComponent {
  constructor(props) {
    super(props);

    const {
      location: { search }
    } = props;

    let code = '';
    let invite = '';
    if (search.substring(0, 7) === '?state=') {
      invite = search.substring(0, search.indexOf('&')).substring(7);

      const tmp = search.substring(`?state=${invite}&`.length);
      code = tmp.substring(5, tmp.indexOf('&'));
    } else {
      code = search.substring(6, search.indexOf('&'));
    }

    (async () => {
      const options = {
        method: 'post',
        data: {
          code
        }
      };

      try {
        const request = await axios(`${apiUrl}/api/staff/auth/google`, options);
        localStorage.setItem('staffKey', request.data.key);
        if (!isEmpty(invite)) {
          props.history.push(`/invite/s/${invite}`);
        } else {
          props.history.push('/invite/s');
        }
      } catch (e) {
        props.history.push({
          pathname: '/individual/login',
          state: { showError: true }
        });
      }
    })();
  }

  render() {
    return <div />;
  }
}

export default withRouter(GoogleAuth);
