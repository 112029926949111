import styled from 'styled-components';

const getTitleStyle = () => `
  box-sizing: border-box;
  color: #172B4D;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 300px;
  padding: 0 7px 0 10px;
  position: sticky;
  text-align: left;
  text-transform: uppercase;
  width: fit-content;
`;

const getSubtitleStyle = () => `
  box-sizing: border-box;
  color: #6B778C;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 300px;
  padding: 10px 7px 0 10px;
  position: sticky;
  width: fit-content;
`;

const getStyle = () => `
  color: #172B4D;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  letter-spacing: -0.15px;
  line-height: 48px;
  width: max-content;
`;

const Line = styled.div`
  ${props => (props.titleLine ? getTitleStyle() : '')};
  ${props => (props.subtitleLine ? getSubtitleStyle() : '')};
  ${props => (!props.titleLine && !props.subtitleLine ? getStyle() : '')};
`;

export default Line;
