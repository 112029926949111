import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_REQUEST_SUCCESS,
  GET_USER_INFO_REQUEST_FAIL,
  GET_MANAGERS_LIST_REQUEST,
  GET_MANAGERS_FILTERED_LIST_REQUEST,
  GET_MANAGERS_LIST_REQUEST_SUCCESS,
  GET_MANAGERS_LIST_REQUEST_FAIL,
  CHANGE_COMMENT_INVITE,
  CHANGE_FLAGS_INVITE,
  CHANGE_FULL_NAME_INVITE,
  CHANGE_EMAIL_INVITE,
  CHANGE_MODALS_INVITE,
  CHANGE_NAME_INVITE,
  CHANGE_ROLE_INVITE,
  CHANGE_TEXT_INVITE,
  SEND_INVITE_REQUEST,
  SEND_INVITE_REQUEST_SUCCESS,
  SEND_INVITE_REQUEST_FAIL,
  GET_INVITES_LIST_REQUEST,
  GET_INVITES_LIST_REQUEST_SUCCESS,
  GET_INVITES_LIST_REQUEST_FAIL,
  CHANGE_DELETE_INVITE_MODALS,
  DELETE_INVITE_REQUEST,
  DELETE_INVITE_REQUEST_SUCCESS,
  DELETE_INVITE_REQUEST_FAIL,
  CHANGE_NAME_FILTER,
  CHANGE_INVITES_NAME_FILTER,
  LOADING_FILTER_END,
  CHANGE_MANAGERS_PAGE,
  CHANGE_INVITES_PAGE,
  CHANGE_MANAGERS_SORT,
  CHANGE_INVITES_SORT,
  GET_BILLING_PLANS_LIST_REQUEST,
  GET_BILLING_PLANS_LIST_REQUEST_SUCCESS,
  GET_BILLING_PLANS_LIST_REQUEST_FAIL,
  OPEN_PROLONG_PLAN_MODAL,
  CLOSE_PROLONG_PLAN_MODAL,
  PROLONG_PLAN_REQUEST,
  PROLONG_PLAN_REQUEST_SUCCESS,
  PROLONG_PLAN_REQUEST_FAIL,
  CHANGE_PROLONG_FLAG,
  CHECK_PROLONG_ORDER_REQUEST,
  CHECK_PROLONG_ORDER_REQUEST_SUCCESS,
  CHECK_PROLONG_ORDER_REQUEST_FAIL,
  OPEN_ACTIVATE_PLAN_MODAL,
  CLOSE_ACTIVATE_PLAN_MODAL,
  ACTIVATE_PLAN_REQUEST,
  ACTIVATE_PLAN_REQUEST_SUCCESS,
  ACTIVATE_PLAN_REQUEST_FAIL,
  CHANGE_PAYMENT_ORGANIZATION,
  CHANGE_PAYMENT_EMAIL,
  CHANGE_PAYMENT_METHOD,
  CHANGE_VISA_POPUP,
  CHANGE_PAYMENT_POPUP,
  CHANGE_PAYMENT_LOADING,
  SEND_PAYMENT_REQUEST,
  SEND_PAYMENT_REQUEST_SUCCESS,
  SEND_PAYMENT_REQUEST_FAIL,
  SEND_TO_PAYMENT_PAGE,
  PAYMENT_WAS_SEND_CHANGE,
  GET_ORGANIZATIONS_LIST_REQUEST,
  GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS,
  GET_ORGANIZATIONS_LIST_REQUEST_FAIL,
  GET_ORGANIZATION_READ_REQUEST,
  GET_ORGANIZATION_READ_REQUEST_SUCCESS,
  GET_ORGANIZATION_READ_REQUEST_FAIL,
  CHANGE_DELETE_ORGANIZATION_MODALS,
  CHANGE_ORGANIZATION_ID,
  CHANGE_ORGANIZATIONS_FLAGS,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_REQUEST_SUCCESS,
  DELETE_ORGANIZATION_REQUEST_FAIL,
  CHANGE_ORGANIZATION_NAME,
  CHANGE_ORGANIZATION_INN,
  CHANGE_ORGANIZATION_KPP,
  CHANGE_ORGANIZATION_OGRN,
  CHANGE_ORGANIZATION_INDEX,
  CHANGE_ORGANIZATION_ADDRESS,
  CHANGE_ORGANIZATION_ACCOUNT_NUMBER,
  CHANGE_ORGANIZATION_BANK_NAME,
  CHANGE_ORGANIZATION_BANK_BIK,
  CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_REQUEST_SUCCESS,
  CREATE_ORGANIZATION_REQUEST_FAIL,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_REQUEST_SUCCESS,
  EDIT_ORGANIZATION_REQUEST_FAIL,
  CHANGE_INVITE_STAFF_NAME,
  CHANGE_INVITE_STAFF_FULL_NAME,
  CHANGE_INVITE_STAFF_EMAIL,
  CHANGE_INVITE_STAFF_TEXT,
  CHANGE_INVITE_STAFF_COMMENT,
  CHANGE_INVITE_STAFF_FLAGS,
  CHANGE_INVITE_STAFF_MODALS,
  SEND_INVITE_STAFF_REQUEST,
  SEND_INVITE_STAFF_REQUEST_SUCCESS,
  SEND_INVITE_STAFF_REQUEST_FAIL,
  SEND_INVITE_STAFF_BY_FILE_REQUEST,
  SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS,
  SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL,
  CHANGE_INVITE_STAFF_BY_FILE_FLAGS
} from './constants';

export function changeInviteStaffByFileFlags(payload) {
  return {
    type: CHANGE_INVITE_STAFF_BY_FILE_FLAGS,
    ...payload
  };
}

export function sendInviteStaffByFile(payload) {
  return {
    type: SEND_INVITE_STAFF_BY_FILE_REQUEST,
    ...payload
  };
}

export function sendInviteStaffByFileSuccess(payload) {
  return {
    type: SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS,
    ...payload
  };
}

export function sendInviteStaffByFileFail(payload) {
  return {
    type: SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL,
    ...payload
  };
}

export function changeInviteStaffName(payload) {
  return {
    type: CHANGE_INVITE_STAFF_NAME,
    ...payload
  };
}

export function changeInviteStaffFullName(payload) {
  return {
    type: CHANGE_INVITE_STAFF_FULL_NAME,
    ...payload
  };
}

export function changeInviteStaffEmail(payload) {
  return {
    type: CHANGE_INVITE_STAFF_EMAIL,
    ...payload
  };
}

export function changeInviteStaffText(payload) {
  return {
    type: CHANGE_INVITE_STAFF_TEXT,
    ...payload
  };
}

export function changeInviteStaffComment(payload) {
  return {
    type: CHANGE_INVITE_STAFF_COMMENT,
    ...payload
  };
}

export function changeInviteStaffFlags(payload) {
  return {
    type: CHANGE_INVITE_STAFF_FLAGS,
    ...payload
  };
}

export function changeInviteStaffModals(payload) {
  return {
    type: CHANGE_INVITE_STAFF_MODALS,
    ...payload
  };
}

export function sendInviteStaff() {
  return {
    type: SEND_INVITE_STAFF_REQUEST
  };
}

export function sendInviteStaffSuccess() {
  return {
    type: SEND_INVITE_STAFF_REQUEST_SUCCESS
  };
}

export function sendInviteStaffFail() {
  return {
    type: SEND_INVITE_STAFF_REQUEST_FAIL
  };
}

export function changePaymentOrganization(payload) {
  return {
    type: CHANGE_PAYMENT_ORGANIZATION,
    ...payload
  };
}

export function changeOrganizationsFlags(payload) {
  return {
    type: CHANGE_ORGANIZATIONS_FLAGS,
    ...payload
  };
}

export function editOrganization() {
  return {
    type: EDIT_ORGANIZATION_REQUEST
  };
}

export function editOrganizationSuccess() {
  return {
    type: EDIT_ORGANIZATION_REQUEST_SUCCESS
  };
}

export function editOrganizationFail() {
  return {
    type: EDIT_ORGANIZATION_REQUEST_FAIL
  };
}

export function createOrganization() {
  return {
    type: CREATE_ORGANIZATION_REQUEST
  };
}

export function createOrganizationSuccess() {
  return {
    type: CREATE_ORGANIZATION_REQUEST_SUCCESS
  };
}

export function createOrganizationFail() {
  return {
    type: CREATE_ORGANIZATION_REQUEST_FAIL
  };
}

export function changeOrganizationName(payload) {
  return {
    type: CHANGE_ORGANIZATION_NAME,
    ...payload
  };
}

export function changeOrganizationInn(payload) {
  return {
    type: CHANGE_ORGANIZATION_INN,
    ...payload
  };
}

export function changeOrganizationKpp(payload) {
  return {
    type: CHANGE_ORGANIZATION_KPP,
    ...payload
  };
}

export function changeOrganizationOgrn(payload) {
  return {
    type: CHANGE_ORGANIZATION_OGRN,
    ...payload
  };
}

export function changeOrganizationIndex(payload) {
  return {
    type: CHANGE_ORGANIZATION_INDEX,
    ...payload
  };
}

export function changeOrganizationAddress(payload) {
  return {
    type: CHANGE_ORGANIZATION_ADDRESS,
    ...payload
  };
}

export function changeOrganizationAccountNumber(payload) {
  return {
    type: CHANGE_ORGANIZATION_ACCOUNT_NUMBER,
    ...payload
  };
}

export function changeOrganizationBankName(payload) {
  return {
    type: CHANGE_ORGANIZATION_BANK_NAME,
    ...payload
  };
}

export function changeOrganizationBankBik(payload) {
  return {
    type: CHANGE_ORGANIZATION_BANK_BIK,
    ...payload
  };
}

export function changeOrganizationCorrespondentAccountNumber(payload) {
  return {
    type: CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER,
    ...payload
  };
}

export function changeOrganizationId(payload) {
  return {
    type: CHANGE_ORGANIZATION_ID,
    ...payload
  };
}

export function getOrganizationRead(payload) {
  return {
    type: GET_ORGANIZATION_READ_REQUEST,
    ...payload
  };
}

export function getOrganizationReadSuccess(payload) {
  return {
    type: GET_ORGANIZATION_READ_REQUEST_SUCCESS,
    ...payload
  };
}

export function getOrganizationReadFail() {
  return {
    type: GET_ORGANIZATION_READ_REQUEST_FAIL
  };
}

export function deleteOrganization(payload) {
  return {
    type: DELETE_ORGANIZATION_REQUEST,
    ...payload
  };
}

export function deleteOrganizationSuccess() {
  return {
    type: DELETE_ORGANIZATION_REQUEST_SUCCESS
  };
}

export function deleteOrganizationFail() {
  return {
    type: DELETE_ORGANIZATION_REQUEST_FAIL
  };
}

export function changeDeleteOrganizationModals(payload) {
  return {
    type: CHANGE_DELETE_ORGANIZATION_MODALS,
    ...payload
  };
}

export function getOrganizationsList(payload) {
  return {
    type: GET_ORGANIZATIONS_LIST_REQUEST,
    ...payload
  };
}

export function getOrganizationsListSuccess(payload) {
  return {
    type: GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getOrganizationsListFail() {
  return {
    type: GET_ORGANIZATIONS_LIST_REQUEST_FAIL
  };
}

export function changePaymentWasSend(payload) {
  return {
    type: PAYMENT_WAS_SEND_CHANGE,
    ...payload
  };
}

export function sendToPaymentPage(payload) {
  return {
    type: SEND_TO_PAYMENT_PAGE,
    ...payload
  };
}

export function sendPayment() {
  return {
    type: SEND_PAYMENT_REQUEST
  };
}

export function sendPaymentSuccess() {
  return {
    type: SEND_PAYMENT_REQUEST_SUCCESS
  };
}

export function sendPaymentFail() {
  return {
    type: SEND_PAYMENT_REQUEST_FAIL
  };
}

export function changePaymentLoading(payload) {
  return {
    type: CHANGE_PAYMENT_LOADING,
    ...payload
  };
}

export function changePaymentEmail(payload) {
  return {
    type: CHANGE_PAYMENT_EMAIL,
    ...payload
  };
}

export function changePaymentMethod(payload) {
  return {
    type: CHANGE_PAYMENT_METHOD,
    ...payload
  };
}

export function changeVisaPopup(payload) {
  return {
    type: CHANGE_VISA_POPUP,
    ...payload
  };
}

export function changePaymentPopup(payload) {
  return {
    type: CHANGE_PAYMENT_POPUP,
    ...payload
  };
}

export function openActivatePlanModal(payload) {
  return {
    type: OPEN_ACTIVATE_PLAN_MODAL,
    ...payload
  };
}

export function closeActivatePlanModal() {
  return {
    type: CLOSE_ACTIVATE_PLAN_MODAL
  };
}

export function activatePlan() {
  return {
    type: ACTIVATE_PLAN_REQUEST
  };
}

export function activatePlanSuccess(payload) {
  return {
    type: ACTIVATE_PLAN_REQUEST_SUCCESS,
    ...payload
  };
}

export function activatePlanFail() {
  return {
    type: ACTIVATE_PLAN_REQUEST_FAIL
  };
}

export function changeProlongFlag(payload) {
  return {
    type: CHANGE_PROLONG_FLAG,
    ...payload
  };
}

export function checkOrder(payload) {
  return {
    type: CHECK_PROLONG_ORDER_REQUEST,
    ...payload
  };
}

export function checkOrderSuccess() {
  return {
    type: CHECK_PROLONG_ORDER_REQUEST_SUCCESS
  };
}

export function checkOrderFail() {
  return {
    type: CHECK_PROLONG_ORDER_REQUEST_FAIL
  };
}

export function prolongPlan() {
  return {
    type: PROLONG_PLAN_REQUEST
  };
}

export function prolongPlanSuccess() {
  return {
    type: PROLONG_PLAN_REQUEST_SUCCESS
  };
}

export function prolongPlanFail() {
  return {
    type: PROLONG_PLAN_REQUEST_FAIL
  };
}

export function openProlongPlanModal() {
  return {
    type: OPEN_PROLONG_PLAN_MODAL
  };
}

export function closeProlongPlanModal() {
  return {
    type: CLOSE_PROLONG_PLAN_MODAL
  };
}

export function billingPlansList() {
  return {
    type: GET_BILLING_PLANS_LIST_REQUEST
  };
}

export function billingPlansListSuccess(payload) {
  return {
    type: GET_BILLING_PLANS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function billingPlansListFail() {
  return {
    type: GET_BILLING_PLANS_LIST_REQUEST_FAIL
  };
}

export function changeInvitesNameFilter(payload) {
  return {
    type: CHANGE_INVITES_NAME_FILTER,
    ...payload
  };
}

export function changeManagersSort(payload) {
  return {
    type: CHANGE_MANAGERS_SORT,
    ...payload
  };
}

export function changeInvitesSort(payload) {
  return {
    type: CHANGE_INVITES_SORT,
    ...payload
  };
}

export function changeManagersPage(payload) {
  return {
    type: CHANGE_MANAGERS_PAGE,
    ...payload
  };
}

export function changeInvitesPage(payload) {
  return {
    type: CHANGE_INVITES_PAGE,
    ...payload
  };
}

export function loadingFilterFinish() {
  return {
    type: LOADING_FILTER_END
  };
}

export function changeNameFilter(payload) {
  return {
    type: CHANGE_NAME_FILTER,
    ...payload
  };
}

export function getUserInfo() {
  return {
    type: GET_USER_INFO_REQUEST
  };
}

export function getUserInfoSuccess(payload) {
  return {
    type: GET_USER_INFO_REQUEST_SUCCESS,
    ...payload
  };
}

export function getUserInfoFail() {
  return {
    type: GET_USER_INFO_REQUEST_FAIL
  };
}

export function getManagersList() {
  return {
    type: GET_MANAGERS_LIST_REQUEST
  };
}

export function getManagersFilteredList() {
  return {
    type: GET_MANAGERS_FILTERED_LIST_REQUEST
  };
}

export function getManagersListSuccess(payload) {
  return {
    type: GET_MANAGERS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getManagersListFail() {
  return {
    type: GET_MANAGERS_LIST_REQUEST_FAIL
  };
}

export function sendInvite() {
  return {
    type: SEND_INVITE_REQUEST
  };
}

export function sendInviteSuccess(payload) {
  return {
    type: SEND_INVITE_REQUEST_SUCCESS,
    ...payload
  };
}

export function sendInviteFail() {
  return {
    type: SEND_INVITE_REQUEST_FAIL
  };
}

export function changeCommentInvite(payload) {
  return {
    type: CHANGE_COMMENT_INVITE,
    ...payload
  };
}

export function changeFlagsInvite(payload) {
  return {
    type: CHANGE_FLAGS_INVITE,
    ...payload
  };
}

export function changeFullNameInvite(payload) {
  return {
    type: CHANGE_FULL_NAME_INVITE,
    ...payload
  };
}

export function changeEmailInvite(payload) {
  return {
    type: CHANGE_EMAIL_INVITE,
    ...payload
  };
}

export function changeModalsInvite(payload) {
  return {
    type: CHANGE_MODALS_INVITE,
    ...payload
  };
}

export function changeNameInvite(payload) {
  return {
    type: CHANGE_NAME_INVITE,
    ...payload
  };
}

export function changeRoleInvite(payload) {
  return {
    type: CHANGE_ROLE_INVITE,
    ...payload
  };
}

export function changeTextInvite(payload) {
  return {
    type: CHANGE_TEXT_INVITE,
    ...payload
  };
}

export function getInvitesList() {
  return {
    type: GET_INVITES_LIST_REQUEST
  };
}

export function getInvitesListSuccess(payload) {
  return {
    type: GET_INVITES_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getInvitesListFail() {
  return {
    type: GET_INVITES_LIST_REQUEST_FAIL
  };
}

export function changeDeleteInviteModals(payload) {
  return {
    type: CHANGE_DELETE_INVITE_MODALS,
    ...payload
  };
}

export function deleteInvite(payload) {
  return {
    type: DELETE_INVITE_REQUEST,
    ...payload
  };
}

export function deleteInviteSuccess() {
  return {
    type: DELETE_INVITE_REQUEST_SUCCESS
  };
}

export function deleteInviteFail() {
  return {
    type: DELETE_INVITE_REQUEST_FAIL
  };
}
