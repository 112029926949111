import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerRequisites');

const makeSelectRequisitesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'count'])
  );

const makeSelectRequisitesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'list']).toJS()
  );

const makeSelectRequisitesLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'loading'])
  );

const makeSelectRequisitesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'page'])
  );

const makeSelectRegistrationFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'flags']).toJS()
  );

const makeSelectRegistrationForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'form']).toJS()
  );

const makeSelectRegistrationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'loading'])
  );

const makeSelectOrganizationFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['organization', 'flags']).toJS()
  );

const makeSelectOrganizationForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['organization', 'form']).toJS()
  );

const makeSelectOrganizationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['organization', 'loading'])
  );

const makeSelectOrganizationRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['organization', 'read']).toJS()
  );

const makeSelectOrganizationUpdateLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['organization', 'updateLoading'])
  );

const makeSelectDelete = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('delete').toJS()
  );

export {
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage,
  makeSelectRegistrationFlags,
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading,
  makeSelectOrganizationFlags,
  makeSelectOrganizationForm,
  makeSelectOrganizationLoading,
  makeSelectOrganizationRead,
  makeSelectOrganizationUpdateLoading,
  makeSelectDelete
};
