import React from 'react';

const Arrow = () => (
  <div>
    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="55" transform="translate(-903.000000, -297.000000)">
          <rect fill="transparent" x="0" y="0" width="1366" height="768" />
          <g
            id="answer"
            transform="translate(433.000000, 268.000000)"
            stroke="#9FB0CC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <g id="arrows" transform="translate(462.000000, 24.000000)">
              <polyline id="Chevron" points="9 15 12.5 18 16 15" />
              <polyline
                id="Chevron"
                transform="translate(12.500000, 7.500000) scale(1, -1) translate(-12.500000, -7.500000) "
                points="9 6 12.5 9 16 6"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default Arrow;
