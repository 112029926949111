import React, { PureComponent } from 'react';
import isNull from 'lodash/isNull';

import Text from './styled/Text';
import Wrapper from './styled/Wrapper';

class MetaWithComment extends PureComponent {
  renderHighlightsTemplate = item => {
    const { createEditCommentBlock, subsection, sectionId } = this.props;

    const subsectionId = subsection.get('id');
    const subsectionText = subsection.get('text');

    const showCommentBlock = event => {
      const { subsection } = this.props;
      const commentId = item.id;
      const comment = subsection.get('comments').find(comment => comment.get('id') === commentId);
      const rect = event.target.getBoundingClientRect();

      const x = rect.left + rect.width + window.scrollX - 180;
      const y = rect.top + rect.height + window.scrollY - 10;

      createEditCommentBlock({
        id: comment.get('id'),
        x,
        y,
        text: comment.get('comment'),
        textRangeFrom: comment.getIn(['selected_text_range', 'lower']),
        textRangeTo: comment.getIn(['selected_text_range', 'upper']),
        selectedText: comment.get('selected_text'),
        sectionId,
        subsectionId
      });
    };

    if (item.highlight) {
      return (
        <span
          className="highlight"
          key={`${item.id}-${item.from}-${item.to}`}
          onClick={showCommentBlock}
        >
          {subsectionText.slice(item.from, item.to)}
        </span>
      );
    }

    return (
      <span key={`${item.id}-${item.from}-${item.to}`}>
        {subsectionText.slice(item.from, item.to)}
      </span>
    );
  };

  render() {
    const { subsection, sectionId } = this.props;

    const subsectionText = subsection.get('text');
    let highlightIntervals = [];
    const highlightsTemplate = [];
    if (subsection.get('comments').size > 0) {
      subsection
        .get('comments')
        .sortBy(comment => comment.getIn(['selected_text_range', 'lower']))
        .forEach(comment => {
          highlightIntervals.push({
            id: comment.get('id'),
            from: comment.getIn(['selected_text_range', 'lower']),
            to: comment.getIn(['selected_text_range', 'upper']),
            highlight: true
          });
        });

      let i = 0;
      let j = null;
      while (i < subsectionText.length) {
        const item = highlightIntervals[0];
        if (highlightIntervals.length > 0 && i === item.from) {
          if (!isNull(j)) {
            highlightsTemplate.push({
              from: j,
              to: i,
              highlight: false
            });
          }

          highlightsTemplate.push({
            id: item.id,
            from: item.from,
            to: item.to,
            highlight: true
          });

          i = item.to;
          j = null;
          highlightIntervals.splice(0, 1);
        } else {
          if (isNull(j)) {
            j = i;
          }
          i += 1;

          if (i === subsectionText.length) {
            highlightsTemplate.push({
              from: j,
              to: i,
              highlight: false
            });
          }
        }
      }
    }

    let text = <span>{subsection.get('text')}</span>;
    if (highlightsTemplate.length > 0) {
      text = highlightsTemplate.map(highlight => this.renderHighlightsTemplate(highlight));
    }

    return (
      <Wrapper>
        <Text
          id={`id-${sectionId}-${subsection.get('id')}`}
          className="subsection-text"
          data-section-id={sectionId}
          data-subsection-id={subsection.get('id')}
        >
          {text}
        </Text>
      </Wrapper>
    );
  }
}

export default MetaWithComment;
