import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

import Container from './styled/Container';
import Image from './styled/Image';
import Header from './styled/Header';
import P from './styled/P';

const Empty = props => {
  let paragraph = null;

  if (!isUndefined(props.text)) {
    paragraph = <P>{props.text}</P>;
  }
  return (
    <Container>
      <Image />
      <Header>{props.header}</Header>
      {paragraph}
      {props.children}
    </Container>
  );
};

Empty.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
  text: PropTypes.string
};

export default Empty;
