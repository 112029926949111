import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/ru';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import NormalButton from '@atlaskit/button';
import NormalFlag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import styled from 'styled-components';
import Form from '@atlaskit/form';
import TextField from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';

import Modal from 'components/Modal';
import RouterLink from 'components/RouterLink';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const NameWrapper = styled.span`
  display: flex;
  align-items: center;
  height: 40px;
`;

const Flag = styled(NormalFlag)`
  background-color: green !important;
`;

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
`;

const Field = styled.div`
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'inherit')};
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};
`;

const Button = styled(NormalButton)`
  &.allWidth {
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;

const Text = styled.p`
  align-self: center;
  margin: 20px 0 0;
`;

const Line = styled.div`
  align-self: center;
  background-color: #e2e3e7;
  height: 1px;
  margin: 20px 0 0;
  width: 150px;
`;

const TriggerButton = <Button>...</Button>;

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'name',
        content: 'Профиль',
        isSortable: true,
        width: withWidth ? 27 : undefined
      },
      {
        key: 'hr',
        content: 'HR-менеджер',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 25 : undefined
      },
      {
        key: 'created_at',
        content: 'Создан',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 22 : undefined
      },
      {
        key: 'updated',
        content: 'Изменен',
        shouldTruncate: true,
        width: withWidth ? 22 : undefined
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class Table extends React.Component {
  state = {
    deleteModals: [],
    sendModals: [],
    flags: [],
    sendFlags: []
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      deleteModals: this.state.deleteModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  closeModal = id => {
    this.setState({ deleteModals: this.state.deleteModals.filter(i => i !== id) });
  };

  closeEditModal = id => {
    this.setState({ sendModals: this.state.sendModals.filter(i => i !== id) });
  };

  addSendFlag = id => {
    this.setState({
      sendFlags: [this.state.flags.length, ...this.state.flags],
      sendModals: this.state.sendModals.filter(i => i !== id)
    });
  };

  removeSendFlag = id => this.setState({ sendFlags: this.state.sendFlags.filter(v => v !== id) });

  onDelete = id => {
    this.addFlag(id);
    this.props.onDelete({
      profileId: id
    });
  };

  render() {
    const rowsTable = this.props.data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: createKey(item.name),
          content: (
            <NameWrapper>
              <Link to={`/profile/${item.id}`}>{item.name}</Link>
            </NameWrapper>
          )
        },
        {
          key: createKey(item.owner.full_name),
          content: item.owner.full_name
        },
        {
          key: createKey(item.created_at),
          content: moment(item.created_at).format('DD.MM.YYYY HH:mm')
        },
        {
          key: createKey(item.updated_at),
          content: moment(item.updated_at).format('DD.MM.YYYY HH:mm')
        },
        {
          content: (
            <DropdownMenu trigger={TriggerButton} position="bottom right">
              <DropdownItemGroup>
                <DropdownItem
                  onClick={() =>
                    this.setState({
                      deleteModals: [item.id]
                    })
                  }
                >
                  Удалить
                </DropdownItem>
                <DropdownItem href={`/profile/edit/${item.id}`} linkComponent={RouterLink}>
                  Изменить
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )
        }
      ]
    }));

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          paginationi18n={{ prev: 'Предыдущая', next: 'Следующая' }}
          rows={rowsTable}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {this.state.deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить профиль"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Удалить',
                onClick: this.onDelete
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>Уверены, что хотите удалить профиль?</p>
          </Modal>
        ))}

        {this.state.sendModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Отправить тест"
            onClose={this.closeEditModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.addSendFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeEditModal
              }
            ]}
            width="small"
          >
            <p>Отправьте или скопируйте ссылку</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup layout="column" form="">
                <Field width="65%">
                  <TextField
                    label="Скопируйте ссылку"
                    shouldFitContainer
                    name="link"
                    placeholder=""
                    isReadOnly
                    value="http://profgrowth.com/test/12345t"
                  />
                </Field>
                <Field width="32%" alignSelf="flex-end">
                  <Button className="allWidth">Скопировать</Button>
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Line />
                <Text>или</Text>
                <Line />
              </FieldGroup>
              <FieldGroup layout="column" form="">
                <Field width="100%">
                  <TextField
                    label="Отправьте тестирование"
                    shouldFitContainer
                    name="email"
                    placeholder=""
                    value="petrov@mail.ru"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup marginTop="8px">
                <Field width="100%">
                  <TextArea
                    shouldFitContainer
                    name="message"
                    placeholder="Добавьте сообщение (необязательно)"
                    value=""
                    isLabelHidden
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Профиль удален"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSendFlag(name)}>
          {this.state.sendFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Ссылка отправлена"
            />
          ))}
        </FlagGroup>
      </div>
    );
  }
}

export default Table;
