import styled from 'styled-components';

const ReadIndicator = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%
  background-color: #0052CC;
`;

export default ReadIndicator;
