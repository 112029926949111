import styled from 'styled-components';

const Column = styled.div`
  width: 560px;

  @media (max-width: 1020px) {
    margin: 20px 0;
    width: 100%;
  }
`;

export default Column;
