import styled from "styled-components";

const ArrowWrapper = styled.button`
  background: none;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  border: none;
  cursor: pointer;
  padding: 5px;
  outline: none;
`;

export default ArrowWrapper;
