import { fromJS } from 'immutable';

import {
  LOADING_TABLE_END,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_FILTER_REQUEST,
  PROFILE_LIST_REQUEST_SUCCESS,
  PROFILE_LIST_REQUEST_FAIL,
  PROFILE_READ_REQUEST,
  PROFILE_READ_REQUEST_SUCCESS,
  PROFILE_READ_REQUEST_FAIL,
  CHANGE_REPORTS_PAGE,
  CHANGE_REPORTS_SORT,
  CHANGE_REPORTS_NAME,
  PROFILE_REPORTS_REQUEST,
  PROFILE_REPORTS_REQUEST_SUCCESS,
  PROFILE_REPORTS_REQUEST_FAIL,
  CREATE_COMPARE_REQUEST,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL,
  CHANGE_STAFF_INPUT,
  STAFF_LIST_REQUEST,
  STAFF_LIST_REQUEST_SUCCESS,
  STAFF_LIST_REQUEST_FAIL,
  CHANGE_STAFF_POSITIVE_INPUT,
  POSITIVE_STAFF_LIST_REQUEST,
  POSITIVE_STAFF_LIST_REQUEST_SUCCESS,
  POSITIVE_STAFF_LIST_REQUEST_FAIL,
  CHANGE_STAFF_NEGATIVE_INPUT,
  NEGATIVE_STAFF_LIST_REQUEST,
  NEGATIVE_STAFF_LIST_REQUEST_SUCCESS,
  NEGATIVE_STAFF_LIST_REQUEST_FAIL,
  CHANGE_NAME_CREATE_FROM_SAMPLE,
  CHANGE_DESC_CREATE_FROM_SAMPLE,
  CHANGE_NEGATIVE_CREATE_FROM_SAMPLE,
  CHANGE_POSITIVE_CREATE_FROM_SAMPLE,
  CHANGE_PATTERN_NAME,
  CHANGE_PATTERN_DESC,
  CHANGE_PATTERN_RANGE_LOWER,
  CHANGE_PATTERN_RANGE_UPPER,
  CHANGE_PATTERN_EDIT_CRITICAL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_REQUEST_SUCCESS,
  PROFILE_UPDATE_REQUEST_FAIL,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_REQUEST_SUCCESS,
  QUESTIONS_LIST_REQUEST_FAIL,
  CHANGE_NAME_CREATE_FROM_QUESTIONS,
  CHANGE_DESC_CREATE_FROM_QUESTIONS,
  CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL,
  PROFILE_CREATE_CUSTOM_REQUEST,
  PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS,
  PROFILE_CREATE_CUSTOM_REQUEST_FAIL,
  CHANGE_PATTERN_CREATED_RANGE_LOWER,
  CHANGE_PATTERN_CREATED_RANGE_UPPER,
  CHANGE_PATTERN_CREATED_CRITICAL,
  CHANGE_PROFILE_FOR_UPDATE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_REQUEST_SUCCESS,
  DELETE_PROFILE_REQUEST_FAIL,
  CHANGE_PROFILE_NAME_CUSTOM,
  CHANGE_PROFILE_DESC_CUSTOM,
  CHANGE_PATTERN_CUSTOM_RANGE_LOWER,
  CHANGE_PATTERN_CUSTOM_RANGE_UPPER,
  CHANGE_PATTERN_CRITICAL,
  META_PROGRAMS_LIST_REQUEST,
  META_PROGRAMS_LIST_REQUEST_SUCCESS,
  META_PROGRAMS_LIST_REQUEST_FAIL,
  CHANGE_NAME_FILTER,
  CHANGE_MANAGERS_FILTER,
  CHANGE_PROFILE_PAGE,
  CHANGE_PROFILE_SORT,
  CHANGE_PROFILE_CHIEF_NAME,
  CHANGE_PROFILE_CHIEF_DESC,
  CHANGE_PROFILE_CHIEF_EMAIL,
  CHANGE_PROFILE_CHIEF_FLAGS,
  CHANGE_PROFILE_CHIEF_FULL_NAME,
  CHANGE_PROFILE_CHIEF_MESSAGE,
  SEND_PROFILE_CHIEF_REQUEST,
  SEND_PROFILE_CHIEF_REQUEST_SUCCESS,
  SEND_PROFILE_CHIEF_REQUEST_FAIL,
  CHANGE_PROFILE_EMAIL_FLAGS,
  CHANGE_PROFILE_EMAIL_MODALS,
  CHANGE_PROFILE_EMAIL_ADDRESS,
  SEND_PROFILE_EMAIL_REQUEST,
  SEND_PROFILE_EMAIL_REQUEST_SUCCESS,
  SEND_PROFILE_EMAIL_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: false,
  loadingNegative: false,
  loadingPositive: false,
  loadingReportsTable: false,
  loadingStaff: false,
  loadingTable: false,
  managerFilter: 0,
  metaProgramsList: [],
  nameFilter: '',
  negativeInput: '',
  negativeList: [],
  positiveInput: '',
  positiveList: [],
  profileChief: {
    name: '',
    desc: '',
    email: '',
    flags: [],
    fullName: '',
    loading: false,
    message: ''
  },
  profileCount: 0,
  profileCreated: {
    created_at: '',
    description: '',
    id: 0,
    name: '',
    owner: {
      email: '',
      first_name: '',
      full_name: '',
      id: 0,
      last_name: '',
      phone_number: '',
      role: 0
    },
    updated_at: ''
  },
  profileCompare: null,
  profileCustom: {
    name: '',
    description: '',
    pattern_ranges: []
  },
  profileEmail: {
    profileId: 0,
    flags: [],
    modals: [],
    email: '',
    loading: false
  },
  profileForUpdate: 'read',
  profileId: null,
  profileList: [],
  profilePage: 1,
  profileQuestionsName: '',
  profileQuestionsDesc: '',
  profileQuestions: {
    name: '',
    description: '',
    answers: []
  },
  profileRead: {
    created_at: '',
    description: '',
    id: 0,
    newName: '',
    name: '',
    owner: {
      email: '',
      first_name: '',
      full_name: '',
      id: 0,
      last_name: '',
      phone_number: '',
      role: 0
    },
    updated_at: ''
  },
  profileReports: [],
  profileReportsCount: 0,
  profileReportsFilter: '',
  profileReportsPage: 1,
  profileReportsSort: {
    key: '',
    order: ''
  },
  profileSample: {
    name: '',
    description: '',
    negativeSamples: [],
    positiveSamples: []
  },
  sort: {
    key: '',
    order: ''
  },
  staffCompare: [],
  staffInput: '',
  staffList: []
});

function profileReducer(state = initialState, action) {
  switch (action.type) {
    //CHANGE_PROFILE_EMAIL_FLAGS,
    //   CHANGE_PROFILE_EMAIL_MODALS,
    //   CHANGE_PROFILE_EMAIL_ADDRESS,
    //
    //   SEND_PROFILE_EMAIL_REQUEST,
    //   SEND_PROFILE_EMAIL_REQUEST_SUCCESS,
    //   SEND_PROFILE_EMAIL_REQUEST_FAIL,
    case CHANGE_PROFILE_EMAIL_FLAGS:
      return state.setIn(['profileEmail', 'flags'], fromJS(action.flags));
    case CHANGE_PROFILE_EMAIL_MODALS:
      return state.setIn(['profileEmail', 'modals'], fromJS(action.modals));
    case CHANGE_PROFILE_EMAIL_ADDRESS:
      return state.setIn(['profileEmail', 'email'], action.email);
    case SEND_PROFILE_EMAIL_REQUEST:
      return state
        .setIn(['profileEmail', 'profileId'], action.id)
        .setIn(['profileEmail', 'loading'], true);
    case SEND_PROFILE_EMAIL_REQUEST_SUCCESS:
      return state
        .setIn(['profileEmail', 'flags'], fromJS([1]))
        .setIn(['profileEmail', 'loading'], false)
        .setIn(['profileEmail', 'modals'], fromJS([]))
        .setIn(['profileEmail', 'email'], '');
    case SEND_PROFILE_EMAIL_REQUEST_FAIL:
      return state
        .setIn(['profileEmail', 'flags'], fromJS([-1]))
        .setIn(['profileEmail', 'loading'], false)
        .setIn(['profileEmail', 'modals'], fromJS([]));
    case CHANGE_PROFILE_CHIEF_NAME:
      return state.setIn(['profileChief', 'name'], action.name);
    case CHANGE_PROFILE_CHIEF_DESC:
      return state.setIn(['profileChief', 'desc'], action.desc);
    case CHANGE_PROFILE_CHIEF_EMAIL:
      return state.setIn(['profileChief', 'email'], action.email);
    case CHANGE_PROFILE_CHIEF_FLAGS:
      return state.setIn(['profileChief', 'flags'], fromJS(action.flags));
    case CHANGE_PROFILE_CHIEF_FULL_NAME:
      return state.setIn(['profileChief', 'fullName'], action.fullName);
    case CHANGE_PROFILE_CHIEF_MESSAGE:
      return state.setIn(['profileChief', 'message'], action.message);
    case SEND_PROFILE_CHIEF_REQUEST:
      return state.setIn(['profileChief', 'loading'], true);
    case SEND_PROFILE_CHIEF_REQUEST_SUCCESS:
      return state
        .setIn(['profileChief', 'flags'], fromJS([1]))
        .setIn(['profileChief', 'loading'], false);
    case SEND_PROFILE_CHIEF_REQUEST_FAIL:
      return state
        .setIn(['profileChief', 'flags'], fromJS([-1]))
        .setIn(['profileChief', 'loading'], false);
    case CHANGE_PROFILE_SORT:
      return state
        .setIn(['sort', 'key'], action.key)
        .setIn(['sort', 'order'], action.order)
        .set('profilePage', 1);
    case CHANGE_PROFILE_PAGE:
      return state.set('profilePage', action.profilePage);
    case CHANGE_NAME_FILTER:
      return state.set('nameFilter', action.nameFilter).set('profilePage', 1);
    case CHANGE_MANAGERS_FILTER:
      return state.set('managerFilter', action.managerFilter).set('profilePage', 1);
    case PROFILE_LIST_FILTER_REQUEST:
      return state.set('loadingTable', true);
    case PROFILE_LIST_REQUEST:
      return state.set('loading', true);
    case PROFILE_LIST_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('profileList', action.profileList)
        .set('profileCount', action.profileCount);
    case PROFILE_LIST_REQUEST_FAIL:
      return state.set('loading', false);
    case LOADING_TABLE_END:
      return state.set('loadingTable', false);

    case PROFILE_READ_REQUEST:
      return state
        .set('loading', true)
        .set('loadingReportsTable', true)
        .set('profileId', action.profileId);
    case PROFILE_READ_REQUEST_SUCCESS:
      return state.set('loading', false).set('profileRead', action.profileRead);
    case PROFILE_READ_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_REPORTS_PAGE:
      return state
        .set('profileReportsPage', action.profileReportsPage)
        .set('loadingReportsTable', true);
    case CHANGE_REPORTS_SORT:
      return state
        .setIn(['profileReportsSort', 'key'], action.key)
        .setIn(['profileReportsSort', 'order'], action.order)
        .set('profileReportsPage', 1)
        .set('loadingReportsTable', true);
    case CHANGE_REPORTS_NAME:
      return state
        .set('profileReportsFilter', action.profileReportsFilter)
        .set('profileReportsPage', 1)
        .set('loadingReportsTable', true);
    case PROFILE_REPORTS_REQUEST:
      return state.set('loadingReportsTable', true);
    case PROFILE_REPORTS_REQUEST_SUCCESS:
      return state
        .set('profileReports', action.profileReports)
        .set('profileReportsCount', action.profileReportsCount)
        .set('loadingReportsTable', false);
    case PROFILE_REPORTS_REQUEST_FAIL:
      return state.set('loadingReportsTable', false);

    case CREATE_COMPARE_REQUEST:
      return state
        .set('profileCompare', action.profileCompare)
        .set('staffCompare', fromJS(action.staffCompare));

    case PROFILE_CREATE_FROM_SAMPLE_REQUEST:
      return state.set('loading', true);
    case PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS:
      return state.set('loading', false).set('profileCreated', action.profileCreated);
    case PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_STAFF_INPUT:
      return state.set('staffInput', action.input).set('loadingStaff', true);
    case STAFF_LIST_REQUEST:
      return state.set('loadingStaff', true);
    case STAFF_LIST_REQUEST_SUCCESS:
      return state.set('loadingStaff', false).set('staffList', action.staffList);
    case STAFF_LIST_REQUEST_FAIL:
      return state.set('loadingStaff', false);

    case CHANGE_STAFF_POSITIVE_INPUT:
      return state.set('positiveInput', action.input).set('loadingPositive', true);
    case POSITIVE_STAFF_LIST_REQUEST:
      return state.set('loadingPositive', true);
    case POSITIVE_STAFF_LIST_REQUEST_SUCCESS:
      return state.set('loadingPositive', false).set('positiveList', fromJS(action.list));
    case POSITIVE_STAFF_LIST_REQUEST_FAIL:
      return state.set('loadingPositive', false);

    case CHANGE_STAFF_NEGATIVE_INPUT:
      return state.set('negativeInput', action.input).set('loadingNegative', true);
    case NEGATIVE_STAFF_LIST_REQUEST:
      return state.set('loadingNegative', true);
    case NEGATIVE_STAFF_LIST_REQUEST_SUCCESS:
      return state.set('loadingNegative', false).set('negativeList', fromJS(action.list));
    case NEGATIVE_STAFF_LIST_REQUEST_FAIL:
      return state.set('loadingNegative', false);

    case CHANGE_NAME_CREATE_FROM_SAMPLE:
      return state.setIn(['profileSample', 'name'], action.name);
    case CHANGE_DESC_CREATE_FROM_SAMPLE:
      return state.setIn(['profileSample', 'description'], action.desc);
    case CHANGE_NEGATIVE_CREATE_FROM_SAMPLE:
      return state.setIn(['profileSample', 'negativeSamples'], action.negativeSamples);
    case CHANGE_POSITIVE_CREATE_FROM_SAMPLE:
      return state.setIn(['profileSample', 'positiveSamples'], action.positiveSamples);

    case CHANGE_PATTERN_NAME:
      return state.setIn(['profileRead', 'newName'], action.name);
    case CHANGE_PATTERN_DESC:
      return state.setIn(['profileRead', 'description'], action.desc);
    case CHANGE_PATTERN_RANGE_LOWER:
      return state.setIn(
        ['profileRead', 'meta_programs'],
        state
          .getIn(['profileRead', 'meta_programs'])
          .update(
            state
              .getIn(['profileRead', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .setIn(['range', 'lower'], action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );
    case CHANGE_PATTERN_RANGE_UPPER:
      return state.setIn(
        ['profileRead', 'meta_programs'],
        state
          .getIn(['profileRead', 'meta_programs'])
          .update(
            state
              .getIn(['profileRead', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .setIn(['range', 'upper'], action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );
    case CHANGE_PATTERN_EDIT_CRITICAL:
      return state.setIn(
        ['profileRead', 'meta_programs'],
        state
          .getIn(['profileRead', 'meta_programs'])
          .update(
            state
              .getIn(['profileRead', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .set('is_important', action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );

    case PROFILE_UPDATE_REQUEST:
      return state.set('loading', true);
    case PROFILE_UPDATE_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['profileQuestions', 'name'], '')
        .setIn(['profileQuestions', 'description'], '')
        .setIn(['profileQuestions', 'answers'], fromJS([]))
        .setIn(['profileCustom', 'name'], '')
        .setIn(['profileCustom', 'description'], '')
        .setIn(['profileCustom', 'pattern_ranges'], fromJS([]))
        .setIn(['profileSample', 'name'], '')
        .setIn(['profileSample', 'description'], '')
        .setIn(['profileSample', 'negativeInput'], '')
        .setIn(['profileSample', 'negativeSamples'], fromJS([]))
        .setIn(['profileSample', 'positiveInput'], '')
        .setIn(['profileSample', 'positiveSamples'], fromJS([]));
    case PROFILE_UPDATE_REQUEST_FAIL:
      return state
        .set('loading', false)
        .setIn(['profileQuestions', 'name'], '')
        .setIn(['profileQuestions', 'description'], '')
        .setIn(['profileQuestions', 'answers'], fromJS([]))
        .setIn(['profileCustom', 'name'], '')
        .setIn(['profileCustom', 'description'], '')
        .setIn(['profileCustom', 'pattern_ranges'], fromJS([]))
        .setIn(['profileSample', 'name'], '')
        .setIn(['profileSample', 'description'], '')
        .setIn(['profileSample', 'negativeInput'], '')
        .setIn(['profileSample', 'negativeSamples'], fromJS([]))
        .setIn(['profileSample', 'positiveInput'], '')
        .setIn(['profileSample', 'positiveSamples'], fromJS([]));

    case QUESTIONS_LIST_REQUEST:
      return state.set('loading', true);
    case QUESTIONS_LIST_REQUEST_SUCCESS:
      return state.set('loading', false).setIn(['profileQuestions', 'answers'], action.answers);
    case QUESTIONS_LIST_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_NAME_CREATE_FROM_QUESTIONS:
      return state.set('profileQuestionsName', action.name);
    case CHANGE_DESC_CREATE_FROM_QUESTIONS:
      return state.set('profileQuestionsDesc', action.description);
    case CHANGE_ANSWER_CREATE_FROM_QUESTIONS:
      return state.setIn(
        [
          'profileQuestions',
          'answers',
          state
            .getIn(['profileQuestions', 'answers'])
            .findIndex(question => question.get('id') === action.questionId),
          'answers',
          state
            .getIn(['profileQuestions', 'answers'])
            .find(question => question.get('id') === action.questionId)
            .get('answers')
            .findIndex(answer => answer.get('id') === action.answerId),
          'value'
        ],
        action.value
      );

    case PROFILE_CREATE_FROM_QUESTIONS_REQUEST:
      return state.set('loading', true);
    case PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS:
      return state.set('loading', false).set('profileCreated', action.profileCreated);
    case PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_PATTERN_CREATED_RANGE_LOWER:
      return state.setIn(
        ['profileCreated', 'meta_programs'],
        state
          .getIn(['profileCreated', 'meta_programs'])
          .update(
            state
              .getIn(['profileCreated', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .setIn(['range', 'lower'], action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );

    case CHANGE_PATTERN_CREATED_RANGE_UPPER:
      return state.setIn(
        ['profileCreated', 'meta_programs'],
        state
          .getIn(['profileCreated', 'meta_programs'])
          .update(
            state
              .getIn(['profileCreated', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .setIn(['range', 'upper'], action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );
    case CHANGE_PATTERN_CREATED_CRITICAL:
      return state.setIn(
        ['profileCreated', 'meta_programs'],
        state
          .getIn(['profileCreated', 'meta_programs'])
          .update(
            state
              .getIn(['profileCreated', 'meta_programs'])
              .findIndex(item => item.get('id') === action.programId),
            program => {
              const patternIndex = program
                .get('patterns')
                .findIndex(pattern => pattern.get('id') === action.patternId);
              const pattern = program
                .get('patterns')
                .get(patternIndex)
                .set('is_important', action.value);
              return program.setIn(['patterns', patternIndex], pattern);
            }
          )
      );
    case CHANGE_PROFILE_FOR_UPDATE:
      return state.set('profileForUpdate', action.profileForUpdate);

    case DELETE_PROFILE_REQUEST:
      return state.set('loading', true).set('profileId', action.profileId);
    case DELETE_PROFILE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case DELETE_PROFILE_REQUEST_FAIL:
      return state.set('loading', false);

    case META_PROGRAMS_LIST_REQUEST:
      return state.set('loading', true);
    case META_PROGRAMS_LIST_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('metaProgramsList', action.metaProgramsList)
        .setIn(['profileCustom', 'pattern_ranges'], action.patternRanges);
    case META_PROGRAMS_LIST_REQUEST_FAIL:
      return state.set('loading', false);

    case CHANGE_PROFILE_NAME_CUSTOM:
      return state.setIn(['profileCustom', 'name'], action.name);
    case CHANGE_PROFILE_DESC_CUSTOM:
      return state.setIn(['profileCustom', 'description'], action.desc);
    case CHANGE_PATTERN_CUSTOM_RANGE_LOWER:
      return state.setIn(
        ['profileCustom', 'pattern_ranges'],
        state
          .getIn(['profileCustom', 'pattern_ranges'])
          .update(
            state
              .getIn(['profileCustom', 'pattern_ranges'])
              .findIndex(item => item.get('id') === action.patternId),
            pattern => pattern.setIn(['range', 'lower'], action.value)
          )
      );
    case CHANGE_PATTERN_CUSTOM_RANGE_UPPER:
      return state.setIn(
        ['profileCustom', 'pattern_ranges'],
        state
          .getIn(['profileCustom', 'pattern_ranges'])
          .update(
            state
              .getIn(['profileCustom', 'pattern_ranges'])
              .findIndex(item => item.get('id') === action.patternId),
            pattern => pattern.setIn(['range', 'upper'], action.value)
          )
      );
    case CHANGE_PATTERN_CRITICAL:
      return state.setIn(
        ['profileCustom', 'pattern_ranges'],
        state
          .getIn(['profileCustom', 'pattern_ranges'])
          .update(
            state
              .getIn(['profileCustom', 'pattern_ranges'])
              .findIndex(item => item.get('id') === action.patternId),
            pattern => pattern.set('is_important', action.value)
          )
      );
    case PROFILE_CREATE_CUSTOM_REQUEST:
      return state.set('loading', true);
    case PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS:
      return state.set('loading', false);
    case PROFILE_CREATE_CUSTOM_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default profileReducer;
