import React from 'react';
import Button from '@atlaskit/button';
import { ModalFooter } from '@atlaskit/modal-dialog';

import ButtonWrapper from './styled/ButtonWrapper';

class Footer extends React.Component {
  render() {
    const { onClose, showKeyline } = this.props;

    return (
      <ModalFooter showKeyline={showKeyline}>
        <ButtonWrapper>
          <Button appearance="subtle" onClick={onClose}>
            Отмена
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    );
  }
}

export default Footer;
