import { createSelector } from 'reselect';

const selectGlobal = state => state.get('landing');

const makeSelectName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('name')
  );

const makeSelectEmail = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('email')
  );

const makeSelectText = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('text')
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('flags').toJS()
  );

const makeSelectModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('modals').toJS()
  );

const makeSelectApplyFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('applyFlags').toJS()
  );

const makeSelectApplyLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('applyLoading')
  );

export {
  makeSelectName,
  makeSelectEmail,
  makeSelectText,
  makeSelectLoading,
  makeSelectFlags,
  makeSelectModals,
  makeSelectApplyFlags,
  makeSelectApplyLoading
};
