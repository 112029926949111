import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled/Wrapper';

class CollapsedText extends PureComponent {
  constructor(props) {
    super(props);

    const { isOpened } = this.props;
    this.collapsedText = React.createRef();
    this.state = {
      isOpened
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpened) {
      this.expandText();
    } else {
      this.collapseText();
    }
  }

  collapseText = () => {
    const collapsedElement = this.collapsedText.current;

    collapsedElement.style.height = `${collapsedElement.scrollHeight}px`;

    window.setTimeout(() => {
      collapsedElement.style.height = '0';
    }, 1);

    window.setTimeout(() => {
      this.setState({
        isOpened: false
      });
    }, 400);
  };

  expandText = () => {
    const collapsedElement = this.collapsedText.current;

    const getHeight = () => {
      collapsedElement.style.display = 'block';
      const height = `${collapsedElement.scrollHeight}px`;
      collapsedElement.style.display = '';

      return height;
    };

    const height = getHeight();
    this.setState(
      {
        isOpened: true
      },
      () => {
        collapsedElement.style.height = height;

        window.setTimeout(() => {
          collapsedElement.style.height = '';
        }, 400);
      }
    );
  };

  render() {
    const { children } = this.props;
    const { isOpened } = this.state;

    return (
      <Wrapper isOpened={isOpened} innerRef={this.collapsedText}>
        {children}
      </Wrapper>
    );
  }
}

CollapsedText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOpened: PropTypes.bool.isRequired
};

export default CollapsedText;
