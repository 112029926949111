import styled from 'styled-components';

const getHeightStyles = isOpened => {
  if (isOpened) {
    return `
      height: auto;
    `;
  }

  return `
      height: 0;
    `;
};

const Wrapper = styled.p`
  white-space: pre-line;
  display: block;
  ${props => getHeightStyles(props.isOpened)};
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

export default Wrapper;
