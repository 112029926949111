import { fromJS } from 'immutable';

import {
  VIDEOS_LIST_REQUEST,
  VIDEOS_LIST_REQUEST_SUCCESS,
  VIDEOS_LIST_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: false,
  sections: []
});

function videoReducer(state = initialState, action) {
  switch (action.type) {
    case VIDEOS_LIST_REQUEST:
      return state.set('loading', true);
    case VIDEOS_LIST_REQUEST_SUCCESS:
      return state.set('sections', fromJS(action.sections)).set('loading', false);
    case VIDEOS_LIST_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default videoReducer;
