import {
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  CHANGE_REQUISITES_REGISTRATION_FIELD,
  RESET_REQUISITES_REGISTRATION_FORM,
  REGISTER_NEW_REQUISITES,
  REGISTER_NEW_REQUISITES_SUCCESS,
  REGISTER_NEW_REQUISITES_FAIL,
  CHANGE_FLAGS,
  CHANGE_MODALS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAIL,
  CHANGE_ORGANIZATION_FIELD,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL
} from './constants';

export function getRequisitesList() {
  return {
    type: GET_REQUISITES_LIST
  };
}

export function getRequisitesListSuccess(payload) {
  return {
    type: GET_REQUISITES_LIST_SUCCESS,
    ...payload
  };
}

export function getRequisitesListFail(payload) {
  return {
    type: GET_REQUISITES_LIST_FAIL,
    ...payload
  };
}

export function changeRequisitesPage(payload) {
  return {
    type: CHANGE_REQUISITES_PAGE,
    ...payload
  };
}

export function changeRequisitesRegistrationField(payload) {
  return {
    type: CHANGE_REQUISITES_REGISTRATION_FIELD,
    ...payload
  };
}

export function resetRequisitesRegistrationForm() {
  return {
    type: RESET_REQUISITES_REGISTRATION_FORM
  };
}

export function registerNewRequisites() {
  return {
    type: REGISTER_NEW_REQUISITES
  };
}

export function registerNewRequisitesSuccess() {
  return {
    type: REGISTER_NEW_REQUISITES_SUCCESS
  };
}

export function registerNewRequisitesFail(payload) {
  return {
    type: REGISTER_NEW_REQUISITES_FAIL,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function changeModals(payload) {
  return {
    type: CHANGE_MODALS,
    ...payload
  };
}

export function getOrganization(payload) {
  return {
    type: GET_ORGANIZATION,
    ...payload
  };
}

export function getOrganizationSuccess(payload) {
  return {
    type: GET_ORGANIZATION_SUCCESS,
    ...payload
  };
}

export function getOrganizationFail(payload) {
  return {
    type: GET_ORGANIZATION_FAIL,
    ...payload
  };
}

export function changeOrganizationField(payload) {
  return {
    type: CHANGE_ORGANIZATION_FIELD,
    ...payload
  };
}

export function updateOrganization(payload) {
  return {
    type: UPDATE_ORGANIZATION,
    ...payload
  };
}

export function updateOrganizationSuccess(payload) {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    ...payload
  };
}

export function updateOrganizationFail(payload) {
  return {
    type: UPDATE_ORGANIZATION_FAIL,
    ...payload
  };
}

export function deleteOrganization(payload) {
  return {
    type: DELETE_ORGANIZATION,
    ...payload
  };
}

export function deleteOrganizationSuccess(payload) {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    ...payload
  };
}

export function deleteOrganizationFail(payload) {
  return {
    type: DELETE_ORGANIZATION_FAIL,
    ...payload
  };
}
