import { createSelector } from 'reselect';
import uuid from 'uuid/v1';

const selectGlobal = state => state.get('compareList');

const makeSelectIdsForRequest = () =>
  createSelector(
    selectGlobal,
    globalState => {
      let result = '';

      globalState
        .get('compareIds')
        .toJS()
        .forEach((id, key) => {
          if (key > 0) {
            result += `&ids=${id}`;
          } else {
            result += `ids=${id}`;
          }
        });

      return result;
    }
  );

const makeSelectCompareIds = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareIds').toJS()
  );

const makeSelectCompareResult = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareResult')
  );

const makeSelectCompareLength = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareResult').size
  );

const makeSelectResultTable = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const compareResult = globalState.get('compareResult');
      const sections = globalState.get('sections');

      const result = sections.map(section => ({
        id: section.get('id'),
        title: section.get('title'),
        subsections: section
          .get('subsections')
          .map(subsection => ({
            id: subsection.get('id'),
            title: subsection.get('title'),
            values: (() => {
              const result = [];

              compareResult.valueSeq().forEach(report => {
                result.push({
                  id: uuid(),
                  value: (() => {
                    const foundSection = report
                      .get('sections')
                      .find(compareSection => compareSection.get('title') === section.get('title'))

                    if(!foundSection) {
                      return "-"
                    }
                    return foundSection
                      .get('subsections')
                      .find(
                        compareSubsection =>
                          compareSubsection.get('title') === subsection.get('title')
                      )
                      .get('value')
                  })()
                });
              });

              return result;
            })()
          }))
          .toJS()
      }));

      return result.toJS();
    }
  );

const makeSelectResultStaff = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const compareResult = globalState.get('compareResult');
      const names = [];

      compareResult.valueSeq().forEach(compare => {
        names.push({
          id: compare.get('staffId'),
          fullName: compare.getIn(['staff', 'fullName'])
        });
      });

      return names;
    }
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectAddModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addModal').toJS()
  );

const makeSelectStaffList = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const ids = globalState.get('compareIds').toJS();
      const staffList = globalState.get('staffList').toJS();

      return staffList.filter(staff => ids.indexOf(staff.value) === -1);
    }
  );

const makeSelectStaffInput = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffInput')
  );

const makeSelectStaffListLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffListLoading')
  );

const makeSelectStaffSelector = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffSelector').toJS()
  );

export {
  makeSelectAddModals,
  makeSelectIdsForRequest,
  makeSelectCompareIds,
  makeSelectCompareResult,
  makeSelectLoading,
  makeSelectResultTable,
  makeSelectResultStaff,
  makeSelectCompareLength,
  makeSelectStaffInput,
  makeSelectStaffList,
  makeSelectStaffListLoading,
  makeSelectStaffSelector
};
