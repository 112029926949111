export const CHANGE_ANSWER_CREATE_FROM_QUESTIONS =
  'hh/pages/chiefQuiz/CHANGE_ANSWER_CREATE_FROM_QUESTIONS';

export const QUESTIONS_LIST_REQUEST = 'hh/pages/chiefQuiz/QUESTIONS_LIST_REQUEST';
export const QUESTIONS_LIST_REQUEST_SUCCESS = 'hh/pages/chiefQuiz/QUESTIONS_LIST_REQUEST_SUCCESS';
export const QUESTIONS_LIST_REQUEST_FAIL = 'hh/pages/chiefQuiz/QUESTIONS_LIST_REQUEST_FAIL';

export const CHECK_INVITE_CODE_REQUEST = 'hh/pages/chiefQuiz/CHECK_INVITE_CODE_REQUEST';
export const CHECK_INVITE_CODE_REQUEST_SUCCESS =
  'hh/pages/chiefQuiz/CHECK_INVITE_CODE_REQUEST_SUCCESS';
export const CHECK_INVITE_CODE_REQUEST_FAIL = 'hh/pages/chiefQuiz/CHECK_INVITE_CODE_REQUEST_FAIL';

export const SEND_QUIZ_RESULT_REQUEST = 'hh/pages/chiefQuiz/SEND_QUIZ_RESULT_REQUEST';
export const SEND_QUIZ_RESULT_REQUEST_SUCCESS =
  'hh/pages/chiefQuiz/SEND_QUIZ_RESULT_REQUEST_SUCCESS';
export const SEND_QUIZ_RESULT_REQUEST_FAIL = 'hh/pages/chiefQuiz/SEND_QUIZ_RESULT_REQUEST_FAIL';
