import React, { Fragment } from 'react';
import StaffRootView from 'navigation/routes/StaffRootView';
import SetActiveView from 'navigation/routes/SetActiveView';
import ReportsPage from 'pages/IndividualProfile/Reports';

const IndividualReportsView = () => (
  <Fragment>
    <StaffRootView />
    <SetActiveView id="individual/index" />
    <ReportsPage />
  </Fragment>
);

export default IndividualReportsView;
