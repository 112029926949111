import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isRequired, isPhoneInProfile, isEmail } from 'utils/validators';
import injectSaga from 'utils/injectSaga';
import Button from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Form from '@atlaskit/form';
import Tabs from '@atlaskit/tabs';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';

import {
  changeEmail,
  changeName,
  changePasswordOne,
  changePasswordTwo,
  changePhoneNumber,
  changeSurname,
  updatePassword,
  updateUser
} from './actions';

import saga from './saga';

import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class MyProfile extends React.Component {
  state = {
    companyName: true,
    emailFlags: [],
    emailDisabled: true,
    emailValid: true,
    flags: [],
    informationDisabled: true,
    nameValid: true,
    passwordDisabled: true,
    passFlags: [],
    passwordOneValid: true,
    passwordTwoValid: true,
    phoneValid: true,
    selected: null,
    surnameValid: true,
    title: ''
  };

  addFlag = () => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags]
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  changeSelected = selected => {
    this.setState({ selected });
  };

  changeEmail = event => {
    const emailValid = isEmail(event.target.value);
    this.setState({
      emailValid,
      emailDisabled: !emailValid
    });

    this.props.changeEmail({
      email: event.target.value
    });
  };

  changeName = event => {
    this.props.changeName({
      name: event.target.value
    });

    this.checkInformationForm('name', event.target.value);
  };

  changePasswordOne = event => {
    this.props.changePasswordOne({
      passwordOne: event.target.value
    });

    this.checkPasswordForm('passwordOne', event.target.value);
  };

  changePasswordTwo = event => {
    this.props.changePasswordTwo({
      passwordTwo: event.target.value
    });

    this.checkPasswordForm('passwordTwo', event.target.value);
  };

  changePhoneNumber = event => {
    this.props.changePhoneNumber({
      phone: event.target.value
    });

    this.checkInformationForm('phone', event.target.value);
  };

  changeSurname = event => {
    this.props.changeSurname({
      surname: event.target.value
    });

    this.checkInformationForm('surname', event.target.value);
  };

  checkInformationForm = (input, value) => {
    const { name, surname, phoneNumber } = this.props;
    let informationDisabled = true;

    const nameValid = isRequired(input === 'name' ? value : name);
    const surnameValid = isRequired(input === 'surname' ? value : surname);
    const phoneValid = isPhoneInProfile(input === 'phone' ? value : phoneNumber);

    switch (input) {
      case 'name':
        this.setState({
          nameValid
        });
        break;
      case 'surname':
        this.setState({
          surnameValid
        });
        break;
      case 'phone':
        this.setState({
          phoneValid
        });
        break;
      default:
        break;
    }

    if (nameValid && surnameValid && phoneValid) {
      informationDisabled = false;
    }

    this.setState({
      informationDisabled
    });
  };

  checkPasswordForm = (input, value) => {
    const { passwordOne, passwordTwo } = this.props;
    let passwordDisabled = true;

    const passwordOneValid = isRequired(input === 'passwordOne' ? value : passwordOne);
    let passwordTwoValid = isRequired(input === 'passwordTwo' ? value : passwordTwo);

    switch (input) {
      case 'passwordOne':
        this.setState({
          passwordOneValid
        });
        break;
      case 'passwordTwo':
        if (!passwordTwoValid || value !== passwordOne) {
          passwordTwoValid = false;
        }
        this.setState({
          passwordTwoValid
        });
        break;
      default:
        break;
    }

    if (passwordOneValid && passwordTwoValid) {
      passwordDisabled = false;
    }

    this.setState({
      passwordDisabled
    });
  };

  updatePassword = () => {
    this.props.updatePassword();
    this.addFlag();
  };

  updateUser = () => {
    this.props.updateUser();
    this.addFlag();
  };

  render() {
    const {
      email,
      name,
      surname,
      phoneNumber,
      userReadLoading,
      id,
      passwordOne,
      passwordTwo,
      passwordLoading,
      userLoading,
      user
    } = this.props;

    const {
      emailDisabled,
      emailValid,
      informationDisabled,
      nameValid,
      passwordDisabled,
      passwordOneValid,
      passwordTwoValid,
      phoneValid,
      surnameValid
    } = this.state;

    let content = <Spinner />;

    if (!userReadLoading && id !== 0) {
      const information = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!nameValid}
                required
                label="Имя"
                name="name"
                onChange={this.changeName}
                placeholder=""
                shouldFitContainer
                value={name}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!surnameValid}
                required
                label="Фамилия"
                name="surname"
                onChange={this.changeSurname}
                placeholder=""
                shouldFitContainer
                value={surname}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!phoneValid}
                label="Телефон"
                mask="+7(999) 999-99-99"
                name="phone"
                onChange={this.changePhoneNumber}
                placeholder=""
                required
                shouldFitContainer
                value={phoneNumber}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={informationDisabled}
              isLoading={userLoading}
              onClick={this.updateUser}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const emailForm = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup marginTop="23px">
            <h4>Изменение email</h4>
          </FieldGroup>
          <FieldGroup marginTop="8px">
            <p>
              Ваш текущий email <b>{user.email}</b>
            </p>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!emailValid}
                required
                label="Новый email"
                name="email"
                onChange={this.changeEmail}
                placeholder="Укажите новый адрес email"
                shouldFitContainer
                value={email}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={emailDisabled}
              isLoading={userLoading}
              onClick={this.updateUser}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const password = (
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup marginTop="23px">
            <h4>Изменение пароля</h4>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!passwordOneValid}
                required
                label="Новый пароль"
                name="current_password"
                onChange={this.changePasswordOne}
                placeholder=""
                shouldFitContainer
                type="password"
                value={passwordOne}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field width="280px">
              <TextField
                isInvalid={!passwordTwoValid}
                required
                label="Повторите новый пароль"
                name="new_password"
                onChange={this.changePasswordTwo}
                placeholder=""
                shouldFitContainer
                type="password"
                value={passwordTwo}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="40px">
            <Button
              appearance="primary"
              isDisabled={passwordDisabled}
              isLoading={passwordLoading}
              onClick={this.updatePassword}
            >
              Сохранить изменения
            </Button>
          </FieldGroup>
        </Form>
      );
      const tabs = [
        { label: 'Общая информация', content: information },
        { label: 'Email', content: emailForm },
        { label: 'Пароль', content: password }
      ];
      let selected = this.state.selected !== null ? tabs[this.state.selected] : tabs[0];

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader>{`${user.name} ${user.surname}`}</PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <Tabs
                onSelect={(selected, index) => this.changeSelected(index)}
                selected={selected}
                tabs={tabs}
              />
              <FlagGroup onDismissed={name => this.removeFlag(name)}>
                {this.state.flags.map(id => (
                  <Flag
                    icon={<Tick label="Success" />}
                    id={id}
                    isDismissAllowed
                    key={`${id}`}
                    title="Изменения сохранены"
                  />
                ))}
              </FlagGroup>
            </GridColumn>
          </Grid>
        </Container>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{`${user.name} ${user.surname}`}</title>
        </Helmet>

        {content}
      </Fragment>
    );
  }
}

MyProfile.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changePasswordOne: PropTypes.func.isRequired,
  changePasswordTwo: PropTypes.func.isRequired,
  changePhoneNumber: PropTypes.func.isRequired,
  changeSurname: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  surname: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  gender: PropTypes.number.isRequired,
  passwordOne: PropTypes.string.isRequired,
  passwordTwo: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  userLoading: PropTypes.bool.isRequired,
  userFlags: PropTypes.array.isRequired,
  passwordLoading: PropTypes.bool.isRequired,
  passwordFlags: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userReadLoading: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeEmail: value => dispatch(changeEmail(value)),
    changeName: value => dispatch(changeName(value)),
    changePasswordOne: value => dispatch(changePasswordOne(value)),
    changePasswordTwo: value => dispatch(changePasswordTwo(value)),
    changePhoneNumber: value => dispatch(changePhoneNumber(value)),
    changeSurname: value => dispatch(changeSurname(value)),
    updatePassword: () => dispatch(updatePassword()),
    updateUser: () => dispatch(updateUser())
  };
}

const mapStateToProps = store => ({
  email: store.getIn(['individualProfile', 'email']),
  name: store.getIn(['individualProfile', 'name']),
  id: store.getIn(['individualProfile', 'id']),
  surname: store.getIn(['individualProfile', 'surname']),
  phoneNumber: store.getIn(['individualProfile', 'phoneNumber']),
  gender: store.getIn(['individualProfile', 'gender']),
  passwordOne: store.getIn(['individualProfile', 'passwordOne']),
  passwordTwo: store.getIn(['individualProfile', 'passwordTwo']),
  loading: store.getIn(['individualProfile', 'loading']),
  user: store.getIn(['individualProfile', 'user']).toJS(),
  userLoading: store.getIn(['individualProfile', 'userLoading']),
  userFlags: store.getIn(['individualProfile', 'userFlags']).toJS(),
  userReadLoading: store.getIn(['individualProfile', 'userReadLoading']),
  passwordLoading: store.getIn(['individualProfile', 'passwordLoading']),
  passwordFlags: store.getIn(['individualProfile', 'passwordFlags']).toJS()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withSaga = injectSaga({ key: 'individualProfile', saga });

export default compose(
  withRouter,
  withSaga,
  withConnect
)(MyProfile);
