import { call, select, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import apiUrl from 'utils/serverConfig';

import { SEND_FEEDBACK_REQUEST, SEND_SUBMIT_REQUEST } from './constants';

import {
  sendFeedbackSuccess,
  sendFeedbackFail,
  sendSubmitSuccess,
  sendSubmitFail
} from './actions';

import { makeSelectName, makeSelectEmail, makeSelectText } from './selectors';

function* sendFeedbackSaga() {
  const url = `${apiUrl}/api/managers/feedback/`;
  const name = yield select(makeSelectName());
  const email = yield select(makeSelectEmail());
  const text = yield select(makeSelectText());
  const options = {
    method: 'post',
    data: {
      name,
      email,
      text
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendFeedbackSuccess());
  } catch (e) {
    yield put(sendFeedbackFail());
  }
}

function* sendSubmitSaga(payload) {
  const url = `${apiUrl}/api/managers/leads/`;
  const options = {
    method: 'post',
    data: {
      name: payload.name,
      phone_number: payload.phone,
      email: payload.email
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendSubmitSuccess());
  } catch (e) {
    yield put(sendSubmitFail());
  }
}

export default function* landingSagas() {
  yield takeLeading(SEND_FEEDBACK_REQUEST, sendFeedbackSaga);
  yield takeLeading(SEND_SUBMIT_REQUEST, sendSubmitSaga);
}
