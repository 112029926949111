import styled from 'styled-components';

const Label = styled.p`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3333333333333333;
`;

export default Label;
