import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { pageHostUrl } from 'utils/serverConfig';
import { isEmail } from 'utils/validators';

import Button, { ButtonGroup } from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Form from '@atlaskit/form';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Select from '@atlaskit/select';
import Empty from 'components/Empty';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import FieldGroup from 'pages/Home/styled/FieldGroup';
import Field from 'pages/Home/styled/Field';
import Line from 'pages/Home/table/styled/Line';
import Text from 'pages/Home/table/styled/Text';

import reducer from 'pages/PartnerProfile/Clients/reducer';
import saga from 'pages/PartnerProfile/Clients/saga';

import {
  changeClientsPage,
  changeFilterField,
  changeFlags,
  changeInviteField,
  changeInviteModal,
  changeIsMineFilter,
  getClientsList,
  sendInvite
} from 'pages/PartnerProfile/Clients/actions';
import {
  makeSelectClientsCount,
  makeSelectClientsIsMineFilter,
  makeSelectClientsList,
  makeSelectClientsLoading,
  makeSelectClientsPage,
  makeSelectClientsSearch,
  makeSelectRegistrationFlags,
  makeSelectInviteForm,
  makeSelectInviteModals,
  makeSelectInviteFlags,
  makeSelectInviteLoading
} from 'pages/PartnerProfile/Clients/selectors';
import { makeSelectUserRead } from 'pages/PartnerProfile/User/selectors';

import ClientsTable from './Table';

class List extends Component {
  state = {
    clientsFilter: this.props.clientsFilter,
    formValid: {
      email: true
    }
  };

  componentDidMount() {
    this.sendFilterToState = debounce(this.sendFilterToState, 300);
    const { getClientsList } = this.props;

    getClientsList();
  }

  onChangeFormField = event => {
    const {
      props: { changeInviteField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeInviteField(field);
    validateForm(field);
  };

  onChangeFilterField = event => {
    this.sendFilterToState(event.target.value);
    this.setState({
      clientsFilter: event.target.value
    });
  };

  onSubmitForm = () => {
    const {
      props: { sendInvite, inviteForm },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: inviteForm[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      sendInvite();
    }
  };

  sendFilterToState = clientsFilter => {
    const {
      props: { changeFilterField }
    } = this;

    changeFilterField({
      field: 'clients',
      text: clientsFilter
    });
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderBottomBar = () => {
    const {
      onChangeFilterField,
      props: { changeIsMineFilter, isMineFilter },
      state: { clientsFilter }
    } = this;
    const selectIsMineFilter = [
      {
        label: 'Все клиенты',
        value: 0
      },
      {
        label: 'Мои клиенты',
        value: 1
      }
    ];

    const onChangeSelector = select => {
      changeIsMineFilter({
        filter: select.value
      });
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 200px' }}>
          <TextField
            isCompact
            isLabelHidden
            label="hidden"
            onChange={onChangeFilterField}
            placeholder="Название, ИНН, email, дата регистрации"
            shouldFitContainer
            value={clientsFilter}
          />
        </div>
        <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
          <Select
            className="single-select"
            defaultOptions={selectIsMineFilter[0]}
            options={selectIsMineFilter}
            isSearchable={false}
            onChange={onChangeSelector}
            placeholder="Все клиенты"
            value={isMineFilter ? selectIsMineFilter[1] : selectIsMineFilter[0]}
          />
        </div>
      </div>
    );
  };

  renderButtons = () => {
    const {
      props: { changeInviteModal }
    } = this;

    const openModal = () => changeInviteModal({ modals: [1] });

    return (
      <ButtonGroup>
        <Button appearance="primary" href="/partner/clients/create" component={RouterLink}>
          Зарегистрировать клиента
        </Button>
        <Button onClick={openModal}>Пригласить по ссылке</Button>
      </ButtonGroup>
    );
  };

  renderContent = () => {
    const { changeClientsPage, clientsFilter, count, list, loading, page } = this.props;

    let content = (
      <Empty
        header="У вас еще нет клиентов"
        text="Начните регистрировать клиентов и получать вознаграждения за приглашенных и зарегистрированных клиентов"
      >
        <div>{this.renderButtons()}</div>
      </Empty>
    );

    if (count >= 1 || !isEmpty(clientsFilter)) {
      content = [
        <ClientsTable key="clientsTable" data={list} isLoading={loading} />,
        <Pagination
          key="pagination"
          value={page}
          total={Math.ceil(count / 25)}
          onChange={page => changeClientsPage({ page })}
        />
      ];
    } else if (loading) {
      content = <Spinner height="80vh" />;
    }

    return content;
  };

  renderRegistrationFlags = () => {
    const { changeFlags, registrationFlags } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'registration',
        flags: registrationFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {registrationFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При регистрации клиента произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Клиент успешно зарегистрирован' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  renderInviteFlags = () => {
    const { changeFlags, inviteFlags } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'invite',
        flags: inviteFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {inviteFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При отправке сообщения произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Приглашение успешно отправлено' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  renderModals = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { changeInviteModal, inviteForm, inviteLoading, inviteModals, user },
      state: { formValid }
    } = this;

    const closeModal = id => {
      changeInviteModal({ modals: inviteModals.filter(i => i !== id) });
    };

    return inviteModals.map(id => (
      <Modal
        key={id}
        id={id}
        heading="Пригласить по ссылке"
        onClose={closeModal}
        actions={[
          {
            isLoading: inviteLoading,
            text: 'Отправить',
            onClick: onSubmitForm
          },
          {
            text: 'Отменить',
            onClick: closeModal
          }
        ]}
        width="small"
      >
        <p>Отправьте или скопируйте ссылку</p>
        <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
          <FieldGroup layout="column" form="">
            <Field width="100%">
              <TextField
                label="Скопируйте ссылку"
                shouldFitContainer
                name="link"
                placeholder=""
                isReadOnly
                value={`${pageHostUrl}/signup/${user.referral_code}`}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Line />
            <Text>или</Text>
            <Line />
          </FieldGroup>
          <FieldGroup layout="column" form="">
            <Field width="100%">
              <TextField
                isInvalid={!formValid.email}
                required
                label="Отправьте приглашение на почту"
                shouldFitContainer
                name="email"
                id="email"
                onBlur={onChangeFormField}
                onChange={onChangeFormField}
                placeholder=""
                value={inviteForm.email}
              />
            </Field>
          </FieldGroup>
          <FieldGroup marginTop="8px">
            <Field width="100%">
              <TextArea
                label=""
                shouldFitContainer
                name="message"
                id="message"
                onBlur={onChangeFormField}
                onChange={onChangeFormField}
                placeholder="Добавьте сообщение (необязательно)"
                value={inviteForm.message}
              />
            </Field>
          </FieldGroup>
        </Form>
      </Modal>
    ));
  };

  render() {
    const {
      props: { clientsFilter, count, loading },
      renderBottomBar,
      renderButtons,
      renderContent,
      renderInviteFlags,
      renderModals,
      renderRegistrationFlags
    } = this;
    const showButtons = (count >= 1 && !loading) || !isEmpty(clientsFilter);

    return (
      <Page>
        <Helmet>
          <title>Клиенты</title>
        </Helmet>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader
              actions={showButtons ? renderButtons() : null}
              bottomBar={renderBottomBar()}
            >
              Клиенты
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{renderContent()}</GridColumn>

          {renderModals()}
          {renderRegistrationFlags()}
          {renderInviteFlags()}
        </Grid>
      </Page>
    );
  }
}

List.propTypes = {
  changeClientsPage: PropTypes.func.isRequired,
  changeFilterField: PropTypes.func.isRequired,
  changeFlags: PropTypes.func.isRequired,
  changeInviteField: PropTypes.func.isRequired,
  changeInviteModal: PropTypes.func.isRequired,
  changeIsMineFilter: PropTypes.func.isRequired,
  clientsFilter: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  getClientsList: PropTypes.func.isRequired,
  inviteFlags: PropTypes.array.isRequired,
  inviteForm: PropTypes.object.isRequired,
  inviteModals: PropTypes.array.isRequired,
  inviteLoading: PropTypes.bool.isRequired,
  isMineFilter: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  registrationFlags: PropTypes.array.isRequired,
  sendInvite: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientsPage: () => dispatch(changeClientsPage()),
    changeFilterField: value => dispatch(changeFilterField(value)),
    changeFlags: value => dispatch(changeFlags(value)),
    changeInviteField: value => dispatch(changeInviteField(value)),
    changeInviteModal: value => dispatch(changeInviteModal(value)),
    changeIsMineFilter: value => dispatch(changeIsMineFilter(value)),
    getClientsList: () => dispatch(getClientsList()),
    sendInvite: () => dispatch(sendInvite())
  };
}

const mapStateToProps = createStructuredSelector({
  count: makeSelectClientsCount(),
  clientsFilter: makeSelectClientsSearch(),
  inviteFlags: makeSelectInviteFlags(),
  inviteForm: makeSelectInviteForm(),
  inviteModals: makeSelectInviteModals(),
  inviteLoading: makeSelectInviteLoading(),
  isMineFilter: makeSelectClientsIsMineFilter(),
  list: makeSelectClientsList(),
  loading: makeSelectClientsLoading(),
  page: makeSelectClientsPage(),
  registrationFlags: makeSelectRegistrationFlags(),
  user: makeSelectUserRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerClients', reducer });
const withSaga = injectSaga({ key: 'partnerClients', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(List);
