import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerPartners');

const makeSelectPartnersCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partners', 'count'])
  );

const makeSelectPartnersList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partners', 'list']).toJS()
  );

const makeSelectPartnersLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partners', 'loading'])
  );

const makeSelectPartnersPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partners', 'page'])
  );

const makeSelectPartnersSearch = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partners', 'search'])
  );

const makeSelectRegistrationForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'form']).toJS()
  );

const makeSelectRegistrationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'loading'])
  );

const makeSelectRegistrationFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'flags']).toJS()
  );

const makeSelectPartnerLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partner', 'loading'])
  );

const makeSelectPartnerRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partner', 'read']).toJS()
  );

const makeSelectPartnerComment = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partner', 'form', 'comment'])
  );

const makeSelectPartnerFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partner', 'flags']).toJS()
  );

const makeSelectPartnerFormLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['partner', 'formLoading'])
  );

const makeSelectClientsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'count'])
  );

const makeSelectClientsList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'list']).toJS()
  );

const makeSelectClientsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'loading'])
  );

const makeSelectClientsPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'page'])
  );

const makeSelectRequisitesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'count'])
  );

const makeSelectRequisitesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'list']).toJS()
  );

const makeSelectRequisitesLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'loading'])
  );

const makeSelectRequisitesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'page'])
  );

const makeSelectInviteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'modals']).toJS()
  );

const makeSelectInviteFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'flags']).toJS()
  );

const makeSelectInviteLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'loading'])
  );

const makeSelectInviteForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'form']).toJS()
  );

export {
  makeSelectClientsCount,
  makeSelectClientsList,
  makeSelectClientsLoading,
  makeSelectClientsPage,
  makeSelectPartnersCount,
  makeSelectPartnersList,
  makeSelectPartnersLoading,
  makeSelectPartnersPage,
  makeSelectPartnersSearch,
  makeSelectPartnerComment,
  makeSelectPartnerFlags,
  makeSelectPartnerFormLoading,
  makeSelectPartnerLoading,
  makeSelectPartnerRead,
  makeSelectRegistrationFlags,
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading,
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage,
  makeSelectInviteModals,
  makeSelectInviteForm,
  makeSelectInviteFlags,
  makeSelectInviteLoading
};
