import styled from 'styled-components';

const LineItem = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 0 9px 0 12px;
  text-align: left;
  width: 130px;
  background-color: ${props => props.good ? '#79F2C0' : props.bad ? '#FF8F73' : ''}

  &:last-child {
    margin-right: 0;
  }
`;

export default LineItem;
