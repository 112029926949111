import { all, call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'axios';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import apiUrl from 'utils/serverConfig';

import {
  CHANGE_ANSWER_TIME,
  GET_QUESTIONNARIE_LIST_REQUEST,
  SEND_QUESTIONNARIE_REQUEST,
  GET_QUESTIONNAIRE_PROGRESS_REQUEST
} from './constants';

import {
  getQuestionnarieListSuccess,
  getQuestionnarieListFail,
  sendQuestionnarieSuccess,
  sendQuestionnarieFail,
  getQuestionnaireSuccess,
  getQuestionnaireFail
} from './actions';

import {
  makeSelectAnswersCount,
  makeSelectAnswersList,
  makeSelectAnswersTime,
  makeSelectQuestionsList,
  makeSelectQuestionnaireId,
  makeSelectMaxQuestionNumber,
  makeSelectInvitationCode
} from './selectors';
import * as Sentry from '@sentry/browser';

function* getQuestionnarieListSaga(payload) {
  const url = `${apiUrl}/api/staff/questionnaire/`;
  const progressUrl = `${apiUrl}/api/staff/questionnaire/current_progress/`;
  const invitationCode = yield select(makeSelectInvitationCode());
  const key = localStorage.getItem('staffKey');
  const options = {
    headers: {
      Authorization: `Token ${key}`
    }
  };

  try {
    const [request, progressRequest] = yield all([
      call(axios, url, options),
      call(axios, progressUrl, options)
    ]);
    const questions = request.data.questions;

    const questionsList = questions.map(question => ({
      id: question.id,
      text: question.text
    }));
    const answersList = questions.map(question => question.answers);
    const answers = questions.map(question => question.answers);
    const answersCount = new Array(answers.length).fill(0);
    const answersTime = answersCount;
    let goToNewQuestionnarie = false;

    if (!isEmpty(progressRequest.data)) {
      progressRequest.data.questions.forEach((question, key) => {
        const currentAnswers = [];
        question.answers.forEach((answer, answerKey) => {
          if (!isUndefined(answersList[key].find(item => item.id === answer))) {
            currentAnswers[answerKey] = answersList[key].find(item => item.id === answer);
          } else {
            goToNewQuestionnarie = true;
          }
        });
        if (currentAnswers.length > 0) {
          answers[key] = currentAnswers;
        }
        answersCount[key] = question.moves_count;
        answersTime[key] = question.duration;
      });
    }

    yield put(
      getQuestionnarieListSuccess({
        questionnaireId: request.data.id,
        questionsList: fromJS(questionsList),
        answersCount: fromJS(answersCount),
        answersList: fromJS(answers),
        answersTime: fromJS(answersTime)
      })
    );

    if (goToNewQuestionnarie) {
      if (isUndefined(invitationCode)) {
        yield put(push(`/test/question/1`));
      } else {
        yield put(push(`/test/question/1/${invitationCode}`));
      }
    } else {
      if (!isEmpty(progressRequest.data) && progressRequest.data.questions.length > 0) {
        if (isUndefined(invitationCode)) {
          if (isUndefined(payload.invitationCode)) {
            yield put(push(`/test/question/${progressRequest.data.questions.length + 1}`));
          } else {
            yield put(
              push(
                `/test/question/${progressRequest.data.questions.length + 1}/${
                  payload.invitationCode
                }`
              )
            );
          }
        } else {
          yield put(
            push(`/test/question/${progressRequest.data.questions.length + 1}/${invitationCode}`)
          );
        }
      }
    }
  } catch (e) {
    yield put(getQuestionnarieListFail());

    if (!isNull(localStorage.getItem('bca3f30e916c75160a384d93542b0bc2'))) {
      localStorage.removeItem('bca3f30e916c75160a384d93542b0bc2');

      if (isUndefined(invitationCode)) {
        yield put(push(`/individual/report`));
      } else {
        yield call(sendInvitationCodeSaga, invitationCode);
      }
    } else {
      if (isUndefined(invitationCode)) {
        yield put(push(`/individual/report`));
      } else {
        yield call(sendInvitationCodeSaga, invitationCode);
      }
    }
  }
}

function* sendInvitationCodeSaga(invitationCode) {
  const url = `${apiUrl}/api/staff/auth/user`;
  const key = localStorage.getItem('staffKey');

  const options = {
    method: 'put',
    headers: {
      Authorization: `Token ${key}`
    },
    data: {
      invitation_code: invitationCode
    }
  };

  try {
    yield call(axios, url, options);
    yield put(push(`/individual/report`));
  } catch (e) {
    console.log(e);
  }
}

function* sendQuestionnarieSaga() {
  const url = `${apiUrl}/api/staff/questionnaire/submit/`;
  const questionsList = yield select(makeSelectQuestionsList());
  const answersCount = yield select(makeSelectAnswersCount());
  const answersList = yield select(makeSelectAnswersList());
  const answersTime = yield select(makeSelectAnswersTime());
  const questionnaireId = yield select(makeSelectQuestionnaireId());
  const key = localStorage.getItem('staffKey');
  const questions = questionsList.map((question, index) => ({
    question: question.get('id'),
    duration: answersTime.get(index),
    moves_count: answersCount.get(index),
    answers: answersList
      .get(index)
      .map(answer => answer.get('id'))
      .toJS()
  }));
  const data = {
    questionnaire: questionnaireId,
    questions: questions.toJS()
  };
  Sentry.captureMessage(`Testing api logs`, {
    list: JSON.stringify(questions.toJS())
  });

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Token ${key}`
    },
    data: data
  };

  try {
    yield call(axios, url, options);

    yield put(sendQuestionnarieSuccess());
  } catch (e) {
    yield put(sendQuestionnarieFail());
  } finally {
    localStorage.removeItem('bca3f30e916c75160a384d93542b0bc2');

    yield put(push(`/individual/report`));
  }
}

function* changeAnswersTimeSaga() {
  const url = `${apiUrl}/api/staff/questionnaire/track_progress/`;
  const questionsList = yield select(makeSelectQuestionsList());
  const answersCount = yield select(makeSelectAnswersCount());
  const answersList = yield select(makeSelectAnswersList());
  const answersTime = yield select(makeSelectAnswersTime());
  const questionnaireId = yield select(makeSelectQuestionnaireId());
  const maxQuestionNumber = yield select(makeSelectMaxQuestionNumber());
  const key = localStorage.getItem('staffKey');
  const questions = questionsList.slice(0, maxQuestionNumber).map((question, index) => ({
    question: question.get('id'),
    duration: answersTime.get(index),
    moves_count: answersCount.get(index),
    answers: answersList
      .get(index)
      .map(answer => answer.get('id'))
      .toJS()
  }));

  const data = {
    questionnaire: questionnaireId,
    questions: questions.toJS()
  };

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Token ${key}`
    },
    data: data
  };

  try {
    yield call(axios, url, options);
  } catch (e) {
    console.log(e);
  }
}

function* getQuestionnaireProgressSaga() {
  const url = `${apiUrl}/api/staff/questionnaire/current_progress/`;
  const key = localStorage.getItem('staffKey');

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${key}`
    }
  };

  try {
    yield call(axios, url, options);

    yield put(getQuestionnaireSuccess());
  } catch (e) {
    yield put(getQuestionnaireFail());
  }
}

export default function* testStartSaga() {
  yield takeLeading(GET_QUESTIONNARIE_LIST_REQUEST, getQuestionnarieListSaga);
  yield takeLeading(SEND_QUESTIONNARIE_REQUEST, sendQuestionnarieSaga);
  yield takeLeading(CHANGE_ANSWER_TIME, changeAnswersTimeSaga);
  yield takeLeading(GET_QUESTIONNAIRE_PROGRESS_REQUEST, getQuestionnaireProgressSaga);
}
