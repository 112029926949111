import {
  ADD_ANSWER_MOVES,
  CHANGE_ANSWERS,
  CHANGE_ANSWER_TIME,
  GET_QUESTIONNARIE_LIST_REQUEST,
  GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS,
  GET_QUESTIONNARIE_LIST_REQUEST_FAIL,
  SEND_QUESTIONNARIE_REQUEST,
  SEND_QUESTIONNARIE_REQUEST_SUCCESS,
  SEND_QUESTIONNARIE_REQUEST_FAIL,
  GET_QUESTIONNAIRE_PROGRESS_REQUEST,
  GET_QUESTIONNAIRE_PROGRESS_SUCCESS,
  GET_QUESTIONNAIRE_PROGRESS_FAIL
} from './constants';

export function getQuestionnaireProgress() {
  return {
    type: GET_QUESTIONNAIRE_PROGRESS_REQUEST
  };
}

export function getQuestionnaireSuccess(payload) {
  return {
    type: GET_QUESTIONNAIRE_PROGRESS_SUCCESS,
    ...payload
  };
}

export function getQuestionnaireFail() {
  return {
    type: GET_QUESTIONNAIRE_PROGRESS_FAIL
  };
}

export function addAnswersMoves(payload) {
  return {
    type: ADD_ANSWER_MOVES,
    ...payload
  };
}

export function changeAnswers(payload) {
  return {
    type: CHANGE_ANSWERS,
    ...payload
  };
}

export function changeAnswerTime(payload) {
  return {
    type: CHANGE_ANSWER_TIME,
    ...payload
  };
}

export function getQuestionnarieList(payload) {
  return {
    type: GET_QUESTIONNARIE_LIST_REQUEST,
    ...payload
  };
}

export function getQuestionnarieListSuccess(payload) {
  return {
    type: GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getQuestionnarieListFail() {
  return {
    type: GET_QUESTIONNARIE_LIST_REQUEST_FAIL
  };
}

export function sendQuestionnarie() {
  return {
    type: SEND_QUESTIONNARIE_REQUEST
  };
}

export function sendQuestionnarieSuccess() {
  return {
    type: SEND_QUESTIONNARIE_REQUEST_SUCCESS
  };
}

export function sendQuestionnarieFail() {
  return {
    type: SEND_QUESTIONNARIE_REQUEST_FAIL
  };
}
