export const ADD_ANSWER_MOVES = 'hh/pages/test/ADD_ANSWER_MOVES';
export const CHANGE_ANSWERS = 'hh/pages/test/CHANGE_ANSWERS';
export const CHANGE_ANSWER_TIME = 'hh/pages/test/CHANGE_ANSWER_TIME';

export const GET_QUESTIONNARIE_LIST_REQUEST = 'hh/pages/test/GET_QUESTIONNARIE_LIST_REQUEST';
export const GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS =
  'hh/pages/test/GET_QUESTIONNARIE_LIST_REQUEST_SUCCESS';
export const GET_QUESTIONNARIE_LIST_REQUEST_FAIL =
  'hh/pages/test/GET_QUESTIONNARIE_LIST_REQUEST_FAIL';

export const SEND_QUESTIONNARIE_REQUEST = 'hh/pages/test/SEND_QUESTIONNARIE_REQUEST';
export const SEND_QUESTIONNARIE_REQUEST_SUCCESS =
  'hh/pages/test/SEND_QUESTIONNARIE_REQUEST_SUCCESS';
export const SEND_QUESTIONNARIE_REQUEST_FAIL = 'hh/pages/test/SEND_QUESTIONNARIE_REQUEST_FAIL';

export const GET_QUESTIONNAIRE_PROGRESS_REQUEST =
  'hh/pages/test/GET_QUESTIONNAIRE_PROGRESS_REQUEST';
export const GET_QUESTIONNAIRE_PROGRESS_SUCCESS =
  'hh/pages/test/GET_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const GET_QUESTIONNAIRE_PROGRESS_FAIL = 'hh/pages/test/GET_QUESTIONNAIRE_PROGRESS_FAIL';

export const SEND_INVITATION_CODE_REQUEST = 'hh/pages/test/GET_QUESTIONNAIRE_PROGRESS_FAIL';
