import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QueuesIcon from '@atlaskit/icon/glyph/queues';
import PersonIcon from '@atlaskit/icon/glyph/person';
import IssuesIcon from '@atlaskit/icon/glyph/issues';

import LinkItem from 'navigation/components/LinkItem';
import ViewRegistrar from 'navigation/components/ViewRegistrar';
import ReportDropdown from 'navigation/containers/ReportDropdown';

const ContainerViews = props => {
  const containerProject = [
    {
      id: 'root/person:header',
      items: [
        {
          id: 'back-button',
          items: [
            {
              type: 'BackItem',
              goTo: 'root/index',
              text: props.personName,
              subText: props.personEmail,
              id: 'back'
            }
          ],
          type: 'Group'
        }
      ],
      type: 'HeaderSection'
    },
    {
      id: 'root/person:menu',
      nestedGroupKey: 'menu',
      items: [
        {
          before: QueuesIcon,
          key: 'dropdown-item',
          href: `/person/report/${props.personId}`,
          type: 'InlineComponent',
          component: ReportDropdown
        },
        {
          before: IssuesIcon,
          id: 'compare',
          text: 'Отчеты-соответствия',
          to: `/person/compare/${props.personId}`,
          type: 'InlineComponent',
          component: LinkItem
        },
        {
          before: PersonIcon,
          id: 'information',
          text: 'Информация',
          to: `/person/${props.personId}`,
          type: 'InlineComponent',
          component: LinkItem
        }
      ],
      type: 'Section'
    }
  ];
  const editedView = [
    {
      id: `root/person/${props.personId}`,
      getItems: () => containerProject,
      type: 'container'
    }
  ];

  return (
    <Fragment>
      {editedView.map(view => (
        <ViewRegistrar key={view.id} view={view} />
      ))}
    </Fragment>
  );
};

ContainerViews.propTypes = {
  personEmail: PropTypes.string,
  personId: PropTypes.number,
  personName: PropTypes.string
};

export default ContainerViews;
