import React from 'react';

const VideoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fillRule="evenodd">
      <rect fill="currentColor" x="4" y="4" width="16" height="16" rx="2" />
      <path
        d="M16.37 14.954L14 13.807v-3.613l2.37-1.148c.285-.138.63.05.63.343v5.222c0 .293-.345.481-.63.343"
        fill="inherit"
      />
      <rect fill="inherit" x="7" y="9" width="6" height="6" rx="1" />
    </g>
  </svg>
);

export default VideoIcon;
