import styled from 'styled-components';

const Line = styled.div`
  align-self: center;
  background-color: #e2e3e7;
  height: 1px;
  margin: 20px 0 0;
  width: 150px;
`;

export default Line;
