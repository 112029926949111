import { fromJS } from 'immutable';
import {
  GET_EDUCATIONS_LIST_REQUEST,
  GET_EDUCATIONS_LIST_SUCCESS,
  GET_EDUCATIONS_LIST_FAIL
} from './constants';

const initialState = fromJS({
  loading: true,
  sections: []
});

function videoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EDUCATIONS_LIST_REQUEST:
      return state.set('loading', true);
    case GET_EDUCATIONS_LIST_SUCCESS:
      return state.set('sections', fromJS(action.sections)).set('loading', false);
    case GET_EDUCATIONS_LIST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default videoReducer;
