import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import MyProfilePage from 'pages/MyProfile';

const MyProfileView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <MyProfilePage />
  </Fragment>
);

export default MyProfileView;
