export const CHANGE_EMAIL = 'hh/pages/registration/CHANGE_EMAIL';
export const CHANGE_PASSWORD_ONE = 'hh/pages/registration/CHANGE_PASSWORD_ONE';
export const CHANGE_SMS_CODE = 'hh/pages/registration/CHANGE_SMS_CODE';
export const CHANGE_PHONE_NUMBER = 'hh/pages/registration/CHANGE_PHONE_NUMBER';
export const CHANGE_NAME = 'hh/pages/registration/CHANGE_NAME';
export const CHANGE_SURNAME = 'hh/pages/registration/CHANGE_SURNAME';

export const CHECK_INVITE_CODE_REQUEST = 'hh/pages/registration/CHECK_INVITE_CODE_REQUEST';
export const CHECK_INVITE_CODE_REQUEST_SUCCESS =
  'hh/pages/registration/CHECK_INVITE_CODE_REQUEST_SUCCESS';
export const CHECK_INVITE_CODE_REQUEST_FAIL =
  'hh/pages/registration/CHECK_INVITE_CODE_REQUEST_FAIL';

export const CHANGE_SMS_FLAGS = 'hh/pages/registration/CHANGE_SMS_FLAGS';
export const CHANGE_FLAGS = 'hh/pages/registration/CHANGE_FLAGS';

export const SEND_SMS_CODE_REQUEST = 'hh/pages/registration/SEND_SMS_CODE_REQUEST';
export const SEND_SMS_CODE_REQUEST_SUCCESS = 'hh/pages/registration/SEND_SMS_CODE_REQUEST_SUCCESS';
export const SEND_SMS_CODE_REQUEST_FAIL = 'hh/pages/registration/SEND_SMS_CODE_REQUEST_FAIL';

export const REGISTER_USER_REQUEST = 'hh/pages/registration/REGISTER_USER_REQUEST';
export const REGISTER_USER_REQUEST_SUCCESS = 'hh/pages/registration/REGISTER_USER_REQUEST_SUCCESS';
export const REGISTER_USER_REQUEST_FAIL = 'hh/pages/registration/REGISTER_USER_REQUEST_FAIL';
