import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import RegistrationPage from 'pages/PartnerProfile/Requisites/Registration';

const RegistrationRequisitesView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/settings" />
    <RegistrationPage />
  </Fragment>
);

export default RegistrationRequisitesView;
