import React, { Component } from 'react';
import uniqid from 'uniqid';
import * as moment from 'moment';
import 'moment/locale/ru';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import Rouble from 'components/Rouble';

import NameWrapper from './styled/NameWrapper';

class FinancesTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'name',
        content: 'Название организации',
        isSortable: false,
        width: 25
      },
      {
        key: 'created_at',
        content: 'Дата',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'original_amount',
        content: 'Сумма',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'reward_amount',
        content: 'Ваше вознаграждение',
        shouldTruncate: true,
        width: 25
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: <NameWrapper>{item.name}</NameWrapper>
        },
        {
          key: uniqid(),
          content: moment(item.created_at).format('DD.MM.YYYY')
        },
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              {item.original_amount.toLocaleString()}&nbsp;<Rouble>₽</Rouble>
            </NameWrapper>
          )
        },
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              {item.reward_amount.toLocaleString()}&nbsp;<Rouble>₽</Rouble>
            </NameWrapper>
          )
        }
      ]
    }));

  render() {
    const { data, isLoading, rewardAmountSum } = this.props;

    return [
      <div key="title" style={{ marginBottom: 15 }}>
        <h3>
          Полученое вознаграждение за выбранный период: {rewardAmountSum.toLocaleString()}{' '}
          <Rouble>₽</Rouble>
        </h3>
      </div>,
      <DynamicTable
        key="table"
        head={this.generateHead()}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        onSetPage={() => {}}
        onSort={() => {}}
        rows={this.generateRows(data)}
      />
    ];
  }
}

export default FinancesTable;
