import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';

import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import TriggerButton from 'pages/Home/table/styled/TriggerButton';
import Modal from 'components/Modal';

import NameWrapper from './styled/NameWrapper';

class RequisitesTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'name',
        content: 'Название организации',
        isSortable: false,
        width: 24
      },
      {
        key: 'inn',
        content: 'ИНН',
        shouldTruncate: true,
        isSortable: false,
        width: 24
      },
      {
        key: 'kpp',
        content: 'КПП',
        shouldTruncate: true,
        isSortable: false,
        width: 24
      },
      {
        key: 'ogrn',
        content: 'ОГРН',
        shouldTruncate: true,
        width: 24
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              <Link to={`/partner/requisites/${item.id}`}>{item.name}</Link>
            </NameWrapper>
          )
        },
        {
          key: uniqid(),
          content: item.inn
        },
        {
          key: uniqid(),
          content: item.kpp
        },
        {
          key: uniqid(),
          content: item.ogrn
        },
        {
          key: uniqid(),
          content: (
            <DropdownMenu trigger={TriggerButton} position="bottom right">
              <DropdownItemGroup>
                <DropdownItem
                  onClick={() => this.props.changeModals({ field: 'delete', modals: [item.id] })}
                >
                  Удалить
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )
        }
      ]
    }));

  renderDeleteModals = () => {
    const {
      props: { changeModals, deleteItem, deleteOrganization }
    } = this;

    const closeModal = id => {
      changeModals({ field: 'delete', modals: deleteItem.modals.filter(i => i !== id) });
    };
    const removeOrganization = id => {
      deleteOrganization({ id });
    };

    return deleteItem.modals.map(id => (
      <Modal
        key={id}
        id={id}
        heading="Удалить организацию"
        onClose={closeModal}
        actions={[
          {
            text: 'Удалить',
            isLoading: deleteItem.loading,
            onClick: removeOrganization
          },
          {
            text: 'Отменить',
            onClick: closeModal
          }
        ]}
      >
        <p>Уверены, что хотите удалить организацию?</p>
      </Modal>
    ));
  };

  render() {
    const {
      props: { data, isLoading },
      renderDeleteModals
    } = this;

    return (
      <Fragment>
        <DynamicTable
          head={this.generateHead()}
          isLoading={isLoading}
          loadingSpinnerSize="large"
          onSetPage={() => {}}
          onSort={() => {}}
          rows={this.generateRows(data)}
        />
        {renderDeleteModals()}
      </Fragment>
    );
  }
}

export default RequisitesTable;
