import { call, select, put, takeLeading } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import isNull from 'lodash/isNull';
import { push } from 'react-router-redux';

import {
  getCompareListSuccess,
  getCompareListFail,
  getStaffList,
  getStaffListSuccess,
  getStaffListFail
} from './actions';

import {
  CHANGE_STAFF_INPUT,
  GET_COMPARE_LIST_REQUEST,
  GET_STAFF_LIST_REQUEST,
  DELETE_STAFF_FROM_COMPARE
} from './constants';

import {
  makeSelectIdsForRequest,
  makeSelectCompareResult,
  makeSelectStaffInput,
  makeSelectCompareIds
} from './selectors';

function* getCompareListSaga() {
  const compareIds = yield select(makeSelectIdsForRequest());
  const ids = yield select(makeSelectCompareIds());
  const localStorageIds = JSON.parse(localStorage.getItem('compareIds'));
  const url = `api/managers/testing_result_reports/testing_result_reports_for_staff/?${compareIds}`;

  let result = '';
  if (!isNull(localStorageIds)) {
    localStorageIds.forEach((id, key) => {
      if (key > 0) {
        result += `&ids=${id}`;
      } else {
        result += `ids=${id}`;
      }
    });
  }

  if (result !== compareIds) {
    localStorage.setItem('compareIds', JSON.stringify(ids));
    yield put(push(`/compare/`));
  }

  try {
    const request = yield call(apiRequest, url);

    const compareResult = {};

    request.data.forEach(report => {
      compareResult[report.staff.id] = {
        id: report.id,
        staffId: report.staff.id,
        staff: {
          fullName: report.staff.full_name,
          firstName: report.staff.first_name,
          lastName: report.staff.last_name
        },
        sections: report.sections.map(section => ({
          id: section.id,
          title: section.title,
          subsections: section.subsections.map(subsection => ({
            id: subsection.id,
            title: subsection.title,
            value: subsection.value
          }))
        }))
      };
    });

    const sections = request.data[0].sections.map(section => ({
      id: section.id,
      title: section.title,
      subsections: section.subsections.map(subsection => ({
        id: subsection.id,
        title: subsection.title
      }))
    }));

    yield put(
      getCompareListSuccess({
        compareResult,
        sections
      })
    );
  } catch (e) {
    yield put(getCompareListFail());
  }
}

function* deleteStaffFromCompareSaga() {
  const compareResult = yield select(makeSelectCompareResult());
  const ids = [];
  compareResult.valueSeq().forEach(compare => {
    ids.push(compare.get('staffId'));
  });

  if (ids.length < 1) {
    localStorage.removeItem('compareIds');
    yield put(push('/person/list'));
  } else {
    localStorage.setItem('compareIds', JSON.stringify(ids));
    yield put(push(`/compare/`));
  }
}

function* getStaffListSaga() {
  const nameFilter = yield select(makeSelectStaffInput());

  const params = {
    ordering: 'full_name',
    limit: 25
  };

  if (nameFilter !== '') {
    params.full_name = nameFilter;
    params.limit = 25;
  }

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getStaffListSuccess({
        staffList: request.data.results.map(result => ({
          label: result.full_name,
          value: result.id
        }))
      })
    );
  } catch (e) {
    yield put(getStaffListFail());
  }
}

function* changeStaffInputSaga() {
  yield put(getStaffList());
}

export default function* compareListSagas() {
  yield takeLeading(CHANGE_STAFF_INPUT, changeStaffInputSaga);
  yield takeLeading(GET_COMPARE_LIST_REQUEST, getCompareListSaga);
  yield takeLeading(GET_STAFF_LIST_REQUEST, getStaffListSaga);
  yield takeLeading(DELETE_STAFF_FROM_COMPARE, deleteStaffFromCompareSaga);
}
