import React from 'react';
import PropTypes from 'prop-types';
import Base, { Label } from '@atlaskit/field-base';

import Input from './styled/Input';

import Wrapper from './styled/Wrapper';

class TextField extends React.Component {
  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  setInputRef = input => {
    this.input = input;
    this.props.innerRef(input);
  };

  render() {
    return (
      <Wrapper>
        <Label
          htmlFor={this.props.id}
          isDisabled={this.props.disabled}
          isLabelHidden={this.props.isLabelHidden}
          isRequired={this.props.required}
          label={this.props.label || ''}
        />
        <Base
          invalidMessage={this.props.invalidMessage}
          isCompact={this.props.compact}
          isDisabled={this.props.disabled}
          isFitContainerWidthEnabled={this.props.shouldFitContainer}
          isInvalid={this.props.isInvalid}
          isReadOnly={this.props.isReadOnly}
          isRequired={this.props.required}
          isValidationHidden={this.props.isValidationHidden}
        >
          <Input
            autoComplete={this.props.autoComplete}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            form={this.props.form}
            id={this.props.id}
            innerRef={this.setInputRef}
            mask={this.props.mask}
            maskChar={this.props.maskChar}
            alwaysShowMask={false}
            formatChars={{
              '9': '[0-9]'
            }}
            maxLength={this.props.maxLength}
            min={this.props.min}
            max={this.props.max}
            name={this.props.name}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            onFocus={this.props.onFocus}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            onKeyUp={this.props.onKeyUp}
            pattern={this.props.pattern}
            placeholder={this.props.placeholder}
            readOnly={this.props.isReadOnly}
            required={this.props.required}
            spellCheck={this.props.isSpellCheckEnabled}
            type={this.props.type}
            value={this.props.value}
          />
        </Base>
      </Wrapper>
    );
  }
}

TextField.defaultProps = {
  compact: false,
  disabled: false,
  isInvalid: false,
  isReadOnly: false,
  isSpellCheckEnabled: true,
  maskChar: '_',
  onChange: () => {},
  required: false,
  type: 'text',
  isValidationHidden: false,
  innerRef: () => {}
};

TextField.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  form: PropTypes.string,
  pattern: PropTypes.string,
  compact: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  id: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  invalidMessage: PropTypes.node,
  shouldFitContainer: PropTypes.bool,
  isSpellCheckEnabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  isValidationHidden: PropTypes.bool
};

export default TextField;
