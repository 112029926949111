import styled from 'styled-components';

const Right = styled.div`
  font-size: 16px;
  width: 660px;

  @media (max-width: 1020px) {
    font-size: 14px;
    margin-top: 40px;
    width: calc(100% - 30px);
  }
`;

export default Right;
