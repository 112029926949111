import React, { Fragment } from 'react';
import StaffRootView from 'navigation/routes/StaffRootView';
import SetActiveView from 'navigation/routes/SetActiveView';
import ReportsPage from 'pages/IndividualProfile/Reports';
import CompareReports from "pages/IndividualProfile/CompareReports";

const IndividualCompareView = () => (
  <Fragment>
    <StaffRootView />
    <SetActiveView id="individual/index" />
    <CompareReports />
  </Fragment>
);

export default IndividualCompareView;
