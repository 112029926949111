import styled from 'styled-components';

const P = styled.p`
  margin-top: ${props => (props.marginTop ? props.marginTop : '16px')};
  max-width: 500px;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

export default P;
