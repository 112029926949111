import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
import * as moment from 'moment';
import 'moment/locale/ru';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';

import NameWrapper from './styled/NameWrapper';

class PartnersTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'name',
        content: 'Имя, Фамилия',
        isSortable: false,
        width: 20
      },
      {
        key: 'registration',
        content: 'Дата регистрации',
        shouldTruncate: true,
        isSortable: false,
        width: 20
      },
      {
        key: 'email',
        content: 'Email',
        shouldTruncate: true,
        isSortable: false,
        width: 20
      },
      {
        key: 'phone',
        content: 'Телефон',
        shouldTruncate: true,
        width: 20
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              <Link to={`/partner/partners/${item.id}`}>
                {item.first_name} {item.last_name}
              </Link>
            </NameWrapper>
          )
        },
        {
          key: uniqid(),
          content: moment(item.date_joined).format('DD.MM.YYYY')
        },
        {
          key: uniqid(),
          content: item.email
        },
        {
          key: uniqid(),
          content: item.phone_number
        }
      ]
    }));

  render() {
    const { data, isLoading } = this.props;

    return (
      <DynamicTable
        head={this.generateHead()}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        onSetPage={() => {}}
        onSort={() => {}}
        rows={this.generateRows(data)}
      />
    );
  }
}

export default PartnersTable;
