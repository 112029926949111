import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isInn } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import RouterLink from 'components/RouterLink';
import TextField from 'components/TextField';

import reducer from 'pages/PartnerProfile/Requisites/reducer';
import saga from 'pages/PartnerProfile/Requisites/saga';

import {
  changeRequisitesRegistrationField,
  registerNewRequisites,
  resetRequisitesRegistrationForm
} from 'pages/PartnerProfile/Requisites/actions';
import {
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading
} from 'pages/PartnerProfile/Requisites/selectors';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class Registration extends Component {
  state = {
    formValid: {
      name: true,
      inn: true
    }
  };

  componentDidMount() {
    const { resetRequisitesRegistrationForm } = this.props;

    resetRequisitesRegistrationForm();
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeFormField = event => {
    const {
      props: { changeRequisitesRegistrationField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeRequisitesRegistrationField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { registerNewRequisites, registrationForm },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: registrationForm[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      registerNewRequisites();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'name':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              name: result
            }
          });
        }
        return result;
      case 'inn':
        result = !isEmpty(text) && isInn(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              inn: result
            }
          });
        }
        return result;
      default:
        return result;
    }
  };

  renderActions = () => (
    <ButtonGroup>
      <Button href="/partner/requisites" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  renderBreadcrumbs = () => (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        href="/partner/requisites"
        onClick={event => this.onBreadcrumbsClick(event, '/partner/requisites')}
        text="Настройки"
        key="settings"
      />
      <BreadcrumbsItem
        href="/partner/requisites"
        onClick={event => this.onBreadcrumbsClick(event, '/partner/requisites')}
        text="Реквизиты"
        key="partnerRequisites"
      />
    </BreadcrumbsStateless>
  );

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { loading, registrationForm },
      state: { formValid }
    } = this;

    return (
      <Form>
        <Field width="100%">
          <TextField
            isInvalid={!formValid.name}
            required
            label="Название организации"
            name="name"
            id="name"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.name}
          />
        </Field>
        <Field width="100%">
          <TextField
            isInvalid={!formValid.inn}
            required
            label="ИНН"
            name="inn"
            id="inn"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.inn}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="КПП"
            name="kpp"
            id="kpp"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.kpp}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="ОГРН"
            name="ogrn"
            id="ogrn"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.ogrn}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Индекс"
            name="index"
            id="index"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.index}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Юридический адрес"
            name="address"
            id="address"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.address}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Расчетный счет"
            name="account_number"
            id="account_number"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.account_number}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Наименование банка"
            name="bank_name"
            id="bank_name"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.bank_name}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="БИК банка"
            name="bank_bik"
            id="bank_bik"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.bank_bik}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="БИК банка"
            name="bank_bik"
            id="bank_bik"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.bank_bik}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Кор. счет"
            name="correspondent_account_number"
            id="correspondent_account_number"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={registrationForm.correspondent_account_number}
          />
        </Field>
        <FieldGroup>
          <ButtonWrapper>
            <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
              Зарегистрировать
            </Button>
          </ButtonWrapper>
        </FieldGroup>
      </Form>
    );
  };

  render() {
    return (
      <Container>
        <Helmet>
          <title>Добавить организацию</title>
        </Helmet>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.renderActions()} breadcrumbs={this.renderBreadcrumbs()}>
              Добавить организацию
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{this.renderForm()}</GridColumn>
        </Grid>
      </Container>
    );
  }
}

Registration.propTypes = {
  changeRequisitesRegistrationField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  registerNewRequisites: PropTypes.func.isRequired,
  registrationForm: PropTypes.object.isRequired,
  resetRequisitesRegistrationForm: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeRequisitesRegistrationField: value => dispatch(changeRequisitesRegistrationField(value)),
    registerNewRequisites: () => dispatch(registerNewRequisites()),
    resetRequisitesRegistrationForm: () => dispatch(resetRequisitesRegistrationForm())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectRegistrationLoading(),
  registrationForm: makeSelectRegistrationForm()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerRequisites', reducer });
const withSaga = injectSaga({ key: 'partnerRequisites', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Registration);
