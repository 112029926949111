import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import isNull from 'lodash/isNull';

const Wrapper = styled.div`
  background-color: #f0f2f5;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 25px;
  padding: 35px 20px;
  width: 100%;
`;

const Name = styled.h3`
  color: #0052cc;
`;

const PlanItem = styled.div`
  margin: 30px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h5`
  margin: 0;
`;

const Value = styled.h2`
  margin: 0;
`;

const Rouble = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};
`;

const Text = styled.div`
  margin-top: 13px;
`;

const P = styled.p`
  color: #6b778c;
  margin: 0;
`;

const Plan = ({ activateHandle, plan }) => (
  <Fragment>
    <Wrapper>
      <Name>{plan.name}</Name>
      <PlanItem>
        <Title>Включено отчетов</Title>
        <Value>{plan.included_reports_count.toLocaleString()}</Value>
      </PlanItem>
      <PlanItem>
        <Title>Каждый дополнительный отчет</Title>
        <Value>
          от {plan.ranges[plan.ranges.length - 1].price_per_report.toLocaleString()} до{' '}
          {plan.ranges[0].price_per_report.toLocaleString()} <Rouble>₽</Rouble>
        </Value>
      </PlanItem>
      <PlanItem>
        <Title>Абонентская плата в год</Title>
        <Value>
          {plan.price_per_year.toLocaleString()} <Rouble>₽</Rouble>
        </Value>
      </PlanItem>
      <PlanItem>
        <Button
          appearance="primary"
          onClick={() =>
            activateHandle({
              activatePlanId: plan.id
            })
          }
          shouldFitContainer
        >
          Активировать
        </Button>
      </PlanItem>
    </Wrapper>
    <Text>
      <P>Стоимость дополнительных отчетов:</P>
      {plan.ranges.map(range => {
        if (isNull(range.quantity_range.upper)) {
          return (
            <P key={range.quantity_range.lower}>
              от {range.quantity_range.lower.toLocaleString()} —{' '}
              {range.price_per_report.toLocaleString()} <Rouble fontWeight={300}>₽</Rouble> за
              отчет;
            </P>
          );
        }

        return (
          <P key={range.quantity_range.lower}>
            от {range.quantity_range.lower.toLocaleString()} до{' '}
            {(parseInt(range.quantity_range.upper, 10) - 1).toLocaleString()} —{' '}
            {range.price_per_report.toLocaleString()} <Rouble fontWeight={300}>₽</Rouble> за отчет;
          </P>
        );
      })}
    </Text>
  </Fragment>
);

export default Plan;
