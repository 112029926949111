import styled from 'styled-components';

const ButtonsContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: ${props => props.align};
  width: 100%;
`;

export default ButtonsContainer;
