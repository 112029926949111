import styled from 'styled-components';

const Name = styled.div`
  color: #172b4d;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  max-width: 85px;
  word-spacing: 100vw;
`;

export default Name;
