import styled from 'styled-components';

const TabTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #172b4d;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin-top: 35px;
`;

export default TabTitle;
