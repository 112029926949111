import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerClients');

const makeSelectClientsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'count'])
  );

const makeSelectClientsList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'list']).toJS()
  );

const makeSelectClientsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'loading'])
  );

const makeSelectClientsSearch = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'search'])
  );

const makeSelectClientsIsMineFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'isMineFilter'])
  );

const makeSelectClientsPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['clients', 'page'])
  );

const makeSelectRegistrationFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'flags']).toJS()
  );

const makeSelectRegistrationForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'form']).toJS()
  );

const makeSelectRegistrationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'loading'])
  );

const makeSelectClientComment = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['client', 'form', 'comment'])
  );

const makeSelectClientFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['client', 'flags']).toJS()
  );

const makeSelectClientFormLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['client', 'formLoading'])
  );

const makeSelectClientLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['client', 'loading'])
  );

const makeSelectClientRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['client', 'read']).toJS()
  );

const makeSelectManagersCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['managers', 'count'])
  );

const makeSelectManagersList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['managers', 'list']).toJS()
  );

const makeSelectManagersLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['managers', 'loading'])
  );

const makeSelectManagersPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['managers', 'page'])
  );

const makeSelectManagersSearch = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['managers', 'search'])
  );

const makeSelectRequisitesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'count'])
  );

const makeSelectRequisitesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'list']).toJS()
  );

const makeSelectRequisitesLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'loading'])
  );

const makeSelectRequisitesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['requisites', 'page'])
  );

const makeSelectFinancesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'count'])
  );

const makeSelectFinancesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'list']).toJS()
  );

const makeSelectFinancesLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'loading'])
  );

const makeSelectFinancesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'page'])
  );

const makeSelectFinancesRewardAmountSum = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'rewardAmountSum'])
  );

const makeSelectFinancesFromDateFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'fromDateFilter'])
  );

const makeSelectFinancesToDateFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'toDateFilter'])
  );

const makeSelectInviteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'modals']).toJS()
  );

const makeSelectInviteFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'flags']).toJS()
  );

const makeSelectInviteLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'loading'])
  );

const makeSelectInviteForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['invite', 'form']).toJS()
  );

export {
  makeSelectClientsCount,
  makeSelectClientsList,
  makeSelectClientsLoading,
  makeSelectClientsPage,
  makeSelectClientsSearch,
  makeSelectClientsIsMineFilter,
  makeSelectRegistrationFlags,
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading,
  makeSelectClientComment,
  makeSelectClientFlags,
  makeSelectClientFormLoading,
  makeSelectClientLoading,
  makeSelectClientRead,
  makeSelectManagersCount,
  makeSelectManagersList,
  makeSelectManagersLoading,
  makeSelectManagersPage,
  makeSelectManagersSearch,
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage,
  makeSelectFinancesCount,
  makeSelectFinancesList,
  makeSelectFinancesLoading,
  makeSelectFinancesPage,
  makeSelectFinancesRewardAmountSum,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter,
  makeSelectInviteModals,
  makeSelectInviteForm,
  makeSelectInviteFlags,
  makeSelectInviteLoading
};
