import { createSelector } from 'reselect';

const selectGlobal = state => state.get('login');

const makeSelectLogin = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('login')
  );

const makeSelectPassword = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('password')
  );

const makeSelectError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('error')
  );

const makeSelectFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('flags').toJS()
  );

const makeSelectOtpLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['otp', 'loading'])
  );

export {
  makeSelectError,
  makeSelectFlags,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectOtpLoading
};
