import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isInn } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';

import reducer from 'pages/PartnerProfile/Requisites/reducer';
import saga from 'pages/PartnerProfile/Requisites/saga';

import {
  changeOrganizationField,
  getOrganization,
  updateOrganization
} from 'pages/PartnerProfile/Requisites/actions';
import {
  makeSelectOrganizationForm,
  makeSelectOrganizationLoading,
  makeSelectOrganizationRead,
  makeSelectOrganizationUpdateLoading
} from 'pages/PartnerProfile/Requisites/selectors';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';

class Edit extends Component {
  state = {
    formValid: {
      name: true,
      inn: true
    }
  };

  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const { getOrganization } = this.props;

    getOrganization({
      id
    });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeFormField = event => {
    const {
      props: { changeOrganizationField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeOrganizationField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { organizationForm, organizationRead, updateOrganization },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: organizationForm[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      updateOrganization({
        id: organizationRead.id
      });
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'name':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              name: result
            }
          });
        }
        return result;
      case 'inn':
        result = !isEmpty(text) && isInn(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              inn: result
            }
          });
        }
        return result;
      default:
        return result;
    }
  };

  renderActions = () => (
    <ButtonGroup>
      <Button href="/partner/requisites" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  renderBreadcrumbs = () => {
    const {
      props: { organizationRead }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/requisites"
          onClick={event => this.onBreadcrumbsClick(event, '/partner/requisites')}
          text="Настройки"
          key="settings"
        />
        <BreadcrumbsItem
          href="/partner/requisites"
          onClick={event => this.onBreadcrumbsClick(event, '/partner/requisites')}
          text="Реквизиты"
          key="partnerRequisites"
        />
        <BreadcrumbsItem
          href={`/partner/requisites/${organizationRead.id}`}
          onClick={event =>
            this.onBreadcrumbsClick(event, `/partner/requisites/${organizationRead.id}`)
          }
          text={organizationRead.name}
          key={`partnerRequisites${organizationRead.id}`}
        />
      </BreadcrumbsStateless>
    );
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { organizationForm, updateLoading },
      state: { formValid }
    } = this;

    return (
      <Form>
        <Field width="100%">
          <TextField
            isInvalid={!formValid.name}
            required
            label="Название организации"
            name="name"
            id="name"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.name}
          />
        </Field>
        <Field width="100%">
          <TextField
            isInvalid={!formValid.inn}
            required
            label="ИНН"
            name="inn"
            id="inn"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.inn}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="КПП"
            name="kpp"
            id="kpp"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.kpp}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="ОГРН"
            name="ogrn"
            id="ogrn"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.ogrn}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Индекс"
            name="index"
            id="index"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.index}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Юридический адрес"
            name="address"
            id="address"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.address}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Расчетный счет"
            name="account_number"
            id="account_number"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.account_number}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Наименование банка"
            name="bank_name"
            id="bank_name"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.bank_name}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="БИК банка"
            name="bank_bik"
            id="bank_bik"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.bank_bik}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="БИК банка"
            name="bank_bik"
            id="bank_bik"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.bank_bik}
          />
        </Field>
        <Field width="100%">
          <TextField
            label="Кор. счет"
            name="correspondent_account_number"
            id="correspondent_account_number"
            onBlur={onChangeFormField}
            onChange={onChangeFormField}
            placeholder=""
            shouldFitContainer
            value={organizationForm.correspondent_account_number}
          />
        </Field>
        <FieldGroup>
          <ButtonWrapper>
            <Button appearance="primary" isLoading={updateLoading} onClick={onSubmitForm}>
              Зарегистрировать
            </Button>
          </ButtonWrapper>
        </FieldGroup>
      </Form>
    );
  };

  renderContent = () => {
    const {
      props: { loading, organizationRead },
      renderActions,
      renderForm
    } = this;

    if (loading) {
      return (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <Spinner height="80vh" />
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={renderActions()} breadcrumbs={this.renderBreadcrumbs()}>
            {organizationRead.name}
          </PageHeader>
        </GridColumn>
        <GridColumn medium={12}>{renderForm()}</GridColumn>
      </Grid>
    );
  };

  render() {
    const {
      props: { organizationRead },
      renderContent
    } = this;

    return (
      <Container>
        <Helmet>
          <title>{organizationRead.name}</title>
        </Helmet>
        {renderContent()}
      </Container>
    );
  }
}

Edit.propTypes = {
  changeOrganizationField: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  organizationForm: PropTypes.object.isRequired,
  organizationRead: PropTypes.object.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  updateLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeOrganizationField: value => dispatch(changeOrganizationField(value)),
    getOrganization: value => dispatch(getOrganization(value)),
    updateOrganization: value => dispatch(updateOrganization(value))
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectOrganizationLoading(),
  organizationForm: makeSelectOrganizationForm(),
  organizationRead: makeSelectOrganizationRead(),
  updateLoading: makeSelectOrganizationUpdateLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerRequisites', reducer });
const withSaga = injectSaga({ key: 'partnerRequisites', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Edit);
