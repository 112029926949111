import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import SettingsInvitesPage from 'pages/Settings/subpages/Invites';

const SettingsInvitesView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <SettingsInvitesPage />
  </Fragment>
);

export default SettingsInvitesView;
