import React, { Component } from 'react';
import uniqid from 'uniqid';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';

import NameWrapper from './styled/NameWrapper';

class ManagersTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'name',
        content: 'Имя, фамилия',
        isSortable: false,
        width: 25
      },
      {
        key: 'email',
        content: 'Email',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'phone',
        content: 'Телефон',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'role',
        content: 'Роль',
        shouldTruncate: true,
        width: 25
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: <NameWrapper>{item.full_name}</NameWrapper>
        },
        {
          key: uniqid(),
          content: item.email
        },
        {
          key: uniqid(),
          content: item.phone_number
        },
        {
          key: uniqid(),
          content: item.role === 0 ? 'Администратор' : 'Менеджер'
        }
      ]
    }));

  render() {
    const { data, isLoading } = this.props;

    return (
      <DynamicTable
        head={this.generateHead()}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        onSetPage={() => {}}
        onSort={() => {}}
        rows={this.generateRows(data)}
      />
    );
  }
}

export default ManagersTable;
