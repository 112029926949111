import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PersonCompareInfoBase from './components/PersonCompareInfoBase';

const PersonCompareInfoView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <PersonCompareInfoBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default PersonCompareInfoView;
