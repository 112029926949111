import styled from 'styled-components';

const Progress = styled.span`
  position: absolute;
  left: ${props => `${props.position}%`};
  transform: translateX(-50%);
  z-index: 10;
  top: -42px;

  &:after {
    background-color: ${props => props.lineColor};
    content: '';
    display: block;
    height: 56px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 2px;
  }
`;

export default Progress;
