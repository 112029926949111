import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import { Grid, GridColumn } from '@atlaskit/page';
import Button from '@atlaskit/button';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import RouterA from 'components/RouterA';
import RouterLink from 'components/RouterLink';

import reducer from 'pages/TestQuestion/reducer';
import testStartReducer from 'pages/TestStart/reducer';
import saga from 'pages/TestQuestion/saga';
import testStartSaga from 'pages/TestStart/saga';

import { getQuestionnarieList } from 'pages/TestQuestion/actions';
import { checkOrder } from 'pages/TestStart/actions';

import { makeSelectLoading } from 'pages/TestQuestion/selectors';
import { makeSelectIsPaid } from 'pages/TestStart/selectors';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Iframe from './styled/Iframe';
import P from './styled/P';
import Title from './styled/Title';
import Video from './styled/Video';

import service from 'utils/checkService';

class TestAbout extends React.Component {
  constructor(props) {
    super(props);

    const invitationCode = this.props.match.params.invitationCode;
    props.getQuestionnarieList({
      invitationCode
    });
    const orderId = this.props.match.params.orderId;
    props.checkOrder({ orderId })
  }

  renderButton = () => {
    const invitationCode = this.props.match.params.invitationCode;
    let text = 'Начать опрос';
    let link = `/test/question/1`;

    if (!isUndefined(invitationCode)) {
      link = `/test/question/1/${invitationCode}`;
    }

    let component = RouterLink;

    return (
      <Button appearance="primary" href={link} component={component}>
        {text}
      </Button>
    );
  };

  render() {
    const { loading } = this.props;

    let content = (
      <>
        <P>
          • 40 вопросов
        </P>

        <P>
          • 25-30 мин время прохождения
        </P>

        <P>
          • Пожалуйста, ознакомьтесь с видео инструкцией, время просмотра 30 секунд.
        </P>

        <P>
          Внимание! Проходите опрос вдумчиво, так как повторное прохождение опроса возможно только через год.
        </P>

        <Video marginTop="40px">
          <Iframe
            src="https://www.youtube.com/embed/EA3SHrw7uHk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      </>
    );

    if (service == "my-yadro") {
      content = (
        <>
          <P>
            • Время прохождения — от 20 до 30 минут
          </P>
  
          <P>
            • В опросе 36 вопросов
          </P>
  
          <P>
            • Вы получите отчёт не сразу после прохождения, так как его формирование занимает время.
          </P>

          <P>
            • Проверьте почту — отчёт придёт в течение 24 часов. Если Вы не видите письмо во входящих, просмотрите папки «спам» и «промоакции».
          </P>

          <P>
            • В отчёте будет расшифровка результатов. Подробнее раскрыть каждый критерий Вы сможете по ходу курса.
          </P>

          <P>
            • Ознакомьтесь, пожалуйста, с видео-инструкцией. Время просмотра — 30 секунд.
          </P>
  
          <P>
          Важно! Проходите опрос внимательно и вдумчиво, повторное прохождение возможно только через год.
          </P>
  
          <Video marginTop="40px">
            <Iframe
              src="https://www.youtube.com/embed/pSw4yWLlnjs"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Video>
        </>
      );
    }

    return (
      <Container>
        {!loading && (
          <Grid layout="fluid">
            <GridColumn medium={12}>
              <Title>Инструкция</Title>

              {content}

              <ButtonWrapper>{this.renderButton()}</ButtonWrapper>
            </GridColumn>
          </Grid>
        )}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getQuestionnarieList: value => dispatch(getQuestionnarieList(value)),
    checkOrder: value => dispatch(checkOrder(value))
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  isPaid: makeSelectIsPaid()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'test', reducer });
const withTestStartReducer = injectReducer({ key: 'testStart', reducer: testStartReducer });
const withSaga = injectSaga({ key: 'test', saga });
const withTestStartSaga = injectSaga({ key: 'testStart', saga: testStartSaga });

export default compose(
  withRouter,
  withReducer,
  withTestStartReducer,
  withSaga,
  withTestStartSaga,
  withConnect
)(TestAbout);
