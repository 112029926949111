import styled from 'styled-components';

const Label = styled.label`
  width: 100%;

  & .label-text {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 4px;
    margin-top: 0;
  }
`;

export default Label;
