import { createSelector } from 'reselect';

const selectGlobal = state => state.get('individualLogin');

const makeSelectLogin = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('login')
  );

const makeSelectPassword = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('password')
  );

const makeSelectError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('error')
  );

const makeSelectFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('flags').toJS()
  );

export { makeSelectError, makeSelectFlags, makeSelectLogin, makeSelectPassword };
