import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerUser');

const makeSelectUserLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'loading'])
  );

const makeSelectUserRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'read']).toJS()
  );

const makeSelectUserForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'form']).toJS()
  );

const makeSelectUserUpdateLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'updateLoading'])
  );

const makeSelectUserFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'flags']).toJS()
  );

const makeSelectUserPassword = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['user', 'formPassword']).toJS()
  );

export {
  makeSelectUserLoading,
  makeSelectUserRead,
  makeSelectUserForm,
  makeSelectUserUpdateLoading,
  makeSelectUserFlags,
  makeSelectUserPassword
};
