import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import 'moment/locale/ru';

import Form from '@atlaskit/form';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Circle from 'components/Circle';
import Comment from 'components/Comment';
import Info from 'components/Info';
import MetaWithComment from 'components/MetaWithComment';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import Field from 'pages/Home/styled/Field';
import FieldGroup from 'pages/Home/styled/FieldGroup';
import Dislike from 'icons/Dislike';
import Fire from 'icons/Fire';
import Like from 'icons/Like';

import { isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeCommentText,
  changeEmailFlags,
  changeEmailModals,
  changeEmailText,
  createCommentRequest,
  createEditComment,
  createNewCommentBlock,
  deleteComment,
  createDislike,
  dislikeSection,
  dislikeSectionFail,
  fireSection,
  hideCommentBlock,
  likeSection,
  getReportList,
  sendEmail,
  changeOrderReportByRank
} from './actions';

import reducer from './reducer';
import saga from './saga';

import Conclusion from './styled/Conclusion';
import ConclusionTitle from './styled/ConclusionTitle';
import Container from './styled/Container';
import Empty from "../../../components/Empty";
import IconsText from './styled/IconsText';
import IconsWrapper from './styled/IconsWrapper';
import Header from '../../../components/Empty/styled/Header';
import TextContainer from '../../../components/Empty/styled/Container';
import TextP from '../../../components/Empty/styled/P';
import Label from './styled/Label';
import LegendWrapper from './styled/LegendWrapper';
import LegendTitle from './styled/LegendTitle';
import LegendText from './styled/LegendText';
import MetaReactionsWrapper from './styled/MetaReactionsWrapper';
import MetaSubtitle from './styled/MetaSubtitle';
import MetaTitle from './styled/MetaTitle';
import MetaWrapper from './styled/MetaWrapper';
import P from './styled/P';
import SelectWrapper from "../../Report/styled/SelectWrapper";
import Select from "@atlaskit/select";
import { fromJS } from "immutable";

const orderOptions = [
  {
    label: 'Стандартно',
    value: 0
  },
  {
    label: 'По значимости',
    value: 1
  }
];

class Reports extends Component {
  orderClicked = false;
  positionX = 0;
  positionY = 0;
  scrollY = 0;
  selectionEndTimeout = null;
  anotherMeta = React.createRef();
  thirdMeta = React.createRef();

  state = {
    emailValid: true,
    showPayFlag: [],
    wasShowed: false
  };

  componentDidMount() {
    this.props.getReportList();

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('selectionChangeEnd', this.selectionChanged);
    document.addEventListener('mouseup', () => {
      const mouseX = this.positionX;
      const mouseY = this.positionY;

      const event = new CustomEvent('selectionChangeEnd', {
        detail: {
          mouseX,
          mouseY
        }
      });

      document.dispatchEvent(event);
    });
  }

  onChangeEmail = event => {
    this.setState({
      emailValid: isEmail(event.target.value)
    });

    this.props.changeEmailText({
      email: event.target.value
    });
  };

  onCloseModal = id => {
    this.props.changeEmailModals({
      modals: this.props.reportModal.modals.filter(i => i !== id)
    });
  };

  onMouseMove = event => {
    this.positionX = event.pageX;
    this.positionY = event.pageY;
  };

  onOpenModal = () => {
    this.props.changeEmailModals({
      modals: [1]
    });
  };

  onRemoveFlag = id => {
    this.props.changeEmailFlags({ flags: this.props.reportModal.flags.filter(v => v !== id) });
  };

  onRemovePayFlag = id => {
    const { showPayFlag } = this.state;

    this.setState({
      showPayFlag: showPayFlag.filter(v => v !== id)
    });
  };

  onSendModal = () => {
    this.props.sendEmail();
  };

  onDislikeClick = (sectionId, subsectionId) => {
    const { createDislike, dislikeSection, list } = this.props;

    const currentSubsection = list.getIn([
      sectionId.toString(),
      'subsections',
      subsectionId.toString()
    ]);
    if (currentSubsection.get('comments').size === 0) {
      const subsection = document.getElementById(`id-${sectionId}-${subsectionId}`);
      const rect = subsection.getBoundingClientRect();

      const x = rect.left + rect.width + window.scrollX - 10;
      const y = rect.top + rect.height + window.scrollY - 10;

      const comment = {
        x: x - 180,
        y,
        isDislike: true,
        textRangeFrom: 0,
        textRangeTo: subsection.textContent.length,
        selectedText: subsection.textContent,
        sectionId,
        subsectionId
      };

      subsection.childNodes[0].innerHTML = `<span id="selected-text-${comment.textRangeFrom}-${comment.textRangeTo
        }" class="selected-text">${subsection.textContent}</span>`;

      createDislike(comment);
    } else {
      dislikeSection({
        sectionId,
        subsectionId
      });
    }
  };

  onHideCommentBlock = () => {
    const { comment, dislikeSectionFail, hideCommentBlock } = this.props;

    if (comment.forDislike) {
      dislikeSectionFail();
    }

    hideCommentBlock();
  };

  selectionChanged = event => {
    this.selectionEndTimeout = null;

    const selectedText = document.getSelection();
    if (
      selectedText.anchorNode === selectedText.focusNode &&
      selectedText.anchorOffset !== selectedText.focusOffset &&
      selectedText.anchorNode.parentNode.parentNode.classList.contains('subsection-text') &&
      !selectedText.anchorNode.parentNode.classList.contains('highlight')
    ) {
      const sectionId = parseInt(
        selectedText.anchorNode.parentNode.parentNode.dataset.sectionId,
        10
      );
      const subsectionId = parseInt(
        selectedText.anchorNode.parentNode.parentNode.dataset.subsectionId,
        10
      );

      const childText = [];
      selectedText.anchorNode.parentNode.parentNode.childNodes.forEach((node, i) => {
        childText.push({
          node,
          key: i
        });
      });

      let commonSymbolCounter = 0;
      let i = 0;
      const selectedChild = childText.find(
        child => child.node === selectedText.anchorNode.parentNode
      );
      while (i < selectedChild.key) {
        commonSymbolCounter += childText[i].node.textContent.length;
        i += 1;
      }

      let anchorOffset = selectedText.anchorOffset;
      let focusOffset = selectedText.focusOffset;

      if (anchorOffset > focusOffset) {
        anchorOffset = selectedText.focusOffset;
        focusOffset = selectedText.anchorOffset;
      }

      const comment = {
        x: event.detail.mouseX,
        y: event.detail.mouseY,
        textRangeFrom: commonSymbolCounter + anchorOffset,
        textRangeTo: commonSymbolCounter + focusOffset,
        selectedText: selectedText.toString(),
        sectionId,
        subsectionId
      };

      const selectedString = selectedText.anchorNode.textContent.slice(anchorOffset, focusOffset);
      selectedText.anchorNode.parentNode.innerHTML =
        selectedText.anchorNode.parentNode.textContent.substr(0, anchorOffset) +
        `<span id="selected-text-${comment.textRangeFrom}-${comment.textRangeTo
        }" class="selected-text">${selectedString}</span>` +
        selectedText.anchorNode.parentNode.textContent.substr(focusOffset);

      this.props.createNewCommentBlock(comment);
    }
  };

  renderMetaprogramm() {
    const { createEditComment, fireSection, likeSection, list, reportShape, order } = this.props;
    const jsList = list.toJS()

    let reportArray = [];
    for (let key in jsList) {
      reportArray.push(jsList[key])
    }

    let report = reportArray;

    if (reportShape != -1) {
      report = reportArray.slice(reportShape, reportShape + 1);
    }

    let orderedReport;
    if (order) {
      orderedReport = report.sort((item1, item2) => item2.rank - item1.rank);
    } else {
      orderedReport = report.sort((item1, item2) => item1.id - item2.id);
    }

    orderedReport = fromJS(orderedReport);

    return orderedReport.map((section, index) => {
      let jsSubsections = section.get('subsections').toJS();

      let subsections = [];
      for (let key in jsSubsections) {
        subsections.push(jsSubsections[key])
      }

      subsections = subsections.sort((item1, item2) => item1.id - item2.id);

      subsections = fromJS(subsections);

      return (
        <MetaWrapper
          key={section.get('id')}
          innerRef={index === 2 ? this.thirdMeta : this.anotherMeta}
        >
          <MetaTitle>
            <Circle rank={section.get('rank')} />
            <h4>{section.get('title')}</h4>
            {!isEmpty(section.get('info')) && <Info isBig text={section.get('info')} />}
          </MetaTitle>
          {subsections
            .map(subsection => (
              <div key={subsection.get('id')}>
                <MetaSubtitle>
                  <h5>
                    {subsection.get('title')} — {subsection.get('value')}%
                    {!isEmpty(subsection.get('info')) && <Info isBig text={subsection.get('info')} />}
                  </h5>
                  <MetaReactionsWrapper>
                    <Dislike
                      active={subsection.get('like_type') === 0}
                      onClick={() => this.onDislikeClick(section.get('id'), subsection.get('id'))}
                    />
                    <Like
                      active={subsection.get('like_type') === 1}
                      onClick={() =>
                        likeSection({
                          sectionId: section.get('id'),
                          subsectionId: subsection.get('id')
                        })
                      }
                    />
                    <Fire
                      active={subsection.get('like_type') === 2}
                      onClick={() =>
                        fireSection({
                          sectionId: section.get('id'),
                          subsectionId: subsection.get('id')
                        })
                      }
                    />
                  </MetaReactionsWrapper>
                </MetaSubtitle>
                <MetaWithComment
                  createEditCommentBlock={createEditComment}
                  sectionId={section.get('id')}
                  subsection={subsection}
                />
              </div>
            ))}
        </MetaWrapper>
      )
    });
  }

  renderConclusion() {
    const { likesCount, subsectionsCount } = this.props;
    const coincidence = Math.floor((likesCount / subsectionsCount) * 100);

    let backgroundColor = '#006644';

    if (coincidence < 31) {
      backgroundColor = '#A62100';
    } else if (coincidence < 71) {
      backgroundColor = '#CC7000';
    }

    return (
      <Conclusion backgroundColor={backgroundColor}>
        <ConclusionTitle>Отчет совпадает с вами на {coincidence}%</ConclusionTitle>
      </Conclusion>
    );
  }

  onOrderByChange = event => {
    this.props.changeOrderReportByRank({
      order: event.value === 1
    });
  }

  render() {
    const {
      changeCommentText,
      createdAt,
      createCommentRequest,
      comment,
      deleteComment,
      deleteLoading,
      loading,
      reportModal,
      user,
      order,
      noContentError,
      list
    } = this.props;

    const { emailValid } = this.state;

    const button = (
      <ButtonGroup>
        <Button className="media-print-hide" onClick={this.onOpenModal}>
          Отправить
        </Button>
        <Button
          className="media-print-hide"
          onClick={() => {
            window.print();
          }}
        >
          Печать
        </Button>
      </ButtonGroup>
    );

    let content = <Spinner height="80vh" />;

    if (noContentError) {
      if (user.is_tested === false) {
        this.props.history.push('/test/about/')
      } else {
        content = (
          <Empty
            header="Отчет генерируется…"
            text="Дорогой друг!

            Ты большой молодец! Первый шаг сделан!

            Мы анализируем твои ответы.

            Следующие шаги жди в письме на электронной почте."
          />
        )
      }
    } else if (!loading) {
      if (!list.size) {
        content = (
          <Empty
            header="Мы анализируем ваши ответы и готовим отчет!"
            text="В течение 24 часов отчет будет готов и вы получите уведомление на почту!"
          />
        )
      } else {
        content = (
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader actions={button}>
                {`${user.name} ${user.surname}`} — отчёт по оценке потенциала
              </PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <div>
                <Label>Дата тестирования</Label>
                <P>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</P>
              </div>
              <div>
                <br />
                <h2>
                Ваш отчёт по категориям мышления. Он поможет увидеть Ваши сильные стороны и зоны роста.
                Мы будем разбирать его в течение всего курса.
                </h2>
                <h2>
                В результате этого вы сможете выстроить свою персональную стратегию по улучшению этих показателей и качества жизни.
                </h2>
                <h2>
                Вперед!🚀🚀🚀
                </h2>
              </div>
            </GridColumn>
            <GridColumn medium={12}>
              <LegendWrapper className="media-print-hide">
                <LegendTitle>Нам важно знать Ваше мнение</LegendTitle>
                <LegendText>
                  Мы&nbsp;постоянно улучшаем наш сервис и&nbsp;стараемся сделать интерпретации точнее.
                  Пожалуйста, поставьте свою оценку каждой интерпретации по&nbsp;следующей системе:
                </LegendText>
                <IconsWrapper>
                  <Dislike withoutHover />
                  <IconsText>— «Это не про меня!»</IconsText>
                </IconsWrapper>
                <IconsWrapper>
                  <Like withoutHover />
                  <IconsText>— «Все верно»</IconsText>
                </IconsWrapper>
                <IconsWrapper>
                  <Fire withoutHover />
                  <IconsText>— «Да, точнее и не сказать!»</IconsText>
                </IconsWrapper>
              </LegendWrapper>
            </GridColumn>
            <GridColumn medium={6}>
              <SelectWrapper>
                <Label>Упорядочить</Label>
                <SelectWrapper marginTop="5px">
                  <Select
                    className="single-select"
                    defaultOptions={orderOptions[0]}
                    options={orderOptions}
                    isSearchable={false}
                    onChange={this.onOrderByChange}
                    placeholder="Упорядочить"
                    value={order ? orderOptions[1] : orderOptions[0]}
                  />
                </SelectWrapper>
              </SelectWrapper>
            </GridColumn>
            <GridColumn medium={12}>

              {this.renderMetaprogramm()}
              {this.renderConclusion()}
            </GridColumn>
          </Grid>
        );
      }
    }

    return (
      <Fragment>
        <Container>{content}</Container>
        <Comment
          onChange={changeCommentText}
          onClose={this.onHideCommentBlock}
          onDelete={deleteComment}
          onSubmit={createCommentRequest}
          id={comment.id}
          isDeleteLoading={deleteLoading}
          isEdit={comment.forEdit}
          isLoading={comment.loading}
          positionX={comment.x}
          positionY={comment.y}
          sectionId={comment.sectionId}
          show={comment.show}
          subsectionId={comment.subsectionId}
          text={comment.text}
          textRangeFrom={comment.textRangeFrom}
          textRangeTo={comment.textRangeTo}
        />

        {reportModal.modals.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Отправить отчет"
            onClose={this.onCloseModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.onSendModal,
                isLoading: reportModal.loading,
                type: 'submit'
              },
              {
                text: 'Отменить',
                onClick: this.onCloseModal
              }
            ]}
            width="small"
          >
            <p>Укажите e-mail, на который необходимо отправить отчет</p>
            <Form name="layout-example" onSubmit={this.onSendModal} onReset={() => { }} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!emailValid}
                    label="Email"
                    name="email"
                    onChange={this.onChangeEmail}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={reportModal.email}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.onRemoveFlag(name)}>
          {reportModal.flags.map(id => (
            <Flag
              description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
              isDismissAllowed
              id={id}
              icon={
                id > 0 ? (
                  <Tick label="Success" />
                ) : (
                  <Error label="Error" primaryColor={colors.R300} />
                )
              }
              key={`${id}`}
              title={id > 0 ? 'Отчет отправлен' : 'Ошибка'}
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

Reports.propTypes = {
  changeCommentText: PropTypes.func.isRequired,
  changeEmailFlags: PropTypes.func.isRequired,
  changeEmailModals: PropTypes.func.isRequired,
  changeEmailText: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  createDislike: PropTypes.func.isRequired,
  createEditComment: PropTypes.func.isRequired,
  createNewCommentBlock: PropTypes.func.isRequired,
  createCommentRequest: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  dislikeSection: PropTypes.func.isRequired,
  dislikeSectionFail: PropTypes.func.isRequired,
  fireSection: PropTypes.func.isRequired,
  getReportList: PropTypes.func.isRequired,
  hideCommentBlock: PropTypes.func.isRequired,
  likesCount: PropTypes.number.isRequired,
  likeSection: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  noContentError: PropTypes.bool.isRequired,
  reportModal: PropTypes.object.isRequired,
  sendEmail: PropTypes.func.isRequired,
  subsectionsCount: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  reportShape: PropTypes.number.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeCommentText: value => dispatch(changeCommentText(value)),
    changeEmailFlags: value => dispatch(changeEmailFlags(value)),
    changeEmailModals: value => dispatch(changeEmailModals(value)),
    changeEmailText: value => dispatch(changeEmailText(value)),
    createEditComment: value => dispatch(createEditComment(value)),
    createNewCommentBlock: value => dispatch(createNewCommentBlock(value)),
    createCommentRequest: () => dispatch(createCommentRequest()),
    createDislike: value => dispatch(createDislike(value)),
    deleteComment: value => dispatch(deleteComment(value)),
    dislikeSection: value => dispatch(dislikeSection(value)),
    dislikeSectionFail: () => dispatch(dislikeSectionFail()),
    hideCommentBlock: value => dispatch(hideCommentBlock(value)),
    fireSection: value => dispatch(fireSection(value)),
    likeSection: value => dispatch(likeSection(value)),
    getReportList: () => dispatch(getReportList()),
    sendEmail: () => dispatch(sendEmail()),
    changeOrderReportByRank: value => dispatch(changeOrderReportByRank(value)),
  };
}

const mapStateToProps = store => ({
  coincidence: store.getIn(['individualReport', 'report', 'coincidence']),
  comment: store.getIn(['individualReport', 'comment']).toJS(),
  createdAt: store.getIn(['individualReport', 'report', 'createdAt']),
  deleteLoading: store.getIn(['individualReport', 'deleteComment', 'loading']),
  likesCount: store.getIn(['individualReport', 'report', 'likesCount']),
  list: store.getIn(['individualReport', 'report', 'list']),
  loading: store.getIn(['individualReport', 'report', 'loading']),
  noContentError: store.getIn(['individualReport', 'report', 'noContentError']),
  reportModal: store.getIn(['individualReport', 'reportModal']).toJS(),
  subsectionsCount: store.getIn(['individualReport', 'report', 'subsectionsCount']),
  reportShape: store.getIn(['individualReport', 'reportShape']),
  user: store.getIn(['individualProfile', 'user']).toJS(),
  order: store.getIn(['individualReport', 'reportOrderByRank']),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'individualReport', reducer });
const withSaga = injectSaga({ key: 'individualReport', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Reports);
