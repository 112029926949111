import {
  CHANGE_NAME,
  CHANGE_PHONE,
  SEND_LEAD_REQUEST,
  SEND_LEAD_REQUEST_SUCCESS,
  SEND_LEAD_REQUEST_FAIL
} from './constants';

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    ...payload
  };
}

export function changePhone(payload) {
  return {
    type: CHANGE_PHONE,
    ...payload
  };
}

export function sendLead() {
  return {
    type: SEND_LEAD_REQUEST
  };
}

export function sendLeadSuccess() {
  return {
    type: SEND_LEAD_REQUEST_SUCCESS
  };
}

export function sendLeadFail() {
  return {
    type: SEND_LEAD_REQUEST_FAIL
  };
}
