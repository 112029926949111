import { createSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import { fromJS } from 'immutable';

const selectGlobal = state => state.get('staff');

const makeSelectStaffList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffList').toJS()
  );

const makeSelectStaffCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCount')
  );

const makeSelectStaffPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffPage')
  );

const makeSelectStaffLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectLoadingTable = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingTable')
  );

const makeSelectUserLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingUser')
  );

const makeSelectStaffError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('error')
  );

const makeSelectErrorCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('errorCode')
  );

const makeSelectStaffId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffId')
  );

const makeSelectStaffRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffRead').toJS()
  );

const makeSelectStaffReport = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const reportShape = globalState.get('reportShape');
      const reportType = globalState.get('reportType');
      let reports = !isUndefined(globalState.getIn(['staffReport', 'reports', reportType]))
        ? globalState.getIn(['staffReport', 'reports', reportType])
        : fromJS([]);

      if (reportShape > 0) {
        if(reportShape === 4) {
          reports = reports.slice(18);
        } else {
          reports = reports.slice(reportShape * 6 - 6, reportShape * 6);
        }
      }

      if (globalState.get('reportOrderByRank')) {
        reports = reports.sortBy(item => -item.get('rank'));
      }

      return {
        createdAt: globalState.getIn(['staffReport', 'createdAt']),
        reports: globalState.getIn(['staffReport', 'reports']).size > 0 ? reports.toJS() : []
      };
    }
  );

const makeSelectStaffReportSize = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffReportSize')
  );

const makeSelectStaffCompare = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCompare')
  );

const makeSelectProfileList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileList')
  );

const makeSelectCompareProfiles = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareProfiles')
  );

const makeSelectCompareId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareId')
  );

const makeSelectCompareRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareRead').delete('sections')
  );

const makeSelectCompareReadSections = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const onlyMismatched = globalState.get('showMismatched');
      const sections = globalState.getIn(['compareRead', 'sections']);

      if (!isUndefined(sections) && onlyMismatched) {
        const result = sections
          .map(section =>
            section.set(
              'subsections',
              section
                .get('subsections')
                .filter(
                  subsection =>
                    subsection.get('value') < subsection.getIn(['range', 'lower']) ||
                    subsection.get('value') >= subsection.getIn(['range', 'upper'])
                )
            )
          )
          .filter(section => section.get('subsections').size > 0);

        return result;
      }

      return sections;
    }
  );

const makeSelectInvite = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invite').toJS()
  );

const makeSelectInviteFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteFlags').toJS()
  );

const makeSelectInviteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteModals').toJS()
  );

const makeSelectLoadingInvite = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingInvite')
  );

const makeSelectUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('user')
  );

const makeSelectManagersList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managersList')
  );

const makeSelectManagerFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managerFilter')
  );

const makeSelectNameFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('nameFilter')
  );

const makeSelectSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('sort')
  );

const makeSelectCompareFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCompareFilter')
  );

const makeSelectCompareCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCompareCount')
  );

const makeSelectComparePage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffComparePage')
  );

const makeSelectCompareSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCompareSort')
  );

const makeSelectLoadingUpdate = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingUpdate')
  );

const makeSelectStaffUpdateFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffUpdateFlags').toJS()
  );

const makeSelectLoadingProfile = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingProfile')
  );

const makeSelectLoadingPassword = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingPassword')
  );

const makeSelectProfileLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareProfileLoading')
  );

const makeSelectProfileInput = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareProfileInput')
  );

const makeSelectCompany = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('company').toJS()
  );

const makeSelectUnreadReportsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('company').toJS().unread_reports_count || 0
  );

const makeSelectWarningFlag = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('warningFlag')
  );

const makeSelectAddsFlag = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsFlag').toJS()
  );

const makeSelectAddsModal = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsModal').toJS()
  );

const makeSelectAddsReportsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsReportsCount')
  );

const makeSelectAddsReportsResult = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsReportsResult')
  );

const makeSelectAddsReturnUrl = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsReturnUrl')
  );

const makeSelectAddsTotal = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('addsTotal')
  );

const makeSelectLoadingAddsReports = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingAddsReports')
  );

const makeSelectOrderId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('orderId')
  );

const makeSelectReportGlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportFlags').toJS()
  );

const makeSelectReportEmail = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportEmail')
  );

const makeSelectReportLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportLoading')
  );

const makeSelectReportModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportModals').toJS()
  );

const makeSelectReportId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportId')
  );

const makeSelectOrderByRank = () =>
  createSelector(
    selectGlobal,
    globalState => (globalState.get('reportOrderByRank') ? 1 : 0)
  );

const makeSelectReportType = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportType')
  );

const makeSelectReportTypeForEmail = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const reportType = globalState.get('reportType');

      if (reportType === 0) {
        return 1;
      } else if (reportType === 1) {
        return 2;
      }

      return 0;
    }
  );

const makeSelectMismatchedSelector = () =>
  createSelector(
    selectGlobal,
    globalState => (globalState.get('showMismatched') ? 1 : 0)
  );

const makeSelectCompareInfo = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareInfo').toJS()
  );

const makeSelectCompareReportsId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('compareReportsId')
  );

const makeSelectCompareIdsString = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const compareReportsIds = globalState.get('compareReportsId');
      const compareIds = [];
      compareReportsIds.valueSeq().forEach(id => {
        compareIds.push(id);
      });

      return encodeURI(JSON.stringify(compareIds));
    }
  );

const makeSelectReportShape = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('reportShape')
  );

const makeSelectReportShapeText = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const reportType = globalState.get('reportType');
      let reports = !isUndefined(globalState.getIn(['staffReport', 'reports', reportType]))
        ? globalState.getIn(['staffReport', 'reports', reportType])
        : fromJS([]);

      const reportShapeText = [
        {
          id: 0,
          label: 'Полный отчет'
        },
        {
          id: 1,
          label: 'Коммуникация'
        },
        {
          id: 2,
          label: 'Мышление'
        },
        {
          id: 3,
          label: 'Мотивация'
        },

      ]

      if(reports.size > 18) {
        reportShapeText.push({
          id: 4,
          label: 'Особенности'
        })
      }

      return reportShapeText
    }
  );

const makeSelectReportIdByType = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const reportType = globalState.get('reportType');
      let index = 0;

      if (reportType === 0) {
        index = 1;
      } else if (reportType === 1) {
        index = 2;
      }

      return globalState.getIn(['reportIds', index]);
    }
  );

const makeSelectInviteWithFileCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteWithFileCount')
  );

const makeSelectInviteWithFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteWithFlags').toJS()
  );

export {
  makeSelectInviteWithFileCount,
  makeSelectInviteWithFlags,
  makeSelectStaffReportSize,
  makeSelectReportType,
  makeSelectCompareInfo,
  makeSelectMismatchedSelector,
  makeSelectStaffList,
  makeSelectStaffCount,
  makeSelectStaffPage,
  makeSelectStaffLoading,
  makeSelectLoadingTable,
  makeSelectErrorCode,
  makeSelectStaffError,
  makeSelectStaffId,
  makeSelectStaffRead,
  makeSelectStaffReport,
  makeSelectStaffCompare,
  makeSelectProfileList,
  makeSelectCompareProfiles,
  makeSelectCompareId,
  makeSelectCompareRead,
  makeSelectInvite,
  makeSelectUser,
  makeSelectUserLoading,
  makeSelectManagersList,
  makeSelectManagerFilter,
  makeSelectNameFilter,
  makeSelectSort,
  makeSelectCompareFilter,
  makeSelectCompareCount,
  makeSelectComparePage,
  makeSelectCompareSort,
  makeSelectLoadingUpdate,
  makeSelectStaffUpdateFlags,
  makeSelectLoadingInvite,
  makeSelectInviteFlags,
  makeSelectInviteModals,
  makeSelectLoadingProfile,
  makeSelectLoadingPassword,
  makeSelectProfileLoading,
  makeSelectProfileInput,
  makeSelectCompany,
  makeSelectWarningFlag,
  makeSelectAddsFlag,
  makeSelectAddsModal,
  makeSelectAddsReportsCount,
  makeSelectAddsReportsResult,
  makeSelectAddsReturnUrl,
  makeSelectAddsTotal,
  makeSelectLoadingAddsReports,
  makeSelectOrderId,
  makeSelectReportGlags,
  makeSelectReportEmail,
  makeSelectReportLoading,
  makeSelectReportModals,
  makeSelectReportId,
  makeSelectOrderByRank,
  makeSelectCompareReadSections,
  makeSelectCompareReportsId,
  makeSelectCompareIdsString,
  makeSelectReportShape,
  makeSelectReportShapeText,
  makeSelectReportIdByType,
  makeSelectReportTypeForEmail,
  makeSelectUnreadReportsCount
};
