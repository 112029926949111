import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerFinances');

const makeSelectFinancesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'count'])
  );

const makeSelectFinancesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'list']).toJS()
  );

const makeSelectFinancesLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'loading'])
  );

const makeSelectFinancesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'page'])
  );

const makeSelectFinancesFromDateFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'fromDateFilter'])
  );

const makeSelectFinancesToDateFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'toDateFilter'])
  );

const makeSelectFinancesTypeSelect = () =>
  createSelector(
    selectGlobal,
    globalState => {
      const typeFilter = globalState.getIn(['finances', 'typeFilter']);
      switch (typeFilter) {
        case 'company':
          return 1;
        case 'partner':
          return 2;
        default:
          return 0;
      }
    }
  );

const makeSelectFinancesTypeFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'typeFilter'])
  );

const makeSelectFinancesRewardAmountSum = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['finances', 'rewardAmountSum'])
  );

export {
  makeSelectFinancesCount,
  makeSelectFinancesList,
  makeSelectFinancesLoading,
  makeSelectFinancesPage,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter,
  makeSelectFinancesTypeFilter,
  makeSelectFinancesTypeSelect,
  makeSelectFinancesRewardAmountSum
};
