import { createSelector } from 'reselect';

const selectGlobal = state => state.get('testStart');

const makeSelectPhone = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('phone')
  );

const makeSelectCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('code')
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectSmsWasSend = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('smsWasSend')
  );

const makeSelectVerified = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('verified')
  );

const makeSelectInvitationCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState && globalState.get('invitationCode')
  );

const makeSelectUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('user')
  );

const makeSelectOrderId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('orderId')
  );

const makeSelectIsPaid = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('isPaid')
  );

const makeSelectTermsAccepted = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('termsAccepted')
  );

export {
  makeSelectPhone,
  makeSelectCode,
  makeSelectLoading,
  makeSelectSmsWasSend,
  makeSelectVerified,
  makeSelectInvitationCode,
  makeSelectUser,
  makeSelectOrderId,
  makeSelectIsPaid,
  makeSelectTermsAccepted
};
