import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Partners/reducer';
import saga from 'pages/PartnerProfile/Partners/saga';
import {
  changeRequisitesPage,
  getPartner,
  getRequisitesList
} from 'pages/PartnerProfile/Partners/actions';
import {
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage,
  makeSelectPartnerRead,
  makeSelectPartnerLoading
} from 'pages/PartnerProfile/Partners/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';

import RequisitesTable from './Table';

class Requisites extends Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { getRequisitesList, getPartner, partner }
    } = this;

    if (partner.id !== id) {
      getPartner({ id });
    }

    getRequisitesList({ id });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { partner }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/partners"
          onClick={event => onBreadcrumbsClick(event, '/partner/partners')}
          text="Партнеры"
          key="partners"
        />
        <BreadcrumbsItem
          href={`/partner/partners/${partner.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/partners/${partner.id}`)}
          text={partner.full_name}
          key="partnerFull_name"
        />
        <BreadcrumbsItem
          href={`/partner/partners/${partner.id}/requisites`}
          onClick={event => onBreadcrumbsClick(event, `/partner/partners/${partner.id}/requisites`)}
          text="Реквизиты"
          key="partnerRequisites"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      props: { changeRequisitesPage, count, list, loading, page, partner, tableLoading },
      renderBreadcrumbs
    } = this;

    let content = (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <Empty header="У партнера еще нет реквизитов" />
        </GridColumn>
      </Grid>
    );

    if (count >= 1) {
      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <RequisitesTable key="partnersRequisitesTable" data={list} isLoading={tableLoading} />
            <Pagination
              key="pagination"
              value={page}
              total={Math.ceil(count / 25)}
              onChange={page => changeRequisitesPage({ page, id: partner.id })}
            />
          </GridColumn>
        </Grid>
      );
    } else if (loading) {
      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <Spinner height="80vh" />
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader breadcrumbs={renderBreadcrumbs()}>Реквизиты</PageHeader>
        </GridColumn>
        {content}
      </Grid>
    );
  };

  render() {
    const {
      props: { partner },
      renderContent
    } = this;

    return (
      <Page>
        <Helmet>
          <title>{partner.full_name}</title>
        </Helmet>
        {renderContent()}
      </Page>
    );
  }
}

Requisites.propTypes = {
  changeRequisitesPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  getRequisitesList: PropTypes.func.isRequired,
  getPartner: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  partner: PropTypes.object.isRequired,
  tableLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeRequisitesPage: value => dispatch(changeRequisitesPage(value)),
    getRequisitesList: value => dispatch(getRequisitesList(value)),
    getPartner: value => dispatch(getPartner(value))
  };
}

const mapStateToProps = createStructuredSelector({
  count: makeSelectRequisitesCount(),
  list: makeSelectRequisitesList(),
  loading: makeSelectPartnerLoading(),
  page: makeSelectRequisitesPage(),
  partner: makeSelectPartnerRead(),
  tableLoading: makeSelectRequisitesLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerPartners', reducer });
const withSaga = injectSaga({ key: 'partnerPartners', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Requisites);
