import {
  GET_COMPARE_LIST_REQUEST,
  GET_COMPARE_LIST_SUCCESS,
  GET_COMPARE_LIST_FAIL,
  GET_STAFF_LIST_REQUEST,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST_FAIL,
  CHANGE_STAFF_INPUT,
  CHANGE_STAFF_SELECTOR,
  DELETE_STAFF_FROM_COMPARE,
  OPEN_ADD_MODAL,
  CLOSE_ADD_MODAL,
  CHANGE_COLUMN_POSITION,
  ADD_STAFF_TO_COMPARE,
  DELETE_ID_FROM_COMPARE
} from './constants';

export function getCompareList(payload) {
  return {
    type: GET_COMPARE_LIST_REQUEST,
    ...payload
  };
}

export function getCompareListSuccess(payload) {
  return {
    type: GET_COMPARE_LIST_SUCCESS,
    ...payload
  };
}

export function getCompareListFail(payload) {
  return {
    type: GET_COMPARE_LIST_FAIL,
    ...payload
  };
}

export function deleteStaffFromCompare(payload) {
  return {
    type: DELETE_STAFF_FROM_COMPARE,
    ...payload
  };
}

export function deleteIdFromCompare(payload) {
  return {
    type: DELETE_ID_FROM_COMPARE,
    ...payload
  };
}

export function openAddModal() {
  return {
    type: OPEN_ADD_MODAL
  };
}

export function closeAddModal() {
  return {
    type: CLOSE_ADD_MODAL
  };
}

export function getStaffList() {
  return {
    type: GET_STAFF_LIST_REQUEST
  };
}

export function getStaffListSuccess(payload) {
  return {
    type: GET_STAFF_LIST_SUCCESS,
    ...payload
  };
}

export function getStaffListFail() {
  return {
    type: GET_STAFF_LIST_FAIL
  };
}

export function changeStaffInput(payload) {
  return {
    type: CHANGE_STAFF_INPUT,
    ...payload
  };
}

export function changeStaffSelector(payload) {
  return {
    type: CHANGE_STAFF_SELECTOR,
    ...payload
  };
}

export function changeColumnPosition(payload) {
  return {
    type: CHANGE_COLUMN_POSITION,
    ...payload
  }
}

export function addStaffToCompare(payload) {
  return {
    type: ADD_STAFF_TO_COMPARE,
    ...payload
  };
}

