import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import 'moment/locale/ru';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Partners/reducer';
import saga from 'pages/PartnerProfile/Partners/saga';
import {
  changeFlags,
  changePartnerComment,
  getPartner,
  updatePartner
} from 'pages/PartnerProfile/Partners/actions';
import {
  makeSelectPartnerComment,
  makeSelectPartnerFlags,
  makeSelectPartnerFormLoading,
  makeSelectPartnerRead,
  makeSelectPartnerLoading
} from 'pages/PartnerProfile/Partners/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Rouble from 'components/Rouble';
import Spinner from 'components/Spinner';

import Block from './styled/Block';
import Container from './styled/Container';
import Label from './styled/Label';
import P from './styled/P';

class Information extends Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { getPartner }
    } = this;

    getPartner({
      id
    });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeComment = event => {
    const {
      props: { changePartnerComment }
    } = this;

    changePartnerComment({
      text: event.target.value
    });
  };

  onSubmitForm = event => {
    const {
      props: { partner, updatePartner }
    } = this;

    event.preventDefault();
    updatePartner({
      id: partner.id
    });
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { partner }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/partners"
          onClick={event => onBreadcrumbsClick(event, '/partner/partners')}
          text="Партнеры"
          key="partners"
        />
        <BreadcrumbsItem
          href={`/partner/partners/${partner.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/partners/${partner.id}`)}
          text={partner.full_name}
          key="partnerFull_name"
        />
        <BreadcrumbsItem
          href={`/partner/partners/${partner.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/partners/${partner.id}`)}
          text="Информация"
          key="partnerInformation"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      onSubmitForm,
      props: { comment, formLoading, loading, partner },
      renderBreadcrumbs
    } = this;

    if (loading) {
      return (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <Spinner height="80vh" />
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader breadcrumbs={renderBreadcrumbs()}>{partner.full_name}</PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Общая информация</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Имя</Label>
                  <P>{partner.full_name}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Дата регистрации</Label>
                  <P>{moment(partner.date_joined).format('DD.MM.YYYY')}</P>
                </GridColumn>
              </Grid>
            </Block>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Email</Label>
                  <P>{partner.email}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Телефон</Label>
                  <P>{partner.phone_number}</P>
                </GridColumn>
              </Grid>
            </Block>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Дата последнего входа</Label>
                  <P>
                    {!isEmpty(partner.last_login)
                      ? moment(partner.last_login).format('DD.MM.YYYY')
                      : ''}
                  </P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Активность</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Зарегистрировано клиентов</Label>
                  <P>{partner.companies_registered_count.toLocaleString()}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Зарегистрировано партнеров</Label>
                  <P>{partner.partners_registered_count.toLocaleString()}</P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Полученное вознаграждение по данному партнеру</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Всего получено</Label>
                  <P>
                    {partner.total_amount.toLocaleString()} <Rouble>₽</Rouble>
                  </P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>За прошлый месяц</Label>
                  <P>
                    {partner.last_month_amount.toLocaleString()} <Rouble>₽</Rouble>
                  </P>
                </GridColumn>
              </Grid>
            </Block>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Максимальное за месяц</Label>
                  <P>
                    {partner.max_month_amount.toLocaleString()} <Rouble>₽</Rouble>
                  </P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <div>
            <TextArea
              label="Комментарий"
              onChange={this.onChangeComment}
              placeholder=""
              shouldFitContainer
              minimumRows={4}
              value={comment}
            />
            <Block>
              <Button appearance="primary" isLoading={formLoading} onClick={onSubmitForm}>
                Сохранить
              </Button>
            </Block>
          </div>
        </GridColumn>
      </Grid>
    );
  };

  renderUpdateFlags = () => {
    const {
      props: { changeFlags, partnerFlags }
    } = this;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'partner',
        flags: partnerFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {partnerFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При редактировании партнера произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Партнер успешно отредактирован' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  render() {
    const {
      props: { partner },
      renderContent,
      renderUpdateFlags
    } = this;

    return (
      <Container>
        <Helmet>
          <title>{partner.full_name}</title>
        </Helmet>
        {renderContent()}
        {renderUpdateFlags()}
      </Container>
    );
  }
}

Information.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changePartnerComment: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  formLoading: PropTypes.bool.isRequired,
  getPartner: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  partner: PropTypes.object.isRequired,
  partnerFlags: PropTypes.array.isRequired,
  updatePartner: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changePartnerComment: value => dispatch(changePartnerComment(value)),
    getPartner: value => dispatch(getPartner(value)),
    updatePartner: value => dispatch(updatePartner(value))
  };
}

const mapStateToProps = createStructuredSelector({
  comment: makeSelectPartnerComment(),
  formLoading: makeSelectPartnerFormLoading(),
  loading: makeSelectPartnerLoading(),
  partner: makeSelectPartnerRead(),
  partnerFlags: makeSelectPartnerFlags()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerPartners', reducer });
const withSaga = injectSaga({ key: 'partnerPartners', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Information);
