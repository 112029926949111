import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Button, { ButtonGroup } from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Empty from 'components/Empty';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';

import reducer from 'pages/PartnerProfile/Requisites/reducer';
import saga from 'pages/PartnerProfile/Requisites/saga';

import {
  changeFlags,
  changeModals,
  changeRequisitesPage,
  deleteOrganization,
  getRequisitesList
} from 'pages/PartnerProfile/Requisites/actions';
import {
  makeSelectOrganizationFlags,
  makeSelectRegistrationFlags,
  makeSelectRequisitesCount,
  makeSelectRequisitesList,
  makeSelectRequisitesLoading,
  makeSelectRequisitesPage,
  makeSelectDelete
} from 'pages/PartnerProfile/Requisites/selectors';

import RequisitesTable from './Table';

class List extends Component {
  componentDidMount() {
    const { getRequisitesList } = this.props;

    getRequisitesList();
  }

  renderButtons = () => (
    <ButtonGroup>
      <Button appearance="primary" href="/partner/requisites/create" component={RouterLink}>
        Добавить организацию
      </Button>
    </ButtonGroup>
  );

  renderContent = () => {
    const {
      changeModals,
      changeRequisitesPage,
      count,
      deleteProps,
      deleteOrganization,
      list,
      loading,
      page
    } = this.props;

    let content = (
      <Empty header="У вас еще нет организаций" text="Начните добавлять организации">
        <div>{this.renderButtons()}</div>
      </Empty>
    );

    if (count >= 1) {
      content = [
        <RequisitesTable
          key="clientsTable"
          changeModals={changeModals}
          deleteItem={deleteProps}
          deleteOrganization={deleteOrganization}
          data={list}
          isLoading={loading}
        />,
        <Pagination
          key="pagination"
          value={page}
          total={Math.ceil(count / 25)}
          onChange={page => changeRequisitesPage({ page })}
        />
      ];
    } else if (loading) {
      content = <Spinner height="80vh" />;
    }

    return content;
  };

  renderRegistrationFlags = () => {
    const { changeFlags, registrationFlags } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'registration',
        flags: registrationFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {registrationFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При добавлении новой организации произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Организация успешно добавлена' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  renderOrganizationFlags = () => {
    const { changeFlags, organizationFlags } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'organization',
        flags: organizationFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {organizationFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При редактировании организации произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Организация успешно отредактирована' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  renderDeleteFlags = () => {
    const { changeFlags, deleteProps } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'delete',
        flags: deleteProps.flags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {deleteProps.flags.map(id => (
          <Flag
            description={
              id < 0 ? 'При удалении организации произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Организация успешно удалена' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  render() {
    const {
      props: { count, loading },
      renderButtons,
      renderContent,
      renderDeleteFlags,
      renderOrganizationFlags,
      renderRegistrationFlags
    } = this;
    const showButtons = count >= 1 && !loading;

    return (
      <Page>
        <Helmet>
          <title>Реквизиты</title>
        </Helmet>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={showButtons ? renderButtons() : null}>Реквизиты</PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{renderContent()}</GridColumn>
        </Grid>
        {renderOrganizationFlags()}
        {renderRegistrationFlags()}
        {renderDeleteFlags()}
      </Page>
    );
  }
}

List.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changeModals: PropTypes.func.isRequired,
  changeRequisitesPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  deleteProps: PropTypes.object.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  getRequisitesList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  organizationFlags: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  registrationFlags: PropTypes.array.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeModals: value => dispatch(changeModals(value)),
    changeRequisitesPage: () => dispatch(changeRequisitesPage()),
    deleteOrganization: value => dispatch(deleteOrganization(value)),
    getRequisitesList: () => dispatch(getRequisitesList())
  };
}

const mapStateToProps = createStructuredSelector({
  count: makeSelectRequisitesCount(),
  deleteProps: makeSelectDelete(),
  list: makeSelectRequisitesList(),
  loading: makeSelectRequisitesLoading(),
  organizationFlags: makeSelectOrganizationFlags(),
  page: makeSelectRequisitesPage(),
  registrationFlags: makeSelectRegistrationFlags()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerRequisites', reducer });
const withSaga = injectSaga({ key: 'partnerRequisites', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(List);
