import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import Page from '@atlaskit/page';
import Form from '@atlaskit/form';
import Button from '@atlaskit/button';
import { AutoDismissFlag as Flag, FlagGroup } from '@atlaskit/flag';
import { colors } from '@atlaskit/theme';
import Error from '@atlaskit/icon/glyph/error';
import Tick from '@atlaskit/icon/glyph/check-circle';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isRequired, isEmail, isPhone } from 'utils/validators';
import { legalName } from "utils/checkService";

import TextField from 'components/TextField';

import {
  changeFlags,
  changeName,
  changeSurname,
  changeEmail,
  changePhone,
  changePasswordOne,
  changeSmsCode,
  changeSmsFlags,
  changeCompany,
  changeInn,
  sendSmsCode,
  registerCompany
} from './actions';

import {
  makeSelectLoading,
  makeSelectName,
  makeSelectSurname,
  makeSelectEmail,
  makeSelectPhone,
  makeSelectPasswordOne,
  makeSelectSmsCode,
  makeSelectCompany,
  makeSelectInn,
  makeSelectFlags,
  makeSelectSmsFlags,
  makeSelectErrorMessages
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import P from './styled/P';
import FieldGroup from './styled/FieldGroup';
import Field from './styled/Field';
import Flex from './styled/Flex';
import ButtonField from './styled/ButtonField';
import FlexItem from './styled/FlexItem';
import StyledA from './styled/Link';
import Title from './styled/Title';

import Image from './img/pic.png';
import Image2x from './img/pic-2x.png';

class RegistrationCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: this.props.company,
      companyChanged: false,
      companyValid: true,
      email: this.props.email,
      emailChanged: false,
      emailValid: true,
      inn: this.props.inn,
      innChanged: false,
      name: this.props.name,
      nameChanged: false,
      nameValid: true,
      passwordOne: this.props.passwordOne,
      passwordOneChanged: false,
      passwordOneValid: true,
      smsCode: this.props.smsCode,
      smsCodeChanged: false,
      smsCodeValid: true,
      phone: this.props.phone,
      phoneChanged: false,
      phoneValid: true,
      surname: this.props.surname,
      surnameChanged: false,
      isDisabled: true
    };

    const token = localStorage.getItem('key');
    if (!isNull(token)) {
      this.props.history.push('/invites');
    }
  }

  checkFormValid = () => {
    const {
      companyChanged,
      companyValid,
      emailChanged,
      emailValid,
      nameChanged,
      nameValid,
      passwordOneChanged,
      passwordOneValid,
      smsCodeChanged,
      smsCodeValid,
      phoneChanged,
      phoneValid
    } = this.state;

    const isDisabled = !(
      companyValid &&
      emailValid &&
      nameValid &&
      passwordOneValid &&
      smsCodeValid &&
      phoneValid &&
      companyChanged &&
      emailChanged &&
      nameChanged &&
      passwordOneChanged &&
      smsCodeChanged &&
      phoneChanged
    );

    this.setState({
      isDisabled
    });
  };

  onChangeCompany = event => {
    this.setState(
      {
        company: event.target.value,
        companyChanged: true,
        companyValid: isRequired(event.target.value)
      },
      this.checkFormValid
    );
    this.props.changeCompany({
      company: event.target.value
    });
  };

  onChangeEmail = event => {
    this.setState(
      {
        email: event.target.value,
        emailChanged: true,
        emailValid: isEmail(event.target.value)
      },
      this.checkFormValid
    );
    this.props.changeEmail({
      email: event.target.value
    });
  };

  onChangeInn = event => {
    this.setState(
      {
        inn: event.target.value,
        innChanged: true
      },
      this.checkFormValid
    );
    this.props.changeInn({
      inn: event.target.value
    });
  };

  onChangeName = event => {
    this.setState(
      {
        name: event.target.value,
        nameChanged: true,
        nameValid: isRequired(event.target.value)
      },
      this.checkFormValid
    );
    this.props.changeName({
      name: event.target.value
    });
  };

  onChangePasswordOne = event => {
    this.setState(
      {
        passwordOne: event.target.value,
        passwordOneChanged: true,
        passwordOneValid: isRequired(event.target.value)
      },
      this.checkFormValid
    );

    this.props.changePasswordOne({
      password: event.target.value
    });
  };

  onChangeSmsCode = event => {
    this.setState(
      {
        smsCode: event.target.value,
        smsCodeChanged: true,
        smsCodeValid: isRequired(event.target.value)
      },
      this.checkFormValid
    );
    this.props.changeSmsCode({
      smsCode: event.target.value
    });
  };

  onChangePhone = event => {
    this.setState(
      {
        phone: event.target.value,
        phoneChanged: true,
        phoneValid: isPhone(event.target.value)
      },
      this.checkFormValid
    );
    this.props.changePhone({
      phone: event.target.value
    });
  };

  onChangeSurname = event => {
    this.setState(
      {
        surname: event.target.value,
        surnameChanged: true
      },
      this.checkFormValid
    );
    this.props.changeSurname({
      surname: event.target.value
    });
  };

  onSubmitForm = () => {
    const referral = this.props.match.params.referral;

    this.props.registerCompany({
      referral
    });
  };

  removeFlag = id => {
    const { changeFlags, flags } = this.props;

    changeFlags({
      flags: flags.filter(v => v !== id)
    });
  };

  removeSmsFlag = id => {
    const { changeSmsFlags, smsFlags } = this.props;

    changeSmsFlags({
      smsFlags: smsFlags.filter(v => v !== id)
    });
  };

  onSendSmsCode = event => {
    event.preventDefault();

    const { phone } = this.state;
    const phoneValid = isPhone(phone);

    if (phoneValid) {
      this.props.sendSmsCode();
    } else {
      this.setState({
        phoneValid
      });
    }
  };

  render() {
    const {
      company,
      companyValid,
      email,
      emailValid,
      inn,
      name,
      nameValid,
      passwordOne,
      passwordOneValid,
      smsCode,
      smsCodeValid,
      phone,
      phoneValid,
      surname
    } = this.state;

    const { flags, errorMessages, smsFlags } = this.props;

    const content = (
      <FlexItem>
        <Title>Регистрация</Title>
        <P marginTop="3px" color="#6B778C">
          Уже зарегистрированы? <Link to="/signin">Войти</Link>
        </P>
        <Form>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!companyValid}
                required
                label="Название компании"
                name="company"
                onBlur={this.onChangeCompany}
                onChange={this.onChangeCompany}
                placeholder=""
                shouldFitContainer
                value={company}
              />
            </Field>
            <Field>
              <TextField
                label="ИНН компании"
                mask="999999999999"
                maskChar=""
                name="inn"
                onBlur={this.onChangeInn}
                onChange={this.onChangeInn}
                placeholder=""
                shouldFitContainer
                value={inn}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!nameValid}
                required
                label="Имя"
                name="name"
                onBlur={this.onChangeName}
                onChange={this.onChangeName}
                placeholder=""
                shouldFitContainer
                value={name}
              />
            </Field>
            <Field>
              <TextField
                label="Фамилия"
                name="surname"
                onBlur={this.onChangeSurname}
                onChange={this.onChangeSurname}
                placeholder=""
                shouldFitContainer
                value={surname}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!emailValid || !isEmpty(errorMessages.email)}
                required
                label="Email"
                name="email"
                onBlur={this.onChangeEmail}
                onChange={this.onChangeEmail}
                placeholder=""
                shouldFitContainer
                value={email}
              />
            </Field>
            <Field>
              <TextField
                isInvalid={!passwordOneValid || !isEmpty(errorMessages.password1)}
                required
                label="Пароль"
                name="password"
                onBlur={this.onChangePasswordOne}
                onChange={this.onChangePasswordOne}
                placeholder=""
                shouldFitContainer
                type="password"
                value={passwordOne}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!phoneValid || !isEmpty(errorMessages.phone_number)}
                required
                label="Телефон"
                mask="+7(999) 999-99-99"
                name="phone"
                onBlur={this.onChangePhone}
                onChange={this.onChangePhone}
                placeholder=""
                shouldFitContainer
                value={phone}
              />
            </Field>
            <Field>
              <TextField
                isInvalid={!smsCodeValid}
                required
                label="Код подтверждения"
                name="sms_code"
                onBlur={this.onChangeSmsCode}
                onChange={this.onChangeSmsCode}
                placeholder=""
                shouldFitContainer
                value={smsCode}
              />
              <StyledA href="#" onClick={this.onSendSmsCode}>
                Получить код подтверждения
              </StyledA>
            </Field>
          </FieldGroup>

          <FieldGroup>
            <ButtonField>
              <Button
                appearance="primary"
                isDisabled={this.state.isDisabled}
                isLoading={this.props.loading}
                onClick={this.onSubmitForm}
              >
                Завершить регистрацию
              </Button>
            </ButtonField>
          </FieldGroup>

          <P marginTop="21px" color="#6B778C">
            Нажимая «Завершить регистрацию» я принимаю условия{' '}
            <a href="/documents/4" target="_blank" rel="noopener noreferrer">
              Пользовательского соглашения
            </a>{' '}
            и даю своё согласие {legalName} на обработку моих персональных данных, в соответствии с
            Федеральным законом от 27.07.2006 года #152-ФЗ "О персональных данных", на условиях и
            для целей, определенных{' '}
            <a href="/documents/3" target="_blank" rel="noopener noreferrer">
              Политикой конфиденциальности
            </a>
            .
          </P>
        </Form>
      </FlexItem>
    );

    return (
      <Page>
        <Helmet>
          <title>Регистрация компании</title>
        </Helmet>

        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
          </FlexItem>
          {content}
        </Flex>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {flags.map(id => (
            <Flag
              description={`${errorMessages.company_inn} ${errorMessages.email} ${
                errorMessages.phone_number
              } ${errorMessages.password1}`}
              icon={<Error label="Error" primaryColor={colors.R300} />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Ошибка"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSmsFlag(name)}>
          {smsFlags.map(id => (
            <Flag
              description=""
              icon={<Tick label="Success" />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Код - последние 4 цифры входящего звонка"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

RegistrationCompany.propTypes = {
  changeCompany: PropTypes.func,
  changeEmail: PropTypes.func,
  changeFlags: PropTypes.func,
  changeInn: PropTypes.func,
  changeName: PropTypes.func,
  changePasswordOne: PropTypes.func,
  changeSmsCode: PropTypes.func,
  changeSmsFlags: PropTypes.func,
  changePhone: PropTypes.func,
  changeSurname: PropTypes.func,
  company: PropTypes.string,
  email: PropTypes.string,
  errorMessages: PropTypes.object,
  flags: PropTypes.array,
  smsFlags: PropTypes.array,
  inn: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  passwordOne: PropTypes.string,
  smsCode: PropTypes.string,
  phone: PropTypes.string,
  registerCompany: PropTypes.func,
  sendSmsCode: PropTypes.func,
  surname: PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeName: value => dispatch(changeName(value)),
    changeSurname: value => dispatch(changeSurname(value)),
    changeEmail: value => dispatch(changeEmail(value)),
    changePhone: value => dispatch(changePhone(value)),
    changePasswordOne: value => dispatch(changePasswordOne(value)),
    changeSmsCode: value => dispatch(changeSmsCode(value)),
    changeSmsFlags: value => dispatch(changeSmsFlags(value)),
    changeCompany: value => dispatch(changeCompany(value)),
    changeInn: value => dispatch(changeInn(value)),
    registerCompany: value => dispatch(registerCompany(value)),
    sendSmsCode: () => dispatch(sendSmsCode())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  name: makeSelectName(),
  surname: makeSelectSurname(),
  email: makeSelectEmail(),
  phone: makeSelectPhone(),
  passwordOne: makeSelectPasswordOne(),
  smsCode: makeSelectSmsCode(),
  company: makeSelectCompany(),
  inn: makeSelectInn(),
  flags: makeSelectFlags(),
  smsFlags: makeSelectSmsFlags(),
  errorMessages: makeSelectErrorMessages()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'registrationCompany', reducer });
const withSaga = injectSaga({ key: 'registrationCompany', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(RegistrationCompany);
