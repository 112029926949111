import React from 'react';
import PropTypes from 'prop-types';
import Desc from './styled/Desc';
import Wrapper from './styled/Wrapper';

const IndividualName = props => (
  <Wrapper>
    {props.text}
    <Desc>{props.desc}</Desc>
  </Wrapper>
);

IndividualName.propTypes = {
  desc: PropTypes.string,
  text: PropTypes.string
};

export default IndividualName;
