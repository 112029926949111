import styled from 'styled-components';

const Conclusion = styled.div`
  background-color: ${props => props.backgroundColor};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 40px;
  margin: 50px 0;
  width: 100%;
  -webkit-print-color-adjust: exact;
`;

export default Conclusion;
