import { fromJS } from 'immutable';

import {
  CHANGE_LOGIN_FIELD,
  CHANGE_FLAGS,
  SEND_LOGIN,
  SEND_LOGIN_SUCCESS,
  SEND_LOGIN_FAIL,
  CHANGE_REGISTRATION_FIELD,
  SEND_REGISTRATION,
  SEND_REGISTRATION_SUCCESS,
  SEND_REGISTRATION_FAIL,
  SEND_PASSCODE,
  SEND_PASSCODE_SUCCESS,
  SEND_PASSCODE_FAIL,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  CHANGE_RESET_FIELD,
  CREATE_PASSWORD_RESET,
  CREATE_PASSWORD_RESET_SUCCESS,
  CREATE_PASSWORD_RESET_FAIL,
  CONFIRM_PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET_SUCCESS,
  CONFIRM_PASSWORD_RESET_FAIL
} from './constants';

const initialState = fromJS({
  login: {
    error: '',
    flags: [],
    form: {
      email: '',
      password: ''
    },
    loading: false
  },
  otp: {
    loading: false
  },
  registration: {
    error: '',
    flags: [],
    form: {
      email: '',
      password: '',
      phone_number: '',
      first_name: '',
      last_name: '',
      passcode: '',
      referral_code: ''
    },
    loading: false,
    passcodeSent: false
  },
  reset: {
    error: '',
    flags: [],
    form: {
      email: '',
      new_password: ''
    },
    loading: false
  }
});

function partnerAuthReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOGIN_FIELD:
      return state.setIn(['login', 'form', action.field], action.text);
    case CHANGE_FLAGS:
      return state.setIn([action.field, 'flags'], fromJS(action.flags));
    case SEND_LOGIN:
      return state
        .setIn(['login', 'flags'], fromJS([]))
        .setIn(['login', 'error'], '')
        .setIn(['login', 'loading'], true);
    case SEND_LOGIN_SUCCESS:
      return state.setIn(['login', 'loading'], false);
    case SEND_LOGIN_FAIL:
      return state
        .setIn(['login', 'flags'], fromJS([1]))
        .setIn(['login', 'error'], action.error)
        .setIn(['login', 'loading'], false);
    case CHANGE_REGISTRATION_FIELD:
      return state.setIn(['registration', 'form', action.field], action.text);
    case SEND_REGISTRATION:
      return state
        .setIn(['registration', 'flags'], fromJS([]))
        .setIn(['registration', 'error'], '')
        .setIn(['registration', 'loading'], true);
    case SEND_REGISTRATION_SUCCESS:
      return state.setIn(['registration', 'loading'], false);
    case SEND_REGISTRATION_FAIL:
      return state
        .setIn(['registration', 'flags'], fromJS([1]))
        .setIn(['registration', 'error'], action.error)
        .setIn(['registration', 'loading'], false);
    case SEND_PASSCODE:
      return state.setIn(['registration', 'passcodeSent'], false);
    case SEND_PASSCODE_SUCCESS:
      return state.setIn(['registration', 'passcodeSent'], true);
    case SEND_PASSCODE_FAIL:
      return state.setIn(['registration', 'passcodeSent'], false);
    case SEND_OTP:
      return state.setIn(['otp', 'loading'], true);
    case SEND_OTP_SUCCESS:
      return state.setIn(['otp', 'loading'], false);
    case SEND_OTP_FAIL:
      return state.setIn(['otp', 'loading'], false);
    case CHANGE_RESET_FIELD:
      return state.setIn(['reset', 'form', action.field], action.text);
    case CREATE_PASSWORD_RESET:
      return state
        .setIn(['reset', 'error'], '')
        .setIn(['reset', 'flags'], fromJS([]))
        .setIn(['reset', 'loading'], true);
    case CREATE_PASSWORD_RESET_SUCCESS:
      return state.setIn(['reset', 'flags'], fromJS([1])).setIn(['reset', 'loading'], false);
    case CREATE_PASSWORD_RESET_FAIL:
      return state.setIn(['reset', 'flags'], fromJS([-1])).setIn(['reset', 'loading'], false);
    case CONFIRM_PASSWORD_RESET:
      return state
        .setIn(['reset', 'error'], '')
        .setIn(['reset', 'flags'], fromJS([]))
        .setIn(['reset', 'loading'], true);
    case CONFIRM_PASSWORD_RESET_SUCCESS:
      return state.setIn(['reset', 'flags'], fromJS([2])).setIn(['reset', 'loading'], false);
    case CONFIRM_PASSWORD_RESET_FAIL:
      return state
        .setIn(['reset', 'error'], action.error)
        .setIn(['reset', 'flags'], fromJS([-1]))
        .setIn(['reset', 'loading'], false);
    default:
      return state;
  }
}

export default partnerAuthReducer;
