import { createSelector } from 'reselect';

const selectGlobal = state => state.get('profile');

const makeSelectProfileList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileList').toJS()
  );

const makeSelectSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('sort')
  );

const makeSelectProfilePage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profilePage')
  );

const makeSelectProfileCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileCount')
  );

const makeSelectProfileLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectTableLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingTable')
  );

const makeSelectProfileRead = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileRead')
  );

const makeSelectProfileid = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileId')
  );

const makeSelectNameFromSample = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileSample', 'name'])
  );

const makeSelectDescFromSample = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileSample', 'description'])
  );

const makeSelectNegativeFromSample = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileSample', 'negativeSamples']).toJS()
  );

const makeSelectPositiveFromSample = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileSample', 'positiveSamples']).toJS()
  );

const makeSelectStaffSample = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffList').toJS()
  );

const makeSelectProfileCreated = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileCreated')
  );

const makeSelectProfileQuestions = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileQuestions')
  );

const makeSelectProfileQuestionsName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileQuestionsName')
  );

const makeSelectProfileQuestionsDesc = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileQuestionsDesc')
  );

const makeSelectProfileQuestionsAnswers = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileQuestions', 'answers'])
  );

const makeSelectProfileAnswers = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileQuestions', 'answers'])
  );

const makeSelectProfileForUpdate = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileForUpdate')
  );

const makeSelectMetaProgramsList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('metaProgramsList')
  );

const makeSelectProfileCustom = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileCustom')
  );

const makeSelectManagersFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managerFilter')
  );

const makeSelectNameFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('nameFilter')
  );

const makeSelectProfileReports = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileReports').toJS()
  );

const makeSelectLoadingReportsTable = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingReportsTable')
  );

const makeSelectProfileReportsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileReportsCount')
  );

const makeSelectProfileReportsPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileReportsPage')
  );

const makeSelectProfileReportsSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileReportsSort')
  );

const makeSelectProfileReportsFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileReportsFilter')
  );

const makeSelectProfileId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileId')
  );

const makeSelectStaffCompare = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffCompare').toJS()
  );

const makeSelectLoadingStaff = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingStaff')
  );

const makeSelectStaffInput = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('staffInput')
  );

const makeSelectLoadingNegative = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingNegative')
  );

const makeSelectLoadingPositive = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingPositive')
  );

const makeSelectNegativeInput = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('negativeInput')
  );

const makeSelectNegativeList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('negativeList').toJS()
  );

const makeSelectPositiveInput = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('positiveInput')
  );

const makeSelectPositiveList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('positiveList').toJS()
  );

const makeSelectProfileChief = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileChief').toJS()
  );

const makeSelectProfileEmail = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profileEmail').toJS()
  );

export {
  makeSelectLoadingNegative,
  makeSelectLoadingPositive,
  makeSelectNegativeInput,
  makeSelectNegativeList,
  makeSelectPositiveInput,
  makeSelectPositiveList,
  makeSelectProfileCount,
  makeSelectProfilePage,
  makeSelectProfileList,
  makeSelectProfileLoading,
  makeSelectTableLoading,
  makeSelectProfileRead,
  makeSelectProfileid,
  makeSelectNameFromSample,
  makeSelectDescFromSample,
  makeSelectNegativeFromSample,
  makeSelectPositiveFromSample,
  makeSelectStaffSample,
  makeSelectProfileCreated,
  makeSelectProfileQuestions,
  makeSelectProfileQuestionsAnswers,
  makeSelectProfileQuestionsName,
  makeSelectProfileQuestionsDesc,
  makeSelectProfileAnswers,
  makeSelectProfileForUpdate,
  makeSelectMetaProgramsList,
  makeSelectProfileCustom,
  makeSelectManagersFilter,
  makeSelectNameFilter,
  makeSelectSort,
  makeSelectProfileReports,
  makeSelectLoadingReportsTable,
  makeSelectProfileReportsCount,
  makeSelectProfileReportsFilter,
  makeSelectProfileReportsPage,
  makeSelectProfileReportsSort,
  makeSelectProfileId,
  makeSelectStaffCompare,
  makeSelectLoadingStaff,
  makeSelectStaffInput,
  makeSelectProfileChief,
  makeSelectProfileEmail
};
