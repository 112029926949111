import styled from 'styled-components';

const Result = styled.div`
  background-color: ${props => props.backgroundColor};
  border-radius: 3px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  height: 70px;
  width: calc(100% - 40px);
  letter-spacing: 0.33px;
  line-height: 70px;
  padding: 0 20px;
  position: relative;
  margin: 59px 0;

  @media print {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

export default Result;
