import styled from 'styled-components';

const NavigationWrapper = styled.div`
  @media print {
    display: none;
  }

  height: 100vh;
  height: var(--app-height);
`;

export default NavigationWrapper;
