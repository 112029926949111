import {
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_FLAGS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAIL,
  LOGOUT
} from './constants';

export function changeLogin(payload) {
  return {
    type: CHANGE_LOGIN,
    ...payload
  };
}

export function changePassword(payload) {
  return {
    type: CHANGE_PASSWORD,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function loginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

export function loginSuccess() {
  return {
    type: LOGIN_REQUEST_SUCCESS
  };
}

export function loginFail(payload) {
  return {
    type: LOGIN_REQUEST_FAIL,
    ...payload
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}
