import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  margin-right: 10px;
  position: relative;
  width: 20px;
  -webkit-print-color-adjust: exact;
`;

export default Wrapper;
