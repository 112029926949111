import { call, select, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import apiUrl from 'utils/serverConfig';
import { formatPhone } from 'utils/formatPhone';

import {
  CHECK_INVITE_CODE_REQUEST,
  REGISTER_USER_REQUEST,
  SEND_SMS_CODE_REQUEST
} from './constants';

import {
  checkInviteCodeSuccess,
  checkInviteCodeFail,
  registerUserSuccess,
  registerUserFail,
  sendSmsCodeSuccess,
  sendSmsCodeFail
} from './actions';

import {
  makeSelectName,
  makeSelectSurname,
  makeSelectEmail,
  makeSelectPhone,
  makeSelectPasswordOne,
  makeSelectSmsCode,
  makeSelectInvitationCode
} from './selectors';
import { push } from 'react-router-redux';
import isUndefined from 'lodash/isUndefined';

function* registerUserSaga() {
  const url = `${apiUrl}/api/managers/auth/registration/`;
  const first_name = yield select(makeSelectName());
  const last_name = yield select(makeSelectSurname());
  const email = yield select(makeSelectEmail());
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const password1 = yield select(makeSelectPasswordOne());
  const password2 = password1;
  const passcode = yield select(makeSelectSmsCode());
  const invitation_code = yield select(makeSelectInvitationCode());

  const options = {
    method: 'post',
    data: {
      first_name,
      last_name,
      email,
      phone_number,
      password1,
      password2,
      passcode,
      invitation_code
    }
  };

  try {
    const request = yield call(axios, url, options);

    const key = request.data.key;
    localStorage.setItem('key', key);

    yield put(registerUserSuccess());
    yield put(push('/invites'));
  } catch (e) {
    const errorMessages = e.response.data;
    yield put(
      registerUserFail({
        email: isUndefined(errorMessages.email) ? '' : errorMessages.email.join(' '),
        phone_number: isUndefined(errorMessages.phone_number)
          ? ''
          : errorMessages.phone_number.join(' ')
      })
    );
  }
}

function* checkInviteCodeSaga() {
  const url = `${apiUrl}/api/managers/invitations/validate`;
  const invite = yield select(makeSelectInvitationCode());
  const options = {
    method: 'post',
    data: {
      invitation_code: invite
    }
  };

  try {
    yield call(axios, url, options);

    yield put(checkInviteCodeSuccess());
  } catch (e) {
    yield put(checkInviteCodeFail());
    yield put(push('/error'));
  }
}

function* sendSmsCodeSaga() {
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const url = `${apiUrl}/api/managers/auth/login/phone_number`;
  const options = {
    method: 'post',
    data: {
      phone_number
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendSmsCodeSuccess());
  } catch (e) {
    yield put(sendSmsCodeFail());
  }
}

export default function* registrationPageSaga() {
  yield takeLeading(CHECK_INVITE_CODE_REQUEST, checkInviteCodeSaga);
  yield takeLeading(REGISTER_USER_REQUEST, registerUserSaga);
  yield takeLeading(SEND_SMS_CODE_REQUEST, sendSmsCodeSaga);
}
