import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import EditOrganizationPage from 'pages/Settings/subpages/EditOrganization';

const SettingsEditOrganizationView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/settings" />
    <EditOrganizationPage />
  </Fragment>
);

export default SettingsEditOrganizationView;
