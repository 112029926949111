import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { QuickSearch, ResultItemGroup, ResultBase } from '@atlaskit/quick-search';

import { changeSearchInput } from './actions';
import {
  makeSelectLoading,
  makeSelectProfilesList,
  makeSelectSearch,
  makeSelectStaffList
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import Nothing from './styled/Nothing';

const availableResultTypes = {
  base: ResultBase
};

class BasicQuickSearch extends React.Component {
  state = {
    query: this.props.search || ''
  };

  componentDidMount() {
    this.sendChangedSearchInput = debounce(this.props.changeSearchInput, 300);
  }

  renderSearchResults = () => {
    const { loading, profilesList, search, staffList } = this.props;

    if (staffList.size === 0 && profilesList.size === 0 && search !== '' && !loading) {
      return <Nothing />;
    }

    const searchResult = [
      {
        title: 'ПОтчеты',
        items: staffList.toJS()
      },
      {
        title: 'ППрофили',
        items: profilesList.toJS()
      }
    ];

    return searchResult.map(group => {
      if (group.items.length > 0) {
        return (
          <ResultItemGroup title={group.title} key={group.title}>
            {group.items.map(props => {
              const Result = availableResultTypes[props.type];
              return Result ? (
                <Result
                  key={props.resultId}
                  {...props}
                  isSelected={false}
                  onClick={item => this.props.history.push(item.href)}
                />
              ) : null;
            })}
          </ResultItemGroup>
        );
      }

      return <div key={group.title} />;
    });
  };

  search = query => {
    this.setState({
      query
    });
    this.sendChangedSearchInput({
      search: query
    });
  };

  render() {
    const { loading, profilesList, staffList } = this.props;

    const searchResult = [
      {
        title: 'ППерсонал',
        items: staffList.toJS()
      },
      {
        title: 'ППользователи',
        items: profilesList.toJS()
      }
    ];

    return (
      <QuickSearch
        isLoading={loading}
        onSearchInput={({ target }) => {
          this.search(target.value);
        }}
        defaultValue={this.state.query}
        placeholder="Поиск"
      >
        {this.renderSearchResults(searchResult, this.state.query)}
      </QuickSearch>
    );
  }
}

BasicQuickSearch.propTypes = {
  changeSearchInput: PropTypes.func,
  loading: PropTypes.bool,
  profilesList: PropTypes.object,
  search: PropTypes.string,
  staffList: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    changeSearchInput: value => dispatch(changeSearchInput(value))
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  profilesList: makeSelectProfilesList(),
  search: makeSelectSearch(),
  staffList: makeSelectStaffList()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'search', reducer });
const withSaga = injectSaga({ key: 'search', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(BasicQuickSearch);
