import styled from 'styled-components';

const Subtext = styled.div`
  font-size: 16px;
  color: #172b4d;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 27px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  @media (max-width: 1021px) {
    width: 100%;
  }
`;

export default Subtext;
