import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import apiUrl from 'utils/serverConfig';

export function isGuest() {
  return isNull(localStorage.getItem('key')) || localStorage.getItem('key') === 'null';
}

export function auth() {
  return new Promise((resolve, reject) => {
    if (isGuest()) {
      if (window.location.pathname !== '/signin') {
        window.location = '/signin';
      }

      reject();
    } else {
      resolve();
    }
  });
}

export function apiRequest(url, options = {}) {
  return auth().then(() => {
    const editedOptions = options;
    const token = localStorage.getItem('key');

    if (isUndefined(editedOptions.headers)) {
      editedOptions.headers = {
        Authorization: `Token ${token}`
      };
    } else {
      editedOptions.headers.Authorization = `Token ${token}`;
    }

    return axios(`${apiUrl}/${url}`, editedOptions);
  });
}
