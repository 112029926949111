import {
  VIDEOS_LIST_REQUEST,
  VIDEOS_LIST_REQUEST_SUCCESS,
  VIDEOS_LIST_REQUEST_FAIL
} from './constants';

export function videosList() {
  return {
    type: VIDEOS_LIST_REQUEST
  };
}

export function videosListSuccess(payload) {
  return {
    type: VIDEOS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function videosListFail() {
  return {
    type: VIDEOS_LIST_REQUEST_FAIL
  };
}
