import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Tabs from '@atlaskit/tabs';
import Spinner from 'components/Spinner';

import reducer from 'pages/PartnerProfile/User/reducer';
import saga from 'pages/PartnerProfile/User/saga';

import { changeFlags } from 'pages/PartnerProfile/User/actions';
import {
  makeSelectUserLoading,
  makeSelectUserRead,
  makeSelectUserFlags
} from 'pages/PartnerProfile/User/selectors';

import Container from './styled/Container';

import CommonUser from './Common';
import EmailUser from './Email';
import PasswordUser from './Password';

class User extends Component {
  state = {
    selected: 0
  };

  renderTabs = () => {
    const {
      state: { selected }
    } = this;

    const tabs = [
      { label: 'Общая информация', content: <CommonUser /> },
      { label: 'Email', content: <EmailUser /> },
      { label: 'Пароль', content: <PasswordUser /> }
    ];

    const changeSelected = (selected, index) => {
      this.setState({ selected: index });
    };

    return (
      <GridColumn medium={12}>
        <Tabs onSelect={changeSelected} selected={selected} tabs={tabs} />
      </GridColumn>
    );
  };

  renderContent = () => {
    const {
      props: { loading, user },
      renderTabs
    } = this;

    let content = (
      <GridColumn medium={12}>
        <Spinner />
      </GridColumn>
    );

    if (!loading && user.pk !== 0) {
      content = (
        <Fragment>
          <GridColumn medium={12}>
            <PageHeader>{`${user.first_name} ${user.last_name}`}</PageHeader>
          </GridColumn>
          {renderTabs()}
        </Fragment>
      );
    }

    return content;
  };

  renderFlags = () => {
    const { changeFlags, flags } = this.props;

    const onRemoveFlag = name => {
      changeFlags({
        flags: flags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {flags.map(id => (
          <Flag
            description={id < 0 ? 'При редактировании произошла ошибка. Попробуйте еще раз.' : ''}
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Информация успешно обновлена' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  render() {
    const {
      props: { user },
      renderContent,
      renderFlags
    } = this;

    return (
      <Fragment>
        <Helmet>
          <title>{`${user.first_name} ${user.last_name}`}</title>
        </Helmet>

        <Container>
          <Grid layout="fluid">{renderContent()}</Grid>
        </Container>
        {renderFlags()}
      </Fragment>
    );
  }
}

User.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  flags: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value))
  };
}

const mapStateToProps = createStructuredSelector({
  flags: makeSelectUserFlags(),
  loading: makeSelectUserLoading(),
  user: makeSelectUserRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerUser', reducer });
const withSaga = injectSaga({ key: 'partnerUser', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(User);
