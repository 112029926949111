import { createSelector } from 'reselect';

const selectGlobal = state => state.get('chiefQuiz');

const makeSelectAnswers = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileQuestions', 'answers'])
  );

const makeSelectQuestionsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['profileQuestions', 'loading'])
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectInviteCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteCode')
  );

const makeSelectQuizResultLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('quizResultLoading')
  );

export {
  makeSelectAnswers,
  makeSelectQuestionsLoading,
  makeSelectLoading,
  makeSelectInviteCode,
  makeSelectQuizResultLoading
};
