import styled from 'styled-components';

import LogoImage from './logo.png';
import MYLogoImage from './my_logo.png';
import service from "../../../../utils/checkService";

const Logo = styled.div`
  background-image: url(${service === "hrh24" ? LogoImage : MYLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 26px;
  transform: translateX(-50%);
  width: 141px;

  @media (max-width: 1020px) {
    background-color: #ffffff;
    background-size: 141px;
    background-position: center;
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
`;

export default Logo;
