import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import CsvDownloader from 'react-csv-downloader';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import Select from '@atlaskit/select';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Empty from 'components/Empty';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import {
  changeColumnPosition,
  changeStaffInput,
  changeStaffSelector,
  closeAddModal,
  deleteStaffFromCompare,
  getCompareList,
  getStaffList,
  openAddModal
} from './actions';

import {
  makeSelectAddModals,
  makeSelectCompareIds,
  makeSelectCompareLength,
  makeSelectCompareResult,
  makeSelectLoading,
  makeSelectResultTable,
  makeSelectResultStaff,
  makeSelectStaffList,
  makeSelectStaffListLoading,
  makeSelectStaffSelector
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Line from './styled/Line';
import LineBody from './styled/LineBody';
import LineItem from './styled/LineItem';
import StaffName from './components/StaffName';
import Table from './styled/Table';
import TableBody from './styled/TableBody';
import TableHeader from './styled/TableHeader';
import TableLine from './styled/TableLine';

class CompareList extends Component {
  constructor(props) {
    super(props);

    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
    document.addEventListener('mousedown', this.onMousedown);
    document.addEventListener('scroll', this.onScroll);

    this.clicked = false;
    this.clickX = 0;
    this.clickY = 0;
    this.tableRef = React.createRef();
    this.tableHeaderRef = React.createRef();
    this.sendChangedStaffSelector = debounce(this.sendChangedStaffSelector, 300);

    const compareIds = JSON.parse(localStorage.getItem('compareIds'));

    if (compareIds && compareIds.length) {
      this.props.getCompareList({
        compareIds
      });

      if (this.props.staffList.length === 0) {
        this.props.getStaffList();
      }
    } else {
      this.props.history.push('/person/list');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMousemove);
    document.removeEventListener('mouseup', this.onMouseup);
    document.removeEventListener('mousedown', this.onMousedown);
  }

  onMouseup = () => {
    this.clicked = false;
  };

  onMousedown = event => {
    this.clicked = true;
    this.clickX = event.pageX;
    this.clickY = event.pageY;
  };

  onMousemove = event => {
    if (this.clicked) {
      const table = this.tableRef;
      const top = window.scrollY + (this.clickY - event.pageY);
      const newScrollX = table.scrollLeft - (-this.clickX + (this.clickX = event.clientX));

      table.scrollLeft = newScrollX;
      window.scrollTo(0, top);
    }
  };

  onScroll = () => {
    if (window.scrollY > 103) {
      this.tableHeaderRef.current.style = `top: ${window.scrollY - 104}px`;
    } else {
      this.tableHeaderRef.current.style = `top: 0`;
    }
  };

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeStaffSelector = result => {
    this.props.changeStaffSelector({
      staffSelector: result
    });
  };

  onAddCompare = () => {
    const { compareIds, staffSelector } = this.props;

    compareIds.push();
    staffSelector.forEach(staff => {
      compareIds.push(staff.value);
    });

    this.props.closeAddModal();
    this.props.getCompareList({
      compareIds
    });
  };

  closeModal = () => {
    const { closeAddModal } = this.props;

    closeAddModal();
  };

  deleteStaffFromCompare = id => () => this.props.deleteStaffFromCompare({ staffId: id });

  sendChangedStaffSelector = staffInput => {
    this.props.changeStaffInput({
      staffInput
    });
  };

  setTableRef = ref => {
    this.tableRef = ref;
  };

  renderBreadCrumbs = () => (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        href="/person/list"
        onClick={event => this.onBreadcrumbsClick(event, '/person/list')}
        text="Отчеты"
        key="Profile"
      />
      <BreadcrumbsItem
        href={`/compare/`}
        onClick={event => this.onBreadcrumbsClick(event, `/compare/`)}
        text="Сравнение"
        key="Compare"
      />
    </BreadcrumbsStateless>
  );

  renderHeaderButtons = () => {
    const { openAddModal, resultNames, resultTable } = this.props;

    const csvData = [];
    resultTable.forEach(section => {
      section.subsections.forEach(subsection => {
        const row = {
          title: `${section.title} - ${subsection.title}`
        };
        subsection.values.forEach((value, index) => row[`z${index}`] = `${value.value}%`);
        csvData.push(row)
      })
    })

    let csvColumns = [{
      id: 'title',
      displayName: 'Паттерн'
    }];

    resultNames.forEach((resultName, index) => {
      csvColumns.push({
        id: `z${index}`,
        displayName: resultName.fullName
      })
    })

    return (
      <ButtonGroup>
        <CsvDownloader
          filename="Сравнение отчетов"
          extension=".csv"
          separator=","
          wrapColumnChar=""
          columns={csvColumns}
          datas={csvData}
        >
          <Button>Экспортировать в .csv</Button>
        </CsvDownloader>
        <Button appearance="primary" onClick={openAddModal}>
          Добавить к сравнению
        </Button>
      </ButtonGroup>
    );
  };

  changePosition = (payload) => {
    const { changeColumnPosition } = this.props;
    changeColumnPosition(payload);
    // setTimeout(() => this.props.history.push(`/compare/l`), 0)
  }

  renderTable = () => {
    const { resultNames, resultTable } = this.props;

    return (
      <Table innerRef={ref => this.setTableRef(ref)}>
        <TableHeader innerRef={this.tableHeaderRef}>
          {resultNames.map((staff, index) => (
            <StaffName
              key={staff.id}
              name={staff.fullName}
              onClose={this.deleteStaffFromCompare(staff.id)}
              activeLeft={index > 0}
              activeRight={index < resultNames.length - 1}
              onLeftArrowClick={() => this.changePosition({ id: staff.id, diff: -1 })}
              onRightArrowClick={() => this.changePosition({ id: staff.id, diff: 1 })}
            />
          ))}
        </TableHeader>
        <TableBody>
          {resultTable.map(section => (
            <TableLine key={section.id}>
              <Line titleLine>{section.title}</Line>
              {section.subsections.map(subsection => (
                <LineBody key={subsection.id}>
                  <Line subtitleLine>{subsection.title}</Line>
                  <Line>
                    {subsection.values.map(subsectionVal => (
                      <LineItem
                        key={subsectionVal.id}
                      >
                        {subsectionVal.value}{!isNaN(+subsectionVal.value) ? "%" : ""}
                      </LineItem>
                    ))}
                  </Line>
                </LineBody>
              ))}
            </TableLine>
          ))}
        </TableBody>
      </Table>
    );
  };

  render() {
    const {
      addModals,
      compareLength,
      loading,
      openAddModal,
      staffList,
      staffListLoading,
      compareIds
    } = this.props;

    let content = <Spinner />;

    if (!loading && compareLength > 0) {
      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.renderHeaderButtons()} breadcrumbs={this.renderBreadCrumbs()}>
              Сравнение отчетов
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{this.renderTable()}</GridColumn>
        </Grid>
      );
    }

    if (!loading && compareLength === 0) {
      content = (
        <Empty
          header="Сравнения недоступны"
          text="Чтобы открыть сравнения, необходимо добавить к сравнению хотя бы один отчет."
        >
          <Button appearance="primary" onClick={() => openAddModal()}>
            Добавить к сравнению
          </Button>
        </Empty>
      );
    }

    return (
      <Page>
        <Helmet>Сравнение отчетов</Helmet>

        {content}
        {addModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Добавить к сравнению"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Добавить',
                onClick: this.onAddCompare
              },
              {
                text: 'Отмена',
                onClick: this.closeModal
              }
            ]}
          >
            <p>
              Выберите одного или нескольких людей, которых вы хотите добавить к&nbsp;сравнению.
            </p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup marginTop="8px">
                <Field width="70%">
                  <Select
                    className="multi-select"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isLoading={staffListLoading}
                    isMulti
                    label="Профиль"
                    loadingMessage={() => 'Загрузка'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.onChangeStaffSelector}
                    onInputChange={this.sendChangedStaffSelector}
                    options={staffList}
                    placeholder="Начните печатать"
                    shouldFitContainer
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}
      </Page>
    );
  }
}

CompareList.propTypes = {
  addModals: PropTypes.array.isRequired,
  changeStaffInput: PropTypes.func.isRequired,
  changeStaffSelector: PropTypes.func.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  compareIds: PropTypes.array.isRequired,
  compareLength: PropTypes.number.isRequired,
  compareResult: PropTypes.object.isRequired,
  deleteStaffFromCompare: PropTypes.func.isRequired,
  changeColumnPosition: PropTypes.func.isRequired,
  getCompareList: PropTypes.func.isRequired,
  getStaffList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openAddModal: PropTypes.func.isRequired,
  resultNames: PropTypes.array.isRequired,
  resultTable: PropTypes.array.isRequired,
  staffList: PropTypes.array.isRequired,
  staffListLoading: PropTypes.bool.isRequired,
  staffSelector: PropTypes.array.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeStaffInput: value => dispatch(changeStaffInput(value)),
    changeStaffSelector: value => dispatch(changeStaffSelector(value)),
    closeAddModal: () => dispatch(closeAddModal()),
    deleteStaffFromCompare: id => dispatch(deleteStaffFromCompare(id)),
    getCompareList: compareIds => dispatch(getCompareList(compareIds)),
    getStaffList: () => dispatch(getStaffList()),
    openAddModal: () => dispatch(openAddModal()),
    changeColumnPosition: id => dispatch(changeColumnPosition(id))
  };
}

const mapStateToProps = createStructuredSelector({
  addModals: makeSelectAddModals(),
  compareIds: makeSelectCompareIds(),
  compareLength: makeSelectCompareLength(),
  compareResult: makeSelectCompareResult(),
  loading: makeSelectLoading(),
  resultNames: makeSelectResultStaff(),
  resultTable: makeSelectResultTable(),
  staffList: makeSelectStaffList(),
  staffListLoading: makeSelectStaffListLoading(),
  staffSelector: makeSelectStaffSelector()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'compareList', reducer });
const withSaga = injectSaga({ key: 'compareList', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(CompareList);
