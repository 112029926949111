import {
  GET_FINANCES_LIST,
  GET_FINANCES_LIST_SUCCESS,
  GET_FINANCES_LIST_FAIL,
  CHANGE_FINANCES_PAGE,
  CHANGE_DATE_FILTER,
  CHANGE_TYPE_FILTER
} from './constants';

export function getFinancesList() {
  return {
    type: GET_FINANCES_LIST
  };
}

export function getFinancesListSuccess(payload) {
  return {
    type: GET_FINANCES_LIST_SUCCESS,
    ...payload
  };
}

export function getFinancesListFail(payload) {
  return {
    type: GET_FINANCES_LIST_FAIL,
    ...payload
  };
}

export function changeFinancesPage(payload) {
  return {
    type: CHANGE_FINANCES_PAGE,
    ...payload
  };
}

export function changeDateFilter(payload) {
  return {
    type: CHANGE_DATE_FILTER,
    ...payload
  };
}

export function changeTypeFilter(payload) {
  return {
    type: CHANGE_TYPE_FILTER,
    ...payload
  };
}
