import styled from 'styled-components';
import posed from 'react-pose';

const wrapperPose = {
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};

const Tooltip = styled(posed.div(wrapperPose))`
  background-color: #172b4d;
  border-radius: 3px;
  box-sizing: border-box;
  height: 20px;
  padding: 0 8px;
  text-align: center;
  text-wrap: none;
`;

export default Tooltip;
