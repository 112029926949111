import React from 'react';
import {Link, Route} from 'react-router-dom';
import BadgeWrapper from "../../../pages/Home/styled/BadgeWrapper";
import Badge from "@atlaskit/badge";
import reducer from 'pages/Home/reducer';
import {createStructuredSelector} from "reselect";
import { makeSelectUnreadReportsCount } from "../../../pages/Home/selectors";
import {connect} from "react-redux";
import injectReducer from "../../../utils/injectReducer";
import {compose} from "redux";

const ConnectedLinkItem = ({ components: C, to, ...props }) => (
  <Route
    render={({ location: { pathname } }) => (
      <C.Item
        component={({ children, className }) => (
          <Link className={className} to={to}>
            {children}
            {
              props.badge ? (
                <BadgeWrapper>
                  <Badge appearance="primaryInverted">{props.unreadReports}</Badge>
                </BadgeWrapper>
              ) : null
            }
          </Link>
        )}
        isSelected={pathname === to}
        {...props}
      />
    )}
  />
);

const mapStateToProps = createStructuredSelector({
  unreadReports: makeSelectUnreadReportsCount(),
});

const withConnect = connect(
  mapStateToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });

export default compose(
  withReducer,
  withConnect
)(ConnectedLinkItem);
