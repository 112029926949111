import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileCreateAskStepOnePage from 'pages/ProfileCreateAsk/StepOne';

const ProfileCreateAskStepOneView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileCreateAskStepOnePage />
  </Fragment>
);

export default ProfileCreateAskStepOneView;
