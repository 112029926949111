import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import Image from './image.png';
import Image2x from './image-2x.png';

const Container = styled.div`
  align-items: center;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
`;

const ErrorMessageContainer = styled.div`
  margin: 16px 0 53px 0;
`;

const P = styled.p`
  text-align: center;
`;

class ErrorPage extends React.Component {
  render() {
    return (
      <Page>
        <Helmet>
          <title>Ссылка недействительна</title>
        </Helmet>

        <Grid layout="fixed">
          <GridColumn medium={12}>
            <Container>
              <h3>Ссылка недействительна</h3>

              <ErrorMessageContainer>
                <P>
                  Ссылка на опрос работает только один раз.
                  <br />
                  Уже проходили регистрацию? <Link to="/individual/login">Войдите в личный кабинет</Link>
                </P>
              </ErrorMessageContainer>

              <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
            </Container>
          </GridColumn>
        </Grid>
      </Page>
    );
  }
}

export default ErrorPage;
