import styled from 'styled-components';

const RightColumn = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 560px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(50% - 15px);
  }

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export default RightColumn;
