import styled from 'styled-components';

const Field = styled.div`
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'initial')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'initial')};
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};

  & .react-select__control {
    ${props => (props.isInvalid ? 'border-color: #DC371D !important;' : '')};
  }
`;

export default Field;
