import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PersonReportBase from './components/PersonReportBase';

const PersonReportView = () => (
  <UIControllerSubscriber>
    {navigationUIController => <PersonReportBase navigationUIController={navigationUIController} />}
  </UIControllerSubscriber>
);

export default PersonReportView;
