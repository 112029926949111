import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import * as moment from 'moment';
import 'moment/locale/ru';
import Button from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Flag, { FlagGroup } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { colors } from '@atlaskit/theme';
import queryString from 'query-string';
import Info from 'components/Info';
import Modal from 'components/Modal';
import Plan from 'components/Plan';
import Spinner from 'components/Spinner';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { changeAddsModal } from 'pages/Home/actions';

import { makeSelectCompany } from 'pages/Home/selectors';

import {
  activatePlan,
  billingPlansList,
  changePaymentWasSend,
  changeProlongFlag,
  checkOrder,
  closeActivatePlanModal,
  closeProlongPlanModal,
  openActivatePlanModal,
  openProlongPlanModal,
  sendToPaymentPage
} from '../actions';
import {
  makeSelectActivateModal,
  makeSelectActivatePlanId,
  makeSelectCheckProlongOrderResult,
  makeSelectLoadingActivate,
  makeSelectLoadingBilling,
  makeSelectLoadingProlong,
  makeSelectPlansList,
  makeSelectProlongModal,
  makeSelectPayment
} from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import Container from '../styled/Container';

import styled from 'styled-components';
const CurrentPlan = styled.div`
  margin-top: 21px;
`;
const H5 = styled.h5`
  letter-spacing: -0.15px;
  margin: 0;
`;
const H4 = styled.h4`
  letter-spacing: -0.32px;
  margin: 6px 0;
`;
const TextRight = styled.div`
  position: relative;
  text-align: right;
`;
const P = styled.p`
  margin: 0;
`;
const getReportsCountBefore = ({ remainingCount, includedCount }) => {
  const percentage = (remainingCount / includedCount) * 100;
  let result = `width: ${percentage}%;`;

  if (percentage > 60) {
    if (percentage === 100) {
      result = `${result} border-radius: 4px;`;
    } else {
      result = `${result} border-radius: 4px 0 0 4px;`;
    }

    return `${result} background-color: #36B37E;`;
  }

  if (percentage > 20) {
    return `${result} background-color: #FFAB00; border-radius: 4px 0 0 4px;`;
  }

  if (percentage > 1) {
    return `${result} background-color: #FF5230; border-radius: 4px 0 0 4px`;
  }

  return `${result} background-color: #D7D9DB; border-radius: 4px 0 0 4px`;
};
const ReportsCount = styled.div`
  background-color: #d7d9db;
  border-radius: 4px;
  height: 8px;
  margin-top: 14px;
  position: relative;
  width: 100%;

  &:before {
    border-radius: 4px;
    content: '';
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    ${getReportsCountBefore}
  }
`;
const Flex = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 12px -16px;
  max-width: 560px;
  padding: 0 8px;
  position: relative;

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;
const Rouble = styled.span`
  font-family: 'Roboto', sans-serif;
`;
const InfoWrapper = styled.div`
  position: absolute;
  right: -20px;
  top: -1px;
`;
const Plans = styled.div`
  margin: 45px 0;
`;

class BillingPlans extends React.Component {
  componentDidMount = () => {
    const getParams = queryString.parse(this.props.location.search);

    const reportsCount = this.props.match.params.reports;
    if (!isUndefined(getParams.orderId) && isUndefined(reportsCount)) {
      this.props.checkOrder({
        orderId: getParams.orderId
      });
    }

    this.props.billingPlansList();
  };

  removeFlag = () => {
    this.props.changeProlongFlag({
      prolongFlag: []
    });
  };

  removePaymentFlag = () => {
    this.props.changePaymentWasSend({
      wasSend: false
    });
  };

  onActivatePlan = () => {
    const { activatePlanId, plansList } = this.props;

    const plan = plansList.find(plan => plan.id === activatePlanId);

    if (plan.price_per_year === 0) {
      this.props.activatePlan();
    } else {
      const cost = plan.price_per_year;
      const paymentType = 'activatePlan';
      const service = `Тариф «${plan.name}»`;

      this.props.sendToPaymentPage({
        cost,
        paymentType,
        service
      });
    }
  };

  onProlongPlan = () => {
    const { company } = this.props;

    const cost = company.billing_plan.price_per_year;
    const paymentType = 'prolongPlan';
    const service = `Тариф «${company.billing_plan.name}»`;

    this.props.sendToPaymentPage({
      cost,
      paymentType,
      service
    });
  };

  render() {
    const {
      activateModal,
      activatePlanId,
      changeAddsModal,
      checkProlongOrderResult,
      closeActivatePlanModal,
      company,
      closeModal,
      loadingActivate,
      loadingBilling,
      loadingProlong,
      openActivatePlanModal,
      openModal,
      payment,
      prolongModal
    } = this.props;

    const plansList = this.props.plansList.filter(plan => plan.id !== company.billing_plan.id);

    let content = <Spinner />;

    const nowMoment = moment();
    const planEndMoment = moment(company.billing_plan_valid_until);
    const showProlongBtn = planEndMoment.diff(nowMoment, 'days') < 1;

    if (!loadingBilling && company.id !== 0 && plansList.length > 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/settings/billing"
            onClick={event => this.onBreadcumbsClick(event, '/settings/billing')}
            text="Настройки"
            key="Settings"
          />
          <BreadcrumbsItem
            href="/settings/billing"
            onClick={event => this.onBreadcumbsClick(event, '/settings/billing')}
            text="Тарифы"
            key="Billing"
          />
        </BreadcrumbsStateless>
      );
      const tooltipText = (
        <div>
          <P>Стоимость дополнительных отчетов:</P>
          {company.billing_plan.ranges.map(range => {
            if (isNull(range.quantity_range.upper)) {
              return (
                <P key={range.quantity_range.lower}>
                  от {range.quantity_range.lower.toLocaleString()} —{' '}
                  {range.price_per_report.toLocaleString()} <Rouble fontWeight={300}>₽</Rouble> за
                  отчет;
                </P>
              );
            }

            return (
              <P key={range.quantity_range.lower}>
                от {range.quantity_range.lower.toLocaleString()} до{' '}
                {(parseInt(range.quantity_range.upper, 10) - 1).toLocaleString()} —{' '}
                {range.price_per_report.toLocaleString()} <Rouble fontWeight={300}>₽</Rouble> за
                отчет;
              </P>
            );
          })}
        </div>
      );
      let validityText = 'Бессрочно';
      if (!isNull(company.billing_plan_valid_until)) {
        validityText = `До ${moment(company.billing_plan_valid_until).format('DD.MM.YYYY')}`;
      }

      content = (
        <Fragment>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader breadcrumbs={breadcrumbs}>Тарифы</PageHeader>
            </GridColumn>
          </Grid>
          <div>
            <Grid>
              <GridColumn medium={12}>
                <h3>Текущий тариф</h3>
              </GridColumn>
            </Grid>
            <Grid>
              <GridColumn medium={8}>
                <CurrentPlan>
                  <H5>{company.billing_plan.name}</H5>
                  <H4>
                    Доступно отчетов:{' '}
                    {company.extra_reports_count + company.billing_plan_available_reports_count} из{' '}
                    {company.extra_reports_count + company.billing_plan.included_reports_count}
                  </H4>
                  <ReportsCount
                    includedCount={
                      company.extra_reports_count + company.billing_plan.included_reports_count
                    }
                    remainingCount={
                      company.extra_reports_count + company.billing_plan_available_reports_count
                    }
                  />
                </CurrentPlan>
              </GridColumn>
              <GridColumn medium={1} />
              <GridColumn medium={3}>
                <CurrentPlan>
                  <H5>&nbsp;</H5>
                  <H4>Срок действия</H4>
                  <H5>{validityText}</H5>
                </CurrentPlan>
              </GridColumn>
            </Grid>
            <Grid>
              <GridColumn medium={8}>
                <Flex>
                  <GridColumn medium={5}>
                    <H5>Включено отчетов</H5>
                  </GridColumn>
                  <GridColumn medium={3}>
                    <TextRight>
                      <H5>{company.billing_plan.included_reports_count}</H5>
                    </TextRight>
                  </GridColumn>
                </Flex>
                <Flex>
                  <GridColumn medium={5}>
                    <H5>Дополнительные отчеты</H5>
                  </GridColumn>
                  <GridColumn medium={3}>
                    <TextRight>
                      <H5>{company.extra_reports_count}</H5>
                    </TextRight>
                  </GridColumn>
                </Flex>
                <Flex>
                  <GridColumn medium={5}>
                    <H5>Каждый дополнительный отчет</H5>
                  </GridColumn>
                  <GridColumn medium={3}>
                    <TextRight>
                      <H5>
                        от{' '}
                        {company.billing_plan.ranges[
                          company.billing_plan.ranges.length - 1
                        ].price_per_report.toLocaleString()}{' '}
                        до {company.billing_plan.ranges[0].price_per_report.toLocaleString()}{' '}
                        <Rouble>₽</Rouble>
                      </H5>
                      <InfoWrapper>
                        <Info isBig text={tooltipText} />
                      </InfoWrapper>
                    </TextRight>
                  </GridColumn>
                </Flex>
                <Flex>
                  <GridColumn medium={5}>
                    <H5>Абонентская плата в год</H5>
                  </GridColumn>
                  <GridColumn medium={3}>
                    <TextRight>
                      <H5>
                        {company.billing_plan.price_per_year.toLocaleString()} <Rouble>₽</Rouble>
                      </H5>
                    </TextRight>
                  </GridColumn>
                </Flex>
              </GridColumn>
            </Grid>
            <Grid>
              <GridColumn medium={5}>
                <Button
                  appearance="primary"
                  onClick={() =>
                    changeAddsModal({
                      addsModal: [1]
                    })
                  }
                  shouldFitContainer
                >
                  Купить дополнительные отчеты
                </Button>
              </GridColumn>
              {showProlongBtn && (
                <GridColumn medium={5}>
                  <Button appearance="primary" onClick={openModal} shouldFitContainer>
                    Продлить тариф на год
                  </Button>
                </GridColumn>
              )}
            </Grid>
          </div>
          <Plans>
            <Grid>
              <GridColumn medium={12}>
                <h3>Другие тарифы</h3>
              </GridColumn>
            </Grid>
            <Grid>
              {plansList.map(plan => (
                <GridColumn key={plan.id} medium={6}>
                  <Plan
                    activateHandle={openActivatePlanModal}
                    id={plan.id}
                    name={plan.name}
                    reports={plan.included_reports_count}
                    plan={plan}
                    pay={plan.price_per_year}
                    priceFrom={plan.price_per_report_1000}
                    priceTo={plan.price_per_report_4001}
                  />
                </GridColumn>
              ))}
            </Grid>
          </Plans>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Тарифы</title>
        </Helmet>
        <Container>
          {content}

          {activateModal.map(id => {
            let activateText = `Количество отчетов при переходе на новый тариф не суммируется. Неизрасходованные отчеты на текущем тарифе сгорят. Вы уверены, что хотите перейти на тариф «${
              plansList.find(plan => plan.id === activatePlanId).name
            }»?`;
            if (company.billing_plan_available_reports_count === 0) {
              activateText = `Вы уверены, что хотите перейти на тариф «${
                plansList.find(plan => plan.id === activatePlanId).name
              }»? `;
            }

            return (
              <Modal
                autoFocus={false}
                key={id}
                id={id}
                heading="Активировать тариф"
                onClose={closeActivatePlanModal}
                actions={[
                  {
                    isLoading: loadingActivate,
                    onClick: this.onActivatePlan,
                    text: 'ОК'
                  },
                  {
                    text: 'Отменить',
                    onClick: closeActivatePlanModal
                  }
                ]}
                width="small"
              >
                <P>{activateText}</P>
              </Modal>
            );
          })}
          {prolongModal.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Продлить тариф"
              onClose={closeModal}
              actions={[
                {
                  isLoading: loadingProlong,
                  onClick: this.onProlongPlan,
                  text: 'ОК'
                },
                {
                  text: 'Отменить',
                  onClick: closeModal
                }
              ]}
              width="small"
            >
              <P>
                Вы будете перенаправлены на страницу оплаты. Стоимость тарифа{' '}
                {company.billing_plan.price_per_year.toLocaleString()} рублей в год. Уверены что
                хотите продлить тариф «{company.billing_plan.name}»?
              </P>
            </Modal>
          ))}

          <FlagGroup onDismissed={this.removeFlag}>
            {checkProlongOrderResult.map(flag => (
              <Flag
                description=""
                icon={
                  flag === 1 ? (
                    <CheckCircleIcon label="Успех" primaryColor={colors.G400} />
                  ) : (
                    <CrossCircleIcon label="Ошибка" primaryColor={colors.R400} />
                  )
                }
                id={flag}
                isDismissAllowed
                key={flag}
                title={flag === 1 ? 'Вы активировали тариф' : 'Ошибка при активации'}
              />
            ))}
          </FlagGroup>

          <FlagGroup onDismissed={this.removePaymentFlag}>
            {payment.wasSend.map(flag => (
              <Flag
                description=""
                icon={<CheckCircleIcon label="Успех" primaryColor={colors.G400} />}
                id={flag}
                isDismissAllowed
                key={flag}
                title="Счет отправлен на указанную почту"
              />
            ))}
          </FlagGroup>
        </Container>
      </Fragment>
    );
  }
}

BillingPlans.propTypes = {
  activateModal: PropTypes.array,
  activatePlan: PropTypes.func,
  activatePlanId: PropTypes.number,
  billingPlansList: PropTypes.func,
  changeAddsModal: PropTypes.func,
  changePaymentWasSend: PropTypes.func,
  changeProlongFlag: PropTypes.func,
  checkOrder: PropTypes.func,
  checkProlongOrderResult: PropTypes.array,
  closeActivatePlanModal: PropTypes.func,
  closeModal: PropTypes.func,
  company: PropTypes.object,
  loadingActivate: PropTypes.bool,
  loadingBilling: PropTypes.bool,
  loadingProlong: PropTypes.bool,
  openActivatePlanModal: PropTypes.func,
  openModal: PropTypes.func,
  payment: PropTypes.object,
  plansList: PropTypes.array,
  prolongModal: PropTypes.array,
  sendToPaymentPage: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    activatePlan: () => dispatch(activatePlan()),
    billingPlansList: () => dispatch(billingPlansList()),
    changeAddsModal: value => dispatch(changeAddsModal(value)),
    changePaymentWasSend: value => dispatch(changePaymentWasSend(value)),
    changeProlongFlag: value => dispatch(changeProlongFlag(value)),
    checkOrder: value => dispatch(checkOrder(value)),
    closeActivatePlanModal: () => dispatch(closeActivatePlanModal()),
    closeModal: () => dispatch(closeProlongPlanModal()),
    openActivatePlanModal: value => dispatch(openActivatePlanModal(value)),
    openModal: () => dispatch(openProlongPlanModal()),
    sendToPaymentPage: value => dispatch(sendToPaymentPage(value))
  };
}

const mapStateToProps = createStructuredSelector({
  activateModal: makeSelectActivateModal(),
  activatePlanId: makeSelectActivatePlanId(),
  checkProlongOrderResult: makeSelectCheckProlongOrderResult(),
  company: makeSelectCompany(),
  loadingActivate: makeSelectLoadingActivate(),
  loadingBilling: makeSelectLoadingBilling(),
  loadingProlong: makeSelectLoadingProlong(),
  payment: makeSelectPayment(),
  plansList: makeSelectPlansList(),
  prolongModal: makeSelectProlongModal()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(BillingPlans);
