import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { apiRequest } from 'utils/partnerRequest';

import {
  GET_REQUISITES_LIST,
  CHANGE_REQUISITES_PAGE,
  REGISTER_NEW_REQUISITES,
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION
} from './constants';

import {
  getRequisitesList,
  getRequisitesListSuccess,
  getRequisitesListFail,
  registerNewRequisitesSuccess,
  registerNewRequisitesFail,
  getOrganizationSuccess,
  getOrganizationFail,
  updateOrganizationSuccess,
  updateOrganizationFail,
  deleteOrganizationSuccess,
  deleteOrganizationFail
} from './actions';

import {
  makeSelectRequisitesPage,
  makeSelectRegistrationForm,
  makeSelectOrganizationForm
} from './selectors';

function* getRequisitesListSaga() {
  const page = yield select(makeSelectRequisitesPage());
  const offset = page * 25 - 25;
  let url = `api/partners/bank_details/`;

  if (page > 1) {
    url = `api/partners/bank_details/?offset=${offset}`;
  }

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getRequisitesListSuccess({
        count: request.data.count,
        list: request.data.results
      })
    );
  } catch (error) {
    yield put(
      getRequisitesListFail({
        error: error.response.data
      })
    );
  }
}

function* registerNewRequisitesSaga() {
  const data = yield select(makeSelectRegistrationForm());

  const url = 'api/partners/bank_details/';
  const options = {
    method: 'post',
    data
  };

  try {
    yield call(apiRequest, url, options);
    yield put(registerNewRequisitesSuccess());
  } catch (error) {
    yield put(
      registerNewRequisitesFail({
        error: error.response.data
      })
    );
  } finally {
    yield put(push('/partner/requisites'));
  }
}

function* getOrganizationSaga({ id }) {
  const url = `api/partners/bank_details/${id}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getOrganizationSuccess({
        organization: request.data
      })
    );
  } catch (error) {
    yield put(
      getOrganizationFail({
        error: error.response.data
      })
    );
  }
}

function* updateOrganizationSaga({ id }) {
  const url = `api/partners/bank_details/${id}/`;
  const data = yield select(makeSelectOrganizationForm());
  const options = {
    method: 'put',
    data
  };

  try {
    yield call(apiRequest, url, options);
    yield put(updateOrganizationSuccess());
  } catch (error) {
    yield put(
      updateOrganizationFail({
        error: error.response.data
      })
    );
  } finally {
    yield put(push('/partner/requisites'));
  }
}

function* deleteOrganizationSaga({ id }) {
  const url = `api/partners/bank_details/${id}/`;
  const options = { method: 'DELETE' };

  try {
    yield call(apiRequest, url, options);
    yield put(deleteOrganizationSuccess());
  } catch (e) {
    yield put(deleteOrganizationFail());
  } finally {
    yield put(getRequisitesList());
  }
}

export default function* partnerClientsSaga() {
  yield takeLeading(GET_REQUISITES_LIST, getRequisitesListSaga);
  yield takeLeading(CHANGE_REQUISITES_PAGE, getRequisitesListSaga);
  yield takeLeading(REGISTER_NEW_REQUISITES, registerNewRequisitesSaga);
  yield takeLeading(GET_ORGANIZATION, getOrganizationSaga);
  yield takeLeading(UPDATE_ORGANIZATION, updateOrganizationSaga);
  yield takeLeading(DELETE_ORGANIZATION, deleteOrganizationSaga);
}
