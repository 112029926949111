import styled from 'styled-components';
import Bg from '../images/empty.jpg';

const Image = styled.div`
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 180px;
  width: 300px;
`;

export default Image;
