import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PartnerRequisitesBase from './components/PartnerRequisitesBase';

const PartnerRequisitesView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <PartnerRequisitesBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default PartnerRequisitesView;
