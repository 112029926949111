import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import Bg from './bg.jpg';

import { isRequired } from 'utils/validators';

const Wrapper = styled.section`
  background-color: #fafafc;
  display: flex;
  min-height: 700px;
  padding: 120px 0 20px;
  width: 100%;

  @media (max-width: 1200px) and (min-width: 1021px) {
    min-height: auto;
    padding: 40px 0;
  }

  @media (max-width: 1020px) {
    margin-top: 50px;
    padding: 50px 0;
  }
`;

const Inner = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1020px) {
    display: block;
    width: calc(100% - 30px);
  }
`;

const LeftColumn = styled.div`
  margin-left: auto;
  width: 560px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(55% - 15px);
  }

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  background-image: url(${Bg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 45%;

  @media (max-width: 1020px) {
    height: 255px;
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 29px;
  color: #172b4d;
  letter-spacing: 0.32px;
  line-height: 32px;

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  color: #172b4d;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin-top: 21px;

  @media (max-width: 1020px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
`;

const Form = styled.form`
  margin-top: 32px;
`;

const FormInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.marginTop}px;
  width: 100%;

  @media (max-width: 1020px) {
    display: block;
  }
`;

const Field = styled.div`
  width: 260px;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

class Feedback extends React.Component {
  state = {
    name: '',
    nameValid: false,
    phone: '',
    phoneValid: false,
    isDisabled: true
  };

  changeName = e => {
    if (isRequired(e.target.value)) {
      this.setState({
        name: e.target.value,
        nameValid: true,
        isDisabled: !this.state.phoneValid
      });
    } else {
      this.setState({
        name: e.target.value,
        nameValid: false,
        isDisabled: true
      });
    }

    this.props.onChangeName({
      name: e.target.value
    });
  };

  changePhone = e => {
    if (isRequired(e.target.value)) {
      this.setState({
        phone: e.target.value,
        phoneValid: true,
        isDisabled: !this.state.nameValid
      });
    } else {
      this.setState({
        phone: e.target.value,
        phoneValid: false,
        isDisabled: true
      });
    }

    this.props.onChangePhone({
      phone: e.target.value
    });
  };

  render() {
    return (
      <Wrapper id="feedback">
        <Inner>
          <LeftColumn>
            <Title>Подпишитесь, чтобы узнать о запуске</Title>
            <Text>
              Запуск сервиса уже совсем скоро, поэтому, если вы хотите быть первым и начать
              пользоваться им как можно раньше, оставьте свои контактные данные, и мы сообщим вам о
              запуске.
            </Text>
            <Form>
              <FormInner>
                <Field>
                  <TextField
                    label="Ваше имя"
                    name="name"
                    onChange={this.changeName}
                    placeholder=""
                    value={this.state.name}
                    shouldFitContainer
                  />
                </Field>
                <Field>
                  <TextField
                    label="Ваш номер телефона"
                    name="phone"
                    onChange={this.changePhone}
                    placeholder="+79999999999"
                    value={this.state.email}
                    shouldFitContainer
                  />
                </Field>
              </FormInner>
              <FormInner marginTop={30}>
                <StyledButton
                  appearance="primary"
                  onClick={this.props.onFormSubmit}
                  isDisabled={this.state.isDisabled}
                >
                  Отправить
                </StyledButton>
              </FormInner>
            </Form>
          </LeftColumn>
          <RightColumn />
        </Inner>
      </Wrapper>
    );
  }
}

Feedback.propTypes = {
  onChangeName: PropTypes.func,
  onChangePhone: PropTypes.func,
  onFormSubmit: PropTypes.func
};

export default Feedback;
