import { fromJS } from 'immutable';
import service from '../../utils/checkService';

import {
  CHANGE_PHONE,
  CHANGE_CODE,
  SEND_CREATE_REQUEST,
  SEND_CREATE_REQUEST_SUCCESS,
  SEND_CREATE_REQUEST_FAIL,
  SEND_VERIFY_REQUEST,
  SEND_VERIFY_REQUEST_SUCCESS,
  SEND_VERIFY_REQUEST_FAIL,
  USER_READ_REQUEST,
  USER_READ_REQUEST_SUCCESS,
  USER_READ_REQUEST_FAIL,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_REQUEST_SUCCESS,
  USER_UPDATE_REQUEST_FAIL,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_INVITE_CODE_REQUEST_SUCCESS,
  CHECK_INVITE_CODE_REQUEST_FAIL,
  CHECK_ORDER_REQUEST,
  CHECK_ORDER_REQUEST_SUCCESS,
  CHECK_ORDER_REQUEST_FAIL,
  CHANGE_TERMS_ACCEPTED
} from './constants';

const initialState = fromJS({
  phone: '',
  code: /*service === "my-yadro" ? */"0000"/* : ""*/,
  smsWasSend: false,
  verified: false,
  invitationCode: null,
  loading: false,
  orderId: null,
  isPaid: false,
  termsAccepted: false,
  user: {
    pk: 0,
    phone_number: '',
    email: '',
    first_name: '',
    last_name: ''
  }
});

function testStartReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PHONE:
      return state.set('phone', action.phone);
    case CHANGE_CODE:
      return state.set('code', action.code);
    case SEND_CREATE_REQUEST:
      return state.set('loading', true).set('smsWasSend', false);
    case SEND_CREATE_REQUEST_SUCCESS:
      return state.set('loading', false).set('smsWasSend', true);
    case SEND_CREATE_REQUEST_FAIL:
      return state.set('loading', false).set('smsWasSend', false);
    case SEND_VERIFY_REQUEST:
      return state
        .set('loading', true)
        .set('verified', false)
        .set('invitationCode', action.invitationCode);
    case SEND_VERIFY_REQUEST_SUCCESS:
      return state.set('loading', false).set('verified', true);
    case SEND_VERIFY_REQUEST_FAIL:
      return state.set('loading', false).set('verified', false);
    case USER_READ_REQUEST:
      return state.set('loading', true);
    case USER_READ_REQUEST_SUCCESS:
      return state.set('loading', false).set('user', action.user).set('isPaid', action.user.is_paid);
    case USER_READ_REQUEST_FAIL:
      return state.set('loading', false);
    case CHANGE_NAME:
      return state.setIn(['user', 'first_name'], action.name);
    case CHANGE_SURNAME:
      return state.setIn(['user', 'last_name'], action.surname);
    case CHANGE_EMAIL:
      return state.setIn(['user', 'email'], action.email);
    case USER_UPDATE_REQUEST:
      return state.set('loading', true);
    case USER_UPDATE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case USER_UPDATE_REQUEST_FAIL:
      return state.set('loading', false);
    case CHECK_INVITE_CODE_REQUEST:
      return state.set('loading', true).set('invitationCode', action.invitationCode);
    case CHECK_INVITE_CODE_REQUEST_SUCCESS:
      return state.set('loading', false);
    case CHECK_INVITE_CODE_REQUEST_FAIL:
      return state.set('loading', false);
    case CHECK_ORDER_REQUEST:
      return state.set('loading', true).set('orderId', action.orderId);
    case CHECK_ORDER_REQUEST_SUCCESS:
      return state.set('loading', false).set('isPaid', action.isPaid);
    case CHECK_ORDER_REQUEST_FAIL:
      return state.set('loading', false).set('isPaid', false);
    case CHANGE_TERMS_ACCEPTED:
      return state.set('termsAccepted', action.termsAccepted);
    default:
      return state;
  }
}

export default testStartReducer;
