import { createSelector } from 'reselect';

const selectGlobal = state => state.get('settings');

const makeSelectSettingsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectLoadingTable = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingTable')
  );

const makeSelectManagersCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managersCount')
  );

const makeSelectManagersPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managersPage')
  );

const makeSelectInvitesCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesCount')
  );

const makeSelectInvitesPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesPage')
  );

const makeSelectSettingsUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('user')
  );

const makeSelectManagersList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managersList')
  );

const makeSelectLoadingInvite = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingInvite')
  );

const makeSelectInvite = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invite')
  );

const makeSelectInviteFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteFlags').toJS()
  );

const makeSelectInviteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteModals').toJS()
  );

const makeSelectInvitesList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesList')
  );

const makeSelectInviteId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteId')
  );

const makeSelectNameFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('nameFilter')
  );

const makeSelectManagersSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('managersSort')
  );

const makeSelectInvitesSort = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesSort')
  );

const makeSelectInvitesFilter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesFilter')
  );

const makeSelectDeleteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('deleteModals').toJS()
  );

const makeSelectLoadingDelete = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingDelete')
  );

const makeSelectLoadingBilling = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingBilling')
  );

const makeSelectPlansList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('plansList').toJS()
  );

const makeSelectProlongModal = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('prolongModal').toJS()
  );

const makeSelectLoadingProlong = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingProlong')
  );

const makeSelectCheckProlongOrderResult = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('checkProlongOrderResult').toJS()
  );

const makeSelectCheckProlongOrderId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('checkProlongOrderId')
  );

const makeSelectActivateModal = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('activateModal').toJS()
  );

const makeSelectActivatePlanId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('activatePlanId')
  );

const makeSelectLoadingActivate = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingActivate')
  );

const makeSelectPayment = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('payment').toJS()
  );

const makeSelectOrganizationsPage = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationsPage')
  );

const makeSelectOrganizationsList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationsList').toJS()
  );

const makeSelectOrganizationsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingOrganizations')
  );

const makeSelectOrganizationDeleteModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationDeleteModals').toJS()
  );

const makeSelectOrganizationId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationId')
  );

const makeSelectOrganizationsCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationsCount')
  );

const makeSelectOrganizationsMounted = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationsMounted')
  );

const makeSelectOrganization = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organization').toJS()
  );

const makeSelectLoadingOrganizationCreate = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loadingOrganizationCreate')
  );

const makeSelectOrganizationsFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('organizationsFlags').toJS()
  );

const makeSelectInviteStaff = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteStaff').toJS()
  );

const makeSelectInviteStaffFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteStaffFlags').toJS()
  );

const makeSelectInviteStaffModals = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('inviteStaffModals').toJS()
  );

const makeSelectInvitesByFileCount = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesByFileCount')
  );

const makeSelectInvitesByFileFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('invitesByFileFlags').toJS()
  );

export {
  makeSelectInviteStaff,
  makeSelectInviteStaffFlags,
  makeSelectInviteStaffModals,
  makeSelectSettingsLoading,
  makeSelectLoadingTable,
  makeSelectSettingsUser,
  makeSelectManagersCount,
  makeSelectManagersList,
  makeSelectManagersPage,
  makeSelectInvite,
  makeSelectInvitesCount,
  makeSelectInvitesList,
  makeSelectInviteId,
  makeSelectInvitesPage,
  makeSelectNameFilter,
  makeSelectInvitesFilter,
  makeSelectInvitesSort,
  makeSelectManagersSort,
  makeSelectLoadingInvite,
  makeSelectInviteFlags,
  makeSelectInviteModals,
  makeSelectDeleteModals,
  makeSelectLoadingDelete,
  makeSelectLoadingBilling,
  makeSelectPlansList,
  makeSelectProlongModal,
  makeSelectLoadingProlong,
  makeSelectCheckProlongOrderResult,
  makeSelectCheckProlongOrderId,
  makeSelectActivateModal,
  makeSelectActivatePlanId,
  makeSelectLoadingActivate,
  makeSelectPayment,
  makeSelectOrganizationsPage,
  makeSelectOrganizationsList,
  makeSelectOrganizationsLoading,
  makeSelectOrganizationDeleteModals,
  makeSelectOrganizationId,
  makeSelectOrganizationsCount,
  makeSelectOrganizationsMounted,
  makeSelectOrganization,
  makeSelectLoadingOrganizationCreate,
  makeSelectOrganizationsFlags,
  makeSelectInvitesByFileCount,
  makeSelectInvitesByFileFlags
};
