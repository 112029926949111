export const REPORT_LIST_REQUEST = 'hh/pages/individual/report/REPORT_LIST_REQUEST';
export const REPORT_LIST_REQUEST_SUCCESS = 'hh/pages/individual/report/REPORT_LIST_REQUEST_SUCCESS';
export const REPORT_LIST_REQUEST_FAIL = 'hh/pages/individual/report/REPORT_LIST_REQUEST_FAIL';
export const REPORT_LIST_REQUEST_NO_CONTENT_ERROR = 'hh/pages/individual/report/REPORT_LIST_REQUEST_NO_CONTENT_ERROR';

export const DISLIKE_REQUEST = 'hh/pages/individual/report/DISLIKE_REQUEST';
export const DISLIKE_REQUEST_SUCCESS = 'hh/pages/individual/report/DISLIKE_REQUEST_SUCCESS';
export const DISLIKE_REQUEST_FAIL = 'hh/pages/individual/report/DISLIKE_REQUEST_FAIL';

export const LIKE_REQUEST = 'hh/pages/individual/report/LIKE_REQUEST';
export const LIKE_REQUEST_SUCCESS = 'hh/pages/individual/report/LIKE_REQUEST_SUCCESS';
export const LIKE_REQUEST_FAIL = 'hh/pages/individual/report/LIKE_REQUEST_FAIL';

export const FIRE_REQUEST = 'hh/pages/individual/report/FIRE_REQUEST';
export const FIRE_REQUEST_SUCCESS = 'hh/pages/individual/report/FIRE_REQUEST_SUCCESS';
export const FIRE_REQUEST_FAIL = 'hh/pages/individual/report/FIRE_REQUEST_FAIL';

export const CREATE_NEW_COMMENT_BLOCK = 'hh/pages/individual/report/CREATE_NEW_COMMENT_BLOCK';
export const CHANGE_COMMENT_TEXT = 'hh/pages/individual/report/CHANGE_COMMENT_TEXT';
export const HIDE_COMMENT_BLOCK = 'hh/pages/individual/report/HIDE_COMMENT_BLOCK';
export const CREATE_EDIT_COMMENT_BLOCK = 'hh/pages/individual/report/CREATE_EDIT_COMMENT_BLOCK';

export const CREATE_COMMENT_REQUEST = 'hh/pages/individual/report/CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'hh/pages/individual/report/CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAIL = 'hh/pages/individual/report/CREATE_COMMENT_FAIL';

export const DELETE_COMMENT_REQUEST = 'hh/pages/individual/report/DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'hh/pages/individual/report/DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'hh/pages/individual/report/DELETE_COMMENT_FAIL';

export const CHANGE_EMAIL_MODALS = 'hh/pages/individual/report/CHANGE_EMAIL_MODALS';
export const CHANGE_EMAIL_FLAGS = 'hh/pages/individual/report/CHANGE_EMAIL_FLAGS';
export const CHANGE_EMAIL_TEXT = 'hh/pages/individual/report/CHANGE_EMAIL_TEXT';

export const SEND_EMAIL_REQUEST = 'hh/pages/individual/report/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'hh/pages/individual/report/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'hh/pages/individual/report/SEND_EMAIL_FAIL';

export const CREATE_DISLIKE = 'hh/pages/individual/report/CREATE_DISLIKE';

export const CHANGE_REPORT_SHAPE = 'hh/pages/individual/report/CHANGE_REPORT_SHAPE';
export const CHANGE_ORDER_REPORT_BY_RANK = 'hh/pages/individual/report/CHANGE_ORDER_REPORT_BY_RANK';
