export const GET_CLIENTS_LIST = 'hh/pages/partner/clients/GET_CLIENTS_LIST';
export const GET_CLIENTS_LIST_SUCCESS = 'hh/pages/partner/clients/GET_CLIENTS_LIST_SUCCESS';
export const GET_CLIENTS_LIST_FAIL = 'hh/pages/partner/clients/GET_CLIENTS_LIST_FAIL';
export const CHANGE_CLIENTS_LIST_PAGE = 'hh/pages/partner/clients/CHANGE_CLIENTS_LIST_PAGE';
export const CHANGE_CLIENT_REGISTRATION_FIELD =
  'hh/pages/partner/clients/CHANGE_CLIENT_REGISTRATION_FIELD';
export const RESET_CLIENT_REGISTRATION_FORM =
  'hh/pages/partner/clients/RESET_CLIENT_REGISTRATION_FORM';
export const REGISTER_NEW_CLIENT = 'hh/pages/partner/clients/REGISTER_NEW_CLIENT';
export const REGISTER_NEW_CLIENT_SUCCESS = 'hh/pages/partner/clients/REGISTER_NEW_CLIENT_SUCCESS';
export const REGISTER_NEW_CLIENT_FAIL = 'hh/pages/partner/clients/REGISTER_NEW_CLIENT_FAIL';
export const CHANGE_FLAGS = 'hh/pages/partner/clients/CHANGE_FLAGS';
export const GET_CLIENT = 'hh/pages/partner/clients/GET_CLIENT';
export const GET_CLIENT_SUCCESS = 'hh/pages/partner/clients/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = 'hh/pages/partner/clients/GET_CLIENT_FAIL';
export const CHANGE_CLIENT_COMMENT = 'hh/pages/partner/clients/CHANGE_CLIENT_COMMENT';
export const UPDATE_CLIENT = 'hh/pages/partner/clients/UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'hh/pages/partner/clients/UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'hh/pages/partner/clients/UPDATE_CLIENT_FAIL';
export const GET_MANAGERS_LIST = 'hh/pages/partner/clients/GET_MANAGERS_LIST';
export const GET_MANAGERS_LIST_SUCCESS = 'hh/pages/partner/clients/GET_MANAGERS_LIST_SUCCESS';
export const GET_MANAGERS_LIST_FAIL = 'hh/pages/partner/clients/GET_MANAGERS_LIST_FAIL';
export const CHANGE_MANAGERS_PAGE = 'hh/pages/partner/clients/CHANGE_MANAGERS_PAGE';
export const GET_REQUISITES_LIST = 'hh/pages/partner/clients/GET_REQUISITES_LIST';
export const GET_REQUISITES_LIST_SUCCESS = 'hh/pages/partner/clients/GET_REQUISITES_LIST_SUCCESS';
export const GET_REQUISITES_LIST_FAIL = 'hh/pages/partner/clients/GET_REQUISITES_LIST_FAIL';
export const CHANGE_REQUISITES_PAGE = 'hh/pages/partner/clients/CHANGE_REQUISITES_PAGE';
export const GET_FINANCES_LIST = 'hh/pages/partner/clients/GET_FINANCES_LIST';
export const GET_FINANCES_LIST_SUCCESS = 'hh/pages/partner/clients/GET_FINANCES_LIST_SUCCESS';
export const GET_FINANCES_LIST_FAIL = 'hh/pages/partner/clients/GET_FINANCES_LIST_FAIL';
export const CHANGE_FINANCES_PAGE = 'hh/pages/partner/clients/CHANGE_FINANCES_PAGE';
export const CHANGE_INVITE_MODAL = 'hh/pages/partner/clients/CHANGE_INVITE_MODAL';
export const CHANGE_INVITE_FIELD = 'hh/pages/partner/clients/CHANGE_INVITE_FIELD';
export const SEND_INVITE = 'hh/pages/partner/clients/SEND_INVITE';
export const SEND_INVITE_SUCCESS = 'hh/pages/partner/clients/SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAIL = 'hh/pages/partner/clients/SEND_INVITE_FAIL';
export const CHANGE_FILTER_FIELD = 'hh/pages/partner/clients/CHANGE_FILTER_FIELD';
export const CHANGE_IS_MINE_FILTER = 'hh/pages/partner/clients/CHANGE_IS_MINE_FILTER';
export const CHANGE_DATE_FILTER = 'hh/pages/partner/clients/CHANGE_DATE_FILTER';
