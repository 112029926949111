import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import ClientFinancesBase from './components/ClientFinancesBase';

const ClientFinancesView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <ClientFinancesBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default ClientFinancesView;
