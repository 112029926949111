import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import Image from './image.png';
import Image2x from './image-2x.png';

const Container = styled.div`
  align-items: center;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  margin-top: 16px;
`;

class NotFoundPage extends React.Component {
  render() {
    return (
      <Page>
        <Helmet>
          <title>Страница не найдена</title>
        </Helmet>

        <Grid layout="fixed">
          <GridColumn medium={12}>
            <Container>
              <h3>Страница не найдена</h3>

              <ImageContainer>
                <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
              </ImageContainer>
            </Container>
          </GridColumn>
        </Grid>
      </Page>
    );
  }
}

export default NotFoundPage;
