export const GET_USER = 'hh/pages/partner/GET_USER';
export const GET_USER_SUCCESS = 'hh/pages/partner/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'hh/pages/partner/GET_USER_FAIL';
export const CHANGE_USER_FIELD = 'hh/pages/partner/CHANGE_USER_FIELD';
export const CHANGE_USER_PASSWORD_FIELD = 'hh/pages/partner/CHANGE_USER_PASSWORD_FIELD';
export const CHANGE_FLAGS = 'hh/pages/partner/CHANGE_FLAGS';

export const UPDATE_USER_COMMON = 'hh/pages/partner/UPDATE_USER_COMMON';
export const UPDATE_USER_COMMON_SUCCESS = 'hh/pages/partner/UPDATE_USER_COMMON_SUCCESS';
export const UPDATE_USER_COMMON_FAIL = 'hh/pages/partner/UPDATE_USER_COMMON_FAIL';

export const UPDATE_USER_EMAIL = 'hh/pages/partner/UPDATE_USER_EMAIL';
export const UPDATE_USER_EMAIL_SUCCESS = 'hh/pages/partner/UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_FAIL = 'hh/pages/partner/UPDATE_USER_EMAIL_FAIL';

export const UPDATE_USER_PASSWORD = 'hh/pages/partner/UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'hh/pages/partner/UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'hh/pages/partner/UPDATE_USER_PASSWORD_FAIL';
