import styled from 'styled-components';

const InfoWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  position: relative;
`;

export default InfoWrapper;
