import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@atlaskit/field-base';
import { DatePicker } from '@atlaskit/datetime-picker';
import moment from 'moment';

import Wrapper from './styled/Wrapper';

class DateField extends React.Component {
  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  setInputRef = input => {
    this.input = input;
    this.props.innerRef(input);
  };

  formatDisplayLabel = (value, dateFormat) => {
    moment.locale('ru');
    return moment(value).format(dateFormat);
  };

  render() {
    return (
      <Wrapper>
        <Label
          htmlFor={this.props.id}
          isDisabled={this.props.disabled}
          isLabelHidden={this.props.isLabelHidden}
          isRequired={this.props.required}
          label={this.props.label || ''}
        />
        <DatePicker
          className="date-picker"
          defaultOptions={this.props.defaultOptions}
          options={this.props.options}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value}
          // locale='ru-RU'
          dateFormat="DD.MM.YYYY"
          formatDisplayLabel={this.formatDisplayLabel}
          parseInputValue={(val) => moment(val, "DD.MM.YYYY").toDate()}
        />
      </Wrapper>
    );
  }
}

DateField.defaultProps = {
  compact: false,
  disabled: false,
  isInvalid: false,
  isReadOnly: false,
  isSpellCheckEnabled: true,
  maskChar: '_',
  onChange: () => {},
  required: false,
  type: 'text',
  isValidationHidden: false,
  innerRef: () => {}
};

DateField.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  form: PropTypes.string,
  pattern: PropTypes.string,
  compact: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  id: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  invalidMessage: PropTypes.node,
  shouldFitContainer: PropTypes.bool,
  isSpellCheckEnabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  isValidationHidden: PropTypes.bool
};

export default DateField;
