import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PartnerInformationBase from './components/PartnerInformationBase';

const PartnerInformationView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <PartnerInformationBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default PartnerInformationView;
