import React from 'react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import RouterLink from 'components/RouterLink';

const PartnerGlobalDropdown = () => (
  <DropdownItemGroup isOpen>
    <DropdownItem href="/partner/profile" linkComponent={RouterLink}>
      Мой профиль
    </DropdownItem>
    <DropdownItem href="/partner/logout" linkComponent={RouterLink}>
      Выйти
    </DropdownItem>
  </DropdownItemGroup>
);

export default PartnerGlobalDropdown;
