import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import apiUrl from 'utils/serverConfig';

class VkAuth extends PureComponent {
  constructor(props) {
    super(props);

    const {
      location: { search }
    } = props;

    const code = search.substring(6, search.indexOf('&') > 0 ? search.indexOf('&') : undefined);
    const invite = search.indexOf('&') > 0 ? search.substring(search.indexOf('&') + 7) : '';

    (async () => {
      const options = {
        method: 'post',
        data: {
          code
        }
      };

      try {
        const request = await axios(`${apiUrl}/api/staff/auth/vk`, options);
        localStorage.setItem('staffKey', request.data.key);
        if (!isEmpty(invite)) {
          props.history.push(`/invite/s/${invite}`);
        } else {
          props.history.push('/invite/s');
        }
      } catch (e) {
        props.history.push({
          pathname: '/individual/login',
          state: { showError: true }
        });
      }
    })();
  }

  render() {
    return <div />;
  }
}

export default withRouter(VkAuth);
