import {
  CHANGE_LOGIN_FIELD,
  CHANGE_FLAGS,
  SEND_LOGIN,
  SEND_LOGIN_SUCCESS,
  SEND_LOGIN_FAIL,
  CHANGE_REGISTRATION_FIELD,
  SEND_REGISTRATION,
  SEND_REGISTRATION_SUCCESS,
  SEND_REGISTRATION_FAIL,
  SEND_PASSCODE,
  SEND_PASSCODE_SUCCESS,
  SEND_PASSCODE_FAIL,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  CHANGE_RESET_FIELD,
  CREATE_PASSWORD_RESET,
  CREATE_PASSWORD_RESET_SUCCESS,
  CREATE_PASSWORD_RESET_FAIL,
  CONFIRM_PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET_SUCCESS,
  CONFIRM_PASSWORD_RESET_FAIL
} from './constants';

export function changeLoginField(payload) {
  return {
    type: CHANGE_LOGIN_FIELD,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function sendLogin() {
  return {
    type: SEND_LOGIN
  };
}

export function sendLoginSuccess() {
  return {
    type: SEND_LOGIN_SUCCESS
  };
}

export function sendLoginFail(payload) {
  return {
    type: SEND_LOGIN_FAIL,
    ...payload
  };
}

export function changeRegistrationField(payload) {
  return {
    type: CHANGE_REGISTRATION_FIELD,
    ...payload
  };
}

export function sendRegistration(payload) {
  return {
    type: SEND_REGISTRATION,
    ...payload
  };
}

export function sendRegistrationSuccess() {
  return {
    type: SEND_REGISTRATION_SUCCESS
  };
}

export function sendRegistrationFail(payload) {
  return {
    type: SEND_REGISTRATION_FAIL,
    ...payload
  };
}

export function sendPasscode() {
  return {
    type: SEND_PASSCODE
  };
}

export function sendPasscodeSuccess() {
  return {
    type: SEND_PASSCODE_SUCCESS
  };
}

export function sendPasscodeFail() {
  return {
    type: SEND_PASSCODE_FAIL
  };
}

export function sendOtp(payload) {
  return {
    type: SEND_OTP,
    ...payload
  };
}

export function sendOtpSuccess() {
  return {
    type: SEND_OTP_SUCCESS
  };
}

export function sendOtpFail() {
  return {
    type: SEND_OTP_FAIL
  };
}

export function changeResetField(payload) {
  return {
    type: CHANGE_RESET_FIELD,
    ...payload
  };
}

export function createPasswordReset() {
  return {
    type: CREATE_PASSWORD_RESET
  };
}

export function createPasswordResetSuccess() {
  return {
    type: CREATE_PASSWORD_RESET_SUCCESS
  };
}

export function createPasswordResetFail() {
  return {
    type: CREATE_PASSWORD_RESET_FAIL
  };
}

export function confirmPasswordReset(payload) {
  return {
    type: CONFIRM_PASSWORD_RESET,
    ...payload
  };
}

export function confirmPasswordResetSuccess(payload) {
  return {
    type: CONFIRM_PASSWORD_RESET_SUCCESS,
    ...payload
  };
}

export function confirmPasswordResetFail(payload) {
  return {
    type: CONFIRM_PASSWORD_RESET_FAIL,
    ...payload
  };
}
