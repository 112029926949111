/* eslint-disable no-useless-escape */
import isString from 'lodash/isString';
import moment from "moment";

export const isRequired = value => {
  return isString(value) && value.length > 0;
};

export const isPhoneInProfile = value => {
  const re = /\+7[0-9]{10}/;
  return isString(value) && value.length > 0 && re.test(String(value));
};

export const isPhone = value => {
  const re = /\+7\([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/;
  return isString(value) && value.length > 0 && re.test(String(value));
};

export const isPhoneInIntFormat = value => {
  return isString(value) && value.length > 1 && String(value).startsWith("+");
};

export const isEmail = value => {
  const re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  return isString(value) && value.length > 0 && re.test(String(value).toLowerCase());
};

export const isDate = value => moment(value, "DD.MM.YYYY").isValid()

export const isInn = value => {
  let result = false;
  if (typeof value === 'number') {
    value = value.toString();
  } else if (typeof value !== 'string') {
    value = '';
  }

  if (!value.length) {
    result = false;
  } else if (/[^0-9]/.test(value)) {
    result = false;
  } else if ([10, 12].indexOf(value.length) === -1) {
    result = false;
  } else {
    const checkDigit = function(value, coefficients) {
      let n = 0;
      for (const i in coefficients) {
        n += coefficients[i] * value[i];
      }

      return parseInt((n % 11) % 10);
    };

    switch (value.length) {
      case 10:
        const n10 = checkDigit(value, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(value[9])) {
          result = true;
        }
        break;
      case 12:
        const n11 = checkDigit(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        const n12 = checkDigit(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(value[10]) && n12 === parseInt(value[11])) {
          result = true;
        }
        break;
      default:
        result = false;
        break;
    }
  }

  return result;
};
