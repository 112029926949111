import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import apiUrl from 'utils/serverConfig';
import { formatPhone } from 'utils/formatPhone';

import { REGISTER_COMPANY_REQUEST, SEND_SMS_CODE_REQUEST } from './constants';

import {
  registerCompanySuccess,
  registerCompanyFail,
  sendSmsCodeSuccess,
  sendSmsCodeFail
} from './actions';

import {
  makeSelectName,
  makeSelectSurname,
  makeSelectEmail,
  makeSelectPhone,
  makeSelectPasswordOne,
  makeSelectCompany,
  makeSelectInn,
  makeSelectSmsCode
} from './selectors';

function* registerCompanySaga({ referral }) {
  const url = `${apiUrl}/api/managers/auth/registration/company`;
  const first_name = yield select(makeSelectName());
  const last_name = yield select(makeSelectSurname());
  const email = yield select(makeSelectEmail());
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const company_name = yield select(makeSelectCompany());
  const company_inn = yield select(makeSelectInn());
  const password1 = yield select(makeSelectPasswordOne());
  const password2 = password1;
  const passcode = yield select(makeSelectSmsCode());
  const data = {
    first_name,
    last_name,
    email,
    phone_number,
    company_name,
    company_inn,
    password1,
    password2,
    passcode
  };

  if (!isUndefined(referral)) {
    data.referral_code = referral;
  }

  const options = {
    method: 'post',
    data
  };

  try {
    const request = yield call(axios, url, options);

    const key = request.data.key;
    localStorage.setItem('key', key);

    yield put(registerCompanySuccess());
    yield put(push('/invites'));
  } catch (e) {
    const errorMessages = e.response.data;
    yield put(
      registerCompanyFail({
        company_inn: isUndefined(errorMessages.company_inn)
          ? ''
          : errorMessages.company_inn.join(' '),
        email: isUndefined(errorMessages.email) ? '' : errorMessages.email.join(' '),
        password1: isUndefined(errorMessages.password1) ? '' : errorMessages.password1.join(' '),
        phone_number: isUndefined(errorMessages.phone_number)
          ? ''
          : errorMessages.phone_number.join(' ')
      })
    );
  }
}

function* sendSmsCodeSaga() {
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const url = `${apiUrl}/api/managers/auth/login/phone_number`;
  const options = {
    method: 'post',
    data: {
      phone_number
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendSmsCodeSuccess());
  } catch (e) {
    yield put(sendSmsCodeFail());
  }
}

export default function* registrationCompanyPageSaga() {
  yield takeLeading(REGISTER_COMPANY_REQUEST, registerCompanySaga);
  yield takeLeading(SEND_SMS_CODE_REQUEST, sendSmsCodeSaga);
}
