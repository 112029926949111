import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Tabs from '@atlaskit/tabs';
import Spinner from 'components/Spinner';

import Container from './styled/Container';
import EmbedVideo from './styled/EmbedVideo';
import PageDesc from './styled/PageDesc';
import TabContent from './styled/TabContent';
import TabTitle from './styled/TabTitle';
import Text from './styled/Text';

class Video extends Component {
  renderTabContent = category =>
    category.videos.map(video => (
      <TabContent key={video.id}>
        <TabTitle>{video.name}</TabTitle>
        <Text>{video.description}</Text>
        <EmbedVideo dangerouslySetInnerHTML={{ __html: video.url }} />
      </TabContent>
    ));

  render() {
    const sectionId = parseInt(this.props.match.params.sectionId, 10);
    const { loading, sections } = this.props;

    let content = <Spinner />;

    if (!loading) {
      const currentSection = sections.find(section => section.id === sectionId);

      const tabs = currentSection.categories.map(category => ({
        label: category.name,
        content: this.renderTabContent(category)
      }));

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader>
              Обучающие видео
              <PageDesc>{currentSection.description}</PageDesc>
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Tabs tabs={tabs} onSelect={(tab, index) => console.log('Selected Tab', index + 1)} />
          </GridColumn>
        </Grid>
      );
    }

    return <Container>{content}</Container>;
  }
}

const mapStateToProps = store => ({
  loading: store.getIn(['educations', 'loading']),
  sections: store.getIn(['educations', 'sections']).toJS()
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect
)(Video);
