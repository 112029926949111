import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const A = styled.a`
  & rect {
    transition: fill 0.1s ease-out;
  }

  &:hover {
    & rect {
      fill: ${darken(0.1, '#4267B2')};
    }
  }
`;

const FacebookAuth = ({ link }) => (
  <A href={link}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-704.000000, -451.000000)">
          <g transform="translate(704.000000, 451.000000)">
            <rect fill="#4267B2" x="0" y="0" width="32" height="32" rx="2" />
            <path
              d="M19.5781253,15.68 L17.3333333,15.68 L17.3333333,24 L14,24 L14,15.68 L12,15.68 L12,13.12 L14,13.12 L14,11.5775002 C14.002604,9.33249984 14.9739587,8 17.7291667,8 L20,8 L20,10.56 L18.4765627,10.56 C17.403646,10.56 17.3333333,10.9450003 17.3333333,11.6624998 L17.3333333,13.12 L20,13.12 L19.5781253,15.68 Z"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  </A>
);

export default FacebookAuth;
