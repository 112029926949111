import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import uuid from 'uuid/v1';
import moment from "moment";
import { fromJS } from 'immutable';

import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import Select from '@atlaskit/select';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Empty from 'components/Empty';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import {
  changeColumnPosition,
  changeReportSelector,
  closeAddModal,
  deleteReportFromCompare,
  getCompareList,
  openAddModal
} from './actions';

import reducer from './reducer';
import saga from './saga';

import Field from '../../CompareList/styled/Field';
import FieldGroup from '../../CompareList/styled/FieldGroup';
import Line from '../../CompareList/styled/Line';
import LineBody from '../../CompareList/styled/LineBody';
import LineItem from '../../CompareList/styled/LineItem';
import StaffName from '../../CompareList/components/StaffName';
import Table from '../../CompareList/styled/Table';
import TableBody from '../../CompareList/styled/TableBody';
import TableHeader from '../../CompareList/styled/TableHeader';
import TableLine from '../../CompareList/styled/TableLine';

class CompareReports extends Component {
  constructor(props) {
    super(props);

    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
    document.addEventListener('mousedown', this.onMousedown);
    document.addEventListener('scroll', this.onScroll);

    this.clicked = false;
    this.clickX = 0;
    this.clickY = 0;
    this.tableRef = React.createRef();
    this.tableHeaderRef = React.createRef();
    this.props.getCompareList();

    this.state = {
      selectedOptions: []
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMousemove);
    document.removeEventListener('mouseup', this.onMouseup);
    document.removeEventListener('mousedown', this.onMousedown);
  }

  onMouseup = () => {
    this.clicked = false;
  };

  onMousedown = event => {
    this.clicked = true;
    this.clickX = event.pageX;
    this.clickY = event.pageY;
  };

  onMousemove = event => {
    if (this.clicked) {
      const table = this.tableRef;
      if(table && table.current !== null) {
        const top = window.scrollY + (this.clickY - event.pageY);
        const newScrollX = table.scrollLeft - (-this.clickX + (this.clickX = event.clientX));

        table.scrollLeft = newScrollX;
        window.scrollTo(0, top);
      }
    }
  };

  onScroll = () => {
    if(this.tableHeaderRef.current) {
      if (window.scrollY > 103) {
        this.tableHeaderRef.current.style = `top: ${window.scrollY - 104}px`;
      } else {
        this.tableHeaderRef.current.style = `top: 0`;
      }
    }
  };

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeReportSelector = result => {
    let newSelectedOptions = [...this.state.selectedOptions];
    result.forEach(res => {
      if(!newSelectedOptions.includes(res.value)) newSelectedOptions.push(res.value)
    })
    this.setState({ selectedOptions: newSelectedOptions })

  };

  onAddCompare = () => {
    const { compareResult } = this.props;
    const { selectedOptions } = this.state;
    const sortedByDate = [...selectedOptions].sort((n1, n2) => {
      const moment1 = moment(compareResult.toJS().find(c => c.id === n1).created_at);
      const moment2 = moment(compareResult.toJS().find(c => c.id === n2).created_at);

      if(moment1.isBefore(moment2)) {
        return 1
      }

      if(moment2.isBefore(moment1)) {
        return -1
      }

      return 0
    })
    this.props.changeReportSelector({
      staffSelector: sortedByDate
    });

    this.closeModal();
  };

  closeModal = () => {
    const { closeAddModal } = this.props;
    this.setState({ selectedOptions: [] });
    closeAddModal();
  };

  deleteReportFromCompare = id => () => this.props.deleteReportFromCompare({ id: id });

  setTableRef = ref => {
    this.tableRef = ref;
  };

  renderHeaderButtons = () => {
    const { openAddModal } = this.props;

    return (
      <ButtonGroup>
        <Button appearance="primary" onClick={openAddModal}>
          Добавить к сравнению
        </Button>
      </ButtonGroup>
    );
  };

  changePosition = (payload) => {
    const { changeColumnPosition } = this.props;
    changeColumnPosition(payload);
  }

  renderTable = () => {
    const { resultNames, resultTable } = this.props;

    return (
      <Table innerRef={ref => this.setTableRef(ref)}>
        <TableHeader innerRef={this.tableHeaderRef}>
          {resultNames.map((staff, index) => (
            <StaffName
              key={index}
              name={staff.fullName}
              onClose={this.deleteReportFromCompare(staff.id)}
              activeLeft={index > 0}
              activeRight={index < resultNames.length - 1}
              onLeftArrowClick={() => this.changePosition({ id: staff.id, diff: -1 })}
              onRightArrowClick={() => this.changePosition({ id: staff.id, diff: 1 })}
            />
          ))}
        </TableHeader>
        <TableBody>
          {resultTable.map(section => {
            return(
              <TableLine key={section.id}>
                <Line titleLine>{section.title}</Line>
                {section.subsections.map(subsection => (
                  <LineBody key={subsection.id}>
                    <Line subtitleLine>{subsection.title}</Line>
                    <Line>
                      {subsection.values.map((subsectionVal, index, array) => {
                        let good;
                        let bad;
                        if(array.length > 1) {
                          if(index === 0 && !isNaN(+subsectionVal) && !isNaN(+array[1].value)) {
                            if(subsectionVal.value > array[1].value + 5) {
                              good = true
                            } else if(subsectionVal.value + 5 < array[1].value) {
                              bad = true
                            }
                          } else if(index === 1 && !isNaN(+subsectionVal) && !isNaN(+array[0].value)) {
                            if(subsectionVal.value > array[0].value + 5) {
                              bad = true
                            } else if(subsectionVal.value + 5 < array[0].value) {
                              good = true
                            }
                          }
                        }

                        return(
                          <LineItem
                            key={subsectionVal.id}
                            good={good}
                            bad={bad}
                          >
                            {subsectionVal.value}{!isNaN(+subsectionVal.value) ? "%" : ""}
                          </LineItem>
                        )
                      })}
                    </Line>
                  </LineBody>
                ))}
              </TableLine>
            )
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    const {
      addModals,
      compareLength,
      compareIds,
      compareResult,
      loading,
      openAddModal
    } = this.props;

    const sortedCompareResult = compareResult.sort((n1, n2) => {
      const moment1 = moment(n1.get('created_at'));
      const moment2 = moment(n2.get('created_at'));

      if(moment1.isBefore(moment2)) {
        return 1
      }

      if(moment2.isBefore(moment1)) {
        return -1
      }

      return 0
    });
    let content = <Spinner />;

    if (!loading && compareLength > 0) {
      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.renderHeaderButtons()} >
              Сравнение отчетов
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{this.renderTable()}</GridColumn>
        </Grid>
      );
    }

    if (!loading && compareLength === 0) {
      content = (
        <Empty
          header="Список сравнения пуст"
          text="Добавьте в сравнение любой из своих отчетов за все время и сравните, как изменились ваши показатели."
        >
          <Button appearance="primary" onClick={() => openAddModal()}>
            Добавить к сравнению
          </Button>
        </Empty>
      );
    }

    return (
      <Page>
        <Helmet>Сравнение отчетов</Helmet>

        {content}
        {addModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Добавить к сравнению"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Добавить',
                onClick: this.onAddCompare
              },
              {
                text: 'Отмена',
                onClick: this.closeModal
              }
            ]}
          >
            <p>
              Выберите один или несколько отчетов, которые вы хотите добавить к сравнению.
            </p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup marginTop="8px">
                <Field width="70%">
                  <Select
                    className="multi-select"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isLoading={loading}
                    isMulti
                    label="Профиль"
                    loadingMessage={() => 'Загрузка'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.onChangeReportSelector}
                    options={sortedCompareResult
                      .filter(elem => !compareIds.includes(elem.get('id')))
                      .map(elem => {
                      return({
                        label: `Отчёт от ${moment(new Date(elem.get('created_at'))).format("DD.MM.YYYY hh:mm")}`,
                        value: elem.get('id')
                      })
                    })}
                    placeholder="Начните печатать"
                    shouldFitContainer
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}
      </Page>
    );
  }
}

CompareReports.propTypes = {
  addModals: PropTypes.array.isRequired,
  changeReportSelector: PropTypes.func.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  compareIds: PropTypes.array.isRequired,
  compareLength: PropTypes.number.isRequired,
  compareResult: PropTypes.object.isRequired,
  deleteReportFromCompare: PropTypes.func.isRequired,
  changeColumnPosition: PropTypes.func.isRequired,
  getCompareList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openAddModal: PropTypes.func.isRequired,
  resultNames: PropTypes.array.isRequired,
  resultTable: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    changeReportSelector: value => dispatch(changeReportSelector(value)),
    closeAddModal: () => dispatch(closeAddModal()),
    deleteReportFromCompare: id => dispatch(deleteReportFromCompare(id)),
    getCompareList: compareIds => dispatch(getCompareList(compareIds)),
    openAddModal: () => dispatch(openAddModal()),
    changeColumnPosition: payload => dispatch(changeColumnPosition(payload))
  };
}

const mapStateToProps = store => ({
  compareResult: store.getIn(['compareList', 'compareResult']),
  resultTable: (() => {
    const compareResult = store.getIn(['compareList', 'compareResult']).toJS();
    const compareIds = store.getIn(['compareList', 'compareIds']).toJS();
    const sections = store.getIn(['compareList', 'sections']);

    const result = sections.map(section => ({
      id: section.get('id'),
      title: section.get('title'),
      subsections: section
        .get('subsections')
        .map(subsection => ({
          id: subsection.get('id'),
          title: subsection.get('title'),
          values: (() => {
            const result = [];

            let newCompRes = fromJS(compareIds.map(id => compareResult.find(res => res.id === id)));
            newCompRes.valueSeq().forEach(report => {
              result.push({
                id: uuid(),
                value: (() => {
                  const foundSection = report
                    .get('sections')
                    .find(compareSection => compareSection.get('title') === section.get('title'))

                  if(!foundSection) {
                    return "-"
                  }
                  return foundSection
                    .get('subsections')
                    .find(
                      compareSubsection =>
                        compareSubsection.get('title') === subsection.get('title')
                    )
                    .get('value')
                })()
              });
            });

            return result;
          })()
        }))
        .toJS()
    }));

    return result.toJS();
  })(),
  resultNames: (() => {
    const compareResult = store.getIn(['compareList', 'compareResult']).toJS();
    const compareIds = store.getIn(['compareList', 'compareIds']).toJS();
    const names = [];

    let newCompRes = fromJS(compareIds.map(id => compareResult.find(res => res.id === id)));
    newCompRes.valueSeq().forEach(compare => {
      names.push({
        id: compare.get('id'),
        fullName: moment(new Date(compare.get('created_at'))).format("DD.MM.YYYY hh:mm")
      });
    });

    return names;
  })(),
  compareLength: store.getIn(['compareList', 'compareIds']).size,
  loading: store.getIn(['compareList', 'loading']),
  addModals: store.getIn(['compareList', 'addModal']).toJS(),
  compareIds: store.getIn(['compareList', 'compareIds']).toJS(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'compareList', reducer });
const withSaga = injectSaga({ key: 'compareList', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(CompareReports);
