import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PersonViewBase from './components/PersonViewBase';

const PersonView = () => (
  <UIControllerSubscriber>
    {navigationUIController => <PersonViewBase navigationUIController={navigationUIController} />}
  </UIControllerSubscriber>
);

export default PersonView;
