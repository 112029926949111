import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';
import VideoPage from 'pages/PartnerProfile/Video';

const PartnerVideosView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/videos" />
    <VideoPage />
  </Fragment>
);

export default PartnerVideosView;
