import styled from 'styled-components';
import posed from 'react-pose';

const wrapperPose = {
  enter: {
    y: '0%',
    opacity: 1
  },
  exit: {
    opacity: 0,
    y: '10%'
  }
};

const Tooltip = styled(posed.div(wrapperPose))`
  align-items: center;
  background-color: #172b4e;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  height: 19px;
  left: -100px;
  letter-spacing: -0.2px;
  position: absolute;
  text-align: left;
  width: 282px;
  justify-content: center;
  bottom: 25px;
`;

export default Tooltip;
