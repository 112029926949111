import { all, call, select, put, takeLeading } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import uniqid from 'uniqid';
import { apiRequest } from 'utils/request';
import { isEmail } from 'utils/validators';
import { formatPhone } from 'utils/formatPhone';

import {
  STAFF_LIST_REQUEST,
  STAFF_LIST_FILTER_REQUEST,
  STAFF_READ_REQUEST,
  STAFF_UPDATE_REQUEST,
  STAFF_REPORT_REQUEST,
  STAFF_COMPARE_REQUEST,
  STAFF_DELETE_REQUEST,
  CHANGE_COMPARE_PROFILE_INPUT,
  PROFILE_LIST_REQUEST,
  CREATE_COMPARE_REQUEST,
  DELETE_COMPARE_REQUEST,
  GET_COMPARE_REQUEST,
  SEND_INVITE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  CHANGE_MANAGERS_FILTER,
  CHANGE_NAME_FILTER,
  CHANGE_STAFF_PAGE,
  CHANGE_STAFF_SORT,
  CHANGE_STAFF_COMPARE_FILTER,
  CHANGE_STAFF_COMPARE_PAGE,
  CHANGE_STAFF_COMPARE_SORT,
  SEND_REPORTS_EMAIL_REQUEST,
  SEND_COMPARE_INFO_REQUEST,
  ADD_REPORT_TO_COMPARE,
  DELETE_REPORT_FROM_COMPARE,
  SEND_INVITES_BY_FILE,
  CHANGE_INVITE_TEXT,
  SEND_REPORT_READ_REQUEST
} from './constants';

import {
  loadingFinish,
  loadingTableFinish,
  staffList,
  staffFilteredList,
  staffListSuccess,
  staffListFail,
  staffReadSuccess,
  staffReadFail,
  staffUpdateSuccess,
  staffUpdateFail,
  staffReportSuccess,
  staffReportFail,
  staffCompare,
  staffCompareSuccess,
  staffCompareFail,
  staffDeleteSuccess,
  staffDeleteFail,
  profileListSuccess,
  profileListFail,
  createCompareSuccess,
  createCompareFail,
  deleteCompareSuccess,
  deleteCompareFail,
  compareReadSuccess,
  compareReadFail,
  sendInviteSuccess,
  sendInviteFail,
  getUserInfo,
  updateUserSuccess,
  updateUserFail,
  updatePasswordSuccess,
  updatePasswordFail,
  sendReportsEmailSuccess,
  sendReportsEmailFail,
  sendCompareInfoSuccess,
  sendCompareInfoFail,
  sendInvitesByFileSuccess,
  sendInvitesByFileFail, decreaseUnreadReportsCount
} from './actions';

import {
  makeSelectCompareProfiles,
  makeSelectCompareId,
  makeSelectInvite,
  makeSelectManagerFilter,
  makeSelectStaffId,
  makeSelectUser,
  makeSelectNameFilter,
  makeSelectStaffPage,
  makeSelectSort,
  makeSelectCompareFilter,
  makeSelectComparePage,
  makeSelectCompareSort,
  makeSelectStaffRead,
  makeSelectProfileInput,
  makeSelectReportEmail,
  makeSelectReportIdByType,
  makeSelectCompareInfo,
  makeSelectCompareReportsId,
  makeSelectReportTypeForEmail,
  makeSelectStaffList
} from './selectors';

function* staffListSaga() {
  const params = {};

  params.ordering = '-testing_date'

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      staffListSuccess({
        staffCount: request.data.count,
        staffList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(staffListFail());
  } finally {
    yield put(loadingFinish());
  }
}

function* staffFilteredListSaga() {
  const managerFilter = yield select(makeSelectManagerFilter());
  const nameFilter = yield select(makeSelectNameFilter());
  const page = yield select(makeSelectStaffPage());
  const sort = yield select(makeSelectSort());
  const offset = page * 25 - 25;

  const params = {};

  if (managerFilter !== 0) {
    params.manager = managerFilter;
  }

  if (nameFilter !== '') {
    params.full_name = nameFilter;
  }

  if (page > 1) {
    params.offset = offset;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      staffListSuccess({
        staffCount: request.data.count,
        staffList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(staffListFail());
  } finally {
    yield put(loadingTableFinish());
  }
}

function* getFilteredListSaga() {
  yield put(staffFilteredList());
}

function* staffReadSaga() {
  const personId = yield select(makeSelectStaffId());
  const url = `api/managers/staff/${personId}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      staffReadSuccess({
        ...request.data
      })
    );
  } catch (e) {
    yield put(staffReadFail());
  }
}

function* staffUpdateSaga() {
  const person = yield select(makeSelectStaffRead());
  const url = `api/managers/staff/${person.id}/`;
  const options = {
    method: 'put',
    data: {
      comment: person.comment
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(staffUpdateSuccess());
  } catch (e) {
    yield put(staffUpdateFail());
  }
}

function* staffReportSaga() {
  const personId = yield select(makeSelectStaffId());
  const url = `api/managers/staff/${personId}/reports/testing_result/`;

  try {
    const request = yield call(apiRequest, url);
    const reports = request.data.map(report =>
      report.sections.map(section => ({
        id: uniqid(),
        subsections: section.subsections.map(subsection => ({
          id: uniqid(),
          ...subsection
        })),
        info: section.info,
        meta_program: section.meta_program,
        rank: section.rank,
        title: section.title
      }))
    );
    [reports[0], reports[1], reports[2]] = [reports[1], reports[2], reports[0]];

    const reportIds = request.data.map(report => report.id);

    if (reports.length > 0) {
      const staffReport = fromJS({
        createdAt: request.data[0].created_at,
        reports: reports
      });

      yield put(
        staffReportSuccess({
          reportId: request.data.id,
          reportIds,
          staffReport
        })
      );
    } else {
      yield put(
        staffReportFail({
          errorCode: 404
        })
      );
    }
  } catch (e) {
    yield put(
      staffReportFail({
        errorCode: e.response.status
      })
    );
  }
}

function* staffCompareSaga() {
  const personId = yield select(makeSelectStaffId());
  const nameFilter = yield select(makeSelectCompareFilter());
  const page = yield select(makeSelectComparePage());
  const sort = yield select(makeSelectCompareSort());
  const offset = page * 25 - 25;

  const params = {};

  if (nameFilter !== '') {
    params.profile_name = nameFilter;
  }

  if (page > 1) {
    params.offset = offset;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/staff/${personId}/reports/profile_conformance/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);
    const data = fromJS(request.data.results);

    yield put(
      staffCompareSuccess({
        staffCompareCount: request.data.count,
        staffCompare: data
      })
    );
  } catch (e) {
    yield put(
      staffCompareFail({
        errorCode: e.response.status
      })
    );
  } finally {
    yield put(loadingTableFinish());
  }
}

function* staffDeleteSaga() {
  const personId = yield select(makeSelectStaffId());
  const url = `api/managers/staff/${personId}/`;
  const options = {
    method: 'delete'
  };

  const compareReportsId = localStorage.getItem('compareIds')
    ? JSON.parse(localStorage.getItem('compareIds'))
    : [];
  const personIdIndex = compareReportsId.indexOf(personId);

  if (personIdIndex !== -1) {
    compareReportsId.splice(personIdIndex, 1);
    localStorage.setItem('compareIds', JSON.stringify(compareReportsId));
  }

  try {
    yield call(apiRequest, url, options);

    yield put(staffDeleteSuccess());
    yield put(staffList());
  } catch (e) {
    yield put(staffDeleteFail());
  }
}

function* profileListSaga() {
  const nameFilter = yield select(makeSelectProfileInput());

  const params = {
    ordering: 'name',
    limit: 25
  };
  if (nameFilter !== '') {
    params.name = nameFilter;
    params.limit = 25;
  }

  const url = `api/managers/profiles/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    const profileList = fromJS(
      request.data.results.map(profile => ({
        label: profile.name,
        value: profile.id
      }))
    );

    yield put(
      profileListSuccess({
        profileList
      })
    );
  } catch (e) {
    yield put(profileListFail());
  }
}

function* createCompareSaga() {
  const url = 'api/managers/profile_conformance_reports/';
  const staffId = yield select(makeSelectStaffId());
  const profilesId = yield select(makeSelectCompareProfiles());

  const data = profilesId
    .map(profile => ({
      staff: staffId,
      profile: profile
    }))
    .toJS();
  const parallelActions = [];

  let i = 0;
  while (i < data.length) {
    const options = {
      method: 'post',
      data: data[i]
    };

    parallelActions[i] = call(apiRequest, url, options);
    i++;
  }

  try {
    yield all(parallelActions);

    yield put(createCompareSuccess());
  } catch (e) {
    yield put(createCompareFail());
  } finally {
    yield put(
      staffCompare({
        staffId
      })
    );
  }
}

function* deleteCompareSaga() {
  const staffId = yield select(makeSelectStaffId());
  const compareId = yield select(makeSelectCompareId());
  const url = `api/managers/profile_conformance_reports/${compareId}/`;
  const options = {
    method: 'delete'
  };

  try {
    yield call(apiRequest, url, options);
    yield put(deleteCompareSuccess());
    yield put(
      staffCompare({
        staffId
      })
    );
  } catch (e) {
    yield put(deleteCompareFail());
  }
}

function* compareReadSaga() {
  const compareId = yield select(makeSelectCompareId());
  const url = `api/managers/profile_conformance_reports/${compareId}/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      compareReadSuccess({
        compareRead: fromJS(request.data)
      })
    );
  } catch (e) {
    yield put(compareReadFail());
  }
}

function* sendInviteUserSaga() {
  const invite = yield select(makeSelectInvite());
  const url = 'api/managers/invitations/';
  const options = {
    method: 'post',
    data: {
      ...invite,
      full_name: `${invite.name} ${invite.full_name}`
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendInviteSuccess());
  } catch (e) {
    yield put(sendInviteFail());
  }
}

function* updateUserSaga() {
  const url = 'api/managers/auth/user/';
  const user = yield select(makeSelectUser());

  const options = {
    method: 'put',
    data: {
      company_name: user.get('company_name'),
      email: isEmail(user.get('new_email')) ? user.get('new_email') : user.get('email'),
      first_name: user.get('first_name'),
      last_name: user.get('last_name'),
      phone_number: formatPhone(user.get('phone_number'))
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(updateUserSuccess());
  } catch (e) {
    yield put(updateUserFail());
  } finally {
    yield put(getUserInfo());
    window.location.reload(false);
  }
}

function* updatePasswordSaga() {
  const url = 'api/managers/auth/password/change/';
  const user = yield select(makeSelectUser());
  const options = {
    method: 'post',
    data: {
      new_password1: user.get('password_one'),
      new_password2: user.get('password_two')
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(updatePasswordSuccess());
  } catch (e) {
    yield put(updatePasswordFail());
  }
}

function* sendReportsEmailSaga() {
  const reportId = yield select(makeSelectReportIdByType());
  const reportType = yield select(makeSelectReportTypeForEmail());
  const email = yield select(makeSelectReportEmail());

  const url = `api/managers/testing_result_reports/${reportId}/send_email/`;

  const options = {
    method: 'post',
    data: {
      type: reportType,
      to_email: email
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendReportsEmailSuccess());
  } catch (e) {
    yield put(sendReportsEmailFail());
  }
}

function* sendCompareInfoSaga() {
  const compare = yield select(makeSelectCompareInfo());
  const url = `api/managers/profile_conformance_reports/${compare.compareId}/send_email/`;

  const options = {
    method: 'post',
    data: {
      to_email: compare.email
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendCompareInfoSuccess());
  } catch (e) {
    yield put(sendCompareInfoFail());
  }
}

function* changeReportInLocalStageSaga() {
  const compareIds = yield select(makeSelectCompareReportsId());

  localStorage.setItem('compareIds', JSON.stringify(compareIds.toArray()));
}

function* sendInvitesByFileSaga(payload) {
  const invite = yield select(makeSelectInvite());
  const url = 'api/managers/invitations/bulk_create_staff/';

  const formData = new FormData();
  const file = payload.files[0];
  formData.append('xlsx_file', file);
  formData.append('comment', invite.comment);
  formData.append('welcome_text', invite.welcome_text);

  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  };

  try {
    const request = yield call(apiRequest, url, options);

    yield put(
      sendInvitesByFileSuccess({
        count: request.data.count
      })
    );
  } catch (e) {
    yield put(sendInvitesByFileFail());
  }
}

// eslint-disable-next-line
function* changeInviteTextSaga(invite) {
  localStorage.setItem('754a618cb45ba8931da2b13d83de2ccf', invite.text);
}

function* sendReportReadSaga(action) {
  const staffList = yield select(makeSelectStaffList());
  const person = staffList.find(user => user.id === action.personId);

  if(person) {
    if(person.is_report_read || !person.is_available) return;

    const url = `api/managers/staff/${action.personId}/mark_report_as_read/`;
    const options = {
      method: 'post'
    };

    try {
      yield call(apiRequest, url, options);
      yield put(decreaseUnreadReportsCount())
    } catch (e) {}
  }
}

export default function* homePageSaga() {
  yield takeLeading(STAFF_LIST_REQUEST, staffListSaga);
  yield takeLeading(STAFF_LIST_FILTER_REQUEST, staffFilteredListSaga);
  yield takeLeading(STAFF_READ_REQUEST, staffReadSaga);
  yield takeLeading(STAFF_REPORT_REQUEST, staffReportSaga);
  yield takeLeading(STAFF_COMPARE_REQUEST, staffCompareSaga);
  yield takeLeading(CHANGE_STAFF_COMPARE_FILTER, staffCompareSaga);
  yield takeLeading(CHANGE_STAFF_COMPARE_PAGE, staffCompareSaga);
  yield takeLeading(CHANGE_STAFF_COMPARE_SORT, staffCompareSaga);
  yield takeLeading(STAFF_DELETE_REQUEST, staffDeleteSaga);
  yield takeLeading(PROFILE_LIST_REQUEST, profileListSaga);
  yield takeLeading(CHANGE_COMPARE_PROFILE_INPUT, profileListSaga);
  yield takeLeading(CREATE_COMPARE_REQUEST, createCompareSaga);
  yield takeLeading(DELETE_COMPARE_REQUEST, deleteCompareSaga);
  yield takeLeading(GET_COMPARE_REQUEST, compareReadSaga);
  yield takeLeading(SEND_INVITE_USER_REQUEST, sendInviteUserSaga);
  yield takeLeading(UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLeading(UPDATE_USER_PASSWORD_REQUEST, updatePasswordSaga);
  yield takeLeading(CHANGE_MANAGERS_FILTER, getFilteredListSaga);
  yield takeLeading(CHANGE_NAME_FILTER, getFilteredListSaga);
  yield takeLeading(CHANGE_STAFF_PAGE, getFilteredListSaga);
  yield takeLeading(CHANGE_STAFF_SORT, getFilteredListSaga);
  yield takeLeading(STAFF_UPDATE_REQUEST, staffUpdateSaga);
  yield takeLeading(SEND_REPORTS_EMAIL_REQUEST, sendReportsEmailSaga);
  yield takeLeading(SEND_COMPARE_INFO_REQUEST, sendCompareInfoSaga);
  yield takeLeading(ADD_REPORT_TO_COMPARE, changeReportInLocalStageSaga);
  yield takeLeading(DELETE_REPORT_FROM_COMPARE, changeReportInLocalStageSaga);
  yield takeLeading(SEND_INVITES_BY_FILE, sendInvitesByFileSaga);
  yield takeLeading(CHANGE_INVITE_TEXT, changeInviteTextSaga);
  yield takeLeading(SEND_REPORT_READ_REQUEST, sendReportReadSaga);
}
