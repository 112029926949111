import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
import * as moment from 'moment';
import 'moment/locale/ru';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';

import NameWrapper from './styled/NameWrapper';

class ClientsTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'company',
        content: 'Название компании',
        isSortable: false,
        width: 20
      },
      {
        key: 'registration',
        content: 'Дата регистрации',
        shouldTruncate: true,
        isSortable: false,
        width: 20
      },
      {
        key: 'organization',
        content: 'Название организации',
        shouldTruncate: true,
        isSortable: false,
        width: 20
      },
      {
        key: 'inn',
        content: 'ИНН',
        shouldTruncate: true,
        width: 20
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              {item.company_is_my ? (
                <Link to={`/partner/clients/${item.company_id}`}>{item.company_name}</Link>
              ) : (
                item.company_name
              )}
            </NameWrapper>
          )
        },
        {
          key: uniqid(),
          content: moment(item.company_created_at).format('DD.MM.YYYY')
        },
        {
          key: uniqid(),
          content: item.name
        },
        {
          key: uniqid(),
          content: item.inn === '0' ? '' : item.inn
        }
      ]
    }));

  render() {
    const { data, isLoading } = this.props;

    return (
      <DynamicTable
        head={this.generateHead()}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        onSetPage={() => {}}
        onSort={() => {}}
        rows={this.generateRows(data)}
      />
    );
  }
}

export default ClientsTable;
