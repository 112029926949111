export const CHANGE_EMAIL = 'hh/pages/recover/CHANGE_EMAIL';
export const CHANGE_RECOVER_SENT = 'hh/pages/recover/CHANGE_RECOVER_SENT';
export const CHANGE_NEW_PASSWORD = 'hh/pages/recover/CHANGE_NEW_PASSWORD';

export const RECOVER_REQUEST = 'hh/pages/recover/RECOVER_REQUEST';
export const RECOVER_REQUEST_SUCCESS = 'hh/pages/recover/RECOVER_REQUEST_SUCCESS';
export const RECOVER_REQUEST_FAIL = 'hh/pages/recover/RECOVER_REQUEST_FAIL';

export const NEW_PASSWORD_REQUEST = 'hh/pages/recover/NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_REQUEST_SUCCESS = 'hh/pages/recover/NEW_PASSWORD_REQUEST_SUCCESS';
export const NEW_PASSWORD_REQUEST_FAIL = 'hh/pages/recover/NEW_PASSWORD_REQUEST_FAIL';
