import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import * as Sentry from '@sentry/browser';
import createHistory from 'history/createBrowserHistory';

import '@csstools/normalize.css';

import configureStore from './configureStore';
import App from './App';
import service from "./utils/checkService";

// Create redux store with history
const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight);
window.addEventListener('orientationchange', appHeight);
appHeight();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cad52e7f54624c04a363003f263ffa05@sentry.io/1334908'
  });
}

render();
