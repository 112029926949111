import React from 'react';
import styled from 'styled-components';
import NormalButton from '@atlaskit/button';

const Button = styled(NormalButton)`
  &.allWidth {
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;

const TriggerButton = <Button>...</Button>;

export default TriggerButton;
