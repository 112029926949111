import React from 'react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import RouterLink from 'components/RouterLink';

const IndividualGlobalDropdown = () => (
  <DropdownItemGroup isOpen>
    <DropdownItem href="/individual/profile" linkComponent={RouterLink}>
      Мой профиль
    </DropdownItem>
    <DropdownItem href="/individual/logout" linkComponent={RouterLink}>
      Выйти
    </DropdownItem>
  </DropdownItemGroup>
);

export default IndividualGlobalDropdown;
