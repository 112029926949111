import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import { colors } from '@atlaskit/theme';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import Form from '@atlaskit/form';
import isEmpty from 'lodash/isEmpty';
import RouterLink from 'components/RouterLink';

import { isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeProfileChiefName,
  changeProfileChiefDesc,
  changeProfileChiefEmail,
  changeProfileChiefMessage,
  changeProfileChiefFlags,
  changeProfileChiefFullName,
  sendProfileChief
} from 'pages/ProfileList/actions';
import { makeSelectProfileChief } from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateChiefAsk extends React.Component {
  state = {
    emailValid: true,
    nameValid: true
  };

  removeFlag = id => {
    this.props.changeFlags({
      flags: this.props.profileChief.flags.filter(v => v !== id)
    });
  };

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button href="/profile/list" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeName = event => {
    this.setState({
      nameValid: true
    });

    this.props.changeName({
      name: event.target.value
    });
  };

  onChangeDesc = event => {
    this.props.changeDesc({
      desc: event.target.value
    });
  };

  onChangeEmail = event => {
    this.setState({
      emailValid: true
    });

    this.props.changeEmail({
      email: event.target.value
    });
  };

  onChangeMessage = event => {
    this.props.changeMessage({
      message: event.target.value
    });
  };

  onChangeFullName = event => {
    this.props.changeFullName({
      fullName: event.target.value
    });
  };

  handleForm = () => {
    const nameValid = !isEmpty(this.props.profileChief.name);
    const emailValid = isEmail(this.props.profileChief.email);

    if (nameValid && emailValid) {
      this.props.sendProfile();
    } else {
      this.setState({
        nameValid,
        emailValid
      });
    }
  };

  render() {
    const { profileChief } = this.props;

    const breadcrumbs = (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/profile/list"
          onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
          text="Профили"
          key="Profile"
        />
        <BreadcrumbsItem
          href="/profile/create/ask/1"
          onClick={event => this.onBreadcumbsClick(event, '/profile/create/chief')}
          text="Отправить опрос руководителю"
          key="Create"
        />
      </BreadcrumbsStateless>
    );

    const bottomBar = (
      <PageHeaderBottom>
        <h4>Отправить опрос руководителю</h4>
      </PageHeaderBottom>
    );

    const content = (
      <Grid layout="fixed">
        <GridColumn medium={12}>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={this.getPageHeaderButtonsDrawer()}
            bottomBar={bottomBar}
          >
            Добавить профиль
          </PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <div>
            <Form onSubmit={this.handleForm}>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.nameValid}
                    required
                    label="Название профиля"
                    onChange={this.onChangeName}
                    placeholder=""
                    shouldFitContainer
                    value={profileChief.name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    label="Описание профиля"
                    minimumRows={4}
                    onChange={this.onChangeDesc}
                    placeholder=""
                    shouldFitContainer
                    value={profileChief.desc}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Имя руководителя"
                    onChange={this.onChangeFullName}
                    placeholder=""
                    shouldFitContainer
                    value={profileChief.fullName}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.emailValid}
                    required
                    label="Email руководителя"
                    onChange={this.onChangeEmail}
                    placeholder=""
                    shouldFitContainer
                    value={profileChief.email}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    label="Сообщение руководителю"
                    minimumRows={4}
                    onChange={this.onChangeMessage}
                    placeholder=""
                    shouldFitContainer
                    value={profileChief.message}
                  />
                </Field>
              </FieldGroup>
              <ButtonWrapper>
                <Button appearance="primary" isLoading={profileChief.loading} type="submit">
                  Отправить
                </Button>
              </ButtonWrapper>
            </Form>
          </div>
        </GridColumn>
      </Grid>
    );

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        <Container>{content}</Container>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {profileChief.flags.map(id => (
            <Flag
              description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
              isDismissAllowed
              id={id}
              icon={
                id > 0 ? (
                  <Tick label="Success" />
                ) : (
                  <Error label="Error" primaryColor={colors.R300} />
                )
              }
              key={`${id}`}
              title={id > 0 ? 'Приглашение отправлено' : 'Ошибка'}
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

ProfileCreateChiefAsk.propTypes = {
  changeName: PropTypes.func,
  changeDesc: PropTypes.func,
  changeEmail: PropTypes.func,
  changeMessage: PropTypes.func,
  changeFlags: PropTypes.func,
  changeFullName: PropTypes.func,
  profileChief: PropTypes.object,
  sendProfile: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeName: value => dispatch(changeProfileChiefName(value)),
    changeDesc: value => dispatch(changeProfileChiefDesc(value)),
    changeEmail: value => dispatch(changeProfileChiefEmail(value)),
    changeMessage: value => dispatch(changeProfileChiefMessage(value)),
    changeFlags: value => dispatch(changeProfileChiefFlags(value)),
    changeFullName: value => dispatch(changeProfileChiefFullName(value)),
    sendProfile: value => dispatch(sendProfileChief(value))
  };
}

const mapStateToProps = createStructuredSelector({
  profileChief: makeSelectProfileChief()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateChiefAsk);
