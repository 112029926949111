import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px auto;
  max-width: 1060px;
  min-height: calc(100vh - 160px);
  width: 100%;
`;

export default Flex;
