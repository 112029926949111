import styled from 'styled-components';

const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 628px;
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    flex-direction: column-reverse;
    width: calc(100% - 30px);
  }
`;

export default Top;
