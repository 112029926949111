import React from 'react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import RouterLink from 'components/RouterLink';

const GlobalDropdown = () => (
  <DropdownItemGroup isOpen>
    <DropdownItem href="/profile" linkComponent={RouterLink}>
      Мой профиль
    </DropdownItem>
    <DropdownItem href="/logout" linkComponent={RouterLink}>
      Выйти
    </DropdownItem>
  </DropdownItemGroup>
);

export default GlobalDropdown;
