import React, { Component } from 'react';
import { DropdownMenuStateless } from '@atlaskit/dropdown-menu';

class BetterDropdown extends Component {
  static defaultProps = { defaultIsOpen: false };
  state = { isOpen: this.props.defaultIsOpen };

  onOpenChange = openState => {
    if (this.props.onOpenChange) {
      this.props.onOpenChange(openState);
    }
    this.setState({ isOpen: openState.isOpen });
  };

  renderTrigger = () => {
    const { trigger: Trigger } = this.props;
    const { isOpen } = this.state;
    return <Trigger isOpen={isOpen} />;
  };

  render() {
    const { isOpen } = this.state;
    const { trigger, ...props } = this.props;

    return (
      <DropdownMenuStateless
        shouldFitContainer
        {...props}
        isOpen={isOpen}
        onOpenChange={this.onOpenChange}
        trigger={this.renderTrigger()}
      />
    );
  }
}

export default BetterDropdown;
