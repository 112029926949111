import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@atlaskit/icon/glyph/info';

import InfoWrapper from './styled/InfoWrapper';
import Tooltip from './styled/Tooltip';

class Info extends React.PureComponent {
  state = {
    isOpened: false
  };

  openTooltip = () => {
    this.setState({
      isOpened: true
    });
  };

  closeTooltip = () => {
    this.setState({
      isOpened: false
    });
  };

  render() {
    const { text } = this.props;

    return (
      <InfoWrapper>
        <div className="info-icon" onMouseEnter={this.openTooltip} onMouseLeave={this.closeTooltip}>
          <InfoIcon size="small" primaryColor="#DFE1E6" />
        </div>
        <Tooltip isBig={this.props.isBig} isOpened={this.state.isOpened}>
          {text}
        </Tooltip>
      </InfoWrapper>
    );
  }
}

Info.propTypes = {
  isBig: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default Info;
