import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import ClientManagersBase from './components/ClientManagersBase';

const ClientManagersView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <ClientManagersBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default ClientManagersView;
