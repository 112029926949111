import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import { fromJS } from 'immutable';
import apiUrl from 'utils/serverConfig';

import {
  DISLIKE_REQUEST,
  FIRE_REQUEST,
  LIKE_REQUEST,
  REPORT_LIST_REQUEST,
  CREATE_COMMENT_REQUEST,
  DELETE_COMMENT_REQUEST,
  SEND_EMAIL_REQUEST
} from './constans';

import {
  createCommentSuccess,
  createCommentFail,
  deleteCommentSuccess,
  deleteCommentFail,
  dislikeSectionSuccess,
  dislikeSectionFail,
  fireSectionSuccess,
  fireSectionFail,
  getReportListSuccess,
  getReportListFail,
  getReportListNoContentError,
  likeSectionSuccess,
  likeSectionFail,
  sendEmailSuccess,
  sendEmailFail,
} from './actions';

function* getReportListSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/report/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    }
  };

  try {
    const request = yield call(axios, url, options);
    const sections = {};
    let likesCount = 0;
    let subsectionsCount = 0;

    if(request.status === 204) {
      yield put(getReportListNoContentError());
      yield put(getReportListFail());
      return
    }

    request.data.sections.forEach(section => {
      const subsections = {};

      section.subsections.forEach(subsection => {
        const comments = {};

        if (subsection.like_type > 0) {
          likesCount += 1;
        }
        subsectionsCount += 1;

        subsection.comments.forEach(comment => {
          comments[comment.id] = comment;
        });

        subsection.comments = comments;
        subsections[subsection.id] = subsection;
      });

      section.subsections = subsections;
      sections[section.id] = section;
    });

    yield put(
      getReportListSuccess({
        id: request.data.id,
        likesCount,
        subsectionsCount,
        createdAt: request.data.created_at,
        list: fromJS(sections).sortBy(item => -item.get('rank'))
      })
    );
  } catch (e) {
    console.log(e)
    yield put(getReportListFail());
  }
}

function* dislikeSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const dislike = yield select(store => store.getIn(['individualReport', 'dislike']));
  const url = `${apiUrl}/api/staff/report/subsections/${dislike.get('subsectionId')}/dislike/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: 'post'
  };

  try {
    yield call(axios, url, options);

    yield put(dislikeSectionSuccess());
  } catch (e) {
    yield delay(500);
    yield put(dislikeSectionFail());
  }
}

function* likeSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const like = yield select(store => store.getIn(['individualReport', 'like']));
  const url = `${apiUrl}/api/staff/report/subsections/${like.get('subsectionId')}/like/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: 'post'
  };

  try {
    yield call(axios, url, options);

    yield put(likeSectionSuccess());
  } catch (e) {
    yield delay(1000);
    yield put(likeSectionFail());
  }
}

function* fireSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const fire = yield select(store => store.getIn(['individualReport', 'fire']));
  const url = `${apiUrl}/api/staff/report/subsections/${fire.get('subsectionId')}/fire/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: 'post'
  };

  try {
    yield call(axios, url, options);

    yield put(fireSectionSuccess());
  } catch (e) {
    yield delay(1000);
    yield put(fireSectionFail());
  }
}

function* createCommentSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const comment = yield select(store => store.getIn(['individualReport', 'comment']).toJS());
  const url = comment.forEdit
    ? `${apiUrl}/api/staff/comments/${comment.id}/`
    : `${apiUrl}/api/staff/comments/`;

  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: comment.forEdit ? 'put' : 'post',
    data: {
      selected_text_range: {
        lower: comment.textRangeFrom,
        upper: comment.textRangeTo
      },
      selected_text: comment.selectedText,
      comment: comment.text,
      subsection: comment.subsectionId
    }
  };

  try {
    const request = yield call(axios, url, options);

    yield put(
      createCommentSuccess({
        commentId: request.data.id
      })
    );
  } catch (e) {
    yield put(createCommentFail());
  } finally {
    if (comment.forDislike) {
      const dislike = yield select(store => store.getIn(['individualReport', 'dislike']));
      const dislikeUrl = `${apiUrl}/api/staff/report/subsections/${dislike.get(
        'subsectionId'
      )}/dislike/`;
      const dislikeOptions = {
        headers: {
          Authorization: `Token ${staffKey}`
        },
        method: 'post'
      };

      try {
        yield call(axios, dislikeUrl, dislikeOptions);

        yield put(dislikeSectionSuccess());
      } catch (e) {
        yield put(dislikeSectionFail());
      }
    }
  }
}

function* deleteCommentSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const deleteComment = yield select(store =>
    store.getIn(['individualReport', 'deleteComment']).toJS()
  );
  const url = `${apiUrl}/api/staff/comments/${deleteComment.id}/`;

  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: 'delete'
  };

  try {
    yield call(axios, url, options);

    yield put(deleteCommentSuccess());
  } catch (e) {
    yield put(deleteCommentFail());
  }
}

function* sendEmailSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/report/send_email/`;
  const email = yield select(store => store.getIn(['individualReport', 'reportModal', 'email']));

  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    },
    method: 'post',
    data: {
      to_email: email
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendEmailSuccess());
  } catch (e) {
    yield put(sendEmailFail());
  }
}

export default function* reportsSagas() {
  yield takeLeading(REPORT_LIST_REQUEST, getReportListSaga);
  yield takeLeading(DISLIKE_REQUEST, dislikeSaga);
  yield takeLeading(LIKE_REQUEST, likeSaga);
  yield takeLeading(FIRE_REQUEST, fireSaga);
  yield takeLeading(CREATE_COMMENT_REQUEST, createCommentSaga);
  yield takeLeading(DELETE_COMMENT_REQUEST, deleteCommentSaga);
  yield takeLeading(SEND_EMAIL_REQUEST, sendEmailSaga);
}
