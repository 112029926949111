import {
  CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_INVITE_CODE_REQUEST_SUCCESS,
  CHECK_INVITE_CODE_REQUEST_FAIL,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_REQUEST_SUCCESS,
  QUESTIONS_LIST_REQUEST_FAIL,
  SEND_QUIZ_RESULT_REQUEST,
  SEND_QUIZ_RESULT_REQUEST_SUCCESS,
  SEND_QUIZ_RESULT_REQUEST_FAIL
} from './constants';

export function questionsListRequest() {
  return {
    type: QUESTIONS_LIST_REQUEST
  };
}

export function questionsListSuccess(payload) {
  return {
    type: QUESTIONS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function questionsListFail() {
  return {
    type: QUESTIONS_LIST_REQUEST_FAIL
  };
}

export function sendQuizResultRequest() {
  return {
    type: SEND_QUIZ_RESULT_REQUEST
  };
}

export function sendQuizResultSuccess() {
  return {
    type: SEND_QUIZ_RESULT_REQUEST_SUCCESS
  };
}

export function sendQuizResultFail() {
  return {
    type: SEND_QUIZ_RESULT_REQUEST_FAIL
  };
}

export function changeAnswer(payload) {
  return {
    type: CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
    ...payload
  };
}

export function checkInviteCodeRequest(payload) {
  return {
    type: CHECK_INVITE_CODE_REQUEST,
    ...payload
  };
}

export function checkInviteCodeSuccess() {
  return {
    type: CHECK_INVITE_CODE_REQUEST_SUCCESS
  };
}

export function checkInviteCodeFail() {
  return {
    type: CHECK_INVITE_CODE_REQUEST_FAIL
  };
}
