import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { isRequired, isPhone, isPhoneInIntFormat, isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { pageHostUrl } from 'utils/serverConfig';

import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Form from '@atlaskit/form';
import Page from '@atlaskit/page';
import FacebookAuth from 'components/FacebookAuth';
import VkAuth from 'components/VkAuth';
import GoogleAuth from 'components/GoogleAuth';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js'

import {
  checkOrder,
  changeCode,
  changeEmail,
  changeName,
  changePhone,
  changeSurname,
  checkInviteCode,
  sendCreate,
  sendVerify,
  userUpdate,
  changeTermsAccepted
} from './actions';

import {
  makeSelectCode,
  makeSelectLoading,
  makeSelectPhone,
  makeSelectSmsWasSend,
  makeSelectVerified,
  makeSelectUser,
  makeSelectTermsAccepted
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import TestImage from './img/test-main.png';
import TestImage2x from './img/test-main-2x.png';

import ButtonField from './styled/ButtonField';
import CheckboxField from './styled/CheckboxField';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import P from './styled/P';
import Title from './styled/Title';
import AuthWrapper from '../IndividualProfile/Login/styled/AuthWrapper';
import { serviceName, legalName } from "utils/checkService";
import service from '../../utils/checkService';

class TestStart extends React.Component {
  state = {
    phoneValid: true,
    codeValid: true,
    nameValid: true,
    surnameValid: true,
    emailValid: true,
    isDisabled: true,
    verifyWasSend: false,
    phoneFormatted: '',
    termsAccepted: false
  };

  componentDidMount() {
    const invitationCode = this.props.match.params.invitationCode;
    const key = localStorage.getItem('staffKey');

    const orderId = this.props.location.search.replace('?orderId=', '').replace('&lang=ru', '');
    if (!isEmpty(orderId)) {
      this.props.checkOrder({
        orderId
      });
    } else {
      if (!isNull(key)) {
        if (!isUndefined(invitationCode)) {
          this.props.history.push(`/test/about/${invitationCode}`);
        } else {
          this.props.history.push('/test/about');
        }
      }

      if (!isUndefined(invitationCode)) {
        this.props.checkInviteCode({
          invitationCode
        });
      }
    }
  }

  changeCode = event => {
    this.setState({
      codeValid: isRequired(event.target.value),
      isDisabled: !isRequired(event.target.value)
    });

    this.props.changeCode({
      code: event.target.value
    });
  };

  changePhone = event => {
    const asYouType = new AsYouType('RU')
    const phoneFormatted = asYouType.input(event.target.value);
    const phone = asYouType.getNumberValue();

    const isValid = asYouType.isValid();

    const termsAccepted = this.props.termsAccepted;

    this.setState({
      phoneFormatted: phoneFormatted,
      phoneValid: isValid,
      isDisabled: !(isValid && termsAccepted)
    });

    this.props.changePhone({
      phone: phone
    });
  };

  changeTermsAccepted = event => {
    this.props.changeTermsAccepted({
      termsAccepted: event.target.checked
    });

    const phoneFormatted = this.state.phoneFormatted;
    const isValid = this.state.phoneValid;
    const termsAccepted = event.target.checked;

    this.setState({
      isDisabled: !(phoneFormatted.length > 0 && isValid && termsAccepted)
    });
  };

  changeName = event => {
    this.props.changeName({
      name: event.target.value
    });

    this.checkRegisterForm('name', event.target.value);
  };

  changeSurname = event => {
    this.props.changeSurname({
      surname: event.target.value
    });

    this.checkRegisterForm('surname', event.target.value);
  };

  changeEmail = event => {
    this.props.changeEmail({
      email: event.target.value
    });

    this.checkRegisterForm('email', event.target.value);
  };

  checkRegisterForm = (input, value) => {
    const user = this.props.user;
    let isDisabled = true;

    const nameValid = isRequired(input === 'name' ? value : user.get('first_name'));
    const surnameValid = isRequired(input === 'surname' ? value : user.get('last_name'));
    const emailValid = isEmail(input === 'email' ? value : user.get('email'));

    switch (input) {
      case 'name':
        this.setState({
          nameValid
        });
        break;
      case 'surname':
        this.setState({
          surnameValid
        });
        break;
      case 'email':
        this.setState({
          emailValid
        });
        break;
      default:
        break;
    }

    if (nameValid && surnameValid && emailValid) {
      isDisabled = false;
    }

    this.setState({
      isDisabled
    });
  };

  sendSms = event => {
    event.preventDefault();
    this.props.sendCreate();
  };

  sendVerify = event => {
    event.preventDefault();
    const invitationCode = this.props.match.params.invitationCode;

    this.setState({
      isDisabled: true,
      verifyWasSend: true
    });
    this.props.sendVerify({
      invitationCode
    });
  };

  sendUserForm = event => {
    event.preventDefault();
    this.props.userUpdate();
  };

  render() {
    const { emailValid, isDisabled, nameValid, surnameValid } = this.state;
    const { loading, user } = this.props;

    let textFieldProps = {
      isInvalid: !this.state.phoneValid,
      label: 'Введите ваш номер телефона',
      name: 'phone',
      onBlur: this.changePhone,
      onChange: this.changePhone,
      value: this.state.phoneFormatted,
      placeholder: '',
      shouldFitContainer: true
    };
    const invitationCode = isUndefined(this.props.match.params.invitationCode)
      ? ''
      : this.props.match.params.invitationCode;

    let buttonText = 'Получить код подтверждения';
    let buttonOnClick = this.sendSms;

    // if (service === "my-yadro") {
    buttonText = 'Продолжить';
    // }

    let termsText = (
      <P marginTop="21px" color="#6B778C">
        Я принимаю условия{' '}
        <a href="/documents/4" target="_blank" rel="noopener noreferrer">
          Пользовательского соглашения
        </a>{' '}
        и даю своё согласие {legalName} на обработку моих персональных данных, в соответствии
        с Федеральным законом от 27.07.2006 года #152-ФЗ "О персональных данных", на условиях
        и для целей, определенных{' '}
        <a href="/documents/3" target="_blank" rel="noopener noreferrer">
          Политикой конфиденциальности
        </a>
        .
      </P>
    )

    if (service === "my-yadro") {
      termsText = (
        <P marginTop="21px" color="#6B778C">
          Я даю согласие на обработку своих{' '}
          <a href="https://my-yadro.ru/policy" target="_blank" rel="noopener noreferrer">
          персональных данных
          </a>{' '} в соответствии с Политикой обработки персональных данных.
          С{' '}
          <a href="https://my-yadro.ru/policy" target="_blank" rel="noopener noreferrer">
            Политикой обработки персональных данных
          </a>{' '}
          ознакомлен (-на) и согласен (-на).
        </P>
      )
    }

    let content = <Spinner />;
    let form = (
      <Form onSubmit={buttonOnClick}>
        <FieldGroup layout="column" form="">
          <Field>
            <TextField {...textFieldProps} />
          </Field>
        </FieldGroup>

        <FieldGroup>
            <CheckboxField>
              <Checkbox
                defaultChecked={false}
                label={termsText}
                onChange={this.changeTermsAccepted}
              />
            </CheckboxField>
          </FieldGroup>

        <FieldGroup displayBlock>
          <ButtonField width="100%">
            <Button appearance="primary" type="submit" isDisabled={this.state.isDisabled}>
              {buttonText}
            </Button>
          </ButtonField>
          {/*<ButtonField width="100%">*/}
          {/*  <div>*/}
          {/*    <P marginTop="20px">или войдите через соц.сети</P>*/}
          {/*    <AuthWrapper>*/}
          {/*      <FacebookAuth*/}
          {/*        link={`https://www.facebook.com/v3.2/dialog/oauth?client_id=2364882473743013&redirect_uri=${pageHostUrl}/facebook&response_type=code&scope=email&state=${invitationCode}`}*/}
          {/*      />*/}
          {/*      <VkAuth*/}
          {/*        link={`https://oauth.vk.com/authorize?client_id=6880349&display=page&redirect_uri=${pageHostUrl}/vk&scope=email&response_type=code&v=5.92&state=${invitationCode}`}*/}
          {/*      />*/}
          {/*      <GoogleAuth*/}
          {/*        link={`https://accounts.google.com/o/oauth2/v2/auth?scope=email&access_type=offline&include_granted_scopes=true&redirect_uri=${encodeURI(*/}
          {/*          `${pageHostUrl}/google`*/}
          {/*        )}&response_type=code&client_id=321511513968-450ftjk9rtm3lrvgon7fktbqg7ki7bmj.apps.googleusercontent.com&state=${invitationCode}`}*/}
          {/*      />*/}
          {/*    </AuthWrapper>*/}
          {/*  </div>*/}
          {/*</ButtonField>*/}
        </FieldGroup>
      </Form>
    );

    if (this.props.smsCodeWasSend && !this.props.verified) {
      textFieldProps = {
        isInvalid: this.state.verifyWasSend && !this.props.verified,
        label: 'Введите код - последние 4 цифры входящего звонка',
        name: 'code',
        onBlur: this.changeCode,
        onChange: this.changeCode,
        value: this.props.code,
        placeholder: '',
        shouldFitContainer: true,
        required: true
      };

      buttonText = 'Начать опрос';
      buttonOnClick = this.sendVerify;

      form = (
        <Form onSubmit={buttonOnClick}>
          <FieldGroup layout="column" form="">
            <Field>
              <TextField {...textFieldProps} />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <ButtonField>
              <Button appearance="primary" type="submit" isDisabled={this.state.isDisabled}>
                {buttonText}
              </Button>
            </ButtonField>
          </FieldGroup>
        </Form>
      );
    } else if (this.props.smsCodeWasSend && this.props.verified) {
      form = (
        <Form onSubmit={this.sendUserForm}>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!nameValid}
                required
                label="Имя"
                name="name"
                onBlur={this.changeName}
                onChange={this.changeName}
                placeholder=""
                shouldFitContainer
                value={user.get('first_name')}
              />
            </Field>
            <Field>
              <TextField
                isInvalid={!surnameValid}
                required
                label="Фамилия"
                name="surname"
                onBlur={this.changeSurname}
                onChange={this.changeSurname}
                placeholder=""
                shouldFitContainer
                value={user.get('last_name')}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <TextField
                isInvalid={!emailValid}
                required
                label="Email"
                name="email"
                onBlur={this.changeEmail}
                onChange={this.changeEmail}
                placeholder=""
                shouldFitContainer
                value={user.get('email')}
              />
            </Field>
            <Field>
              <TextField
                required
                disabled
                label="Телефон"
                name="phone"
                placeholder=""
                shouldFitContainer
                value={user.get('phone_number')}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <ButtonField>
              <Button appearance="primary" isDisabled={isDisabled} type="submit">
                Начать опрос
              </Button>
            </ButtonField>
          </FieldGroup>
        </Form>
      );
    }

    if (!loading) {
      const buttonText =
        this.props.smsCodeWasSend && !this.props.verified
          ? 'Начать опрос'
          : 'Продолжить';

      content = (
        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={TestImage} srcSet={`${TestImage2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Добро пожаловать на сервис {serviceName}!</Title>
            {
              invitationCode ? (
                <>

                  {form}

                </>
              ) : (
                <p>Регистрация и тестирование доступны только по приглашению.</p>
              )
            }

          </FlexItem>
        </Flex>
      );
    }

    return <Page>{content}</Page>;
  }
}

TestStart.propTypes = {
  changeCode: PropTypes.func,
  changeEmail: PropTypes.func,
  changeName: PropTypes.func,
  checkOrder: PropTypes.func,
  changePhone: PropTypes.func,
  changeSurname: PropTypes.func,
  changeTermsAccepted: PropTypes.func,
  checkInviteCode: PropTypes.func,
  code: PropTypes.string,
  loading: PropTypes.bool,
  sendCreate: PropTypes.func,
  phone: PropTypes.string,
  smsCodeWasSend: PropTypes.bool,
  user: PropTypes.object,
  userUpdate: PropTypes.func,
  verified: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeCode: value => dispatch(changeCode(value)),
    changeEmail: value => dispatch(changeEmail(value)),
    changeName: value => dispatch(changeName(value)),
    checkOrder: value => dispatch(checkOrder(value)),
    changePhone: value => dispatch(changePhone(value)),
    changeSurname: value => dispatch(changeSurname(value)),
    checkInviteCode: value => dispatch(checkInviteCode(value)),
    sendCreate: () => dispatch(sendCreate()),
    sendVerify: value => dispatch(sendVerify(value)),
    userUpdate: () => dispatch(userUpdate()),
    changeTermsAccepted: value => dispatch(changeTermsAccepted(value)),
  };
}

const mapStateToProps = createStructuredSelector({
  code: makeSelectCode(),
  loading: makeSelectLoading(),
  phone: makeSelectPhone(),
  smsCodeWasSend: makeSelectSmsWasSend(),
  verified: makeSelectVerified(),
  user: makeSelectUser(),
  termsAccepted: makeSelectTermsAccepted()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'testStart', reducer });
const withSaga = injectSaga({ key: 'testStart', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(TestStart);
