import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form from '@atlaskit/form';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Flag, { FlagGroup } from '@atlaskit/flag';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';

import { isInn, isRequired } from 'utils/validators';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeOrganizationName,
  changeOrganizationInn,
  changeOrganizationKpp,
  changeOrganizationOgrn,
  changeOrganizationIndex,
  changeOrganizationAddress,
  changeOrganizationAccountNumber,
  changeOrganizationBankName,
  changeOrganizationBankBik,
  changeOrganizationCorrespondentAccountNumber,
  changeOrganizationId,
  changeOrganizationsFlags,
  createOrganization,
  editOrganization,
  getOrganizationRead
} from '../actions';
import {
  makeSelectOrganization,
  makeSelectOrganizationId,
  makeSelectOrganizationsFlags,
  makeSelectOrganizationsLoading,
  makeSelectLoadingOrganizationCreate
} from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import Container from '../styled/Container';
import FieldGroup from '../styled/FieldGroup';
import Field from '../styled/Field';

class EditOrganization extends React.Component {
  state = {
    nameValid: true,
    innValid: true
  };

  componentDidMount() {
    const organizationId = parseInt(this.props.match.params.id, 10);

    if (!isNaN(organizationId)) {
      this.props.getOrganizationRead({
        flags: [],
        organizationId
      });
    } else {
      this.props.changeOrganizationId({
        organizationId: null
      });
    }
  }

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeName = event => {
    this.setState({
      nameValid: isRequired(event.target.value)
    });
    this.props.changeOrganizationName({
      name: event.target.value
    });
  };

  onChangeInn = event => {
    this.setState({
      innValid: isInn(event.target.value)
    });
    this.props.changeOrganizationInn({
      inn: event.target.value
    });
  };

  onChangeKpp = event => {
    this.props.changeOrganizationKpp({
      kpp: event.target.value
    });
  };

  onChangeOgrn = event => {
    this.props.changeOrganizationOgrn({
      ogrn: event.target.value
    });
  };

  onChangeIndex = event => {
    this.props.changeOrganizationIndex({
      index: event.target.value
    });
  };

  onChangeAddress = event => {
    this.props.changeOrganizationAddress({
      address: event.target.value
    });
  };

  onChangeAccountNumber = event => {
    this.props.changeOrganizationAccountNumber({
      accountNumber: event.target.value
    });
  };

  onChangeBankName = event => {
    this.props.changeOrganizationBankName({
      bankName: event.target.value
    });
  };

  onChangeBankBik = event => {
    this.props.changeOrganizationBankBik({
      bankBik: event.target.value
    });
  };

  onChangeCorrespondentAccountNumber = event => {
    this.props.changeOrganizationCorrespondentAccountNumber({
      correspondentAccountNumber: event.target.value
    });
  };

  onRemoveFlag = id => {
    this.props.changeOrganizationsFlags({
      flags: this.props.organizationsFlags.filter(v => v !== id)
    });
  };

  onSendForm = () => {
    const nameValid = isRequired(this.props.organization.newName);
    const innValid = isInn(this.props.organization.inn);

    if (nameValid && innValid) {
      const organizationId = this.props.organizationId;

      if (isNull(organizationId)) {
        this.props.createOrganization();
      } else {
        this.props.editOrganization();
      }
    } else {
      this.setState({
        nameValid,
        innValid
      });
    }
  };

  render() {
    const { nameValid, innValid } = this.state;

    const {
      organization,
      organizationCreateLoading,
      organizationId,
      organizationsFlags,
      organizationsLoading
    } = this.props;

    let content = <Spinner />;

    if (!organizationsLoading) {
      let pageTitle = 'Добавить организацию';
      let breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem text="Настройки" key="Settings" />
          <BreadcrumbsItem
            href="/settings/organizations/list"
            onClick={event => this.onBreadcumbsClick(event, '/settings/organizations/list')}
            text="Организации"
            key="Organizations"
          />
        </BreadcrumbsStateless>
      );
      const actions = (
        <ButtonGroup>
          <Button href="/settings/organizations/list" component={RouterLink}>
            Отмена
          </Button>
        </ButtonGroup>
      );

      if (!isNull(organizationId)) {
        pageTitle = organization.name;
        breadcrumbs = (
          <BreadcrumbsStateless onExpand={() => {}}>
            <BreadcrumbsItem text="Настройки" key="Settings" />
            <BreadcrumbsItem
              href="/settings/organizations/list"
              onClick={event => this.onBreadcumbsClick(event, '/settings/organizations/list')}
              text="Организации"
              key="Organizations"
            />
            <BreadcrumbsItem
              href={`/settings/organizations/${organizationId}`}
              onClick={event =>
                this.onBreadcumbsClick(event, `/settings/organizations/${organizationId}`)
              }
              text={organization.name}
              key="Organizations name"
            />
          </BreadcrumbsStateless>
        );
      }

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={actions} breadcrumbs={breadcrumbs}>
              {pageTitle}
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!nameValid}
                    required
                    label="Название организации"
                    name="name"
                    onChange={this.onChangeName}
                    placeholder=""
                    shouldFitContainer
                    value={organization.newName}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!innValid}
                    required
                    label="ИНН"
                    mask="999999999999"
                    maskChar=""
                    name="inn"
                    onChange={this.onChangeInn}
                    placeholder=""
                    shouldFitContainer
                    value={organization.inn}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="КПП"
                    mask="999999999"
                    maskChar=""
                    name="kpp"
                    onChange={this.onChangeKpp}
                    placeholder=""
                    shouldFitContainer
                    value={organization.kpp}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="ОГРН"
                    mask="9999999999999"
                    maskChar=""
                    name="ogrn"
                    onChange={this.onChangeOgrn}
                    placeholder=""
                    shouldFitContainer
                    value={organization.ogrn}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Индекс"
                    mask="999999"
                    maskChar=""
                    name="index"
                    onChange={this.onChangeIndex}
                    placeholder=""
                    shouldFitContainer
                    value={organization.index}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Юридический адрес"
                    name="address"
                    onChange={this.onChangeAddress}
                    placeholder=""
                    shouldFitContainer
                    value={organization.address}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Расчетный счет"
                    mask="99999999999999999999"
                    maskChar=""
                    name="accountNumber"
                    onChange={this.onChangeAccountNumber}
                    placeholder=""
                    shouldFitContainer
                    value={organization.accountNumber}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Наименование банка"
                    name="bankName"
                    onChange={this.onChangeBankName}
                    placeholder=""
                    shouldFitContainer
                    value={organization.bankName}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="БИК банка"
                    mask="999999999"
                    maskChar=""
                    name="bankBik"
                    onChange={this.onChangeBankBik}
                    placeholder=""
                    shouldFitContainer
                    value={organization.bankBik}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Кор. счет"
                    mask="99999999999999999999"
                    maskChar=""
                    name="correspondentAccountNumber"
                    onChange={this.onChangeCorrespondentAccountNumber}
                    placeholder=""
                    shouldFitContainer
                    value={organization.correspondentAccountNumber}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup marginTop="20px">
                <Button
                  appearance="primary"
                  isLoading={organizationCreateLoading}
                  onClick={this.onSendForm}
                >
                  Сохранить
                </Button>
              </FieldGroup>
            </Form>
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Компания</title>
        </Helmet>

        <Container>
          {content}

          <FlagGroup onDismissed={name => this.onRemoveFlag(name)}>
            {organizationsFlags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={id}
                title="Организация сохранена"
              />
            ))}
          </FlagGroup>
        </Container>
      </Fragment>
    );
  }
}

EditOrganization.propTypes = {
  changeOrganizationName: PropTypes.func,
  changeOrganizationInn: PropTypes.func,
  changeOrganizationKpp: PropTypes.func,
  changeOrganizationOgrn: PropTypes.func,
  changeOrganizationIndex: PropTypes.func,
  changeOrganizationAddress: PropTypes.func,
  changeOrganizationAccountNumber: PropTypes.func,
  changeOrganizationBankName: PropTypes.func,
  changeOrganizationBankBik: PropTypes.func,
  changeOrganizationCorrespondentAccountNumber: PropTypes.func,
  changeOrganizationId: PropTypes.func,
  changeOrganizationsFlags: PropTypes.func,
  createOrganization: PropTypes.func,
  editOrganization: PropTypes.func,
  getOrganizationRead: PropTypes.func,
  organization: PropTypes.object,
  organizationCreateLoading: PropTypes.bool,
  organizationId: PropTypes.number,
  organizationsFlags: PropTypes.array,
  organizationsLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeOrganizationName: value => dispatch(changeOrganizationName(value)),
    changeOrganizationInn: value => dispatch(changeOrganizationInn(value)),
    changeOrganizationKpp: value => dispatch(changeOrganizationKpp(value)),
    changeOrganizationOgrn: value => dispatch(changeOrganizationOgrn(value)),
    changeOrganizationIndex: value => dispatch(changeOrganizationIndex(value)),
    changeOrganizationAddress: value => dispatch(changeOrganizationAddress(value)),
    changeOrganizationAccountNumber: value => dispatch(changeOrganizationAccountNumber(value)),
    changeOrganizationBankName: value => dispatch(changeOrganizationBankName(value)),
    changeOrganizationBankBik: value => dispatch(changeOrganizationBankBik(value)),
    changeOrganizationCorrespondentAccountNumber: value =>
      dispatch(changeOrganizationCorrespondentAccountNumber(value)),
    changeOrganizationId: value => dispatch(changeOrganizationId(value)),
    changeOrganizationsFlags: value => dispatch(changeOrganizationsFlags(value)),
    createOrganization: () => dispatch(createOrganization()),
    editOrganization: () => dispatch(editOrganization()),
    getOrganizationRead: value => dispatch(getOrganizationRead(value))
  };
}

const mapStateToProps = createStructuredSelector({
  organization: makeSelectOrganization(),
  organizationCreateLoading: makeSelectLoadingOrganizationCreate(),
  organizationId: makeSelectOrganizationId(),
  organizationsFlags: makeSelectOrganizationsFlags(),
  organizationsLoading: makeSelectOrganizationsLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(EditOrganization);
