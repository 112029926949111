import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import RequisitesListPage from 'pages/PartnerProfile/Requisites/List';

const RequisitesListView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/settings" />
    <RequisitesListPage />
  </Fragment>
);

export default RequisitesListView;
