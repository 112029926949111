import React from 'react';
import Button from '@atlaskit/button';
import { Link } from 'react-router-dom';

class RouterLink extends React.PureComponent {
  render() {
    const { children, className, disabled, href, onMouseEnter, onMouseLeave } = this.props;

    let result = (
      <Link className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} to={href}>
        {children}
      </Link>
    );

    if (disabled === true) {
      result = <Button isDisabled={true}>{children}</Button>;
    }

    return result;
  }
}

export default RouterLink;
