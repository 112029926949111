import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import uniqid from 'uniqid';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import Select from '@atlaskit/select';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isEmail } from 'utils/validators';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import Info from 'components/Info';
import Spinner from 'components/Spinner';
import Form from '@atlaskit/form';
import Label from 'pages/Report/styled/Label';
import SelectWrapper from 'pages/Report/styled/SelectWrapper';
import Modal from 'components/Modal';
import FieldGroup from 'pages/Home/styled/FieldGroup';
import Field from 'pages/Home/styled/Field';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';

import {
  changeAddsModal,
  changeMismatchedPatterns,
  compareRead,
  changeCompareInfoModal,
  changeCompareInfoEmail,
  changeCompareInfoFlag,
  staffRead,
  sendCompareInfo
} from 'pages/Home/actions';
import {
  makeSelectCompany,
  makeSelectCompareInfo,
  makeSelectCompareRead,
  makeSelectCompareReadSections,
  makeSelectStaffRead,
  makeSelectStaffLoading,
  makeSelectMismatchedSelector
} from 'pages/Home/selectors';
import reducer from 'pages/Home/reducer';
import saga from 'pages/Home/saga';

import Block from './styled/Block';
import Container from './styled/Container';
import MainBlock from './styled/MainBlock';
import Progress from './styled/Progress';
import ProgressAfter from './styled/ProgressAfter';
import ProgressBefore from './styled/ProgressBefore';
import ProgressLine from './styled/ProgressLine';
import Result from './styled/Result';
import Title from './styled/Title';

class CompareInfo extends React.Component {
  state = {
    emailValid: true
  };

  componentDidMount() {
    const personId = parseInt(this.props.match.params.personId, 10);
    const compareId = parseInt(this.props.match.params.compareId, 10);
    const { staffRead } = this.props;

    if (staffRead.id !== personId) {
      this.props.getStaffRead({
        staffId: personId
      });
    }

    this.props.getCompareRead({
      compareId
    });
  }

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button className="media-print-hide" onClick={this.onOpenModal}>
        Отправить на email
      </Button>
      <Button
        className="media-print-hide"
        onClick={() => {
          window.print();
        }}
      >
        Печать
      </Button>
    </ButtonGroup>
  );

  getProgressColor = progress => {
    if (progress >= 80) {
      return '#36B37E';
    } else if (progress < 80 && progress > 20) {
      return '#FFAB00';
    } else {
      return '#FF5230';
    }
  };

  getSubsectionLineColor = (value, lowerRange, upperRange) => {
    if (value <= upperRange && value >= lowerRange) {
      return '#36B37E';
    } else {
      return '#FF5230';
    }
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onMismatchedChange = event => {
    this.props.changeMismatched({
      showMismatched: event.value === 1
    });
  };

  onOpenModal = () => {
    this.props.changeCompareInfoModal({
      modal: [1]
    });
  };

  onCloseModal = id => {
    this.props.changeCompareInfoModal({
      modal: this.props.compareInfo.modal.filter(i => i !== id)
    });
  };

  onRemoveFlag = id => {
    this.props.changeCompareInfoFlag({ flag: this.props.compareInfo.flag.filter(v => v !== id) });
  };

  onEmailChange = event => {
    if (!this.state.emailValid) {
      this.setState({
        emailValid: true
      });
    }

    this.props.changeCompareInfoEmail({
      email: event.target.value
    });
  };

  onSendModal = event => {
    if (isObject(event)) {
      event.preventDefault();
    }
    const { compareInfo } = this.props;

    if (isEmail(compareInfo.email)) {
      const compareId = parseInt(this.props.match.params.compareId, 10);
      this.props.sendCompareInfo({
        compareId
      });
    } else {
      this.setState({
        emailValid: false
      });
    }
  };

  renderSubsection = subsection => {
    const lowerRange = subsection.getIn(['range', 'lower']);
    const upperRange = 100 - subsection.getIn(['range', 'upper']);

    return (
      <Block key={uniqid()}>
        <Title>
          <h4>{subsection.get('title')}</h4>
          {!isEmpty(subsection.get('info')) && <Info isBig text={subsection.get('info')} />}
        </Title>
        <p>{subsection.get('text')}</p>
        <ProgressLine
          color={this.getSubsectionLineColor(
            subsection.get('value'),
            lowerRange,
            subsection.getIn(['range', 'upper'])
          )}
          progressBefore={lowerRange}
          progressAfter={upperRange}
        >
          <Progress
            position={subsection.get('value')}
            lineColor={this.getSubsectionLineColor(
              subsection.get('value'),
              lowerRange,
              subsection.getIn(['range', 'upper'])
            )}
          >
            {subsection.get('value')}%
          </Progress>
          <ProgressBefore width={lowerRange}>{lowerRange}%</ProgressBefore>
          <ProgressAfter width={upperRange}>{100 - upperRange}%</ProgressAfter>
        </ProgressLine>
      </Block>
    );
  };

  renderSection = section => {
    return (
      <MainBlock key={uniqid()}>
        <Block>
          <Title>
            <h3>{section.get('title')}</h3>
            {!isEmpty(section.get('info')) && <Info isBig text={section.get('info')} />}
          </Title>
          <p>{section.get('text')}</p>

          {section.get('subsections').map(subsection => this.renderSubsection(subsection))}
        </Block>
      </MainBlock>
    );
  };

  render() {
    const { emailValid } = this.state;
    const {
      compareInfo,
      compareRead,
      mismatchedSelector,
      sections,
      staffLoading,
      staffRead
    } = this.props;
    const personId = parseInt(this.props.match.params.personId, 10);
    const compareId = parseInt(this.props.match.params.compareId, 10);

    let content = <Spinner />;

    if (!staffLoading && compareRead.size > 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/person/list"
            onClick={event => this.onBreadcumbsClick(event, '/person/list')}
            text="Сотрудники"
            key="Personal"
          />
          <BreadcrumbsItem
            href={`/person/${personId}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/${personId}`)}
            text={staffRead.full_name}
            key="Petrov"
          />
          <BreadcrumbsItem
            href={`/person/compare/${personId}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/compare/${personId}`)}
            text="Отчет-соответствие"
            key="Report"
          />
          <BreadcrumbsItem
            href={`/person/compare-info/${compareId}/${personId}`}
            onClick={event =>
              this.onBreadcumbsClick(event, `/person/compare-info/${compareId}/${personId}`)
            }
            text={compareRead.getIn(['profile', 'name'])}
            key="It"
          />
        </BreadcrumbsStateless>
      );

      const orderOptions = [
        {
          label: 'Все паттерны',
          value: 0
        },
        {
          label: 'Несовпадающие паттерны',
          value: 1
        }
      ];

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader breadcrumbs={breadcrumbs} actions={this.getPageHeaderButtonsDrawer()}>
                {compareRead.getIn(['profile', 'name'])}
              </PageHeader>
            </GridColumn>
            <GridColumn medium={6}>
              <div className="media-print-hide">
                <Label>Показать</Label>
                <SelectWrapper marginTop="5px">
                  <Select
                    className="single-select"
                    defaultOptions={orderOptions[0]}
                    options={orderOptions}
                    isSearchable={false}
                    onChange={this.onMismatchedChange}
                    placeholder="Все паттерны"
                    value={orderOptions[mismatchedSelector]}
                  />
                </SelectWrapper>
              </div>
            </GridColumn>
            <GridColumn medium={12}>
              <div>
                <Result
                  backgroundColor={this.getProgressColor(compareRead.get('conformance_value'))}
                >
                  Соответствие профилю — {compareRead.get('conformance_value')} %
                </Result>
                <Result
                  backgroundColor={this.getProgressColor(compareRead.get('important_conformance_value'))}
                >
                  Соответствие по значимым паттернам — {compareRead.get('important_conformance_value')} %
                </Result>
              </div>
              {sections.map(section => this.renderSection(section))}
            </GridColumn>
          </Grid>
        </Container>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{compareRead.getIn(['profile', 'name'])}</title>
        </Helmet>

        {content}

        {compareInfo.modal.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Отправить отчет-соответствие"
            onClose={this.onCloseModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.onSendModal,
                isLoading: compareInfo.loading,
                type: 'submit'
              },
              {
                text: 'Отменить',
                onClick: this.onCloseModal
              }
            ]}
            width="small"
          >
            <p>Укажите e-mail, на который необходимо отправить отчет-соответствие</p>
            <Form name="layout-example" onSubmit={this.onSendModal} onReset={() => {}} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!emailValid}
                    label="Email"
                    name="email"
                    onChange={this.onEmailChange}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={compareInfo.email}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.onRemoveFlag(name)}>
          {compareInfo.flag.map(id => (
            <Flag
              description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
              isDismissAllowed
              id={id}
              icon={
                id > 0 ? (
                  <Tick label="Success" />
                ) : (
                  <Error label="Error" primaryColor={colors.R300} />
                )
              }
              key={`${id}`}
              title={id > 0 ? 'Отчет отправлен' : 'Ошибка'}
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

CompareInfo.propTypes = {
  changeAddsModal: PropTypes.func,
  changeCompareInfoEmail: PropTypes.func,
  changeCompareInfoFlag: PropTypes.func,
  changeCompareInfoModal: PropTypes.func,
  changeMismatched: PropTypes.func,
  company: PropTypes.object,
  compareInfo: PropTypes.object,
  compareRead: PropTypes.object,
  getCompareRead: PropTypes.func,
  getStaffRead: PropTypes.func,
  mismatchedSelector: PropTypes.number,
  sections: PropTypes.object,
  staffLoading: PropTypes.bool,
  staffRead: PropTypes.object,
  sendCompareInfo: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeAddsModal: value => dispatch(changeAddsModal(value)),
    changeCompareInfoEmail: value => dispatch(changeCompareInfoEmail(value)),
    changeCompareInfoFlag: value => dispatch(changeCompareInfoFlag(value)),
    changeCompareInfoModal: value => dispatch(changeCompareInfoModal(value)),
    changeMismatched: value => dispatch(changeMismatchedPatterns(value)),
    getCompareRead: value => dispatch(compareRead(value)),
    getStaffRead: value => dispatch(staffRead(value)),
    sendCompareInfo: value => dispatch(sendCompareInfo(value))
  };
}

const mapStateToProps = createStructuredSelector({
  company: makeSelectCompany(),
  compareInfo: makeSelectCompareInfo(),
  compareRead: makeSelectCompareRead(),
  mismatchedSelector: makeSelectMismatchedSelector(),
  sections: makeSelectCompareReadSections(),
  staffLoading: makeSelectStaffLoading(),
  staffRead: makeSelectStaffRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(CompareInfo);
