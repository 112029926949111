import React, { Component } from 'react';
import uniqid from 'uniqid';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import Rouble from 'components/Rouble';

import NameWrapper from './styled/NameWrapper';

class ClientsTable extends Component {
  generateHead = () => ({
    cells: [
      {
        key: 'name',
        content: 'Название компании',
        isSortable: false,
        width: 25
      },
      {
        key: 'used_reports_count',
        content: 'Получено отчетов',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'remaining_reports_count',
        content: 'Доступно отчетов',
        shouldTruncate: true,
        isSortable: false,
        width: 25
      },
      {
        key: 'paid_total',
        content: 'Оплачено услуг',
        shouldTruncate: true,
        width: 25
      }
    ]
  });

  generateRows = data =>
    data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: uniqid(),
          content: <NameWrapper>{item.name}</NameWrapper>
        },
        {
          key: uniqid(),
          content: item.used_reports_count.toLocaleString()
        },
        {
          key: uniqid(),
          content: item.remaining_reports_count.toLocaleString()
        },
        {
          key: uniqid(),
          content: (
            <NameWrapper>
              {item.paid_total.toLocaleString()} <Rouble>₽</Rouble>
            </NameWrapper>
          )
        }
      ]
    }));

  render() {
    const { data, isLoading } = this.props;

    return (
      <DynamicTable
        head={this.generateHead()}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        onSetPage={() => {}}
        onSort={() => {}}
        rows={this.generateRows(data)}
      />
    );
  }
}

export default ClientsTable;
