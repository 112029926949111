import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';

import Container from './styled/Container';
import P from './styled/P';
import Title from './styled/Title';

class TestFinish extends React.Component {
  render() {
    return (
      <Container>
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <Title>Спасибо!</Title>

            <P>
              Опрос успешно завершен. <br />
              Результаты отправлены вашему HR-менеджеру. <br />
            </P>
          </GridColumn>
        </Grid>
      </Container>
    );
  }
}

export default TestFinish;
