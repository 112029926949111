import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';

const Wrapper = styled.section`
  display: flex;
  padding: 100px 0;
  width: 100%;

  @media (max-width: 1020px) {
    padding: 0;
  }
`;

const Inner = styled.div`
  background-color: #fafafc;
  display: flex;
  margin: 0 auto;
  padding: 62px 100px 59px;
  width: 960px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    padding: 62px 15px 59px;
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    flex-direction: column;
    padding: 62px 15px 59px;
    width: calc(100% - 30px);
  }
`;

const Title = styled.p`
  font-size: 24px;
  color: #172b4d;
  letter-spacing: 0.33px;
  line-height: 28px;
  width: 500px;

  @media (max-width: 1020px) {
    font-size: 19px;
    line-height: 22px;
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: #172b4d;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 23px;
  width: 500px;

  @media (max-width: 1020px) {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
`;

const LeftBlock = styled.div`
  width: 500px;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const RightBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 560px;

  @media (max-width: 1020px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 260px;

  @media (max-width: 1020px) {
    height: 32px;
    width: 100% !important;
  }
`;

const Banner = ({ onClick }) => (
  <Wrapper>
    <Inner>
      <LeftBlock>
        <Title>Начните ускорять найм правильных людей бесплатно</Title>
      </LeftBlock>
      <RightBlock>
        <StyledButton appearance="primary" onClick={onClick}>
          Оставить заявку
        </StyledButton>
      </RightBlock>
    </Inner>
  </Wrapper>
);

export default Banner;
