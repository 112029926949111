import { createSelector } from 'reselect';

const selectGlobal = state => state.get('partnerAuth');

const makeSelectLoginError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['login', 'error'])
  );

const makeSelectLoginFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['login', 'flags']).toJS()
  );

const makeSelectLoginForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['login', 'form']).toJS()
  );

const makeSelectLoginLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['login', 'loading'])
  );

const makeSelectRegistrationError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'error'])
  );

const makeSelectRegistrationFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'flags']).toJS()
  );

const makeSelectRegistrationForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'form']).toJS()
  );

const makeSelectRegistrationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'loading'])
  );

const makeSelectRegistrationPasscodeSent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['registration', 'passcodeSent'])
  );

const makeSelectOtpLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['otp', 'loading'])
  );

const makeSelectResetError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['reset', 'error'])
  );

const makeSelectResetForm = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['reset', 'form']).toJS()
  );

const makeSelectResetFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['reset', 'flags']).toJS()
  );

const makeSelectResetLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['reset', 'loading'])
  );

export {
  makeSelectLoginError,
  makeSelectLoginFlags,
  makeSelectLoginForm,
  makeSelectLoginLoading,
  makeSelectRegistrationError,
  makeSelectRegistrationFlags,
  makeSelectRegistrationForm,
  makeSelectRegistrationLoading,
  makeSelectRegistrationPasscodeSent,
  makeSelectOtpLoading,
  makeSelectResetError,
  makeSelectResetForm,
  makeSelectResetFlags,
  makeSelectResetLoading
};
