import React, { Fragment } from 'react';
import StaffRootView from 'navigation/routes/StaffRootView';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfilePage from 'pages/IndividualProfile/Profile';

const IndividualProfileView = () => (
  <Fragment>
    <StaffRootView />
    <SetActiveView id="individual/index" />
    <ProfilePage />
  </Fragment>
);

export default IndividualProfileView;
