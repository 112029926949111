import React from 'react';
import styled from 'styled-components';

const CloseWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  outline: none;
`;

const Close = props => (
  <CloseWrapper {...props}>
    <svg width="6px" height="6px" viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-100.000000, -22.000000)" fill="#172B4D">
          <g>
            <path d="M105.770203,26.6622694 C106.076599,26.9686857 106.076599,27.4639673 105.770203,27.7703837 C105.617396,27.9232 105.416789,28 105.216182,28 C105.015574,28 104.814967,27.9232 104.662161,27.7703837 L103.000098,26.1082122 L101.337251,27.7703837 C101.184445,27.9232 100.983838,28 100.78323,28 C100.582623,28 100.382799,27.9232 100.22921,27.7703837 C99.9235968,27.4639673 99.9235968,26.9686857 100.22921,26.6622694 L101.891272,25.000098 L100.22921,23.3379265 C99.9235968,23.0315102 99.9235968,22.5354449 100.22921,22.2298122 C100.535606,21.9233959 101.031639,21.9233959 101.337251,22.2298122 L103.000098,23.8919837 L104.662161,22.2298122 C104.968557,21.9233959 105.463806,21.9233959 105.770203,22.2298122 C106.076599,22.5354449 106.076599,23.0315102 105.770203,23.3379265 L104.10814,25.000098 L105.770203,26.6622694 Z" />
          </g>
        </g>
      </g>
    </svg>
  </CloseWrapper>
);

export default Close;
