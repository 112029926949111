import { fromJS } from 'immutable';

import {
  CHANGE_EMAIL,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_PHONE_NUMBER,
  CHANGE_GENDER,
  CHANGE_PASSWORD_ONE,
  CHANGE_PASSWORD_TWO,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_READ_REQUEST,
  USER_READ_SUCCESS,
  USER_READ_FAIL
} from './constants';

const initialState = fromJS({
  id: 0,
  email: '',
  name: '',
  surname: '',
  phoneNumber: '',
  gender: 0,
  passwordOne: '',
  passwordTwo: '',
  loading: true,
  userLoading: false,
  userFlags: [],
  passwordLoading: false,
  passwordFlags: [],
  user: {
    email: '',
    name: '',
    surname: '',
    gender: 0,
    phoneNumber: '',
    is_tested: null
  },
  userReadLoading: true,
});

function individualProfileReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_EMAIL:
      return state.set('email', action.email);
    case CHANGE_NAME:
      return state.set('name', action.name);
    case CHANGE_SURNAME:
      return state.set('surname', action.surname);
    case CHANGE_PHONE_NUMBER:
      return state.set('phoneNumber', action.phoneNumber);
    case CHANGE_GENDER:
      return state.set('gender', action.gender);
    case CHANGE_PASSWORD_ONE:
      return state.set('passwordOne', action.passwordOne);
    case CHANGE_PASSWORD_TWO:
      return state.set('passwordTwo', action.passwordTwo);
    case UPDATE_PASSWORD_REQUEST:
      return state.set('passwordLoading', true);
    case UPDATE_PASSWORD_SUCCESS:
      return state
        .set('passwordOne', '')
        .set('passwordTwo', '')
        .set('passwordFlags', fromJS([1]))
        .set('passwordLoading', false);
    case UPDATE_PASSWORD_FAIL:
      return state
        .set('passwordOne', '')
        .set('passwordTwo', '')
        .set('passwordFlags', fromJS([-1]))
        .set('passwordLoading', false);
    case UPDATE_USER_REQUEST:
      return state.set('userLoading', true);
    case UPDATE_USER_SUCCESS:
      return state
        .setIn(['user', 'email'], state.get('email'))
        .setIn(['user', 'name'], state.get('name'))
        .setIn(['user', 'gender'], state.get('gender'))
        .setIn(['user', 'surname'], state.get('surname'))
        .setIn(['user', 'phoneNumber'], state.get('phoneNumber'))
        .set('userLoading', false);
    case UPDATE_USER_FAIL:
      return state.set('userLoading', false);
    case USER_READ_REQUEST:
      return state.set('userReadLoading', true);
    case USER_READ_SUCCESS:
      return state
        .setIn(['user', 'email'], action.email)
        .setIn(['user', 'name'], action.first_name)
        .setIn(['user', 'gender'], action.gender)
        .setIn(['user', 'surname'], action.last_name)
        .setIn(['user', 'phoneNumber'], action.phone_number)
        .setIn(['user', 'is_tested'], action.is_tested)
        .set('email', action.email)
        .set('name', action.first_name)
        .set('gender', action.gender)
        .set('surname', action.last_name)
        .set('phoneNumber', action.phone_number)
        .set('id', parseInt(action.pk, 10))
        .set('userReadLoading', false);
    case USER_READ_FAIL:
      return state
        .setIn(['user', 'email'], '')
        .setIn(['user', 'name'], '')
        .setIn(['user', 'gender'], '')
        .setIn(['user', 'surname'], '')
        .setIn(['user', 'phoneNumber'], '')
        .set('email', action.email)
        .set('name', action.first_name)
        .set('gender', action.gender)
        .set('surname', action.last_name)
        .set('phoneNumber', action.phone_number)
        .set('id', 0)
        .set('userReadLoading', false);
    default:
      return state;
  }
}

export default individualProfileReducer;
