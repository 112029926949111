import { fromJS } from 'immutable';

import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CHANGE_USER_FIELD,
  CHANGE_USER_PASSWORD_FIELD,
  CHANGE_FLAGS,
  UPDATE_USER_COMMON,
  UPDATE_USER_COMMON_SUCCESS,
  UPDATE_USER_COMMON_FAIL,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAIL,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAIL
} from './constants';

const initialState = fromJS({
  user: {
    error: null,
    flags: [],
    form: {
      pk: 0,
      phone_number: '',
      email: '',
      first_name: '',
      last_name: '',
      referral_code: ''
    },
    formPassword: {
      current_password: '',
      new_password: ''
    },
    read: {
      pk: 0,
      phone_number: '',
      email: '',
      first_name: '',
      last_name: '',
      referral_code: ''
    },
    loading: false,
    updateLoading: false
  }
});

function partnerUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return state
        .setIn(['user', 'loading'], true)
        .setIn(
          ['user', 'form'],
          fromJS({
            pk: 0,
            phone_number: '',
            email: '',
            first_name: '',
            last_name: '',
            referral_code: ''
          })
        )
        .setIn(
          ['user', 'read'],
          fromJS({
            pk: 0,
            phone_number: '',
            email: '',
            first_name: '',
            last_name: '',
            referral_code: ''
          })
        );
    case GET_USER_SUCCESS:
      return state
        .setIn(['user', 'form'], fromJS(action.user))
        .setIn(['user', 'read'], fromJS(action.user))
        .setIn(['user', 'loading'], false);
    case GET_USER_FAIL:
      return state.setIn(['user', 'error'], action.error).setIn(['user', 'loading'], false);
    case CHANGE_USER_FIELD:
      return state.setIn(['user', 'form', action.field], action.text);
    case CHANGE_USER_PASSWORD_FIELD:
      return state.setIn(['user', 'formPassword', action.field], action.text);
    case CHANGE_FLAGS:
      return state.setIn(['user', 'flags'], fromJS(action.flags));
    case UPDATE_USER_COMMON:
      return state.setIn(['user', 'updateLoading'], true).setIn(['user', 'flags'], fromJS([]));
    case UPDATE_USER_COMMON_SUCCESS:
      return state
        .setIn(['user', 'read', 'phone_number'], state.getIn(['user', 'form', 'phone_number']))
        .setIn(['user', 'read', 'first_name'], state.getIn(['user', 'form', 'first_name']))
        .setIn(['user', 'read', 'last_name'], state.getIn(['user', 'form', 'last_name']))
        .setIn(['user', 'updateLoading'], false)
        .setIn(['user', 'flags'], fromJS([1]));
    case UPDATE_USER_COMMON_FAIL:
      return state.setIn(['user', 'updateLoading'], false).setIn(['user', 'flags'], fromJS([-1]));
    case UPDATE_USER_EMAIL:
      return state.setIn(['user', 'updateLoading'], true).setIn(['user', 'flags'], fromJS([]));
    case UPDATE_USER_EMAIL_SUCCESS:
      return state
        .setIn(['user', 'read', 'email'], state.getIn(['user', 'form', 'email']))
        .setIn(['user', 'updateLoading'], false)
        .setIn(['user', 'flags'], fromJS([1]));
    case UPDATE_USER_EMAIL_FAIL:
      return state.setIn(['user', 'updateLoading'], false).setIn(['user', 'flags'], fromJS([-1]));
    case UPDATE_USER_PASSWORD:
      return state.setIn(['user', 'updateLoading'], true).setIn(['user', 'flags'], fromJS([]));
    case UPDATE_USER_PASSWORD_SUCCESS:
      return state
        .setIn(['user', 'formPassword', 'current_password'], '')
        .setIn(['user', 'formPassword', 'new_password'], '')
        .setIn(['user', 'updateLoading'], false)
        .setIn(['user', 'flags'], fromJS([1]));
    case UPDATE_USER_PASSWORD_FAIL:
      return state
        .setIn(['user', 'formPassword', 'current_password'], '')
        .setIn(['user', 'formPassword', 'new_password'], '')
        .setIn(['user', 'updateLoading'], false)
        .setIn(['user', 'flags'], fromJS([-1]));
    default:
      return state;
  }
}

export default partnerUserReducer;
