import { fromJS } from 'immutable';

import {
  CHANGE_NAME,
  CHANGE_PHONE,
  SEND_LEAD_REQUEST,
  SEND_LEAD_REQUEST_SUCCESS,
  SEND_LEAD_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: false,
  name: '',
  phone: ''
});

function promoReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_NAME:
      return state.set('name', action.name);
    case CHANGE_PHONE:
      return state.set('phone', action.phone);
    case SEND_LEAD_REQUEST:
      return state.set('loading', true);
    case SEND_LEAD_REQUEST_SUCCESS:
      return state.set('loading', false);
    case SEND_LEAD_REQUEST_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default promoReducer;
