import styled from 'styled-components';
import CursorIcon from './cursor.svg';

const Cursor = styled.div`
  mask: url(${CursorIcon}) no-repeat top center;
  background-color: ${props => (props.isCritical ? '#00875A' : '#7A869A')};
  background-repeat: no-repeat;
  background-position: top center;
  content: '';
  cursor: pointer;
  color: #7a869a;
  display: block;
  height: 17px;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: -23px;
  transform: translateX(-50%);
  transition: all 0.3s ease-out;
  width: 11px;
  touch-action: none;
`;

export default Cursor;
