import styled from 'styled-components';

const Tooltip = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: default;
  left: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
  margin-top: 18px;
  opacity: ${props => (props.isOpened ? '1' : '0')};
  padding: 15px 20px;
  position: absolute;
  text-align: left;
  top: 0;
  transition: all 0.3s ease-out;
  width: ${props => (props.isBig ? '260px' : '140px')};
  z-index: ${props => (props.isOpened ? '1000' : '-1')};
`;

export default Tooltip;
