import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Page from '@atlaskit/page';
import Header from 'promo/Header';
import Footer from 'promo/Footer';
import Spinner from 'components/Spinner';

import reducer from './reducer';
import saga from './saga';

import { documentsList, documentRead } from './actions';
import { makeSelectLoading, makeSelectDocuments, makeSelectActiveDocument } from './selectors';

import Container from './styled/Container';
import Flex from './styled/Flex';
import Left from './styled/Left';
import Link from './styled/Link';
import MenuItem from './styled/MenuItem';
import Right from './styled/Right';

class Documents extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;

    if (!isUndefined(id)) {
      this.props.documentRead({
        id
      });
    }

    if (this.props.documents.length === 0) {
      this.props.documentsList();
    }
  }

  onMenuItemClick = event => {
    this.props.history.push(`/documents/${event.target.id}`);
    this.props.documentRead({
      id: event.target.id
    });
  };

  render() {
    const { activeDocument, documents, loading } = this.props;

    let content = (
      <Flex>
        <Left>
          {documents.map(documentItem => {
            if (isNull(documentItem) || isNull(activeDocument)) {
              return null;
            }

            return (
              <MenuItem key={documentItem.id} active={documentItem.id === activeDocument.id}>
                <Link id={documentItem.id} onClick={this.onMenuItemClick}>
                  {documentItem.title}
                </Link>
              </MenuItem>
            );
          })}
        </Left>
        <Right>
          <Spinner />
        </Right>
      </Flex>
    );

    if (!loading && !isNull(activeDocument) && documents.length > 0) {
      content = (
        <Flex>
          <Left>
            {documents.map(documentItem => {
              return (
                <MenuItem key={documentItem.id} active={documentItem.id === activeDocument.id}>
                  <Link id={documentItem.id} onClick={this.onMenuItemClick}>
                    {documentItem.title}
                  </Link>
                </MenuItem>
              );
            })}
          </Left>
          <Right dangerouslySetInnerHTML={{ __html: activeDocument.content }} />
        </Flex>
      );
    }

    return (
      <Page>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <title>Правовая информация</title>
        </Helmet>
        <Header withLinks />
        <Container>
          <h1>Правовая информация</h1>
          {content}
        </Container>
        <Footer />
      </Page>
    );
  }
}

Documents.propTypes = {
  activeDocument: PropTypes.object,
  documents: PropTypes.array,
  documentsList: PropTypes.func,
  documentRead: PropTypes.func,
  loading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    documentsList: () => dispatch(documentsList()),
    documentRead: value => dispatch(documentRead(value))
  };
}

const mapStateToProps = createStructuredSelector({
  activeDocument: makeSelectActiveDocument(),
  loading: makeSelectLoading(),
  documents: makeSelectDocuments()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'documents', reducer });
const withSaga = injectSaga({ key: 'documents', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Documents);
