import styled from 'styled-components';

const Subtitle = styled.p`
  color: #172b4d;
  font-size: 24px;
  letter-spacing: 0.33px;
  line-height: 28px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  @media (max-width: 1020px) {
    font-size: 19px;
    line-height: 22px;
    width: 100%;
  }
`;

export default Subtitle;
