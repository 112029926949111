import styled from 'styled-components';

const MetaTitle = styled.div`
  color: #172b4d;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.34px;
  line-height: 24px;
  align-items: center;
  display: flex;

  & h4 {
    font-weight: 600;
    margin-top: 0;
  }
`;

export default MetaTitle;
