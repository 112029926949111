import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfilePage from 'pages/Profile';

const ProfileView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfilePage />
  </Fragment>
);

export default ProfileView;
