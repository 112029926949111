import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import isUndefined from 'lodash/isUndefined';

import Cursor from './styled/Cursor';
import ProgressAfter from './styled/ProgressAfter';
import ProgressBefore from './styled/ProgressBefore';
import ProgressLine from './styled/ProgressLine';

class Progress extends React.PureComponent {
  state = {
    before: this.props.before,
    after: this.props.after,
    enableScrollLock: false,
    mouseDownBefore: false,
    mouseEnterBefore: true,
    mouseDownAfter: false,
    mouseEnterAfter: true
  };

  onMouseDownBefore = () => {
    document.addEventListener('mouseup', this.onMouseUpBefore, false);
    document.addEventListener('mousemove', this.onMouseMoveBefore, false);
  };

  onMouseMoveBefore = e => {
    e.preventDefault();
    const minX = this.parentRef.getBoundingClientRect().left;
    const maxX = this.parentRef.getBoundingClientRect().right;
    let currentX = e.screenX;

    // It's mobile
    if (isUndefined(currentX)) {
      this.setState({
        enableScrollLock: true
      });
      currentX = e.changedTouches[0].clientX;
      document.addEventListener('touchend', this.onMouseUpBefore, false);
    }

    if (window.screenX < 0 && currentX < 0) {
      currentX = window.screenX * -1 - currentX * -1;
    } else if (window.screenX < currentX) {
      currentX = currentX - window.screenX;
    }

    let result = (100 * (currentX - minX)) / (maxX - minX);
    if (currentX <= minX) {
      result = 0;
    } else if (currentX >= maxX) {
      result = 100;
    }

    if (result >= 100 - this.state.after - 5) {
      result = 100 - this.state.after - 5;
    }

    this.setState({
      before: result
    });
  };

  onMouseUpBefore = () => {
    this.setState({
      enableScrollLock: false
    });

    const { patternId, programId } = this.props;
    const { before } = this.state;

    this.props.onBeforeChange(programId, patternId, Math.trunc(before));

    document.removeEventListener('mouseup', this.onMouseUpBefore);
    document.removeEventListener('mousemove', this.onMouseMoveBefore);
    document.removeEventListener('touchend', this.onMouseUpBefore);
  };

  onMouseDownAfter = () => {
    document.addEventListener('mouseup', this.onMouseUpAfter);
    document.addEventListener('mousemove', this.onMouseMoveAfter);
  };

  onMouseMoveAfter = e => {
    const minX = this.parentRef.getBoundingClientRect().left;
    const maxX = this.parentRef.getBoundingClientRect().right;
    let currentX = e.screenX;

    // It's mobile
    if (isUndefined(currentX)) {
      this.setState({
        enableScrollLock: true
      });
      currentX = e.changedTouches[0].clientX;
      document.addEventListener('touchend', this.onMouseUpAfter, false);
    }

    if (window.screenX < 0 && currentX < 0) {
      currentX = window.screenX * -1 - currentX * -1;
    } else if (window.screenX < currentX) {
      currentX = currentX - window.screenX;
    }

    let result = 100 - (100 * (currentX - minX)) / (maxX - minX);
    if (currentX <= minX) {
      result = 100;
    } else if (currentX >= maxX) {
      result = 0;
    }

    if (100 - result <= this.state.before + 5) {
      result = 100 - this.state.before - 5;
    }

    this.setState({
      after: result
    });
  };

  onMouseUpAfter = () => {
    this.setState({
      enableScrollLock: false
    });

    const { patternId, programId } = this.props;
    const { after } = this.state;

    this.props.onAfterChange(programId, patternId, 100 - Math.trunc(after));

    document.removeEventListener('mouseup', this.onMouseUpAfter);
    document.removeEventListener('touchend', this.onMouseUpAfter);
    document.removeEventListener('mousemove', this.onMouseMoveAfter);
  };

  render() {
    const { after, before, enableScrollLock } = this.state;

    const { isCritical } = this.props;

    return (
      <div
        ref={node => {
          this.parentRef = node;
        }}
      >
        {enableScrollLock && <ScrollLock />}
        <ProgressLine isCritical={isCritical} progressBefore={before} progressAfter={after}>
          <ProgressBefore isCritical={isCritical} width={before}>
            <Cursor
              isCritical={isCritical}
              onMouseDown={this.onMouseDownBefore}
              onTouchMove={this.onMouseMoveBefore}
            />
            {Math.trunc(before)}%
          </ProgressBefore>
          <ProgressAfter isCritical={isCritical} width={Math.min(100, after)}>
            <Cursor
              isCritical={isCritical}
              onMouseDown={this.onMouseDownAfter}
              onTouchMove={this.onMouseMoveAfter}
            />
            {100 - Math.trunc(after)}%
          </ProgressAfter>
        </ProgressLine>
      </div>
    );
  }
}

Progress.propTypes = {
  after: PropTypes.number.isRequired,
  before: PropTypes.number.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  onBeforeChange: PropTypes.func.isRequired,
  isCritical: PropTypes.bool.isRequired,
  patternId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired
};

export default Progress;
