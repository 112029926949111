import { call, put, select, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import isNull from 'lodash/isNull';
import apiUrl from 'utils/serverConfig';

import { DOCUMENTS_LIST_REQUEST, DOCUMENT_READ_REQUEST } from './constants';

import {
  documentRead,
  documentReadSuccess,
  documentReadFail,
  documentsListSuccess,
  documentsListFail
} from './actions';

import { makeSelectActiveDocument, makeSelectActiveDocumentId } from './selectors';

function* documentsListSaga() {
  const url = `${apiUrl}/api/managers/documents/`;

  try {
    const request = yield call(axios, url);

    yield put(
      documentsListSuccess({
        documents: request.data
      })
    );

    if (isNull(yield select(makeSelectActiveDocument()))) {
      yield put(
        documentRead({
          id: request.data[0].id
        })
      );
    }
  } catch (e) {
    yield put(documentsListFail());
  }
}

function* documentReadSaga() {
  const id = yield select(makeSelectActiveDocumentId());
  const url = `${apiUrl}/api/managers/documents/${id}/`;

  try {
    const request = yield call(axios, url);

    yield put(
      documentReadSuccess({
        activeDocument: request.data
      })
    );
  } catch (e) {
    yield put(documentReadFail());
  }
}

export default function* documentsPageSaga() {
  yield takeLeading(DOCUMENTS_LIST_REQUEST, documentsListSaga);
  yield takeLeading(DOCUMENT_READ_REQUEST, documentReadSaga);
}
