import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ContainerViews from 'navigation/routes/Partner/Clients/ContainerView';
import RootViews from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import injectReducer from 'utils/injectReducer';
import reducer from 'pages/PartnerProfile/Clients/reducer';
import InformationPage from 'pages/PartnerProfile/Clients/View/Information';

import {
  makeSelectClientLoading,
  makeSelectClientRead
} from 'pages/PartnerProfile/Clients/selectors';

class ClientInformationBase extends Component {
  render() {
    const id = parseInt(this.props.match.params.id, 10);
    const { client, loading } = this.props;

    let container = <div />;

    if (!loading && client.id !== 0) {
      container = <ContainerViews clientId={client.id} clientName={client.name} />;
    }

    return (
      <Fragment>
        <RootViews />
        {container}
        <SetActiveView id={`partner/client/${id}`} />
        <InformationPage />
      </Fragment>
    );
  }
}

ClientInformationBase.propTypes = {
  client: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  client: makeSelectClientRead(),
  loading: makeSelectClientLoading()
});

const withConnect = connect(mapStateToProps);
const withReducer = injectReducer({ key: 'partnerClients', reducer });

export default compose(
  withRouter,
  withReducer,
  withConnect
)(ClientInformationBase);
