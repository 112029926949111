import React, { Fragment } from 'react';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import TrayIcon from '@atlaskit/icon/glyph/tray';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';

import Item from './styled/Item';
import Text from './styled/Text';

class Create extends React.Component {
  onItemClick = pathname => {
    this.props.onItemClick(pathname);
  };

  render() {
    return (
      <Fragment>
        <Item
          onClick={() =>
            this.onItemClick({
              pathname: '/invites',
              state: { addStaffModals: [1] }
            })
          }
        >
          <PeopleIcon />
          <Text>Пригласить на опрос</Text>
        </Item>
        <Item
          onClick={() =>
            this.onItemClick({
              pathname: '/profile/list',
              state: { addModals: [1] }
            })
          }
        >
          <TrayIcon />
          <Text>Создать профиль</Text>
        </Item>
        <Item
          onClick={() =>
            this.onItemClick({
              pathname: '/invites',
              state: { addModals: [1] }
            })
          }
        >
          <PeopleGroupIcon />
          <Text>Пригласить пользователя</Text>
        </Item>
      </Fragment>
    );
  }
}

export default Create;
