import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  & {
    cursor: pointer;

    .fb-inner {
      fill: #11203a;
      transition: all 0.3s ease-out;
    }
  }

  &:hover {
    & .fb-inner {
      fill: #0052cc;
    }
  }
`;

const Fb = () => (
  <Svg
    width="51px"
    height="51px"
    viewBox="0 0 51 51"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop" transform="translate(-1134.000000, -8689.000000)">
        <g id="11-//-footer" transform="translate(0.000000, 8587.000000)">
          <g id="social" transform="translate(1134.000000, 102.000000)">
            <g id="fb">
              <circle id="Oval-6" fill="#FFFFFF" cx="25.5" cy="25.5" r="25.5" />
              <path
                className="fb-inner"
                d="M32.7469066,35 L28.264342,35 L28.264342,28.242125 L30.7311586,28.242125 L31.1012373,26.0045 L28.264342,26.0045 L28.264342,23.841125 C28.264342,23.127875 28.4555681,22.6205 29.4780652,22.6205 L31.071991,22.6205 L31.071991,20.4875 C30.8470191,20.45825 30.3723285,20.3615 29.4713161,20.3615 C27.5905512,20.3615 25.9966254,21.6125 25.9966254,23.719625 L25.9966254,26.0045 L23.7502812,26.0045 L23.7502812,28.242125 L25.9966254,28.242125 L25.9966254,35 L19.2508436,35 C18.0067492,35 17,33.992 17,32.75 L17,19.25 C17,18.008 18.0067492,17 19.2508436,17 L32.7469066,17 C33.9932508,17 35,18.008 35,19.25 L35,32.75 C35,33.992 33.9932508,35 32.7469066,35 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default Fb;
