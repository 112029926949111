import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { Checkbox } from '@atlaskit/checkbox';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeAnswerCreateFromQuestions,
  profileCreateFromQuesitons,
  questionsList
} from 'pages/ProfileList/actions';
import {
  makeSelectProfileQuestionsName,
  makeSelectProfileLoading,
  makeSelectProfileQuestionsAnswers
} from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import ButtonWrapper from './styled/ButtonWrapper';
import Container from './styled/Container';
import MainBlock from './styled/MainBlock';
import PageHeaderBottom from './styled/PageHeaderBottom';

class ProfileCreateCompareStepTwo extends React.Component {
  constructor(props) {
    super(props);

    if (isEmpty(props.name)) {
      this.props.history.push('/profile/create/ask/1');
    }

    this.state = {
      changeFlags: [],
      nameValid: true
    };
  }

  componentDidMount() {
    const { getQuestionsList } = this.props;

    getQuestionsList();
  }

  addFlag = () => {
    this.setState({
      changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
    });
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button href="/profile/list" component={RouterLink}>
        Отмена
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onAnswerChange = event => {
    const { name, checked, value } = event.target;
    const questionId = parseInt(name.replace('question-', ''), 10);
    const answerId = parseInt(value, 10);

    this.props.changeAnswer({
      questionId,
      answerId,
      value: checked
    });
  };

  onNextQuestion = () => {
    const number = parseInt(this.props.match.params.questionNumber, 10);
    this.props.history.push(`/profile/create/ask/2/${number + 1}`);
  };

  onPrevQuestion = () => {
    const number = parseInt(this.props.match.params.questionNumber, 10);
    this.props.history.push(`/profile/create/ask/2/${number - 1}`);
  };

  handleForm = () => {
    this.props.profileCreateFromQuesitons();
  };

  renderQuestions() {
    const { answers } = this.props;
    const number = parseInt(this.props.match.params.questionNumber, 10);

    const question = answers.get(number - 1);
    return (
      <MainBlock key={question.get('id')}>
        <h4>
          Вопрос {number} из {answers.size}
        </h4>
        <p>{question.get('text')}</p>
        <Block>
          {question.get('answers').map(answer => (
            <Checkbox
              defaultChecked={answer.get('value')}
              key={answer.get('id')}
              label={answer.get('text')}
              onChange={this.onAnswerChange}
              value={answer.get('id')}
              id={question.get('id')}
              name={`question-${question.get('id')}`}
            />
          ))}
        </Block>
      </MainBlock>
    );
  }

  renderButtons() {
    const number = parseInt(this.props.match.params.questionNumber, 10);
    const { size } = this.props.answers;

    if (number > 1 && number < size) {
      return (
        <ButtonWrapper>
          <div>
            <Button appearance="default" onClick={this.onPrevQuestion}>
              Предыдущий вопрос
            </Button>
          </div>
          <div>
            <Button appearance="primary" onClick={this.onNextQuestion}>
              Следующий вопрос
            </Button>
          </div>
        </ButtonWrapper>
      );
    } else if (number === 1) {
      return (
        <ButtonWrapper>
          <div>
            <Button appearance="primary" onClick={this.onNextQuestion}>
              Следующий вопрос
            </Button>
          </div>
        </ButtonWrapper>
      );
    }

    return (
      <ButtonWrapper>
        <div>
          <Button appearance="primary" onClick={this.handleForm}>
            Далее
          </Button>
        </div>
      </ButtonWrapper>
    );
  }

  render() {
    const { answers, profileLoading } = this.props;

    let content = <Spinner />;

    if (!profileLoading && answers.size > 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/profile/list"
            onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
            text="Профили"
            key="Profile"
          />
          <BreadcrumbsItem
            href="/profile/create/ask/1"
            onClick={event => this.onBreadcumbsClick(event, '/profile/create/ask/1')}
            text="Создать с помощью опросника"
            key="Create"
          />
        </BreadcrumbsStateless>
      );

      const bottomBar = (
        <PageHeaderBottom>
          <h4>С помощью опросника</h4>
        </PageHeaderBottom>
      );

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader
              breadcrumbs={breadcrumbs}
              actions={this.getPageHeaderButtonsDrawer()}
              bottomBar={bottomBar}
            >
              Добавить профиль
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <div>
              <h4>Шаг 2 из 3</h4>
            </div>
            <div>{this.renderQuestions()}</div>
            {this.renderButtons()}
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Добавить профиль</title>
        </Helmet>

        <Container>{content}</Container>
      </Fragment>
    );
  }
}

ProfileCreateCompareStepTwo.propTypes = {
  answers: PropTypes.object,
  changeAnswer: PropTypes.func,
  changeName: PropTypes.func,
  changeDesc: PropTypes.func,
  getQuestionsList: PropTypes.func,
  name: PropTypes.string,
  profileCreateFromQuesitons: PropTypes.func,
  profileLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeAnswer: value => dispatch(changeAnswerCreateFromQuestions(value)),
    getQuestionsList: () => dispatch(questionsList()),
    profileCreateFromQuesitons: () => dispatch(profileCreateFromQuesitons())
  };
}

const mapStateToProps = createStructuredSelector({
  answers: makeSelectProfileQuestionsAnswers(),
  name: makeSelectProfileQuestionsName(),
  profileLoading: makeSelectProfileLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileCreateCompareStepTwo);
