import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from 'pages/PartnerProfile/User/reducer';
import saga from 'pages/PartnerProfile/User/saga';

import { changeUserField, updateUserEmail } from 'pages/PartnerProfile/User/actions';

import {
  makeSelectUserUpdateLoading,
  makeSelectUserForm,
  makeSelectUserRead
} from 'pages/PartnerProfile/User/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import TextField from 'components/TextField';
import FieldGroup from 'pages/MyProfile/styled/FieldGroup';
import Field from 'pages/MyProfile/styled/Field';

class EmailUser extends Component {
  state = {
    formValid: {
      email: true
    }
  };

  onChangeFormField = event => {
    const {
      props: { changeUserField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeUserField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { updateUserEmail, user },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: user[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      updateUserEmail();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { loading, user, userRead },
      state: { formValid }
    } = this;

    return (
      <Form name="userEmail">
        <FieldGroup marginTop="23px">
          <h4>Изменение email</h4>
        </FieldGroup>
        <FieldGroup marginTop="8px">
          <p>
            Ваш текущий email <b>{userRead.email}</b>
          </p>
        </FieldGroup>
        <FieldGroup>
          <Field width="280px">
            <TextField
              isInvalid={!formValid.email}
              required
              label="Новый email"
              id="email"
              name="email"
              onChange={onChangeFormField}
              placeholder="Укажите новый адрес email"
              shouldFitContainer
              value={user.email}
            />
          </Field>
        </FieldGroup>
        <FieldGroup marginTop="40px">
          <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
            Сохранить изменения
          </Button>
        </FieldGroup>
      </Form>
    );
  };

  render() {
    const { renderForm } = this;

    return renderForm();
  }
}

EmailUser.propTypes = {
  changeUserField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateUserEmail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userRead: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeUserField: value => dispatch(changeUserField(value)),
    updateUserEmail: () => dispatch(updateUserEmail())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectUserUpdateLoading(),
  user: makeSelectUserForm(),
  userRead: makeSelectUserRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerUser', reducer });
const withSaga = injectSaga({ key: 'partnerUser', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(EmailUser);
