import { call, delay, select, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'react-router-redux';
import isUndefined from 'lodash/isUndefined';
import apiUrl from 'utils/serverConfig';

import {
  SEND_LOGIN,
  SEND_PASSCODE,
  SEND_REGISTRATION,
  SEND_OTP,
  CREATE_PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET
} from './constants';

import {
  sendLoginSuccess,
  sendLoginFail,
  sendPasscodeSuccess,
  sendPasscodeFail,
  sendRegistrationSuccess,
  sendRegistrationFail,
  sendOtpSuccess,
  sendOtpFail,
  createPasswordResetSuccess,
  createPasswordResetFail,
  confirmPasswordResetSuccess,
  confirmPasswordResetFail
} from './actions';

import { makeSelectLoginForm, makeSelectRegistrationForm, makeSelectResetForm } from './selectors';

function* sendLoginSaga() {
  const data = yield select(makeSelectLoginForm());
  const url = `${apiUrl}/api/partners/auth/login/`;
  const options = {
    method: 'post',
    data
  };

  try {
    const request = yield call(axios, url, options);
    localStorage.setItem('partnerKey', request.data.key);

    yield put(sendLoginSuccess());
    yield put(push('/partner/clients'));
  } catch (error) {
    yield put(
      sendLoginFail({
        error: error.response.data.non_field_errors.join(' ')
      })
    );
  }
}

function* sendPasscodeSaga() {
  const url = `${apiUrl}/api/partners/auth/login/phone_number`;
  const form = yield select(makeSelectRegistrationForm());
  const options = {
    method: 'post',
    data: {
      phone_number: form.phone_number
    }
  };

  try {
    yield call(axios, url, options);
    yield put(sendPasscodeSuccess());
  } catch (e) {
    yield put(sendPasscodeFail());
  }
}

function* sendRegistrationSaga({ referral }) {
  const form = yield select(makeSelectRegistrationForm());
  const url = `${apiUrl}/api/partners/auth/registration/partner`;
  const data = {
    email: form.email,
    password1: form.password,
    password2: form.password,
    phone_number: form.phone_number,
    first_name: form.first_name,
    last_name: form.last_name,
    passcode: form.passcode
  };

  if (!isUndefined(referral)) {
    data.referral_code = referral;
  }

  const options = {
    method: 'post',
    data
  };

  try {
    const request = yield call(axios, url, options);
    localStorage.setItem('partnerKey', request.data.key);
    yield put(sendRegistrationSuccess());
    yield put(push('/partner/clients'));
  } catch (e) {
    const errorMessages = e.response.data;
    let error = '';
    error += isUndefined(errorMessages.email) ? '' : `${' '}${errorMessages.email.join(' ')}`;
    error += isUndefined(errorMessages.non_field_errors) ? '' : `${' '}${errorMessages.non_field_errors.join(' ')}`;
    error += isUndefined(errorMessages.phone_number)
      ? ''
      : `${' '}${errorMessages.phone_number.join(' ')}`;
    yield put(
      sendRegistrationFail({
        error
      })
    );
  }
}

function* sendOtpSaga({ otp }) {
  const url = `${apiUrl}/api/partners/auth/login/otp`;
  const options = {
    method: 'post',
    data: {
      otp
    }
  };

  try {
    const request = yield call(axios, url, options);
    localStorage.setItem('partnerKey', request.data.key);
    yield put(sendOtpSuccess());
    yield put(push('/partner/clients'));
  } catch (e) {
    yield put(sendOtpFail());
  }
}

function* createPasswordResetSaga() {
  const url = `${apiUrl}/api/partners/auth/password/reset/`;
  const form = yield select(makeSelectResetForm());

  const options = {
    method: 'post',
    data: {
      email: form.email
    }
  };

  try {
    yield call(axios, url, options);
    yield put(createPasswordResetSuccess());
  } catch (e) {
    yield put(createPasswordResetFail());
  }
}

function* confirmPasswordResetSaga({ uid, token }) {
  const url = `${apiUrl}/api/partners/auth/password/reset/confirm/`;
  const form = yield select(makeSelectResetForm());
  const options = {
    method: 'post',
    data: {
      new_password1: form.new_password,
      new_password2: form.new_password,
      uid,
      token
    }
  };

  try {
    yield call(axios, url, options);

    yield put(confirmPasswordResetSuccess());
    yield delay(3000);
    yield put(push('/partner/login'));
  } catch (e) {
    yield put(
      confirmPasswordResetFail({
        error: 'Произошла ошибка. Попробуйте еще раз.'
      })
    );
  }
}

export default function* partnerAuthSaga() {
  yield takeLeading(SEND_LOGIN, sendLoginSaga);
  yield takeLeading(SEND_PASSCODE, sendPasscodeSaga);
  yield takeLeading(SEND_REGISTRATION, sendRegistrationSaga);
  yield takeLeading(SEND_OTP, sendOtpSaga);
  yield takeLeading(CREATE_PASSWORD_RESET, createPasswordResetSaga);
  yield takeLeading(CONFIRM_PASSWORD_RESET, confirmPasswordResetSaga);
}
