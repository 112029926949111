import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileCreateCompareStepOnePage from 'pages/ProfileCreateCompare/StepOne';

const ProfileCreateCompareStepOneView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileCreateCompareStepOnePage />
  </Fragment>
);

export default ProfileCreateCompareStepOneView;
