import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/ru';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import uniqid from 'uniqid';

import Modal from 'components/Modal';

import Button from './styled/Button';
import Flag from './styled/Flag';
import NameWrapper from './styled/NameWrapper';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const TriggerButton = <Button>...</Button>;

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'profile_name',
        content: 'Профиль',
        isSortable: true,
        width: withWidth ? 47 : undefined
      },
      {
        key: 'created_at',
        content: 'Дата создания',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 28 : undefined
      },
      {
        key: 'result',
        content: 'Соответствие',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'resultImportant',
        content: 'Соответствие по значимым параметрам',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class Table extends React.Component {
  state = {
    deleteModals: [],
    flags: []
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      deleteModals: this.state.deleteModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  closeModal = id => {
    this.setState({ deleteModals: this.state.deleteModals.filter(i => i !== id) });
  };

  onDelete = id => {
    this.addFlag(id);
    this.props.onDelete(id);
  };

  render() {
    const rowsTable = this.props.data.map((item, index) => ({
      key: `row-${index}-${item.get('id')}`,
      cells: [
        {
          key: createKey(item.getIn(['profile', 'name'])),
          content: (
            <NameWrapper>
              <Link to={`/person/compare-info/${item.get('id')}/${this.props.personId}`}>
                {item.getIn(['profile', 'name'])}
              </Link>
            </NameWrapper>
          )
        },
        {
          key: createKey(item.get('created_at')),
          content: moment(item.get('created_at')).format('DD.MM.YYYY HH:mm')
        },
        {
          key: createKey(item.get('conformance_value').toString()),
          content: `${item.get('conformance_value')}%`
        },
        {
          key: createKey(item.get('important_conformance_value').toString()),
          content: `${item.get('important_conformance_value')}%`
        },
        {
          content: (
            <DropdownMenu trigger={TriggerButton} position="bottom right">
              <DropdownItemGroup>
                <DropdownItem
                  onClick={() =>
                    this.setState({
                      deleteModals: [item.get('id')]
                    })
                  }
                >
                  Удалить
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )
        }
      ]
    }));

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          rows={rowsTable.toJS()}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {this.state.deleteModals.map(id => (
          <Modal
            key={uniqid()}
            id={id}
            heading="Удалить отчет"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Удалить',
                onClick: this.onDelete
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
          >
            <p>Уверены, что хотите удалить этот отчет?</p>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Отчет удален"
            />
          ))}
        </FlagGroup>
      </div>
    );
  }
}

export default Table;
