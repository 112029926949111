import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Page from '@atlaskit/page';
import Form from '@atlaskit/form';
import Button from '@atlaskit/button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { isRequired } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import TextField from 'components/TextField';

import {
  changeNewPassword,
  changeRecoverSent,
  newPasswordRequest
} from 'pages/RecoverPassword/actions';

import { makeSelectNewPassword, makeSelectLoading } from 'pages/RecoverPassword/selectors';

import reducer from 'pages/RecoverPassword/reducer';
import saga from 'pages/RecoverPassword/saga';

import Image from 'pages/RecoverPassword/images/pic.png';
import Image2x from 'pages/RecoverPassword/images/pic-2x.png';

import ButtonField from 'pages/RecoverPassword/styled/ButtonField';
import Field from 'pages/RecoverPassword/styled/Field';
import FieldGroup from 'pages/RecoverPassword/styled/FieldGroup';
import Flex from 'pages/RecoverPassword/styled/Flex';
import FlexItem from 'pages/RecoverPassword/styled/FlexItem';
import P from 'pages/RecoverPassword/styled/P';
import Title from 'pages/RecoverPassword/styled/Title';

class NewPassword extends Component {
  state = {
    errorFlag: [1],
    password: {
      wasChanged: false,
      isInvalid: false,
      value: ''
    },
    isFormValid: false
  };

  onChangePassword = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changeNewPassword({
      newPassword: e.target.value
    });

    if (isRequired(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      password: result,
      isFormValid: !result.isInvalid && result.wasChanged
    });
  };

  sendNewPassword = () => {
    const uid = this.props.match.params.uid;
    const token = this.props.match.params.token;

    this.props.newPasswordRequest({
      uid,
      token
    });
  };

  render() {
    const { newPassword, loading } = this.props;

    let content = (
      <Form>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={this.state.password.isInvalid}
              required
              label="Новый пароль"
              name="password"
              onBlur={this.onChangePassword}
              onChange={this.onChangePassword}
              placeholder=""
              value={newPassword}
              shouldFitContainer
              type="password"
            />
          </Field>
        </FieldGroup>

        <FieldGroup>
          <ButtonField>
            <Button
              appearance="primary"
              isDisabled={!this.state.isFormValid}
              isLoading={loading}
              onClick={this.sendNewPassword}
            >
              Продолжить
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );

    return (
      <Page>
        <Helmet>
          <title>Новый пароль</title>
        </Helmet>

        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Введите новый пароль</Title>

            {content}
          </FlexItem>
        </Flex>
      </Page>
    );
  }
}

NewPassword.propTypes = {
  changeNewPassword: PropTypes.func,
  changeRecoverSent: PropTypes.func,
  newPassword: PropTypes.string,
  loading: PropTypes.bool,
  newPasswordRequest: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeNewPassword: value => dispatch(changeNewPassword(value)),
    changeRecoverSent: value => dispatch(changeRecoverSent(value)),
    newPasswordRequest: value => dispatch(newPasswordRequest(value))
  };
}

const mapStateToProps = createStructuredSelector({
  newPassword: makeSelectNewPassword(),
  loading: makeSelectLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'recoverPassword', reducer });
const withSaga = injectSaga({ key: 'recoverPassword', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(NewPassword);
