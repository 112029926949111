import { createSelector } from 'reselect';

const selectGlobal = state => state.get('registrationCompany');

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('first_name')
  );

const makeSelectSurname = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('last_name')
  );

const makeSelectEmail = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('email')
  );

const makeSelectPhone = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('phone_number')
  );

const makeSelectPasswordOne = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('password1')
  );

const makeSelectSmsCode = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('sms_code')
  );

const makeSelectCompany = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('company_name')
  );

const makeSelectInn = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('company_inn')
  );

const makeSelectFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('flags').toJS()
  );

const makeSelectSmsFlags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('smsFlags').toJS()
  );

const makeSelectErrorMessages = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('errorMessages').toJS()
  );

export {
  makeSelectLoading,
  makeSelectName,
  makeSelectSurname,
  makeSelectEmail,
  makeSelectPhone,
  makeSelectPasswordOne,
  makeSelectSmsCode,
  makeSelectCompany,
  makeSelectInn,
  makeSelectFlags,
  makeSelectSmsFlags,
  makeSelectErrorMessages
};
