import React, { Component } from 'react';
import { injectGlobal } from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import {Redirect, Route, Switch} from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';

import CookieConsent from 'react-cookie-consent';

import MobileHeader from '@atlaskit/mobile-header';
import 'css/css-reset.css';

import LayoutTestPage from 'navigation/components/LayoutTestPage';

import {
  LayoutManagerWithViewController,
  NavigationProvider,
  light,
  UIControllerSubscriber
} from '@atlaskit/navigation-next';

import DefaultGlobalNavigation from 'navigation/components/DefaultGlobalNavigation';
import IndividualGlobalNavigation from 'navigation/components/IndividualGlobalNavigation';
import PartnerGlobalNavigation from 'navigation/components/PartnerGlobalNavigation';
import Wrapper from 'navigation/components/Wrapper';
import { isGuest } from 'utils/request';

import CompareListView from 'navigation/routes/CompareListView';
import HomeView from 'navigation/routes/HomeView';
import MyProfileView from 'navigation/routes/MyProfileView';
import PersonView from 'navigation/routes/PersonView';
import PersonReportView from 'navigation/routes/PersonReportView';
import PersonCompareView from 'navigation/routes/PersonCompareView';
import PersonCompareInfoView from 'navigation/routes/PersonCompareInfoView';
import ProfilesListView from 'navigation/routes/ProfilesListView';
import ProfileView from 'navigation/routes/ProfileView';
import ProfileEditView from 'navigation/routes/ProfileEditView';
import ProfileCreateCompareStepOneView from 'navigation/routes/ProfileCreateCompareStepOneView';
import ProfileCreateCompareStepTwoView from 'navigation/routes/ProfileCreateCompareStepTwoView';
import ProfileCreateAskStepOneView from 'navigation/routes/ProfileCreateAskStepOneView';
import ProfileCreateAskStepTwoView from 'navigation/routes/ProfileCreateAskStepTwoView';
import ProfileCreateAskStepThreeView from 'navigation/routes/ProfileCreateAskStepThreeView';
import ProfileCreateCustomView from 'navigation/routes/ProfileCreateCustomView';
import ProfileCreateAskChiefView from 'navigation/routes/ProfileCreateAskChiefView';
import SettingsEditOrganizationView from 'navigation/routes/SettingsEditOrganizationView';
import SettingsCompanyView from 'navigation/routes/SettingsCompanyView';
import SettingsUsersView from 'navigation/routes/SettingsUsersView';
import SettingsInvitesView from 'navigation/routes/SettingsInvitesView';
import SettingsBillingPlansView from 'navigation/routes/SettingsBillingPlansView';
import SettingsPaymentView from 'navigation/routes/SettingsPaymentView';
import VideoView from 'navigation/routes/VideoView';

import IndividualReportsView from 'navigation/routes/IndividualReportsView';
import IndividualVideoView from 'navigation/routes/IndividualVideoView';
import IndividualProfileView from 'navigation/routes/IndividualProfileView';
import IndividualCompareView from 'navigation/routes/IndividualCompareView';

import ClientListView from 'navigation/routes/Partner/Clients/ClientListView';
import RegistrationClientView from 'navigation/routes/Partner/Clients/RegistrationClientView';
import ClientInformationView from 'navigation/routes/Partner/Clients/Information';
import ClientManagersView from 'navigation/routes/Partner/Clients/Managers';
import ClientRequisitesView from 'navigation/routes/Partner/Clients/Requisites';
import ClientFinancesView from 'navigation/routes/Partner/Clients/Finances';
import PartnerListView from 'navigation/routes/Partner/Partners/PartnerListView';
import RegistrationPartnerView from 'navigation/routes/Partner/Partners/RegistrationPartnerView';
import PartnerInformationView from 'navigation/routes/Partner/Partners/Information';
import PartnerClientsView from 'navigation/routes/Partner/Partners/Clients';
import PartnerRequisitesView from 'navigation/routes/Partner/Partners/Requisites';
import FinancesListView from 'navigation/routes/Partner/Finances/FinancesListView';
import PartnerVideosView from 'navigation/routes/Partner/Videos/PartnerVideosView';
import RequisitesListView from 'navigation/routes/Partner/Requisites/RequisitesListView';
import RegistrationRequisitesView from 'navigation/routes/Partner/Requisites/RegistrationRequisitesView';
import EditRequisitesView from 'navigation/routes/Partner/Requisites/EditRequisitesView';
import PartnerUserView from 'navigation/routes/Partner/User/PartnerUserView';
import PartnerLogin from 'pages/PartnerProfile/Auth/Login';
import PartnerRegistration from 'pages/PartnerProfile/Auth/Registration';
import PartnerReset from 'pages/PartnerProfile/Auth/Reset';

import IndividualLogin from 'pages/IndividualProfile/Login';
import ChiefQuizView from 'pages/ChiefQuiz';
import TestStartView from 'pages/TestStart';
import TestAboutView from 'pages/TestAbout';
import TestFinishView from 'pages/TestFinish';
import TestRegistrationView from 'pages/TestRegistration';
import ErrorView from 'pages/Error404';
import NotFoundView from 'pages/NotFound';
import QuestionView from 'pages/TestQuestion';
import RegistrationView from 'pages/Registration';
import RegistrationCompanyView from 'pages/RegistrationCompany';
import LoginView from 'pages/Login';
import PromoView from 'pages/Promo';
import LastPromo from 'pages/LastPromo';
import NewPasswordView from 'pages/NewPassword';
import RecoverPasswordView from 'pages/RecoverPassword';
import DocumentsView from 'pages/Documents';
import FacebookAuth from 'pages/FacebookAuth';
import VkAuth from 'pages/VkAuth';
import GoogleAuth from 'pages/GoogleAuth';

import OnlyMobile from 'components/OnlyMobile';
import service, {serviceName} from 'utils/checkService';

injectGlobal`
  body.hide-jivo {
    html>body div#jivo-iframe-container:not(.jivo-c-mobile), jdiv {
      display: none !important;
    }
  }

  @media print {
    html>body div#jivo-iframe-container:not(.jivo-c-mobile), jdiv {
      display: none !important;
    }
  }
`;

// filling the <head>
const head = document.getElementsByTagName("head")[0];

const appendIcons = () => {
  // add favicons (depends on service)
  const createLink = (rel, sizes, hrefName, type) => {
    const link = document.createElement("link");
    link.setAttribute("rel", rel);
    link.setAttribute("sizes", sizes);
    link.setAttribute("href", `${service}/${hrefName}-${sizes}.png?v=2`);
    if(type) {
      link.setAttribute("type", type)
    }
    return link
  }

  const icons = [
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      hrefName: "apple-icon"
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      hrefName: "apple-icon"
    },
    {
      rel: "icon",
      sizes: "192x192",
      hrefName: "android-icon",
      type: "image/png"
    },
    {
      rel: "icon",
      sizes: "32x32",
      hrefName: "favicon",
      type: "image/png"
    },
    {
      rel: "icon",
      sizes: "96x96",
      hrefName: "favicon",
      type: "image/png"
    },
    {
      rel: "icon",
      sizes: "16x16",
      hrefName: "favicon",
      type: "image/png"
    },
  ];

  icons.forEach(({rel, sizes, hrefName, type}) => {
    head.appendChild(createLink(rel, sizes, hrefName, type))
  });
}

const appendManifest = () => {
  const manifest = document.createElement("link");
  manifest.setAttribute("rel", "manifest");
  manifest.setAttribute("href", `${service}/manifest.json`);

  head.appendChild(manifest);
}

const appendBrowserconfig = () => {
  const browserconfig = document.createElement("meta");
  browserconfig.setAttribute("name", "msapplication-config");
  browserconfig.setAttribute("content", `${service}/browserconfig.xml`);
  head.appendChild(browserconfig)
}

const addMSIcon = () => {
  const tileImage = document.createElement("meta");
  tileImage.setAttribute("name", "msapplication-TileImage");
  tileImage.setAttribute("content", `${service}/ms-icon-144x144.png`);
  head.appendChild(tileImage)
}

const addDataForService = () => {
  appendIcons();
  appendManifest();
  appendBrowserconfig();
  addMSIcon()
}

class App extends Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  renderLogout = () => {
    localStorage.removeItem('key');
    localStorage.removeItem('compareIds');
    window.location.replace('/signin');

    return null;
  };

  renderIndividualLogout = () => {
    localStorage.removeItem('staffKey');
    window.location.replace('/individual/login');

    return null;
  };

  renderPartnerLogout = () => {
    localStorage.removeItem('partnerKey');
    window.location.replace('/partner/login');

    return null;
  };

  renderPageWithNavigation = page => {
    if (isGuest()) {
      this.props.history.push('/signin');
    }

    addDataForService();

    return (
      <LayoutManagerWithViewController globalNavigation={DefaultGlobalNavigation}>
        <Helmet>
          <meta name="viewport" content="width=1440" />
        </Helmet>
        <OnlyMobile>
          <UIControllerSubscriber>
            {navigationUIController => (
              <MobileHeader
                drawerState={navigationUIController.state.isCollapsed ? 'none' : 'navigation'}
                navigation={isOpen => isOpen && <div />}
                menuIconLabel={'Menu'}
                pageHeading=""
                onNavigationOpen={navigationUIController.toggleCollapse}
                onDrawerClose={navigationUIController.toggleCollapse}
              />
            )}
          </UIControllerSubscriber>
        </OnlyMobile>

        <div style={{ marginBottom: 15 }}>{page}</div>
      </LayoutManagerWithViewController>
    );
  };

  renderIndividualPage = page => {
    addDataForService();

    return (
      <LayoutManagerWithViewController globalNavigation={IndividualGlobalNavigation}>
        <Helmet>
          <meta name="viewport" content="width=1440" />
        </Helmet>
        <OnlyMobile>
          <UIControllerSubscriber>
            {navigationUIController => (
              <MobileHeader
                drawerState={navigationUIController.state.isCollapsed ? 'none' : 'navigation'}
                navigation={isOpen => isOpen && <div />}
                menuIconLabel={'Menu'}
                pageHeading=""
                onNavigationOpen={navigationUIController.toggleCollapse}
                onDrawerClose={navigationUIController.toggleCollapse}
              />
            )}
          </UIControllerSubscriber>
        </OnlyMobile>

        <div style={{ marginBottom: 15 }}>{page}</div>
      </LayoutManagerWithViewController>
    );
  };

  renderPartnerPage = page => {
    addDataForService();

    return (
      <LayoutManagerWithViewController globalNavigation={PartnerGlobalNavigation}>
        <Helmet>
          <meta name="viewport" content="width=1440" />
        </Helmet>
        <OnlyMobile>
          <UIControllerSubscriber>
            {navigationUIController => (
              <MobileHeader
                drawerState={navigationUIController.state.isCollapsed ? 'none' : 'navigation'}
                navigation={isOpen => isOpen && <div />}
                menuIconLabel={'Menu'}
                pageHeading=""
                onNavigationOpen={navigationUIController.toggleCollapse}
                onDrawerClose={navigationUIController.toggleCollapse}
              />
            )}
          </UIControllerSubscriber>
        </OnlyMobile>

        <div style={{ marginBottom: 15 }}>{page}</div>
      </LayoutManagerWithViewController>
    );
  };

  renderTestPage = page => {
    const s = document.getElementById('jivosite-script');

    if (!isNull(s)) {
      try {
        s.remove();
      } catch (e) {
        console.log(e);
      }
    }

    return <LayoutTestPage page={page} />;
  };

  render() {
    return (
      <Wrapper>
        <Helmet
          titleTemplate={`%s – ${serviceName}`}
          defaultTitle={`Узнай свой потенциал – ${serviceName}`}
        >
          <meta charSet="utf-8" />
        </Helmet>
        <NavigationProvider cache={false}>
          <ThemeProvider theme={light}>
            <Switch>
              <Route exact path="/" render={() => service === "my-yadro" ? <Redirect to="/individual/login"/> : <LastPromo />} />
              <Route path="/landing" render={() => <PromoView />} />
              <Route path="/documents/:id([0-9]*)?" render={() => <DocumentsView />} />
              <Route
                path="/compare/list*"
                render={() => this.renderPageWithNavigation(<CompareListView />)}
              />
              <Route
                path="/compare"
                render={() => this.renderPageWithNavigation(<CompareListView />)}
              />
              <Route
                path="/person/list"
                render={() => this.renderPageWithNavigation(<HomeView />)}
              />
              <Route
                path="/person/:id([0-9]*)"
                render={() => this.renderPageWithNavigation(<PersonView />)}
              />
              <Route
                path="/person/report/:id([0-9]*)/:reports?"
                render={() => this.renderPageWithNavigation(<PersonReportView />)}
              />
              <Route
                path="/person/compare/:id([0-9]*)/:reports?"
                render={() => this.renderPageWithNavigation(<PersonCompareView />)}
              />
              <Route
                path="/person/compare-info/:compareId([0-9]*)/:personId([0-9]*)"
                render={() => this.renderPageWithNavigation(<PersonCompareInfoView />)}
              />
              <Route
                path="/profile/list"
                render={() => this.renderPageWithNavigation(<ProfilesListView />)}
              />
              <Route
                path="/profile/:id([0-9]*)"
                render={() => this.renderPageWithNavigation(<ProfileView />)}
              />
              <Route
                path="/profile/edit/:id([0-9]*)"
                render={() => this.renderPageWithNavigation(<ProfileEditView />)}
              />
              <Route
                path="/profile/create/compare/1"
                render={() => this.renderPageWithNavigation(<ProfileCreateCompareStepOneView />)}
              />
              <Route
                path="/profile/create/compare/2"
                render={() => this.renderPageWithNavigation(<ProfileCreateCompareStepTwoView />)}
              />
              <Route
                path="/profile/create/ask/1"
                render={() => this.renderPageWithNavigation(<ProfileCreateAskStepOneView />)}
              />
              <Route
                path="/profile/create/ask/2/:questionNumber"
                render={() => this.renderPageWithNavigation(<ProfileCreateAskStepTwoView />)}
              />
              <Route
                path="/profile/create/ask/3"
                render={() => this.renderPageWithNavigation(<ProfileCreateAskStepThreeView />)}
              />
              <Route
                path="/profile/create/custom"
                render={() => this.renderPageWithNavigation(<ProfileCreateCustomView />)}
              />
              <Route
                path="/profile/create/chief"
                render={() => this.renderPageWithNavigation(<ProfileCreateAskChiefView />)}
              />
              <Route
                path="/settings/organizations/list"
                render={() => this.renderPageWithNavigation(<SettingsCompanyView />)}
              />
              <Route
                path="/settings/organizations/:id"
                render={() => this.renderPageWithNavigation(<SettingsEditOrganizationView />)}
              />
              <Route
                path="/settings/users"
                render={() => this.renderPageWithNavigation(<SettingsUsersView />)}
              />
              <Route
                path="/invites"
                render={() => this.renderPageWithNavigation(<SettingsInvitesView />)}
              />
              <Route
                path="/settings/billing/:reports?"
                render={() => this.renderPageWithNavigation(<SettingsBillingPlansView />)}
              />
              <Route
                path="/settings/payment"
                render={() => this.renderPageWithNavigation(<SettingsPaymentView />)}
              />
              <Route
                path="/profile"
                render={() => this.renderPageWithNavigation(<MyProfileView />)}
              />
              <Route
                path="/videos/:sectionId"
                render={() => this.renderPageWithNavigation(<VideoView />)}
              />

              <Route
                path="/signup/:referral?"
                render={() => this.renderTestPage(<RegistrationCompanyView />)}
              />
              <Route path="/signin/:otp?" render={() => this.renderTestPage(<LoginView />)} />
              <Route
                path="/invite/s/:invitationCode?"
                render={() => this.renderTestPage(<TestStartView />)}
              />
              <Route
                path="/invite/m/:invitationCode"
                render={() => this.renderTestPage(<RegistrationView />)}
              />
              <Route
                path="/invite/p/:invitationCode/:questionNumber?"
                render={() => this.renderTestPage(<ChiefQuizView />)}
              />
              <Route path="/test/finish" render={() => this.renderTestPage(<TestFinishView />)} />
              <Route
                path="/test/about/:invitationCode?"
                render={() => this.renderTestPage(<TestAboutView />)}
              />
              <Route
                path="/test/registration/:invitationCode?"
                render={() => this.renderTestPage(<TestRegistrationView />)}
              />
              <Route path="/error" render={() => this.renderTestPage(<ErrorView />)} />
              <Route
                path="/test/question/:questionNumber/:invitationCode?"
                render={props => this.renderTestPage(<QuestionView {...props} />)}
              />
              <Route path="/logout" render={() => this.renderLogout()} />
              <Route path="/recover" render={() => this.renderTestPage(<RecoverPasswordView />)} />
              <Route
                path="/reset/:uid/:token"
                render={() => this.renderTestPage(<NewPasswordView />)}
              />

              <Route
                path="/individual/login"
                render={() => this.renderTestPage(<IndividualLogin />)}
              />
              <Route
                path="/individual/report"
                render={() => this.renderIndividualPage(<IndividualReportsView />)}
              />
              <Route
                path="/individual/compare"
                render={() => this.renderIndividualPage(<IndividualCompareView />)}
              />
              <Route
                path="/individual/videos/:sectionId"
                render={() => this.renderIndividualPage(<IndividualVideoView />)}
              />
              <Route path="/individual/logout" render={() => this.renderIndividualLogout()} />
              <Route
                path="/individual/profile"
                render={() => this.renderIndividualPage(<IndividualProfileView />)}
              />
              <Route path="/facebook" component={FacebookAuth} />
              <Route path="/vk" component={VkAuth} />
              <Route path="/google" component={GoogleAuth} />

              <Route
                path="/partner/clients/create"
                render={() => this.renderPartnerPage(<RegistrationClientView />)}
              />
              <Route
                path="/partner/clients/:id([0-9]*)/managers"
                render={() => this.renderPartnerPage(<ClientManagersView />)}
              />
              <Route
                path="/partner/clients/:id([0-9]*)/requisites"
                render={() => this.renderPartnerPage(<ClientRequisitesView />)}
              />
              <Route
                path="/partner/clients/:id([0-9]*)/finances"
                render={() => this.renderPartnerPage(<ClientFinancesView />)}
              />
              <Route
                path="/partner/clients/:id([0-9]*)"
                render={() => this.renderPartnerPage(<ClientInformationView />)}
              />
              <Route
                path="/partner/clients"
                render={() => this.renderPartnerPage(<ClientListView />)}
              />
              <Route
                path="/partner/partners/create"
                render={() => this.renderPartnerPage(<RegistrationPartnerView />)}
              />
              <Route
                path="/partner/partners/:id([0-9]*)/clients"
                render={() => this.renderPartnerPage(<PartnerClientsView />)}
              />
              <Route
                path="/partner/partners/:id([0-9]*)/requisites"
                render={() => this.renderPartnerPage(<PartnerRequisitesView />)}
              />
              <Route
                path="/partner/partners/:id([0-9]*)"
                render={() => this.renderPartnerPage(<PartnerInformationView />)}
              />
              <Route
                path="/partner/partners"
                render={() => this.renderPartnerPage(<PartnerListView />)}
              />
              <Route
                path="/partner/finances"
                render={() => this.renderPartnerPage(<FinancesListView />)}
              />
              <Route
                path="/partner/videos/:sectionId"
                render={() => this.renderPartnerPage(<PartnerVideosView />)}
              />
              <Route
                path="/partner/requisites/create"
                render={() => this.renderPartnerPage(<RegistrationRequisitesView />)}
              />
              <Route
                path="/partner/requisites/:id([0-9]*)"
                render={() => this.renderPartnerPage(<EditRequisitesView />)}
              />
              <Route
                path="/partner/requisites"
                render={() => this.renderPartnerPage(<RequisitesListView />)}
              />
              <Route
                path="/partner/profile"
                render={() => this.renderPartnerPage(<PartnerUserView />)}
              />
              <Route
                path="/partner/signup/:referral?"
                render={() => this.renderTestPage(<PartnerRegistration />)}
              />
              <Route
                path="/partner/login/:otp?"
                render={() => this.renderTestPage(<PartnerLogin />)}
              />
              <Route
                path="/partner/reset/:uid?/:token?"
                render={() => this.renderTestPage(<PartnerReset />)}
              />
              <Route path="/partner/logout" render={() => this.renderPartnerLogout()} />

              <Route render={() => this.renderTestPage(<NotFoundView />)} />
            </Switch>
          </ThemeProvider>
        </NavigationProvider>
        <CookieConsent debug={true}
          style={{ background: '#fff', border: '1px solid rgba(0, 0, 0, 0.05)', textAlign: 'left', color: '#172B4D' }}
          buttonStyle={{ color: '#fff', background: '#0052CC', fontSize: '14px' }}
          buttonText="Принять и закрыть"
        >
          Этот сайт использует файлы cookie, чтобы обеспечить вам наилучший клиентский опыт на нашем сайте. Используя наш сайт, вы даете согласие на использование файлов cookie. <a href={service === "my-yadro" ? "https://my-yadro.ru/policy" : "/documents/3"}>Узнать больше.</a></CookieConsent>
      </Wrapper>
    );
  }
}

App.propTypes = {
  history: PropTypes.object
};

function mapDispatchToProps() {
  return {};
}

const mapStateToProps = createStructuredSelector({});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  withConnect
)(App);
