import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AppSwitcher from '@atlaskit/app-switcher';
import GlobalNavigation from '@atlaskit/global-navigation';
import { GlobalItem } from '@atlaskit/navigation-next';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';

import IndividualGlobalDropdown from 'navigation/components/IndividualGlobalDropdown';
import HelpDropdown from 'navigation/components/HelpDropdown';

import Logo from './styled/Logo';
import NavigationWrapper from './styled/NavigationWrapper';
import {serviceName} from "../../../utils/checkService";

const appSwitcherData = {
  isHomeLinkEnabled: false,
  isMarketplaceLinkEnabled: false,
  isSiteAdminLinkEnabled: false,
  isPeopleProfileLinkEnabled: false,
  recentContainers: [],
  linkedApplications: {
    configureLink: '',
    apps: [],
    error: false
  },
  isAnonymousUser: false,
  i18n: {
    home: '',
    configure: '',
    recent: '',
    'container.confluence-space': '',
    'container.jira-project': '',
    'applinks.error': '',
    'try.lozenge': '',
    apps: 'ПЕРЕЙТИ В'
  }
};
const appSwitcherDropdownOptions = {
  position: 'right bottom'
};

const AppSwitcherComponent = itemProps => (
  <AppSwitcher
    {...appSwitcherData}
    isDropdownOpenInitially={false}
    dropdownOptions={appSwitcherDropdownOptions}
    trigger={isDropdownOpen => (
      <GlobalItem {...itemProps} icon={AppSwitcherIcon} isSelected={isDropdownOpen} />
    )}
  />
);

class IndividualGlobalNavigation extends Component {
  render() {
    return (
      <NavigationWrapper>
        <GlobalNavigation
          productIcon={Logo}
          productHref="/"
          productTooltip={serviceName}
          helpTooltip="Поддержка"
          helpItems={HelpDropdown}
          profileTooltip="Профиль"
          profileItems={IndividualGlobalDropdown}
        />
      </NavigationWrapper>
    );
  }
}

export default withRouter(IndividualGlobalNavigation);
