import styled from 'styled-components';

const TableHeader = styled.div`
  background: #fff;
  border-bottom: 2px solid #e2e3e7;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 6px;
  padding-top: 15px;
  position: absolute;
  top: 0;
  transition: none;
  width: max-content;
  z-index: 1000;
`;

export default TableHeader;
