export const GET_USER_INFO_REQUEST = 'hh/pages/settings/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_REQUEST_SUCCESS = 'hh/pages/settings/GET_USER_INFO_REQUEST_SUCCESS';
export const GET_USER_INFO_REQUEST_FAIL = 'hh/pages/settings/GET_USER_INFO_REQUEST_FAIL';

export const GET_MANAGERS_LIST_REQUEST = 'hh/pages/settings/GET_MANAGERS_LIST_REQUEST';
export const GET_MANAGERS_FILTERED_LIST_REQUEST =
  'hh/pages/settings/GET_MANAGERS_FILTERED_LIST_REQUEST';
export const GET_MANAGERS_LIST_REQUEST_SUCCESS =
  'hh/pages/settings/GET_MANAGERS_LIST_REQUEST_SUCCESS';
export const GET_MANAGERS_LIST_REQUEST_FAIL = 'hh/pages/settings/GET_MANAGERS_LIST_REQUEST_FAIL';

export const CHANGE_COMMENT_INVITE = 'hh/pages/settings/CHANGE_COMMENT_INVITE';
export const CHANGE_FLAGS_INVITE = 'hh/pages/settings/CHANGE_FLAGS_INVITE';
export const CHANGE_FULL_NAME_INVITE = 'hh/pages/settings/CHANGE_FULL_NAME_INVITE';
export const CHANGE_MODALS_INVITE = 'hh/pages/settings/CHANGE_MODALS_INVITE';
export const CHANGE_EMAIL_INVITE = 'hh/pages/settings/CHANGE_EMAIL_INVITE';
export const CHANGE_NAME_INVITE = 'hh/pages/settings/CHANGE_NAME_INVITE';
export const CHANGE_ROLE_INVITE = 'hh/pages/settings/CHANGE_ROLE_INVITE';
export const CHANGE_TEXT_INVITE = 'hh/pages/settings/CHANGE_TEXT_INVITE';

export const SEND_INVITE_REQUEST = 'hh/pages/settings/SEND_INVITE_REQUEST';
export const SEND_INVITE_REQUEST_SUCCESS = 'hh/pages/settings/SEND_INVITE_REQUEST_SUCCESS';
export const SEND_INVITE_REQUEST_FAIL = 'hh/pages/settings/SEND_INVITE_REQUEST_FAIL';

export const GET_INVITES_LIST_REQUEST = 'hh/pages/settings/GET_INVITES_LIST_REQUEST';
export const GET_INVITES_LIST_REQUEST_SUCCESS =
  'hh/pages/settings/GET_INVITES_LIST_REQUEST_SUCCESS';
export const GET_INVITES_LIST_REQUEST_FAIL = 'hh/pages/settings/GET_INVITES_LIST_REQUEST_FAIL';

export const CHANGE_DELETE_INVITE_MODALS = 'hh/pages/settings/CHANGE_DELETE_INVITE_MODALS';

export const DELETE_INVITE_REQUEST = 'hh/pages/settings/DELETE_INVITE_REQUEST';
export const DELETE_INVITE_REQUEST_SUCCESS = 'hh/pages/settings/DELETE_INVITE_REQUEST_SUCCESS';
export const DELETE_INVITE_REQUEST_FAIL = 'hh/pages/settings/DELETE_INVITE_REQUEST_FAIL';

export const LOADING_FILTER_END = 'hh/pages/settings/LOADING_FILTER_END';
export const CHANGE_NAME_FILTER = 'hh/pages/settings/CHANGE_NAME_FILTER';
export const CHANGE_INVITES_NAME_FILTER = 'hh/pages/settings/CHANGE_INVITES_NAME_FILTER';
export const CHANGE_MANAGERS_PAGE = 'hh/pages/settings/CHANGE_MANAGERS_PAGE';
export const CHANGE_INVITES_PAGE = 'hh/pages/settings/CHANGE_INVITES_PAGE';
export const CHANGE_MANAGERS_SORT = 'hh/pages/settings/CHANGE_MANAGERS_SORT';
export const CHANGE_INVITES_SORT = 'hh/pages/settings/CHANGE_INVITES_SORT';

export const GET_BILLING_PLANS_LIST_REQUEST = 'hh/pages/settings/GET_BILLING_PLANS_LIST_REQUEST';
export const GET_BILLING_PLANS_LIST_REQUEST_SUCCESS =
  'hh/pages/settings/GET_BILLING_PLANS_LIST_REQUEST_SUCCESS';
export const GET_BILLING_PLANS_LIST_REQUEST_FAIL =
  'hh/pages/settings/GET_BILLING_PLANS_LIST_REQUEST_FAIL';

export const OPEN_PROLONG_PLAN_MODAL = 'hh/pages/settings/OPEN_PROLONG_PLAN_MODAL';
export const CLOSE_PROLONG_PLAN_MODAL = 'hh/pages/settings/CLOSE_PROLONG_PLAN_MODAL';

export const PROLONG_PLAN_REQUEST = 'hh/pages/settings/PROLONG_PLAN_REQUEST';
export const PROLONG_PLAN_REQUEST_SUCCESS = 'hh/pages/settings/PROLONG_PLAN_REQUEST_SUCCESS';
export const PROLONG_PLAN_REQUEST_FAIL = 'hh/pages/settings/PROLONG_PLAN_REQUEST_FAIL';

export const CHANGE_PROLONG_FLAG = 'hh/pages/settings/CHANGE_PROLONG_FLAG';
export const CHECK_PROLONG_ORDER_REQUEST = 'hh/pages/settings/CHECK_PROLONG_ORDER_REQUEST';
export const CHECK_PROLONG_ORDER_REQUEST_SUCCESS =
  'hh/pages/settings/CHECK_PROLONG_ORDER_REQUEST_SUCCESS';
export const CHECK_PROLONG_ORDER_REQUEST_FAIL =
  'hh/pages/settings/CHECK_PROLONG_ORDER_REQUEST_FAIL';

export const OPEN_ACTIVATE_PLAN_MODAL = 'hh/pages/settings/OPEN_ACTIVATE_PLAN_MODAL';
export const CLOSE_ACTIVATE_PLAN_MODAL = 'hh/pages/settings/CLOSE_ACTIVATE_PLAN_MODAL';

export const ACTIVATE_PLAN_REQUEST = 'hh/pages/settings/ACTIVATE_PLAN_REQUEST';
export const ACTIVATE_PLAN_REQUEST_SUCCESS = 'hh/pages/settings/ACTIVATE_PLAN_REQUEST_SUCCESS';
export const ACTIVATE_PLAN_REQUEST_FAIL = 'hh/pages/settings/ACTIVATE_PLAN_REQUEST_FAIL';

export const CHANGE_PAYMENT_METHOD = 'hh/pages/settings/CHANGE_PAYMENT_METHOD';
export const CHANGE_VISA_POPUP = 'hh/pages/settings/CHANGE_VISA_POPUP';
export const CHANGE_PAYMENT_POPUP = 'hh/pages/settings/CHANGE_PAYMENT_POPUP';
export const CHANGE_PAYMENT_EMAIL = 'hh/pages/settings/CHANGE_PAYMENT_EMAIL';
export const CHANGE_PAYMENT_LOADING = 'hh/pages/settings/CHANGE_PAYMENT_LOADING';
export const CHANGE_PAYMENT_ORGANIZATION = 'hh/pages/settings/CHANGE_PAYMENT_ORGANIZATION';

export const SEND_PAYMENT_REQUEST = 'hh/pages/settings/SEND_PAYMENT_REQUEST';
export const SEND_PAYMENT_REQUEST_SUCCESS = 'hh/pages/settings/SEND_PAYMENT_REQUEST_SUCCESS';
export const SEND_PAYMENT_REQUEST_FAIL = 'hh/pages/settings/SEND_PAYMENT_REQUEST_FAIL';

export const SEND_TO_PAYMENT_PAGE = 'hh/pages/settings/SEND_TO_PAYMENT_PAGE';
export const PAYMENT_WAS_SEND_CHANGE = 'hh/pages/settings/PAYMENT_WAS_SEND_CHANGE';

export const GET_ORGANIZATIONS_LIST_REQUEST = 'hh/pages/settings/GET_ORGANIZATIONS_LIST_REQUEST';
export const GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS =
  'hh/pages/settings/GET_ORGANIZATIONS_LIST_REQUEST_SUCCESS';
export const GET_ORGANIZATIONS_LIST_REQUEST_FAIL =
  'hh/pages/settings/GET_ORGANIZATIONS_LIST_REQUEST_FAIL';

export const DELETE_ORGANIZATION_REQUEST = 'hh/pages/settings/DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_REQUEST_SUCCESS =
  'hh/pages/settings/DELETE_ORGANIZATION_REQUEST_SUCCESS';
export const DELETE_ORGANIZATION_REQUEST_FAIL =
  'hh/pages/settings/DELETE_ORGANIZATION_REQUEST_FAIL';

export const GET_ORGANIZATION_READ_REQUEST = 'hh/pages/settings/GET_ORGANIZATION_READ_REQUEST';
export const GET_ORGANIZATION_READ_REQUEST_SUCCESS =
  'hh/pages/settings/GET_ORGANIZATION_READ_REQUEST_SUCCESS';
export const GET_ORGANIZATION_READ_REQUEST_FAIL =
  'hh/pages/settings/GET_ORGANIZATION_READ_REQUEST_FAIL';

export const CHANGE_ORGANIZATION_ID = 'hh/pages/settings/CHANGE_ORGANIZATION_ID';
export const CHANGE_ORGANIZATIONS_FLAGS = 'hh/pages/settings/CHANGE_ORGANIZATIONS_FLAGS';
export const CHANGE_DELETE_ORGANIZATION_MODALS =
  'hh/pages/settings/CHANGE_DELETE_ORGANIZATION_MODALS';

export const CHANGE_ORGANIZATION_NAME = 'hh/pages/settings/CHANGE_ORGANIZATION_NAME';
export const CHANGE_ORGANIZATION_INN = 'hh/pages/settings/CHANGE_ORGANIZATION_INN';
export const CHANGE_ORGANIZATION_KPP = 'hh/pages/settings/CHANGE_ORGANIZATION_KPP';
export const CHANGE_ORGANIZATION_OGRN = 'hh/pages/settings/CHANGE_ORGANIZATION_OGRN';
export const CHANGE_ORGANIZATION_INDEX = 'hh/pages/settings/CHANGE_ORGANIZATION_INDEX';
export const CHANGE_ORGANIZATION_ADDRESS = 'hh/pages/settings/CHANGE_ORGANIZATION_ADDRESS';
export const CHANGE_ORGANIZATION_ACCOUNT_NUMBER =
  'hh/pages/settings/CHANGE_ORGANIZATION_ACCOUNT_NUMBER';
export const CHANGE_ORGANIZATION_BANK_NAME = 'hh/pages/settings/CHANGE_ORGANIZATION_BANK_NAME';
export const CHANGE_ORGANIZATION_BANK_BIK = 'hh/pages/settings/CHANGE_ORGANIZATION_BANK_BIK';
export const CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER =
  'hh/pages/settings/CHANGE_ORGANIZATION_CORRESPONDENT_ACCOUNT_NUMBER';

export const CREATE_ORGANIZATION_REQUEST = 'hh/pages/settings/CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_REQUEST_SUCCESS =
  'hh/pages/settings/CREATE_ORGANIZATION_REQUEST_SUCCESS';
export const CREATE_ORGANIZATION_REQUEST_FAIL =
  'hh/pages/settings/CREATE_ORGANIZATION_REQUEST_FAIL';

export const EDIT_ORGANIZATION_REQUEST = 'hh/pages/settings/EDIT_ORGANIZATION_REQUEST';
export const EDIT_ORGANIZATION_REQUEST_SUCCESS =
  'hh/pages/settings/EDIT_ORGANIZATION_REQUEST_SUCCESS';
export const EDIT_ORGANIZATION_REQUEST_FAIL = 'hh/pages/settings/EDIT_ORGANIZATION_REQUEST_FAIL';

export const CHANGE_INVITE_STAFF_NAME = 'hh/pages/settings/CHANGE_INVITE_STAFF_NAME';
export const CHANGE_INVITE_STAFF_FULL_NAME = 'hh/pages/settings/CHANGE_INVITE_STAFF_FULL_NAME';
export const CHANGE_INVITE_STAFF_EMAIL = 'hh/pages/settings/CHANGE_INVITE_STAFF_EMAIL';
export const CHANGE_INVITE_STAFF_TEXT = 'hh/pages/settings/CHANGE_INVITE_STAFF_TEXT';
export const CHANGE_INVITE_STAFF_COMMENT = 'hh/pages/settings/CHANGE_INVITE_STAFF_COMMENT';

export const CHANGE_INVITE_STAFF_FLAGS = 'hh/pages/settings/CHANGE_INVITE_STAFF_FLAGS';
export const CHANGE_INVITE_STAFF_MODALS = 'hh/pages/settings/CHANGE_INVITE_STAFF_MODALS';

export const SEND_INVITE_STAFF_REQUEST = 'hh/pages/settings/SEND_INVITE_STAFF_REQUEST';
export const SEND_INVITE_STAFF_REQUEST_SUCCESS =
  'hh/pages/settings/SEND_INVITE_STAFF_REQUEST_SUCCESS';
export const SEND_INVITE_STAFF_REQUEST_FAIL = 'hh/pages/settings/SEND_INVITE_STAFF_REQUEST_FAIL';

export const SEND_INVITE_STAFF_BY_FILE_REQUEST =
  'hh/pages/settings/SEND_INVITE_STAFF_BY_FILE_REQUEST';
export const SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS =
  'hh/pages/settings/SEND_INVITE_STAFF_BY_FILE_REQUEST_SUCCESS';
export const SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL =
  'hh/pages/settings/SEND_INVITE_STAFF_BY_FILE_REQUEST_FAIL';

export const CHANGE_INVITE_STAFF_BY_FILE_FLAGS =
  'hh/pages/settings/CHANGE_INVITE_STAFF_BY_FILE_FLAGS';
