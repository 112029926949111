import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { fromJS } from 'immutable';
import * as Sentry from '@sentry/browser';

import Button from '@atlaskit/button';

import Modal from 'components/Modal';
import Question from 'components/Question';
import Spinner from 'components/Spinner';

import {
  addAnswersMoves,
  changeAnswers,
  changeAnswerTime,
  getQuestionnarieList,
  sendQuestionnarie
} from './actions';
import {
  makeSelectLoading,
  makeSelectQuestionsList,
  makeSelectAnswersCount,
  makeSelectAnswersList,
  makeSelectAnswersTime
} from './selectors';

import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import ButtonsContainer from './styled/ButtonsContainer';

class TestQuestion extends React.Component {
  state = {
    finishTestModal: false,
    intervalId: null,
    currentTimer: null,
    warningModal: false
  };

  componentDidMount() {
    if (!isString(localStorage.getItem('staffKey'))) {
      this.props.history.push('/404');
    }

    const invitationCode = this.props.match.params.invitationCode;
    this.props.getQuestionnarieList({
      invitationCode
    });
  }

  sendToFinish = () => {
    this.props.sendQuestionnarie();
  };

  switchWarningModal = () => {
    this.setState({
      warningModal: !this.state.warningModal
    });
  };

  switchToNextQuestionFromWarning = () => {
    const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
      ? parseInt(this.props.match.params.questionNumber, 10)
      : 1;
    const nextQuestionNumber = currentQuestionNumber + 1;

    this.setState({
      warningModal: !this.state.warningModal
    });

    this.switchToAnotherQuestion(nextQuestionNumber);
  };

  switchFinishTestModal = () => {
    const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
      ? parseInt(this.props.match.params.questionNumber, 10)
      : 1;

    const index = currentQuestionNumber - 1;
    const time = this.currentTimer;

    this.props.changeAnswerTime({
      index,
      time,
      currentQuestionNumber
    });

    this.setState({
      finishTestModal: !this.state.finishTestModal
    });
  };

  switchToNextQuestion = () => {
    const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
      ? parseInt(this.props.match.params.questionNumber, 10)
      : 1;
    const index = currentQuestionNumber - 1;
    const nextQuestionNumber = currentQuestionNumber + 1;
    const answersMove = this.props.answersCount.get(index);

    if (answersMove === 0) {
      this.switchWarningModal();
    } else {
      this.switchToAnotherQuestion(nextQuestionNumber);
    }
  };

  switchToPrevQuestion = () => {
    const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
      ? parseInt(this.props.match.params.questionNumber, 10)
      : 1;
    const nextQuestionNumber = currentQuestionNumber - 1;

    this.switchToAnotherQuestion(nextQuestionNumber);
  };

  switchToAnotherQuestion = nextQuestionNumber => {
    const invitationCode = this.props.match.params.invitationCode;
    const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
      ? parseInt(this.props.match.params.questionNumber, 10)
      : 1;
    const url = isUndefined(invitationCode)
      ? `/test/question/${nextQuestionNumber}`
      : `/test/question/${nextQuestionNumber}/${invitationCode}`;

    const index = currentQuestionNumber - 1;
    const answersMove = this.props.answersCount.get(index);
    const time = this.currentTimer;

    if (time === 0 && answersMove !== 0) {
      Sentry.captureMessage(
        `Testing stat error, time=${time} countMove=${answersMove}, currentQuestionNumber=${currentQuestionNumber}`
      );
    }

    this.props.changeAnswerTime({
      index,
      time,
      currentQuestionNumber
    });

    this.props.history.push(url);
    this.startCountDownTimer(nextQuestionNumber);
  };

  changeAnswersList = (number, answers) => {
    const answersList = this.props.answersList.set(number, fromJS(answers));

    this.props.addAnswersMoves({
      index: number
    });
    this.props.changeAnswers({
      answersList
    });
  };

  startCountDownTimer = currentQuestionNumber => {
    if (isUndefined(this.timerEnabled)) {
      this.timerEnabled = true;
    }

    const { answersTime } = this.props;
    this.currentTimer = answersTime.get(currentQuestionNumber - 1);

    if (!isUndefined(this.timer)) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      this.currentTimer = this.currentTimer + 1;
    }, 1000);
  };

  render() {
    const { questionsList, answersList } = this.props;

    if (!this.props.loading && !isNull(questionsList)) {
      const currentQuestionNumber = isNumber(parseInt(this.props.match.params.questionNumber, 10))
        ? parseInt(this.props.match.params.questionNumber, 10)
        : 1;
      if (isUndefined(this.timerEnabled)) {
        this.startCountDownTimer(currentQuestionNumber);
      }
      const totalQuestionNumber = questionsList.size;

      const currentQuestion = questionsList.get(currentQuestionNumber - 1).toJS();
      const answers = answersList.get(currentQuestionNumber - 1).toJS();

      const question = {
        body: currentQuestion.text,
        answers: answers
      };

      let buttons = (
        <ButtonsContainer align="flex-end">
          <Button appearance="primary" onClick={this.switchToNextQuestion}>
            Следующий вопрос
          </Button>
        </ButtonsContainer>
      );
      if (currentQuestionNumber > 1) {
        let nextQuestionButton = (
          <Button appearance="primary" onClick={this.switchToNextQuestion}>
            Следующий вопрос
          </Button>
        );

        if (currentQuestionNumber === totalQuestionNumber) {
          nextQuestionButton = (
            <Button appearance="primary" onClick={this.switchFinishTestModal}>
              Завершить опрос
            </Button>
          );
        }

        buttons = (
          <ButtonsContainer align="space-between">
            <Button appearance="default" onClick={this.switchToPrevQuestion}>
              Предыдущий вопрос
            </Button>
            {nextQuestionButton}
          </ButtonsContainer>
        );
      }

      return (
        <Flex>
          <FlexItem>
            <Question
              currentQuestionNumber={currentQuestionNumber}
              onAnswersChange={this.changeAnswersList}
              question={question}
              totalQuestionNumber={totalQuestionNumber}
            />
          </FlexItem>

          {buttons}

          {this.state.warningModal ? (
            <Modal
              heading="Внимание"
              onClose={this.switchWarningModal}
              actions={[
                {
                  text: 'Продолжить',
                  onClick: this.switchToNextQuestionFromWarning
                },
                {
                  text: 'Отменить',
                  onClick: this.switchWarningModal
                }
              ]}
              width="small"
            >
              <p>Вы не сделали ни одной перестановки в вопросе. Уверены, что хотите продолжить?</p>
            </Modal>
          ) : (
            ''
          )}

          {this.state.finishTestModal ? (
            <Modal
              heading="Завершить опрос"
              onClose={this.switchFinishTestModal}
              actions={[
                {
                  text: 'Завершить',
                  onClick: this.sendToFinish
                },
                {
                  text: 'Отменить',
                  onClick: this.switchFinishTestModal
                }
              ]}
              width="small"
            >
              <p>
                Повторное прохождение опроса можно будет возможно не ранее, чем через год. Уверены,
                что хотите завершить опрос?
              </p>
            </Modal>
          ) : (
            ''
          )}
        </Flex>
      );
    }

    return <Spinner />;
  }
}

TestQuestion.propTypes = {
  addAnswersMoves: PropTypes.func,
  answersCount: PropTypes.object,
  answersList: PropTypes.object,
  answersTime: PropTypes.object,
  changeAnswers: PropTypes.func,
  changeAnswerTime: PropTypes.func,
  currentQuestion: PropTypes.object,
  getQuestionnarieList: PropTypes.func,
  loading: PropTypes.bool,
  questionsList: PropTypes.object,
  sendQuestionnarie: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    addAnswersMoves: value => dispatch(addAnswersMoves(value)),
    changeAnswers: value => dispatch(changeAnswers(value)),
    changeAnswerTime: value => dispatch(changeAnswerTime(value)),
    getQuestionnarieList: value => dispatch(getQuestionnarieList(value)),
    sendQuestionnarie: () => dispatch(sendQuestionnarie())
  };
}

const mapStateToProps = createStructuredSelector({
  answersCount: makeSelectAnswersCount(),
  answersList: makeSelectAnswersList(),
  answersTime: makeSelectAnswersTime(),
  loading: makeSelectLoading(),
  questionsList: makeSelectQuestionsList()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  withConnect
)(TestQuestion);
