export const CHANGE_EMAIL = 'hh/pages/individual/profile/CHANGE_EMAIL';
export const CHANGE_NAME = 'hh/pages/individual/profile/CHANGE_NAME';
export const CHANGE_PASSWORD_ONE = 'hh/pages/individual/profile/CHANGE_PASSWORD_ONE';
export const CHANGE_PASSWORD_TWO = 'hh/pages/individual/profile/CHANGE_PASSWORD_TWO';
export const CHANGE_PHONE_NUMBER = 'hh/pages/individual/profile/CHANGE_PHONE_NUMBER';
export const CHANGE_SURNAME = 'hh/pages/individual/profile/CHANGE_SURNAME';
export const CHANGE_GENDER = 'hh/pages/individual/profile/CHANGE_GENDER';

export const UPDATE_PASSWORD_REQUEST = 'hh/pages/individual/profile/UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'hh/pages/individual/profile/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'hh/pages/individual/profile/UPDATE_PASSWORD_FAIL';

export const UPDATE_USER_REQUEST = 'hh/pages/individual/profile/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'hh/pages/individual/profile/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'hh/pages/individual/profile/UPDATE_USER_FAIL';

export const USER_READ_REQUEST = 'hh/pages/individual/profile/USER_READ_REQUEST';
export const USER_READ_SUCCESS = 'hh/pages/individual/profile/USER_READ_SUCCESS';
export const USER_READ_FAIL = 'hh/pages/individual/profile/USER_READ_FAIL';
