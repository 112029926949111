import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileCreateAskStepTwoPage from 'pages/ProfileCreateAsk/StepTwo';

const ProfileCreateAskStepTwoView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileCreateAskStepTwoPage />
  </Fragment>
);

export default ProfileCreateAskStepTwoView;
