import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/ru';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Form from '@atlaskit/form';
import TextField from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';

import Modal from 'components/Modal';

import Block from './styled/Block';
import Button from './styled/Button';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flag from './styled/Flag';
import Line from './styled/Line';
import NameWrapper from './styled/NameWrapper';
import Text from './styled/Text';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'name',
        content: 'Имя и фамилия',
        isSortable: true,
        width: withWidth ? 52 : undefined
      },
      {
        key: 'created_at',
        content: 'Создан',
        isSortable: true,
        width: withWidth ? 30 : undefined
      },
      {
        key: 'result',
        content: 'Соответствие',
        shouldTruncate: true
      },
      {
        key: 'resultImportant',
        content: 'Соответствие по значимым параметрам',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class Table extends React.Component {
  state = {
    deleteModals: [],
    sendModals: [],
    flags: [],
    sendFlags: []
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      deleteModals: this.state.deleteModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  closeModal = id => {
    this.setState({ deleteModals: this.state.deleteModals.filter(i => i !== id) });
  };

  closeEditModal = id => {
    this.setState({ sendModals: this.state.sendModals.filter(i => i !== id) });
  };

  addSendFlag = id => {
    this.setState({
      sendFlags: [this.state.flags.length, ...this.state.flags],
      sendModals: this.state.sendModals.filter(i => i !== id)
    });
  };

  removeSendFlag = id => this.setState({ sendFlags: this.state.sendFlags.filter(v => v !== id) });

  render() {
    const rowsTable = this.props.data.map((item, index) => ({
      key: `row-${index}-${item.id}`,
      cells: [
        {
          key: createKey(item.staff.full_name),
          content: (
            <NameWrapper>
              <Link to={`/person/compare-info/${item.id}/${item.staff.id}`}>
                {item.staff.full_name}
              </Link>
            </NameWrapper>
          )
        },
        {
          key: createKey(item.created_at),
          content: moment(item.created_at).format('DD.MM.YYYY HH:mm')
        },
        {
          key: createKey(item.conformance_value.toString()),
          content: item.conformance_value
        },
        {
          key: createKey(item.important_conformance_value.toString()),
          content: item.important_conformance_value
        }
      ]
    }));

    return (
      <div>
        <Block />
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          rows={rowsTable}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {this.state.deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить профиль"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Удалить',
                onClick: this.addFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>Уверены, что хотите удалить профиль?</p>
          </Modal>
        ))}

        {this.state.sendModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Отправить тест"
            onClose={this.closeEditModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.addSendFlag
              },
              {
                text: 'Отменить',
                onClick: this.closeEditModal
              }
            ]}
            width="small"
          >
            <p>Отправьте или скопируйте ссылку</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup layout="column" form="">
                <Field width="65%">
                  <TextField
                    label="Скопируйте ссылку"
                    shouldFitContainer
                    name="link"
                    placeholder=""
                    isReadOnly
                    value="http://profgrowth.com/test/12345t"
                  />
                </Field>
                <Field width="32%" alignSelf="flex-end">
                  <Button className="allWidth">Скопировать</Button>
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Line />
                <Text>или</Text>
                <Line />
              </FieldGroup>
              <FieldGroup layout="column" form="">
                <Field width="100%">
                  <TextField
                    label="Отправьте тестирование"
                    shouldFitContainer
                    name="email"
                    placeholder=""
                    value="petrov@mail.ru"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup marginTop="8px">
                <Field width="100%">
                  <TextArea
                    shouldFitContainer
                    name="message"
                    placeholder="Добавьте сообщение (необязательно)"
                    value=""
                    isLabelHidden
                  />
                </Field>
              </FieldGroup>
            </Form>
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.flags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Профиль удален"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSendFlag(name)}>
          {this.state.sendFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Ссылка отправлена"
            />
          ))}
        </FlagGroup>
      </div>
    );
  }
}

export default Table;
