import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled/Wrapper';

const CompanyName = props => (
  <Wrapper>
    {props.text}
    <br />
  </Wrapper>
);

CompanyName.propTypes = {
  text: PropTypes.string
};

export default CompanyName;
