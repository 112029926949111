import styled from 'styled-components';

const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 12px 0;
`;

export default IconsWrapper;
