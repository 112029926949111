import {
  LOADING_END,
  LOADING_TABLE_END,
  STAFF_LIST_REQUEST,
  STAFF_LIST_FILTER_REQUEST,
  STAFF_LIST_REQUEST_SUCCESS,
  STAFF_LIST_REQUEST_FAIL,
  CHANGE_STAFF_PAGE,
  CHANGE_STAFF_SORT,
  CHANGE_STAFF_COMMENT,
  CHANGE_STAFF_FLAGS,
  STAFF_READ_REQUEST,
  STAFF_READ_REQUEST_SUCCESS,
  STAFF_READ_REQUEST_FAIL,
  STAFF_UPDATE_REQUEST,
  STAFF_UPDATE_REQUEST_SUCCESS,
  STAFF_UPDATE_REQUEST_FAIL,
  STAFF_REPORT_REQUEST,
  STAFF_REPORT_REQUEST_SUCCESS,
  STAFF_REPORT_REQUEST_FAIL,
  CHANGE_STAFF_COMPARE_FILTER,
  CHANGE_STAFF_COMPARE_PAGE,
  CHANGE_STAFF_COMPARE_SORT,
  STAFF_COMPARE_REQUEST,
  STAFF_COMPARE_REQUEST_SUCCESS,
  STAFF_COMPARE_REQUEST_FAIL,
  STAFF_DELETE_REQUEST,
  STAFF_DELETE_REQUEST_SUCCESS,
  STAFF_DELETE_REQUEST_FAIL,
  CHANGE_COMPARE_PROFILE,
  CHANGE_COMPARE_PROFILE_INPUT,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_REQUEST_SUCCESS,
  PROFILE_LIST_REQUEST_FAIL,
  CREATE_COMPARE_REQUEST,
  CREATE_COMPARE_REQUEST_SUCCESS,
  CREATE_COMPARE_REQUEST_FAIL,
  DELETE_COMPARE_REQUEST,
  DELETE_COMPARE_REQUEST_SUCCESS,
  DELETE_COMPARE_REQUEST_FAIL,
  GET_COMPARE_REQUEST,
  GET_COMPARE_REQUEST_SUCCESS,
  GET_COMPARE_REQUEST_FAIL,
  CHANGE_INVITE_COMMENT,
  CHANGE_INVITE_EMAIL,
  CHANGE_INVITE_FULL_NAME,
  CHANGE_INVITE_NAME,
  CHANGE_INVITE_TEXT,
  CHANGE_INVITE_FLAGS,
  CHANGE_INVITE_MODALS,
  SEND_INVITE_USER_REQUEST,
  SEND_INVITE_USER_REQUEST_SUCCESS,
  SEND_INVITE_USER_REQUEST_FAIL,
  GET_USER_READ_REQUEST,
  GET_USER_READ_REQUEST_SUCCESS,
  GET_USER_READ_REQUEST_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_SUCCESS,
  UPDATE_USER_REQUEST_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST_SUCCESS,
  UPDATE_USER_PASSWORD_REQUEST_FAIL,
  CHANGE_USER_NAME,
  CHANGE_USER_SURNAME,
  CHANGE_USER_EMAIL,
  CHANGE_USER_PHONE_NUMBER,
  CHANGE_USER_PASSWORD1,
  CHANGE_USER_PASSWORD2,
  CHANGE_USER_COMPANY_NAME,
  GET_MANAGERS_LIST_REQUEST,
  GET_MANAGERS_LIST_REQUEST_SUCCESS,
  GET_MANAGERS_LIST_REQUEST_FAIL,
  CHANGE_NAME_FILTER,
  CHANGE_MANAGERS_FILTER,
  CHANGE_WARNING_FLAG,
  CHANGE_ADDS_REPORTS_COUNT,
  CHANGE_ADDS_MODAL,
  CHANGE_ADDS_FLAG,
  ADDS_REPORTS_REQUEST,
  ADDS_REPORTS_REQUEST_SUCCESS,
  ADDS_REPORTS_REQUEST_FAIL,
  CHECK_ORDER_REQUEST,
  CHECK_ORDER_REQUEST_SUCCESS,
  CHECK_ORDER_REQUEST_FAIL,
  SEND_TO_PAYMENT_PAGE,
  CHANGE_REPORTS_FLAGS,
  CHANGE_REPORTS_EMAIL,
  CHANGE_REPORTS_LOADING,
  CHANGE_REPORTS_MODALS,
  SEND_REPORTS_EMAIL_REQUEST,
  SEND_REPORTS_EMAIL_REQUEST_SUCCESS,
  SEND_REPORTS_EMAIL_REQUEST_FAIL,
  CHANGE_ORDER_REPORT_BY_RANK,
  CHANGE_MISMATCHED_PATTERNS,
  CHANGE_REPORT_TYPE,
  CHANGE_COMPARE_INFO_EMAIL,
  CHANGE_COMPARE_INFO_LOADING,
  CHANGE_COMPARE_INFO_MODAL,
  CHANGE_COMPARE_INFO_FLAG,
  SEND_COMPARE_INFO_REQUEST,
  SEND_COMPARE_INFO_REQUEST_SUCCESS,
  SEND_COMPARE_INFO_REQUEST_FAIL,
  ADD_REPORT_TO_COMPARE,
  DELETE_REPORT_FROM_COMPARE,
  UPDATE_REPORTS_TO_COMPARE,
  CHANGE_REPORT_SHAPE,
  SEND_INVITES_BY_FILE,
  SEND_INVITES_BY_FILE_SUCCESS,
  SEND_INVITES_BY_FILE_FAIL,
  CHANGE_INVITE_BY_FILE_FLAGS,
  SEND_REPORT_READ_REQUEST,
  DECREASE_UNREAD_REPORTS_COUNT
} from './constants';

export function changeInviteByFileFlags(payload) {
  return {
    type: CHANGE_INVITE_BY_FILE_FLAGS,
    ...payload
  };
}

export function sendInvitesByFile(payload) {
  return {
    type: SEND_INVITES_BY_FILE,
    ...payload
  };
}

export function sendInvitesByFileSuccess(payload) {
  return {
    type: SEND_INVITES_BY_FILE_SUCCESS,
    ...payload
  };
}

export function sendInvitesByFileFail(payload) {
  return {
    type: SEND_INVITES_BY_FILE_FAIL,
    ...payload
  };
}

export function changeReportShape(payload) {
  return {
    type: CHANGE_REPORT_SHAPE,
    ...payload
  };
}

export function changeReportType(payload) {
  return {
    type: CHANGE_REPORT_TYPE,
    ...payload
  };
}

export function sendCompareInfo(payload) {
  return {
    type: SEND_COMPARE_INFO_REQUEST,
    ...payload
  };
}

export function sendCompareInfoSuccess() {
  return {
    type: SEND_COMPARE_INFO_REQUEST_SUCCESS
  };
}

export function sendCompareInfoFail() {
  return {
    type: SEND_COMPARE_INFO_REQUEST_FAIL
  };
}

export function changeCompareInfoEmail(payload) {
  return {
    type: CHANGE_COMPARE_INFO_EMAIL,
    ...payload
  };
}

export function changeCompareInfoLoading(payload) {
  return {
    type: CHANGE_COMPARE_INFO_LOADING,
    ...payload
  };
}

export function changeCompareInfoModal(payload) {
  return {
    type: CHANGE_COMPARE_INFO_MODAL,
    ...payload
  };
}

export function changeCompareInfoFlag(payload) {
  return {
    type: CHANGE_COMPARE_INFO_FLAG,
    ...payload
  };
}

export function changeMismatchedPatterns(payload) {
  return {
    type: CHANGE_MISMATCHED_PATTERNS,
    ...payload
  };
}

export function changeOrderReportByRank(payload) {
  return {
    type: CHANGE_ORDER_REPORT_BY_RANK,
    ...payload
  };
}

export function changeReportsFlags(payload) {
  return {
    type: CHANGE_REPORTS_FLAGS,
    ...payload
  };
}

export function changeReportsEmail(payload) {
  return {
    type: CHANGE_REPORTS_EMAIL,
    ...payload
  };
}

export function changeReportsLoading(payload) {
  return {
    type: CHANGE_REPORTS_LOADING,
    ...payload
  };
}

export function changeReportsModals(payload) {
  return {
    type: CHANGE_REPORTS_MODALS,
    ...payload
  };
}

export function sendReportsEmail(payload) {
  return {
    type: SEND_REPORTS_EMAIL_REQUEST,
    ...payload
  };
}

export function sendReportsEmailSuccess() {
  return {
    type: SEND_REPORTS_EMAIL_REQUEST_SUCCESS
  };
}

export function sendReportsEmailFail() {
  return {
    type: SEND_REPORTS_EMAIL_REQUEST_FAIL
  };
}

export function sendToPaymentPage(payload) {
  return {
    type: SEND_TO_PAYMENT_PAGE,
    ...payload
  };
}

export function changeAddsFlag(payload) {
  return {
    type: CHANGE_ADDS_FLAG,
    ...payload
  };
}

export function checkOrder(payload) {
  return {
    type: CHECK_ORDER_REQUEST,
    ...payload
  };
}

export function checkOrderSuccess() {
  return {
    type: CHECK_ORDER_REQUEST_SUCCESS
  };
}

export function checkOrderFail() {
  return {
    type: CHECK_ORDER_REQUEST_FAIL
  };
}

export function changeAddsModal(payload) {
  return {
    type: CHANGE_ADDS_MODAL,
    ...payload
  };
}

export function changeAddsReportsCount(payload) {
  return {
    type: CHANGE_ADDS_REPORTS_COUNT,
    ...payload
  };
}

export function addsReports(payload) {
  return {
    type: ADDS_REPORTS_REQUEST,
    ...payload
  };
}

export function addsReportsSuccess() {
  return {
    type: ADDS_REPORTS_REQUEST_SUCCESS
  };
}

export function addsReportsFail() {
  return {
    type: ADDS_REPORTS_REQUEST_FAIL
  };
}

export function changeWarningFlag(payload) {
  return {
    type: CHANGE_WARNING_FLAG,
    ...payload
  };
}

export function changeStaffFlags(payload) {
  return {
    type: CHANGE_STAFF_FLAGS,
    ...payload
  };
}

export function changeComment(payload) {
  return {
    type: CHANGE_STAFF_COMMENT,
    ...payload
  };
}

export function changeSort(payload) {
  return {
    type: CHANGE_STAFF_SORT,
    ...payload
  };
}

export function changeNameFilter(payload) {
  return {
    type: CHANGE_NAME_FILTER,
    ...payload
  };
}

export function changeManagersFilter(payload) {
  return {
    type: CHANGE_MANAGERS_FILTER,
    ...payload
  };
}

export function getManagersList() {
  return {
    type: GET_MANAGERS_LIST_REQUEST
  };
}

export function getManagersListSuccess(payload) {
  return {
    type: GET_MANAGERS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getManagersListFail(payload) {
  return {
    type: GET_MANAGERS_LIST_REQUEST_FAIL,
    ...payload
  };
}

export function staffList() {
  return {
    type: STAFF_LIST_REQUEST
  };
}

export function staffFilteredList() {
  return {
    type: STAFF_LIST_FILTER_REQUEST
  };
}

export function staffListSuccess(payload) {
  return {
    type: STAFF_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffListFail(payload) {
  return {
    type: STAFF_LIST_REQUEST_FAIL,
    ...payload
  };
}

export function staffRead(payload) {
  return {
    type: STAFF_READ_REQUEST,
    ...payload
  };
}

export function staffReadSuccess(payload) {
  return {
    type: STAFF_READ_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffReadFail(payload) {
  return {
    type: STAFF_READ_REQUEST_FAIL,
    ...payload
  };
}

export function staffUpdate() {
  return {
    type: STAFF_UPDATE_REQUEST
  };
}

export function staffUpdateSuccess(payload) {
  return {
    type: STAFF_UPDATE_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffUpdateFail() {
  return {
    type: STAFF_UPDATE_REQUEST_FAIL
  };
}

export function staffReport(payload) {
  return {
    type: STAFF_REPORT_REQUEST,
    ...payload
  };
}

export function staffReportSuccess(payload) {
  return {
    type: STAFF_REPORT_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffReportFail(payload) {
  return {
    type: STAFF_REPORT_REQUEST_FAIL,
    ...payload
  };
}

export function staffCompare(payload) {
  return {
    type: STAFF_COMPARE_REQUEST,
    ...payload
  };
}

export function staffCompareSuccess(payload) {
  return {
    type: STAFF_COMPARE_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffCompareFail(payload) {
  return {
    type: STAFF_COMPARE_REQUEST_FAIL,
    ...payload
  };
}

export function staffDelete(payload) {
  return {
    type: STAFF_DELETE_REQUEST,
    ...payload
  };
}

export function staffDeleteSuccess() {
  return {
    type: STAFF_DELETE_REQUEST_SUCCESS
  };
}

export function staffDeleteFail() {
  return {
    type: STAFF_DELETE_REQUEST_FAIL
  };
}

export function profileList() {
  return {
    type: PROFILE_LIST_REQUEST
  };
}

export function profileListSuccess(payload) {
  return {
    type: PROFILE_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileListFail() {
  return {
    type: PROFILE_LIST_REQUEST_FAIL
  };
}

export function changeCompareProfileInput(payload) {
  return {
    type: CHANGE_COMPARE_PROFILE_INPUT,
    ...payload
  };
}

export function changeCompareProfile(payload) {
  return {
    type: CHANGE_COMPARE_PROFILE,
    ...payload
  };
}

export function createCompare(payload) {
  return {
    type: CREATE_COMPARE_REQUEST,
    ...payload
  };
}

export function createCompareSuccess() {
  return {
    type: CREATE_COMPARE_REQUEST_SUCCESS
  };
}

export function createCompareFail() {
  return {
    type: CREATE_COMPARE_REQUEST_FAIL
  };
}

export function deleteCompare(payload) {
  return {
    type: DELETE_COMPARE_REQUEST,
    ...payload
  };
}

export function deleteCompareSuccess() {
  return {
    type: DELETE_COMPARE_REQUEST_SUCCESS
  };
}

export function deleteCompareFail() {
  return {
    type: DELETE_COMPARE_REQUEST_FAIL
  };
}

export function compareRead(payload) {
  return {
    type: GET_COMPARE_REQUEST,
    ...payload
  };
}

export function compareReadSuccess(payload) {
  return {
    type: GET_COMPARE_REQUEST_SUCCESS,
    ...payload
  };
}

export function compareReadFail() {
  return {
    type: GET_COMPARE_REQUEST_FAIL
  };
}

export function changeInviteComment(payload) {
  return {
    type: CHANGE_INVITE_COMMENT,
    ...payload
  };
}

export function changeInviteText(payload) {
  return {
    type: CHANGE_INVITE_TEXT,
    ...payload
  };
}

export function changeInviteFullName(payload) {
  return {
    type: CHANGE_INVITE_FULL_NAME,
    ...payload
  };
}

export function changeInviteEmail(payload) {
  return {
    type: CHANGE_INVITE_EMAIL,
    ...payload
  };
}

export function changeInviteName(payload) {
  return {
    type: CHANGE_INVITE_NAME,
    ...payload
  };
}

export function changeInviteFlags(payload) {
  return {
    type: CHANGE_INVITE_FLAGS,
    ...payload
  };
}

export function changeInviteModals(payload) {
  return {
    type: CHANGE_INVITE_MODALS,
    ...payload
  };
}

export function sendInvite() {
  return {
    type: SEND_INVITE_USER_REQUEST
  };
}

export function sendInviteSuccess() {
  return {
    type: SEND_INVITE_USER_REQUEST_SUCCESS
  };
}

export function sendInviteFail() {
  return {
    type: SEND_INVITE_USER_REQUEST_FAIL
  };
}

export function getUserInfo() {
  return {
    type: GET_USER_READ_REQUEST
  };
}

export function getUserInfoSuccess(payload) {
  return {
    type: GET_USER_READ_REQUEST_SUCCESS,
    ...payload
  };
}

export function getUserInfoFail() {
  return {
    type: GET_USER_READ_REQUEST_FAIL
  };
}

export function updateUser() {
  return {
    type: UPDATE_USER_REQUEST
  };
}

export function updateUserSuccess() {
  return {
    type: UPDATE_USER_REQUEST_SUCCESS
  };
}

export function updateUserFail() {
  return {
    type: UPDATE_USER_REQUEST_FAIL
  };
}

export function changeName(payload) {
  return {
    type: CHANGE_USER_NAME,
    ...payload
  };
}

export function changeSurname(payload) {
  return {
    type: CHANGE_USER_SURNAME,
    ...payload
  };
}

export function changeCompanyName(payload) {
  return {
    type: CHANGE_USER_COMPANY_NAME,
    ...payload
  };
}

export function changePhoneNumber(payload) {
  return {
    type: CHANGE_USER_PHONE_NUMBER,
    ...payload
  };
}

export function changeEmail(payload) {
  return {
    type: CHANGE_USER_EMAIL,
    ...payload
  };
}

export function changePasswordOne(payload) {
  return {
    type: CHANGE_USER_PASSWORD1,
    ...payload
  };
}

export function changePasswordTwo(payload) {
  return {
    type: CHANGE_USER_PASSWORD2,
    ...payload
  };
}

export function updatePassword() {
  return {
    type: UPDATE_USER_PASSWORD_REQUEST
  };
}

export function updatePasswordSuccess() {
  return {
    type: UPDATE_USER_PASSWORD_REQUEST_SUCCESS
  };
}

export function updatePasswordFail() {
  return {
    type: UPDATE_USER_PASSWORD_REQUEST_FAIL
  };
}

export function loadingFinish() {
  return {
    type: LOADING_END
  };
}

export function loadingTableFinish() {
  return {
    type: LOADING_TABLE_END
  };
}

export function changeStaffPage(payload) {
  return {
    type: CHANGE_STAFF_PAGE,
    ...payload
  };
}

export function changeStaffCompareFilter(payload) {
  return {
    type: CHANGE_STAFF_COMPARE_FILTER,
    ...payload
  };
}

export function changeStaffComparePage(payload) {
  return {
    type: CHANGE_STAFF_COMPARE_PAGE,
    ...payload
  };
}

export function changeStaffCompareSort(payload) {
  return {
    type: CHANGE_STAFF_COMPARE_SORT,
    ...payload
  };
}

export function addReportToCompare(payload) {
  return {
    type: ADD_REPORT_TO_COMPARE,
    ...payload
  };
}

export function deleteReportFromCompare(payload) {
  return {
    type: DELETE_REPORT_FROM_COMPARE,
    ...payload
  };
}

export function updateReportsToCompare(payload) {
  return {
    type: UPDATE_REPORTS_TO_COMPARE,
    ...payload
  };
}

export function changeReportRead(payload) {
  return {
    type: SEND_REPORT_READ_REQUEST,
    ...payload
  };
}

export function decreaseUnreadReportsCount() {
  return {
    type: DECREASE_UNREAD_REPORTS_COUNT
  }
}
