import styled from 'styled-components';

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(99.9999% / 12 * 6 - 16px);
  margin: auto 8px 0;
  word-wrap: break-word;
`;

export default RightWrapper;
