import styled from 'styled-components';

const Link = styled.div`
  color: #0052cc;
  cursor: pointer;
  text-decoration: none

  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
