import React from 'react';
import styled from 'styled-components';

import FirstStep from './first.jpg';
import SecondStep from './second.jpg';
import ThirdStep from './third.jpg';

import FirstSvg from './1.svg';
import SecondSvg from './2.svg';
import ThirdSvg from './3.svg';

const AllWidthBlock = styled.section`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};
  display: flex;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  width: 100%;

  @media (max-width: 1200px) {
    padding: 50px 0;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1200px) {
    width: calc(100% - 30px);
  }
`;

const Title = styled.div`
  color: #172b4d;
  font-size: 29px;
  letter-spacing: 0.32px;
  line-height: 32px;
  text-align: center;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
    text-align: left;
  }
`;

const FlexBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0')};

  @media (max-width: 1200px) {
    flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
    margin: 50px 0;
  }
`;

const LeftBlock = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: ${props =>
    props.backgroundPosition ? `${props.backgroundPosition}px` : 'center'};
  background-size: ${props => `${props.backgroundWidth}px ${props.backgroundHeight}px`};
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props => props.backgroundHeight}px;
  width: 360px;

  @media (max-width: 1200px) {
    margin: 50px 0;
  }

  @media (max-width: 1020px) {
    background-size: contain;
    background-position: left;
    margin: 0;
    min-height: auto;
    width: 100%;
  }
`;

const RightBlock = styled.div`
  width: 760px;

  @media (max-width: 1020px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const Subtitle = styled.p`
  color: #172b4d;
  font-size: 24px;
  letter-spacing: 0.33px;
  line-height: 28px;

  @media (max-width: 1020px) {
    font-size: 19px;
    line-height: 22px;
  }
`;

const Text = styled.p`
  margin-top: 24px;

  @media (max-width: 1020px) {
    font-size: 13px;
    line-height: 18px;
    margin-top: 15px;
  }
`;

const Img = styled.img`
  box-shadow: 0 4px 35px 0 rgba(90, 122, 190, 0.2);
`;

const HowWork = () => (
  <AllWidthBlock padding="100px 0 222px">
    <Inner>
      <Title>Как работает платформа “HRHelper” — 3 простых шага:</Title>
      <FlexBlock marginTop={150}>
        <LeftBlock
          backgroundImage={FirstSvg}
          backgroundWidth={148}
          backgroundHeight={353}
          backgroundPosition={90}
        >
          <Subtitle>Кандидаты (сотрудники) проходят онлайн опрос</Subtitle>
          <Text>
            — В опросе 40 вопросов.
            <br />
            — Время прохождения 25–40 минут.
            <br />— Нет верных и неверных ответов. Задача кандидата — в каждом вопросе выстроить
            варианты ответов от наиболее до наименее важных для него самого.
          </Text>
        </LeftBlock>
        <RightBlock>
          <Img
            src={FirstStep}
            width="100%"
            alt="Скриншот создания профиля должности кандидата. Шаг 1"
          />
        </RightBlock>
      </FlexBlock>
      <FlexBlock marginTop={150} reverse>
        <RightBlock>
          <Img src={SecondStep} width="100%" alt="Скриншот тестирования кандидата. Шаг 2" />
        </RightBlock>
        <LeftBlock backgroundImage={SecondSvg} backgroundWidth={245} backgroundHeight={359}>
          <Subtitle>Создайте профиль должности одним из трех способов</Subtitle>
          <Text>
            Профиль должности — набор навыков, который определит модель поведения вашего «идеального
            кандидата».
          </Text>
        </LeftBlock>
      </FlexBlock>
      <FlexBlock marginTop={150}>
        <LeftBlock
          backgroundImage={ThirdSvg}
          backgroundWidth={258}
          backgroundHeight={366}
          backgroundPosition={50}
        >
          <Subtitle>Результаты опроса сопоставляются с профилем должности</Subtitle>
          <Text>
            Вам наглядно показывается результат сопоставления.
            <br />
            — Характеристика сотрудника 5% не попала в желаемый диапозон профиля должности 23–60%.
            <br />— Характеристика сотрудника 35% попала в желаемый диапозон профиля должности
            29–70%.
          </Text>
        </LeftBlock>
        <RightBlock>
          <Img
            src={ThirdStep}
            width="100%"
            alt="Скриншот сравнения результатов тестирования и профиля. Шаг 3"
          />
        </RightBlock>
      </FlexBlock>
    </Inner>
  </AllWidthBlock>
);

export default HowWork;
