import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import apiUrl from 'utils/serverConfig';
import { formatPhone } from 'utils/formatPhone';
import service from '../../utils/checkService';

import {
  SEND_CREATE_REQUEST,
  SEND_VERIFY_REQUEST,
  USER_READ_REQUEST,
  USER_UPDATE_REQUEST,
  CHECK_INVITE_CODE_REQUEST,
  CHECK_ORDER_REQUEST
} from './constants';

import {
  makeSelectPhone,
  makeSelectCode,
  makeSelectInvitationCode,
  makeSelectUser,
  makeSelectOrderId
} from './selectors';

import {
  sendCreateSuccess,
  sendCreateFail,
  sendVerifySuccess,
  sendVerifyFail,
  userReadSuccess,
  userReadFail,
  userUpdateSuccess,
  userUpdateFail,
  checkInviteCodeSuccess,
  checkInviteCodeFail,
  checkOrderSuccess,
  checkOrderFail
} from './actions';

function* sendCreateSaga() {
  const url = `${apiUrl}/api/staff/auth/login/phone_number`;
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const options = {
    method: 'post',
    data: {
      phone_number
    }
  };

  try {
    yield call(axios, url, options);
    // if (service === "my-yadro") {
      yield sendVerifySaga();
    // } else {
    //   yield put(sendCreateSuccess());
    // }
  } catch (e) {
    yield put(sendCreateFail());
  }
}

function* sendVerifySaga() {
  const url = `${apiUrl}/api/staff/auth/login/phone_number/verify`;
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const passcode = yield select(makeSelectCode());
  const invitationCode = yield select(makeSelectInvitationCode());
  const options = {
    method: 'post',
    data: {
      phone_number,
      passcode
    }
  };

  try {
    const request = yield call(axios, url, options);
    const key = request.data.key;
    localStorage.setItem('staffKey', key);
    localStorage.setItem('bca3f30e916c75160a384d93542b0bc2', 'true');

    yield put(sendVerifySuccess());

    if (!isUndefined(invitationCode)) {
      yield put(push(`/test/registration/${invitationCode}`));
    } else {
      yield put(push(`/test/registration`));
    }
  } catch (e) {
    yield put(sendVerifyFail());
  }
}

function* userReadSaga() {
  const url = `${apiUrl}/api/staff/auth/user`;
  const key = localStorage.getItem('staffKey');
  const invitationCode = yield select(makeSelectInvitationCode());
  const options = {
    headers: {
      Authorization: `Token ${key}`
    }
  };

  try {
    const request = yield call(axios, url, options);
    const user = fromJS(request.data);

    yield put(
      userReadSuccess({
        user
      })
    );

    if (
      user.get('email').length > 0 &&
      user.get('first_name').length > 0 &&
      user.get('last_name').length > 0
    ) {
      if(invitationCode) {
        yield put(push(`/test/about/${invitationCode}`));
      } else {
        yield put(push('/test/about'));
      }
    }
  } catch (e) {
    yield put(userReadFail());
  }
}

function* userUpdateSaga() {
  const url = `${apiUrl}/api/staff/auth/user`;
  const user = yield select(makeSelectUser());
  const key = localStorage.getItem('staffKey');
  const invitationCode = yield select(makeSelectInvitationCode());

  const options = {
    method: 'put',
    headers: {
      Authorization: `Token ${key}`
    },
    data: user.toJS()
  };

  try {
    yield call(axios, url, options);
    yield put(userUpdateSuccess());
    if(invitationCode) {
      yield put(push(`/test/about/${invitationCode}`));
    } else {
      yield put(push('/test/about'));
    }
  } catch (e) {
    yield put(userUpdateFail());
  }
}

function* checkInviteCodeSaga() {
  const url = `${apiUrl}/api/staff/invitations/validate`;
  const invite = yield select(makeSelectInvitationCode());
  const options = {
    method: 'post',
    data: {
      invitation_code: invite
    }
  };

  try {
    yield call(axios, url, options);

    yield put(checkInviteCodeSuccess());
  } catch (e) {
    yield put(checkInviteCodeFail());
    yield put(push('/error'));
  }
}

function* checkOrderIdSaga() {
  const key = localStorage.getItem('staffKey');
  const orderId = yield select(makeSelectOrderId());
  const url = `${apiUrl}/api/staff/auth/user/check_is_paid?orderId=${orderId}`;
  const invitationCode = yield select(makeSelectInvitationCode());

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${key}`
    }
  };

  try {
    const request = yield call(axios, url, options);

    yield put(checkOrderSuccess({
      isPaid: request.data.is_paid,
    }));
  } catch (e) {
    yield put(checkOrderFail());
  } finally {
    if(invitationCode) {
      yield put(push(`/test/about/${invitationCode}`));
    } else {
      yield put(push('/test/about'));
    }
  }
}

export default function* testStartSaga() {
  yield takeLeading(SEND_CREATE_REQUEST, sendCreateSaga);
  yield takeLeading(SEND_VERIFY_REQUEST, sendVerifySaga);
  yield takeLeading(USER_READ_REQUEST, userReadSaga);
  yield takeLeading(USER_UPDATE_REQUEST, userUpdateSaga);
  yield takeLeading(CHECK_INVITE_CODE_REQUEST, checkInviteCodeSaga);
  yield takeLeading(CHECK_ORDER_REQUEST, checkOrderIdSaga);
}
