import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button, { ButtonGroup } from '@atlaskit/button';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import Select from '@atlaskit/select';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Pagination from '@atlaskit/pagination';
import isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';

import Center from 'components/Center';
import CustomModal from 'components/CustomModal';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { getManagersList } from 'pages/Home/actions';
import { makeSelectManagersList } from 'pages/Home/selectors';

import {
  changeManagersFilter,
  changeNameFilter,
  changeProfilePage,
  changeSort,
  profileList,
  deleteProfile
} from './actions';
import {
  makeSelectManagersFilter,
  makeSelectProfileCount,
  makeSelectProfileLoading,
  makeSelectProfileList,
  makeSelectProfilePage,
  makeSelectSort,
  makeSelectTableLoading
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import P from './styled/P';

import Table from './Table';

class ProfileList extends React.Component {
  state = {
    addModals: [],
    flags: [],
    nameFilter: '',
    filterWasChanged: false
  };

  componentDidMount() {
    this.sendChangedNameFilter = debounce(this.sendChangedNameFilter, 300);

    if (!isUndefined(this.props.location.state)) {
      this.setState({
        addModals: this.props.location.state.addModals
      });
    }

    this.props.getProfileList();
    this.props.getManagersList();
  }

  componentWillReceiveProps(nextProps) {
    if (!isUndefined(nextProps.location.state)) {
      this.setState({
        addModals: nextProps.location.state.addModals
      });

      this.props.history.replace({
        pathname: '/profile/list',
        state: undefined
      });
    }
  }

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button
        appearance="primary"
        onClick={() =>
          this.setState({
            addModals: [1]
          })
        }
      >
        Создать профиль
      </Button>
    </ButtonGroup>
  );

  closeModal = id => {
    this.setState({ addModals: this.state.addModals.filter(i => i !== id) });
  };

  addFlag = id => {
    this.setState({
      flags: [this.state.flags.length, ...this.state.flags],
      addModals: this.state.addModals.filter(i => i !== id)
    });
  };

  removeFlag = id => this.setState({ flags: this.state.flags.filter(v => v !== id) });

  onCreateButton = path => {
    this.props.history.push(path);
  };

  onChangeFilter = event => {
    this.props.changeManagersFilter({
      managerFilter: event.value
    });
  };

  onChangeNameFilter = event => {
    this.sendChangedNameFilter(event.target.value);
    this.setState({
      filterWasChanged: true,
      nameFilter: event.target.value
    });
  };

  sendChangedNameFilter = nameFilter => {
    this.props.changeNameFilter({
      nameFilter
    });
  };

  onSort = (key, order) => {
    this.props.changeSort({
      key,
      order
    });
  };

  render() {
    const {
      changeProfilePage,
      deleteProfile,
      managersFilter,
      managersList,
      profileCount,
      profileList,
      profileLoading,
      profilePage,
      sort,
      tableLoading
    } = this.props;

    const { filterWasChanged, nameFilter } = this.state;

    let content = <Spinner />;

    if (!profileLoading && (profileList.length > 0 || filterWasChanged || managersFilter > 0)) {
      const selectManagersHr = managersList.map(manager => ({
        label: manager.get('full_name'),
        value: manager.get('id')
      }));

      const selectItems = [{ label: 'Все HR-менеджеры', value: 0 }, ...selectManagersHr.toJS()];

      const barContent = (
        <ButtonGroup>
          <TextField
            isLabelHidden
            label="hidden"
            onChange={this.onChangeNameFilter}
            placeholder="Название профиля"
            value={nameFilter}
          />
          <Select
            className="single-select"
            defaultOptions={
              selectItems[
                selectManagersHr.findIndex(manager => manager.value === managersFilter) + 1
              ]
            }
            options={selectItems}
            onChange={this.onChangeFilter}
            placeholder="Все HR-менеджеры"
            value={
              selectItems[
                selectManagersHr.findIndex(manager => manager.value === managersFilter) + 1
              ]
            }
          />
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.getPageHeaderButtonsDrawer()} bottomBar={barContent}>
              Профили
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Table
              data={profileList}
              isLoading={tableLoading}
              onDelete={deleteProfile}
              onSort={this.onSort}
              sortKey={sort.get('key')}
              sortOrder={sort.get('order')}
            />
            {!tableLoading && (
              <Center>
                <Pagination
                  value={profilePage}
                  total={Math.ceil(profileCount / 25)}
                  onChange={profilePage => changeProfilePage({ profilePage })}
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!profileLoading && profileList.length === 0) {
      content = (
        <Empty header="" text="Нажмите на кнопку чтобы создать новый профиль">
          <Button
            appearance="primary"
            onClick={() =>
              this.setState({
                addModals: [1]
              })
            }
          >
            Создать профиль
          </Button>
        </Empty>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Профили</title>
        </Helmet>

        <Page>
          {content}
          {this.state.addModals.map(id => (
            <CustomModal
              autoFocus={false}
              key={id}
              id={id}
              heading="Создать профиль"
              onClose={this.closeModal}
              actions={[
                {
                  text: 'Отменить',
                  onClick: this.closeModal
                }
              ]}
            >
              <P marginTop="9px">Выберите способ создания нового профиля</P>
              <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
                <div>
                  <FieldGroup marginTop="23px">
                    <h4>Путем сравнения</h4>
                  </FieldGroup>
                  <FieldGroup>
                    <Field width="456px">
                      <P>
                        Вы можете создать профиль должности путем сравнения ваших лучших и худших
                        кадров и затем вручную внести изменения.
                      </P>
                    </Field>
                    <Field align="center" width="83px">
                      <Button
                        onClick={() => {
                          this.onCreateButton('/profile/create/compare/1');
                        }}
                      >
                        Создать
                      </Button>
                    </Field>
                  </FieldGroup>
                </div>
                <div>
                  <FieldGroup marginTop="23px">
                    <h4>С помощью опросника</h4>
                  </FieldGroup>
                  <FieldGroup>
                    <Field width="456px">
                      <P>
                        Создать профиль должности вы можете ответив на вопросы о том, какими
                        качествами должны обладать кандидаты.
                      </P>
                    </Field>
                    <Field align="center" width="83px">
                      <Button
                        onClick={() => {
                          this.onCreateButton('/profile/create/ask/1');
                        }}
                      >
                        Создать
                      </Button>
                    </Field>
                  </FieldGroup>
                </div>
                <div>
                  <FieldGroup marginTop="23px">
                    <h4>Отправить опрос руководителю</h4>
                  </FieldGroup>
                  <FieldGroup>
                    <Field width="456px">
                      <P>Профиль будет создан после прохождения опроса вашим руководителем.</P>
                    </Field>
                    <Field align="center" width="83px">
                      <Button
                        onClick={() => {
                          this.onCreateButton('/profile/create/chief');
                        }}
                      >
                        Создать
                      </Button>
                    </Field>
                  </FieldGroup>
                </div>
                <div>
                  <FieldGroup marginTop="23px">
                    <h4>Вручную</h4>
                  </FieldGroup>
                  <FieldGroup>
                    <Field width="456px">
                      <P>
                        Вы можете создать профиль вручную и указать конкретные интервалы для каждого
                        метапрограммного паттерна.
                      </P>
                    </Field>
                    <Field align="center" width="83px">
                      <Button
                        onClick={() => {
                          this.onCreateButton('/profile/create/custom');
                        }}
                      >
                        Создать
                      </Button>
                    </Field>
                  </FieldGroup>
                </div>
              </Form>
            </CustomModal>
          ))}

          <FlagGroup onDismissed={name => this.removeFlag(name)}>
            {this.state.flags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={`${id}`}
                title="Сотрудник добавлен, ссылка на тестирование отправлена"
              />
            ))}
          </FlagGroup>
        </Page>
      </Fragment>
    );
  }
}

ProfileList.propTypes = {
  changeManagersFilter: PropTypes.func,
  changeNameFilter: PropTypes.func,
  changeProfilePage: PropTypes.func,
  changeSort: PropTypes.func,
  deleteProfile: PropTypes.func,
  getManagersList: PropTypes.func,
  getProfileList: PropTypes.func,
  managersFilter: PropTypes.number,
  managersList: PropTypes.object,
  profileCount: PropTypes.number,
  profileList: PropTypes.array,
  profileLoading: PropTypes.bool,
  profilePage: PropTypes.number,
  sort: PropTypes.object,
  tableLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeManagersFilter: value => dispatch(changeManagersFilter(value)),
    changeNameFilter: value => dispatch(changeNameFilter(value)),
    changeProfilePage: value => dispatch(changeProfilePage(value)),
    changeSort: value => dispatch(changeSort(value)),
    deleteProfile: value => dispatch(deleteProfile(value)),
    getManagersList: () => dispatch(getManagersList()),
    getProfileList: () => dispatch(profileList())
  };
}

const mapStateToProps = createStructuredSelector({
  managersFilter: makeSelectManagersFilter(),
  managersList: makeSelectManagersList(),
  profileCount: makeSelectProfileCount(),
  profileLoading: makeSelectProfileLoading(),
  profileList: makeSelectProfileList(),
  profilePage: makeSelectProfilePage(),
  sort: makeSelectSort(),
  tableLoading: makeSelectTableLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileList);
