import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import ClientInformationBase from './components/ClientInformationBase';

const ClientInformationView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <ClientInformationBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default ClientInformationView;
