import { call, put, takeLeading } from 'redux-saga/effects';

import {
  getCompareListSuccess,
  getCompareListFail
} from './actions';

import {
  GET_COMPARE_LIST_REQUEST
} from './constants';

import axios from "axios";
import apiUrl from "../../../utils/serverConfig";

function* getCompareListSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    }
  };
  try {
    const request = yield call(axios, `${apiUrl}/api/staff/report/all/`, options);

    const compareResult = {};

    request.data.forEach(report => {
      compareResult[report.id] = {
        id: report.id,
        staffId: report.staff.id,
        created_at: report.created_at,
        staff: {
          fullName: report.staff.full_name,
          firstName: report.staff.first_name,
          lastName: report.staff.last_name
        },
        sections: report.sections.map(section => ({
          id: section.id,
          title: section.title,
          subsections: section.subsections.map(subsection => ({
            id: subsection.id,
            title: subsection.title,
            value: subsection.value
          }))
        }))
      };
    });

    const sections = request.data[0].sections.map(section => ({
      id: section.id,
      title: section.title,
      subsections: section.subsections.map(subsection => ({
        id: subsection.id,
        title: subsection.title
      }))
    }));

    yield put(
      getCompareListSuccess({
        compareResult,
        sections
      })
    );
  } catch (e) {
    yield put(getCompareListFail());
  }
}

export default function* compareListSagas() {
  yield takeLeading(GET_COMPARE_LIST_REQUEST, getCompareListSaga);
}
