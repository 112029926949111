import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 15px 8px;
`;

const Content = ({ data, elementProps }) => <Wrapper {...elementProps}>{data.content}</Wrapper>;

export default Content;
