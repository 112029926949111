export const CHANGE_LOGIN_FIELD = 'hh/pages/partner/auth/CHANGE_LOGIN_FIELD';
export const CHANGE_FLAGS = 'hh/pages/partner/auth/CHANGE_FLAGS';
export const SEND_LOGIN = 'hh/pages/partner/auth/SEND_LOGIN';
export const SEND_LOGIN_SUCCESS = 'hh/pages/partner/auth/SEND_LOGIN_SUCCESS';
export const SEND_LOGIN_FAIL = 'hh/pages/partner/auth/SEND_LOGIN_FAIL';
export const CHANGE_REGISTRATION_FIELD = 'hh/pages/partner/auth/CHANGE_REGISTRATION_FIELD';
export const SEND_PASSCODE = 'hh/pages/partner/auth/SEND_PASSCODE';
export const SEND_PASSCODE_SUCCESS = 'hh/pages/partner/auth/SEND_PASSCODE_SUCCESS';
export const SEND_PASSCODE_FAIL = 'hh/pages/partner/auth/SEND_PASSCODE_FAIL';
export const SEND_REGISTRATION = 'hh/pages/partner/auth/SEND_REGISTRATION';
export const SEND_REGISTRATION_SUCCESS = 'hh/pages/partner/auth/SEND_REGISTRATION_SUCCESS';
export const SEND_REGISTRATION_FAIL = 'hh/pages/partner/auth/SEND_REGISTRATION_FAIL';
export const SEND_OTP = 'hh/pages/partner/auth/SEND_OTP';
export const SEND_OTP_SUCCESS = 'hh/pages/partner/auth/SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'hh/pages/partner/auth/SEND_OTP_FAIL';
export const CHANGE_RESET_FIELD = 'hh/pages/partner/auth/CHANGE_RESET_FIELD';
export const CREATE_PASSWORD_RESET = 'hh/pages/partner/auth/CREATE_PASSWORD_RESET';
export const CREATE_PASSWORD_RESET_SUCCESS = 'hh/pages/partner/auth/CREATE_PASSWORD_RESET_SUCCESS';
export const CREATE_PASSWORD_RESET_FAIL = 'hh/pages/partner/auth/CREATE_PASSWORD_RESET_FAIL';
export const CONFIRM_PASSWORD_RESET = 'hh/pages/partner/auth/CONFIRM_PASSWORD_RESET';
export const CONFIRM_PASSWORD_RESET_SUCCESS =
  'hh/pages/partner/auth/CONFIRM_PASSWORD_RESET_SUCCESS';
export const CONFIRM_PASSWORD_RESET_FAIL = 'hh/pages/partner/auth/CONFIRM_PASSWORD_RESET_FAIL';
