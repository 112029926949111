import styled from 'styled-components';

const Text = styled.div`
  font-size: 20px;
  color: #172b4d;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin-top: 27px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  @media (max-width: 1020px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
    width: 100%;
  }
`;

export default Text;
