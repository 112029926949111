import React, { Fragment } from 'react';
import ProfileRootView from 'navigation/routes/Partner/RootView';
import SetActiveView from 'navigation/routes/SetActiveView';

import EditPage from 'pages/PartnerProfile/Requisites/Edit';

const EditRequisitesView = () => (
  <Fragment>
    <ProfileRootView />
    <SetActiveView id="partner/settings" />
    <EditPage />
  </Fragment>
);

export default EditRequisitesView;
