import {
  REPORT_LIST_REQUEST,
  REPORT_LIST_REQUEST_SUCCESS,
  REPORT_LIST_REQUEST_FAIL,
  REPORT_LIST_REQUEST_NO_CONTENT_ERROR,
  CREATE_DISLIKE,
  DISLIKE_REQUEST,
  DISLIKE_REQUEST_SUCCESS,
  DISLIKE_REQUEST_FAIL,
  LIKE_REQUEST,
  LIKE_REQUEST_SUCCESS,
  LIKE_REQUEST_FAIL,
  FIRE_REQUEST,
  FIRE_REQUEST_SUCCESS,
  FIRE_REQUEST_FAIL,
  CREATE_NEW_COMMENT_BLOCK,
  CHANGE_COMMENT_TEXT,
  HIDE_COMMENT_BLOCK,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAIL,
  CREATE_EDIT_COMMENT_BLOCK,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  CHANGE_EMAIL_MODALS,
  CHANGE_EMAIL_FLAGS,
  CHANGE_EMAIL_TEXT,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  CHANGE_REPORT_SHAPE,
  CHANGE_ORDER_REPORT_BY_RANK
} from './constans';

export function createDislike(payload) {
  return {
    type: CREATE_DISLIKE,
    ...payload
  };
}

export function changeEmailModals(payload) {
  return {
    type: CHANGE_EMAIL_MODALS,
    ...payload
  };
}

export function changeEmailFlags(payload) {
  return {
    type: CHANGE_EMAIL_FLAGS,
    ...payload
  };
}

export function changeEmailText(payload) {
  return {
    type: CHANGE_EMAIL_TEXT,
    ...payload
  };
}

export function sendEmail(payload) {
  return {
    type: SEND_EMAIL_REQUEST,
    ...payload
  };
}

export function sendEmailSuccess(payload) {
  return {
    type: SEND_EMAIL_SUCCESS,
    ...payload
  };
}

export function sendEmailFail(payload) {
  return {
    type: SEND_EMAIL_FAIL,
    ...payload
  };
}

export function deleteComment(payload) {
  return {
    type: DELETE_COMMENT_REQUEST,
    ...payload
  };
}

export function deleteCommentSuccess() {
  return {
    type: DELETE_COMMENT_SUCCESS
  };
}

export function deleteCommentFail() {
  return {
    type: DELETE_COMMENT_FAIL
  };
}

export function createEditComment(payload) {
  return {
    type: CREATE_EDIT_COMMENT_BLOCK,
    ...payload
  };
}

export function createCommentRequest() {
  return {
    type: CREATE_COMMENT_REQUEST
  };
}

export function createCommentSuccess(payload) {
  return {
    type: CREATE_COMMENT_SUCCESS,
    ...payload
  };
}

export function createCommentFail() {
  return {
    type: CREATE_COMMENT_FAIL
  };
}

export function hideCommentBlock() {
  return {
    type: HIDE_COMMENT_BLOCK
  };
}

export function createNewCommentBlock(payload) {
  return {
    type: CREATE_NEW_COMMENT_BLOCK,
    ...payload
  };
}

export function changeCommentText(payload) {
  return {
    type: CHANGE_COMMENT_TEXT,
    ...payload
  };
}

export function getReportList() {
  return {
    type: REPORT_LIST_REQUEST
  };
}

export function getReportListSuccess(payload) {
  return {
    type: REPORT_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function getReportListFail(payload) {
  return {
    type: REPORT_LIST_REQUEST_FAIL,
    ...payload
  };
}

export function getReportListNoContentError(payload) {
  return {
    type: REPORT_LIST_REQUEST_NO_CONTENT_ERROR,
    ...payload
  };
}

export function dislikeSection(payload) {
  return {
    type: DISLIKE_REQUEST,
    ...payload
  };
}

export function dislikeSectionSuccess(payload) {
  return {
    type: DISLIKE_REQUEST_SUCCESS,
    ...payload
  };
}

export function dislikeSectionFail(payload) {
  return {
    type: DISLIKE_REQUEST_FAIL,
    ...payload
  };
}

export function likeSection(payload) {
  return {
    type: LIKE_REQUEST,
    ...payload
  };
}

export function likeSectionSuccess(payload) {
  return {
    type: LIKE_REQUEST_SUCCESS,
    ...payload
  };
}

export function likeSectionFail(payload) {
  return {
    type: LIKE_REQUEST_FAIL,
    ...payload
  };
}

export function fireSection(payload) {
  return {
    type: FIRE_REQUEST,
    ...payload
  };
}

export function fireSectionSuccess(payload) {
  return {
    type: FIRE_REQUEST_SUCCESS,
    ...payload
  };
}

export function fireSectionFail(payload) {
  return {
    type: FIRE_REQUEST_FAIL,
    ...payload
  };
}

export function changeReportShape(payload) {
  return {
    type: CHANGE_REPORT_SHAPE,
    ...payload
  };
}

export function changeOrderReportByRank(payload) {
  return {
    type: CHANGE_ORDER_REPORT_BY_RANK,
    ...payload
  };
}

export function userReportsRequest(payload) {
  return {
    type: FIRE_REQUEST,
    ...payload
  };
}
