import styled from 'styled-components';
import bg from './logo_sm.png';

const Logo = styled.div`
  background-image: url(${bg});
  background-size: contain;
  height: 24px;
  width: 24px;
`;

export default Logo;
