import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import VideoPage from 'pages/Video';

const VideoView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <VideoPage />
  </Fragment>
);

export default VideoView;
