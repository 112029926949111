import { call, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import apiUrl from 'utils/serverConfig';

import { GET_EDUCATIONS_LIST_REQUEST } from './constants';

import { getEducationsListSuccess, getEducationsListFail } from './actions';

function* getVideoSaga() {
  const staffKey = localStorage.getItem('staffKey');
  const url = `${apiUrl}/api/staff/education_sections/`;
  const options = {
    headers: {
      Authorization: `Token ${staffKey}`
    }
  };

  try {
    const request = yield call(axios, url, options);

    yield put(
      getEducationsListSuccess({
        sections: request.data
      })
    );
  } catch (e) {
    yield put(getEducationsListFail());
  }
}

export default function* videoSagas() {
  yield takeLeading(GET_EDUCATIONS_LIST_REQUEST, getVideoSaga);
}
