import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Clients/reducer';
import saga from 'pages/PartnerProfile/Clients/saga';

import {
  changeFilterField,
  changeManagersPage,
  getManagersList,
  getClient
} from 'pages/PartnerProfile/Clients/actions';

import {
  makeSelectClientRead,
  makeSelectManagersCount,
  makeSelectManagersList,
  makeSelectManagersLoading,
  makeSelectManagersPage,
  makeSelectManagersSearch
} from 'pages/PartnerProfile/Clients/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';

import ManagersTable from './Table';

class Clients extends Component {
  state = {
    managersFilter: this.props.managersFilter
  };

  componentDidMount() {
    this.sendFilterToState = debounce(this.sendFilterToState, 300);
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { client, getManagersList, getClient }
    } = this;

    if (client.id !== id) {
      getClient({ id });
    }

    getManagersList({ id });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeFilterField = event => {
    this.sendFilterToState(event.target.value);
    this.setState({
      managersFilter: event.target.value
    });
  };

  sendFilterToState = clientsFilter => {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { changeFilterField }
    } = this;

    changeFilterField({
      id,
      field: 'managers',
      text: clientsFilter
    });
  };

  renderBottomBar = () => {
    const {
      onChangeFilterField,
      state: { managersFilter }
    } = this;

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 200px' }}>
          <TextField
            isCompact
            isLabelHidden
            label="hidden"
            onChange={onChangeFilterField}
            placeholder="Имя или фамилия"
            shouldFitContainer
            value={managersFilter}
          />
        </div>
      </div>
    );
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { client }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/clients"
          onClick={event => onBreadcrumbsClick(event, '/partner/clients')}
          text="Клиенты"
          key="clients"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}`)}
          text={client.name}
          key="clientName"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}/managers`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}/managers`)}
          text="Менеджеры"
          key="clientManagers"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      props: { changeManagersPage, count, list, page, client, managersFilter, tableLoading },
      renderBottomBar,
      renderBreadcrumbs
    } = this;

    const header = (
      <GridColumn medium={12}>
        <PageHeader breadcrumbs={renderBreadcrumbs()} bottomBar={renderBottomBar()}>
          Менеджеры
        </PageHeader>
      </GridColumn>
    );

    let content = (
      <Fragment>
        {header}
        <GridColumn medium={12}>
          <Empty header="У партнера еще нет менеджеров" />
        </GridColumn>
      </Fragment>
    );

    if (count >= 1 || !isEmpty(managersFilter)) {
      content = (
        <Fragment>
          {header}
          <GridColumn medium={12}>
            <ManagersTable key="clientsManagersTable" data={list} isLoading={tableLoading} />
            <Pagination
              key="pagination"
              value={page}
              total={Math.ceil(count / 25)}
              onChange={page => changeManagersPage({ page, id: client.id })}
            />
          </GridColumn>
        </Fragment>
      );
    } else if (tableLoading) {
      content = (
        <GridColumn medium={12}>
          <Spinner height="80vh" />
        </GridColumn>
      );
    }

    return <Grid layout="fluid">{content}</Grid>;
  };

  render() {
    const {
      props: { client },
      renderContent
    } = this;

    return (
      <Page>
        <Helmet>
          <title>{client.name}</title>
        </Helmet>
        {renderContent()}
      </Page>
    );
  }
}

Clients.propTypes = {
  changeFilterField: PropTypes.func.isRequired,
  changeManagersPage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  getClient: PropTypes.func.isRequired,
  getManagersList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  managersFilter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  tableLoading: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFilterField: value => dispatch(changeFilterField(value)),
    changeManagersPage: value => dispatch(changeManagersPage(value)),
    getClient: value => dispatch(getClient(value)),
    getManagersList: value => dispatch(getManagersList(value))
  };
}

const mapStateToProps = createStructuredSelector({
  client: makeSelectClientRead(),
  count: makeSelectManagersCount(),
  list: makeSelectManagersList(),
  managersFilter: makeSelectManagersSearch(),
  page: makeSelectManagersPage(),
  tableLoading: makeSelectManagersLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerClients', reducer });
const withSaga = injectSaga({ key: 'partnerClients', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Clients);
