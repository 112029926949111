import React from 'react';
import PropTypes from 'prop-types';
import Reorder, { reorder } from 'react-reorder';
import Answer from './Answer';

class AnswerList extends React.Component {
  state = {
    list: this.props.answers
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      list: nextProps.answers
    });
  }

  onReorder = (event, previousIndex, nextIndex) => {
    const newList = reorder(this.state.list, previousIndex, nextIndex);
    this.setState({
      list: newList
    });

    this.props.onReorder(this.props.number - 1, newList);
  };

  render() {
    if (this.state.list.length > 0) {
      return (
        <Reorder
          reorderId="my-list"
          reorderGroup="reorder-group"
          draggedClassName="dragged"
          lock="horizontal"
          autoScroll={true}
          onReorder={this.onReorder}
        >
          {this.state.list.map((item, index) => (
            <div key={index}>
              <Answer answer={item} />
            </div>
          ))}
        </Reorder>
      );
    }

    return <div />;
  }
}

AnswerList.propTypes = {
  answers: PropTypes.array.isRequired
};

AnswerList.defaultProps = {
  answers: []
};

export default AnswerList;
