import styled from 'styled-components';

const AllWidthColumn = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 50%;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export default AllWidthColumn;
