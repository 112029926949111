import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isGuest } from 'utils/partnerRequest';
import isUndefined from 'lodash/isUndefined';

import reducer from 'pages/PartnerProfile/Auth/reducer';
import saga from 'pages/PartnerProfile/Auth/saga';

import {
  changeFlags,
  changeLoginField,
  sendLogin,
  sendOtp
} from 'pages/PartnerProfile/Auth/actions';

import {
  makeSelectLoginError,
  makeSelectLoginFlags,
  makeSelectLoginForm,
  makeSelectLoginLoading,
  makeSelectOtpLoading
} from 'pages/PartnerProfile/Auth/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import Page from '@atlaskit/page';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Spinner from 'components/Spinner';
import ButtonField from 'pages/Login/styled/ButtonField';
import FieldGroup from 'pages/Login/styled/FieldGroup';
import Field from 'pages/Login/styled/Field';
import Flex from 'pages/Login/styled/Flex';
import FlexItem from 'pages/Login/styled/FlexItem';
import Image from 'pages/Login/images/pic.png';
import Image2x from 'pages/Login/images/pic-2x.png';
import Title from 'pages/Login/styled/Title';
import P from 'pages/Login/styled/P';

import TextField from 'components/TextField';
import ContactsWrapper from "../../../IndividualProfile/Login/styled/ContactsWrapper";
import Telegram from "../../../../promo/Footer/telegram";
import WhatsApp from "../../../../promo/Footer/whatsapp";

class Login extends Component {
  state = {
    formValid: {
      email: true,
      password: true
    }
  };

  componentDidMount() {
    if (!isGuest()) {
      const {
        props: {
          history: { push }
        }
      } = this;

      push('/partner/clients');
    }

    const sendOtp = this.props.sendOtp;
    const otp = this.props.match.params.otp;
    if (!isUndefined(otp)) {
      sendOtp({ otp });
    }
  }

  onChangeFormField = event => {
    const {
      props: { changeLoginField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeLoginField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { form, sendLogin },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: form[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      sendLogin();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'email':
        result = isEmail(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              email: result
            }
          });
        }

        return result;
      case 'password':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              password: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { form, loading },
      state: { formValid }
    } = this;

    return (
      <Form name="submitForm">
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!formValid.email}
              required
              label="Email"
              name="email"
              id="email"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.email}
              shouldFitContainer
            />
          </Field>
          <Field>
            <TextField
              isInvalid={!formValid.password}
              required
              label="Пароль"
              name="password"
              id="password"
              onBlur={onChangeFormField}
              onChange={onChangeFormField}
              placeholder=""
              value={form.password}
              shouldFitContainer
              type="password"
            />
            <P marginTop="5px">
              <Link to="/partner/reset">Забыли пароль?</Link>
            </P>
          </Field>
        </FieldGroup>

        <FieldGroup>
          <ButtonField>
            <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
              Войти
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );
  };

  renderFlags = () => {
    const {
      props: { changeFlags, error, flags }
    } = this;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'login',
        flags: flags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {flags.map(id => (
          <Flag
            description={error}
            isDismissAllowed
            id={id}
            icon={<Error label="Error" primaryColor={colors.R300} />}
            key={`${id}`}
            title="Ошибка"
          />
        ))}
      </FlagGroup>
    );
  };

  renderContent = () => {
    const {
      props: { otpLoading },
      renderForm,
      renderFlags
    } = this;

    if (otpLoading) {
      return <Spinner height="80vh" />;
    }

    return (
      <Flex>
        <FlexItem align="center" hideOnMobile>
          <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
        </FlexItem>
        <FlexItem>
          <Title>Вход для партнеров</Title>
          <P marginTop="3px" color="#6B778C">
            Еще не зарегистрированы? <Link to="/partner/signup">Регистрация</Link>
          </P>

          {renderForm()}
          {renderFlags()}

          <ContactsWrapper>
            <a href="https://t.me/eg0rgaiduk" target="_blank" rel="noopener noreferrer">
              <Telegram />
            </a>
            <a href="https://api.whatsapp.com/send/?phone=79830007000" target="_blank" rel="noopener noreferrer">
              <WhatsApp />
            </a>
          </ContactsWrapper>
        </FlexItem>
      </Flex>
    );
  };

  render() {
    const { renderContent } = this;

    return (
      <Page>
        <Helmet>
          <title>Вход в кабинет партнера</title>
        </Helmet>

        {renderContent()}
      </Page>
    );
  }
}

Login.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changeLoginField: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  flags: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  otpLoading: PropTypes.bool.isRequired,
  sendLogin: PropTypes.func.isRequired,
  sendOtp: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeLoginField: value => dispatch(changeLoginField(value)),
    sendLogin: () => dispatch(sendLogin()),
    sendOtp: value => dispatch(sendOtp(value))
  };
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectLoginError(),
  flags: makeSelectLoginFlags(),
  form: makeSelectLoginForm(),
  loading: makeSelectLoginLoading(),
  otpLoading: makeSelectOtpLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerAuth', reducer });
const withSaga = injectSaga({ key: 'partnerAuth', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Login);
