import styled from 'styled-components';

const Block = styled.section`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};
  display: flex;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    flex-direction: column-reverse;
    padding: 30px 0;
    width: calc(100% - 30px);
  }
`;

export default Block;
