import styled from 'styled-components';

const Field = styled.div`
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export default Field;
