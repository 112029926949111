import styled from 'styled-components';

const Left = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-right: 40px;

  @media (max-width: 1020px) {
    align-items: flex-start;
    width: calc(100% - 30px);
  }
`;

export default Left;
