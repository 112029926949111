import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';
import Empty from 'components/Empty';
import RouterLink from 'components/RouterLink';
import Spinner from 'components/Spinner';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeDeleteOrganizationModals,
  deleteOrganization,
  getOrganizationsList
} from '../actions';
import {
  makeSelectLoadingDelete,
  makeSelectOrganizationsCount,
  makeSelectOrganizationsList,
  makeSelectOrganizationsLoading,
  makeSelectOrganizationsMounted,
  makeSelectOrganizationsPage,
  makeSelectOrganizationDeleteModals
} from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import OrganizationsTable from '../table/OrganizationsTable';
import Center from '../../../components/Center';
import Pagination from '@atlaskit/pagination/dist/esm/components/Pagination';

class Organizations extends React.Component {
  componentDidMount() {
    this.props.getOrganizationsList({
      organizationsPage: 1,
      organizationsMounted: true
    });
  }

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onDeleteOrganization = organizationId => {
    this.props.deleteOrganization({
      organizationId
    });
  };

  render() {
    const {
      changeDeleteOrganizationModals,
      loadingDelete,
      organizationsCount,
      organizationsList,
      organizationsLoading,
      organizationsMounted,
      organizationsPage,
      organizationDeleteModals
    } = this.props;

    let content = <Spinner />;

    if (!organizationsMounted && (organizationsList.length > 0 || organizationsLoading)) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem text="Настройки" key="Settings" />
          <BreadcrumbsItem
            href="/settings/organizations/list"
            onClick={event => this.onBreadcumbsClick(event, '/settings/organizations/list')}
            text="Организации"
            key="Organizations"
          />
        </BreadcrumbsStateless>
      );

      const actions = (
        <ButtonGroup>
          <Button appearance="primary" href="/settings/organizations/create" component={RouterLink}>
            Добавить организацию
          </Button>
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={actions} breadcrumbs={breadcrumbs}>
              Организации
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <OrganizationsTable
              data={organizationsList}
              deleteModals={organizationDeleteModals}
              isLoading={organizationsLoading}
              isLoadingDelete={loadingDelete}
              onChangeDeleteModals={changeDeleteOrganizationModals}
              onDelete={this.onDeleteOrganization}
            />
            {!organizationsLoading && (
              <Center>
                <Pagination
                  value={organizationsPage}
                  total={Math.ceil(organizationsCount / 25)}
                  onChange={organizationsPage =>
                    this.props.getOrganizationsList({
                      organizationsPage: organizationsPage
                    })
                  }
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!organizationsMounted && organizationsList.length === 0) {
      content = (
        <Empty header="" text="Нажмите на кнопку чтобы добавить организацию">
          <Button appearance="primary" href="/settings/organizations/create" component={RouterLink}>
            Добавить организацию
          </Button>
        </Empty>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>Компания</title>
        </Helmet>

        {content}
      </Fragment>
    );
  }
}

Organizations.propTypes = {
  changeDeleteOrganizationModals: PropTypes.func,
  deleteOrganization: PropTypes.func,
  getOrganizationsList: PropTypes.func,
  loadingDelete: PropTypes.bool,
  organizationsCount: PropTypes.number,
  organizationsList: PropTypes.array,
  organizationsLoading: PropTypes.bool,
  organizationsMounted: PropTypes.bool,
  organizationsPage: PropTypes.number,
  organizationDeleteModals: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  return {
    changeDeleteOrganizationModals: value => dispatch(changeDeleteOrganizationModals(value)),
    deleteOrganization: value => dispatch(deleteOrganization(value)),
    getOrganizationsList: value => dispatch(getOrganizationsList(value))
  };
}

const mapStateToProps = createStructuredSelector({
  loadingDelete: makeSelectLoadingDelete(),
  organizationsCount: makeSelectOrganizationsCount(),
  organizationsList: makeSelectOrganizationsList(),
  organizationsLoading: makeSelectOrganizationsLoading(),
  organizationsMounted: makeSelectOrganizationsMounted(),
  organizationsPage: makeSelectOrganizationsPage(),
  organizationDeleteModals: makeSelectOrganizationDeleteModals()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Organizations);
