export const CHANGE_PHONE = 'hh/pages/test/registration/CHANGE_PHONE';
export const CHANGE_CODE = 'hh/pages/test/registration/CHANGE_CODE';

export const SEND_CREATE_REQUEST = 'hh/pages/test/registration/SEND_CREATE_REQUEST';
export const SEND_CREATE_REQUEST_SUCCESS = 'hh/pages/test/registration/SEND_CREATE_REQUEST_SUCCESS';
export const SEND_CREATE_REQUEST_FAIL = 'hh/pages/test/registration/SEND_CREATE_REQUEST_FAIL';

export const SEND_VERIFY_REQUEST = 'hh/pages/test/registration/SEND_VERIFY_REQUEST';
export const SEND_VERIFY_REQUEST_SUCCESS = 'hh/pages/test/registration/SEND_VERIFY_REQUEST_SUCCESS';
export const SEND_VERIFY_REQUEST_FAIL = 'hh/pages/test/registration/SEND_VERIFY_REQUEST_FAIL';

export const CHANGE_NAME = 'hh/pages/test/registration/CHANGE_NAME';
export const CHANGE_SURNAME = 'hh/pages/test/registration/CHANGE_SURNAME';
export const CHANGE_EMAIL = 'hh/pages/test/registration/CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'hh/pages/test/registration/CHANGE_PASSWORD';
export const CHANGE_GENDER = 'hh/pages/test/registration/CHANGE_GENDER';
export const CHANGE_DATE_OF_BIRTH = 'hh/pages/test/registration/CHANGE_DATE_OF_BIRTH';
export const CHANGE_TERMS_ACCEPTED = 'hh/pages/test/registration/CHANGE_TERMS_ACCEPTED';

export const USER_READ_REQUEST = 'hh/pages/test/registration/USER_READ_REQUEST';
export const USER_READ_REQUEST_SUCCESS = 'hh/pages/test/registration/USER_READ_REQUEST_SUCCESS';
export const USER_READ_REQUEST_FAIL = 'hh/pages/test/registration/USER_READ_REQUEST_FAIL';

export const USER_UPDATE_REQUEST = 'hh/pages/test/registration/USER_UPDATE_REQUEST';
export const USER_UPDATE_REQUEST_SUCCESS = 'hh/pages/test/registration/USER_UPDATE_REQUEST_SUCCESS';
export const USER_UPDATE_REQUEST_FAIL = 'hh/pages/test/registration/USER_UPDATE_REQUEST_FAIL';

export const CHECK_INVITE_CODE_REQUEST = 'hh/pages/test/registration/CHECK_INVITE_CODE_REQUEST';
export const CHECK_INVITE_CODE_REQUEST_SUCCESS =
  'hh/pages/test/registration/CHECK_INVITE_CODE_REQUEST_SUCCESS';
export const CHECK_INVITE_CODE_REQUEST_FAIL =
  'hh/pages/test/registration/CHECK_INVITE_CODE_REQUEST_FAIL';
