import { call, select, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import apiUrl from 'utils/serverConfig';

import {
  CHECK_INVITE_CODE_REQUEST,
  QUESTIONS_LIST_REQUEST,
  SEND_QUIZ_RESULT_REQUEST
} from './constants';

import {
  checkInviteCodeSuccess,
  checkInviteCodeFail,
  questionsListRequest,
  questionsListSuccess,
  questionsListFail,
  sendQuizResultSuccess,
  sendQuizResultFail
} from './actions';

import { makeSelectInviteCode, makeSelectAnswers } from './selectors';

function* checkInviteCodeSaga() {
  const inviteCode = yield select(makeSelectInviteCode());
  const url = `${apiUrl}/api/managers/profile_create_invitations/${inviteCode}/validate/`;

  try {
    yield call(axios, url);

    yield put(checkInviteCodeSuccess());
    yield put(questionsListRequest());
  } catch (e) {
    yield put(push('/404'));
    yield put(checkInviteCodeFail());
  }
}

function* questionsListSaga() {
  const url = `${apiUrl}/api/managers/questions/`;
  const inviteCode = yield select(makeSelectInviteCode());

  try {
    const request = yield call(axios, url);
    const answers = [];

    request.data.map(question =>
      answers.push({
        id: question.id,
        text: question.text,
        answers: question.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          value: false
        }))
      })
    );

    yield put(
      questionsListSuccess({
        answers: fromJS(answers)
      })
    );

    yield put(push(`/invite/p/${inviteCode}/1`));
  } catch (e) {
    yield put(push('/404'));
    yield put(questionsListFail());
  }
}

function* sendQuizResultSaga() {
  const url = `${apiUrl}/api/managers/profiles/create_from_questionnaire_and_invite/`;
  const inviteCode = yield select(makeSelectInviteCode());
  const answers = yield select(makeSelectAnswers());

  const options = {
    method: 'post',
    data: {
      profile_create_invitation_code: inviteCode,
      questions: answers
        .map(question => ({
          question: question.get('id'),
          answers: question.get('answers').reduce((result, answer) => {
            if (answer.get('value') === true) {
              result.push(answer.get('id'));
            }

            return result;
          }, [])
        }))
        .toJS()
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendQuizResultSuccess());
  } catch (e) {
    yield put(sendQuizResultFail());
  } finally {
    yield put(push(`/invite/p/${inviteCode}/success`));
  }
}
export default function* chiefQuizSagas() {
  yield takeLeading(CHECK_INVITE_CODE_REQUEST, checkInviteCodeSaga);
  yield takeLeading(QUESTIONS_LIST_REQUEST, questionsListSaga);
  yield takeLeading(SEND_QUIZ_RESULT_REQUEST, sendQuizResultSaga);
}
