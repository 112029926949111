import React from 'react';
import PropTypes from 'prop-types';

import Close from './styled/Close';
import Name from './styled/Name';
import Wrapper from './styled/Wrapper';
import NameContainer from './styled/NameContainer';
import ArrowWrapper from './styled/ArrowWrapper';
import ArrowContainer from './styled/ArrowContainer';

import ArrowLeftActive from "./arrow-left-active.svg";
import ArrowLeftInactive from "./arrow-left-inactive.svg";
import ArrowRightActive from "./arrow-right-active.svg";
import ArrowRightInactive from "./arrow-right-inactive.svg";

const StaffName = ({
  name,
  onClose,
  activeRight = true,
  activeLeft = true,
  onLeftArrowClick,
  onRightArrowClick
}) => (
  <NameContainer>
    <Wrapper>
      <Name>
        {name.length > 16 && `${name.substring(0, 16)}...`}
        {name.length <= 16 && name}
      </Name>
      <Close onClick={onClose} />
    </Wrapper>

    <ArrowContainer>
      <ArrowWrapper
        backgroundImage={activeLeft ? ArrowLeftActive : ArrowLeftInactive}
        onClick={activeLeft ? onLeftArrowClick : () => {}}
      />
      <ArrowWrapper
        backgroundImage={activeRight ? ArrowRightActive : ArrowRightInactive}
        onClick={activeRight ? onRightArrowClick : () => {}}
      />
    </ArrowContainer>
  </NameContainer>
);

StaffName.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default StaffName;
