import React from 'react';
import { UIControllerSubscriber } from '@atlaskit/navigation-next';

import PersonCompareBase from './components/PersonCompareBase';

const PersonCompareView = () => (
  <UIControllerSubscriber>
    {navigationUIController => (
      <PersonCompareBase navigationUIController={navigationUIController} />
    )}
  </UIControllerSubscriber>
);

export default PersonCompareView;
