import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { isPhoneInProfile } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from 'pages/PartnerProfile/User/reducer';
import saga from 'pages/PartnerProfile/User/saga';

import { changeUserField, updateUserCommon } from 'pages/PartnerProfile/User/actions';

import {
  makeSelectUserUpdateLoading,
  makeSelectUserForm
} from 'pages/PartnerProfile/User/selectors';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import TextField from 'components/TextField';
import FieldGroup from 'pages/MyProfile/styled/FieldGroup';
import Field from 'pages/MyProfile/styled/Field';

class CommonUser extends Component {
  state = {
    formValid: {
      phone_number: true,
      first_name: true
    }
  };

  onChangeFormField = event => {
    const {
      props: { changeUserField },
      validateForm
    } = this;

    const field = {
      field: event.target.id,
      text: event.target.value
    };

    changeUserField(field);
    validateForm(field);
  };

  onSubmitForm = event => {
    event.preventDefault();

    const {
      props: { updateUserCommon, user },
      state: { formValid },
      validateForm
    } = this;
    const formValidationResult = {};
    let isValid = true;

    Object.entries(formValid).forEach(([key]) => {
      const field = {
        field: key,
        text: user[key]
      };
      formValidationResult[key] = validateForm(field, false);

      if (!formValidationResult[key]) {
        isValid = false;
      }
    });

    this.setState({
      formValid: formValidationResult
    });

    if (isValid) {
      updateUserCommon();
    }
  };

  validateForm = ({ field, text }, setState = true) => {
    const { formValid } = this.state;
    let result = null;

    switch (field) {
      case 'first_name':
        result = !isEmpty(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              first_name: result
            }
          });
        }

        return result;
      case 'phone_number':
        result = isPhoneInProfile(text);
        if (setState) {
          this.setState({
            formValid: {
              ...formValid,
              phone_number: result
            }
          });
        }

        return result;
      default:
        return result;
    }
  };

  renderForm = () => {
    const {
      onChangeFormField,
      onSubmitForm,
      props: { loading, user },
      state: { formValid }
    } = this;

    return (
      <Form name="userCommon">
        <FieldGroup>
          <Field width="280px">
            <TextField
              isInvalid={!formValid.first_name}
              required
              label="Имя"
              id="first_name"
              name="first_name"
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={user.first_name}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field width="280px">
            <TextField
              label="Фамилия"
              name="last_name"
              id="last_name"
              onChange={onChangeFormField}
              placeholder=""
              shouldFitContainer
              value={user.last_name}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field width="280px">
            <TextField
              isInvalid={!formValid.phone_number}
              label="Телефон"
              mask="+79999999999"
              name="phone_number"
              id="phone_number"
              onChange={onChangeFormField}
              placeholder=""
              required
              shouldFitContainer
              value={user.phone_number}
            />
          </Field>
        </FieldGroup>
        <FieldGroup marginTop="40px">
          <Button appearance="primary" isLoading={loading} onClick={onSubmitForm}>
            Сохранить изменения
          </Button>
        </FieldGroup>
      </Form>
    );
  };

  render() {
    const { renderForm } = this;

    return renderForm();
  }
}

CommonUser.propTypes = {
  changeUserField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateUserCommon: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeUserField: value => dispatch(changeUserField(value)),
    updateUserCommon: () => dispatch(updateUserCommon())
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectUserUpdateLoading(),
  user: makeSelectUserForm()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerUser', reducer });
const withSaga = injectSaga({ key: 'partnerUser', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(CommonUser);
