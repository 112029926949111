import { fromJS } from 'immutable';

import {
  GET_PARTNERS_LIST,
  GET_PARTNERS_LIST_SUCCESS,
  GET_PARTNERS_LIST_FAIL,
  CHANGE_PARTNERS_PAGE,
  CHANGE_FLAGS,
  CHANGE_PARTNER_REGISTRATION_FIELD,
  RESET_PARTNER_REGISTRATION_FORM,
  REGISTER_NEW_PARTNER,
  REGISTER_NEW_PARTNER_SUCCESS,
  REGISTER_NEW_PARTNER_FAIL,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAIL,
  CHANGE_PARTNER_COMMENT_FIELD,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  CHANGE_CLIENTS_PAGE,
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  CHANGE_INVITE_MODAL,
  CHANGE_INVITE_FIELD,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  CHANGE_FILTER_FIELD
} from './constants';

const initialState = fromJS({
  clients: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1
  },
  invite: {
    flags: [],
    form: {
      email: '',
      message: ''
    },
    modals: [],
    loading: false
  },
  partner: {
    error: null,
    loading: true,
    form: {
      comment: ''
    },
    flags: [],
    formLoading: false,
    read: {
      id: 0,
      full_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      referral_code: '',
      last_login: '',
      date_joined: '',
      comment: '',
      companies_registered_count: '',
      partners_registered_count: '',
      total_amount: '',
      last_month_amount: '',
      max_month_amount: ''
    }
  },
  partners: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1,
    search: ''
  },
  registration: {
    error: null,
    flags: [],
    form: {
      email: '',
      phone_number: '',
      first_name: '',
      last_name: ''
    },
    loading: false
  },
  requisites: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1
  }
});

function partnerPartnersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS_LIST:
      return state.setIn(['partners', 'loading'], true);
    case GET_PARTNERS_LIST_SUCCESS:
      return state
        .setIn(['partners', 'count'], action.count)
        .setIn(['partners', 'list'], fromJS(action.list))
        .setIn(['partners', 'loading'], false);
    case GET_PARTNERS_LIST_FAIL:
      return state
        .setIn(['partners', 'error'], action.error)
        .setIn(['partners', 'count'], 0)
        .setIn(['partners', 'list'], fromJS([]))
        .setIn(['partners', 'loading'], false);
    case CHANGE_PARTNERS_PAGE:
      return state.setIn(['partners', 'page'], action.page);
    case CHANGE_FLAGS:
      return state.setIn([action.field, 'flags'], fromJS(action.flags));
    case CHANGE_PARTNER_REGISTRATION_FIELD:
      return state.setIn(['registration', 'form', action.field], action.text);
    case RESET_PARTNER_REGISTRATION_FORM:
      return state.setIn(
        ['registration', 'form'],
        fromJS({
          email: '',
          phone_number: '',
          first_name: '',
          last_name: ''
        })
      );
    case REGISTER_NEW_PARTNER:
      return state.setIn(['registration', 'loading'], true);
    case REGISTER_NEW_PARTNER_SUCCESS:
      return state
        .setIn(['registration', 'flags'], fromJS([1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            email: '',
            phone_number: '',
            first_name: '',
            last_name: ''
          })
        );
    case REGISTER_NEW_PARTNER_FAIL:
      return state
        .setIn(['registration', 'error'], action.error)
        .setIn(['registration', 'flags'], fromJS([-1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            email: '',
            phone_number: '',
            first_name: '',
            last_name: ''
          })
        );
    case GET_PARTNER:
      return state
        .setIn(['partner', 'error'], null)
        .setIn(
          ['partner', 'read'],
          fromJS({
            id: 0,
            full_name: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            referral_code: '',
            last_login: '',
            date_joined: '',
            comment: '',
            companies_registered_count: '',
            partners_registered_count: '',
            total_amount: '',
            last_month_amount: '',
            max_month_amount: ''
          })
        )
        .setIn(['partner', 'loading'], true);
    case GET_PARTNER_SUCCESS:
      return state
        .setIn(['partner', 'read'], fromJS(action.partner))
        .setIn(['partner', 'form', 'comment'], action.partner.comment)
        .setIn(['partner', 'loading'], false);
    case GET_PARTNER_FAIL:
      return state.setIn(['partner', 'error'], action.error).setIn(['partner', 'loading'], false);
    case CHANGE_PARTNER_COMMENT_FIELD:
      return state.setIn(['partner', 'form', 'comment'], action.text);
    case UPDATE_PARTNER:
      return state.setIn(['partner', 'flags'], fromJS([])).setIn(['partner', 'formLoading'], true);
    case UPDATE_PARTNER_SUCCESS:
      return state
        .setIn(['partner', 'flags'], fromJS([1]))
        .setIn(['partner', 'formLoading'], false);
    case UPDATE_PARTNER_FAIL:
      return state
        .setIn(['partner', 'flags'], fromJS([-1]))
        .setIn(['partner', 'formError'], action.error)
        .setIn(['partner', 'formLoading'], false);
    case GET_CLIENTS_LIST:
      return state.setIn(['clients', 'loading'], true);
    case GET_CLIENTS_LIST_SUCCESS:
      return state
        .setIn(['clients', 'count'], action.count)
        .setIn(['clients', 'list'], fromJS(action.list))
        .setIn(['clients', 'loading'], false);
    case GET_CLIENTS_LIST_FAIL:
      return state
        .setIn(['clients', 'error'], action.error)
        .setIn(['clients', 'count'], 0)
        .setIn(['clients', 'list'], fromJS([]))
        .setIn(['clients', 'loading'], false);
    case CHANGE_CLIENTS_PAGE:
      return state.setIn(['clients', 'page'], action.page);
    case GET_REQUISITES_LIST:
      return state.setIn(['requisites', 'loading'], true);
    case GET_REQUISITES_LIST_SUCCESS:
      return state
        .setIn(['requisites', 'count'], action.count)
        .setIn(['requisites', 'list'], fromJS(action.list))
        .setIn(['requisites', 'loading'], false);
    case GET_REQUISITES_LIST_FAIL:
      return state
        .setIn(['requisites', 'error'], action.error)
        .setIn(['requisites', 'count'], 0)
        .setIn(['requisites', 'list'], fromJS([]))
        .setIn(['requisites', 'loading'], false);
    case CHANGE_REQUISITES_PAGE:
      return state.setIn(['requisites', 'page'], action.page);
    case CHANGE_INVITE_MODAL:
      return state.setIn(['invite', 'modals'], fromJS(action.modals));
    case CHANGE_INVITE_FIELD:
      return state.setIn(['invite', 'form', action.field], action.text);
    case SEND_INVITE:
      return state.setIn(['invite', 'loading'], true);
    case SEND_INVITE_SUCCESS:
      return state
        .setIn(['invite', 'modals'], fromJS([]))
        .setIn(
          ['invite', 'form'],
          fromJS({
            email: '',
            message: ''
          })
        )
        .setIn(['invite', 'flags'], fromJS([1]))
        .setIn(['invite', 'loading'], false);
    case SEND_INVITE_FAIL:
      return state
        .setIn(['invite', 'modals'], fromJS([]))
        .setIn(
          ['invite', 'form'],
          fromJS({
            email: '',
            message: ''
          })
        )
        .setIn(['invite', 'flags'], fromJS([-1]))
        .setIn(['invite', 'loading'], false);
    case CHANGE_FILTER_FIELD:
      return state.setIn([action.field, 'search'], action.text);
    default:
      return state;
  }
}

export default partnerPartnersReducer;
