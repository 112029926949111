import { fromJS } from 'immutable';

import {
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  CHANGE_CLIENTS_LIST_PAGE,
  CHANGE_CLIENT_REGISTRATION_FIELD,
  RESET_CLIENT_REGISTRATION_FORM,
  REGISTER_NEW_CLIENT,
  REGISTER_NEW_CLIENT_SUCCESS,
  REGISTER_NEW_CLIENT_FAIL,
  CHANGE_FLAGS,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  CHANGE_CLIENT_COMMENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  GET_MANAGERS_LIST,
  GET_MANAGERS_LIST_SUCCESS,
  GET_MANAGERS_LIST_FAIL,
  CHANGE_MANAGERS_PAGE,
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  GET_FINANCES_LIST,
  GET_FINANCES_LIST_SUCCESS,
  GET_FINANCES_LIST_FAIL,
  CHANGE_FINANCES_PAGE,
  CHANGE_INVITE_MODAL,
  CHANGE_INVITE_FIELD,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  CHANGE_FILTER_FIELD,
  CHANGE_IS_MINE_FILTER,
  CHANGE_DATE_FILTER
} from './constants';

const initialState = fromJS({
  client: {
    error: null,
    flags: [],
    form: {
      comment: ''
    },
    formLoading: false,
    loading: false,
    read: {
      id: 0,
      name: '',
      created_at: '',
      owner: {
        id: 0,
        full_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        role: 0,
        last_login: ''
      },
      used_reports_count: '',
      remaining_reports_count: '',
      comment: ''
    }
  },
  clients: {
    count: 0,
    error: null,
    isMineFilter: false,
    list: [],
    loading: true,
    page: 1,
    search: ''
  },
  finances: {
    count: 0,
    error: null,
    fromDateFilter: '',
    list: [],
    loading: true,
    page: 1,
    rewardAmountSum: 0,
    toDateFilter: ''
  },
  invite: {
    flags: [],
    form: {
      email: '',
      message: ''
    },
    modals: [],
    loading: false
  },
  managers: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1,
    search: ''
  },
  registration: {
    error: null,
    flags: [],
    form: {
      company_name: '',
      company_inn: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      city: ''
    },
    loading: false
  },
  requisites: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1
  }
});

function partnerClientsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_LIST:
      return state.setIn(['clients', 'loading'], true);
    case GET_CLIENTS_LIST_SUCCESS:
      return state
        .setIn(['clients', 'count'], action.count)
        .setIn(['clients', 'list'], fromJS(action.list))
        .setIn(['clients', 'loading'], false);
    case GET_CLIENTS_LIST_FAIL:
      return state
        .setIn(['clients', 'error'], action.error)
        .setIn(['clients', 'count'], 0)
        .setIn(['clients', 'list'], fromJS([]))
        .setIn(['clients', 'loading'], false);
    case CHANGE_CLIENTS_LIST_PAGE:
      return state.setIn(['clients', 'loading'], true).setIn(['clients', 'page'], action.page);
    case CHANGE_CLIENT_REGISTRATION_FIELD:
      return state.setIn(['registration', 'form', action.field], action.text);
    case RESET_CLIENT_REGISTRATION_FORM:
      return state.setIn(
        ['registration', 'form'],
        fromJS({
          company_name: '',
          company_inn: '',
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          city: ''
        })
      );
    case REGISTER_NEW_CLIENT:
      return state.setIn(['registration', 'loading'], true);
    case REGISTER_NEW_CLIENT_SUCCESS:
      return state
        .setIn(['registration', 'flags'], fromJS([1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            company_name: '',
            company_inn: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            city: ''
          })
        );
    case REGISTER_NEW_CLIENT_FAIL:
      return state
        .setIn(['registration', 'error'], action.error)
        .setIn(['registration', 'flags'], fromJS([-1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            company_name: '',
            company_inn: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            city: ''
          })
        );
    case CHANGE_FLAGS:
      return state.setIn([action.field, 'flags'], fromJS(action.flags));
    case GET_CLIENT:
      return state.setIn(['client', 'loading'], true);
    case GET_CLIENT_SUCCESS:
      return state
        .setIn(['client', 'read'], fromJS(action.client))
        .setIn(['client', 'form', 'comment'], action.client.comment)
        .setIn(['client', 'loading'], false);
    case GET_CLIENT_FAIL:
      return state.setIn(['client', 'error'], action.error).setIn(['client', 'loading'], false);
    case CHANGE_CLIENT_COMMENT:
      return state.setIn(['client', 'form', 'comment'], action.text);
    case UPDATE_CLIENT:
      return state.setIn(['client', 'flags'], fromJS([])).setIn(['client', 'formLoading'], true);
    case UPDATE_CLIENT_SUCCESS:
      return state.setIn(['client', 'flags'], fromJS([1])).setIn(['client', 'formLoading'], false);
    case UPDATE_CLIENT_FAIL:
      return state.setIn(['client', 'flags'], fromJS([-1])).setIn(['client', 'formLoading'], false);
    case GET_MANAGERS_LIST:
      return state.setIn(['managers', 'loading'], true);
    case GET_MANAGERS_LIST_SUCCESS:
      return state
        .setIn(['managers', 'count'], action.count)
        .setIn(['managers', 'list'], fromJS(action.list))
        .setIn(['managers', 'loading'], false);
    case GET_MANAGERS_LIST_FAIL:
      return state
        .setIn(['managers', 'error'], action.error)
        .setIn(['managers', 'count'], 0)
        .setIn(['managers', 'list'], fromJS([]))
        .setIn(['managers', 'loading'], false);
    case CHANGE_MANAGERS_PAGE:
      return state.setIn(['managers', 'page'], action.page);
    case GET_REQUISITES_LIST:
      return state.setIn(['requisites', 'loading'], true);
    case GET_REQUISITES_LIST_SUCCESS:
      return state
        .setIn(['requisites', 'count'], action.count)
        .setIn(['requisites', 'list'], fromJS(action.list))
        .setIn(['requisites', 'loading'], false);
    case GET_REQUISITES_LIST_FAIL:
      return state
        .setIn(['requisites', 'error'], action.error)
        .setIn(['requisites', 'count'], 0)
        .setIn(['requisites', 'list'], fromJS([]))
        .setIn(['requisites', 'loading'], false);
    case CHANGE_REQUISITES_PAGE:
      return state.setIn(['requisites', 'page'], action.page);
    case GET_FINANCES_LIST:
      return state.setIn(['finances', 'loading'], true);
    case GET_FINANCES_LIST_SUCCESS:
      return state
        .setIn(['finances', 'count'], action.count)
        .setIn(['finances', 'list'], fromJS(action.list))
        .setIn(['finances', 'rewardAmountSum'], action.rewardAmountSum)
        .setIn(['finances', 'loading'], false);
    case GET_FINANCES_LIST_FAIL:
      return state
        .setIn(['finances', 'error'], action.error)
        .setIn(['finances', 'count'], 0)
        .setIn(['finances', 'list'], fromJS([]))
        .setIn(['finances', 'rewardAmountSum'], 0)
        .setIn(['finances', 'loading'], false);
    case CHANGE_FINANCES_PAGE:
      return state.setIn(['finances', 'page'], action.page);
    case CHANGE_INVITE_MODAL:
      return state.setIn(['invite', 'modals'], fromJS(action.modals));
    case CHANGE_INVITE_FIELD:
      return state.setIn(['invite', 'form', action.field], action.text);
    case SEND_INVITE:
      return state.setIn(['invite', 'loading'], true);
    case SEND_INVITE_SUCCESS:
      return state
        .setIn(['invite', 'modals'], fromJS([]))
        .setIn(
          ['invite', 'form'],
          fromJS({
            email: '',
            message: ''
          })
        )
        .setIn(['invite', 'flags'], fromJS([1]))
        .setIn(['invite', 'loading'], false);
    case SEND_INVITE_FAIL:
      return state
        .setIn(['invite', 'modals'], fromJS([]))
        .setIn(
          ['invite', 'form'],
          fromJS({
            email: '',
            message: ''
          })
        )
        .setIn(['invite', 'flags'], fromJS([-1]))
        .setIn(['invite', 'loading'], false);
    case CHANGE_FILTER_FIELD:
      return state.setIn([action.field, 'search'], action.text);
    case CHANGE_IS_MINE_FILTER:
      return state.setIn(['clients', 'isMineFilter'], action.filter === 1);
    case CHANGE_DATE_FILTER:
      return state.setIn(['finances', action.field], action.text);
    default:
      return state;
  }
}

export default partnerClientsReducer;
