import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getFill = (active, withoutHover) => {
  if (active || withoutHover) {
    return `
      & circle {
        fill: #E14729;
        transition: all .3s ease-out;
      }
    `;
  }

  return `
    & circle {
      fill: #CACBCF;
      transition: all .3s ease-out;
    }

    &:hover circle {
      fill: #E14729;
    }
  `;
};

const Svg = styled.svg`
  cursor: ${props => (props.withoutHover ? 'default' : 'pointer')};
  ${props => getFill(props.active, props.withoutHover)};
`;

const Fire = ({ active, onClick, withoutHover }) => (
  <Svg
    active={active}
    onClick={onClick}
    withoutHover={withoutHover}
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="comment-01" transform="translate(-575.000000, -440.000000)">
        <g id="fire" transform="translate(575.000000, 440.000000)">
          <circle id="Oval" fill="#E14729" cx="16" cy="16" r="16" />
          <path
            d="M15.4190751,27.0867052 C15.4190751,27.0867052 8,27.1156069 8,20.7572254 C8,14.3988439 14.5982659,12.6647399 14.5982659,7.7283237 C14.5982659,6.37283237 14.2369942,5.77745665 13.4595376,5 C16.9653179,5.10693642 19.7543353,8.6300578 19.7543353,13.632948 C19.7543353,14.6271676 19.3930636,16.6849711 18.9595376,17.1184971 C18.5260116,17.5520231 21.7774566,15.150289 21.7774566,13.0549133 C21.7774566,13.0549133 24,15.1676301 24,19.7398844 C24,24.3121387 19.0982659,27.5520231 16.7687861,27.5520231 C18.2312139,26.0895954 19.2254335,24.699422 19.2254335,23.0549133 C19.2254335,19.7369942 16.4450867,20.0260116 15.7572254,18.3352601 C15.7105985,18.2218193 15.6766732,18.1035654 15.6560694,17.982659 C15.5907971,17.5325514 15.7049712,17.0748172 15.9739884,16.7080925 L16.7312139,15.6156069 C16.7312139,15.6156069 12.4132948,16.8583815 12.4132948,21.482659 C12.4132948,26.1069364 14.7138728,26.3786127 15.4190751,27.0867052 Z"
            id="Path"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </Svg>
);

Fire.defaultProps = {
  active: false,
  onClick: () => {},
  withoutHover: false
};

Fire.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  withoutHover: PropTypes.bool
};

export default Fire;
