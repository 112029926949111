import styled from 'styled-components';

const LegendTitle = styled.h2`
  color: #172b4d;
  font-size: 24px;
  letter-spacing: 0.33px;
  line-height: 28px;
`;

export default LegendTitle;
