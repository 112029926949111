import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Fb from './fb';
import Instagram from './instagram.js';
import Telegram from './telegram.js';
import WhatsApp from "./whatsapp";
import { legalName } from "utils/checkService";

const Wrapper = styled.div`
  display: flex;
  height: 260px;
  width: 100%;
`;

const Inner = styled.div`
  align-items: flex-start;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: auto;
  width: 1160px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    display: block;
    margin-top: 50px;
    width: calc(100% - 30px);
  }
`;

const Column = styled.div`
  width: 360px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% / 3 - 10px);
  }

  @media (max-width: 1020px) {
    margin-top: 30px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const ColumnTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #6b778c;
  letter-spacing: 0;
  line-height: 16px;
`;

const Text = styled.p`
  font-size: 16px;
  color: #172b4d;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;

  @media (max-width: 1020px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  @media (max-width: 1020px) {
    margin-top: 10px;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: 250px;
  }

  @media (max-width: 1020px) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

const Footer = () => (
  <Wrapper id="contacts">
    <Inner>
      <Column>
        <ColumnTitle>Реквизиты</ColumnTitle>
        <Text>
          {legalName}
          <br />
          ОГРНИП 317547600027591
        </Text>
      </Column>
      <Column>
        <ColumnTitle>Документы</ColumnTitle>
        <Links>
          <Link to="/documents/2">Правила оплаты</Link>
          <Link to="/documents/1">Договор-оферта</Link>
          <Link to="/documents/3">Политика конфиденциальности</Link>
          <Link to="/documents/4">Пользовательское соглашение</Link>
        </Links>
      </Column>
      <Socials>
        <a href="https://t.me/eg0rgaiduk" target="_blank" rel="noopener noreferrer">
          <Telegram />
        </a>
        <a href="https://api.whatsapp.com/send/?phone=79830007000" target="_blank" rel="noopener noreferrer">
          <WhatsApp />
        </a>
      </Socials>
    </Inner>
  </Wrapper>
);

export default Footer;
