import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import isEmpty from 'lodash/isEmpty';
import CriticalToggle from 'components/CriticalToggle';
import Progress from 'components/Progress';
import Spinner from 'components/Spinner';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changePatternName,
  changePatternDesc,
  changePatternRangeLower,
  changePatternRangeUpper,
  changePatternEditCritical,
  profileRead,
  changeProfileForUpdate
} from 'pages/ProfileList/actions';
import { makeSelectProfileLoading, makeSelectProfileRead } from 'pages/ProfileList/selectors';

import reducer from 'pages/ProfileList/reducer';
import saga from 'pages/ProfileList/saga';

import Block from './styled/Block';
import CenteredBlock from './styled/CenteredBlock';
import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import MainBlock from './styled/MainBlock';
import P from './styled/P';

class ProfileEdit extends React.Component {
  state = {
    profile: null,
    changeFlags: [],
    nameValid: true
  };

  componentDidMount() {
    const profileId = parseInt(this.props.match.params.id, 10);

    this.props.getProfileRead({
      profileId
    });
  }

  addFlag = () => {
    const profile = this.props.profileRead;

    const nameValid = !isEmpty(profile.get('newName'));

    if (nameValid) {
      this.props.changeProfileForUpdate({
        profileForUpdate: 'read'
      });

      this.setState({
        changeFlags: [this.state.changeFlags.length, ...this.state.changeFlags]
      });
    } else {
      this.setState({
        nameValid
      });
    }
  };

  removeFlag = id => this.setState({ changeFlags: this.state.changeFlags.filter(v => v !== id) });

  getPageHeaderButtonsDrawer = () => (
    <ButtonGroup>
      <Button appearance="primary" onClick={this.addFlag}>
        Сохранить
      </Button>
    </ButtonGroup>
  );

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeName = event => {
    this.setState({
      nameValid: !isEmpty(event.target.value)
    });
    this.props.changePatternName({
      name: event.target.value
    });
  };

  onChangeDesc = event => {
    this.props.changePatternDesc({
      desc: event.target.value
    });
  };

  onRangeLowerChange = (programId, patternId, value) => {
    this.props.changePatternRangeLower({
      programId,
      patternId,
      value
    });
  };

  onRangeUpperChange = (programId, patternId, value) => {
    this.props.changePatternRangeUpper({
      programId,
      patternId,
      value
    });
  };

  onChangeCritical = (programId, patternId, value) => () =>
    this.props.changePatternEditCritical({
      programId,
      patternId,
      value
    });

  renderMetaProgram = program => (
    <MainBlock key={program.get('id')}>
      <h3>{program.get('name')}</h3>
      <p>{program.get('description')}</p>
      {program.get('patterns').map(pattern => (
        <Block key={pattern.get('id')}>
          <CenteredBlock>
            <h4>{pattern.get('name')}</h4>
            <CriticalToggle
              onChange={this.onChangeCritical(
                program.get('id'),
                pattern.get('id'),
                !pattern.get('is_important')
              )}
              isChecked={pattern.get('is_important')}
            />
          </CenteredBlock>
          <P marginTop="9px">{pattern.get('description')}</P>
          <P color="#6B778C" marginTop="9px">
            Удерживайте и тяните бегунки
          </P>
          <Progress
            after={100 - pattern.getIn(['range', 'upper'])}
            before={pattern.getIn(['range', 'lower'])}
            programId={program.get('id')}
            patternId={pattern.get('id')}
            onAfterChange={this.onRangeUpperChange}
            onBeforeChange={this.onRangeLowerChange}
            isCritical={pattern.get('is_important')}
          />
        </Block>
      ))}
    </MainBlock>
  );

  render() {
    let { nameValid, profile } = this.state;

    if (isNull(profile)) {
      profile = this.props.profileRead;
    }

    let content = <Spinner />;

    if (!this.props.profileLoading && profile.get('id') !== 0) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/profile/list"
            onClick={event => this.onBreadcumbsClick(event, '/profile/list')}
            text="Профили"
            key="Profile"
          />
          <BreadcrumbsItem
            href={`/profile/edit/${profile.get('id')}`}
            onClick={event => this.onBreadcumbsClick(event, `/profile/edit/${profile.get('id')}`)}
            text={profile.get('name')}
            key="director"
          />
        </BreadcrumbsStateless>
      );

      content = (
        <Grid layout="fixed">
          <GridColumn medium={12}>
            <PageHeader breadcrumbs={breadcrumbs} actions={this.getPageHeaderButtonsDrawer()}>
              {profile.get('name')}
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <div>
              <Form>
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!nameValid}
                      required
                      label="Название профиля"
                      onChange={this.onChangeName}
                      placeholder=""
                      shouldFitContainer
                      value={profile.get('newName')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextArea
                      label="Описание"
                      onChange={this.onChangeDesc}
                      placeholder=""
                      shouldFitContainer
                      minimumRows={4}
                      value={profile.get('description')}
                    />
                  </Field>
                </FieldGroup>
              </Form>
            </div>
            {profile.get('meta_programs').map(program => this.renderMetaProgram(program))}
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{profile.get('name')}</title>
        </Helmet>

        <Container>{content}</Container>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.state.changeFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Изменения сохранены"
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

ProfileEdit.propTypes = {
  changePatternName: PropTypes.func,
  changePatternDesc: PropTypes.func,
  changePatternRangeLower: PropTypes.func,
  changePatternRangeUpper: PropTypes.func,
  changePatternEditCritical: PropTypes.func.isRequired,
  changeProfileForUpdate: PropTypes.func,
  getProfileRead: PropTypes.func,
  profileLoading: PropTypes.bool,
  profileRead: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    changePatternName: value => dispatch(changePatternName(value)),
    changePatternDesc: value => dispatch(changePatternDesc(value)),
    changePatternRangeLower: value => dispatch(changePatternRangeLower(value)),
    changePatternRangeUpper: value => dispatch(changePatternRangeUpper(value)),
    changePatternEditCritical: value => dispatch(changePatternEditCritical(value)),
    getProfileRead: value => dispatch(profileRead(value)),
    changeProfileForUpdate: value => dispatch(changeProfileForUpdate(value))
  };
}

const mapStateToProps = createStructuredSelector({
  profileLoading: makeSelectProfileLoading(),
  profileRead: makeSelectProfileRead()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'profile', reducer });
const withSaga = injectSaga({ key: 'profile', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(ProfileEdit);
