import { fromJS } from 'immutable';

import {
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_FLAGS,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAIL,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL
} from './constants';

const initialState = fromJS({
  error: '',
  flags: [],
  key: false,
  login: '',
  password: '',
  otp: {
    loading: false
  }
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOGIN:
      return state.set('login', action.login);
    case CHANGE_PASSWORD:
      return state.set('password', action.password);
    case CHANGE_FLAGS:
      return state.set('flags', fromJS(action.flags));
    case LOGIN_REQUEST_SUCCESS:
      return state.set('key', action.key);
    case LOGIN_REQUEST_FAIL:
      return state.set('flags', fromJS([1])).set('error', action.errorMessage);
    case SEND_OTP:
      return state.setIn(['otp', 'loading'], true);
    case SEND_OTP_SUCCESS:
      return state.setIn(['otp', 'loading'], false);
    case SEND_OTP_FAIL:
      return state.setIn(['otp', 'loading'], false);
    default:
      return state;
  }
}

export default loginReducer;
