import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import 'moment/locale/ru';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from 'pages/PartnerProfile/Clients/reducer';
import saga from 'pages/PartnerProfile/Clients/saga';

import {
  changeFlags,
  changeClientComment,
  getClient,
  updateClient
} from 'pages/PartnerProfile/Clients/actions';
import {
  makeSelectClientComment,
  makeSelectClientFlags,
  makeSelectClientFormLoading,
  makeSelectClientLoading,
  makeSelectClientRead
} from 'pages/PartnerProfile/Clients/selectors';

import { BreadcrumbsItem, BreadcrumbsStateless } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextAreaStateless as TextArea } from '@atlaskit/field-text-area';
import Spinner from 'components/Spinner';

import Block from './styled/Block';
import Container from './styled/Container';
import Label from './styled/Label';
import P from './styled/P';

class Information extends Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);
    const {
      props: { getClient }
    } = this;

    getClient({ id });
  }

  onBreadcrumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeComment = event => {
    const {
      props: { changeClientComment }
    } = this;

    changeClientComment({
      text: event.target.value
    });
  };

  onSubmitForm = event => {
    const {
      props: { client, updateClient }
    } = this;

    event.preventDefault();
    updateClient({
      id: client.id
    });
  };

  renderBreadcrumbs = () => {
    const {
      onBreadcrumbsClick,
      props: { client }
    } = this;

    return (
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          href="/partner/clients"
          onClick={event => onBreadcrumbsClick(event, '/partner/clients')}
          text="Клиенты"
          key="clients"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}`)}
          text={client.name}
          key="clientName"
        />
        <BreadcrumbsItem
          href={`/partner/clients/${client.id}`}
          onClick={event => onBreadcrumbsClick(event, `/partner/clients/${client.id}`)}
          text="Информация"
          key="partnerInformation"
        />
      </BreadcrumbsStateless>
    );
  };

  renderContent = () => {
    const {
      onSubmitForm,
      props: { client, comment, formLoading, loading },
      renderBreadcrumbs
    } = this;

    if (loading) {
      return (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <Spinner height="80vh" />
          </GridColumn>
        </Grid>
      );
    }

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader breadcrumbs={renderBreadcrumbs()}>{client.name}</PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Компания</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Название</Label>
                  <P>{client.name}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Дата регистрации</Label>
                  <P>{moment(client.created_at).format('DD.MM.YYYY')}</P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Администратор</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Имя</Label>
                  <P>{client.owner.full_name}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Email</Label>
                  <P>{client.owner.email}</P>
                </GridColumn>
              </Grid>
            </Block>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Телефон</Label>
                  <P>{client.owner.phone_number}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Дата последнего входа</Label>
                  <P>
                    {!isEmpty(client.owner.last_login)
                      ? moment(client.owner.last_login).format('DD.MM.YYYY')
                      : ''}
                  </P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <h4>Отчеты</h4>
          <div>
            <Block>
              <Grid layout="fluid">
                <GridColumn medium={6}>
                  <Label>Использовано отчетов</Label>
                  <P>{client.used_reports_count.toLocaleString()}</P>
                </GridColumn>
                <GridColumn medium={6}>
                  <Label>Доступно отчетов</Label>
                  <P>{client.remaining_reports_count.toLocaleString()}</P>
                </GridColumn>
              </Grid>
            </Block>
          </div>
        </GridColumn>
        <GridColumn medium={12}>
          <div>
            <TextArea
              label="Комментарий"
              onChange={this.onChangeComment}
              placeholder=""
              shouldFitContainer
              minimumRows={4}
              value={comment}
            />
            <Block>
              <Button appearance="primary" isLoading={formLoading} onClick={onSubmitForm}>
                Сохранить
              </Button>
            </Block>
          </div>
        </GridColumn>
      </Grid>
    );
  };

  renderUpdateFlags = () => {
    const {
      props: { changeFlags, clientFlags }
    } = this;

    const onRemoveFlag = name => {
      changeFlags({
        field: 'client',
        flags: clientFlags.filter(v => v !== name)
      });
    };

    return (
      <FlagGroup onDismissed={name => onRemoveFlag(name)}>
        {clientFlags.map(id => (
          <Flag
            description={
              id < 0 ? 'При редактировании клиента произошла ошибка. Попробуйте еще раз.' : ''
            }
            isDismissAllowed
            id={id}
            icon={
              id > 0 ? <Tick label="Success" /> : <Error label="Error" primaryColor={colors.R300} />
            }
            key={`${id}`}
            title={id > 0 ? 'Клиент успешно отредактирован' : 'Ошибка'}
          />
        ))}
      </FlagGroup>
    );
  };

  render() {
    const {
      props: { client },
      renderContent,
      renderUpdateFlags
    } = this;

    return (
      <Container>
        <Helmet>
          <title>{client.name}</title>
        </Helmet>
        {renderContent()}
        {renderUpdateFlags()}
      </Container>
    );
  }
}

Information.propTypes = {
  changeFlags: PropTypes.func.isRequired,
  changeClientComment: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  clientFlags: PropTypes.array.isRequired,
  comment: PropTypes.string.isRequired,
  formLoading: PropTypes.bool.isRequired,
  getClient: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateClient: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeFlags: value => dispatch(changeFlags(value)),
    changeClientComment: value => dispatch(changeClientComment(value)),
    getClient: value => dispatch(getClient(value)),
    updateClient: value => dispatch(updateClient(value))
  };
}

const mapStateToProps = createStructuredSelector({
  client: makeSelectClientRead(),
  clientFlags: makeSelectClientFlags(),
  comment: makeSelectClientComment(),
  formLoading: makeSelectClientFormLoading(),
  loading: makeSelectClientLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerClients', reducer });
const withSaga = injectSaga({ key: 'partnerClients', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Information);
