import styled from 'styled-components';

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export default FieldGroup;
