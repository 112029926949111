import styled from 'styled-components';

const Video = styled.div`
  box-shadow: 0 4px 35px 0 rgba(90, 122, 190, 0.2);
  margin-top: 40px;
  max-width: 500px;

  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

export default Video;
