import { fromJS } from 'immutable';

import {
  CHANGE_COMPANY,
  CHANGE_INN,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
  CHANGE_PASSWORD_ONE,
  CHANGE_SMS_CODE,
  CHANGE_FLAGS,
  CHANGE_SMS_FLAGS,
  SEND_SMS_CODE_REQUEST,
  SEND_SMS_CODE_REQUEST_SUCCESS,
  SEND_SMS_CODE_REQUEST_FAIL,
  REGISTER_COMPANY_REQUEST,
  REGISTER_COMPANY_REQUEST_SUCCESS,
  REGISTER_COMPANY_REQUEST_FAIL
} from './constants';

const initialState = fromJS({
  loading: false,
  flags: [],
  smsFlags: [],
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  company_name: '',
  company_inn: '',
  password1: '',
  sms_code: '',
  errorMessages: {
    company_inn: '',
    email: '',
    password1: '',
    phone_number: ''
  }
});

function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_NAME:
      return state.set('first_name', action.name);
    case CHANGE_SURNAME:
      return state.set('last_name', action.surname);
    case CHANGE_EMAIL:
      return state.set('email', action.email);
    case CHANGE_PHONE_NUMBER:
      return state.set('phone_number', action.phone);
    case CHANGE_SMS_CODE:
      return state.set('sms_code', action.smsCode);
    case CHANGE_PASSWORD_ONE:
      return state.set('password1', action.password);
    case CHANGE_COMPANY:
      return state.set('company_name', action.company);
    case CHANGE_INN:
      return state.set('company_inn', action.inn);
    case CHANGE_FLAGS:
      return state.set('flags', fromJS(action.flags));
    case CHANGE_SMS_FLAGS:
      return state.set('smsFlags', fromJS(action.smsFlags));
    case SEND_SMS_CODE_REQUEST:
      return state.set('smsFlags', fromJS([]));
    case SEND_SMS_CODE_REQUEST_SUCCESS:
      return state.set('smsFlags', fromJS([1]));
    case SEND_SMS_CODE_REQUEST_FAIL:
      return state.set('smsFlags', fromJS([0]));
    case REGISTER_COMPANY_REQUEST:
      return state.set('loading', true);
    case REGISTER_COMPANY_REQUEST_SUCCESS:
      return state.set('loading', false);
    case REGISTER_COMPANY_REQUEST_FAIL:
      return state
        .set('loading', false)
        .setIn(['errorMessages', 'company_inn'], action.company_inn)
        .setIn(['errorMessages', 'email'], action.email)
        .setIn(['errorMessages', 'password1'], action.password1)
        .setIn(['errorMessages', 'phone_number'], action.phone_number)
        .set('flags', fromJS([1]));
    default:
      return state;
  }
}

export default registrationReducer;
