import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CHANGE_FLAGS,
  CHANGE_USER_FIELD,
  CHANGE_USER_PASSWORD_FIELD,
  UPDATE_USER_COMMON,
  UPDATE_USER_COMMON_SUCCESS,
  UPDATE_USER_COMMON_FAIL,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAIL,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAIL
} from './constants';

export function getUser(payload) {
  return {
    type: GET_USER,
    ...payload
  };
}

export function getUserSuccess(payload) {
  return {
    type: GET_USER_SUCCESS,
    ...payload
  };
}

export function getUserFail(payload) {
  return {
    type: GET_USER_FAIL,
    ...payload
  };
}

export function changeUserField(payload) {
  return {
    type: CHANGE_USER_FIELD,
    ...payload
  };
}

export function changeUserPasswordField(payload) {
  return {
    type: CHANGE_USER_PASSWORD_FIELD,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function updateUserCommon() {
  return {
    type: UPDATE_USER_COMMON
  };
}

export function updateUserCommonSuccess() {
  return {
    type: UPDATE_USER_COMMON_SUCCESS
  };
}

export function updateUserCommonFail() {
  return {
    type: UPDATE_USER_COMMON_FAIL
  };
}

export function updateUserEmail() {
  return {
    type: UPDATE_USER_EMAIL
  };
}

export function updateUserEmailSuccess() {
  return {
    type: UPDATE_USER_EMAIL_SUCCESS
  };
}

export function updateUserEmailFail() {
  return {
    type: UPDATE_USER_EMAIL_FAIL
  };
}

export function updateUserPassword() {
  return {
    type: UPDATE_USER_PASSWORD
  };
}

export function updateUserPasswordSuccess() {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS
  };
}

export function updateUserPasswordFail() {
  return {
    type: UPDATE_USER_PASSWORD_FAIL
  };
}
