import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { Item } from '@atlaskit/navigation-next';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import QueuesIcon from '@atlaskit/icon/glyph/queues';

import { changeReportShape } from 'pages/Home/actions';
import { makeSelectReportShape, makeSelectReportShapeText } from 'pages/Home/selectors';

import BetterDropdown from './BetterDropdown';

const ReportDropdown = ({ changeReportShape, reportShape, ...props }) => (
  <BetterDropdown
    trigger={({ isOpen = false }) => (
      <Item
        before={() => <QueuesIcon />}
        isSelected={isOpen || props.href === props.match.url}
        text={props.reportShapeText[reportShape].label}
        after={() => (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
      />
    )}
  >
    <DropdownItemGroup>
      {props.reportShapeText.map(dropdown => (
        <DropdownItem
          key={dropdown.id}
          isSelected={dropdown.id === reportShape}
          onClick={() => {
            if (props.href !== props.match.url) {
              props.history.push(props.href);
            }

            changeReportShape({ reportShape: dropdown.id });
          }}
        >
          {dropdown.label}
        </DropdownItem>
      ))}
    </DropdownItemGroup>
  </BetterDropdown>
);

function mapDispatchToProps(dispatch) {
  return {
    changeReportShape: value => dispatch(changeReportShape(value))
  };
}

const mapStateToProps = createStructuredSelector({
  reportShape: makeSelectReportShape(),
  reportShapeText: makeSelectReportShapeText()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  withConnect
)(ReportDropdown);
