import styled from 'styled-components';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.align ? props.align : 'inherit')};
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};
`;

export default Field;
