import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { DatePicker } from '@atlaskit/datetime-picker';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Pagination from '@atlaskit/pagination';
import Select from '@atlaskit/select';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';

import reducer from 'pages/PartnerProfile/Finances/reducer';
import saga from 'pages/PartnerProfile/Finances/saga';

import {
  changeDateFilter,
  changeFinancesPage,
  changeTypeFilter,
  getFinancesList
} from 'pages/PartnerProfile/Finances/actions';
import {
  makeSelectFinancesCount,
  makeSelectFinancesList,
  makeSelectFinancesLoading,
  makeSelectFinancesPage,
  makeSelectFinancesFromDateFilter,
  makeSelectFinancesToDateFilter,
  makeSelectFinancesTypeSelect,
  makeSelectFinancesRewardAmountSum
} from 'pages/PartnerProfile/Finances/selectors';

import FinancesTable from './Table';

class List extends Component {
  componentDidMount() {
    const { getFinancesList } = this.props;

    getFinancesList();
  }

  renderBottomBar = () => {
    const {
      props: { changeDateFilter, changeTypeFilter, fromDateFilter, toDateFilter, typeSelect }
    } = this;

    const selectTypeFilter = [
      {
        label: 'Все источники',
        value: 0
      },
      {
        label: 'Клиенты',
        value: 1
      },
      {
        label: 'Партнеры',
        value: 2
      }
    ];

    const onChangeFromDateFilterField = value => {
      changeDateFilter({
        field: 'fromDateFilter',
        text: value
      });
    };

    const onChangeToDateFilterField = value => {
      changeDateFilter({
        field: 'toDateFilter',
        text: value
      });
    };

    const onChangeSelector = select => {
      let typeFilter = '';

      switch (select.value) {
        case 1:
          typeFilter = 'company';
          break;
        case 2:
          typeFilter = 'partner';
          break;
        default:
          break;
      }

      changeTypeFilter({
        typeFilter
      });
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 200px' }}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            id="fromDateFilter"
            onChange={onChangeFromDateFilterField}
            placeholder="От даты"
            value={fromDateFilter}
          />
        </div>
        <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            id="toDateFilter"
            onChange={onChangeToDateFilterField}
            placeholder="До даты"
            value={toDateFilter}
          />
        </div>
        <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
          <Select
            className="single-select"
            defaultOptions={selectTypeFilter[0]}
            options={selectTypeFilter}
            isSearchable={false}
            onChange={onChangeSelector}
            placeholder="Все источники"
            value={selectTypeFilter[typeSelect]}
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const {
      changeFinancesPage,
      count,
      fromDateFilter,
      list,
      loading,
      page,
      rewardAmountSum,
      toDateFilter
    } = this.props;

    let content = (
      <Empty
        header="У вас еще нет вознаграждений"
        text="Начните регистрировать клиентов и получать вознаграждения за приглашенных и зарегистрированных клиентов"
      />
    );

    if (count >= 1 || !isEmpty(fromDateFilter) || !isEmpty(toDateFilter)) {
      content = [
        <FinancesTable
          key="financesTable"
          data={list}
          isLoading={loading}
          rewardAmountSum={rewardAmountSum}
        />,
        <Pagination
          key="pagination"
          value={page}
          total={Math.ceil(count / 25)}
          onChange={page => changeFinancesPage({ page })}
        />
      ];
    } else if (loading) {
      content = <Spinner height="80vh" />;
    }

    return content;
  };

  render() {
    const { renderBottomBar, renderContent } = this;

    return (
      <Page>
        <Helmet>
          <title>Финансы</title>
        </Helmet>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader bottomBar={renderBottomBar()}>Финансы</PageHeader>
          </GridColumn>
          <GridColumn medium={12}>{renderContent()}</GridColumn>
        </Grid>
      </Page>
    );
  }
}

List.propTypes = {
  changeDateFilter: PropTypes.func.isRequired,
  changeFinancesPage: PropTypes.func.isRequired,
  changeTypeFilter: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  fromDateFilter: PropTypes.string.isRequired,
  getFinancesList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rewardAmountSum: PropTypes.number.isRequired,
  toDateFilter: PropTypes.string.isRequired,
  typeSelect: PropTypes.number.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeDateFilter: value => dispatch(changeDateFilter(value)),
    changeFinancesPage: () => dispatch(changeFinancesPage()),
    changeTypeFilter: value => dispatch(changeTypeFilter(value)),
    getFinancesList: () => dispatch(getFinancesList())
  };
}

const mapStateToProps = createStructuredSelector({
  count: makeSelectFinancesCount(),
  list: makeSelectFinancesList(),
  loading: makeSelectFinancesLoading(),
  page: makeSelectFinancesPage(),
  fromDateFilter: makeSelectFinancesFromDateFilter(),
  toDateFilter: makeSelectFinancesToDateFilter(),
  typeSelect: makeSelectFinancesTypeSelect(),
  rewardAmountSum: makeSelectFinancesRewardAmountSum()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'partnerFinances', reducer });
const withSaga = injectSaga({ key: 'partnerFinances', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(List);
