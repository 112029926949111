import styled from 'styled-components';

const P = styled.p`
  margin-bottom: 15px;
  margin-top: 15px;
  max-width: 400px;
  text-align: center;
`;

export default P;
