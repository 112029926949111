import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import { legalName } from "utils/checkService";

import { isRequired, isPhone, isEmail, isDate } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Form from '@atlaskit/form';
import Page from '@atlaskit/page';
import SelectField from 'components/SelectField';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';
import { DatePicker } from '@atlaskit/datetime-picker';
import moment from "moment";

import {
  changeCode,
  changeEmail,
  changeName,
  changePhone,
  changeSurname,
  changePassword,
  changeGender,
  changeDateOfBirth,
  changeTermsAccepted,
  checkInviteCode,
  sendCreate,
  sendVerify,
  userRead,
  userUpdate
} from './actions';

import {
  makeSelectCode,
  makeSelectLoading,
  makeSelectPhone,
  makeSelectSmsWasSend,
  makeSelectTermsAccepted,
  makeSelectVerified,
  makeSelectUser
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import TestImage from './img/test-main.png';
import TestImage2x from './img/test-main-2x.png';

import ButtonField from './styled/ButtonField';
import CheckboxField from './styled/CheckboxField';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import P from './styled/P';
import Title from './styled/Title';
import Wrapper from "../../components/TextField/styled/Wrapper";
import DateField from "../../components/DateField";

import service from '../../utils/checkService';

class TestStart extends React.Component {
  state = {
    phoneValid: true,
    codeValid: true,
    nameValid: true,
    surnameValid: true,
    emailValid: true,
    isDisabled: true,
    passwordValid: true,
    genderValid: true,
    dateOfBirthValid: true,
    termsAccepted: true,
    verifyWasSend: false
  };

  componentDidMount() {
    const key = localStorage.getItem('staffKey');

    if (!isNull(key)) {
      this.props.userRead();
    } else {
      this.props.history.push('/invite/s');
    }
  }

  changeCode = event => {
    this.setState({
      codeValid: isRequired(event.target.value),
      isDisabled: !isRequired(event.target.value)
    });

    this.props.changeCode({
      code: event.target.value
    });
  };

  changePhone = event => {
    this.setState({
      phoneValid: isPhone(event.target.value),
      isDisabled: !isPhone(event.target.value)
    });

    this.props.changePhone({
      phone: event.target.value
    });
  };

  changeName = event => {
    this.props.changeName({
      name: event.target.value
    });

    this.checkRegisterForm('name', event.target.value);
  };

  changeSurname = event => {
    this.props.changeSurname({
      surname: event.target.value
    });

    this.checkRegisterForm('surname', event.target.value);
  };

  changeEmail = event => {
    this.props.changeEmail({
      email: event.target.value
    });

    this.checkRegisterForm('email', event.target.value);
  };

  changePasswordInput = event => {
    this.props.changePassword({
      password: event.target.value
    });

    this.checkRegisterForm('password', event.target.value);
  };

  changeGender = event => {
    this.props.changeGender({
      gender: event.value
    });

    this.checkRegisterForm('gender', event.value);
  };

  changeDateOfBirthInput = event => {
    this.props.changeDateOfBirth({
      date_of_birth: event.target.value
    });

    this.checkRegisterForm('date_of_birth', event.target.value);
  };

  changeTermsAccepted = event => {
    this.props.changeTermsAccepted({
      termsAccepted: event.target.checked
    });

    this.checkRegisterForm('termsAccepted', event.target.checked);
  };

  checkRegisterForm = (input, value) => {
    const user = this.props.user;
    let isDisabled = true;

    const nameValid = isRequired(input === 'name' ? value : user.get('first_name'));
    const surnameValid = isRequired(input === 'surname' ? value : user.get('last_name'));
    const emailValid = isEmail(input === 'email' ? value : user.get('email'));
    const passwordValid = isRequired(input === 'password' ? value : user.get('password'));
    const dateOfBirthValid = isDate(input === 'date_of_birth' ? value : user.get('date_of_birth'));
    const termsAccepted = input === 'termsAccepted' ? value : this.props.termsAccepted;

    switch (input) {
      case 'name':
        this.setState({
          nameValid
        });
        break;
      case 'surname':
        this.setState({
          surnameValid
        });
        break;
      case 'email':
        this.setState({
          emailValid
        });
        break;
      case 'password':
        this.setState({
          passwordValid
        });
        break;
      case 'date_of_birth':
        this.setState({
          dateOfBirthValid
        });
        break;
      case 'termsAccepted':
        this.setState({
          termsAccepted
        });
        break;
      default:
        break;
    }

    if (nameValid && surnameValid && emailValid && passwordValid && dateOfBirthValid && termsAccepted) {
      isDisabled = false;
    }

    this.setState({
      isDisabled
    });
  };

  sendSms = event => {
    event.preventDefault();
    this.props.sendCreate();
  };

  sendVerify = event => {
    event.preventDefault();
    const invitationCode = this.props.match.params.invitationCode;

    this.setState({
      isDisabled: true,
      verifyWasSend: true
    });
    this.props.sendVerify({
      invitationCode
    });
  };

  sendUserForm = event => {
    event.preventDefault();
    this.props.userUpdate();
  };

  isPhone = phone => {
    return /\+7[\d]{10}/.test(phone);
  };

  render() {
    const { emailValid, isDisabled, nameValid, surnameValid, passwordValid } = this.state;
    const { loading, user } = this.props;

    const orderOptions = [
      {
        label: 'Мужской',
        value: 0
      },
      {
        label: 'Женский',
        value: 1
      }
    ];

    let termsText = (
      <P marginTop="21px" color="#6B778C">
        Я принимаю условия{' '}
        <a href="/documents/4" target="_blank" rel="noopener noreferrer">
          Пользовательского соглашения
        </a>{' '}
        и даю своё согласие {legalName} на обработку моих персональных данных, в соответствии
        с Федеральным законом от 27.07.2006 года #152-ФЗ "О персональных данных", на условиях
        и для целей, определенных{' '}
        <a href="/documents/3" target="_blank" rel="noopener noreferrer">
          Политикой конфиденциальности
        </a>
        .
      </P>
    )

    if (service === "my-yadro") {
      termsText = (
        <P marginTop="21px" color="#6B778C">
          Я даю согласие на обработку своих{' '}
          <a href="https://my-yadro.ru/policy" target="_blank" rel="noopener noreferrer">
            персональных данных
          </a>{' '} в соответствии с Политикой обработки персональных данных.
          С{' '}
          <a href="https://my-yadro.ru/policy" target="_blank" rel="noopener noreferrer">
            Политикой обработки персональных данных
          </a>{' '}
          ознакомлен (-на) и согласен (-на).
        </P>
      )
    }

    let content = <Spinner />;
    const form = (
      <Form onSubmit={this.sendUserForm}>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!nameValid}
              required
              label="Имя"
              name="name"
              onBlur={this.changeName}
              onChange={this.changeName}
              placeholder=""
              shouldFitContainer
              value={user.get('first_name')}
            />
          </Field>
          <Field>
            <TextField
              isInvalid={!surnameValid}
              required
              label="Фамилия"
              name="surname"
              onBlur={this.changeSurname}
              onChange={this.changeSurname}
              placeholder=""
              shouldFitContainer
              value={user.get('last_name')}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!emailValid}
              required
              label="Email"
              name="email"
              onBlur={this.changeEmail}
              onChange={this.changeEmail}
              placeholder=""
              shouldFitContainer
              value={user.get('email')}
            />
          </Field>
          {isPhone(user.get('phone_number')) && (
            <Field>
              <TextField
                required
                disabled
                label="Телефон"
                name="phone"
                placeholder=""
                shouldFitContainer
                value={user.get('phone_number')}
              />
            </Field>
          )}
          {!isPhone(user.get('phone_number')) && (
            <Field>
              <SelectField
                label="Пол"
                name="gender"
                required
                className="single-select"
                defaultOptions={orderOptions[0]}
                options={orderOptions}
                isSearchable={false}
                onChange={this.changeGender}
                placeholder="Пол"
                value={orderOptions[user.get('gender')]}
              />
            </Field>
          )}

        </FieldGroup>
        <FieldGroup>
          <Field>
            <TextField
              isInvalid={!passwordValid}
              required
              label="Пароль"
              name="password"
              onBlur={this.changePasswordInput}
              onChange={this.changePasswordInput}
              placeholder=""
              shouldFitContainer
              type="password"
              value={user.get('password')}
            />
          </Field>
          {isPhone(user.get('phone_number')) && (
            <Field>
              <SelectField
                label="Пол"
                name="gender"
                required
                className="single-select"
                defaultOptions={orderOptions[0]}
                options={orderOptions}
                isSearchable={false}
                onChange={this.changeGender}
                placeholder="Пол"
                value={orderOptions[user.get('gender')]}
              />
            </Field>
          )}
          <Field
          >
            <TextField
              label="Дата рождения"
              name="date_of_birth"
              isInvalid={!passwordValid}
              placeholder=""
              required
              shouldFitContainer
              mask="99.99.9999"
              value={user.get('date_of_birth') || ''}
              onChange={this.changeDateOfBirthInput}
              onBlur={this.changeDateOfBirthInput}
            />
          </Field>
        </FieldGroup>

        <FieldGroup>
          <CheckboxField>
            <Checkbox
              defaultChecked={false}
              label={termsText}
              onChange={this.changeTermsAccepted}
            />
          </CheckboxField>
        </FieldGroup>

        <FieldGroup>
          <ButtonField>
            <Button appearance="primary" isDisabled={isDisabled} type="submit">
              Завершить регистрацию
            </Button>
          </ButtonField>
        </FieldGroup>
      </Form>
    );

    if (!loading) {
      content = (
        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={TestImage} srcSet={`${TestImage2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Регистрация</Title>
            <P marginTop="3px" color="#6B778C">
              Уже зарегистрированы? <Link to="/individual/login">Войти</Link>
            </P>

            {form}
          </FlexItem>
        </Flex>
      );
    }

    return <Page>{content}</Page>;
  }
}

TestStart.propTypes = {
  changeCode: PropTypes.func,
  changeEmail: PropTypes.func,
  changeName: PropTypes.func,
  changePhone: PropTypes.func,
  changeSurname: PropTypes.func,
  checkInviteCode: PropTypes.func,
  changePassword: PropTypes.func.isRequired,
  changeGender: PropTypes.func.isRequired,
  code: PropTypes.string,
  loading: PropTypes.bool,
  sendCreate: PropTypes.func,
  phone: PropTypes.string,
  smsCodeWasSend: PropTypes.bool,
  user: PropTypes.object,
  userRead: PropTypes.func.isRequired,
  userUpdate: PropTypes.func,
  verified: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeCode: value => dispatch(changeCode(value)),
    changeEmail: value => dispatch(changeEmail(value)),
    changeName: value => dispatch(changeName(value)),
    changePhone: value => dispatch(changePhone(value)),
    changeSurname: value => dispatch(changeSurname(value)),
    changePassword: value => dispatch(changePassword(value)),
    changeGender: value => dispatch(changeGender(value)),
    changeDateOfBirth: value => dispatch(changeDateOfBirth(value)),
    changeTermsAccepted: value => dispatch(changeTermsAccepted(value)),
    checkInviteCode: value => dispatch(checkInviteCode(value)),
    sendCreate: () => dispatch(sendCreate()),
    sendVerify: value => dispatch(sendVerify(value)),
    userRead: () => dispatch(userRead()),
    userUpdate: () => dispatch(userUpdate())
  };
}

const mapStateToProps = createStructuredSelector({
  code: makeSelectCode(),
  loading: makeSelectLoading(),
  phone: makeSelectPhone(),
  smsCodeWasSend: makeSelectSmsWasSend(),
  termsAccepted: makeSelectTermsAccepted(),
  verified: makeSelectVerified(),
  user: makeSelectUser()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'testRegistration', reducer });
const withSaga = injectSaga({ key: 'testRegistration', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(TestStart);
