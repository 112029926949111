import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/ru';
import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import NormalButton from '@atlaskit/button';
import styled from 'styled-components';
import uuid from 'uuid/v1';
import isEmpty from 'lodash/isEmpty';

import Modal from 'components/Modal';

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

const Button = styled(NormalButton)`
  &.allWidth {
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;

const TableItem = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`;

const TriggerButton = <Button>...</Button>;

const createHead = withWidth => {
  return {
    cells: [
      {
        key: 'full_name',
        content: 'Имя',
        isSortable: false,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'user',
        content: 'Пользователь',
        isSortable: false,
        width: withWidth ? 20 : undefined
      },
      {
        key: 'email',
        content: 'Email',
        shouldTruncate: true,
        isSortable: false,
        width: withWidth ? 10 : undefined
      },
      {
        key: 'access',
        content: 'Тип',
        shouldTruncate: true,
        width: withWidth ? 10 : undefined
      },
      {
        key: 'hr',
        content: 'HR-менеджер',
        shouldTruncate: true,
        width: withWidth ? 15 : undefined
      },
      {
        key: 'created_at',
        content: 'Дата создания',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 13 : undefined
      },
      {
        key: 'more',
        shouldTruncate: true
      }
    ]
  };
};

const headTable = createHead(true);

class InviteTable extends React.Component {
  closeModal = id => {
    this.props.onChangeDeleteModals({
      modals: this.props.deleteModals.filter(i => i !== id)
    });
  };

  onDelete = id => {
    this.props.onDelete(id);
  };

  render() {
    const { deleteModals, isLoadingDelete } = this.props;
    const rowsTable = this.props.data
      .map((item, index) => ({
        key: `row-${index}-${item.get('id')}`,
        cells: [
          {
            key: createKey(item.get('full_name')),
            content: <TableItem>{item.get('full_name')}</TableItem>
          },
          {
            key: createKey(item.getIn(['invitee', 'full_name'])),
            content: (
              <TableItem>
                {!isEmpty(item.getIn(['invitee', 'full_name'])) && (
                  <Link to={`/person/report/${item.getIn(['invitee', 'id'])}`}>
                    {item.getIn(['invitee', 'full_name'])}
                  </Link>
                )}
              </TableItem>
            )
          },
          {
            key: createKey(item.get('email')),
            content: <TableItem>{item.get('email')}</TableItem>
          },
          {
            key: uuid(),
            content: (
              <TableItem>
                {item.get('type') === 0
                  ? 'Персонал'
                  : item.get('type') === 1
                  ? 'Менеджер'
                  : 'Администратор'}
              </TableItem>
            )
          },
          {
            key: createKey(item.getIn(['inviter', 'full_name'])),
            content: <TableItem>{item.getIn(['inviter', 'full_name'])}</TableItem>
          },
          {
            key: createKey(item.get('created_at')),
            content: <TableItem>{moment(item.get('created_at')).format('DD.MM.YYYY')}</TableItem>
          },
          {
            content: (
              <DropdownMenu trigger={TriggerButton} position="bottom right">
                <DropdownItemGroup>
                  <DropdownItem
                    onClick={() =>
                      this.props.onChangeDeleteModals({
                        modals: [item.get('id')]
                      })
                    }
                  >
                    Удалить
                  </DropdownItem>
                </DropdownItemGroup>
              </DropdownMenu>
            )
          }
        ]
      }))
      .toJS();

    return (
      <div>
        <DynamicTable
          head={headTable}
          isLoading={this.props.isLoading}
          loadingSpinnerSize="large"
          onSort={sort => this.props.onSort(sort.key, sort.sortOrder)}
          rows={rowsTable}
          sortKey={this.props.sortKey}
          sortOrder={this.props.sortOrder}
        />

        {deleteModals.map(id => (
          <Modal
            key={id}
            id={id}
            heading="Удалить приглашение"
            onClose={this.closeModal}
            actions={[
              {
                isLoading: isLoadingDelete,
                onClick: this.onDelete,
                text: 'Удалить'
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <p>Уверены, что хотите удалить приглашение?</p>
          </Modal>
        ))}
      </div>
    );
  }
}

export default InviteTable;
