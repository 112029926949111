import {
  CHANGE_COMPANY,
  CHANGE_INN,
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
  CHANGE_PASSWORD_ONE,
  CHANGE_SMS_CODE,
  CHANGE_FLAGS,
  CHANGE_SMS_FLAGS,
  SEND_SMS_CODE_REQUEST,
  SEND_SMS_CODE_REQUEST_SUCCESS,
  SEND_SMS_CODE_REQUEST_FAIL,
  REGISTER_COMPANY_REQUEST,
  REGISTER_COMPANY_REQUEST_SUCCESS,
  REGISTER_COMPANY_REQUEST_FAIL
} from './constants';

export function changeSmsFlags(payload) {
  return {
    type: CHANGE_SMS_FLAGS,
    ...payload
  };
}

export function sendSmsCode() {
  return {
    type: SEND_SMS_CODE_REQUEST
  };
}

export function sendSmsCodeSuccess() {
  return {
    type: SEND_SMS_CODE_REQUEST_SUCCESS
  };
}

export function sendSmsCodeFail() {
  return {
    type: SEND_SMS_CODE_REQUEST_FAIL
  };
}

export function changeCompany(payload) {
  return {
    type: CHANGE_COMPANY,
    ...payload
  };
}

export function changeInn(payload) {
  return {
    type: CHANGE_INN,
    ...payload
  };
}

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    ...payload
  };
}

export function changeSurname(payload) {
  return {
    type: CHANGE_SURNAME,
    ...payload
  };
}

export function changeEmail(payload) {
  return {
    type: CHANGE_EMAIL,
    ...payload
  };
}

export function changePhone(payload) {
  return {
    type: CHANGE_PHONE_NUMBER,
    ...payload
  };
}

export function changePasswordOne(payload) {
  return {
    type: CHANGE_PASSWORD_ONE,
    ...payload
  };
}

export function changeSmsCode(payload) {
  return {
    type: CHANGE_SMS_CODE,
    ...payload
  };
}

export function changeFlags(payload) {
  return {
    type: CHANGE_FLAGS,
    ...payload
  };
}

export function registerCompany(payload) {
  return {
    type: REGISTER_COMPANY_REQUEST,
    ...payload
  };
}

export function registerCompanySuccess() {
  return {
    type: REGISTER_COMPANY_REQUEST_SUCCESS
  };
}

export function registerCompanyFail(payload) {
  return {
    type: REGISTER_COMPANY_REQUEST_FAIL,
    ...payload
  };
}
