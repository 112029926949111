import styled from 'styled-components';

const ProgressLine = styled.div`
  background-color: #7a869a;
  border-radius: 3px;
  color: ${props => props.color};
  font-size: 16px;
  height: 32px;
  text-align: center;
  width: 100%;
  letter-spacing: -0.32px;
  line-height: 32px;
  position: relative;
  margin-bottom: 44px;
  margin-top: 59px;

  &:before {
    border-radius: 2px 0 0 2px;
    content: '';
    background-color: #f0f2f5;
    top: 0;
    left: 0;
    position: absolute;
    width: ${props => `${props.progressBefore}%`};
    height: 32px;
  }

  &:after {
    border-radius: 0 2px 2px 0;
    content: '';
    background-color: #f0f2f5;
    top: 0;
    right: 0;
    position: absolute;
    width: ${props => `${props.progressAfter}%`};
    height: 32px;
  }

  @media print {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

export default ProgressLine;
