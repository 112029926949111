import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import CompareListPage from 'pages/CompareList';

const CompareListView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <CompareListPage />
  </Fragment>
);

export default CompareListView;
