import styled from 'styled-components';

const Link = styled.button`
  background: none;
  border: none;
  color: #0052cc;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #0065ff;
    outline: none;
    text-decoration: underline;
  }
`;

export default Link;
