import React, { PureComponent } from 'react';
// import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';

import { ToggleStateless } from '@atlaskit/toggle';
import { PoseGroup } from 'react-pose';

import Tooltip from './styled/Tooltip';
import TooltipText from './styled/TooltipText';
import Wrapper from './styled/Wrapper';

class CriticalToggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenedTooltip: false,
      timerId: null
    };

    // this.onMouseOver = debounce(this.onMouseOver, 300);
  }

  onMouseOver = () => {
    this.setState({
      timerId: setTimeout(() => {
        this.setState({
          isOpenedTooltip: true
        });
      }, 300)
    });
  };

  onMouseOut = () => {
    if (!isNull(this.state.timerId)) {
      clearTimeout(this.state.timerId);
    }

    this.setState({
      timerId: null,
      isOpenedTooltip: false
    });
  };

  render() {
    const { isOpenedTooltip } = this.state;
    const tooltipText = this.props.isChecked ? (
      <TooltipText>Паттерн&nbsp;выделен,&nbsp;как&nbsp;критически&nbsp;важный</TooltipText>
    ) : (
      <TooltipText>Сделать&nbsp;паттерн&nbsp;критически&nbsp;важным</TooltipText>
    );

    return (
      <Wrapper>
        <div onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
          <ToggleStateless {...this.props} />
        </div>
        <PoseGroup>{isOpenedTooltip && <Tooltip key="tooltip">{tooltipText}</Tooltip>}</PoseGroup>
      </Wrapper>
    );
  }
}

export default CriticalToggle;
