import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog, { ModalTransition } from 'components/ModalDialog';

class Modal extends React.Component {
  onClose = () => {
    this.props.onClose(this.props.id);
  };

  render() {
    if (this.props.actions.length === 0) {
      return <div />;
    }

    const actions = this.props.actions.map(action => ({
      ...action,
      text: action.text,
      onClick: () => action.onClick(this.props.id)
    }));

    return (
      <ModalTransition>
        <ModalDialog
          autoFocus={false}
          heading={this.props.heading}
          onClose={this.onClose}
          actions={actions}
          width={this.props.width ? this.props.width : 'medium'}
        >
          {this.props.children}
        </ModalDialog>
      </ModalTransition>
    );
  }
}

Modal.propTypes = {
  actions: PropTypes.array.isRequired
};

Modal.defaultProps = {
  actions: []
};

export default Modal;
