import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { fromJS } from 'immutable';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { isEmail, isRequired } from 'utils/validators';

import Badge from '@atlaskit/badge';
import Button, { ButtonGroup } from '@atlaskit/button';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';
import Select from '@atlaskit/select';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Pagination from '@atlaskit/pagination';
import Tabs from '@atlaskit/tabs';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';

import Center from 'components/Center';
import Empty from 'components/Empty';
import InviteByFileForm from 'components/InviteByFileForm';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import {
  addReportToCompare,
  changeInviteComment,
  changeInviteFlags,
  changeInviteFullName,
  changeInviteEmail,
  changeInviteModals,
  changeInviteName,
  changeInviteText,
  changeInviteByFileFlags,
  sendInvitesByFile,
  changeManagersFilter,
  changeNameFilter,
  changeSort,
  changeStaffPage,
  getManagersList,
  sendInvite,
  staffDelete,
  staffList,
  updateReportsToCompare
} from './actions';

import {
  addStaffToCompare,
  deleteStaffFromCompare,
  deleteIdFromCompare
} from '../CompareList/actions';

import {
  makeSelectInvite,
  makeSelectInviteFlags,
  makeSelectInviteModals,
  makeSelectLoadingInvite,
  makeSelectLoadingTable,
  makeSelectManagerFilter,
  makeSelectManagersList,
  makeSelectSort,
  makeSelectStaffCount,
  makeSelectStaffList,
  makeSelectStaffLoading,
  makeSelectStaffPage,
  makeSelectCompareReportsId,
  makeSelectInviteWithFileCount,
  makeSelectInviteWithFlags
} from './selectors';
import reducer from './reducer';
import compareListReducer from '../CompareList/reducer';
import saga from './saga';

import BadgeWrapper from './styled/BadgeWrapper';
import Content from './styled/Content';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Table from './table/Table';
import TabContent from './styled/TabContent';

import './css/index.css';
import {makeSelectCompareIds} from "../CompareList/selectors";

class Home extends Component {
  constructor(props) {
    super(props);

    this.inviteFiles = [];

    this.state = {
      compareFlags: [],
      emailChanged: !isEmail(this.props.invite.email),
      emailValid: true,
      nameChanged: !isEmpty(this.props.invite.name),
      nameValid: true,
      nameFilter: '',
      filterChanged: false,
      isDisabled: true,
      isInviteByFile: 0,
      isInviteByFileInvalid: false
    };

    const compareReportsId = localStorage.getItem('compareIds')
      ? JSON.parse(localStorage.getItem('compareIds'))
      : [];
    if (compareReportsId.length > 0) {
      let compareReportsObject = fromJS({});

      compareReportsId.forEach(report => {
        compareReportsObject = compareReportsObject.set(report, report);
      });

      props.updateReportsToCompare({
        compareReportsId: compareReportsObject
      });
    }
  }

  componentDidMount() {
    this.sendChangedNameFilter = debounce(this.sendChangedNameFilter, 300);

    this.props.staffListRequest();
    this.props.getManagersList();

    if (!isUndefined(this.props.location.state)) {
      this.props.changeInviteModals({
        modals: this.props.location.state.addModals
      });
    }
  }

  getPageHeaderButtonsDrawer = (ids = []) => {
    return (
      <ButtonGroup>
        <Button
          appearance="primary"
          onClick={() =>
            this.props.changeInviteModals({
              modals: [1]
            })
          }
        >
          Пригласить на опрос
        </Button>
        <Button onClick={this.onCompareClick}>
          Сравнение
          <BadgeWrapper>
            <Badge appearance="primary">{ids.length}</Badge>
          </BadgeWrapper>
        </Button>
      </ButtonGroup>
    );
  };

  closeModal = id => {
    this.props.changeInviteModals({
      modals: this.props.inviteModals.filter(i => i !== id)
    });
  };

  addFlag = () => {
    this.props.changeInviteFlags({
      flags: [this.props.inviteFlags.length, ...this.props.inviteFlags.flags]
    });
  };

  removeFlag = id => {
    this.props.changeInviteFlags({ flags: this.props.inviteFlags.filter(v => v !== id) });
  };

  removeInviteByFileFlag = id => {
    this.props.changeInviteByFileFlags({
      flags: this.props.inviteWithFileFlags.filter(v => v !== id)
    });
  };

  onChangeFullName = event => {
    this.props.changeInviteFullName({
      fullName: event.target.value
    });
  };

  onChangeName = event => {
    const name = event.target.value;
    this.props.changeInviteName({
      name
    });
    this.setState({
      nameValid: isRequired(name)
    });
  };

  onChangeNameFilter = event => {
    this.sendChangedNameFilter(event.target.value);
    this.setState({
      filterChanged: true,
      nameFilter: event.target.value
    });
  };

  sendChangedNameFilter = nameFilter => {
    this.props.changeNameFilter({
      nameFilter
    });
  };

  onChangeComment = event => {
    this.props.changeInviteComment({
      comment: event.target.value
    });
  };

  onChangeEmail = event => {
    const email = event.target.value;
    this.props.changeInviteEmail({
      email
    });

    this.setState({
      emailChanged: true,
      emailValid: isEmail(email)
    });
  };

  onChangeFilter = event => {
    this.props.changeManagersFilter({
      managerFilter: event.value
    });
  };

  onChangeText = event => {
    this.props.changeInviteText({
      text: event.target.value
    });
  };

  onSendInvite = () => {
    const { invite } = this.props;
    const { isInviteByFile } = this.state;

    if (isInviteByFile === 1) {
      const files = this.inviteFiles;
      if (files.length > 0) {
        this.props.sendInvitesByFile({
          files
        });

        this.inviteFiles = [];
      } else {
        this.setState({
          isInviteByFileInvalid: true
        });
      }
    } else {
      const nameValid = isRequired(invite.name);
      const emailValid = isEmail(invite.email);

      if (nameValid && emailValid) {
        this.props.sendInviteRequest();
      } else {
        this.setState({
          emailValid,
          nameValid
        });
      }
    }
  };

  onSort = (key, order) => {
    this.props.changeSort({
      key,
      order
    });
  };

  onCompareClick = () => {
    let compareIds = localStorage.getItem('compareIds');
    if(!compareIds) {
      compareIds = "[]"
    }
    this.props.history.push(`/compare/`);
  };

  onAddReportToCompare = ({ id }) => {
    const compareFlags = this.state.compareFlags;
    const { addReportToCompare, addStaffToCompare } = this.props;
    compareFlags.push(id);

    this.setState({
      compareFlags: compareFlags
    });

    // addReportToCompare({ id });
    addStaffToCompare({ id })
  };

  onRemoveCompareFlag = id => {
    this.setState({
      compareFlags: this.state.compareFlags.filter(v => v !== id)
    });
  };

  onDropFile = files => {
    this.setState({
      isInviteByFileInvalid: false
    });

    this.inviteFiles = files;
  };

  onChangeTab = (tab, index) => {
    this.setState({
      isInviteByFile: index
    });
  };

  deleteFromCompare = val => this.props.deleteIdFromCompare(val)

  render() {
    const {
      changeStaffPage,
      invite,
      inviteFlags,
      inviteLoading,
      inviteModals,
      inviteWithFileCount,
      inviteWithFileFlags,
      managersFilter,
      managersList,
      sort,
      staffDeleteRequest,
      staffCount,
      staffList,
      staffLoading,
      staffPage,
      tableLoading
    } = this.props;

    const {
      compareFlags,
      filterChanged,
      nameFilter,
      isInviteByFile,
      isInviteByFileInvalid
    } = this.state;

    const compareIds = JSON.parse(localStorage.getItem('compareIds')) || [];

    let content = <Spinner height="80vh" />;

    if (!staffLoading && (staffList.length > 0 || filterChanged || managersFilter > 0)) {
      const selectManagersHr = managersList.map(manager => ({
        label: manager.get('full_name'),
        value: manager.get('id')
      }));

      const selectItems = [{ label: 'Все HR-менеджеры', value: 0 }, ...selectManagersHr.toJS()];

      const barContent = (
        <ButtonGroup>
          <TextField
            isLabelHidden
            label="hidden"
            onChange={this.onChangeNameFilter}
            placeholder="Имя или фамилия"
            value={nameFilter}
          />
          <Select
            className="single-select"
            defaultOptions={
              selectItems[
                selectManagersHr.findIndex(manager => manager.value === managersFilter) + 1
              ]
            }
            options={selectItems}
            isSearchable={false}
            onChange={this.onChangeFilter}
            placeholder="Все HR-менеджеры"
            value={
              selectItems[
                selectManagersHr.findIndex(manager => manager.value === managersFilter) + 1
              ]
            }
          />
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={this.getPageHeaderButtonsDrawer(compareIds)} bottomBar={barContent}>
              Отчеты
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <Table
              compareIds={fromJS(compareIds)}
              data={staffList}
              isLoading={tableLoading}
              onCompare={this.onAddReportToCompare}
              onDeleteCompare={this.deleteFromCompare}
              onDelete={staffDeleteRequest}
              onSort={this.onSort}
              sortKey={sort.get('key')}
              sortOrder={sort.get('order')}
            />
            {!tableLoading && (
              <Center>
                <Pagination
                  value={staffPage}
                  total={Math.ceil(staffCount / 25)}
                  onChange={staffPage => changeStaffPage({ staffPage })}
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!staffLoading && staffList.length === 0) {
      content = (
        <Empty header="У вас нет отчетов" text="Нажмите на кнопку чтобы пригласить пользователя">
          <Button
            appearance="primary"
            onClick={() =>
              this.props.changeInviteModals({
                modals: [1]
              })
            }
          >
            Отправить опрос
          </Button>
        </Empty>
      );
    }

    const tabs = [
      {
        label: 'Разовое приглашение',
        content: (
          <TabContent>
            <p>Укажите имя человека и e-mail, на который необходимо отправить ссылку на опрос</p>
            <Form name="layout-example" onSubmit={() => {}} onReset={() => {}} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.nameValid}
                    label="Имя"
                    name="name"
                    onChange={this.onChangeName}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={invite.name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Фамилия"
                    name="full_name"
                    onChange={this.onChangeFullName}
                    placeholder=""
                    shouldFitContainer
                    value={invite.full_name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!this.state.emailValid}
                    label="Email"
                    name="email"
                    onChange={this.onChangeEmail}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={invite.email}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    enableResize={false}
                    label="Текст приглашения"
                    minimumRows={4}
                    name="text"
                    onChange={this.onChangeText}
                    placeholder=""
                    shouldFitContainer
                    value={invite.welcome_text}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    enableResize={false}
                    label="Комментарий"
                    minimumRows={4}
                    name="comment"
                    onChange={this.onChangeComment}
                    placeholder=""
                    shouldFitContainer
                    value={invite.comment}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </TabContent>
        )
      },
      {
        label: 'Массовое приглашение',
        content: (
          <TabContent>
            <InviteByFileForm
              invite={invite}
              isFileInvalid={isInviteByFileInvalid}
              onChangeText={this.onChangeText}
              onChangeComment={this.onChangeComment}
              onDropFile={this.onDropFile}
            />
          </TabContent>
        )
      }
    ];

    return (
      <Page>
        <Helmet>
          <title>Отчеты</title>
        </Helmet>

        {content}

        {inviteModals.map(id => (
          <Modal
            autoFocus={false}
            key={id}
            id={id}
            heading="Отправить опрос"
            onClose={this.closeModal}
            actions={[
              {
                text: 'Отправить',
                onClick: this.onSendInvite,
                isLoading: inviteLoading
              },
              {
                text: 'Отменить',
                onClick: this.closeModal
              }
            ]}
            width="small"
          >
            <Tabs
              components={{ Content }}
              tabs={tabs}
              selected={tabs[isInviteByFile]}
              onSelect={this.onChangeTab}
            />
          </Modal>
        ))}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {inviteFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Ссылка на опрос отправлена"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeInviteByFileFlag(name)}>
          {inviteWithFileFlags.map(id => (
            <Flag
              description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
              isDismissAllowed
              id={id}
              icon={
                id > 0 ? (
                  <Tick label="Success" />
                ) : (
                  <Error label="Error" primaryColor={colors.R300} />
                )
              }
              key={`${id}`}
              title={id > 0 ? `Опрос отправлен ${inviteWithFileCount} людям` : 'Ошибка'}
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.onRemoveCompareFlag(name)}>
          {compareFlags.map(id => (
            <Flag
              actions={[
                {
                  content: 'Перейти к сравнению',
                  onClick: () => {
                    this.props.history.push(`/compare/`);
                  }
                }
              ]}
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Отчет добавлен к сравнению"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

Home.propTypes = {
  addReportToCompare: PropTypes.func,
  changeInviteByFileFlags: PropTypes.func.isRequired,
  changeInviteComment: PropTypes.func,
  changeInviteFlags: PropTypes.func,
  changeInviteFullName: PropTypes.func,
  changeInviteEmail: PropTypes.func,
  changeInviteModals: PropTypes.func,
  changeInviteName: PropTypes.func,
  changeInviteText: PropTypes.func,
  changeManagersFilter: PropTypes.func,
  changeNameFilter: PropTypes.func,
  changeSort: PropTypes.func,
  changeStaffPage: PropTypes.func,
  compareReportsIds: PropTypes.object,
  getManagersList: PropTypes.func,
  invite: PropTypes.object,
  inviteFlags: PropTypes.array,
  inviteLoading: PropTypes.bool,
  inviteModals: PropTypes.array,
  inviteWithFileCount: PropTypes.number,
  inviteWithFileFlags: PropTypes.array,
  managersFilter: PropTypes.number,
  managersList: PropTypes.object,
  sendInviteRequest: PropTypes.func,
  sendInvitesByFile: PropTypes.func.isRequired,
  sort: PropTypes.object,
  staffCount: PropTypes.number,
  staffDeleteRequest: PropTypes.func,
  staffList: PropTypes.array,
  staffLoading: PropTypes.bool,
  staffListRequest: PropTypes.func,
  staffPage: PropTypes.number,
  staffShow: PropTypes.func,
  tableLoading: PropTypes.bool,
  updateReportsToCompare: PropTypes.func,

  addStaffToCompare: PropTypes.func,
  deleteIdFromCompare: PropTypes.func,
  compareIds: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  return {
    addReportToCompare: value => dispatch(addReportToCompare(value)),
    changeInviteComment: value => dispatch(changeInviteComment(value)),
    changeInviteFlags: value => dispatch(changeInviteFlags(value)),
    changeInviteFullName: value => dispatch(changeInviteFullName(value)),
    changeInviteEmail: value => dispatch(changeInviteEmail(value)),
    changeInviteModals: value => dispatch(changeInviteModals(value)),
    changeInviteName: value => dispatch(changeInviteName(value)),
    changeInviteText: value => dispatch(changeInviteText(value)),
    changeInviteByFileFlags: value => dispatch(changeInviteByFileFlags(value)),
    sendInvitesByFile: value => dispatch(sendInvitesByFile(value)),
    changeManagersFilter: value => dispatch(changeManagersFilter(value)),
    changeNameFilter: value => dispatch(changeNameFilter(value)),
    changeSort: value => dispatch(changeSort(value)),
    changeStaffPage: value => dispatch(changeStaffPage(value)),
    getManagersList: () => dispatch(getManagersList()),
    sendInviteRequest: () => dispatch(sendInvite()),
    staffDeleteRequest: value => dispatch(staffDelete(value)),
    staffListRequest: () => dispatch(staffList()),
    updateReportsToCompare: value => dispatch(updateReportsToCompare(value)),

    addStaffToCompare: value => dispatch(addStaffToCompare(value)),
    deleteStaffFromCompare: value => dispatch(deleteStaffFromCompare(value)),
    deleteIdFromCompare: value => dispatch(deleteIdFromCompare(value)),
  };
}

const mapStateToProps = createStructuredSelector({
  compareReportsIds: makeSelectCompareReportsId(),
  invite: makeSelectInvite(),
  inviteFlags: makeSelectInviteFlags(),
  inviteLoading: makeSelectLoadingInvite(),
  inviteModals: makeSelectInviteModals(),
  inviteWithFileCount: makeSelectInviteWithFileCount(),
  inviteWithFileFlags: makeSelectInviteWithFlags(),
  managersFilter: makeSelectManagerFilter(),
  managersList: makeSelectManagersList(),
  sort: makeSelectSort(),
  staffCount: makeSelectStaffCount(),
  staffList: makeSelectStaffList(),
  staffLoading: makeSelectStaffLoading(),
  staffPage: makeSelectStaffPage(),
  tableLoading: makeSelectLoadingTable(),

  compareIds: makeSelectCompareIds(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });
const withCompareListReducer = injectReducer({ key: 'compareList', reducer: compareListReducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withReducer,
  withCompareListReducer,
  withSaga,
  withConnect
)(Home);
