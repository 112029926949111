import { createSelector } from 'reselect';

const selectGlobal = state => state.get('video');

const makeSelectVideoLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading')
  );

const makeSelectVideoList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('sections').toJS()
  );

export { makeSelectVideoList, makeSelectVideoLoading };
