import styled from 'styled-components';

const Container = styled.div`
  margin: 52px auto 0;
  width: 1160px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1020px) {
    flex-direction: column-reverse;
    width: calc(100% - 30px);
  }
`;

export default Container;
