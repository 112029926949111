import { call, select, put, takeLeading } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { apiRequest } from 'utils/request';

import { CHANGE_SEARCH_INPUT, STAFF_SEARCH_REQUEST, PROFILE_SEARCH_REQUEST } from './constants';

import {
  staffSearch,
  staffSearchSuccess,
  staffSearchFail,
  profileSearch,
  profileSearchSuccess,
  profileSearchFail
} from './actions';

import { makeSelectSearch } from './selectors';

function* changeSearchSaga() {
  yield put(staffSearch());
  yield put(profileSearch());
}

function* staffSearchSaga() {
  const search = yield select(makeSelectSearch());
  const url = `api/managers/staff/?full_name=${search}&limit=10`;

  if (search !== '') {
    try {
      const request = yield call(apiRequest, url);
      const staffList = fromJS(request.data.results);

      yield put(
        staffSearchSuccess({
          staffList: staffList.map(personal => ({
            href: `/person/${personal.get('id')}`,
            name: personal.get('full_name'),
            resultId: `personal-${personal.get('id')}`,
            text: personal.get('full_name'),
            type: 'base'
          }))
        })
      );
    } catch (e) {
      yield put(staffSearchFail());
    }
  } else {
    yield put(staffSearchFail());
  }
}

function* profilesSearchSaga() {
  const search = yield select(makeSelectSearch());
  const url = `api/managers/profiles/?name=${search}&limit=10`;

  if (search !== '') {
    try {
      const request = yield call(apiRequest, url);
      const profilesList = fromJS(request.data.results);

      yield put(
        profileSearchSuccess({
          profilesList: profilesList.map(profile => ({
            href: `/profile/${profile.get('id')}`,
            name: profile.get('name'),
            resultId: `profile-${profile.get('id')}`,
            text: profile.get('name'),
            type: 'base'
          }))
        })
      );
    } catch (e) {
      yield put(profileSearchFail());
    }
  } else {
    yield put(profileSearchFail());
  }
}

export default function* loginPageSaga() {
  yield takeLeading(STAFF_SEARCH_REQUEST, staffSearchSaga);
  yield takeLeading(PROFILE_SEARCH_REQUEST, profilesSearchSaga);
  yield takeLeading(CHANGE_SEARCH_INPUT, changeSearchSaga);
}
