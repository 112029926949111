import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';
import Button from '@atlaskit/button';
import Bg from './bg.jpg';
import { legalName } from "utils/checkService";

import { isEmail, isRequired } from 'utils/validators';

const BigField = styled.div`
  width: 100%;
`;

const Wrapper = styled.section`
  background-color: #fafafc;
  display: flex;
  min-height: 700px;
  padding: 120px 0 20px;
  width: 100%;

  @media (max-width: 1200px) and (min-width: 1021px) {
    min-height: auto;
    padding: 40px 0;
  }

  @media (max-width: 1020px) {
    margin-top: 50px;
    padding: 50px 0;
  }
`;

const Inner = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1160px;

  @media (max-width: 1020px) {
    display: block;
    width: calc(100% - 30px);
  }
`;

const LeftColumn = styled.div`
  margin-left: auto;
  width: 560px;

  @media (max-width: 1200px) and (min-width: 1021px) {
    width: calc(55% - 15px);
  }

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  background-image: url(${Bg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 45%;

  @media (max-width: 1020px) {
    height: 255px;
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 29px;
  color: #172b4d;
  letter-spacing: 0.32px;
  line-height: 32px;

  @media (max-width: 1020px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  color: #172b4d;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin-top: 21px;

  @media (max-width: 1020px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
`;

const Form = styled.form`
  margin-top: 32px;
`;

const FormInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.marginTop}px;
  width: 100%;

  @media (max-width: 1020px) {
    display: block;
  }
`;

const Field = styled.div`
  width: 260px;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

const P = styled.p`
  color: ${props => (props.color ? props.color : 'inherit')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
`;

class Feedback extends React.Component {
  state = {
    name: '',
    nameValid: false,
    email: '',
    emailValid: false,
    question: '',
    questionValid: false,
    isDisabled: true
  };

  changeName = e => {
    if (isRequired(e.target.value)) {
      this.setState({
        name: e.target.value,
        nameValid: true,
        isDisabled: !(this.state.emailValid && this.state.questionValid)
      });
    } else {
      this.setState({
        name: e.target.value,
        nameValid: false,
        isDisabled: true
      });
    }

    this.props.onChangeName({
      name: e.target.value
    });
  };

  changeEmail = e => {
    if (isEmail(e.target.value)) {
      this.setState({
        email: e.target.value,
        emailValid: true,
        isDisabled: !(this.state.nameValid && this.state.questionValid)
      });
    } else {
      this.setState({
        email: e.target.value,
        emailValid: false,
        isDisabled: true
      });
    }

    this.props.onChangeEmail({
      email: e.target.value
    });
  };

  changeQuestion = e => {
    if (isRequired(e.target.value)) {
      this.setState({
        question: e.target.value,
        questionValid: true,
        isDisabled: !(this.state.nameValid && this.state.emailValid)
      });
    } else {
      this.setState({
        question: e.target.value,
        questionValid: false,
        isDisabled: true
      });
    }

    this.props.onChangeText({
      text: e.target.value
    });
  };

  render() {
    return (
      <Wrapper id="feedback">
        <Inner>
          <LeftColumn>
            <Title>Задайте ваши вопросы</Title>
            <Text>
              Остались вопросы, есть замечания или предложения? Напишите нам, мы обязательно
              ответим.
            </Text>
            <Form>
              <FormInner>
                <Field>
                  <TextField
                    label="Ваше имя"
                    name="name"
                    onChange={this.changeName}
                    placeholder=""
                    value={this.state.name}
                    shouldFitContainer
                  />
                </Field>
                <Field>
                  <TextField
                    label="Ваш e-mail"
                    name="email"
                    onChange={this.changeEmail}
                    placeholder=""
                    value={this.state.email}
                    shouldFitContainer
                  />
                </Field>
              </FormInner>
              <FormInner>
                <BigField>
                  <TextArea
                    shouldFitContainer
                    label="Ваш вопрос"
                    name="question"
                    minimumRows={4}
                    onChange={this.changeQuestion}
                    value={this.state.question}
                  />
                </BigField>
              </FormInner>
              <FormInner marginTop={30}>
                <StyledButton
                  appearance="primary"
                  isDisabled={this.state.isDisabled}
                  isLoading={this.props.isLoading}
                  onClick={this.props.onFormSubmit}
                >
                  Отправить
                </StyledButton>
              </FormInner>
            </Form>
            <P marginTop="21px" color="#6B778C">
              Нажимая «Отправить» я принимаю условия{' '}
              <a href="/documents/4" target="_blank" rel="noopener noreferrer">
                Пользовательского соглашения
              </a>{' '}
              и даю своё согласие {legalName} на обработку моих персональных данных, в соответствии
              с Федеральным законом от 27.07.2006 года #152-ФЗ "О персональных данных", на условиях
              и для целей, определенных{' '}
              <a href="/documents/3" target="_blank" rel="noopener noreferrer">
                Политикой конфиденциальности
              </a>
              .
            </P>
          </LeftColumn>
          <RightColumn />
        </Inner>
      </Wrapper>
    );
  }
}

Feedback.propTypes = {
  onChangeName: PropTypes.func,
  onChangePhone: PropTypes.func,
  onFormSubmit: PropTypes.func
};

export default Feedback;
