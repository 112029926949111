import { fromJS } from 'immutable';

import {
  GET_REQUISITES_LIST,
  GET_REQUISITES_LIST_SUCCESS,
  GET_REQUISITES_LIST_FAIL,
  CHANGE_REQUISITES_PAGE,
  CHANGE_REQUISITES_REGISTRATION_FIELD,
  RESET_REQUISITES_REGISTRATION_FORM,
  REGISTER_NEW_REQUISITES,
  REGISTER_NEW_REQUISITES_SUCCESS,
  REGISTER_NEW_REQUISITES_FAIL,
  CHANGE_FLAGS,
  CHANGE_MODALS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAIL,
  CHANGE_ORGANIZATION_FIELD,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL
} from './constants';

const initialState = fromJS({
  delete: {
    flags: [],
    loading: false,
    modals: []
  },
  organization: {
    error: null,
    flags: [],
    form: {
      name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      index: '',
      address: '',
      account_number: '',
      bank_name: '',
      bank_bik: '',
      correspondent_account_number: ''
    },
    read: {
      id: 0,
      name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      index: '',
      address: '',
      account_number: '',
      bank_name: '',
      bank_bik: '',
      correspondent_account_number: ''
    },
    loading: false,
    updateLoading: false
  },
  requisites: {
    count: 0,
    error: null,
    list: [],
    loading: true,
    page: 1
  },
  registration: {
    error: null,
    flags: [],
    form: {
      name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      index: '',
      address: '',
      account_number: '',
      bank_name: '',
      bank_bik: '',
      correspondent_account_number: ''
    },
    loading: false
  }
});

function partnerFinancesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUISITES_LIST:
      return state.setIn(['requisites', 'loading'], true);
    case GET_REQUISITES_LIST_SUCCESS:
      return state
        .setIn(['requisites', 'count'], action.count)
        .setIn(['requisites', 'list'], fromJS(action.list))
        .setIn(['requisites', 'loading'], false);
    case GET_REQUISITES_LIST_FAIL:
      return state
        .setIn(['requisites', 'error'], action.error)
        .setIn(['requisites', 'count'], 0)
        .setIn(['requisites', 'list'], fromJS([]))
        .setIn(['requisites', 'loading'], false);
    case CHANGE_REQUISITES_PAGE:
      return state.setIn(['requisites', 'page'], action.page);
    case CHANGE_REQUISITES_REGISTRATION_FIELD:
      return state.setIn(['registration', 'form', action.field], action.text);
    case RESET_REQUISITES_REGISTRATION_FORM:
      return state.setIn(
        ['registration', 'form'],
        fromJS({
          name: '',
          inn: '',
          kpp: '',
          ogrn: '',
          index: '',
          address: '',
          account_number: '',
          bank_name: '',
          bank_bik: '',
          correspondent_account_number: ''
        })
      );
    case REGISTER_NEW_REQUISITES:
      return state.setIn(['registration', 'loading'], true);
    case REGISTER_NEW_REQUISITES_SUCCESS:
      return state
        .setIn(['registration', 'flags'], fromJS([1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            name: '',
            inn: '',
            kpp: '',
            ogrn: '',
            index: '',
            address: '',
            account_number: '',
            bank_name: '',
            bank_bik: '',
            correspondent_account_number: ''
          })
        );
    case REGISTER_NEW_REQUISITES_FAIL:
      return state
        .setIn(['registration', 'error'], action.error)
        .setIn(['registration', 'flags'], fromJS([-1]))
        .setIn(['registration', 'loading'], false)
        .setIn(
          ['registration', 'form'],
          fromJS({
            name: '',
            inn: '',
            kpp: '',
            ogrn: '',
            index: '',
            address: '',
            account_number: '',
            bank_name: '',
            bank_bik: '',
            correspondent_account_number: ''
          })
        );
    case CHANGE_FLAGS:
      return state.setIn([action.field, 'flags'], fromJS(action.flags));
    case CHANGE_MODALS:
      return state.setIn([action.field, 'modals'], fromJS(action.modals));
    case GET_ORGANIZATION:
      return state
        .setIn(['organization', 'loading'], true)
        .setIn(
          ['organization', 'read'],
          fromJS({
            id: 0,
            name: '',
            inn: '',
            kpp: '',
            ogrn: '',
            index: '',
            address: '',
            account_number: '',
            bank_name: '',
            bank_bik: '',
            correspondent_account_number: ''
          })
        )
        .setIn(
          ['organization', 'form'],
          fromJS({
            name: '',
            inn: '',
            kpp: '',
            ogrn: '',
            index: '',
            address: '',
            account_number: '',
            bank_name: '',
            bank_bik: '',
            correspondent_account_number: ''
          })
        );
    case GET_ORGANIZATION_SUCCESS:
      return state
        .setIn(['organization', 'read'], fromJS(action.organization))
        .setIn(['organization', 'form'], fromJS(action.organization))
        .setIn(['organization', 'loading'], false);
    case GET_ORGANIZATION_FAIL:
      return state
        .setIn(['organization', 'error'], action.error)
        .setIn(['organization', 'loading'], false);
    case CHANGE_ORGANIZATION_FIELD:
      return state.setIn(['organization', 'form', action.field], action.text);
    case UPDATE_ORGANIZATION:
      return state.setIn(['organization', 'updateLoading'], true);
    case UPDATE_ORGANIZATION_SUCCESS:
      return state
        .setIn(['organization', 'flags'], fromJS([1]))
        .setIn(['organization', 'updateLoading'], false);
    case UPDATE_ORGANIZATION_FAIL:
      return state
        .setIn(['organization', 'flags'], fromJS([-1]))
        .setIn(['organization', 'error'], action.error)
        .setIn(['organization', 'updateLoading'], false);
    case DELETE_ORGANIZATION:
      return state.setIn(['delete', 'loading'], true);
    case DELETE_ORGANIZATION_SUCCESS:
      return state
        .setIn(['delete', 'modals'], fromJS([]))
        .setIn(['delete', 'flags'], fromJS([1]))
        .setIn(['delete', 'loading'], false);
    case DELETE_ORGANIZATION_FAIL:
      return state
        .setIn(['delete', 'modals'], fromJS([]))
        .setIn(['delete', 'flags'], fromJS([-1]))
        .setIn(['delete', 'loading'], false);
    default:
      return state;
  }
}

export default partnerFinancesReducer;
