import { call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import axios from 'axios';
import isNull from 'lodash/isNull';
import apiUrl from 'utils/serverConfig';
import { formatPhone } from 'utils/formatPhone';
import moment from "moment";

import { makeSelectInvitationCode as invitationCodeFromStart } from 'pages/TestStart/selectors';

import {
  SEND_CREATE_REQUEST,
  SEND_VERIFY_REQUEST,
  USER_READ_REQUEST,
  USER_UPDATE_REQUEST,
  CHECK_INVITE_CODE_REQUEST
} from './constants';

import {
  makeSelectPhone,
  makeSelectCode,
  makeSelectInvitationCode,
  makeSelectUser
} from './selectors';

import {
  sendCreateSuccess,
  sendCreateFail,
  sendVerifySuccess,
  sendVerifyFail,
  userRead,
  userReadSuccess,
  userReadFail,
  userUpdateSuccess,
  userUpdateFail,
  checkInviteCodeSuccess,
  checkInviteCodeFail
} from './actions';

function* sendCreateSaga() {
  const url = `${apiUrl}/api/staff/auth/login/phone_number`;
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const options = {
    method: 'post',
    data: {
      phone_number
    }
  };

  try {
    yield call(axios, url, options);

    yield put(sendCreateSuccess());
  } catch (e) {
    yield put(sendCreateFail());
  }
}

function* sendVerifySaga() {
  const url = `${apiUrl}/api/staff/auth/login/phone_number/verify`;
  const phone_number = formatPhone(yield select(makeSelectPhone()));
  const passcode = yield select(makeSelectCode());
  const invitation_code = yield select(makeSelectInvitationCode());
  const options = {
    method: 'post',
    data: {
      phone_number,
      passcode,
      invitation_code
    }
  };

  try {
    const request = yield call(axios, url, options);
    const key = request.data.key;
    localStorage.setItem('staffKey', key);

    yield put(sendVerifySuccess());
    yield put(userRead());
  } catch (e) {
    yield put(sendVerifyFail());
  }
}

function* userReadSaga() {
  const url = `${apiUrl}/api/staff/auth/user`;
  const key = localStorage.getItem('staffKey');
  const invitationCode = yield select(invitationCodeFromStart());
  const options = {
    headers: {
      Authorization: `Token ${key}`
    }
  };

  try {
    const request = yield call(axios, url, options);
    const user = request.data;
    user.password = '';
    user.gender = 0;

    yield put(
      userReadSuccess({
        user: fromJS(user)
      })
    );

    if (
      user.get('email').length > 0 &&
      user.get('first_name').length > 0 &&
      user.get('last_name').length > 0
    ) {
      if (invitationCode) {
        yield put(push(`/test/about/${invitationCode}`));
      } else {
        yield put(push('/test/about'));
      }
    }
  } catch (e) {
    yield put(userReadFail());
  }
}

function* userUpdateSaga() {
  const url = `${apiUrl}/api/staff/auth/user`;
  const user = yield select(makeSelectUser());
  const key = localStorage.getItem('staffKey');
  const invitationCode = yield select(invitationCodeFromStart());
  const data = user.toJS();

  if(data.date_of_birth) {
    data.date_of_birth = moment(data.date_of_birth, "DD.MM.YYYY").format("YYYY-MM-DD")
  }

  if (!isNull(invitationCode)) {
    data.invitation_code = invitationCode;
  }

  const options = {
    method: 'put',
    headers: {
      Authorization: `Token ${key}`
    },
    data
  };

  try {
    yield call(axios, url, options);

    yield put(userUpdateSuccess());
    if (invitationCode) {
      yield put(push(`/test/about/${invitationCode}`));
    } else {
      yield put(push('/test/about'));
    }
  } catch (e) {
    yield put(userUpdateFail());
  }
}

function* checkInviteCodeSaga() {
  const url = `${apiUrl}/api/staff/invitations/validate`;
  const invite = yield select(makeSelectInvitationCode());
  const options = {
    method: 'post',
    data: {
      invitation_code: invite
    }
  };

  try {
    yield call(axios, url, options);

    yield put(checkInviteCodeSuccess());
  } catch (e) {
    yield put(checkInviteCodeFail());
    yield put(push('/error'));
  }
}

export default function* testStartSaga() {
  yield takeLeading(SEND_CREATE_REQUEST, sendCreateSaga);
  yield takeLeading(SEND_VERIFY_REQUEST, sendVerifySaga);
  yield takeLeading(USER_READ_REQUEST, userReadSaga);
  yield takeLeading(USER_UPDATE_REQUEST, userUpdateSaga);
  yield takeLeading(CHECK_INVITE_CODE_REQUEST, checkInviteCodeSaga);
}
