import { call, put, takeLeading } from 'redux-saga/effects';
import { apiRequest } from 'utils/partnerRequest';

import { GET_EDUCATIONS_LIST_REQUEST } from './constants';

import { getEducationsListSuccess, getEducationsListFail } from './actions';

function* getVideoSaga() {
  const url = `api/partners/education_sections/`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getEducationsListSuccess({
        sections: request.data
      })
    );
  } catch (e) {
    yield put(getEducationsListFail());
  }
}

export default function* videoSagas() {
  yield takeLeading(GET_EDUCATIONS_LIST_REQUEST, getVideoSaga);
}
