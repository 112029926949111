import styled from 'styled-components';

const ButtonField = styled.div`
  width: ${props => (props.width ? props.width : 'calc(50% - 10px)')};
  margin-top: 40px;

  @media (max-width: 1020px) {
    text-align: center;
    width: 100%;
  }
`;

export default ButtonField;
