import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Arrow from './Arrow';

export const AnswerContainer = styled.div`
  cursor: pointer;
  padding: 24px;
  background: #505f79;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  .placeholder & {
    box-shadow: none;
    opacity: 0;
  }

  @media (max-width: 1020px) {
    padding: 15px;
  }
`;

export const AnswerBody = styled.div`
  cursor: pointer;
  max-width: 414px;

  @media (max-width: 1020px) {
    font-size: 13px;
    letter-spacing: -0.14px;
    line-height: 18px;
    margin-right: 10px;
  }
`;

class Answer extends React.Component {
  render() {
    const answer = this.props.answer;

    return (
      <AnswerContainer>
        <AnswerBody>{answer.text}</AnswerBody>
        <Arrow />
      </AnswerContainer>
    );
  }
}

Answer.propTypes = {
  answer: PropTypes.object.isRequired
};

Answer.defaultProps = {
  answer: {
    text: ''
  }
};

export default Answer;
