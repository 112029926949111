import React, { Fragment } from 'react';
import RootViews from 'navigation/routes/RootViews';
import SetActiveView from 'navigation/routes/SetActiveView';
import ProfileEditPage from 'pages/ProfileEdit';

const ProfileEditView = () => (
  <Fragment>
    <RootViews />
    <SetActiveView id="root/index" />
    <ProfileEditPage />
  </Fragment>
);

export default ProfileEditView;
