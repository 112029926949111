import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { apiRequest } from 'utils/request';
import { pageHostUrl } from 'utils/serverConfig';
import { fromJS } from 'immutable';
import * as moment from 'moment';
import isNull from 'lodash/isNull';
import { noun } from 'plural-ru';
import 'moment/locale/ru';

import {
  changePaymentEmail,
  changePaymentLoading,
  changePaymentPopup,
  changePaymentWasSend,
  changeVisaPopup
} from 'pages/Settings/actions';
import { makeSelectPayment } from 'pages/Settings/selectors';

import {
  ADDS_REPORTS_REQUEST,
  CHECK_ORDER_REQUEST,
  GET_MANAGERS_LIST_REQUEST,
  GET_USER_READ_REQUEST,
  SEND_TO_PAYMENT_PAGE
} from './constants';

import {
  addsReportsSuccess,
  addsReportsFail,
  checkOrderSuccess,
  checkOrderFail,
  getManagersListSuccess,
  getManagersListFail,
  getUserInfo,
  getUserInfoSuccess,
  getUserInfoFail
} from './actions';

import {
  makeSelectAddsReportsCount,
  makeSelectAddsReturnUrl,
  makeSelectAddsTotal,
  makeSelectOrderId
} from './selectors';

function* getUserInfoSaga() {
  const url = 'api/managers/auth/user/';
  const urlCompany = 'api/managers/company/';

  try {
    const request = yield all([call(apiRequest, url), call(apiRequest, urlCompany)]);

    const user = request[0].data;
    const company = request[1].data;

    const nowMoment = moment();
    const planEndMoment = moment(company.billing_plan_valid_until);

    yield put(
      getUserInfoSuccess({
        company,
        warningFlag: planEndMoment.diff(nowMoment, 'days') < 1,
        company_inn: user.company_inn,
        company_name: user.company_name,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        pk: user.pk,
        role: user.role
      })
    );
  } catch (e) {
    yield put(getUserInfoFail());
    yield put(push('/signin'));
  }
}

function* getManagersListSaga() {
  const url = 'api/managers/managers/';

  try {
    const request = yield call(apiRequest, url);

    yield put(
      getManagersListSuccess({
        managersList: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(getManagersListFail());
  }
}

function* addsReportsSaga() {
  const url = 'api/managers/orders/create_for_reports/';
  const amount = yield select(makeSelectAddsTotal());
  const quantity = yield select(makeSelectAddsReportsCount());
  const returnUrl = yield select(makeSelectAddsReturnUrl());
  const payment = yield select(makeSelectPayment());

  const options = {
    method: 'post',
    data: {
      return_url: `${pageHostUrl}${returnUrl}/${quantity}`,
      amount,
      quantity: parseInt(quantity, 10)
    }
  };

  if (!isNull(payment.organization)) {
    options.data.organization = payment.organization;
  }

  if (payment.email !== '') {
    options.data.invoice_email = payment.email;
  }

  try {
    const request = yield call(apiRequest, url, options);

    yield put(
      addsReportsSuccess({
        result: parseInt(quantity, 10)
      })
    );

    if (payment.email !== '') {
      yield put(
        changePaymentWasSend({
          wasSend: true
        })
      );
      yield put(push('/settings/billing'));
    } else {
      window.location.href = request.data.formUrl;
    }
  } catch (e) {
    yield put(addsReportsFail());
  } finally {
    const popup = false;
    const email = '';

    yield put(
      changePaymentLoading({
        loading: false
      })
    );
    yield put(
      changePaymentPopup({
        popup
      })
    );
    yield put(
      changeVisaPopup({
        popup
      })
    );
    yield put(
      changePaymentEmail({
        email
      })
    );
  }
}

function* checkOrderSaga() {
  const orderId = yield select(makeSelectOrderId());
  const url = `api/managers/orders/${orderId}/status/`;

  try {
    const request = yield call(apiRequest, url);

    if (request.data.is_paid) {
      yield put(checkOrderSuccess());
    } else {
      yield put(checkOrderFail());
    }
  } catch (e) {
    yield put(checkOrderFail());
  } finally {
    yield put(getUserInfo());
  }
}

function* sendToPaymentPageSaga() {
  const amount = yield select(makeSelectAddsTotal());
  const quantity = yield select(makeSelectAddsReportsCount());

  yield put(
    push({
      pathname: '/settings/payment',
      state: {
        cost: amount,
        service: noun(
          quantity,
          '%d дополнительный отчет',
          '%d дополнительных отчета',
          '%d дополнительных отчетов'
        ),
        type: 'addsReports'
      }
    })
  );
}

export default function* userSaga() {
  yield takeLeading(GET_MANAGERS_LIST_REQUEST, getManagersListSaga);
  yield takeLeading(GET_USER_READ_REQUEST, getUserInfoSaga);
  yield takeLeading(ADDS_REPORTS_REQUEST, addsReportsSaga);
  yield takeLeading(CHECK_ORDER_REQUEST, checkOrderSaga);
  yield takeLeading(SEND_TO_PAYMENT_PAGE, sendToPaymentPageSaga);
}
