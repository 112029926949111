import styled from 'styled-components';

const ProgressAfter = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: #6b778c;
  letter-spacing: 0;
  line-height: 16px;
  bottom: -24px;
  right: ${props => `${props.width}%`};
  transform: translateX(50%);
`;

export default ProgressAfter;
