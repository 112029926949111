import styled from 'styled-components';

const Text = styled.div`
  font-size: 14px;
  color: #172b4d;
  letter-spacing: -0.15px;
  text-align: left;
  line-height: 20px;
  margin-top: 13px;
  position: relative;
  z-index: 1;

  & span {
    white-space: pre-line;
    
    &.highlight {
      background-color: #fff78f;
      cursor: pointer;
    }

    &.selected-text {
      background-color: #b4d7ff;
    }
  }
`;

export default Text;
