import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import { fromJS } from 'immutable';
import { noun } from 'plural-ru';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { changeComment, changeStaffFlags, staffRead, staffUpdate } from 'pages/Home/actions';
import {
  makeSelectLoadingUpdate,
  makeSelectStaffList,
  makeSelectStaffRead,
  makeSelectStaffLoading,
  makeSelectStaffUpdateFlags
} from 'pages/Home/selectors';
import reducer from 'pages/Home/reducer';
import saga from 'pages/Home/saga';

import Button from '@atlaskit/button';
import { Grid, GridColumn } from '@atlaskit/page';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import TextArea from '@atlaskit/field-text-area';
import Form from '@atlaskit/form';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';

import Spinner from 'components/Spinner';
import { isRequired, isEmail } from 'utils/validators';
import { formatTime } from 'utils/formatTime';

import Container from './styled/Container';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Note from './styled/Note';
import Stat from './styled/Stat';
import StatLine from './styled/StatLine';

import moment from "moment";

class Person extends React.Component {
  state = {
    person: null,
    email: {
      wasChanged: false,
      isInvalid: false,
      value: ''
    },
    emailPopup: {
      wasChanged: true,
      isInvalid: false,
      value: 'petrov@mail.ru'
    },
    phone: {
      wasChanged: false,
      isInvalid: false,
      value: ''
    },
    isDisabledMainButton: true
  };

  componentDidMount() {
    const personId = parseInt(this.props.match.params.id, 10);

    this.props.getStaffRead({
      staffId: personId
    });
  }

  validateInput = (value, object, validator) => {
    let currentState = fromJS(this.state[object]);

    currentState = currentState.set('isInvalid', !validator(value));
    currentState = currentState.set('wasChanged', true);
    currentState = currentState.set('value', value);

    const result = {};
    if (object === 'email') {
      result.isDisabledMainButton = false;
      if (currentState.get('isInvalid') === true || currentState.get('wasChanged') === false) {
        result.isDisabledMainButton = true;
      }

      if (this.state.phone.isInvalid === true || this.state.phone.wasChanged === false) {
        result.isDisabledMainButton = true;
      }
    } else if (object === 'phone') {
      result.isDisabledMainButton = false;
      if (currentState.get('isInvalid') === true || currentState.get('wasChanged') === false) {
        result.isDisabledMainButton = true;
      }

      if (this.state.email.isInvalid === true || this.state.email.wasChanged === false) {
        result.isDisabledMainButton = true;
      }
    } else if (object === 'emailPopup') {
      result.isDisabledModalButton = false;
      if (currentState.get('isInvalid') === true || currentState.get('wasChanged') === false) {
        result.isDisabledModalButton = true;
      }
    }

    result[object] = currentState.toJS();

    this.setState(result);
  };

  validateForm = () => {
    this.validateInput(this.state.email.value, 'email', isEmail);
    this.validateInput(this.state.phone.value, 'phone', isRequired);
  };

  removeFlag = id => {
    this.props.changeStaffFlags({
      flags: this.props.staffUpdateFlags.filter(v => v !== id)
    });
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeComment = event => {
    this.props.changeComment({ comment: event.target.value });
    this.setState({
      isDisabledMainButton: false
    });
  };

  onSaveComment = () => {
    this.props.staffUpdate();
  };

  render() {
    let { person } = this.state;

    if (isNull(person)) {
      person = this.props.staffRead;
    }

    let content = <Spinner />;

    if (!this.props.staffLoading) {
      const breadcrumbs = (
        <BreadcrumbsStateless onExpand={() => {}}>
          <BreadcrumbsItem
            href="/person/list"
            onClick={event => this.onBreadcumbsClick(event, '/person/list')}
            text="Отчеты"
            key="Personal"
          />
          <BreadcrumbsItem
            href={`/person/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/${person.id}`)}
            text={person.full_name}
            key="Petrov"
          />
          <BreadcrumbsItem
            href={`/person/${person.id}`}
            onClick={event => this.onBreadcumbsClick(event, `/person/${person.id}`)}
            text="Информация"
            key="Information"
          />
        </BreadcrumbsStateless>
      );

      content = (
        <Container>
          <Grid layout="fixed">
            <GridColumn medium={12}>
              <PageHeader breadcrumbs={breadcrumbs}>{person.full_name}</PageHeader>
            </GridColumn>
            <GridColumn medium={12}>
              <Form name="layout-example" onSubmit={() => {}} method="GET">
                <FieldGroup layout="column" form="">
                  <Field>
                    <TextField
                      shouldFitContainer
                      name="name"
                      placeholder=""
                      label="Имя"
                      value={person.first_name}
                      isReadOnly
                    />
                  </Field>
                  <Field>
                    <TextField
                      label="Фамилия"
                      shouldFitContainer
                      name="surname"
                      placeholder=""
                      value={person.last_name}
                      isReadOnly
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field>
                    <TextField
                      isInvalid={this.state.email.isInvalid}
                      label="Email"
                      name="email"
                      onBlur={e => {
                        e.preventDefault();
                        this.validateInput(e.target.value, 'email', isEmail);
                      }}
                      placeholder=""
                      shouldFitContainer
                      value={person.email}
                      isReadOnly
                    />
                  </Field>
                  <Field>
                    <TextField
                      isInvalid={this.state.phone.isInvalid}
                      label="Телефон"
                      name="phone"
                      onBlur={e => {
                        e.preventDefault();
                        this.validateInput(e.target.value, 'phone', isRequired);
                      }}
                      placeholder=""
                      shouldFitContainer
                      value={person.phone_number}
                      isReadOnly
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field>
                    <TextField
                      label="Дата рождения"
                      shouldFitContainer
                      name="date_of_birth"
                      placeholder="Не указано"
                      value={person.date_of_birth ? moment(person.date_of_birth, "YYYY-MM-DD").format("DD.MM.YYYY") : ""}
                      isReadOnly
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup layout="column" form="">
                  <Field width="100%">
                    <TextArea
                      enableResize={false}
                      label="Комментарий (необязательно)"
                      minimumRows={8}
                      name="comment"
                      onChange={this.onChangeComment}
                      placeholder="Введите ваш комментарий"
                      shouldFitContainer
                      value={person.comment}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field marginTop="20px">
                    <Button
                      appearance="primary"
                      isDisabled={this.state.isDisabledMainButton}
                      isLoading={this.props.updateLoading}
                      onClick={this.onSaveComment}
                    >
                      Сохранить
                    </Button>
                  </Field>
                </FieldGroup>
              </Form>
            </GridColumn>
            {!isNull(person.testing_result) && (
              <GridColumn medium={12}>
                <Stat>
                  <h3>Статистика прохождения опроса</h3>
                  <Stat>
                    <Grid layout="fixed">
                      <GridColumn medium={6}>
                        <h4>Время</h4>
                        <StatLine>
                          <h2>{formatTime(person.testing_result.questionnaire_duration)}</h2>
                          <Note>Общее время прохождения опроса</Note>
                        </StatLine>
                        <StatLine>
                          <h2>{formatTime(person.testing_result.question_duration_min)}</h2>
                          <Note>Минимальное время ответа на вопрос</Note>
                        </StatLine>
                        <StatLine>
                          <h2>{formatTime(person.testing_result.question_duration_max)}</h2>
                          <Note>Максимальное время ответа на вопрос</Note>
                        </StatLine>
                      </GridColumn>
                      <GridColumn medium={6}>
                        <h4>Активность перемещения ответов</h4>
                        <StatLine>
                          <h2>
                            {noun(
                              person.testing_result.question_moves_count_0,
                              '%d вопрос',
                              '%d вопроса',
                              '%d вопросов'
                            )}
                          </h2>
                          <Note>Без перемещений</Note>
                        </StatLine>
                        <StatLine>
                          <h2>
                            {noun(
                              person.testing_result.question_moves_count_1,
                              '%d вопрос',
                              '%d вопроса',
                              '%d вопросов'
                            )}
                          </h2>
                          <Note>С 1 перемещением</Note>
                        </StatLine>
                        <StatLine>
                          <h2>
                            {noun(
                              person.testing_result.question_moves_count_2,
                              '%d вопрос',
                              '%d вопроса',
                              '%d вопросов'
                            )}
                          </h2>
                          <Note>С 2 перемещениями</Note>
                        </StatLine>
                        <StatLine>
                          <h2>
                            {noun(
                              person.testing_result.question_moves_count_3,
                              '%d вопрос',
                              '%d вопроса',
                              '%d вопросов'
                            )}
                          </h2>
                          <Note>С 3 перемещениями</Note>
                        </StatLine>
                        <StatLine>
                          <h2>
                            {noun(
                              person.testing_result.question_moves_count_4,
                              '%d вопрос',
                              '%d вопроса',
                              '%d вопросов'
                            )}
                          </h2>
                          <Note>С 4 и более перемещениями</Note>
                        </StatLine>
                      </GridColumn>
                    </Grid>
                  </Stat>
                </Stat>
              </GridColumn>
            )}
          </Grid>
        </Container>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{person.full_name}</title>
        </Helmet>
        {content}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {this.props.staffUpdateFlags.map(id => (
            <Flag
              isDismissAllowed
              id={id}
              icon={<Tick label="Success" />}
              key={`${id}`}
              title="Изменения сохранены"
            />
          ))}
        </FlagGroup>
      </Fragment>
    );
  }
}

Person.propTypes = {
  changeComment: PropTypes.func,
  changeStaffFlags: PropTypes.func,
  getStaffRead: PropTypes.func,
  staffList: PropTypes.array,
  staffRead: PropTypes.object,
  staffLoading: PropTypes.bool,
  staffUpdate: PropTypes.func,
  staffUpdateFlags: PropTypes.array,
  updateLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeComment: value => dispatch(changeComment(value)),
    changeStaffFlags: value => dispatch(changeStaffFlags(value)),
    getStaffRead: value => dispatch(staffRead(value)),
    staffUpdate: () => dispatch(staffUpdate())
  };
}

const mapStateToProps = createStructuredSelector({
  staffList: makeSelectStaffList(),
  staffRead: makeSelectStaffRead(),
  staffLoading: makeSelectStaffLoading(),
  staffUpdateFlags: makeSelectStaffUpdateFlags(),
  updateLoading: makeSelectLoadingUpdate()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'staff', reducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Person);
