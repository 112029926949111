import {
  LOADING_TABLE_END,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_FILTER_REQUEST,
  PROFILE_LIST_REQUEST_SUCCESS,
  PROFILE_LIST_REQUEST_FAIL,
  PROFILE_READ_REQUEST,
  PROFILE_READ_REQUEST_SUCCESS,
  PROFILE_READ_REQUEST_FAIL,
  CHANGE_REPORTS_PAGE,
  CHANGE_REPORTS_SORT,
  CHANGE_REPORTS_NAME,
  PROFILE_REPORTS_REQUEST,
  PROFILE_REPORTS_REQUEST_SUCCESS,
  PROFILE_REPORTS_REQUEST_FAIL,
  CREATE_COMPARE_REQUEST,
  CREATE_COMPARE_REQUEST_SUCCESS,
  CREATE_COMPARE_REQUEST_FAIL,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL,
  CHANGE_STAFF_INPUT,
  STAFF_LIST_REQUEST,
  STAFF_LIST_REQUEST_SUCCESS,
  STAFF_LIST_REQUEST_FAIL,
  CHANGE_STAFF_POSITIVE_INPUT,
  POSITIVE_STAFF_LIST_REQUEST,
  POSITIVE_STAFF_LIST_REQUEST_SUCCESS,
  POSITIVE_STAFF_LIST_REQUEST_FAIL,
  CHANGE_STAFF_NEGATIVE_INPUT,
  NEGATIVE_STAFF_LIST_REQUEST,
  NEGATIVE_STAFF_LIST_REQUEST_SUCCESS,
  NEGATIVE_STAFF_LIST_REQUEST_FAIL,
  CHANGE_NAME_CREATE_FROM_SAMPLE,
  CHANGE_DESC_CREATE_FROM_SAMPLE,
  CHANGE_NEGATIVE_CREATE_FROM_SAMPLE,
  CHANGE_POSITIVE_CREATE_FROM_SAMPLE,
  CHANGE_PATTERN_NAME,
  CHANGE_PATTERN_DESC,
  CHANGE_PATTERN_RANGE_LOWER,
  CHANGE_PATTERN_RANGE_UPPER,
  CHANGE_PATTERN_EDIT_CRITICAL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_REQUEST_SUCCESS,
  PROFILE_UPDATE_REQUEST_FAIL,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_REQUEST_SUCCESS,
  QUESTIONS_LIST_REQUEST_FAIL,
  CHANGE_NAME_CREATE_FROM_QUESTIONS,
  CHANGE_DESC_CREATE_FROM_QUESTIONS,
  CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL,
  CHANGE_PATTERN_CREATED_RANGE_LOWER,
  CHANGE_PATTERN_CREATED_RANGE_UPPER,
  CHANGE_PATTERN_CREATED_CRITICAL,
  CHANGE_PROFILE_FOR_UPDATE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_REQUEST_SUCCESS,
  DELETE_PROFILE_REQUEST_FAIL,
  CHANGE_PATTERN_CUSTOM_RANGE_LOWER,
  CHANGE_PATTERN_CUSTOM_RANGE_UPPER,
  CHANGE_PROFILE_DESC_CUSTOM,
  CHANGE_PROFILE_NAME_CUSTOM,
  CHANGE_PATTERN_CRITICAL,
  META_PROGRAMS_LIST_REQUEST,
  META_PROGRAMS_LIST_REQUEST_SUCCESS,
  META_PROGRAMS_LIST_REQUEST_FAIL,
  PROFILE_CREATE_CUSTOM_REQUEST,
  PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS,
  PROFILE_CREATE_CUSTOM_REQUEST_FAIL,
  CHANGE_NAME_FILTER,
  CHANGE_MANAGERS_FILTER,
  CHANGE_PROFILE_PAGE,
  CHANGE_PROFILE_SORT,
  CHANGE_PROFILE_CHIEF_NAME,
  CHANGE_PROFILE_CHIEF_DESC,
  CHANGE_PROFILE_CHIEF_EMAIL,
  CHANGE_PROFILE_CHIEF_MESSAGE,
  CHANGE_PROFILE_CHIEF_FLAGS,
  CHANGE_PROFILE_CHIEF_FULL_NAME,
  SEND_PROFILE_CHIEF_REQUEST,
  SEND_PROFILE_CHIEF_REQUEST_SUCCESS,
  SEND_PROFILE_CHIEF_REQUEST_FAIL,
  CHANGE_PROFILE_EMAIL_FLAGS,
  CHANGE_PROFILE_EMAIL_MODALS,
  CHANGE_PROFILE_EMAIL_ADDRESS,
  SEND_PROFILE_EMAIL_REQUEST,
  SEND_PROFILE_EMAIL_REQUEST_SUCCESS,
  SEND_PROFILE_EMAIL_REQUEST_FAIL
} from './constants';

export function changeProfileEmailFlags(payload) {
  return {
    type: CHANGE_PROFILE_EMAIL_FLAGS,
    ...payload
  };
}

export function changeProfileEmailModals(payload) {
  return {
    type: CHANGE_PROFILE_EMAIL_MODALS,
    ...payload
  };
}

export function changeProfileEmailAddress(payload) {
  return {
    type: CHANGE_PROFILE_EMAIL_ADDRESS,
    ...payload
  };
}

export function sendProfileEmail(payload) {
  return {
    type: SEND_PROFILE_EMAIL_REQUEST,
    ...payload
  };
}

export function sendProfileEmailSuccess() {
  return {
    type: SEND_PROFILE_EMAIL_REQUEST_SUCCESS
  };
}

export function sendProfileEmailFail() {
  return {
    type: SEND_PROFILE_EMAIL_REQUEST_FAIL
  };
}

export function changeProfileChiefName(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_NAME,
    ...payload
  };
}

export function changeProfileChiefDesc(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_DESC,
    ...payload
  };
}

export function changeProfileChiefEmail(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_EMAIL,
    ...payload
  };
}

export function changeProfileChiefMessage(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_MESSAGE,
    ...payload
  };
}

export function changeProfileChiefFullName(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_FULL_NAME,
    ...payload
  };
}

export function changeProfileChiefFlags(payload) {
  return {
    type: CHANGE_PROFILE_CHIEF_FLAGS,
    ...payload
  };
}

export function sendProfileChief(payload) {
  return {
    type: SEND_PROFILE_CHIEF_REQUEST,
    ...payload
  };
}

export function sendProfileChiefSuccess() {
  return {
    type: SEND_PROFILE_CHIEF_REQUEST_SUCCESS
  };
}

export function sendProfileChiefFail() {
  return {
    type: SEND_PROFILE_CHIEF_REQUEST_FAIL
  };
}

export function createCompare(payload) {
  return {
    type: CREATE_COMPARE_REQUEST,
    ...payload
  };
}

export function createCompareSuccess() {
  return {
    type: CREATE_COMPARE_REQUEST_SUCCESS
  };
}

export function createCompareFail() {
  return {
    type: CREATE_COMPARE_REQUEST_FAIL
  };
}

export function changeSort(payload) {
  return {
    type: CHANGE_PROFILE_SORT,
    ...payload
  };
}

export function changeProfilePage(payload) {
  return {
    type: CHANGE_PROFILE_PAGE,
    ...payload
  };
}

export function changeManagersFilter(payload) {
  return {
    type: CHANGE_MANAGERS_FILTER,
    ...payload
  };
}

export function changeNameFilter(payload) {
  return {
    type: CHANGE_NAME_FILTER,
    ...payload
  };
}

export function profileFilteredList() {
  return {
    type: PROFILE_LIST_FILTER_REQUEST
  };
}

export function loadingTableFinish() {
  return {
    type: LOADING_TABLE_END
  };
}

export function profileList(payload) {
  return {
    type: PROFILE_LIST_REQUEST,
    ...payload
  };
}

export function profileListSuccess(payload) {
  return {
    type: PROFILE_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileListFail(payload) {
  return {
    type: PROFILE_LIST_REQUEST_FAIL,
    ...payload
  };
}

export function profileRead(payload) {
  return {
    type: PROFILE_READ_REQUEST,
    ...payload
  };
}

export function profileReadSuccess(payload) {
  return {
    type: PROFILE_READ_REQUEST_SUCCESS,
    ...payload
  };
}

export function changeReportsPage(payload) {
  return {
    type: CHANGE_REPORTS_PAGE,
    ...payload
  };
}

export function changeReportsSort(payload) {
  return {
    type: CHANGE_REPORTS_SORT,
    ...payload
  };
}

export function changeReportsName(payload) {
  return {
    type: CHANGE_REPORTS_NAME,
    ...payload
  };
}

export function profileReadFail() {
  return {
    type: PROFILE_READ_REQUEST_FAIL
  };
}

export function profileReports(payload) {
  return {
    type: PROFILE_REPORTS_REQUEST,
    ...payload
  };
}

export function profileReportsSuccess(payload) {
  return {
    type: PROFILE_REPORTS_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileReportsFail() {
  return {
    type: PROFILE_REPORTS_REQUEST_FAIL
  };
}

export function profileCreateFromSample(payload) {
  return {
    type: PROFILE_CREATE_FROM_SAMPLE_REQUEST,
    ...payload
  };
}

export function profileCreateFromSampleSuccess(payload) {
  return {
    type: PROFILE_CREATE_FROM_SAMPLE_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileCreateFromSampleFail() {
  return {
    type: PROFILE_CREATE_FROM_SAMPLE_REQUEST_FAIL
  };
}

export function changeNameCreateFromSimple(payload) {
  return {
    type: CHANGE_NAME_CREATE_FROM_SAMPLE,
    ...payload
  };
}

export function changeDescCreateFromSimple(payload) {
  return {
    type: CHANGE_DESC_CREATE_FROM_SAMPLE,
    ...payload
  };
}

export function changeNegativeCreateFromSimple(payload) {
  return {
    type: CHANGE_NEGATIVE_CREATE_FROM_SAMPLE,
    ...payload
  };
}

export function changePositiveCreateFromSimple(payload) {
  return {
    type: CHANGE_POSITIVE_CREATE_FROM_SAMPLE,
    ...payload
  };
}

export function changeStaffInput(payload) {
  return {
    type: CHANGE_STAFF_INPUT,
    ...payload
  };
}

export function staffList() {
  return {
    type: STAFF_LIST_REQUEST
  };
}

export function staffListSuccess(payload) {
  return {
    type: STAFF_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function staffListFail() {
  return {
    type: STAFF_LIST_REQUEST_FAIL
  };
}

export function changePositiveInput(payload) {
  return {
    type: CHANGE_STAFF_POSITIVE_INPUT,
    ...payload
  };
}

export function positiveStaffList() {
  return {
    type: POSITIVE_STAFF_LIST_REQUEST
  };
}

export function positiveStaffListSuccess(payload) {
  return {
    type: POSITIVE_STAFF_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function positiveStaffListFail() {
  return {
    type: POSITIVE_STAFF_LIST_REQUEST_FAIL
  };
}

export function changeNegativeInput(payload) {
  return {
    type: CHANGE_STAFF_NEGATIVE_INPUT,
    ...payload
  };
}

export function negativeStaffList() {
  return {
    type: NEGATIVE_STAFF_LIST_REQUEST
  };
}

export function negativeStaffListSuccess(payload) {
  return {
    type: NEGATIVE_STAFF_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function negativeStaffListFail() {
  return {
    type: NEGATIVE_STAFF_LIST_REQUEST_FAIL
  };
}

export function changePatternRangeLower(payload) {
  return {
    type: CHANGE_PATTERN_RANGE_LOWER,
    ...payload
  };
}

export function changePatternRangeUpper(payload) {
  return {
    type: CHANGE_PATTERN_RANGE_UPPER,
    ...payload
  };
}

export function changePatternEditCritical(payload) {
  return {
    type: CHANGE_PATTERN_EDIT_CRITICAL,
    ...payload
  };
}

export function profileUpdate(payload) {
  return {
    type: PROFILE_UPDATE_REQUEST,
    ...payload
  };
}

export function profileUpdateSuccess(payload) {
  return {
    type: PROFILE_UPDATE_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileUpdateFail(payload) {
  return {
    type: PROFILE_UPDATE_REQUEST_FAIL,
    ...payload
  };
}

export function questionsList() {
  return {
    type: QUESTIONS_LIST_REQUEST
  };
}

export function questionListSuccess(payload) {
  return {
    type: QUESTIONS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function questionListFail() {
  return {
    type: QUESTIONS_LIST_REQUEST_FAIL
  };
}

export function changeNameCreateFromQuestions(payload) {
  return {
    type: CHANGE_NAME_CREATE_FROM_QUESTIONS,
    ...payload
  };
}

export function changeDescCreateFromQuestions(payload) {
  return {
    type: CHANGE_DESC_CREATE_FROM_QUESTIONS,
    ...payload
  };
}

export function changeAnswerCreateFromQuestions(payload) {
  return {
    type: CHANGE_ANSWER_CREATE_FROM_QUESTIONS,
    ...payload
  };
}

export function profileCreateFromQuesitons() {
  return {
    type: PROFILE_CREATE_FROM_QUESTIONS_REQUEST
  };
}

export function profileCreateFromQuestionsSuccess(payload) {
  return {
    type: PROFILE_CREATE_FROM_QUESTIONS_REQUEST_SUCCESS,
    ...payload
  };
}

export function profileCreateFromQuestionsFail() {
  return {
    type: PROFILE_CREATE_FROM_QUESTIONS_REQUEST_FAIL
  };
}

export function changePatternRangeCreatedLower(payload) {
  return {
    type: CHANGE_PATTERN_CREATED_RANGE_LOWER,
    ...payload
  };
}

export function changePatternRangeCreatedUpper(payload) {
  return {
    type: CHANGE_PATTERN_CREATED_RANGE_UPPER,
    ...payload
  };
}

export function changePatternCreatedCritical(payload) {
  return {
    type: CHANGE_PATTERN_CREATED_CRITICAL,
    ...payload
  };
}

export function changeProfileForUpdate(payload) {
  return {
    type: CHANGE_PROFILE_FOR_UPDATE,
    ...payload
  };
}

export function deleteProfile(payload) {
  return {
    type: DELETE_PROFILE_REQUEST,
    ...payload
  };
}

export function deleteProfileSuccess() {
  return {
    type: DELETE_PROFILE_REQUEST_SUCCESS
  };
}

export function deleteProfileFail() {
  return {
    type: DELETE_PROFILE_REQUEST_FAIL
  };
}

export function metaProgramsList() {
  return {
    type: META_PROGRAMS_LIST_REQUEST
  };
}

export function metaProgramsListSuccess(payload) {
  return {
    type: META_PROGRAMS_LIST_REQUEST_SUCCESS,
    ...payload
  };
}

export function metaProgramsListFail() {
  return {
    type: META_PROGRAMS_LIST_REQUEST_FAIL
  };
}

export function changeProfileNameCustom(payload) {
  return {
    type: CHANGE_PROFILE_NAME_CUSTOM,
    ...payload
  };
}

export function changeProfileDescCustom(payload) {
  return {
    type: CHANGE_PROFILE_DESC_CUSTOM,
    ...payload
  };
}

export function changePatternCustomLower(payload) {
  return {
    type: CHANGE_PATTERN_CUSTOM_RANGE_LOWER,
    ...payload
  };
}

export function changePatternCustomUpper(payload) {
  return {
    type: CHANGE_PATTERN_CUSTOM_RANGE_UPPER,
    ...payload
  };
}

export function changePatternCritical(payload) {
  return {
    type: CHANGE_PATTERN_CRITICAL,
    ...payload
  };
}

export function profileCreateCustom() {
  return {
    type: PROFILE_CREATE_CUSTOM_REQUEST
  };
}

export function profileCreateCustomSuccess() {
  return {
    type: PROFILE_CREATE_CUSTOM_REQUEST_SUCCESS
  };
}

export function profileCreateCustomFail() {
  return {
    type: PROFILE_CREATE_CUSTOM_REQUEST_FAIL
  };
}

export function changePatternName(payload) {
  return {
    type: CHANGE_PATTERN_NAME,
    ...payload
  };
}

export function changePatternDesc(payload) {
  return {
    type: CHANGE_PATTERN_DESC,
    ...payload
  };
}
